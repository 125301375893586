import React, { useState, useEffect } from 'react'
import Header from '../../../commons/components/Header'
import Footer from '../../../commons/components/Footer'
import { Link } from 'react-router-dom'
import EnterpriseSideBar from '../../../commons/components/SideBars/EnterpriseSideBar'
import { useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom'
import { API } from '../../../config/APIConfig';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import logger from '../../../utils/logger'
import { S3Config } from '../../../config/AWSConfig'
const GETRFPDETAILAPI = `${API.URL}api/v1/enterprise/rfp/view`;
const RFPViewEnterprise = () => {
    console.log("IN RFP VIEW PAGE")
    const { i18n, t } = useTranslation(["rfp"]);


    const [rfpviewdata, setRFPViewData] = useState({});
    const [rfpDetails, setRfpDetails] = useState({});
    const [rfpindustryExperienceInfo, setRFPIndustryExperienceInfo] = useState([]);
    const [rfpAttachments, setRfpAttachments] = useState([{}]);
    const [rfpExperience, setRfpExperience] = useState([{}]);
    const location = useLocation();
    // const  rfp  = location.state;
    const rfpId = useLocation().state.passrfpId
    const rfpStatus = useLocation().state.status

    const enterpriseId = sessionStorage.getItem("enterpriseId");
    const userId = sessionStorage.getItem("userId");
    console.log(`${GETRFPDETAILAPI}/?enterpriseId=${enterpriseId}&userId=${userId}&rfpId=${rfpId}`)


    function getRFPDetails(enterpriseId, userId, rfpId) {
        // console.log("In the function")
        // logger.verbose("RFP Details", "RFPViewEnterprise Component", "getRFPDetails", "GET", "Get RFP Details", `enterpriseId=${enterpriseId}&userId=${userId}&rfpId=${rfpId}`);
        axios.get(`${GETRFPDETAILAPI}/?enterpriseId=${enterpriseId}&userId=${userId}&rfpId=${rfpId}`)
            .then(resp => {
                // console.log(resp.data.response);
                setRFPViewData(resp.data.response);
                setRfpDetails(resp.data.response.rfpDetails)
                setRfpAttachments(resp.data.response.rfpAttachments)
                // console.log(resp.data.response.rfpAttachments);
                setRfpExperience(resp.data.response.rfpExperience)
                setRFPIndustryExperienceInfo(resp.data.response.rfpIndustryExp)
                // logger.verbose("RFP Details", "RFPViewEnterprise Component", "getRFPDetails", "GET", "Get RFP Details API Response Successfull", JSON.stringify(resp.data.response));
            }).catch(error => {
                console.log(error);
                // logger.error("RFP Details", "RFPViewEnterprise Component", "getRFPDetails", "GET", "Get RFP Details API Response Failed",JSON.stringify(error));
            })
    }

    useEffect(() => {
        getRFPDetails(enterpriseId, userId, rfpId);

    }, []);
    // var response=getRFPDetails(enterpriseId,userId,rfpId)

    const urlheader = location.pathname.split('/')[1];
    // console.log(rfpviewdata)
    // console.log((rfpDetails.rfpTitle))

    return (


        <div className="container-scroller">
            <Header entity={urlheader} />
            <div className="container-fluid page-body-wrapper">
                <EnterpriseSideBar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="col-12">
                                    <div className="cardPad card-body border border-radius">
                                        <div className="card-header">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <nav className="navbar navbar-expand bg-white">
                                                        <ul className="navbar-nav">
                                                            <li className="nav-item bold">
                                                                <h3 className="fw-bold mb-0">{t('viewRFPDetails')}</h3>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                                <div>
                                                    <div>
                                                        <div className="d-flex align-items-center float-left text-nowrap">
                                                            {urlheader == "enterprise" ? <Link to="/enterprise/manager/rfp/manage" className="btn btn-primary btn-sm add-client btn-icon-text text-nowrap me-3" state={{ type: "all" }}><i className="fa fa-arrow-left btn-icon-prepend"></i>{t('backToRFP')}</Link> : <Link to="/cxo/cxo/rfp/manage" className="btn btn-primary btn-sm add-client btn-icon-text text-nowrap me-3" state={{ type: "all" }}><i className="fa fa-arrow-left btn-icon-prepend"></i>{t('backToRFP')}</Link>}
                                                            {/* <Link to="/cxo/cxo/rfp" className="btn btn-primary btn-sm add-client btn-icon-text text-nowrap me-3" state={{ type: "all" }}>Back to RFP</Link> */}
                                                            {/* <a className="btn btn-primary btn-sm add-client btn-icon-text text-nowrap me-3" href="activerequest.html"><i className="fa-solid fa-arrow-left btn-icon-prepend"></i>Back to RFP</a> */}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="col-12 col-md-1"></div>
                                                <div className="col-12 col-md-10">
                                                    <div className="row">
                                                        <div className="card overflow-hidden mb-4">
                                                            <div className="card overflow-hidden1  mb-0">
                                                                <div className="left-col-deatil col ">
                                                                    {rfpStatus == 5 ?
                                                                        <Link to={`/enterprise/manager/rfp/edit/${rfpId}`}><i class="fa fa-pencil fa-lg float-end text-muted pt-4 pr_30" title="Edit RFP" data-bs-toggle="modal" data-bs-target="#Industry"></i></Link>
                                                                        : ''
                                                                    }
                                                                    <ul className="job-detail-des">
                                                                        <li className="text-dark">
                                                                            <span>{t('title')}:&nbsp;&nbsp;&nbsp;{rfpDetails.rfpTitle}</span>
                                                                        </li>
                                                                        <li className="text-dark"><span>{t('requirement')}:</span></li>
                                                                        <li className="text-dark">{rfpDetails.rfpRequirement}
                                                                        </li>
                                                                        <li className="text-dark">
                                                                            <span >{t('inscopeItems')}:</span>
                                                                        </li>
                                                                        <li>
                                                                        </li>
                                                                        <li className="text-dark">{rfpDetails.rfpInScope}
                                                                        </li>
                                                                        <li className="text-dark">
                                                                            <span > {t('outscopeItems')}:</span>
                                                                        </li>
                                                                        <li className="text-dark">{rfpDetails.rfpOutScope}
                                                                        </li>
                                                                        <li className="text-dark">
                                                                            <span >{t('budget')} :</span><b >{t('minimumPrice')}</b>: ${rfpDetails.rfpMinBudget} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <b>{t('maximumPrice')}</b>: ${rfpDetails.rfpMaxBudget}
                                                                        </li>
                                                                        <li className="text-dark">
                                                                            <span >
                                                                                {t('startDate')}
                                                                                :
                                                                            </span >{rfpDetails.rfpStartDate}
                                                                        </li>
                                                                        <li className="text-dark">
                                                                            <span>
                                                                                {t('quoteDueDate')}
                                                                                :
                                                                            </span>{rfpDetails.rfpQuoteDueDate}
                                                                        </li>

                                                                        <li>
                                                                            <span className="text-dark">
                                                                                {t('caasMemberCode')}
                                                                                :
                                                                            </span>{rfpDetails.cxoUser}
                                                                            {/* CIO-01, CIO-02 */}
                                                                        </li>
                                                                        <li>
                                                                            <h3 className="fw-bold mt-4 h4 text-dark">{t('attachments')}</h3>
                                                                        </li>
                                                                        <li>
                                                                            {
                                                                                rfpAttachments.length != 0 ? (
                                                                                    rfpAttachments.map((ele) => (
                                                                                        <div className="mt-0 pb-1">

                                                                                            <label className="text-gray mb-2 mt-2 pb-3 border-bottom d-block "> <i className="fa-solid fa-file-word fa-lg"></i> <a href={ele?.attachmentFile} target="_blank">{ele?.attachmentFile?.split("/")[3]?.split(S3Config.S3ObjectDelimiter)[0]}</a></label>

                                                                                        </div>
                                                                                    ))
                                                                                ) : (
                                                                                    <p>{t('noRecordsAvailable')}.</p>
                                                                                )
                                                                            }


                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="d-flex justify-content-between align-items-center px-2 mt-4">
                                                                    <h3 className="m-0 fw-bold fs-5">{t('industryAndTheExperience')}</h3>
                                                                </div>
                                                                <div data-plugin="lazyload" className="lazyHead border-top pt-4 mt-4" id="lazyKeySkills" data-loaded="true">
                                                                    <div className="keySkills">
                                                                        <ul className="job-detail-des d-flex">
                                                                            <li>
                                                                                <b className="text-dark">
                                                                                    {t('industryExperience')}:
                                                                                </b>
                                                                            </li>
                                                                            <li>
                                                                                <b>
                                                                                    {rfpDetails.rfpIndustryExperience} {t('years')}
                                                                                </b>

                                                                            </li>
                                                                        </ul>
                                                                        <div className="card">

                                                                            <div className="cardPad">
                                                                                <div className="widgetHead">
                                                                                <span className="widgetTitle"> {t('industry')} </span>
                                                                                    <div className="cardPad card-body box-shadow pb_30">
                                                                                        {
                                                                                            rfpindustryExperienceInfo.length != 0 ? (
                                                                                                rfpindustryExperienceInfo.map((ele) => (
                                                                                                    <button type="button" className="btn industry-list btn-outline-secondary btn-sm ml_20" value={ele.industryId}> {ele.industryname} </button>
                                                                                                ))
                                                                                            ) : (
                                                                                                <p>{t('noRecordsAvailable')}</p>
                                                                                            )
                                                                                        }
                                                                                    </div>

                                                                                </div>
                                                                                <div className="widgetCont">
                                                                                    <div className="prefill">



                                                                                        {/* <span className="chip" title="html">Manufacturing</span>
                                                                                <span className="chip" title="css">Banking</span>
                                                                                <span className="chip" title="Dreamweaver">Insurance</span>
                                                                                <span className="chip" title="Photoshop">Airline</span>
                                                                                <span className="chip" title="jQuery">Pharma</span>
                                                                                <span className="chip" title="Javascript">Healthcare</span> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div data-plugin="lazyload" className="lazyHead border-top pt-4 mt-4" id="lazyResumeHead" data-loaded="true">
                                                                    <div className="resumeHeadline text-right">
                                                                        <div className="d-flex justify-content-between align-items-center">
                                                                            <b className="wigettitle h4 fw-bold text-dark">{t('skills')}</b>
                                                                        </div>
                                                                        {
                                                                            rfpExperience != 0 ? (
                                                                                rfpExperience.map((ele) => (
                                                                                    <>
                                                                                        <div className="card mt15">
                                                                                            <div className="cardPad">
                                                                                                <div className="table-content-skills">
                                                                                                    <div className="widgetCont" key={ele.expid}>
                                                                                                        <div className="prefill">
                                                                                                            <div className="text-left text-dark"><b className="float-start">{t('skill')}:</b> <span>{ele.experienceSkill}</span></div>
                                                                                                        </div>
                                                                                                        <div className="prefill">
                                                                                                            <div className="text-left text-dark"><b className="float-start">{t('softwareVersion')}:</b> <span>{ele.experienceSoftwareVersion}</span></div>
                                                                                                        </div>
                                                                                                        <div className="prefill">
                                                                                                            <div className="text-left text-dark"><b className="float-start">{t('lastUsed')}:</b> <span>{ele.experienceLastUsed}</span></div>
                                                                                                        </div>
                                                                                                        <div className="prefill">
                                                                                                            <div className="text-left text-dark"><b className="float-start">{t('experience')}:</b> <span>{ele.experienceYears} {t('years')}</span></div>
                                                                                                        </div>
                                                                                                        <div className="prefill">
                                                                                                            <div className="text-left text-dark"><b className="float-start">{t('implementationSize')}</b> {ele.experienceImplementationSize} {t('users')}</div>
                                                                                                        </div>
                                                                                                        <div className="prefill">
                                                                                                            <div className="text-left text-dark"><b className="float-start">{t('experienceLevel')}:</b> {ele.experienceLevelName}</div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                ))
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>

            </div>
        </div>

    )
}

export default RFPViewEnterprise