import React from 'react'
import Header from '../../../commons/components/Header';
import CaaSSideBar from '../../../commons/components/SideBars/CaaSSideBar';
import { API } from '../../../config/APIConfig';
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import swal from 'sweetalert';
import $ from "jquery";
const ENTERPRISEDETAILS = `${API.URL}api/v1/enterprise/view/details`;
const UPDATEENTERPRISESTATUSAPI = `${API.URL}api/v1/admin/updateenterprisestatus`;
const UPDATEENTERPRISEAPPROVALSTATUSAPI = `${API.URL}api/v1/admin/updateenterpriseapproval`;
const RESETPASSWORDAPI = `${API.URL}api/v1/auth/password/reset`;
const EnterpriseView = () => {
    const [enterprisedetails, SetEnterpriseDetails] = useState([]);
    const [resetUserDetails, setResetUserDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const params = useParams()
    const enterpriseId = params.enterpriseId;
    useEffect(() => {
        getEnterpriseDetails();
    }, []);
    const getEnterpriseDetails = () => {
        setLoading(true);
        axios.get(`${ENTERPRISEDETAILS}?enterpriseId=${enterpriseId}`).then(response => {
            if (response.data.status === 200) {
                SetEnterpriseDetails(response.data.response);
                setLoading(false);
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    const updateEnterpriseApprovalStatus = (enterpriseId, approval) => {

        const searchedenterprise = enterprisedetails.filter((ele) => ele?.enterpriseId == enterpriseId);
        if (searchedenterprise.length != 1)
            return
        var { enterpriseName, emailAddress, keyContact, entityId, roleId ,keyContactEmail} = searchedenterprise[0];
        var isApproved = approval == 0 ? 1 : 0;
        let updobj = { enterpriseId, entityId, emailAddress, roleId, enterpriseName, keyContact, isApproved ,keyContactEmail}
        console.log(UPDATEENTERPRISEAPPROVALSTATUSAPI, updobj)
        axios.put(UPDATEENTERPRISEAPPROVALSTATUSAPI, updobj)
            .then(response => {
                if (response.data.status === 200) {
                    getEnterpriseDetails();
                    swal("Approved Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }
    const resetEnterpriseStatus = (enterpriseId, curStatus) => {
        var status = curStatus == 0 ? 1 : 0;
        updateEnterpriseDetails(enterpriseId, status);
    }
    const updateEnterpriseDetails = (enterpriseId, status) => {
        const searchedenterprise = enterprisedetails.filter((ele) => ele?.enterpriseId == enterpriseId);
        if (searchedenterprise.length != 1)
            return
        console.log("Searched Enterprise", searchedenterprise[0])
        var { enterpriseName, entityId, roleId } = searchedenterprise[0];
        let updobj = { enterpriseId, entityId, roleId, enterpriseName, status }
        console.log(UPDATEENTERPRISESTATUSAPI, updobj)
        axios.put(UPDATEENTERPRISESTATUSAPI, updobj)
            .then(response => {
                if (response.data.status === 200) {
                    getEnterpriseDetails();

                    swal("Updated Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }
    const bindUserDetailsForReset = (enterpriseId) => {
        document.getElementById("resetpasswordform").reset();
        const searchedenterprise = enterprisedetails.filter((ele) => ele?.enterpriseId == enterpriseId);
        if (searchedenterprise.length != 1)
            return
        setResetUserDetails([...searchedenterprise]);
        document.getElementById("resetUserName").value = searchedenterprise[0]?.keyContact;
    }
    const handleKeyPress = (e) => {
        if (e.charCode === 32 && e.key === " ") {
          e.preventDefault();
        }
      }
    const resetUserPassword = () => {
        $("#resetPasswordbtn").addClass("disabled");
        const userName = document.getElementById("resetUserName").value;
        const userNewPassword = document.getElementById("resetEnterprisePassword").value;
        console.log("Before Username", userNewPassword)
        if (userName.length > 0 && userNewPassword.length > 0) {

            const { enterpriseId, emailAddress, userId, entityId, roleId, keyContact, keyContactEmail } = resetUserDetails[0];

            console.log("reset user details", resetUserDetails[0])
            if (userName === keyContact) {
                let updobj = { enterpriseId, emailAddress, userId, entityId, roleId, userName: keyContact, userNewPassword, keyContactEmail };
                console.log(RESETPASSWORDAPI, updobj);
                axios.put(RESETPASSWORDAPI, updobj)
                    .then(response => {
                        if (response.data.status === 200) {
                            swal("Password was Reset Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                            $("#ResetPassword .close").trigger('click');
                            $("#resetPasswordbtn").removeClass("disabled");
                            document.getElementById("resetpasswordform").reset();
                            getEnterpriseDetails();
                        }
                        $("#resetPasswordbtn").removeClass("disabled");
                    })
                    
                    .catch(error => {
                        console.error('There was an error!', error);
                        $("#resetPasswordbtn").removeClass("disabled");
                    });
            }
        }
        else {
            swal("Please enter the password", "", "error", { closeOnClickOutside: false, closeOnEsc: false });
            $("#resetPasswordbtn").removeClass("disabled");
        }
    }
    return (
        <>
        <div className="container-scroller">
            <Header entity="CaaS" />
            <div className="container-fluid page-body-wrapper">
                <CaaSSideBar />
                {loading ? (
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                ) : (
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-12 grid-margin stretch-card">
                                    <div className="col-12">
                                        <div className="grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body pb-0">
                                                    <h4 className="card-title">
                                                        Enterprise Details
                                                    </h4>
                                                    <Link className="text-blue fs-5 float-sm-right" to="/caas/global/enterprise/manage"><span className=" mdi mdi-arrow-left"></span>Back to Enterprises</Link>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-body">
                                                {/* <div className="row">
                                                <div className="col-12 col-md-1"></div>
                                                <div className="col-12 col-md-10">
                                                    <div className="row">
                                                        <div className="card overflow-hidden mb-4">
                                                            <div className="card overflow-hidden1  mb-0">
                                                                <div className="left-col-deatil col ">
                                                                    <ul className="job-detail-des">
                                                                        <li className="text-dark">
                                                                            <span>Enterprise Name :&nbsp;&nbsp;&nbsp;""</span>
                                                                        </li>
                                                                        <li className="text-dark"><span>Enterprise Location:&nbsp;&nbsp;&nbsp;""</span></li>                                                                      
                                                                        <li className="text-dark">
                                                                            <span >Enterprise PM:&nbsp;&nbsp;&nbsp;""</span>
                                                                        </li>                                                                      
                                                                        <li className="text-dark"> <span >Enterprise Email:&nbsp;&nbsp;&nbsp;""</span>
                                                                        </li>
                                                                        <li className="text-dark">
                                                                            <span > Enterprise Contact:&nbsp;&nbsp;&nbsp;""</span>
                                                                        </li>                                                                    
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                                <div class="mt-0 text-left box-shadow p-4 cardPad card-body border border-radius">
                                                    <div class="d-flex justify-content-between align-items-start RFP_Details">
                                                        {enterprisedetails.length != 0 ? (
                                                            enterprisedetails.map((ele, index) => (
                                                                < div class="list-item-details" key={index}>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5 text-dark">Enterprise Name</div>
                                                                        <div class="d-table-cell">{ele.enterpriseName}</div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">Location</div>
                                                                        <div class="d-table-cell">{ele.address}</div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">Enterprise PM</div>
                                                                        <div class="d-table-cell">{ele.keyContact}</div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">Enterprise Email</div>
                                                                        <div class="d-table-cell">{ele.keyContactEmail}</div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">Key Contact</div>
                                                                        <div class="d-table-cell">{ele.phoneNumber}</div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">City</div>
                                                                        <div class="d-table-cell">{ele.cityName}</div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">State</div>
                                                                        <div class="d-table-cell">{ele.stateName}</div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">Country</div>
                                                                        <div class="d-table-cell">{ele.countryName}</div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">Zip Code</div>
                                                                        <div class="d-table-cell">{ele.zipCode}</div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">Status</div>
                                                                        <div class="d-table-cell">
                                                                            <td className="text-center">
                                                                                <div className="d-flex">
                                                                                    <label htmlFor="site_state" className="form-check-label">In Active</label>
                                                                                    <div className="form-switch form-check ms-2 margin-0">
                                                                                        <input type="checkbox" className="form-check-input" id="site_state" onChange={() => {
                                                                                            resetEnterpriseStatus(ele?.enterpriseId, ele?.status, ele?.status, ele?.status)
                                                                                        }} checked={ele?.status == 1 ? 'checked' : ''} />
                                                                                    </div>
                                                                                    <label htmlFor="site_state" className="form-check-label">Active</label>
                                                                                </div>
                                                                            </td>



                                                                        </div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">Approval Status</div>
                                                                        <div class="d-table-cell">
                                                                            {ele?.isApproved == 1 ? "Approved" : <div className="form-switch form-check  margin-0 text-center">
                                                                                <input type="checkbox" className="form-check-input text-center" id="site_state" onChange={() => {
                                                                                    updateEnterpriseApprovalStatus(ele?.enterpriseId, ele?.isApproved)
                                                                                }} />
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">Reset Password</div>
                                                                        <div class="d-table-cell">
                                                                            {ele?.isApproved == 1 ?
                                                                                <a className="btn addindustrybtn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#ResetPassword" title="Reset Password"
                                                                                    onClick={() => { bindUserDetailsForReset(ele?.enterpriseId) }}
                                                                                >        Reset Password
                                                                                </a>
                                                                                : "NA"
                                                                            }


                                                                        </div>
                                                                    </div>
                                                                </div>))
                                                        ) : <span>No Enterprise Data Available</span>
                                                        }
                                                    </div>
                                                    <div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="modal fade show" id="ResetPassword" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">Reset Password </h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="resetpasswordform">

                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">User Name </label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="resetUserName" disabled />

                                    </div>

                                    <label className="col-sm-4 col-form-label mt_10">Password </label>
                                    <div className="col-sm-8 mt_10">
                                        <input type="text" className="form-control" id="resetEnterprisePassword" onKeyPress={handleKeyPress} />

                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">Cancel</a>
                            <a type="button" className="btn btn-warning btn-md next-btn" id="resetPasswordbtn"
                                onClick={resetUserPassword}
                            >Reset</a>

                        </div>
                    </div>
                </div>
            </div>
        </div >
        </>
    )
}

export default EnterpriseView
