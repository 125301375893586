import { React, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { API } from '../../../config/APIConfig';
import logger from '../../../utils/logger';
const CONNECTIONREQUESTCOUNT = `${API.URL}api/v1/social/connection/requestcount`;
const SocialFeedNavigations = () => {
    const [connectionCount, setConnectionCount] = useState(0);
    const loggedInUserId = sessionStorage.getItem("userId");
    const curuserRoleId = sessionStorage.getItem("roleId");
    const curUserEntityId = sessionStorage.getItem("entityId");
    const getConnectionRequestCount = () => {
        logger.verbose("Connection Request", "SocialFeedNavigations Component", "getConnectionRequestCount", "GET", "Get Connection Request count API Hit", "");
        axios.get(`${CONNECTIONREQUESTCOUNT}?userId=${loggedInUserId}&userEntityId=${curUserEntityId}&userRoleId=${curuserRoleId}`)
            .then(resp => {
                if (resp.data.status === 200) {
                    setConnectionCount(resp.data.response);
                    logger.verbose("Connection Request", "SocialFeedNavigations Component", "getConnectionRequestCount", "GET", "Get Connection Request count API Response Successfull", JSON.stringify(resp.data.response));
                }

            }).catch(error => {
                console.log(error);
                logger.error("Connection Request", "SocialFeedNavigations Component", "getConnectionRequestCount", "GET", "Get Connection Request count API Response Failed!", JSON.stringify(error));
            })
    }
    useEffect(() => {
        getConnectionRequestCount();
    }, [connectionCount])
    return (
        <div className="box-home box_shadow_1 icon-box px-4 menu-container">
            <Link to="/cxo/cxo/wall/personal" className="text-black-50 d-block mb-4" id="homeLink">
                <i className="fa-sharp fa-solid fa-house me-3"></i>Home
            </Link>
            <Link to="/cxo/cxo/myconnections" className="text-black-50 d-block mb-4" id="myConnectionsLink">
                <span className="position-relative me-3">
                    <i className="fa-regular fa-user"></i>
                    <span className="position-absolute top-0 left-1 start-100 translate-middle badge rounded-pill bg-primary" id="connectionCount">{connectionCount}</span>
                </span>My Connections
            </Link>
            <Link to="/cxo/cxo/caasmembers" className="text-black-50 d-block mb-4" id="caasMemberLink">
                <i className="fa-solid fa-globe me-3"></i>
                {/* <i className="fa-solid fa-users me-3"></i> */}
                CAAS Members
            </Link>
            <Link to="/cxo/cxo/grouplist" className="text-black-50 d-block mb-4" id="groupsLink">

                <i className="fa-solid fa-users me-3"></i>
                Groups
            </Link>
            <Link to="/cxo/cxo/myconversations" className="text-black-50 d-block mb-4" id="conversationsLink">

                <i className="fa-regular fa-comments me-3"></i>
                Conversations
            </Link>
            <Link to="/cxo/cxo/notifications" className="text-black-50 d-block" id="notificationLink" >
                <span className="position-relative me-3"><i className="fa-regular fa-bell"></i>
                    <span className="position-absolute top-0 left-1 start-100 translate-middle badge rounded-pill bg-primary" id="groupPartReqs">
                        0
                    </span>
                </span>Notifications</Link>
        </div>
    )
}

export default SocialFeedNavigations