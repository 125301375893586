import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_CAAS_APIKEY_STG,
  authDomain: process.env.REACT_APP_CAAS_AUTHDOMAIN_STG,
  database: process.env.REACT_APP_CAAS_DATABASE_STG,
  projectId: process.env.REACT_APP_CAAS_PROJECTID_STG,
  storageBucket: process.env.REACT_APP_CAAS_STORAGEBUCKET_STG,
  messagingSenderId: process.env.REACT_APP_CAAS_MESSAGINGSENDERID_STG,
  appId: process.env.REACT_APP_CAAS_APPID_STG
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
