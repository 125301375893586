import React, { useEffect, useState } from "react";

import "../../../assets/fontawesome6/css/all.min.css";
import "../../../assets/css/materialdesignicons.min.css"
import "../../../assets/css/themify-icons.css"
import "../../../assets/css/style.css"
import "../../../assets/css/select2-bootstrap.min.css"
import "../../../assets/css/select2.min.css"
import "../../../assets/css/custom.css"
import "../../../assets/css/userend.css"
import "../../../assets/css/responsive.css"
import SocialHeader from './SocialHeader';
import SocialFeedNavigations from './SocialFeedNavigations';
import CAASWorkspaceNavigations from './CAASWorkspaceNavigations';
import CompanyAnnouncements from './CompanyAnnouncements';
import AddToFeed from './AddToFeed';
import profilepic from "../../../assets/images/noav.jpg";
import Connections from './Connections';
import { isRegistered, Login, Register } from '../../../libs/firebase/firebase';
import { auth, db, storage } from "../../../config/firebaseConfig";
import { collection, query, where, onSnapshot, orderBy, getDoc, doc, updateDoc, setDoc, addDoc, Timestamp } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import ConversationUsers from "./ConversationUsers";
// import Conversations from "./Conversations";
import axios from 'axios';
import { API } from '../../../config/APIConfig';
import $ from "jquery"
const CAASMEMBERCONNECTIONS = `${API.URL}api/v1/social/caasmember/connections`;
const MyConversations = () => {
    const curUserFirstName = sessionStorage.getItem("firstname");
    const curUserLastName = sessionStorage.getItem("lastname");
    const curUserEmail = sessionStorage.getItem("userEmail");
    const curUserRoleId = sessionStorage.getItem("roleId");
    const profilePicture = sessionStorage.getItem("profilePicture");
    const curUserEntityId = 3;
    const curUserId = sessionStorage.getItem("userId");
    const curUserKey = `conversation-user-${curUserId}-${curUserEntityId}-${curUserRoleId}`;
    const curUserPassword = `fireChat-Pass-${curUserId}-${curUserEntityId}-${curUserRoleId}`;
    const [chatUsers, setChatUsers] = useState([]);
    const [curChatUser, setCurCharUser] = useState({});
    const [text, setText] = useState("");
    const [img, setImg] = useState("");
    const [msgs, setMsgs] = useState([]);
    const [loggedInChatUserUID, setLoggedInChatUserUID] = useState("");

    useEffect(() => {
        var highlightele = document.getElementById("conversationsLink");
        highlightele.classList.remove("text-black-50");
        highlightele.classList.add("text-link");
        enterChat();
        $("html").removeClass();
        $("html").addClass('myconversationsss');
        var screenHeight = $(window).height();
        var box_3 = $(window).height() - $('.header-section').height();
        $(".box_3 .connectionss").attr('style', 'height:' + ((box_3) - 141.5 + 'px !important'));
        var box_4 = $('.box_4 .sticky-top .box_shadow_1:first-child').height() + $('.header-section').height();
         $(".box_4 .sticky-top .box_shadow_1 .companyannouncements1").attr('style', 'height:' + ((screenHeight - box_4) - 180 + 'px !important'));
        var dd =$('.header-section').height();
        $(".box_1 .sticky-top .box_shadow_1 .list-company").attr('style', 'height:' + ((dd) + 'px !important'));
    }, [])
    const enterChat = async () => {
        const userExists = isRegistered(curUserFirstName, curUserEmail, curUserPassword, curUserKey, profilePicture);
        if (userExists) {
            loginChat();
        } else {
            await Register(curUserFirstName, curUserEmail, curUserPassword, curUserKey, profilePicture).then(() => {
                loginChat();
            }).catch(err => console.log(err));
        }
    }
    const loginChat = async () => {
        await Login(curUserEmail, curUserPassword, curUserKey).then(() => {
            setLoggedInChatUserUID(auth.currentUser.uid)
            console.log(auth.currentUser.uid);
            getMemberList(auth.currentUser.uid);
        }).catch(err => console.log(err));
    }
    const getMemberList = (Uid) => {
        const uid = loggedInChatUserUID ? loggedInChatUserUID : Uid;
        const usersRef = collection(db, "tbl_chat_user_master");
        const q = query(usersRef, where("uid", "!=", uid));
        const unsub = onSnapshot(q, (querySnapshot) => {
            let users = [];
            querySnapshot.forEach((doc) => {
                users.push(doc.data());
            });
            // setChatUsers(users);
            bindConnectedhatUsers(users);
        });
    }
    const bindConnectedhatUsers = (users) => {
        axios.get(`${CAASMEMBERCONNECTIONS}?userId=${curUserId}&userEntityId=${curUserEntityId}&userRoleId=${curUserRoleId}`)
            .then(resp => {
                const data = resp.data.response;
                const mapConversationUserNames = data.map(o => ({ ...o, conversation_user_key: `conversation-user-${o.userId}-${o.entityId}-${o.roleId}` }))
                const connectedChatUser = users.filter(el => {
                    return mapConversationUserNames.some(f => {
                        return f.conversation_user_key === el.userKey;
                    });
                });
                setChatUsers([...connectedChatUser]);
            }).catch(error => {
                console.log(error);
            })
    }
    const setThisChat = async (e) => {
        const selectedUserUId = e.currentTarget.id;
        const newChat = chatUsers?.find((ele) => ele.uid == selectedUserUId);
        setCurCharUser({ ...newChat });
        const id = loggedInChatUserUID > selectedUserUId ? `${loggedInChatUserUID + selectedUserUId}` : `${selectedUserUId + loggedInChatUserUID}`;
        const msgsRef = collection(db, "tbl_chat_messages", id, "chat");
        const q = query(msgsRef, orderBy("createdAt", "asc"));
        onSnapshot(q, (querySnapshot) => {
            let msgs = [];
            querySnapshot.forEach((doc) => {
                msgs.push(doc.data());
            });
            setMsgs(msgs);
            console.log("msgs", msgs)
        });
        const docSnap = await getDoc(doc(db, "tbl_chat_last_messages", id));
        if (docSnap.data() && docSnap.data().from !== loggedInChatUserUID) {
            await updateDoc(doc(db, "tbl_chat_last_messages", id), { unread: false });
        }
    }
    const sendMsg = async (e) => {
        e.preventDefault();
        const selectedUserUId = curChatUser.uid;
        const id = loggedInChatUserUID > selectedUserUId ? `${loggedInChatUserUID + selectedUserUId}` : `${selectedUserUId + loggedInChatUserUID}`;
        let url;
        if (img) {
            const imgRef = ref(
                storage,
                `images/${new Date().getTime()} - ${img.name}`
            );
            const snap = await uploadBytes(imgRef, img);
            const dlUrl = await getDownloadURL(ref(storage, snap.ref.fullPath));
            url = dlUrl;
        }

        await addDoc(collection(db, "tbl_chat_messages", id, "chat"), {
            text,
            from: loggedInChatUserUID,
            to: selectedUserUId,
            createdAt: Timestamp.fromDate(new Date()),
            media: url || "",
        });
        await setDoc(doc(db, "tbl_chat_last_messages", id), {
            text,
            from: loggedInChatUserUID,
            to: selectedUserUId,
            createdAt: Timestamp.fromDate(new Date()),
            media: url || "",
            unread: true,
        });
        setText("");
        setImg("");
    }
    return (
        <>
            <div className="main-body-section main-body-section-social">
                <SocialHeader />
                <section className="body-section">
                    <div className="body-content container-fluid">
                       <div className="row1 myconversations1">
                            <div className="col_res1 align-items-stretch box_1 w_171">
                                <div className="sticky-top">
                                    <SocialFeedNavigations />
                                    <AddToFeed />
                                </div>
                            </div>

                            {
                                Object.keys(curChatUser).length === 0 && curChatUser.constructor === Object ?
                                    (
                                        <div className="mob-remove-padding w_461">
                                            <div className="box-home box_shadow_1 p-4">
                                                <div className="d-flex justify-content-start align-items-center mb-0 ps-3">
                                                    {/* <img src={curChatUser?.profilePicture ? curChatUser?.profilePicture : profilepic} alt="CAAS Member" className="img-fluid img-jobs" /> */}
                                                    <div className="ps-3">
                                                        <p className="mb-0 fw-bold">
                                                            <span className="fw-bold fs-5">Please choose a connection and start conversation</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    : <>
                                        <div className="mob-remove-padding w_461">
                                            <div className="box-home box_shadow_1 p-2 chatconvo">
                                                <div className="d-flex justify-content-start align-items-center mb-0 ps-4">
                                                    <img src={curChatUser?.profilePicture ? curChatUser?.profilePicture : profilepic} alt="CAAS Member" className="img-fluid img-jobs" />
                                                    <div className="ps-3">
                                                        <p className="mb-0 fw-bold d-flex">
                                                            <span className="fw-bold fs-5">{curChatUser?.name}</span>
                                                            {/* <i className="fa-sharp fa-solid fa-circle-dot fa-xs text-green px-2"></i> */}
                                                            <span className={`text-green px-2 fs_25 ${curChatUser?.isOnline ? "" : "d-none"}`}>●</span>
                                                            <span className="text-black-50 small text-white">{curChatUser?.isOnline ? "Online" : "Offline"}</span></p>
                                                        <p className="mb-0 text-black-50 fs-6 small">{curChatUser?.profileTitle}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box-home box_shadow_1 height_79 px-4  custom-scroll chatconvoradius">
                                                {msgs.length
                                                    ? msgs.map((msg, i) => (
                                                        <>
                                                            {
                                                                msg?.from === loggedInChatUserUID ? (<div className="row">
                                                                    <div className="col-md-6"></div>
                                                                    <div className="col-md-6">
                                                                        <div className="d-flex justify-content-end align-items-end flex-row">
                                                                            <div className="pe-3 w-100 mb_10">
                                                                                <div className="chat-box">
                                                                                    <p className="m-0">{msg?.text}</p>
                                                                                </div>
                                                                                {/* <div className="d-flex"><p className="text-black-50 small">7:45 AM</p></div> */}
                                                                            </div>
                                                                            <img src={profilePicture ? profilePicture : profilepic} alt="CAAS Member" className="img-fluid img-jobs-chat" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                    //     <div className="row">
                                                                    //     <h4 className="text-black-50 text-center pb-2 border-bottom mt-3">Today</h4>
                                                                    // </div>
                                                                ) : (<div className="row mt-4">
                                                                    <div className="col-md-6">
                                                                        <div className="d-flex justify-content-start align-items-end flex-row">
                                                                            <img src={curChatUser?.profilePicture ? curChatUser?.profilePicture : profilepic} alt="CAAS Member" className="img-fluid img-jobs-chat" />
                                                                            <div className="ps-3 w-100 mb_10">
                                                                                <div className="chat-box1">
                                                                                    <p className="m-0">{msg?.text}</p>
                                                                                </div>
                                                                                {/* <div className="d-flex"><p className="text-black-50 small">5:10 AM</p></div> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6"></div>
                                                                </div>)
                                                            }
                                                        </>
                                                    ))
                                                    : null}
                                            </div>
                                            <div className="box-home box_shadow_1 px-4 py-2 mt-4">
                                                <div className="d-flex justify-content-between">
                                                    <div className="cursor">
                                                        <input type="text" className="form-control border-0 ps-0 w-375" placeholder="write a message..." value={text}
                                                            onChange={(e) => setText(e.target.value)} />
                                                        <i></i>
                                                    </div>
                                                    <div>
                                                        <a href="#" className="text-black-50 me-2">

                                                            {/* <input id="post-image-file-input" className="hiddenInputFile" type="file" accept="image/*" onChange={(e) => setImg(e.target.files[0])} />
                                                <label className="text-black-50 m-0 me-3 uploadFileIcon" htmlFor="post-image-file-input" >
                                                    <i className="fa-regular fa-image"></i>
                                                </label> */}
                                                        </a>
                                                        {/* <a href="#" className="text-black-50  me-2">
                                                <label htmlFor="file1">
                                                    <span className="mdi mdi-attachment fa-lg text-black-50"></span>
                                                </label>
                                            </a>
                                            <a href="#" className="text-black-50 me-2">
                                                <label htmlFor="file2">
                                                    <span className="mdi mdi-file-gif-box mdi-18px text-black-50"></span>
                                                </label>
                                            </a>
                                            <a href="#" className="text-black-50">
                                                <label htmlFor="file3">
                                                    <i className="fa-regular fa-face-smile fa-sm"></i>
                                                </label>
                                            </a> */}
                                                        <a href="#" className="text-black-50 send-btn" onClick={sendMsg}>
                                                            <i className="fa-regular icon-cursor rotate-45"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </>
                            }


                            {/* <Conversations /> */}
                            {/* <Connections /> */}

                            <div className="col_res1 align-items-stretch mob-remove-pe box_3 w_171">
                                <div className="box-home box_shadow_1 px-4 sticky-top">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h3 className="fw-bold fs-4 mb-0">Conversations</h3>
                                        {/* <div>
                                            <i
                                                className="fa-solid fa-ellipsis text-black-50 float-end fa-md ms-3"
                                            ></i>
                                            <i
                                                className="fa-regular fa-square-plus float-end text-black-50"
                                            ></i>
                                        </div> */}
                                        <div>
                                            <i className="fa-solid fa-circle-info" title="Choose a connection to have 1-1 conversation"></i>
                                        </div>
                                    </div>
                                    <div className="row no-gutters mt-4">
                                        <div className="col">
                                            <input
                                                className="form-control border-secondary border-right-0 rounded-0 search-input"
                                                type="search"
                                                placeholder="Search Messaging"
                                                id="example-search-input5"
                                            />
                                        </div>
                                        <div className="col-auto">
                                            <button
                                                className="btn btn-outline-secondary border-left-0 rounded-0 rounded-right search-btn"
                                                type="button"
                                            >
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='connectionss'>

                                    {chatUsers.length != 0 ? chatUsers.map((ele, index) => (
                                        <ConversationUsers thisUser={ele} setThisChat={setThisChat} loggedInChatUserUID={loggedInChatUserUID} />
                                    )) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="col_res1 align-items-stretch box_4 w_171">
                                <div className="sticky-top">
                                    <CAASWorkspaceNavigations />
                                    <CompanyAnnouncements />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="footer-sections"></section>
            </div >

        </>
    )
}

export default MyConversations
