import React from 'react'
import { Link } from 'react-router-dom'

const VendorSideBar = () => {
    return (
        <nav className="sidebar sidebar-offcanvas">
            <ul className="nav">
                <li className="nav-item title-card" id="RFP_reg">
                    <Link to="/vendor/admin/dashboard" className="nav-link text-center pl_2 br-btm">
                        <span className="menu-title2 sidebar-title main-title fw-bold">Vendor</span>
                    </Link>
                </li>
                <li className="nav-item" id="vendor_wall">
                    <Link to="/vendor/wall" className="nav-link">
                        <i className="mdi mdi-view-dashboard menu-icon"></i>
                        <span className="menu-title2 sidebar-title main-title fw-bold">Ad Space</span>
                    </Link>
                </li>
                {/* <li className="nav-item" id="dashboardSidNav">
                    <a href="#" className="nav-link">
                        <i className="mdi mdi-view-dashboard menu-icon"></i>
                        <span className="menu-title1 sidebar-title">Vendor Profile</span>
                    </a>
                </li> */}
            </ul>
        </nav>
    )
}

export default VendorSideBar