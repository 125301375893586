import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import $ from "jquery";

const CaaSSideBar = () => {
    const { i18n, t } = useTranslation(["common"]);
    return (
        <>
            <nav className="sidebar sidebar-offcanvas">
                <ul className="nav" id="caas_sidebar">
                    <li className="nav-item title-card" id="RFP_reg">
                        <a className="nav-link text-center pl_2 br-btm" href="#">
                            <span className="menu-title2 sidebar-title main-title fw-bold">{t('caasAdmin')}</span>
                        </a>
                    </li>
                    <li className="nav-item" id="caas_dasboard_li">
                        <Link to="/caas/global/dashboard" className="nav-link">
                            <i className="mdi mdi-view-dashboard menu-icon"></i>
                            <span className="menu-title1 sidebar-title">{t('dashboard')}</span>
                        </Link>
                    </li>
                    {/* <li className="nav-item">
                        <Link to="/caas/enterprise" className="nav-link">
                            <i className="mdi mdi-pencil-box-outline menu-icon"></i>
                            <span className="menu-title2 sidebar-title">{t('enterprises')}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/caas/global/rfp" className="nav-link">
                            <i className="mdi mdi-pencil-box-outline menu-icon"></i>
                            <span className="menu-title2 sidebar-title">{t('rfp')}</span>
                        </Link>
                    </li> */}

                    <li className="nav-item sidebarmenu" id="caas_members_li">
                        <Link to="/caas/global/cxo/manage" className="nav-link" >
                            <i className="mdi mdi-account-multiple menu-icon"></i>
                            <span className="menu-title4 sidebar-title">CAAS Members</span>
                        </Link>
                    </li>
                    <li className="nav-item sidebarmenu" id="caas_enterprises_li">
                        <Link to="/caas/global/enterprise/manage" className="nav-link" >
                            <i className="fas fa-building fa-lg menu-icon"></i>
                            <span className="menu-title4 sidebar-title">Enterprises</span>
                        </Link>
                    </li>
                    <li className="nav-item sidebarmenu" id="caas_vendors_li">
                        <Link to="/caas/global/vendor/manage" className="nav-link">
                            <i className="far fa-user-circle  fa-lg menu-icon"></i>
                            <span className="menu-title2 sidebar-title">Vendors</span>
                        </Link>
                    </li>
                    <li className="nav-item sidebarmenu" id="caas_languages_li">
                        <Link to="/caas/global/languages" className="nav-link" >
                            <i className="fa fa-language fa-lg menu-icon"></i>
                            <span className="menu-title4 sidebar-title">Languages</span>
                        </Link>
                    </li>
                    <li className="nav-item sidebarmenu" id="caas_projectareas_li">
                        <Link to="/caas/global/areaofproject" className="nav-link" >
                            <i className="mdi mdi-table-edit menu-icon"></i>
                            <span className="menu-title4 sidebar-title">Project Areas</span>
                        </Link>
                    </li>

                    <li className="nav-item sidebarmenu" id="caas_education_li">
                        <Link to="/caas/global/education" className="nav-link" >
                            <i className="mdi mdi-book-open menu-icon"></i>
                            <span className="menu-title4 sidebar-title">Education</span>
                        </Link>
                    </li>

                    <li className="nav-item sidebarmenu" id="caas_industries_li">
                        <Link to="/caas/global/industry" className="nav-link" >
                            <i className="fas fa-industry menu-icon"></i>
                            <span className="menu-title4 sidebar-title">Industries</span>
                        </Link>
                    </li>

                    <li className="nav-item sidebarmenu" id="caas_skills_li">
                        <Link to="/caas/global/manageskill" className="nav-link" >
                            <i className=" mdi mdi-chart-bar menu-icon"></i>
                            <span className="menu-title4 sidebar-title">Skills & Versions</span>
                        </Link>
                    </li>

                    {/* <li className="nav-item">
                    <a className="nav-link" href="#">
                            <i className="ti-credit-card menu-icon"></i>
                            <span className="menu-title3 sidebar-title">{t('projects')}</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <Link to="/caas/global/role" className="nav-link">
                            <i className="mdi mdi-pencil-box-outline menu-icon"></i>
                            <span className="menu-title2 sidebar-title">{t('roles')}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">
                            <i className=" mdi mdi-chart-bar menu-icon"></i>
                            <span className="menu-title4 sidebar-title">{t('pickList')}</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">
                            <i className=" mdi mdi-chart-bar menu-icon"></i>
                            <span className="menu-title4 sidebar-title">{t('roleAccess')}</span>
                        </a>
                    </li>
                   
                    <li className="nav-item">
                        <a className="nav-link" href="#">
                            <i className=" mdi mdi-chart-bar menu-icon"></i>
                            <span className="menu-title4 sidebar-title">{t('reports')}</span>
                        </a>
                    </li> */}
                </ul>
            </nav>
        </>
    )
}

export default CaaSSideBar