import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";


const EnterpriseSideBar = () => {
    const { i18n, t } = useTranslation(["common"]);

    return (
            <nav className="sidebar sidebar-offcanvas">
                <ul className="nav mt-3">
                    <li className="nav-item title-card" id="RFP_reg">
                        <a className="nav-link text-center pl_2 br-btm" href="#">
                            <span className="menu-title2 sidebar-title main-title fw-bold">{t('enterprisePM')}</span>
                        </a>
                    </li>
                    <li className="nav-item" id="dashboardSidNav">
                        <Link to="/enterprise/manager/dashboard" className="nav-link">
                            <i className="mdi mdi-view-dashboard menu-icon"></i>
                            <span className="menu-title1 sidebar-title">{t('dashboard')}</span>
                        </Link>
                    </li>
                    <li className="nav-item" id="PostRFPSidNav">
                        <Link to="/enterprise/manager/rfp/create" className="nav-link">
                            <i className="mdi mdi-pencil-box-outline menu-icon"></i>
                            <span className="menu-title2 sidebar-title">{t('postRFP')}</span>
                        </Link>
                    </li>
                    <li className="nav-item" id="RFPSidNav">
                        <Link to="/enterprise/manager/rfp/manage" className="nav-link">
                            <i className="mdi mdi-open-in-app menu-icon"></i>
                            <span className="menu-title2 sidebar-title">{t('manageRFP')}</span>
                        </Link>
                    </li>

                    {/* <li className="nav-item">
                        <a className="nav-link" href="#">
                            <i className="ti-credit-card menu-icon"></i>
                            <span className="menu-title3 sidebar-title">{t('projects')}</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">
                            <i className=" mdi mdi-chart-bar menu-icon"></i>
                            <span className="menu-title4 sidebar-title">{t('reports')}</span>
                        </a>
                    </li> */}
                </ul>
            </nav>
        
    )
}

export default EnterpriseSideBar