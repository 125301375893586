import React, { useEffect, useState } from 'react'


import "../../assets/fontawesome6/css/all.min.css";
import "../../assets/css/themify-icons.css"
import "../../assets/css/style.css"
import "../../assets/css/select2-bootstrap.min.css"
import "../../assets/css/select2.min.css"
import "../../assets/css/custom.css"
import "../../assets/css/userend.css"
import "../../assets/css/responsive.css"
import SocialHeader from './commons/SocialHeader'
import SocialFeedNavigations from './commons/SocialFeedNavigations';
import CAASWorkspaceNavigations from './commons/CAASWorkspaceNavigations';
import CompanyAnnouncements from './commons/CompanyAnnouncements';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { quillModules } from '../../utils/quillModules';
import { Toaster } from 'react-hot-toast';
import { successToast, celebrationToast, errorToast } from '../../utils/toastUtil';
import logger from '../../utils/logger';
import AddToFeed from './commons/AddToFeed';
import profilePicture from "../../assets/images/group.png";
import groupDefaultLogo from "../../assets/images/group.png";
import $ from "jquery";
import { API } from '../../config/APIConfig';
import axios from 'axios';
import S3FileUpload from '../../utils/S3FileUpload';
import { S3Config } from '../../config/AWSConfig';
import { useLocation,useParams  } from "react-router-dom";
import GroupPosts from './GroupPosts';
import uuidCustom from '../../utils/uuidCustom';
const GROUPDETAILSAPI = `${API.URL}api/v1/social/group/details`;
const GROUPACCESSAPI = `${API.URL}api/v1/social/group/access`;
const CreatePostAPI = `${API.URL}api/v1/social/post/create`;
const UPDATEGROUPtAPI = `${API.URL}api/v1/social/group/edit`;
const GROUPNAMECHECKAPI = `${API.URL}api/v1/social/group/validate`;
const PARTICIPATEINGROUPAPI = `${API.URL}api/v1/social/group/participate`;

const GroupWall = (props) => {
    const [groupPostContent, setGroupPostContent] = useState("");
    const [groupPostMedia, setGroupPostMedia] = useState([]);
    const [groupDetails, setGroupDetails] = useState([]);
    const [editGroupDetails, setEditGroupDetails] = useState([]);
    const [groupNameExists, setGroupNameExists] = useState(false);
    const [groupAccessInfo, setGroupAccess] = useState([]);
    const [isPrivate, setIsPrivate] = useState(true);
    const [hasAccess, setHasAccess] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const curUserFirstName = sessionStorage.getItem("firstname");
    const curUserLastName = sessionStorage.getItem("lastname");
    const curUserFullName = curUserFirstName + " " + curUserLastName;
    const curUserId = sessionStorage.getItem("userId");
    const curUserRoleId = sessionStorage.getItem("roleId");
    const curUserEntityId = 3;
    const location = useLocation();
   // const groupId = location.state?.groupId;
    const { groupId } = useParams();
    const getGroupAccess = () => {
        axios.get(`${GROUPACCESSAPI}?userId=${curUserId}&userEntityId=${curUserEntityId}&userRoleId=${curUserRoleId}&groupId=${groupId}`)
            .then(resp => {
                setGroupAccess(resp.data.response);
                setIsPrivate(resp?.data?.response?.isPrivate);
                setHasAccess(resp?.data?.response?.isOwner || resp?.data?.response?.isParticipant);
                setIsOwner(resp?.data?.response?.isOwner);
            }).catch(error => {
                console.log(error);
            })
    }
    const getGroupDetails = () => {
        // logger.verbose("Get Group Details", "GroupWall Component", "getGroupDetails", "GET", "Get Group Details API Respone Successfull", `GroupId: ${groupId}`);
        axios.get(`${GROUPDETAILSAPI}?groupId=${groupId}`)
            .then(resp => {
                setGroupDetails(resp.data.response);
                setEditGroupDetails(resp.data.response);
                // logger.verbose("Get Group Details", "GroupWall Component", "getGroupDetails", "GET", "Get Group Details API Respone Successfull", JSON.stringify(resp.data.response));
            }).catch(error => {
                console.log(error);
                logger.error("Get Group Details", "GroupWall Component", "getGroupDetails", "GET", "Get Group Details API Respone Failed", JSON.stringify(error));
            })
    }
    useEffect(() => {
        $("body").css("height", '');
        $("html").removeClass();
        $("html").addClass('groupwall');

    }, [])
    useEffect(() => {
        if (groupPostContent)
            $("#submitPostBtn").removeClass("disabled");
        else
            $("#submitPostBtn").addClass("disabled");
    }, [groupPostContent])
    useEffect(() => {
        getGroupDetails();
        getGroupAccess();
    }, [])


    const fileUpload = async (e) => {    
        const file = e.target.files[0];
        // const fileName = new Date().toISOString() + "|" +  e.target.files[0].name;
        var fileName = e.target.files[0].name;   
        var fileExtension =fileName.split('.').pop();  
        fileName +=  S3Config.S3ObjectDelimiter + uuidCustom() +"."+ fileExtension;
        const contentType = file.type;
        const formData = { file, fileName, contentType };
        let result = await S3FileUpload(formData).then(
            (response) => {
                if (response.status === 200) {
                    return { status: 200, s3FileUrl: S3Config.S3ObjectBaseURL + fileName, fileName: fileName, contentType: contentType }
                }
            }
        ).catch(err => console.log(err));
        return result;
    }
    const uploadPostMedia = (e) => {
        const [media] = e.target.files;
        let previewMedia = URL.createObjectURL(media);
        fileUpload(e).then(
            (response) => {              
                let obj = {
                    id: groupPostMedia.length + 1,
                    mediaTitle: response?.fileName,
                    mediaURL: response?.s3FileUrl,
                    mediaType: response?.contentType,
                    previewMedia: previewMedia
                }
                setGroupPostMedia([...groupPostMedia, obj]);
            }).catch(err => console.log(err));
    }
    const removeMedia = (e) => {
        let media_id = e.target.getAttribute("data-media-id")
        const filtered_postmedia = groupPostMedia.filter((media) => media.id != media_id)
        const new_postmedia = filtered_postmedia.map((obj, index) => {
            return { ...obj, id: index + 1 };
        });
        setGroupPostMedia(new_postmedia);
    }
    const submitPost = () => {
        // logger.verbose("Group Post Create", "GroupWall Component", "submitPost", "POST", "Submit Post", "");
        if ((!groupPostContent || groupPostContent == "<p><br></p>") && groupPostMedia.length === 0) {
            errorToast("Post Content is empty")
            return
        }
        else {
            const postData = [];
            const postDataObj =
            {
                "socialEntityTypeId": "4",
                "parentSocialEntityTypeId": "2",
                "parentSocialEntityId": groupId,
                "postContent": groupPostContent,
                "createdByUserId": curUserId,
                "createdByUserEntityTypeId": curUserEntityId,
                "createdByUserRoleId": curUserRoleId
            }
            postData.push(postDataObj);
            const objdata = {
                "postDetails": postData,
                "mediaDetails": groupPostMedia
            }
            // logger.verbose("Group Post Create", "GroupWall Component", "submitPost", "POST", "Submit Post Object API", JSON.stringify(objdata));
            const headers = {
                'Content-Type': 'application/json;charset=utf-8',
            }
            axios.post(CreatePostAPI, objdata, {
                headers: headers
            }).then(response => {
                if (response.data.status === 200) {
                    successToast('Post Created Successfully!')
                    setGroupPostContent("");
                    setGroupPostMedia([]);
                    $(".ql-editor").html("");
                    // logger.verbose("Group Post Create", "GroupWall Component", "submitPost", "POST", "Post Created in group wall API Respone Successfull", JSON.stringify(response.data));
                    window.location.reload(false);

                }
            }).catch(error => {
                console.error('There was an error!', error);
                logger.error("Group Post Create", "GroupWall Component", "getGroupDetails", "GET", "Post Created in group wall API Respone Failed", JSON.stringify(error));
            });
        }
    }
    const sendParticipationRequest = (e) => {
        const groupId = e.target.getAttribute("data-group-id");
        const participationObj = {
            "requestedByUserId": curUserId,
            "requestedByUserEntityId": curUserEntityId,
            "requestedByUserRoleId": curUserRoleId,
            "groupId": groupId
        }
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
        }
        axios.post(PARTICIPATEINGROUPAPI, participationObj, {
            headers: headers
        }).then(response => {
            if (response.data.status === 200) {
                if (isPrivate == false) {
                    celebrationToast(response.data.response);
                    setHasAccess(true);
                } else {
                    successToast(response.data.response);
                    $("#subscribeToGroupBtn").hide();
                }
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
    const handleEditGroup = async (e) => {
        let dataFor = e.target.getAttribute("data-for");
        let dataId = groupDetails[0]?.groupId;
        let value = e.target.value;
        if (dataFor === "title") {
            GroupNameValidation(value);
        }
        if (dataFor === "isPrivate")
            value = value === "true" ? true : false;
        if (dataFor === "logo") {
            const files = e.target.files;
            const uploadlogo = await fileUpload(e).then(response => response).catch(err => console.log(err));
            value = files.length == 0 ? groupDefaultLogo : await uploadlogo.s3FileUrl;
        }
        resetEditGroupDetails(dataId, dataFor, value);
    }

    const resetEditGroupDetails = (dataId, dataFor, value) => {
        const editedGroupInfo = editGroupDetails.map(obj => {
            if (obj.groupId == dataId) {
                return { ...obj, [dataFor]: value };
            }
            return obj;
        });
        setEditGroupDetails([...editedGroupInfo]);
    }

    const updateGroup = () => {      
        const editGroupObj = editGroupDetails[0];
        const len = Object.keys(editGroupObj).length;
        if (len <= 0) {
            return;
        }
        if (editGroupObj?.title === "") {
            errorToast("Please fill the group title");
            return;
        }
        if (editGroupDetails?.title !== groupDetails[0]?.title && groupNameExists == true) {
            errorToast('Group Title Already Exists');
            return;
        }
        if (editGroupObj?.description == "") {
            errorToast("Please fill the group description");
            return;
        }
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
        }
        axios.put(UPDATEGROUPtAPI, editGroupObj, {
            headers: headers
        }).then(response => {
            if (response.data.status === 200) {
                document.getElementById("createGroupForm").reset();
                getGroupDetails();
                $("#creategroup .close").trigger('click');
                successToast("Group details were updated")
                // logger.verbose("Edit Group", "GroupWall Component", "EditGroup", "POST", "Edit Group API Respone Successfull", JSON.stringify(response.data.response));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            logger.error("Edit Group", "GroupWall Component", "EditGroup", "POST", "Edit Group API Response Failed!!", JSON.stringify(error));
        });

        setGroupDetails([...editGroupDetails]);
    }
    const GroupNameValidation = (groupTitle) => {
        // logger.verbose("Group Name Validation", "GroupWall Component", "GroupNameValidation", "GET", "Group Name Validation API Hit", groupTitle);
        axios.get(`${GROUPNAMECHECKAPI}?groupTitle=${groupTitle}`)
            .then(response => {
                if (response.data === true && groupTitle !== groupDetails[0]?.title) {
                    errorToast('Group Name Already Exists');
                    // logger.verbose("Group Name Validation", "GroupWall Component", "GroupNameValidation", "GET", "Group Name Validation API Respone Successfull", JSON.stringify(response.data));
                }
                setGroupNameExists(response.data);
                return response.data
            })
            .catch(error => {
                console.error('There was an error!', error);
                logger.error("Group Name Validation", "GroupWall Component", "GroupNameValidation", "GET", "Group Name Validation API Respone Successfull", JSON.stringify(error));

            });
    }
    return (
        <>
            <div className="main-body-section main-body-section-social">
                <SocialHeader />
                <section className="body-section">
                    <div className="body-content container-fluid">
                        <div className="row1 My_groups">
                            <div className="col_res1 align-items-stretch box_1 w_171">
                                <div className="sticky-top">
                                    <SocialFeedNavigations />
                                    <AddToFeed />
                                </div>
                            </div>
                            <div className="mob-remove-padding box_2 w_661">
                                {groupDetails.length != 0 ?
                                    (groupDetails.map((ele, index) => (
                                        <div className="rounded-top h_0 pb-3 text-white d-flex flex-row position-relative" key={index}>
                                            {isOwner ? <a href="#" className="float-end position-absolute position-end mt-4 text-white pe-3 editGroupIcon"> <i className="fa fa-pencil fa-lg float-end  pt-2" title="Edit" data-bs-toggle="modal" data-bs-target="#creategroup"></i></a> : null}
                                            <div className="ms-4 d-flex flex-column p-image">
                                                <img src={ele?.logo ? ele?.logo : groupDefaultLogo} alt="Generic placeholder image img-circle" className="img-fluid img-thumbnail mt-4 mb-2 placeholder1" />

                                            </div>
                                            <div className="ms-4 mt-4 col-sm-10">
                                                <div className="row mb-2">
                                                    <div className="col-sm-3">
                                                        <p className="mb-0 fw-bold">Group Name:</p>
                                                        <p className="mb-0 mt-2 text-opacity" >
                                                            {ele?.isPrivate === false ? <span><i className="fa-solid fa-users"></i><span> Public group</span></span> : <span><i className="mdi mdi-lock"></i> <span>Private group</span></span>}
                                                        </p>
                                                        <p className="mb-0 mt-2 text-opacity" > <i className="mdi mdi-account-circle"></i> {ele?.totalParticipants} members </p>

                                                       <p className="mb-0 mt-2 text-opacity" > Owner : {ele?.ownerFirstName} {ele?.ownerLastName}</p>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <p className="mb-0">{ele?.title}</p>
                                                    </div>
                                                    {!hasAccess && <div className="col-sm-5">
                                                        <a className="btn btn-primary btn-md float-end fw-bold"
                                                            id="subscribeToGroupBtn" data-group-id={ele?.groupId} data-is-private={ele?.isPrivate} onClick={sendParticipationRequest}>Subscribe</a>
                                                    </div>}
                                                </div>
                                                <p className="mb-0 mt-2 text-opacity">Description: {ele?.description}</p>
                                            </div>
                                        </div>
                                    ))
                                    ) : null}

                                {hasAccess && (<div className="box-home box_shadow_1 no-border-radius mt_20 ">
                                    <div className="d-sm-flex justify-content-between align-items-start">
                                        <a className="mb-0 text-black-50" href="#" data-bs-toggle="modal" data-bs-target="#CreatePostModal">Post your thoughts here...</a>
                                        <div>
                                            <a href="#" data-bs-toggle="modal" data-bs-target="#CreatePostModal" className="text-black-50 me-2"><span
                                                className="mdi mdi-file-document-outline text-black-50 fa-xl"></span></a>
                                            {/* <a href="#" data-bs-toggle="modal" data-bs-target="#CreatePostModal" className="text-black-50"><span className="mdi mdi-camera-enhance-outline text-black-50"></span></a> */}
                                        </div>
                                    </div>
                                </div>
                                )}
                                <div className="box-home box_shadow_1 mt-4">
                                    {isPrivate ? (hasAccess ? <GroupPosts isOwner={isOwner}/> : <div className='text-center'><i className="fa fa-lock fa-2xl ml-10 mr_10" aria-hidden="true"></i> <span>Private Content</span></div>) : <GroupPosts />}
                                </div>
                            </div>
                            <div className="col_res1 align-items-stretch box_4 w_171">
                                <div className="sticky-top">
                                    <CAASWorkspaceNavigations />
                                    <CompanyAnnouncements />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="modal fade show" id="CreatePostModal">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title text-black-50" id="exampleModalLabel">
                                Create a post
                            </h3>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex align-items-center">
                                <img
                                    src={groupDetails[0]?.logo ? groupDetails[0]?.logo : groupDefaultLogo}
                                    alt="User Image"
                                    className="img-fluid rounded-circle img-profile me-3"
                                />
                                <div>
                                    <p className="fw-bold">{groupDetails[0]?.title}</p>
                                </div>
                            </div>
                            <div className="cursor mt-3">
                                {/* <input
                                    type="text"
                                    className="form-control border-0 ps-0"
                                    placeholder="what do you want to talk about?"
                                />
                                <i></i> */}

                                <ReactQuill modules={quillModules} theme="snow" onChange={setGroupPostContent} placeholder="what do you want to talk about?" />
                                <div className="mt_15">
                                    <h4 className="modal-title text-black-50 fs-5">Media</h4>
                                    <div className="flex-row createPostMediaPreviewDiv">
                                        {groupPostMedia.length != 0 ? (groupPostMedia.map((ele, index) => (
                                            <>
                                                <a key={index}>
                                                    {ele?.mediaType.indexOf("image") != -1 ?
                                                        <img src={ele?.previewMedia} alt="Uploaded Media" className="img-lg mb-2" /> :
                                                        <video width="320" height="240" muted controls src={ele?.previewMedia}></video>}
                                                </a>
                                                <a className="position-relative text-link inline-block mr_15"
                                                    onClick={removeMedia}>
                                                    <span className="ml-15" data-media-id={ele?.id} aria-hidden="true">&times;</span>
                                                </a>
                                            </>
                                        ))) : null}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="hashtag mt-5 pt-5">
                                <a href="#" className="text-link fw-bold"
                                >Add hashtag</a
                                >
                            </div> */}
                        </div>
                        <div className="modal-footer text-black-50 justify-content-between">
                            <div>
                                <input id="post-image-file-input" className="hiddenInputFile" type="file" accept="image/*" onChange={uploadPostMedia} />
                                <label className="text-black-50 m-0 me-3 uploadFileIcon" htmlFor="post-image-file-input" >
                                    <i className="fa-regular fa-image fa-xl"></i>
                                </label>
                                <input id="post-video-file-input" className="hiddenInputFile" type="file" accept="video/*" onChange={uploadPostMedia} />
                                <label className="text-black-50 m-0 me-3 uploadFileIcon" htmlFor="post-video-file-input">
                                    <i className="fa-brands fa-youtube fa-xl"></i>
                                </label>
                                {/* <a href="#" className="text-black-50 m-0 me-5 boeder-left ps-4">
                                    <span className="mdi mdi-chat-processing-outline"></span>
                                    Anyone
                                </a> */}
                            </div>
                            <div>
                                <a className="btn btn-light btn-sm float-end"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    id="submitPostBtn" onClick={submitPost}>Post</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal" id="creategroup">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header pt_0">
                            <h3 className="modal-title">Create Group</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="row1">
                                <div className="card">
                                    <div className="card-body py-3">
                                        <form className="forms-sample" id="createGroupForm">
                                            <div className="form-group">
                                                <label className="fw-bold" htmlFor="exampleInputUsername1">Group Name</label>
                                                <input type="text" className="form-control" data-for="title" placeholder="GroupName" value={editGroupDetails[0]?.title} onChange={handleEditGroup} />
                                            </div>
                                            <div className="form-group">
                                                <label className="fw-bold" htmlFor="exampleInputEmail1">Description</label>
                                                <textarea name="" id="groupDescription" cols="30" rows="3" data-for="description" value={editGroupDetails[0]?.description} onChange={handleEditGroup}></textarea>
                                            </div>
                                            <div className="form-group d-flex flex-row my-4">
                                                <label className="fw-bold p-0">Group Type :</label>
                                                <div className="col1 ps-4 pt-1">
                                                    <div className="form-check m-0 ">
                                                        <label className="form-check-label p-0 lh-1">
                                                            <input type="radio" className="form-check-input" value="false" name="groupType" data-for="isPrivate" checked={editGroupDetails[0]?.isPrivate === false} onChange={handleEditGroup} />
                                                            Public
                                                            <i className="input-helper"></i></label>
                                                    </div>
                                                </div>
                                                <div className="col1 ps-4 pt-1">
                                                    <div className="form-check m-0">
                                                        <label className="form-check-label p-0 lh-1">
                                                            <input type="radio" className="form-check-input" value="true" name="groupType" data-for="isPrivate" checked={editGroupDetails[0]?.isPrivate === true} onChange={handleEditGroup} />
                                                            Private
                                                            <i className="input-helper"></i></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="fw-bold">Upload Group's Logo Here </label>
                                                <input className="form-control" type="file" id="groupLogo" accept="image/*" data-for="logo" onChange={handleEditGroup} />
                                                <img src={editGroupDetails[0]?.logo} alt="Uploaded Media" className="img-lg mt-5 mb-2" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a className="btn btn-warning btn-md accept-btn" onClick={updateGroup}>Update Group</a>
                            <a href="#" className="btn btn-warning btn-md ignore-btn" data-bs-dismiss="modal">Cancel</a>
                        </div>
                    </div>
                </div>
            </div >
            <Toaster />
        </>

    )
}

export default GroupWall