import { React, useState, useEffect } from 'react'
import { API } from '../../../config/APIConfig';
import axios from 'axios';
import Pagination from './Pagination';
import Header from '../../../commons/components/Header';
import CaaSSideBar from '../../../commons/components/SideBars/CaaSSideBar';
import swal from 'sweetalert';
import $ from "jquery";
import { Link } from 'react-router-dom';
const CXOUserManagement = () => {
    const [cxoList, setCXOList] = useState([]);
    const [curCXOList, setCurCXOList] = useState([]);
    const [resetUserDetails, setResetUserDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const CXOListAPI = `${API.URL}api/v1/cxo/allusers`;
    const UPDATEUSERSTATUSAPI = `${API.URL}api/v1/admin/updateuserstatus`;
    const UPDATEAPPROVALSTATUSAPI = `${API.URL}api/v1/admin/updateuserapproval`;
    const RESETPASSWORDAPI = `${API.URL}api/v1/auth/password/reset`;
    const [currentPage, setCurrentPage] = useState(1);
    // No of Records to be displayed on each page   
    const [recordsPerPage] = useState(10);
    //pagination
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const getCXOList = async () => {
        const response = await fetch(CXOListAPI);
        if (!response.ok) {
            throw new Error('Data coud not be fetched!')
        } else {
            return response.json()
        }
    }
    const bindUsers = () => {
        setLoading(true);
        getCXOList()
            .then((data) => {
                if (data.response) {
                    setCXOList(data.response);
                    setCurCXOList(data.response)
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e.message)
            });
    }
    useEffect(() => {
        bindUsers();
        highlightNavMenu();
    }, []);

    const highlightNavMenu = () => {
        $("li.sidebarmenu").removeClass("active");
        $("#caas_members_li").addClass("active");
    }

    //pagination
    const currentRecords = curCXOList.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(curCXOList.length / recordsPerPage)


    const resetUserStatus = (userId, curStatus) => {
        var status = curStatus == 0 ? 1 : 0;
        updateUserDetails(userId, status);
    }
    const updateUserDetails = (userId, userStatus) => {
        const searcheduser = cxoList.filter((ele) => ele?.userId == userId);
        if (searcheduser.length != 1)
            return
        var { userName, entityId, roleId } = searcheduser[0];
        let updobj = { userId, entityId, roleId, userName, userStatus }
        axios.put(UPDATEUSERSTATUSAPI, updobj)
            .then(response => {
                if (response.data.status === 200) {
                    bindUsers();
                    swal("Updated Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    const updateUserApprovalStatus = (userId, approval) => {
        const searcheduser = cxoList.filter((ele) => ele?.userId == userId);
        if (searcheduser.length != 1)
            return
        var { userName, firstName, lastName, emailAddress, entityId, roleId } = searcheduser[0];
        var isApproved = approval == 0 ? 1 : 0;
        let updobj = { userId, entityId, roleId, userName, isApproved, emailAddress, firstName, lastName }
        axios.put(UPDATEAPPROVALSTATUSAPI, updobj)
            .then(response => {
                if (response.data.status === 200) {
                    bindUsers();
                    swal("Approved Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }


    const searchUser = () => {
        const keyword = document.getElementById("searchuser").value;
        const searcheduser = cxoList.filter((ele) => ele.firstName.toLowerCase().includes(keyword.toLowerCase()) || ele.lastName.toLowerCase().includes(keyword.toLowerCase()));
        setCurrentPage(1);
        setCurCXOList([...searcheduser]);
    }

    const bindUserDetailsForReset = (userId) => {
        document.getElementById("resetpasswordform").reset();
        const searcheduser = cxoList.filter((ele) => ele?.userId == userId);
        if (searcheduser.length != 1)
            return
        setResetUserDetails([...searcheduser]);
        document.getElementById("resetUserName").value = searcheduser[0]?.userName;
    }

      const handleKeyPress = (e) => {
        if (e.charCode === 32 && e.key === " ") {
          e.preventDefault();
        }
      }
      
    const resetUserPassword = () => {
        $("#resetPasswordbtn").addClass("disabled");
        const user_name = document.getElementById("resetUserName").value;
        const userNewPassword = document.getElementById("resetUserPassword").value;         
        if (user_name.length > 0 && userNewPassword.length > 0) {
            const { userId, entityId, roleId, userName, emailAddress, firstName, lastName } = resetUserDetails[0];
            if (user_name === userName) {
                let updobj = { userId, entityId, roleId, userName, userNewPassword, emailAddress, firstName, lastName };

                axios.put(RESETPASSWORDAPI, updobj)
                    .then(response => {
                        if (response.data.status === 200) {
                            swal("Password was Reset Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                            $("#ResetPassword .close").trigger('click');
                            $("#resetPasswordbtn").removeClass("disabled");
                            document.getElementById("resetpasswordform").reset();
                            bindUsers();
                        }
                        $("#resetPasswordbtn").removeClass("disabled");
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                        $("#resetPasswordbtn").removeClass("disabled");
                    });
            }
        }
        else {
            swal("Please enter the password", "", "error", { closeOnClickOutside: false, closeOnEsc: false });
            $("#resetPasswordbtn").removeClass("disabled");
        }
    }
    return (
        <div className="container-scroller">
            <Header entity="CaaS" />
            <div className="container-fluid page-body-wrapper">
                <CaaSSideBar />
                {loading ? (
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                ) : (
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-12 grid-margin stretch-card">
                                    <div className="col-lg-12 d-grid">
                                        <div className="grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body pb-0">
                                                    {/* <h4 className="card-title text-center">CAAS Members</h4> */}
                                                    {/* <h4 className="card-title text-center"><Link to="/caas/global/caasmember/view" state={{ quotedBy: 54, role: 4}}>CAAS Members</Link></h4> */}
                                                    <h4 className="card-title">CAAS Members</h4>

                                                </div>

                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="mt-0 text-left box-shadow cardPad card-body border border-radius">
                                                    <div className="d-flex justify-content-end">
                                                        {/* <a className="btn addindustrybtn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#AddIndustry" title="Add" >
                                                        <i className="mdi mdi-note-plus-outline"></i> Add CXO
                                                    </a> */}
                                                        <div className="float-end d-flex align-items-center filter-option mb-0">
                                                            <input className="form-control border-secondary border-right-0 rounded-0 search-input" type="search"
                                                                placeholder="Search User"
                                                                id="searchuser"
                                                                onChange={searchUser}
                                                            />
                                                            <div className="col-auto">
                                                                <button className="btn btn-outline-secondary border-left-0 rounded-0 rounded-right search-btn" type="button"><i className="fa fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-sm-12 mt-2">
                                                            <div className="home-tab">
                                                                <div className="tab-content1">
                                                                    <div className="" id="NewCompany" >
                                                                        <div className="template-demo table-responsive">
                                                                            <Records currentRecords={currentRecords} resetUserStatus={resetUserStatus} updateUserApprovalStatus={updateUserApprovalStatus} bindUserDetailsForReset={bindUserDetailsForReset} />
                                                                            <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="modal fade show" id="ResetPassword" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">Reset Password </h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="resetpasswordform">

                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">User Name </label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="resetUserName" disabled />

                                    </div>

                                    <label className="col-sm-4 col-form-label mt_10">Password </label>
                                    <div className="col-sm-8 mt_10">
                                        <input type="text" className="form-control" id="resetUserPassword" name="resetPassword" onKeyPress={handleKeyPress}/>

                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">Cancel</a>
                            <a type="button" className="btn btn-warning btn-md next-btn" id="resetPasswordbtn" onClick={resetUserPassword} >Reset</a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Records = ({ currentRecords, resetUserStatus, updateUserApprovalStatus, bindUserDetailsForReset }) => {

    return (
        <table className="table table-bordered mb-3 admin-table-res">
            <thead>
                <tr>

                    <th className="text-left-intend">CAAS Member Name</th>
                    <th className="text-left-intend">Login ID</th>
                    <th className="text-left-intend" >Status</th>
                    <th className="text-left-intend">Approval Status</th>
                    <th className="text-left-intend">Reset Password</th>
                </tr>
            </thead>
            <tbody>
                {
                    currentRecords.length != 0 ? (
                        currentRecords.map((ele, index) => (
                            <tr key={ele?.userId}>

                                <td className="text-left-intend"><Link to="/caas/global/caasmember/view" state={{ quotedBy: ele?.userId, role: ele?.roleId }}>{ele?.firstName} {ele?.lastName}</Link></td>
                                <td className='text-left-intend'>{ele?.userName}</td>
                                <td className="text-center">
                                    <div className="d-flex">
                                        <label htmlFor="site_state" className="form-check-label">In Active</label>
                                        <div className="form-switch form-check ms-2 margin-0">
                                            <input type="checkbox" className="form-check-input" id="site_state" onChange={() => {

                                                resetUserStatus(ele?.userId, ele?.userStatus)

                                            }} checked={ele?.userStatus == 1 ? 'checked' : ''} />
                                        </div>
                                        <label htmlFor="site_state" className="form-check-label">Active</label>
                                    </div>
                                </td>
                                {/* <td className="text-center">
                                    <div className={ele?.userStatus === 1 ? "badge  badge-success" : "badge badge-warning"}>{ele?.userStatus === 1 ? "Active" : "InActive"}</div>
                                </td> */}
                                <td>
                                    {ele?.isApproved == 1 ? "Approved" : <div className="form-switch form-check  margin-0 text-center">
                                        <input type="checkbox" className="form-check-input text-center" id="site_state" onChange={() => {

                                            // resetUserStatus(ele?.userId,ele?.userName,ele?.userStatus)
                                            updateUserApprovalStatus(ele?.userId, ele?.isApproved)

                                        }} />
                                    </div>}
                                </td>
                                <td className="text-center">
                                    {ele?.isApproved == 1 ?
                                        <a className="btn addindustrybtn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#ResetPassword" title="Reset Password"
                                            onClick={() => { bindUserDetailsForReset(ele?.userId) }}>
                                            Reset Password
                                        </a>
                                        : "NA"}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                        </tr>
                    )
                }
            </tbody>

        </table >

    )
}
export default CXOUserManagement