import React, { useState } from 'react'
import Header from '../../../commons/components/Header'
import CaaSSideBar from '../../../commons/components/SideBars/CaaSSideBar'
import Footer from '../../../commons/components/Footer'
import { Link } from 'react-router-dom'
import $ from "jquery";
import OpenRFPGlobal from './OpenRFPGlobal'
import ClosedRFPGlobal from './ClosedRFPGlobal'
import ExpiredRFPFlobal from './ExpiredRFPFlobal'
import WithdrawnRFPGlobal from './WithdrawnRFPGlobal'
const ActiveRFPGlobal = () => {
    const [statusFilter, setStatusFilter] = useState("all");
    const [enterpriseFilter, setEnterpriseFilter] = useState([{ enterpriseId: 1 }, { enterpriseId: 2 }, { enterpriseId: 3 }]);
    const changeStatusFilter = (e) => {
        setStatusFilter(e.target.getAttribute("filter-value"));
    }
    const changeEnterpriseFilter = (e) => {
        var arr = []; var obj = {};
        if (e.target) {
            obj["enterpriseId"] = e.target.getAttribute("filter-value");
            arr.push({...obj});
            setEnterpriseFilter([...arr]);
        }
        console.log(enterpriseFilter);
    }
    return (
        <div className="container-scroller">
            <Header entity="CaaS" />
            <div className="container-fluid page-body-wrapper">
                <CaaSSideBar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="col-12">
                                    <div className="card card-rounded card-outline card-primary">
                                        <div className="card-header">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <nav className="navbar navbar-expand bg-white">
                                                        <ul className="navbar-nav">
                                                            <li className="nav-item bold">
                                                                <h3 className="fw-bold">Active RFP</h3>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                                <div className="d-flex align-items-center text-nowrap">
                                                    <Link to="/caas/global/dashboard" className="btn btn-primary btn-sm add-client btn-icon-text text-nowrap me-3">Back to Dashboard</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body bg-light-blue">
                                            <div className="row">
                                                <div className="col-md-10 mx-auto">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="btn-group mb-3">
                                                                <button type="button" className="btn btn-default">Filter by Status : {statusFilter.toLocaleUpperCase()} </button>
                                                                <button type="button" className="btn btn-default dropdown-toggle dropdown-toggle-split" id="dropdownMenuSplitButton2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                </button>
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuSplitButton2">
                                                                    <a className="dropdown-item" href="#" filter-value="all" onClick={changeStatusFilter}>All</a>
                                                                    <a className="dropdown-item" href="#" filter-value="open" onClick={changeStatusFilter}>Open</a>
                                                                    <a className="dropdown-item" href="#" filter-value="closed" onClick={changeStatusFilter}>Closed</a>
                                                                    <a className="dropdown-item" href="#" filter-value="expired" onClick={changeStatusFilter}>Expired</a>
                                                                    <a className="dropdown-item" href="#" filter-value="withdrawn" onClick={changeStatusFilter}>Withdrawn</a>
                                                                    <div className="dropdown-divider"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 mb-3">
                                                            <div className="btn-group">
                                                                <button type="button" className="btn btn-default">Filter by : Enterprise All </button>
                                                                <button type="button" className="btn btn-default dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                </button>
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuSplitButton2">
                                                                    <a className="dropdown-item" href="#" filter-value="1" onClick={changeEnterpriseFilter} >Enterprise 1</a>
                                                                    <a className="dropdown-item" href="#" filter-value="2" onClick={changeEnterpriseFilter} >Enterprise 2</a>
                                                                    <a className="dropdown-item" href="#" filter-value="3" onClick={changeEnterpriseFilter} >Enterprise 3</a>
                                                                    <div className="dropdown-divider"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-10 mx-auto">
                                                    {statusFilter == "all" || statusFilter == "open" ? <OpenRFPGlobal enterpriseFilter={enterpriseFilter} /> : null}
                                                    {statusFilter == "all" || statusFilter == "closed" ? <ClosedRFPGlobal enterpriseFilter={enterpriseFilter} /> : null}
                                                    {statusFilter == "all" || statusFilter == "expired" ? <ExpiredRFPFlobal enterpriseFilter={enterpriseFilter} /> : null}
                                                    {statusFilter == "all" || statusFilter == "withdrawn" ? <WithdrawnRFPGlobal enterpriseFilter={enterpriseFilter} /> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}
export default ActiveRFPGlobal