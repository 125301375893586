import React, { useRef } from 'react'
import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Footer from '../../../commons/components/Footer'
import AuthHeader from '../AuthHeader'
import $ from "jquery";
import cioImg from "../../../assets/images/speaker.png"
import experienceImg from "../../../assets/images/experience.png"
import employmentImg from "../../../assets/images/employment.png"
import educationImg from "../../../assets/images/education.png"
import certificateImg from "../../../assets/images/certificate.png"
import swal from 'sweetalert';
import profilepic from "../../../assets/images/noav.jpg"
import logger from '../../../utils/logger.js';
import { API } from '../../../config/APIConfig';
import axios from 'axios';
import { getCountryStateCityList, getNationalityList, getLanguageList, getEducationList, getSkillExperienceLevelList, getSkillDetailslList, getGenderDetailsList, getIndustryDetailsList } from '../../../utils/meta';
import { S3Config } from '../../../config/AWSConfig';
import S3FileUpload from '../../../utils/S3FileUpload';
import Emailer from '../../../utils/Emailer';
import { useNavigate } from 'react-router-dom';
import PDFViewer from '../../Acknowledgements/PDFViewer';
import SignPad from '../../Acknowledgements/SignPad';
import uuidCustom from '../../../utils/uuidCustom';
const CXORegistrationAPI = `${API.URL}api/v1/cxo/register`;
const CXOUsernameCheckAPI = `${API.URL}api/v1/cxo/validate/username`;
const NDAHTMLFILE = S3Config.NDAHTMLFILE;
const CXORegistration = () => {
    const emailform = useRef();
    const navigate = useNavigate();
    //For Language Translation
    const { i18n, t } = useTranslation(["registration"]);
    useEffect(() => {
        if (localStorage.getItem("i18nextLng")?.length > 2) {
            i18n.changeLanguage("en");
        }
        bindDropDownData();
    }, []);
    const handleLanguageChange = (e) => {
        i18n.changeLanguage(e.target.value);
    };
    //
    const [personalInfo, setPersonalInfo] = useState({});
    const [profileInfo, setProfileInfo] = useState({});
    const [experienceInfo, setExperienceInfo] = useState([]);
    // const [industryExperienceInfo, setIndustryExperienceInfo] = useState([]);
    const [employmentInfo, setEmploymentInfo] = useState([]);
    const [educationInfo, setEducationInfo] = useState([]);
    const [certificationInfo, setCertificationInfo] = useState([]);
    const [curEmpId, setcurEmpId] = useState(0);
    const [curEmp, setCurEmp] = useState({});
    const [acknowledgementInfo, setAcknowledgementInfo] = useState(false);
    const [countriesMeta, setCountriesMeta] = useState([]);
    const [statesMeta, setStatesMeta] = useState([]);
    const [curStatesMeta, setCurStatesMeta] = useState([]);
    const [citiesMeta, setCitiesMeta] = useState([]);
    const [curCityList, setCurCityList] = useState([]);
    const [nationalitiesMeta, setNationalitiesMeta] = useState([]);
    const [languagesMeta, setLanguagesMeta] = useState([]);
    const [educationsMeta, setEducationsMeta] = useState([]);
    const [coursesMeta, setCoursesMeta] = useState([]);
    const [institutesMeta, setInstitutesMeta] = useState([]);
    const [coursetypesMeta, setCoursetypesMeta] = useState([]);
    const [skillexperiencelevelsMeta, setSkillexperiencelevelsMeta] = useState([]);
    const [skilldetailsMeta, setSkillDetailsMeta] = useState([]);
    const [skillversionMeta, setSkillVersionssMeta] = useState([]);
    const [curskillVersionList, setCurSkillversionList] = useState([]);
    const [certificateFile, setCertificateFile] = useState("");
    const [uploadCVInfo, setUploadCVInfo] = useState([]);
    const [curCVId, setcurCVId] = useState(0);
    const [curCVInfo, setcurCVInfo] = useState(0);
    const [cvAttachmentFile, setCVAttachmentFile] = useState("");
    const [previewProfilePicture, setPreviewProfilePicture] = useState(profilepic);
    const [cxoLanguages, setCXOLanguages] = useState([]);
    const [skillRef, setSkillRef] = useState({});
    const [skillVersionRef, setSkillVersionRef] = useState({});
    const [countryRef, setCountryRef] = useState({});
    const [stateRef, setStateRef] = useState({});
    const [cityRef, setCityRef] = useState({});
    const [genderMeta, setGenderMeta] = useState([]);
    const [ndaChecked, setNDAChecked] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [userExists, setUserExists] = useState(false)
    const [industryExperiencedisplay, setIndustryExperienceDisplay] = useState([]);
    const [industryExperienceInfo, setIndustryExperienceInfo] = useState("");
    const [industrydetails, setIndustryDetails] = useState([]);
    const [countryphonecodeMeta, setCountryPhoneCodeMeta] = useState([]);
    const [curCountryphonecodeMeta, setCurCountryPhoneCodeMeta] = useState([]);
    const [curempCityList, setCurEmpCityList] = useState([]);
    const [curType, setCurType] = useState(0);
    const [cxoackInfo, setcxoackInfo] = useState([]);
    const [ndaContent, setNDAContent] = useState("");
    const [showNDASignPicker, setNDASignPicker] = useState(false);
    const [ndaSignImg, setNDASignImg] = useState(false);
    const [clearAllCount, setClearAllCount] = useState(0);
    const [clearACkType, setClearACkType] = useState(0);
    const sendAckInfo = (index) => {
        const mergeData = [...cxoackInfo, ...index];
        setcxoackInfo([...mergeData]);
        // setcxoackInfo(index);
    };
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    const sendSignImgDataToParent = (index) => {
        setNDASignImg(index);
    };
    const addNdaAckInfo = () => {
        debugger
        const obj = {
            "has_acknowledged": true,
            "acknowledgement_type_id": 1,
            "acknowledgementSignatureUrl": ndaSignImg,
            "acknowledgementFileUrl": NDAHTMLFILE,
            "acknowledgementContent":$('#NDA_Body_Id').html()
        }
        // console.log(obj);
        setcxoackInfo([...cxoackInfo, obj]);
        $("#ndaId").prop("checked", true);
        // $("#sign-btn").addClass("hide");
        $("#nda-sign-btn").addClass("hide");
        $("#NonDiscAgreement .close").trigger('click');
    }
    const openSignPad = () => {
        setNDASignPicker(true);
        // $("#nda-sign-btn").removeClass("hide");
       // $("#open-nda-sign-btn").addClass("hide");
    }
    const clearSign = (typeId) => {
        if (typeId == 0) {
            setcxoackInfo([]);
            // document.getElementById("termsId").disabled = true;
            $("#ndaId").prop("checked", false);
            $("#privacyPolicyId").prop("checked", false);
            $("#cocId").prop("checked", false);
            setClearAllCount(clearAllCount => clearAllCount + 1);
        }
        else {
            const deleted = cxoackInfo.filter((ack) => ack.acknowledgement_type_id != typeId);
            setcxoackInfo([...deleted]);
            if (typeId == 1) {
                $("#ndaId").prop("checked", false);
                setClearACkType(1);
                // document.getElementById("termsId").disabled = true;
            }
            else if (typeId == 2) {
                $("#privacyPolicyId").prop("checked", false);
                setClearACkType(2);
                // document.getElementById("termsId").disabled = true;
            }
            else if (typeId == 3) {
                $("#cocId").prop("checked", false);
                setClearACkType(3);
                // document.getElementById("termsId").disabled = true;
            }
        }
    }
    // useEffect(() => { 
    //     if($('#cocId')[0].checked && $('#privacyPolicyId')[0].checked && $('#ndaId')[0].checked){
    //     document.getElementById("termsId").disabled = false;
    // } 
    // }, [cxoackInfo]);
    useEffect(() => {
        let editCVIndex = uploadCVInfo.findIndex((obj => obj.id == curCVId));
        if (editCVIndex != -1) {
            let editCVObj = uploadCVInfo[editCVIndex];
            setcurCVInfo({ ...editCVObj })
        }
    }, [curCVId]);
    useEffect(() => {
        let editEmpIndex = employmentInfo.findIndex((obj => obj.id == curEmpId));
        if (editEmpIndex != -1) {
            let editEmpObj = employmentInfo[editEmpIndex];
            setCurEmp({ ...editEmpObj })
        }
    }, [curEmpId]);
    const [curExpId, setcurExpId] = useState(0);
    const [curExp, setCurExp] = useState({});
    useEffect(() => {
        let editExpIndex = experienceInfo.findIndex((obj => obj.id == curExpId));
        if (editExpIndex != -1) {
            let editExpObj = experienceInfo[editExpIndex];
            // resetskillversion(editExpObj.experienceSkill);
            setCurExp({ ...editExpObj })
        }
    }, [curExpId]);
    const resetskillversion = (id) => {
        // var id = e.target.value;
        var newskillversionList = skillversionMeta.filter((ele) => ele.skillId == id);
        setCurSkillversionList([...newskillversionList]);
    }
    const [curEduId, setcurEduId] = useState(0);
    const [curEdu, setCurEdu] = useState({});
    useEffect(() => {
        let editEduIndex = educationInfo.findIndex((obj => obj.id == curEduId));
        if (editEduIndex != -1) {
            let editEduObj = educationInfo[editEduIndex];
            setCurEdu({ ...editEduObj })
            // console.log(document.getElementById('editCompYear').value);
            // console.log(editEduObj.educationCompletedYear);
            document.getElementById('editCompYear').value = editEduObj.educationCompletedYear;
        }
    }, [curEduId]);
    const [curCertId, setcurCertId] = useState(0);
    const [curCert, setCurCert] = useState({});
    useEffect(() => {
        let editCertIndex = certificationInfo.findIndex((obj => obj.id == curCertId));
        if (editCertIndex != -1) {
            let editCertObj = certificationInfo[editCertIndex];
            setCurCert({ ...editCertObj })
        }
    }, [curCertId]);
    useEffect(() => {
        var langslist = languagesMeta.filter((ele) => ele.languageId == profileInfo.profileLanguages);
        setCXOLanguages([...langslist]);
    }, [profileInfo.profileLanguages])
    const showPreviousDiv = () => {
        let ele = $("#sideNavTabs").find(".current");
        let parentElement = document.getElementById("sideNavTabs");
        let prevItemId = ele.index() - 1;
        if (prevItemId == 0) {
            $("#previousli").addClass("disabled");
        }
        if (prevItemId > 0) {
            let prevItem = parentElement.childNodes.item(prevItemId);
            prevItem.click();

            let curSection = prevItem.childNodes[0].getAttribute("aria-controls");
            if (curSection) {
                $("section.mainSection").hide();
                $('#' + curSection).show();
            }
            $("#nextBtn").show();
            $("#finishli").hide();
        }
    }
    // const showNextDiv = () => {
    //     let ele = $("#sideNavTabs").find(".current");
    //     let parentElement = document.getElementById("sideNavTabs");
    //     let nextItemId = ele.index() + 1;
    //     if (nextItemId < 10) {
    //         $("#previousli").removeClass("disabled");
    //         let nextItem = parentElement.childNodes.item(nextItemId);
    //         nextItem.click();
    //         let curSection = nextItem.childNodes[0].getAttribute("aria-controls");
    //         if (curSection) {
    //             $("section.mainSection").hide();
    //             $('#' + curSection).show();
    //             $("#nextBtn").show();
    //             $("#finishli").hide();
    //         }
    //         if (nextItemId == 9) {
    //             $("#nextBtn").hide();
    //             $("#finishli").show();
    //             if(JSON.stringify(profileInfo) === '{}'||experienceInfo.length == 0 ||employmentInfo.length == 0||experienceInfo.length == 0
    //             ||educationInfo.length == 0||certificationInfo.length == 0 )
    //             {
    //                 $("#finishli").addClass("disabled");
    //             }
    //             else{

    //                 $("#finishli").removeClass("disabled")
    //             }
    //         }
    //         $("#personalInfoDiv > section").hide();
    //     }
    // }
    const replaceNDAContentV1 = () => {
        var NDAhtml = document.getElementById("NDA_Body_Id").innerHTML;
        var NDAres = NDAhtml.replace(/{DATE}/g, date);
        if (personalInfo.firstName)
            NDAres = NDAres.replace(/{CAAS_MEMBER_FIRST_NAME}/g, personalInfo.firstName);
        if (personalInfo.lastName)
            NDAres = NDAres.replace(/{CAAS_MEMBER_LAST_NAME}/g, personalInfo.lastName);
        if (profileInfo.profileTitle)
            NDAres = NDAres.replace(/{CAAS_MEMBER_PROFILE_TITLE}/g, profileInfo.profileTitle);
        document.getElementById("NDA_Body_Id").innerHTML = NDAres;
    }

    
    const replaceNDAContent = () => {
        const firstName = document.getElementById("firstName").value;
        const lastName = document.getElementById("lastName").value;
        const profileTitle = document.getElementById("profileTitle").value;
        var NDAhtml = document.getElementById("NDA_Body_Id").innerHTML;
        var NDAres = NDAhtml.replace(/{DATE}/g, date);
        if (firstName)
            NDAres = NDAres.replace(/{CAAS_MEMBER_FIRST_NAME}/g,firstName);
        if (lastName)
            NDAres = NDAres.replace(/{CAAS_MEMBER_LAST_NAME}/g, lastName);
        if (profileTitle)
            NDAres = NDAres.replace(/{CAAS_MEMBER_PROFILE_TITLE}/g, profileTitle);
        document.getElementById("NDA_Body_Id").innerHTML = NDAres;
    }

    const showNextDiv = () => {
        replaceNDAContent();
        let ele = $("#sideNavTabs").find(".current");
        if (ele.index() == 1) {
            var flaglist = allcheckValidation();
            if (flaglist.includes(false))
                var flag = false;
            else
                var flag = true;
            if (flag) {
                // var NDAhtml = document.getElementById("NDA_Body_Id").innerHTML;
                // var NDAres = NDAhtml.replace(/{CAAS_MEMBER_FIRST_NAME}/g, personalInfo.firstName);
                // NDAres = NDAres.replace(/{CAAS_MEMBER_LAST_NAME}/g, personalInfo.lastName);
                // NDAres = NDAres.replace(/{DATE}/g, date);
                // document.getElementById("NDA_Body_Id").innerHTML = NDAres;
                showNextSection();
            }

            else {
                swal("Please fill the Personal Information", "", "error", { closeOnClickOutside: false, closeOnEsc: false });
            }
        }
        if (ele.index() == 2) {

            var flag = handleSubmitProfile();

            if (flag) {
                //   $("#ProfileSummary .close").trigger("click");
                // var NDAhtml = document.getElementById("NDA_Body_Id").innerHTML;
                // var NDAres = NDAhtml.replace(/{CAAS_MEMBER_PROFILE_TITLE}/g, profileInfo.profileTitle);
                // document.getElementById("NDA_Body_Id").innerHTML = NDAres;
                showNextSection();
            }

            else {
                //    alert("Fill all the details")
                swal("Please fill the Profile Information", "", "error", { closeOnClickOutside: false, closeOnEsc: false });

            }
        }
        if (ele.index() == 3) {
            if (!experienceInfo.length > 0) {
                var flaglist = allskillcheckValidation();
                if (flaglist.includes(false))
                    var flag = false;
                else
                    var flag = true;

                if (!flag) {
                    swal("Please fill the Experience Details", "", "error", { closeOnClickOutside: false, closeOnEsc: false });
                    //   showNextSection(); 
                }
            }
            else if (experienceInfo.length < 1) {
                swal("Please enter at least 1 skills", "", "error", { closeOnClickOutside: false, closeOnEsc: false });
            }
            else {
                showNextSection();
            }
        }

        if (ele.index() == 4) {
            if (!employmentInfo.length > 0) {
                var flaglist = allcheckEmploymentValidation();
                if (flaglist.includes(false))
                    var flag = false;
                else
                    var flag = true;

                if (!flag) {
                    swal("Please fill the Employment Information", "", "error", { closeOnClickOutside: false, closeOnEsc: false });
                    //   showNextSection(); 
                }


            }
            else {
                showNextSection();

            }
        }
        if (ele.index() == 5) {
            // if (!educationInfo.length > 0) {
            //     var flaglist = allcheckEducationvalidation();
            //     if (flaglist.includes(false))
            //         var flag = false;
            //     else
            //         var flag = true;

            //     if (!flag) {
            //         //   $("#ProfileSummary .close").trigger("click");
            //         swal("Please fill the Education Information", "", "error", { closeOnClickOutside: false, closeOnEsc: false });

            //     }
            // }

            // else {
                showNextSection();

            // }
        }
        if (ele.index() == 6) {
            // if (!certificationInfo.length > 0) {
            //     var flaglist = certificateValidation();
            //     if (flaglist.includes(false))
            //         var flag = false;
            //     else
            //         var flag = true;

            //     if (!flag) {
            //         //   $("#ProfileSummary .close").trigger("click");
            //         swal("Please fill the certification Information", "", "error", { closeOnClickOutside: false, closeOnEsc: false });

            //     }
            // }

            // else {
            showNextSection();

            // }
        }
        if (ele.index() == 7) {
            if (!uploadCVInfo.length > 0) {
                var flaglist = cvValidation();
                if (flaglist.includes(false))
                    var flag = false;
                else
                    var flag = true;

                if (!flag) {
                    //   $("#ProfileSummary .close").trigger("click");
                    swal("Please fill the CV Information", "", "error", { closeOnClickOutside: false, closeOnEsc: false });

                }
            }
            else {
                showNextSection();

            }
        }
        if (ele.index() > 7) {
            showNextSection();
        }


    }
    const showNextSection = () => {
        let ele = $("#sideNavTabs").find(".current");
        let parentElement = document.getElementById("sideNavTabs");
        let nextItemId = ele.index() + 1;
        if (nextItemId < 10) {
            $("#previousli").removeClass("disabled");
            let nextItem = parentElement.childNodes.item(nextItemId);
            nextItem.click();
            let curSection = nextItem.childNodes[0].getAttribute("aria-controls");
            if (curSection) {
                $("section.mainSection").hide();
                $('#' + curSection).show();
                $("#nextBtn").show();
                $("#finishli").hide();
            }
            if (nextItemId == 9) {
                $("#nextBtn").hide();
                $("#finishli").show();
            }
            $("#personalInfoDiv > section").hide();
        }

    }
    useEffect(() => {
        bindEventsHandlers();
        bindNdaContent();
    }, []);
    const bindNdaContent = () => {
        $.ajax({
            url: NDAHTMLFILE, success: function (data) {
                debugger
                setNDAContent(data);
                $(".NonDiscAgreementBody").html(data);
                var NDAhtml = document.getElementById("NDA_Body_Id").innerHTML;
                var NDAres = NDAhtml.replace(/{DATE}/g, date);
                document.getElementById("NDA_Body_Id").innerHTML = NDAres;

            }
        });
    }
    const handleInput = (e) => {
        var id = e.target.id;
        if (id === "firstName") {
           //var firstNameValue = e.target.value.replace(/[^\w\s]/gi, "")
           //firstNameValue = firstNameValue.replace(/[^a-z]/gi, '');
            // var firstNameValue = e.target.value.replace(new RegExp(/^\s[^a-zA-Z0-9_\s]*$/, 'ig'), "");
            var firstNameValue = e.target.value.replace(/[^a-z ]/gi, "")
            e.target.value = firstNameValue;
            $(".NonDiscAgreementBody").html("");
            $(".NonDiscAgreementBody").html(ndaContent);
        }
        if (id === "lastName") {
            // var lastNameValue = e.target.value.replace(new RegExp(/^\s[^a-zA-Z0-9_\s]*$/, 'ig'), "");
            // var lastNameValue = e.target.value.replace(/[^\w\s]/gi, "")
            // lastNameValue = lastNameValue.replace(/[^a-z]/gi, '');
            var lastNameValue = e.target.value.replace(/[^a-z ]/gi, "")
            e.target.value = lastNameValue;
            $(".NonDiscAgreementBody").html("");
            $(".NonDiscAgreementBody").html(ndaContent);
        }
        if (id === "state")
            resetCityList(e);
        if (id === "country") {
            resetStateList(e);
            resetCountryCodeList(e)
        }
        if (id === "userName")
            userValidationCheck(e.target.value);
        if (id === "phoneNumber") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
        }
        if (id === "zipCode") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
        }
        if (id === "poBoxNo") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
        }
        if (id === "email") {
            var newValue = e.target.value.replace(/\s/g, "");
            e.target.value = newValue;
        }
        var value = e.target.value;
        setPersonalInfo({ ...personalInfo, [id]: value });

        checkValidation(e.target.id);
    }
    //validation 1 personal info
    const checkValidation = (id) => {
        if (id === "" || id === undefined) {
            return allcheckValidation()
        }
        else {
            return singlecheckValidation(id)
        }
    }
    const selectTab = (tab) => {
        replaceNDAContent();
        let ele = $("#sideNavTabs").find(".current");
        ele.removeClass("current");
        ele.removeClass("active");
        ele.addClass("disabled-wizard");
        highlightSection(tab);
    }
    const highlightSection = (tab) => {
        tab.currentTarget.className = "nav-item active current";
        let curSection = tab.target.getAttribute("aria-controls");
        if (curSection) {
            $("section.mainSection").hide()
            $('#' + curSection).show();
        }
    }
    const bindEventsHandlers = () => {
        $("#sideNavTabs > li").on("click", function (e) {
            selectTab(e);
        });
        $("#finishli").on("click", function () {
            // swal("Thank you for registering!", "Please confirm your Registration by verifying the email.", "success").then(okay => {
            //     if (okay) {
            //         navigate('/register', { replace: true })
            //     }
            // }
            // );
        });
        // $('#Terms termsBody').scroll(function(){
        //     //scrollTop refers to the top of the scroll position, which will be scrollHeight - offsetHeight
        //     if(this.scrollTop == (this.scrollHeight - this.offsetHeight)) {
        //       alert("Top of the bottom reached!");
        //     }
        //   });
        const myDiv = document.getElementById("termsBody")
        myDiv.addEventListener('scroll', () => {
            if (myDiv.offsetHeight + myDiv.scrollTop >= myDiv.scrollHeight) {
                //console.log('scrolled to bottom')
            }
        });
        $(".add_pi").on("click", function () {
            loadPersonalInfoDiv();
        });

        $("#uploadCVSave").on("click", function () {
            $("#showUploadCVInfoDiv").removeClass("hide");
            $("#uploadCVBtn").addClass("hide");
        });
        loadPersonalInfoDiv();
    }
    const loadPersonalInfoDiv = () => {
        $("#personalInfoInnerDiv").removeClass("hide");
        $("#navControls").removeClass("hide");
        $("#dispshow").addClass("hide");
        $("#addpi_btn").addClass("hide");
    }
    //Profile Section//
    const handleClick_Profile_Common = (id, value) => {
        $(".NonDiscAgreementBody").html("");
        $(".NonDiscAgreementBody").html(ndaContent);
        setProfileInfo({ ...profileInfo, [id]: value });
        if (JSON.stringify(profileInfo) !== "{}") {
            $("#addProfileBtn").hide();
            $("#viewProfileDiv").show();
        }
        checkProfilevalidation(id);
    }
    const handleClick_Profile = (e) => {
        let id = e.target.id; let value = "";
        if (id === "profilePicture") {
            const [img] = e.target.files;
            setPreviewProfilePicture(URL.createObjectURL(img));
            fileUpload(e).then(
                (response) => {
                    value = response.s3FileUrl;
                    handleClick_Profile_Common(id, value);
                }).catch(err => console.log(err));
        } else {
            value = e.target.value;
            handleClick_Profile_Common(id, value);
        }
    }
    // Experience Summary//
    const editExperience = (e) => {
        let dataFor = e.target.getAttribute("data-for");
        let dataId = curExpId;
        let value = e.target.value;
        if (dataFor === "experienceSkillId") {
            resetversionList(e);
            // editExperinceSkillFix(value);
        }
        if (dataFor === "experienceSoftwareVersion") {
            // editExperinceSkillversionFix(value);
        }
        if (dataFor === "experienceImplementationSize") {
            value = value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = value;
        }

        const newExperience = experienceInfo.map(obj => {
            if (obj.id == dataId) {
                return { ...obj, [dataFor]: value };
            }
            return obj;
        });
        setExperienceInfo([...newExperience]);
        setCurExp([...newExperience]);
        if (JSON.stringify(experienceInfo) !== "{}") {
            $("#viewExperienceDiv .row").show();
        }
        if (dataFor === "experienceLevel") {
            editExperinceFix(value);
        }
    }
    const editExperinceFix = (data) => {
        let dataFor = "experienceLevelName";
        let dataId = curExpId;
        let value = skillexperiencelevelsMeta.filter((ele) => ele.skillExperienceId == data)[0].skillExperienceLevel;
        const newExperience = experienceInfo.map(obj => {
            if (obj.id == dataId) {
                return { ...obj, [dataFor]: value };
            }
            return obj;
        });
        setExperienceInfo([...newExperience]);
        setCurExp([...newExperience]);
        if (JSON.stringify(experienceInfo) !== "{}") {
            $("#viewExperienceDiv .row").show();
        }
    }
    const editExperinceSkillFix = (data) => {
        let dataFor = "experienceSkillName";
        let dataId = curExpId;
        let value = skilldetailsMeta.filter((ele) => ele.skillId == data)[0].skillName;
        const newExperience = experienceInfo.map(obj => {
            if (obj.id == dataId) {
                return { ...obj, [dataFor]: value };
            }
            return obj;
        });
        setExperienceInfo([...newExperience]);
        setCurExp([...newExperience]);
        if (JSON.stringify(experienceInfo) !== "{}") {
            $("#viewExperienceDiv .row").show();
        }
    }

    const editExperinceSkillversionFix = (data) => {
        let dataFor = "experienceSoftwareVersionName";
        let dataId = curExpId;
        let value = skillversionMeta.filter((ele) => ele.skillVersionId == data)[0].skillVersionName;
        const newExperience = experienceInfo.map(obj => {
            if (obj.id == dataId) {
                return { ...obj, [dataFor]: value };
            }
            return obj;
        });
        setExperienceInfo([...newExperience]);
        setCurExp([...newExperience]);
        if (JSON.stringify(experienceInfo) !== "{}") {
            $("#viewExperienceDiv .row").show();
        }
    }
    const resetversionList = (e) => {
        var id = e.target.value;
        let idName = e.target.getAttribute("data-for");
        var newskillversionList = skillversionMeta.filter((ele) => ele.skillId == id);
        setCurSkillversionList([...newskillversionList]);
        if (idName == "experienceSkillId") {
            if (id == 0) {
                $("#_skillNameerror").show();
                $("#experienceSkill").addClass("error-shadow")

            }
            else {
                $("#_skillNameerror").hide();
                if ($("#experienceSkill").hasClass('error-shadow'))
                    $("#experienceSkill").removeClass('error-shadow');

            }
        }
        else {
            if (id == 0) {
                $("#experienceSkillerror").show();
                $("#experienceSkill").addClass("error-shadow")

            }
            else {
                $("#experienceSkillerror").hide();
                if ($("#experienceSkill").hasClass('error-shadow'))
                    $("#experienceSkill").removeClass('error-shadow');

            }
        }
    }
    const addExperience = () => {
        let obj = {
            id: experienceInfo.length + 1,
            experienceSkillId: document.getElementById('experienceSkill').value,
            experienceSoftwareVersionId: document.getElementById('experienceSoftwareVersion').value,
            experienceLastUsed: document.getElementById('experienceLastUsed').value,
            experienceYears: document.getElementById('experienceYears').value,
            // experienceMonths: document.getElementById('experienceMonths').value,
            experienceImplementationSize: document.getElementById('experienceImplementationSize').value,
            experienceLevel: document.getElementById('experienceLevel').value,
            experienceLevelName: $("#experienceLevel option:selected").text(),
            experienceSkillName: $("#experienceSkill option:selected").text(),
            experienceSoftwareVersionName: $("#experienceSoftwareVersion option:selected").text()
        }
        var flaglist = allskillcheckValidation()
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;
        if (flag) {
            setExperienceInfo([...experienceInfo, obj]);
            $("#viewexperienceDiv").removeClass("hide");
            $("#experienceImg").hide();
            document.getElementById("addExperienceForm").reset();
            $("#AddExperience .close").trigger('click')
        }


        // else {
        //     console.log("All the mandatory fields for experience should be filled")
        // }
    }
    const skillvalidation = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        if (id === "experienceImplementationSize") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
        }
        if (id == "experienceSoftwareVersion") {
            if (value == 0) {
                $("#experienceSoftwareVersionerror").show();
                $("#experienceSoftwareVersion").addClass("error-shadow")

            }
            else {
                $("#experienceSoftwareVersionerror").hide();
                if ($("#experienceSoftwareVersion").hasClass('error-shadow'))
                    $("#experienceSoftwareVersion").removeClass('error-shadow');
            }
        }
        if (id == "experienceLastUsed") {
            if (value == 0) {
                $("#experienceLastUsederror").show();
                $("#experienceLastUsed").addClass("error-shadow")

            }
            else {
                $("#experienceLastUsederror").hide();
                if ($("#experienceLastUsed").hasClass('error-shadow'))
                    $("#experienceLastUsed").removeClass('error-shadow');
            }
        }
        if (id == "experienceYears") {
            if (value == 0) {
                $("#experienceYearserror").show();
                $("#experienceYears").addClass("error-shadow")

            }
            else {
                $("#experienceYearserror").hide();
                if ($("#experienceYears").hasClass('error-shadow'))
                    $("#experienceYears").removeClass('error-shadow');
            }
        }
        // if (id == "experienceImplementationSize") {
        //     if (value == 0) {
        //         $("#experienceImplementationSizeerror").show();
        //         $("#experienceImplementationSize").addClass("error-shadow")

        //     }
        //     else {
        //         $("#experienceImplementationSizeerror").hide();
        //         if ($("#experienceImplementationSize").hasClass('error-shadow'))
        //             $("#experienceImplementationSize").removeClass('error-shadow');
        //     }
        // }
        if (id == "experienceLevel") {
            if (value == 0) {
                $("#experienceLevelerror").show();
                $("#experienceLevel").addClass("error-shadow")

            }
            else {
                $("#experienceLevelerror").hide();
                if ($("#experienceLevel").hasClass('error-shadow'))
                    $("#experienceLevel").removeClass('error-shadow');
            }
        }
    }
    // const experiencecheckValidation = (id) => {
    //     if (id === "" || id === undefined) {
    //      return allskillcheckValidation()
    //     }
    //     else {
    //      return singlecheckValidation(id)
    //     }
    //   }
    const allskillcheckValidation = () => {
        var flag = true;
        var flaglist = [];
        let obj = {
            id: experienceInfo.length + 1,
            experienceSkillId: document.getElementById('experienceSkill').value,
            experienceSoftwareVersionId: document.getElementById('experienceSoftwareVersion').value,
            experienceLastUsed: document.getElementById('experienceLastUsed').value,
            experienceYears: document.getElementById('experienceYears').value,
            // experienceMonths: document.getElementById('experienceMonths').value,
            experienceImplementationSize: document.getElementById('experienceImplementationSize').value,
            experienceLevel: document.getElementById('experienceLevel').value,
            experienceLevelName: $("#experienceLevel option:selected").text(),
            experienceSkillName: $("#experienceSkill option:selected").text(),
            experienceSoftwareVersionName: $("#experienceSoftwareVersion option:selected").text()
        }
        if (obj.experienceSkillId == 0) {
            $("#experienceSkillerror").show();
            $("#experienceSkill").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#experienceSkillerror").hide();
            if ($("#experienceSkill").hasClass('error-shadow'))
                $("#experienceSkill").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (obj.experienceSoftwareVersionId == 0) {
            $("#experienceSoftwareVersionerror").show();
            $("#experienceSoftwareVersion").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#experienceSoftwareVersionerror").hide();
            if ($("#experienceSoftwareVersion").hasClass('error-shadow'))
                $("#experienceSoftwareVersion").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (obj.experienceLastUsed == 0) {
            $("#experienceLastUsederror").show();
            $("#experienceLastUsed").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#experienceLastUsederror").hide();
            if ($("#experienceLastUsed").hasClass('error-shadow'))
                $("#experienceLastUsed").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (obj.experienceYears == 0) {
            $("#experienceYearserror").show();
            $("#experienceYears").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#experienceYearserror").hide();
            if ($("#experienceYears").hasClass('error-shadow'))
                $("#experienceYears").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        // if (obj.experienceImplementationSize == 0) {
        //     $("#experienceImplementationSizeerror").show();
        //     $("#experienceImplementationSize").addClass("error-shadow")
        //     flag = false;
        //     flaglist.push(flag);

        // }
        // else {
        //     $("#experienceImplementationSizeerror").hide();
        //     if ($("#experienceImplementationSize").hasClass('error-shadow'))
        //         $("#experienceImplementationSize").removeClass('error-shadow');
        //     flag = true;
        //     flaglist.push(flag);
        // }
        if (obj.experienceLevel == 0) {
            $("#experienceLevelerror").show();
            $("#experienceLevel").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#experienceLevelerror").hide();
            if ($("#experienceLevel").hasClass('error-shadow'))
                $("#experienceLevel").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        return flaglist;
    }
    const handleexpdelete = (id) => {
        const deleted = experienceInfo.filter((exp) => exp.id !== id);
        setExperienceInfo(deleted);
    }

    // const editskillvalidation = (e) => {
    //     let id = e.target.getAttribute("data-for");
    //     let value = e.target.value;
    //     var flag;
    //     if (id === "experienceImplementationSize") {
    //         var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
    //         e.target.value = newValue;
    //     }
    //     if (id == "experienceSoftwareVersion") {
    //         if (value == 0) {
    //             $("#_softwareVererror").show();
    //             $("#experienceSoftwareVersion").addClass("error-shadow")
    //             flag = false;
    //             return flag;

    //         }
    //         else {
    //             $("#_softwareVererror").hide();
    //             if ($("#experienceSoftwareVersion").hasClass('error-shadow'))
    //                 $("#experienceSoftwareVersion").removeClass('error-shadow');
    //             flag = true;
    //             return flag;
    //         }
    //     }
    //     if (id == "experienceLastUsed") {
    //         if (value == 0) {
    //             $("#_experienceLastUsederror").show();
    //             $("#experienceLastUsed").addClass("error-shadow")
    //             flag = false;
    //             return flag;
    //         }
    //         else {
    //             $("#_experienceLastUsederror").hide();
    //             if ($("#experienceLastUsed").hasClass('error-shadow'))
    //                 $("#experienceLastUsed").removeClass('error-shadow');
    //             flag = true;
    //             return flag;
    //         }
    //     }
    //     if (id == "experienceYears") {
    //         if (value == 0) {
    //             $("#_experienceYearserror").show();
    //             $("#experienceYears").addClass("error-shadow")
    //             flag = false;
    //             return flag;
    //         }
    //         else {
    //             $("#_experienceYearserror").hide();
    //             if ($("#experienceYears").hasClass('error-shadow'))
    //                 $("#experienceYears").removeClass('error-shadow');
    //             flag = true;
    //             return flag;
    //         }
    //     }
    //     if (id == "experienceImplementationSize") {
    //         if (value == 0) {
    //             $("#_experienceImplementationSizeerror").show();
    //             $("#experienceImplementationSize").addClass("error-shadow")
    //             flag = false;
    //             return flag;
    //         }
    //         else {
    //             $("#_experienceImplementationSizeerror").hide();
    //             if ($("#experienceImplementationSize").hasClass('error-shadow'))
    //                 $("#experienceImplementationSize").removeClass('error-shadow');
    //             flag = true;
    //             return flag;
    //         }
    //     }
    //     if (id == "experienceLevel") {
    //         if (value == 0) {
    //             $("#_experienceLevelerror").show();
    //             $("#experienceLevel").addClass("error-shadow")
    //             flag = false;
    //             return flag;
    //         }
    //         else {
    //             $("#_experienceLevelerror").hide();
    //             if ($("#experienceLevel").hasClass('error-shadow'))
    //                 $("#experienceLevel").removeClass('error-shadow');
    //             flag = true;
    //             return flag;
    //         }
    //     }
    // }
    // Employment Summary//
    const editEmployment = (e) => {

        let dataFor = e.target.getAttribute("data-for");
        let dataId = curEmpId;
        let value = e.target.value;
        if (dataFor === "employmentState") {
            resetCityList(e);
        }
        if (dataFor === "employmentCountry") {
            resetStateList(e);
        }
        if (dataFor === "employmentYears") {
            value = value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = value;
        }

        const newEmployment = employmentInfo.map(obj => {
            if (obj.id == dataId) {
                return { ...obj, [dataFor]: value };
            }
            return obj;
        });

        setEmploymentInfo([...newEmployment]);

        setCurEmp([...newEmployment]);
    }
    const addEmployment = () => {

        let obj = {
            id: employmentInfo.length + 1,
            employmentCompany: document.getElementById('employmentCompany').value,
            employmentCountry: document.getElementById('employmentCountry').value,
            employmentState: document.getElementById('employmentState').value,
            employmentCity: document.getElementById('employmentCity').value,
            employmentDesignation: document.getElementById('employmentDesignation').value,
            employmentYears: document.getElementById('employmentYears').value,
            // employmentSkillId: document.getElementById('employmentSkills').value,
            employmentSkills: document.getElementById('employmentSkills').value
        }
        var flaglist = allcheckEmploymentValidation();
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;

        if (flag) {
            setEmploymentInfo([...employmentInfo, obj]);
            $("#viewEmploymentDiv").removeClass("hide");
            $("#employmentImg").hide();
            document.getElementById("addEmploymentForm").reset();

            $("#AddEmployment .close").trigger('click')
        }

        // else {
        //     console.log("Enter all fields in employment")
        // }
    }
    const handleemploymentdelete = (id) => {
        const deleted = employmentInfo.filter((exp) => exp.id !== id);
        setEmploymentInfo(deleted);
    }
    const handleEmploymentLocation = (e) => {
        let id = e.target.id;
        let idName = e.target.getAttribute("data-for");
        if (id === "employmentState") {
            resetCityList(e);
        }
        if (id === "employmentCountry") {
            resetStateList(e);
        }
        if (idName === "addemploymentCountry") {
            if (id == 0) {
                $("#employmentCountryerror").show();
                $("#employmentCountry").addClass("error-shadow")

            }
            else {
                $("#employmentCountryerror").hide();
                if ($("#employmentCountry").hasClass('error-shadow'))
                    $("#employmentCountry").removeClass('error-shadow');

            }
        }
        if (idName === "addemploymentState") {
            if (id == 0) {
                $("#employmentStateerror").show();
                $("#employmentState").addClass("error-shadow")

            }
            else {
                $("#employmentStateerror").hide();
                if ($("#employmentState").hasClass('error-shadow'))
                    $("#employmentState").removeClass('error-shadow');

            }
        }
        if (idName === "addemploymentCity") {
            if (id == 0) {
                $("#employmentCityerror").show();
                $("#employmentCity").addClass("error-shadow")

            }
            else {
                $("#employmentCityerror").hide();
                if ($("#employmentCity").hasClass('error-shadow'))
                    $("#employmentCity").removeClass('error-shadow');

            }
        }

    }
    // CV Summary
    const editCVInfo = (e) => {
        let dataFor = e.target.getAttribute("data-for");
        let dataId = curCVId;
        let value = "";
        if (dataFor === "cv_attachment") {
            fileUpload(e).then(
                (response) => {
                    var updCVAttachment = response.s3FileUrl;
                    const newCV = uploadCVInfo.map(obj => {
                        if (obj.id == dataId) {
                            return { ...obj, [dataFor]: updCVAttachment };
                        }
                        return obj;
                    });
                    setUploadCVInfo([...newCV]);
                    setcurCVInfo([...newCV]);
                })
        }
        else {
            value = e.target.value;
            const newCV = uploadCVInfo.map(obj => {
                if (obj.id == dataId) {
                    return { ...obj, [dataFor]: value };
                }
                return obj;
            });
            setUploadCVInfo([...newCV]);
            setcurCVInfo([...newCV]);
        }
    }
    const addUploadCVInfo = () => {

        let obj = {
            id: uploadCVInfo.length + 1,
            user_cv_title: document.getElementById('user_cv_title').value,
            cv_attachment: cvAttachmentFile,
        }
        var flaglist = cvValidation();
        if (flaglist.includes(false)) {
            var flag = false;
        }
        else {
            var flag = true;
        }
        if (flag) {
            setUploadCVInfo([...uploadCVInfo, obj]);
            document.getElementById("addCVForm").reset();
            $("#uploadCVBtn").hide();
            $("#UploadCV .close").trigger('click');
        }

        // else {
        //     console.log("CV files are not Provided")
        // }
    }
    // Education Summary//
    const editEducation = (e) => {
        let dataFor = e.target.getAttribute("data-for");
        let dataAffects = e.target.getAttribute("data-affects");
        let dataId = curEduId;
        let value = e.target.value;
        if (dataFor == "educationCompletedYear") {
            if (value == 0 || value > 2022 || value < 1950) {
                return;
            }
            else {
                e.target.value = value
            }
        }
        const newEducation = educationInfo.map(obj => {
            if (obj.id == dataId) {
                obj.educationName = $("#eduname option:selected").text();
                obj.educationCourseName = $("#educoursename option:selected").text();
                obj.educationInstituteName = $("#eduInstitutename option:selected").text();
                obj.educationCourseTypeName = $("#educoursetypename option:selected").text();
                return { ...obj, [dataFor]: value };
            }
            return obj;
        });
        setEducationInfo([...newEducation]);
        setCurEdu([...newEducation]);
    }
    const addEducation = () => {
        let obj = {
            id: educationInfo.length + 1,
            educationId: document.getElementById('educationId').value,
            educationCourseId: document.getElementById('educationCourseId').value,
            educationInstituteId: document.getElementById('educationInstituteId').value,
            educationCourseTypeId: document.getElementById('educationCourseTypeId').value,
            educationCompletedYear: document.getElementById('educationCompletedYear').value,
            educationName: $("#educationId option:selected").text(),
            educationCourseName: $("#educationCourseId option:selected").text(),
            educationInstituteName: $("#educationInstituteId option:selected").text(),
            educationCourseTypeName: $("#educationCourseTypeId option:selected").text(),
        }
        var flaglist = allcheckEducationvalidation();
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;
        if (flag) {
            setEducationInfo([...educationInfo, obj]);
            $("#viewEducationDiv").removeClass("hide");
            $("#educationImg").hide();
            document.getElementById("addEducationForm").reset();
            $("#AddEducation .close").trigger('click');
        }


        // else {
        //     console.log("Education fields should be filled");
        // }
    }

    const handleeducationdelete = (id) => {
        const deleted = educationInfo.filter((exp) => exp.id !== id);
        setEducationInfo(deleted);
    }

    const educationvalid = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        if (id == "educationId") {
            if (value == "0") {
                $("#educationIderror").show();
                $("#educationId").addClass("error-shadow")
            }
            else {
                $("#educationIderror").hide();
                if ($("#educationId").hasClass('error-shadow'))
                    $("#educationId").removeClass('error-shadow');

            }
        }
        if (id == "educationCourseId") {
            if (value == "0") {
                $("#educationCourseIderror").show();
                $("#educationCourseId").addClass("error-shadow")
            }
            else {
                $("#educationCourseIderror").hide();
                if ($("#educationCourseId").hasClass('error-shadow'))
                    $("#educationCourseId").removeClass('error-shadow');
            }
        }
        if (id == "educationInstituteId") {
            if (value == "0") {
                $("#educationInstituteIderror").show();
                $("#educationInstituteId").addClass("error-shadow")
            }
            else {
                $("#educationInstituteIderror").hide();
                if ($("#educationInstituteId").hasClass('error-shadow'))
                    $("#educationInstituteId").removeClass('error-shadow');
            }
        }
        if (id == "educationCourseTypeId") {
            if (value == "0") {
                $("#educationCourseTypeIderror").show();
                $("#educationCourseTypeId").addClass("error-shadow")
            }
            else {
                $("#educationCourseTypeIderror").hide();
                if ($("#educationCourseTypeId").hasClass('error-shadow'))
                    $("#educationCourseTypeId").removeClass('error-shadow');
            }
        }
        if (id == "educationCompletedYear") {
            // if (value == "0") {
            //     $("#educationCompletedYearerror").show();
            //     $("#educationCompletedYear").addClass("error-shadow")
            // }
            // else {
            //     $("#educationCompletedYearerror").hide();
            //     if ($("#educationCourseTypeId").hasClass('error-shadow'))
            //         $("#educationCourseTypeId").removeClass('error-shadow');
            // }
            if (value == 0) {
                $("#educationCompletedYearerror").show();
                $("#educationCompletedYear").addClass("error-shadow")
            }
            else if (value > 2022) {
                $("#educationCompletedYearerror").show();
                $("#educationCompletedYearerror").text("Year value should be less than 2022");
                $("#educationCompletedYear").addClass("error-shadow")
            }
            else if (value < 1950) {
                $("#educationCompletedYearerror").show();
                $("#educationCompletedYearerror").text("Year value should be greater than 1950");
                $("#educationCompletedYear").addClass("error-shadow")
            }
            else {
                $("#educationCompletedYearerror").hide();

                $("#educationCompletedYear").removeClass('error-shadow');
            }
        }

    }
    // Certification Summary//
    const EditCertification = (e) => {
        let dataFor = e.target.getAttribute("data-for");
        let dataId = curCertId;
        let value = "";
        if (dataFor === "certificationFiles") {
            fileUpload(e).then(
                (response) => {
                    var updCert = response.s3FileUrl;
                    setCertificateFile(updCert);
                    const newCert = certificationInfo.map(obj => {
                        if (obj.id == dataId) {
                            return { ...obj, [dataFor]: updCert };
                        }
                        return obj;
                    });
                    setCertificationInfo([...newCert]);
                    setCurCert([...newCert]);
                }).catch(err => console.log(err));
        } else {
            value = e.target.value;
            const newCert = certificationInfo.map(obj => {
                if (obj.id == dataId) {
                    return { ...obj, [dataFor]: value };
                }
                return obj;
            });
            setCertificationInfo([...newCert]);
            setCurCert([...newCert]);
        }
    }
    const addCertification = () => {

        let obj = {
            id: certificationInfo.length + 1,
            certificationTitle: document.getElementById('certificationTitle').value,
            certificationOrganization: document.getElementById('certificationOrganization').value,
            certificationFiles: certificateFile,//document.getElementById('certificationFiles').value,
            certificationCompletionDate: document.getElementById('certificationCompletionDate').value
        }

        var flaglist = certificateValidation();
        if (flaglist.includes(false)) {
            var flag = false;
        }
        else {
            var flag = true;
        }
        if (flag) {
            setCertificationInfo([...certificationInfo, obj]);
            $("#viewCertificationDiv").removeClass("hide");
            $("#certificateImg").hide();
            document.getElementById("addCertificationForm").reset();
            $("#AddCertification .close").trigger('click');
        }

    }

    const certificatevalid = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        if (id == "certificationTitle") {
            if (value == "" || value == null || value == undefined) {
                $("#certificationTitleerror").show();
                $("#certificationTitle").addClass("error-shadow")

            }
            else {
                $("#certificationTitleerror").hide();
                if ($("#certificationTitle").hasClass('error-shadow'))
                    $("#certificationTitle").removeClass('error-shadow');
            }
        }
        if (id == "certificationCompletionDate") {
            if (value == "" || value == null || value == undefined) {
                $("#certificationCompletionDateerror").show();
                $("#certificationCompletionDate").addClass("error-shadow")

            }
            else {
                $("#certificationCompletionDateerror").hide();
                if ($("#certificationCompletionDate").hasClass('error-shadow'))
                    $("#certificationCompletionDate").removeClass('error-shadow');
            }
        }
        if (id == "certificationOrganization") {
            if (value == "" || value == null || value == undefined) {
                $("#certificationOrganizationerror").show();
                $("#certificationOrganization").addClass("error-shadow")

            }
            else {
                $("#certificationOrganizationerror").hide();
                if ($("#certificationOrganization").hasClass('error-shadow'))
                    $("#certificationOrganization").removeClass('error-shadow');
            }
        }

    }

    const handlecertificationdelete = (id) => {
        const deleted = certificationInfo.filter((exp) => exp.id !== id);
        setCertificationInfo(deleted);
    }
    const addUpdIndustry = (e) => {
        let data = $("#experienceIndustry").val();
        data = data.filter((ele) => ele != "0");
        let obj = {
            id: industryExperienceInfo.length + 1,
            industryName: $('#experienceIndustry option:selected').text(),
            industryId: e.target.value,
        }
        setIndustryExperienceInfo(data.toString());
        // setIndustryExperienceInfo([...industryExperienceInfo, obj]);
        setIndustryExperienceDisplay(data);
    }
    const bindDropDownData = () => {
        getCountryStateCityList()
            .then((res) => {
                if (res.response) {
                    var countryDetails = res.response.countryList;
                    var stateDetails = res.response.stateList;
                    var cityDetails = res.response.cityList;
                    var phoneCodeDetails = res.response.phoneCodeList;
                    setCountriesMeta(countryDetails);
                    setStatesMeta(stateDetails);
                    setCurStatesMeta(stateDetails);
                    setCitiesMeta(cityDetails);
                    setCurCityList(cityDetails);
                    setCurEmpCityList(cityDetails);
                    setCountryPhoneCodeMeta(phoneCodeDetails);
                    setCurCountryPhoneCodeMeta(phoneCodeDetails);
                    var countryData = countryDetails.reduce((obj, country) => Object.assign(obj, { [country.countryId]: country.countryName }), {});
                    setCountryRef(countryData);
                    var stateData = stateDetails.reduce((obj, state) => Object.assign(obj, { [state.stateId]: state.stateName }), {});
                    setStateRef(stateData);
                    var cityData = cityDetails.reduce((obj, city) => Object.assign(obj, { [city.cityId]: city.cityName }), {});
                    setCityRef(cityData);
                }
            })
            .catch((e) => {
                //console.log(e.message)
            })
        getNationalityList()
            .then((res) => {
                if (res.response) {
                    setNationalitiesMeta(res.response);
                }
            })
            .catch((e) => {
                //console.log(e.message)
            })
        getLanguageList()
            .then((res) => {
                if (res.response) {
                    setLanguagesMeta(res.response);
                }
            })
            .catch((e) => {
                //console.log(e.message)
            })
        getEducationList()
            .then((res) => {
                if (res.response) {
                    setEducationsMeta(res.response.educationList);
                    setCoursesMeta(res.response.courseList);
                    setInstitutesMeta(res.response.instituteList);
                    setCoursetypesMeta(res.response.coursTypeList);
                }
            })
            .catch((e) => {
                //console.log(e.message)
            })
        getSkillExperienceLevelList()
            .then((res) => {
                if (res.response) {
                    setSkillexperiencelevelsMeta(res.response);
                }
            })
            .catch((e) => {
                //console.log(e.message)
            })
        getSkillDetailslList()
            .then((res) => {
                if (res.response) {
                    var skillDetails = res.response.skillMasterList;
                    var skillVersionDetails = res.response.skillVersionList;
                    setSkillDetailsMeta(skillDetails);
                    setSkillVersionssMeta(skillVersionDetails);
                    var skilldata = skillDetails.reduce((obj, skill) => Object.assign(obj, { [skill.skillId]: skill.skillName }), {});
                    setSkillRef(skilldata);
                    var skillVersiondata = skillVersionDetails.reduce((obj, version) => Object.assign(obj, { [version.skillVersionId]: version.skillVersionName }), {});
                    setSkillVersionRef(skillVersiondata);
                }
            })
            .catch((e) => {
                //console.log(e.message)
            })
        getGenderDetailsList().
            then((res) => {
                if (res.response) {
                    setGenderMeta(res.response)
                }
            })
            .catch((e) => {
                //console.log(e.message)
            })

        getIndustryDetailsList()
            .then((res) => {
                if (res.response) {
                    setIndustryDetails(res.response);
                }
            })
            .catch((e) => {
                console.error('There was an error!', e);
            })
    }
    const resetStateList = (e) => {
        var id = e.target.value;
        var newStateList = statesMeta.filter((ele) => ele.countryId == id);
        setCurStatesMeta([...newStateList]);
    }
    useEffect(() => {
        const employmentStateId = $("#employmentState").val();
        var newCityList = citiesMeta.filter((ele) => ele.stateId == employmentStateId);
        setCurEmpCityList([...newCityList]);
    }, [curStatesMeta])
    useEffect(() => {
        const stateId = $("#state").val();
        var newCityList = citiesMeta.filter((ele) => ele.stateId == stateId);
        setCurCityList([...newCityList]);
    }, [curStatesMeta])
    const resetCityList = (e) => {
        var id = e.target.value;
        var newCityList = citiesMeta.filter((ele) => ele.stateId == id);
        setCurCityList([...newCityList]);
        setCurEmpCityList([...newCityList]);
    }
    const resetCountryCodeList = (e) => {
        var id = e.target.value;
        if (id == "0") {
            setCurCountryPhoneCodeMeta([...countryphonecodeMeta]);
            return;
        }
        var newCodeList = countryphonecodeMeta.filter((ele) => ele.countryId == id);
        setCurCountryPhoneCodeMeta([...newCodeList]);
        // $("#countryPhoneCodeId").val(newCodeList[0].countryPhoneCodeId);
    }
    useEffect(() => {
        if (curCountryphonecodeMeta.length == 1) {
            $("#countryPhoneCodeId").val(curCountryphonecodeMeta[0]?.countryPhoneCodeId);
            singlecheckValidation("countryPhoneCodeId");

        }
        else {
            $("#countryPhoneCodeId").val(0);
        }

    }, [curCountryphonecodeMeta])
    //personal info single check validation
    const singlecheckValidation = (id) => {
        var flag = true;
        var single_flaglist = []

        var obj = {
            firstName: document.getElementById("firstName").value,
            lastName: document.getElementById("lastName").value,
            email: document.getElementById("email").value,
            // gender: document.getElementById("gender").value,
            userName: document.getElementById("userName").value,
            country: document.getElementById("country").value,
            state: document.getElementById("state").value,
            city: document.getElementById("city").value,
            zipCode: document.getElementById("zipCode").value,
            // nationality: document.getElementById("nationality").value,
            phoneNumber: document.getElementById("phoneNumber").value,
            address: document.getElementById("address").value,
            linkedIn: document.getElementById("linkedIn").value,
            countryPhoneCodeId: document.getElementById("countryPhoneCodeId").value
        }
        if (id == "firstName") {
            if (!obj.firstName.trim()) {
                $("#firstnameerror").show();
                $("#firstName").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#firstnameerror").hide();
                if ($("#firstName").hasClass('error-shadow'))
                    $("#firstName").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "lastName") {
            if (!obj.lastName.trim()) {
                $("#lastnameerror").show();
                $("#lastName").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#lastnameerror").hide();
                if ($("#lastName").hasClass('error-shadow'))
                    $("#lastName").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);

            }
        }
        const regemail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        //const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (id == "email") {
            if (!obj.email.trim()) {
                $("#emailerror").show();
                $("#email").addClass("error-shadow");
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            // else if (!regex.test(obj.email)) {
            else if (!regemail.test(obj.email)) {
                $("#emailerror").show();
                $("#emailerror").text("Please enter a valid email address");
                $("#email").addClass("error-shadow");
                flag = false;
                single_flaglist.push(flag);
                return;
            } else {
                $("#emailerror").hide();
                if ($("#email").hasClass('error-shadow'))
                    $("#email").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        // if (id == "gender") {

        //     if (obj.gender == 0) {
        //         $("#gendererror").show();
        //         $("#gender").addClass("error-shadow")
        //         flag = false;
        //         single_flaglist.push(flag);
        //         return;
        //     }
        //     else {
        //         $("#gendererror").hide();
        //         if ($("#gender").hasClass('error-shadow'))
        //             $("#gender").removeClass('error-shadow');
        //         flag = true;
        //         single_flaglist.push(flag);
        //     }
        // }
        if (id == "userName") {


            if (!obj.userName.trim()) {
                $("#existingUser").hide();
                $("#usernameerror").show();
                flag = false;
                single_flaglist.push(flag);
                return;

            }
            else {
                flag = userExists
                if (flag === true) {
                    $("#userName").addClass("error-shadow");
                    flag = false
                    single_flaglist.push(flag);
                    return;
                }
                else {
                    flag = true;
                    single_flaglist.push(flag);
                    if ($("#userName").hasClass('error-shadow'))
                        $("#userName").removeClass('error-shadow');
                }
                $("#usernameerror").hide();
            }
        }
        if (id == "country") {
            if (obj.country == 0) {
                $("#countryerror").show();
                $("#country").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#countryerror").hide();
                if ($("#country").hasClass('error-shadow'))
                    $("#country").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "state") {
            if (obj.state == 0) {
                $("#stateerror").show();
                $("#state").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#stateerror").hide();
                if ($("#state").hasClass('error-shadow'))
                    $("#state").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "city") {
            if (obj.city == 0) {
                $("#cityerror").show();
                $("#city").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#cityerror").hide();
                if ($("#city").hasClass('error-shadow'))
                    $("#city").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        // if (id == "zipCode") {
        //     if (!obj.zipCode.trim()) {
        //         $("#zipcodeerror").show();
        //         $("#zipCode").addClass("error-shadow")
        //         flag = false;
        //         single_flaglist.push(flag);
        //         return;
        //     }
        //     else {
        //         $("#zipcodeerror").hide();
        //         if ($("#zipCode").hasClass('error-shadow'))
        //             $("#zipCode").removeClass('error-shadow');
        //         flag = true;
        //         single_flaglist.push(flag);
        //     }
        // }
        // if (id == "nationality") {
        //     if (obj.nationality == 0) {
        //         $("#nationalityerror").show();
        //         $("#nationality").addClass("error-shadow")
        //         flag = false;
        //         single_flaglist.push(flag);
        //         return;
        //     }
        //     else {
        //         $("#nationalityerror").hide();
        //         if ($("#nationality").hasClass('error-shadow'))
        //             $("#nationality").removeClass('error-shadow');
        //         flag = true;
        //         single_flaglist.push(flag);
        //     }
        // }

        if (id == "countryPhoneCodeId") {
            if (obj.countryPhoneCodeId == 0) {
                $("#countryPhoneCodeIderror").show();
                $("#countryPhoneCodeId").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#countryPhoneCodeIderror").hide();
                if ($("#countryPhoneCodeId").hasClass('error-shadow'))
                    $("#countryPhoneCodeId").removeClass('error-shadow');

                flag = true;
                single_flaglist.push(flag);
            }
        }
        const phoneregex = /^\d{10}$/;

        if (id == "phoneNumber") {
            if (!obj.phoneNumber.trim()) {
                $("#phonenumbererror").show();
                $("#phoneNumber").addClass("error-shadow");

                flag = false;
                single_flaglist.push(flag);
                return;
            }
            // else if (!phoneregex.test(obj.phoneNumber)) {
            else if ((obj.phoneNumber.length > 13)) {
                $("#phonenumbererror").show();
                $("#phonenumbererror").text("Please enter the phone number less than 13 digit");
                // $("#phonenumbererror").show();
                // $("#phonenumbererror").text("Please enter the 10 digit phone number");
                $("#phoneNumber").addClass("error-shadow");
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#phonenumbererror").hide();
                if ($("#phoneNumber").hasClass('error-shadow'))
                    $("#phoneNumber").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }

        }
        if (id == "address") {
            if (!obj.address.trim()) {
                $("#addresserror").show();
                $("#address").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                flag = true;
                single_flaglist.push(flag);
                if ($("#address").hasClass('error-shadow'))
                    $("#address").removeClass('error-shadow');
                $("#addresserror").hide();
            }
        }
        // if (!obj.linkedIn) {
        //     $("#linkedinerror").show();
        //     flag = false;
        // }
        // else {
        //     flag = true;
        //     $("#linkedinerror").hide();
        // }
        return single_flaglist;
    }

    //personal info all check validation
    const allcheckValidation = () => {

        var flag = true;
        var flaglist = []
        var obj = {
            firstName: document.getElementById("firstName").value,
            lastName: document.getElementById("lastName").value,
            email: document.getElementById("email").value,
            // gender: document.getElementById("gender").value,
            userName: document.getElementById("userName").value,
            country: document.getElementById("country").value,
            state: document.getElementById("state").value,
            city: document.getElementById("city").value,
            zipCode: document.getElementById("zipCode").value,
            // nationality: document.getElementById("nationality").value,
            phoneNumber: document.getElementById("phoneNumber").value,
            address: document.getElementById("address").value,
            linkedIn: document.getElementById("linkedIn").value,
            countryPhoneCodeId: document.getElementById("countryPhoneCodeId").value
        }
        if (!obj.firstName.trim()) {
            $("#firstnameerror").show();
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#firstnameerror").hide();
            flag = true;
            flaglist.push(flag);

        }
        if (!obj.lastName.trim()) {
            $("#lastnameerror").show();
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#lastnameerror").hide();
            flag = true;
            flaglist.push(flag);

        }
        const regemail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!obj.email.trim()) {
            $("#emailerror").show();
            flag = false;
            flaglist.push(flag);

        }
        // else if (!regex.test(obj.email)) {
        else if (!regemail.test(obj.email)) {
            $("#emailerror").show();
            $("#emailerror").text("Please enter a valid email address");
            flag = false;
            flaglist.push(flag);

        } else {
            $("#emailerror").hide();
            flag = true;
            flaglist.push(flag);

        }
        // if (obj.gender == 0) {
        //     $("#gendererror").show();
        //     flag = false;
        //     flaglist.push(flag);

        // }
        // else {
        //     $("#gendererror").hide();
        //     flag = true;
        //     flaglist.push(flag);

        // }
        if (!obj.userName.trim()) {
            $("#existingUser").hide();
            $("#usernameerror").show();
            flag = false;
            flaglist.push(flag)
        }
        else {
            flag = userExists
            if (flag === true) {
                $("#userName").addClass("error-shadow");
                flag = false
                flaglist.push(flag);
            }
            else {
                flag = true;
                flaglist.push(flag);
                if ($("#userName").hasClass('error-shadow'))
                    $("#userName").removeClass('error-shadow');
            }
            $("#usernameerror").hide();
        }
        if (obj.country == 0) {
            $("#countryerror").show();
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#countryerror").hide();
            flag = true;
            flaglist.push(flag);

        }
        if (obj.state == 0) {
            $("#stateerror").show();
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#stateerror").hide();
            flag = true;
            flaglist.push(flag);

        }
        if (obj.city == 0) {
            $("#cityerror").show();
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#cityerror").hide();
            flag = true;
            flaglist.push(flag);

        }
        // if (!obj.zipCode.trim()) {
        //     $("#zipcodeerror").show();
        //     flag = false;
        //     flaglist.push(flag);

        // }
        // else {
        //     $("#zipcodeerror").hide();
        //     flag = true;
        //     flaglist.push(flag);

        // }
        // if (obj.nationality == 0) {
        //     $("#nationalityerror").show();
        //     flag = false;
        //     flaglist.push(flag);

        // }
        // else {
        //     $("#nationalityerror").hide();
        //     flag = true;
        //     flaglist.push(flag);

        // }

        if (obj.countryPhoneCodeId == 0) {
            $("#countryPhoneCodeIderror").show();
            $("#countryPhoneCodeId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#countryPhoneCodeIderror").hide();
            if ($("#countryPhoneCodeId").hasClass('error-shadow'))
                $("#countryPhoneCodeId").removeClass('error-shadow');

            flag = true;
            flaglist.push(flag);
        }

        if (!obj.phoneNumber.trim()) {
            $("#phonenumbererror").show();
            flag = false;
            flaglist.push(flag);

        }
        // else if ((obj.phoneNumber.length > 13)) {
        //     $("#phonenumbererror").show();
        //     $("#phonenumbererror").text("Please enter the phone number less than 13 digit");
        //     $("#phoneNumber").addClass("error-shadow");
        //     flag = false;
        //     flaglist.push(flag);

        // }
        else {
            $("#phonenumbererror").hide();
            flag = true;
            flaglist.push(flag);

        }
        if (!obj.address.trim()) {
            $("#addresserror").show();
            flag = false;
            flaglist.push(flag);

        }
        else {
            flag = true;
            $("#addresserror").hide();
            flaglist.push(flag);

        }
        // if (!obj.linkedIn) {
        //     $("#linkedinerror").show();
        //     flag = false;
        // }
        // else {
        //     flag = true;
        //     $("#linkedinerror").hide();
        // }
        return flaglist;
    }
    const checkProfilevalidation = (id) => {
        if (id === "" || id === undefined) {
            return allcheckProfilevalidation()
        }
        else {
            return singlecheckProfilevalidation(id)
        }
    }
    //single check profile 
    const singlecheckProfilevalidation = (id) => {
        var flag = true;
        var single_flaglist = []
        var obj = {
            profileTitle: document.getElementById("profileTitle").value,
            profileSummary: document.getElementById("profileSummary").value,
            profileLanguages: document.getElementById("profileLanguages").value,
            profilePhoto: document.getElementById("profilePicture").value
        }
        if (!obj.profileTitle) {
            $("#profileTitleerror").show();
            flag = false;
            single_flaglist.push(flag);
            return;
        }
        else {
            $("#profileTitleerror").hide();
            flag = true;
            single_flaglist.push(flag);

        }
        if (!obj.profileSummary) {
            $("#profileSummaryerror").show();
            flag = false;
            single_flaglist.push(flag);
            return;
        }
        else {
            $("#profileSummaryerror").hide();
            flag = true;
            single_flaglist.push(flag);

        }
        if (obj.profileLanguages == 0) {
            $("#profilelanguageerror").show();
            flag = false;
            single_flaglist.push(flag);
            return;
        }
        else {
            $("#profilelanguageerror").hide();
            flag = true;
            single_flaglist.push(flag);

        }
        if (!obj.profilePhoto) {
            $("#profilephotoerror").show();
            flag = false;
            single_flaglist.push(flag);
            return;
        }
        else {
            $("#profilephotoerror").hide();
            flag = true;
            single_flaglist.push(flag);

        }
        return single_flaglist;
    };
    //all check profile
    const allcheckProfilevalidation = () => {
        var flag = true;
        var flaglist = []
        var obj = {
            profileTitle: document.getElementById("profileTitle").value,
            profileSummary: document.getElementById("profileSummary").value,
            profileLanguages: document.getElementById("profileLanguages").value,
            profilePhoto: document.getElementById("profilePicture").value
        }
        if (!obj.profileTitle) {
            $("#profileTitleerror").show();
            flag = false;
            flaglist.push(flag)
        }
        else {
            $("#profileTitleerror").hide();
            flag = true;
            flaglist.push(flag)

        }
        if (!obj.profileSummary) {
            $("#profileSummaryerror").show();
            flag = false;
            flaglist.push(flag)

        }
        else {
            $("#profileSummaryerror").hide();
            flag = true;
            flaglist.push(flag)

        }
        if (obj.profileLanguages == 0) {
            $("#profilelanguageerror").show();
            flag = false;
            flaglist.push(flag)

        }
        else {
            $("#profilelanguageerror").hide();
            flag = true;
            flaglist.push(flag)

        }
        if (!obj.profilePhoto) {
            $("#profilephotoerror").show();
            flag = false;
            flaglist.push(flag)

        }
        else {
            $("#profilephotoerror").hide();
            flag = true;
            flaglist.push(flag)

        }
        return flaglist;
    };
    const handleSubmitProfile = () => {
        var flaglist = allcheckProfilevalidation();
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;
        if (flag) {
            $("#ProfileSummary .close").trigger("click");
            return flag;
        }
        else {
            return flag;
        }
    }




    const submitcioregistration = () => {
        $(".finish-btn").addClass('disabled');
        // if (ndaChecked == true && termsChecked == true) {
        // if (cxoackInfo.length > 2 && termsChecked == true) {

        // if (cxoackInfo.length > 2) {

        // if ($('#cocId')[0].checked && $('#privacyPolicyId')[0].checked && $('#ndaId')[0].checked) {
        if ($("#ndaId").is(':checked') && $("#privacyPolicyId").is(':checked') && $("#cocId").is(':checked')) {
            const ackobj = { has_acknowledged: true };
            setAcknowledgementInfo([ackobj]);
            // personalInfo.gender =  personalInfo.gender? personalInfo.gender:"3";
            // personalInfo.nationality =  personalInfo.nationality? personalInfo.nationality:2;
            // personalInfo.zipCode =  personalInfo.zipCode? personalInfo.zipCode:"0";
            // personalInfo.poBoxNo =  personalInfo.poBoxNo? personalInfo.poBoxNo:"0";
            personalInfo.countryPhoneCodeId = personalInfo.countryPhoneCodeId ? personalInfo.countryPhoneCodeId : document.getElementById("countryPhoneCodeId").value;
            personalInfo.state = personalInfo.state ? personalInfo.state : document.getElementById("state").value;
            personalInfo.city = personalInfo.city ? personalInfo.city : document.getElementById("city").value;
            const person_info = [];
            person_info.push(personalInfo);
            const profile = [];
            profile.push(profileInfo);
            const acknowledgement = [];
            acknowledgement.push(ackobj);
            const objdata = {
                "CXOPersonalInfo": person_info,
                "CXOProfileInfo": profile,
                "CXOExperienceInfo": experienceInfo,
                "CXOEmploymentInfo": employmentInfo,
                "CXOEducationInfo": educationInfo,
                "CXOCertificationInfo": certificationInfo,
                "CXOUserCVInfo": uploadCVInfo,
                // "CXOAcknowledgementsInfo": acknowledgement,
                "CXOAcknowledgementsInfo": cxoackInfo,
                "cxoindustryExperienceInfo": industryExperienceInfo
            }
            logger.verbose("CaasMember Registration", "CaasMember Registration Component", "submitcioregistration", "POST", "Caas Member Registration object Created", JSON.stringify(objdata));
            const headers = {
                'Content-Type': 'application/json;charset=utf-8',
            }
            axios.post(CXORegistrationAPI, objdata, {
                headers: headers
            }).then(response => {
                if (response.data.status === 200) {
                    // sendMail();
                    swal("Thank you for registering!", "Please confirm your Registration by verifying the email.", "success", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/auth/caasmember/login', { replace: true }) } });
                    logger.verbose("CaasMember Registration", "CaasMember Registration Component", "submitcioregistration", "POST", "Caas Member Registration API response successfull", JSON.stringify(response.data));
                }
                else {
                    swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false });//.then(okay => { if (okay) { navigate('/', { replace: true }) } })
                    $(".finish-btn").removeClass('disabled');
                    logger.error("CaasMember Registration", "CaasMember Registration Component", "submitcioregistration", "POST", "Caas Member Registration API response Failed", JSON.stringify(response.data));
                }
            }).catch(error => {
                logger.error("CaasMember Registration Failed", "CaasMember Registration Component", "submitcioregistration", "POST", "Caas Member Registration API response Failed", JSON.stringify(error));
                $(".finish-btn").removeClass('disabled');
                swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false });//.then(okay => { if (okay) { navigate('/', { replace: true }) } })
            });
        }
        else {
            $(".finish-btn").removeClass('disabled');
            swal("Please acknowledge NDA, Privacy Policy, Code of Conduct", " ", "error", { closeOnClickOutside: false, closeOnEsc: false })
        }
    }
    // const checkExperiencevalidation = () => {
    //     var flag = true;
    //     var obj = {
    //         experienceSkill: document.getElementById("experienceSkill").value,
    //         experienceSoftwareVersion: document.getElementById('experienceSoftwareVersion').value,


    //         experienceLastUsed: document.getElementById("experienceLastUsed").value,
    //         experienceYears: document.getElementById("experienceYears").value,
    //         // experienceMonths: document.getElementById("experienceMonths").value,
    //         experienceImplementationSize: document.getElementById('experienceImplementationSize').value,

    //         experienceLevel: document.getElementById("experienceLevel").value
    //     }
    //     console.log("The Objects are")
    //     console.log(obj)

    //     if (obj.experienceSkill=="0") {
    //         $("#experienceSkillerror").show();
    //         flag = false;
    //     }
    //     else {
    //         $("#experienceSkillerror").hide();
    //         flag = true;
    //     }
    //     if (obj.experienceSoftwareVersion=="0") {
    //         $("#experienceSoftwareVersionerror").show();
    //         flag = false;
    //     }
    //     else {
    //         $("#experienceSoftwareVersionerror").hide();
    //         flag = true;
    //     }
    //     if (obj.experienceLastUsed=="0") {
    //         $("#experienceLastUsederror").show();
    //         flag = false;
    //     }
    //     else {
    //         $("#experienceLastUsederror").hide();
    //         flag = true;
    //     }
    //     if (obj.experienceYears=="0") {
    //         $("#experienceYearserror").show();
    //         flag = false;
    //     }
    //     else {
    //         $("#experienceYearserror").hide();
    //         flag = true;
    //     }
    //     if (!obj.experienceImplementationSize) {
    //         $("#experienceImplementationSizeerror").show();
    //         flag = false;
    //     }
    //     else {
    //         $("#experienceImplementationSizeerror").hide();
    //         flag = true;
    //     }
    //     if (obj.experienceLevel=="0") {
    //         $("#experienceLevelerror").show();
    //         flag = false;
    //     }
    //     else {
    //         $("#experienceLevelerror").hide();
    //         flag = true;
    //     }
    //     return flag;
    // }
    const allcheckEmploymentValidation = () => {

        var flag = true;
        var flaglist = []
        let obj = {
            id: employmentInfo.length + 1,
            employmentCompany: document.getElementById('employmentCompany').value,
            employmentCountry: document.getElementById('employmentCountry').value,
            employmentState: document.getElementById('employmentState').value,
            employmentCity: document.getElementById('employmentCity').value,
            employmentDesignation: document.getElementById('employmentDesignation').value,
            employmentYears: document.getElementById('employmentYears').value,
            // employmentSkillId: document.getElementById('employmentSkills').value,
            employmentSkills: document.getElementById('employmentSkills').value
        }
        if (!obj.employmentCompany) {
            $("#employmentCompanyerror").show();
            $("#employmentCompany").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#employmentCompanyerror").hide();
            if ($("#employmentCompany").hasClass('error-shadow'))
                $("#employmentCompany").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (obj.employmentCountry == "0") {
            $("#employmentCountryerror").show();
            $("#employmentCountry").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#employmentCountryerror").hide();
            if ($("#employmentCountry").hasClass('error-shadow'))
                $("#employmentCountry").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (obj.employmentState == "0") {
            $("#employmentStateerror").show();
            $("#employmentState").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#employmentStateerror").hide();
            if ($("#employmentState").hasClass('error-shadow'))
                $("#employmentState").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (obj.employmentCity == "0") {
            $("#employmentCityerror").show();
            $("#employmentCity").addClass("error-shadow")

            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#employmentCityerror").hide();
            if ($("#employmentCity").hasClass('error-shadow'))
                $("#employmentCity").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (!obj.employmentDesignation) {
            $("#employmentDesignationerror").show();
            $("#employmentDesignation").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#employmentDesignationerror").hide();
            if ($("#employmentDesignation").hasClass('error-shadow'))
                $("#employmentDesignation").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (!obj.employmentYears) {
            $("#employmentYearserror").show();
            $("#employmentYears").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#employmentYearserror").hide();
            if ($("#employmentYears").hasClass('error-shadow'))
                $("#employmentYears").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (!obj.employmentSkills) {
            $("#employmentSkillserror").show();
            $("#employmentSkills").addClass("error-shadow")

            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#employmentSkillserror").hide();
            if ($("#employmentSkills").hasClass('error-shadow'))
                $("#employmentSkills").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        return flaglist;
    }

    const employmentValidation = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        if (id === "employmentYears") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
        }
        if (id === "employmentCompany") {
            if (value == "" || value == null || value == undefined) {

                $("#employmentCompanyerror").show();
                $("#employmentCompany").addClass("error-shadow")

            }
            else {
                $("#employmentCompanyerror").hide();
                if ($("#employmentCompany").hasClass('error-shadow'))
                    $("#employmentCompany").removeClass('error-shadow');
            }
        }
        if (id === "employmentDesignation") {
            if (value == "" || value == null || value == undefined) {

                $("#employmentDesignationerror").show();
                $("#employmentDesignation").addClass("error-shadow")

            }
            else {
                $("#employmentDesignationerror").hide();
                if ($("#employmentDesignation").hasClass('error-shadow'))
                    $("#employmentDesignation").removeClass('error-shadow');
            }
        }

        if (id === "employmentYears") {
            if (value == "" || value == null || value == undefined) {
                $("#employmentYearserror").show();
                $("#employmentYears").addClass("error-shadow")

            }
            else {
                $("#employmentYearserror").hide();
                if ($("#employmentYears").hasClass('error-shadow'))
                    $("#employmentYears").removeClass('error-shadow');
            }
        }

        if (id === "employmentSkills") {
            if (value == "" || value == null || value == undefined) {
                $("#employmentSkillserror").show();
                $("#employmentSkills").addClass("error-shadow")

            }
            else {
                $("#employmentSkillserror").hide();
                if ($("#employmentSkills").hasClass('error-shadow'))
                    $("#employmentSkills").removeClass('error-shadow');
            }
        }

    }

    const allcheckEducationvalidation = () => {

        var flag = true;
        var flaglist = []
        var obj = {
            educationId: document.getElementById("educationId").value,
            educationCourseId: document.getElementById('educationCourseId').value,
            educationInstituteId: document.getElementById("educationInstituteId").value,
            educationCourseTypeId: document.getElementById("educationCourseTypeId").value,
            educationCompletedYear: document.getElementById('educationCompletedYear').value
        }


        if (obj.educationId == "0") {
            $("#educationIderror").show();
            $("#educationId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#educationIderror").hide();
            if ($("#educationId").hasClass('error-shadow'))
                $("#educationId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);

        }
        if (obj.educationCourseId == "0") {
            $("#educationCourseIderror").show();
            $("#educationCourseId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            if ($("#educationCourseId").hasClass('error-shadow'))
                $("#educationCourseId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);

        }
        if (obj.educationInstituteId == "0") {
            $("#educationInstituteIderror").show();
            $("#educationInstituteId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#educationInstituteIderror").hide();
            if ($("#educationInstituteId").hasClass('error-shadow'))
                $("#educationInstituteId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);

        }
        if (obj.educationCourseTypeId == "0") {
            $("#educationCourseTypeIderror").show();
            $("#educationCourseTypeId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#educationCourseTypeIderror").hide();
            if ($("#educationCourseTypeId").hasClass('error-shadow'))
                $("#educationCourseTypeId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        // if (obj.educationCompletedYear == "0") {
        //     $("#educationCompletedYearerror").show();
        //     $("#educationCompletedYear").addClass("error-shadow")
        //     flag = false;
        //     flaglist.push(flag);
        // }
        // else {
        //     $("#educationCompletedYearerror").hide();
        //     if ($("#educationCourseTypeId").hasClass('error-shadow'))
        //         $("#educationCourseTypeId").removeClass('error-shadow');
        //     flag = true;
        //     flaglist.push(flag);
        // }
        if (obj.educationCompletedYear == 0) {
            $("#educationCompletedYearerror").show();
            $("#educationCompletedYear").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else if (obj.educationCompletedYear > 2022) {
            $("#educationCompletedYearerror").show();
            $("#educationCompletedYearerror").text("Year value should be less than 2022");
            $("#educationCompletedYear").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else if (obj.educationCompletedYear < 1950) {
            $("#educationCompletedYearerror").show();
            $("#educationCompletedYearerror").text("Year value should be greater than 1950");
            $("#educationCompletedYear").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#educationCompletedYearerror").hide();
            $("#educationCompletedYear").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        return flaglist;
    }



    const certificateValidation = () => {
        var flag = true;
        var flaglist = []
        var obj = {
            certificationTitle: document.getElementById("certificationTitle").value,
            certificationCompletionDate: document.getElementById('certificationCompletionDate').value,
            certificationOrganization: document.getElementById("certificationOrganization").value,
            certificationFiles: document.getElementById("certificationFiles").value
        }

        if (!obj.certificationTitle) {
            $("#certificationTitleerror").show();
            $("#certificationTitle").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#certificationTitleerror").hide();
            if ($("#certificationTitle").hasClass('error-shadow'))
                $("#certificationTitle").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);

        }
        if (!obj.certificationCompletionDate) {
            $("#certificationCompletionDateerror").show();
            $("#certificationCompletionDate").addClass("error-shadow")

            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#certificationCompletionDateerror").hide();
            if ($("#certificationCompletionDate").hasClass('error-shadow'))
                $("#certificationCompletionDate").removeClass('error-shadow');

            flag = true;
            flaglist.push(flag);

        }
        if (!obj.certificationOrganization) {
            $("#certificationOrganizationerror").show();
            $("#certificationOrganization").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#certificationOrganizationerror").hide();
            if ($("#certificationOrganization").hasClass('error-shadow'))
                $("#certificationOrganization").removeClass('error-shadow');

            flag = true;
            flaglist.push(flag);

        }
        // if (!obj.certificationFiles) {
        //     $("#certificationFileserror").show();
        //     $("#certificationFiles").addClass("error-shadow")
        //     flag = false;
        //     flaglist.push(flag);

        // }
        // else {
        //     $("#certificationFileserror").hide();
        //     if ($("#certificationFiles").hasClass('error-shadow'))
        //         $("#certificationFiles").removeClass('error-shadow');

        //     flag = true;
        //     flaglist.push(flag);

        // }
        return flaglist;
    }
    //cv validation

    const cvvalid = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        if (id == "user_cv_title") {
            if (value == "" || value == null || value == undefined) {
                $("#cvTitleerror").show();
                $("#user_cv_title").addClass("error-shadow");

            }
            else {
                $("#cvTitleerror").hide();
                if ($("#user_cv_title").hasClass('error-shadow'))
                    $("#user_cv_title").removeClass('error-shadow');
            }
        }
    }
    const cvValidation = () => {
        var flag = true;
        var flaglist = []
        var obj = {
            cvTitle: document.getElementById("user_cv_title").value,
            cvAttachment: document.getElementById("cv_attachment").value,
        }
        if (!obj.cvTitle) {
            $("#cvTitleerror").show();
            $("#user_cv_title").addClass("error-shadow")

            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#cvTitleerror").hide();
            if ($("#user_cv_title").hasClass('error-shadow'))
                $("#user_cv_title").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);

        }
        if (!obj.cvAttachment) {
            $("#cvAttachementerror").show();
            $("#cv_attachment").addClass("error-shadow")

            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#cvAttachementerror").hide();
            if ($("#cv_attachment").hasClass('error-shadow'))
                $("#cv_attachment").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);

        }
        return flaglist;
    }
    const fileUpload = async (e) => {
        const file = e.target.files[0];
        // const fileName = new Date().toISOString() + "|" +  e.target.files[0].name;
        var fileName = e.target.files[0].name;   
        var fileExtension =fileName.split('.').pop();  
        fileName +=  S3Config.S3ObjectDelimiter + uuidCustom() +"."+ fileExtension;
        const contentType = file.type;
        const formData = { file, fileName, contentType };
        let result = await S3FileUpload(formData).then(
            (response) => {
                if (response.status === 200) {
                    return { status: 200, s3FileUrl: S3Config.S3ObjectBaseURL + fileName }
                }
            }
        ).catch(err => console.log(err));
        return result;
    }
    const uploadCertificateFile = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        // if (id == "certificationFiles") {
        //     if (value == "" || value == undefined || value == null) {
        //         $("#certificationFileserror").show();
        //         $("#certificationFiles").addClass("error-shadow")
        //     }
        //     else {
        //         $("#certificationFileserror").hide();
        //         if ($("#certificationFiles").hasClass('error-shadow'))
        //             $("#certificationFiles").removeClass('error-shadow');
        //     }
        // }
        fileUpload(e).then(
            (response) => {
                setCertificateFile(response.s3FileUrl);
            }).catch(err => console.log(err));
    }
    const addCVAttachmentFile = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        if (id == "cv_attachment") {
            if (value == "" || value == null || value == undefined) {
                $("#cvAttachementerror").show();
                $("#cv_attachment").addClass("error-shadow")
            }
            else {
                $("#cvAttachementerror").hide();
                if ($("#cv_attachment").hasClass('error-shadow'))
                    $("#cv_attachment").removeClass('error-shadow');
            }
        }

        fileUpload(e).then(
            (response) => {
                setCVAttachmentFile(response.s3FileUrl);
            }).catch(err => console.log(err));
    }
    const sendMail = () => {
        Emailer(emailform.current);
    }
    function userValidationCheck(username) {
        username = JSON.stringify(username).replaceAll('"', '')
        let url = `${CXOUsernameCheckAPI}?username=${username}}`
        axios.get(`${CXOUsernameCheckAPI}?username=${username}`)
            .then(response => {
                // console.log(response.data);
                if (response.data === true) {
                    $("#userName").addClass("error-shadow");
                    $('#usernameerror').hide();
                    $("#existingUser").show();
                    setUserExists(true);

                    return response.data

                }
                else {
                    if ($("#userName").hasClass("error-shadow"))
                        $("#userName").removeClass("error-shadow");
                    $("#existingUser").hide();
                    setUserExists(false);
                    return response.data
                }

            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    const handleNDACheck = () => {

        setNDAChecked(!ndaChecked);

    };

    // const handleTermCheck = () => {
    //     setTermsChecked(!termsChecked);
    // if(!termsChecked)
    // {
    //     if (cxoackInfo.length < 3){
    //         document.getElementById('sign-btn').click();
    //     }

    // }
    // };
    return (
        <>
            <div className="sidebar-icon-only with-welcome-text" data-new-gr-c-s-check-loaded="14.1072.0" data-gr-ext-installed="">
                <div className="container-scroller">
                    <AuthHeader />
                    <div className="container-fluid page-body-wrapper" id="steps-uid-0">
                        <nav className="sidebar sidebar-offcanvas" id="sidebar">
                            <ul className="nav" id="sideNavTabs">
                                <li className="nav-item title-card" id="cio_reg"><a className="nav-link text-center pl_2 br-btm" href="#"><span className="menu-title2 sidebar-title main-title">{t('caasMemberRegistration')}</span></a></li>
                                <li className="nav-item active current"><a className="nav-link" id="steps-uid-0-t-0" href="#steps-uid-0-h-0" aria-controls="steps-uid-0-p-0"> <i className="mdi mdi-account-check menu-icon"></i><span className="menu-title1 sidebar-title" aria-controls="steps-uid-0-p-0" >{t('personalInformation')}</span> </a> </li>
                                <li className="nav-item disabled-wizard"><a className="nav-link" id="steps-uid-0-t-1" href="#steps-uid-0-h-1" aria-controls="steps-uid-0-p-1"><i className="mdi mdi-account menu-icon"></i><span className="menu-title2 sidebar-title" aria-controls="steps-uid-0-p-1"> {t('profile')}</span></a></li>
                                <li className="nav-item disabled-wizard"><a className="nav-link" id="steps-uid-0-t-2" href="#steps-uid-0-h-2" aria-controls="steps-uid-0-p-2"><i className="mdi mdi-account-star menu-icon"></i><span className="menu-title3 sidebar-title" aria-controls="steps-uid-0-p-2">{t('experience')}</span></a></li>
                                <li className="nav-item disabled-wizard"><a className="nav-link" id="steps-uid-0-t-3" href="#steps-uid-0-h-3" aria-controls="steps-uid-0-p-3"><i className="mdi mdi-comment-account menu-icon"></i><span className="menu-title4 sidebar-title" aria-controls="steps-uid-0-p-3"> {t('employment')}</span></a></li>
                                <li className="nav-item disabled-wizard"><a className="nav-link" id="steps-uid-0-t-4" href="#steps-uid-0-h-4" aria-controls="steps-uid-0-p-4"><i className="mdi mdi mdi-chart-bar menu-icon"></i><span className="menu-title5 sidebar-title" aria-controls="steps-uid-0-p-4">{t('education')}</span></a></li>
                                <li className="nav-item disabled-wizard"><a className="nav-link" id="steps-uid-0-t-5" href="#steps-uid-0-h-5" aria-controls="steps-uid-0-p-5"><i className="fa fa-graduation-cap menu-icon"></i><span className="menu-title6 sidebar-title" aria-controls="steps-uid-0-p-5">{t('certification')}</span></a></li>
                                <li className="nav-item disabled-wizard"><a className="nav-link" id="steps-uid-0-t-6" href="#steps-uid-0-h-6" aria-controls="steps-uid-0-p-6"><i className="fa fa-file-text menu-icon"></i><span className="menu-title7 sidebar-title" aria-controls="steps-uid-0-p-6"> {t('uploadCV')}</span></a></li>
                                <li className="nav-item disabled-wizard"><a className="nav-link" id="steps-uid-0-t-7" href="#steps-uid-0-h-7" aria-controls="steps-uid-0-p-7"><i className="fa fa-eye menu-icon"></i><span className="menu-title8 sidebar-title" aria-controls="steps-uid-0-p-7">{t('preview')}</span></a></li>
                                <li className="nav-item disabled-wizard"><a className="nav-link" id="steps-uid-0-t-8" href="#steps-uid-0-h-8" aria-controls="steps-uid-0-p-8"><i className="fa fa-thumbs-up menu-icon"></i><span className="menu-title9 sidebar-title" aria-controls="steps-uid-0-p-8">{t('acknowledgement')}</span></a></li>
                            </ul>
                        </nav>
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-12 grid-margin stretch-card">
                                        <div className="col-12">
                                            <div className="row" id="personalInfoDiv">
                                                <section className="body current mainSection" id="steps-uid-0-p-0" role="tabpanel" aria-labelledby="steps-uid-0-h-0" aria-hidden="false">
                                                    <div className="col-md-12 grid-margin stretch-card">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h4 className="card-title">
                                                                    {t('hpersonalInformation')}<a className="add_pi btn-primary add-pi float-right btn-rounded btn-icon" id="addpi_btn">
                                                                        <i className="ti-plus"></i>
                                                                    </a>
                                                                </h4>
                                                                <div className="hide" id="personalInfoInnerDiv">
                                                                    <div className="content clearfix">
                                                                        <div className="row mb-0">
                                                                            <div className="form-group col-12 col-sm-6">
                                                                                <label>{t('firstName')} <span className="error">*</span></label>
                                                                                <input type="text" className="form-control" data-for="firstName" placeholder={t('firstName')} id="firstName" onChange={handleInput} />
                                                                                <label id="firstnameerror" className="error hide">First Name is required</label>
                                                                            </div>
                                                                            <div className="form-group col-12 col-sm-6">
                                                                                <label>{t('lastName')} <span className="error">*</span></label>
                                                                                <input type="text" className="form-control" placeholder={t('lastName')} id="lastName" onChange={handleInput} />
                                                                                <label id="lastnameerror" className="error hide">Last Name is required</label>
                                                                            </div>
                                                                            <div className="form-group col-12 col-sm-6">
                                                                                <label>{t('email')} <span className="error">*</span></label>
                                                                                <input type="email" className="form-control" placeholder={t('email')} id="email" onChange={handleInput} />
                                                                                <label id="emailerror" className="error hide">Email Address is required</label> </div>
                                                                            {/* <div className="form-group col-12 col-sm-3">
                                                                                <label>{t('mobilePhone')} <span className="error">*</span></label>
                                                                                <input type="text" className="form-control" placeholder={t('mobilePhone')} id="phoneNumber" maxLength="10" onChange={handleInput} />
                                                                                <label id="phonenumbererror" className="error hide">PhoneNumber is required</label>
                                                                            </div> */}
                                                                            <div className="form-group col-12 col-sm-6">
                                                                                <label>{t('mobilePhone')} <span className="error">*</span></label>
                                                                                <div className="col-12 col-sm-12 d-flex">
                                                                                    <select className="form-select w-20" id="countryPhoneCodeId" onChange={handleInput}>
                                                                                        <option value="0">Code</option>
                                                                                        {
                                                                                            curCountryphonecodeMeta.length != 0 ? (
                                                                                                curCountryphonecodeMeta.map((ele, index) => (
                                                                                                    <option key={index} value={ele.countryPhoneCodeId}>{ele.countryPhoneCode} {ele.countryCode}</option>
                                                                                                ))
                                                                                            ) : null
                                                                                        }
                                                                                    </select>
                                                                                    <input type="text" className="form-control" placeholder={t('mobilePhone')} id="phoneNumber" maxLength="12" onChange={handleInput} />
                                                                                </div>
                                                                                <label id="countryPhoneCodeIderror" className="error hide">Phone Code is required</label>&nbsp;&nbsp;&nbsp;
                                                                                <label id="phonenumbererror" className="error hide">PhoneNumber is required</label>
                                                                            </div>
                                                                            <div className="form-group col-12 col-sm-6 suffix-email">
                                                                                <label className="d-block">{t('userName')} <span className="error">*</span></label>
                                                                                <div><input type="text" className="form-control suffix-email1" placeholder={t('userName')} id="userName" onChange={handleInput} /></div>
                                                                                <label id="usernameerror" className="error hide">User Name is required</label>
                                                                                <label id="existingUser" className="error hide">User Name already exists</label>
                                                                            </div>
                                                                            <div className="form-group col-12 col-sm-6 mt-4 pt-3">
                                                                                <div className="text-left"><label className="d-block"><p className="text-danger fw-bold">({t('thisIsYourLoginId')})</p></label></div>
                                                                            </div>
                                                                            <div className="form-group col-12 col-sm-6">
                                                                                <label>{t('country')} <span className="error">*</span></label>
                                                                                <select className="form-select" id="country" onChange={handleInput}>
                                                                                    <option value="0" key={0}>{t('country')}</option>
                                                                                    {
                                                                                        countriesMeta.length != 0 ? (
                                                                                            countriesMeta.map((ele, index) => (
                                                                                                <option key={index} value={ele.countryId}>{ele.countryName}</option>
                                                                                            ))
                                                                                        ) : null
                                                                                    }
                                                                                </select>
                                                                                <label id="countryerror" className="error hide">Country not selected</label>
                                                                            </div>
                                                                            <div className="form-group col-12 col-sm-3">
                                                                                <label>{t('state')} <span className="error">*</span></label>
                                                                                <select className="form-select" id="state" onChange={handleInput}>
                                                                                    <option value="0" key={0}>{t('state')}</option>
                                                                                    {
                                                                                        curStatesMeta.length != 0 ? (
                                                                                            curStatesMeta.map((ele, index) => (
                                                                                                <option key={index} value={ele.stateId}>{ele.stateName}</option>
                                                                                            ))
                                                                                        ) : null
                                                                                    }
                                                                                </select>
                                                                                <label id="stateerror" className="error hide">State not selected</label>
                                                                            </div>
                                                                            <div className="form-group col-12 col-sm-3">
                                                                                <label>{t('city')} <span className="error">*</span></label>
                                                                                <select className="form-select" id="city" onChange={handleInput}>
                                                                                    <option value="0" key={0}>{t('city')}</option>
                                                                                    {
                                                                                        curCityList.length != 0 ? (
                                                                                            curCityList.map((ele, index) => (
                                                                                                <option key={index} value={ele.cityId}>{ele.cityName}</option>
                                                                                            ))
                                                                                        ) : null
                                                                                    }
                                                                                </select>
                                                                                <label id="cityerror" className="error hide">City not selected</label>
                                                                            </div>

                                                                            <div className="form-group col-12 col-sm-6">
                                                                                <label>{t('linkedIn')}</label>
                                                                                <input type="text" className="form-control" placeholder={t('linkedIn')} id="linkedIn" onChange={handleInput} />
                                                                                <label id="linkedinerror" className="error hide">LinkedIn Profile is required</label>
                                                                            </div>
                                                                            <div className="form-group col-12 col-sm-3">
                                                                                <label>{t('nationality')} </label>
                                                                                <select className="form-select" id="nationality" onChange={handleInput}>
                                                                                    <option value="0" key={0}>{t('select')}</option>
                                                                                    {
                                                                                        nationalitiesMeta.length != 0 ? (
                                                                                            nationalitiesMeta.map((ele, index) => (
                                                                                                <option key={index} value={ele.nationalityId}>{ele.nationalityName}</option>
                                                                                            ))
                                                                                        ) : null
                                                                                    }
                                                                                </select>
                                                                                {/* <label id="nationalityerror" className="error hide">Nationality not selected</label> */}
                                                                            </div>
                                                                            <div className="form-group col-12 col-sm-3">
                                                                                <label>{t('gender')} </label>
                                                                                <select className="form-select" id="gender" onChange={handleInput}>
                                                                                    <option value="0" key={0}>{t('gender')}</option>
                                                                                    {
                                                                                        genderMeta.length != 0 ? (
                                                                                            genderMeta.map((ele, index) => (
                                                                                                <option key={index} value={ele.genderId}>{ele.genderName}</option>
                                                                                            ))
                                                                                        ) : null
                                                                                    }
                                                                                </select>
                                                                                {/* <label id="gendererror" className="error hide">Gender not selected</label> */}
                                                                            </div>
                                                                            <div className="form-group col-12 col-sm-6 mb-lg-5">
                                                                                <label>{t('address')} <span className="error">*</span></label>
                                                                                <textarea className="form-control" placeholder={t('address')} id="address" onChange={handleInput}></textarea>
                                                                                <label id="addresserror" className="error hide">Address is required</label>
                                                                            </div>

                                                                            <div className="form-group col-12 col-sm-3">
                                                                                <label>{t('zipCode')} </label>
                                                                                <input type="text" className="form-control" placeholder={t('zipCode')} id="zipCode" maxLength="6" onChange={handleInput} />
                                                                                {/* <label id="zipcodeerror" className="error hide">ZipCode is required</label> */}
                                                                            </div>
                                                                            <div className="form-group col-12 col-sm-3 mb-5">
                                                                                <label>PO Box </label>
                                                                                <input type="text" className="form-control" placeholder="PO Box" id="poBoxNo" maxLength="6" onChange={handleInput} />
                                                                                {/* <label id="poboxeerror" className="error hide">PO Box is required</label> */}
                                                                            </div>

                                                                            {/* {personalInfo.zipCode && <div className="form-group col-12 col-sm-12">
                                                                                        <label>GEO Location</label>
                                                                                        <div className="mapouter img-thumbnail"><div className="gmap_canvas">
                                                                                            <iframe className="gmap_iframe" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={getMap}>
                                                                                            </iframe> 
                                                                                        </div></div>
                                                                                    </div>} */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="text-center mt_50 disp_hide" id="dispshow">
                                                                    <img src={cioImg} className="img-responsive pi-image" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                            <div className="row" id="profileDiv">

                                                <section id="steps-uid-0-p-1" className="mainSection body" role="tabpanel" aria-labelledby="steps-uid-0-h-1" aria-hidden="true" style={{ display: "none" }}>
                                                    <div className="col-12 grid-margin stretch-card">
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-md-12 grid-margin stretch-card">
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <h4 className="card-title">
                                                                                {t('hprofile')}
                                                                                {/* <a className="addProfileBtn btn-primary add-pi float-right btn-rounded btn-icon" data-bs-toggle="modal" data-bs-target="#ProfileSummary" id="addProfileBtn"><i className="ti-plus"></i></a> */}
                                                                            </h4>
                                                                            <div className="card-body border mt-0 text-left box-shadow" id="viewProfileDiv">
                                                                                {/* style={{ display: "none" }} */}
                                                                                <a href="#" className="edit-profile"> <i className="fa fa-pencil fa-lg float-end text-muted pt-2" title="Edit" data-bs-toggle="modal" data-bs-target="#ProfileSummary"></i></a>
                                                                                <div className="row">
                                                                                    <div className='col-lg-3 col-md-3 col-sm-12'>
                                                                                    <a className="position-relative profilepicframe  text-link inline-block" data-bs-toggle="modal" data-bs-target="#ProfileSummary">
                                                                                        <img src={previewProfilePicture} alt="CIO Image" className="img-lg mb-2 w-130 h-130" />
                                                                                    </a>
                                                                                    </div>
                                                                                    <div className="col-lg-9 col-md-9 col-sm-12 mob-top-5">
                                                                                    <div className="inline-block ps-4">
                                                                                        <h4 className="profile-name pb_10">{personalInfo.firstName} {personalInfo.lastName}</h4>
                                                                                        {/* <p className="mb-0 profile-info pb_10">{profileInfo.profileTitle} &nbsp;&nbsp; | &nbsp;&nbsp; {cxoLanguages?.map(x => x.languageName).join(",")}</p> */}
                                                                                            <p className="mb-0 profile-info pb_10">{profileInfo.profileTitle} </p>
                                                                                            <p className="mb-0 profile-info pb_10">{cxoLanguages?.map(x => x.languageName).join(",")}</p>
                                                                                            <p className="mb-0 profile-info">{profileInfo.profileSummary}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex-row">


                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                            <div className="row" id="experienceDiv">
                                                <section id="steps-uid-0-p-2" className="mainSection body" role="tabpanel" aria-labelledby="steps-uid-0-h-2" aria-hidden="true" style={{ display: "none" }}>
                                                    <div className="col-md-12 grid-margin stretch-card">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h4 className="card-title">
                                                                    {t('hexperience')}
                                                                    <span className="fw-noraml">  ({t('minimum1SkillRequired')})</span>
                                                                    <a href="#" id="addExperienceBtn" data-bs-toggle="modal" data-bs-target="#AddExperience" className="btn-primary add-pi float-right btn-rounded btn-icon">
                                                                        <i className="ti-plus"></i>
                                                                    </a>
                                                                </h4>
                                                                <div className="hide" id="viewexperienceDiv">
                                                                    <div className="row">
                                                                        {
                                                                            experienceInfo.length != 0 ? (
                                                                                experienceInfo.map((ele, index) => (
                                                                                    <>
                                                                                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 custom-width_125" key={index}>
                                                                                            <div className="cardPad card-body border border-radius mt-0 text-left box-shadow">
                                                                                            <div className='card-header border-0'>
                                                                                            <div className="widgetHead float-right">
                                                                                                        <a href="#" className="delete-experience" > <i className="fa-solid fa-trash fa-lg float-end text-muted pt-2" title="Delete Experience" data-bs-toggle="modal" data-bs-target="#DeleteExperience" id="deleteExperienceBtn" data-for={ele.id} onClick={() => {
                                                                                                            handleexpdelete(ele.id)
                                                                                                        }}></i></a>&nbsp;
                                                                                                        <a href="#" className="edit-profile" > <i className="fa fa-pencil fa-lg float-end text-muted pt-2" title="Edit Experience" data-bs-toggle="modal" data-bs-target="#EditExperience" id="editExperienceBtn" data-for={ele.id} onClick={() => {
                                                                                                            setcurExpId(ele.id)
                                                                                                        }}></i></a>
                                                                                                    </div>
                                                                                            </div>
                                                                                            <div className='experience-section'>
                                                                                                <ul>
                                                                                                    <li><b className="float-start fw-bold">{t('skill')}:</b> </li>
                                                                                                    <li className='expereince-list-details ml_30 ml-pc-8'>{skillRef[ele.experienceSkillId]}</li>
                                                                                                    <li><b className="float-start fw-bold">{t('softwareVersion')}:</b></li>
                                                                                                    <li className='expereince-list-details ml_30 ml-pc-8'>{skillVersionRef[ele.experienceSoftwareVersionId]}</li>
                                                                                                    <li><b className="float-start fw-bold">{t('lastUsed')}:</b></li>
                                                                                                    <li className='expereince-list-details ml_30 ml-pc-8'>{ele.experienceLastUsed}</li>
                                                                                                    <li><b className="float-start fw-bold">{t('experience')}:</b></li>
                                                                                                    <li className='expereince-list-details ml_30 ml-pc-8'>{ele.experienceYears} {t('years')}</li>
                                                                                                    <li><b className="float-start fw-bold">{t('implementationSize')}:</b> </li>
                                                                                                    <li className='expereince-list-details ml_30 ml-pc-8'> {ele.experienceImplementationSize} {t('users')}</li>
                                                                                                    <li><b className="float-start fw-bold">{t('experienceLevel')}:</b> </li>
                                                                                                    <li className='expereince-list-details ml_30 ml-pc-8'>{ele.experienceLevelName}</li>
                                                                                                </ul>
                                                                                            </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                ))
                                                                            ) : (
                                                                                <p>{t('noRecordsAvailable')}</p>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <h4 className="card-title mt_50">
                                                                        {t('hindustry')}
                                                                    </h4>
                                                                    <a href="#" className="edit-profile"> <i className="fa fa-pencil fa-lg float-end text-muted pt-4 pr_30" title="Edit" data-bs-toggle="modal" data-bs-target="#Industry"></i></a>
                                                                    <div className="cardPad card-body border border-radius box-shadow pb_30 pt-5">
                                                                        {/* {
                                                                            industryExperienceInfo.length != 0 ? (
                                                                                industryExperienceInfo.map((ele) => (
                                                                                    <>
                                                                                        <button type="button" className="btn industry-list btn-outline-secondary btn-sm ml_20"> {ele} </button>
                                                                                    </>
                                                                                ))
                                                                            ) : (
                                                                                <p>{t('noRecordsAvailable')}</p>
                                                                            )
                                                                        } */}
                                                                        {
                                                                            industryExperiencedisplay.length != 0 ? (
                                                                                industryExperiencedisplay.map((ele) => (
                                                                                    industrydetails.map((exp) => (
                                                                                        ele == exp.industryId ?
                                                                                            <button type="button" className="btn industry-list btn-outline-secondary btn-sm ml_20 mb-3" value={exp.industryId}> {exp.industryName} </button> :
                                                                                            <></>
                                                                                    ))
                                                                                ))
                                                                            ) : (
                                                                                <>
                                                                                    {/* <a href="#" className="edit-profile"> <i className="fa-solid fa-plus float-end text-muted pt-4 pr_30" title="Edit" data-bs-toggle="modal" data-bs-target="#Industry"></i></a>
                                                                                        <div className="cardPad card-body box-shadow pb_30"> */}
                                                                                    <p>{t('noRecordsAvailable')}</p>
                                                                                    {/* </div> */}
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="text-center mt_20" id="experienceImg">
                                                                    <img src={experienceImg} className="img-responsive pi-image" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                            <div className="row" id="employmentDiv">
                                                <section id="steps-uid-0-p-3" className="mainSection body" role="tabpanel" aria-labelledby="steps-uid-0-h-3" aria-hidden="true" style={{ display: "none" }}>
                                                    <div className="col-md-12 grid-margin stretch-card">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h4 className="card-title">
                                                                    {t('hemployment')}
                                                                    <a className="btn-primary add-pi float-right btn-rounded btn-icon" data-bs-toggle="modal" data-bs-target="#AddEmployment">
                                                                        <i className="ti-plus"></i>
                                                                    </a>
                                                                </h4>
                                                                <div className="hide" id="viewEmploymentDiv">
                                                                    <div className='row'>
                                                                    {
                                                                        employmentInfo.length != 0 ? (
                                                                            employmentInfo.map((ele) => (
                                                                                <>
										    <div className='col-md-12 col-lg-6 col-sm-12 custom-width_125'>
                                                                                    <div className="cardPad card-body border border-radius box-shadow">
                                                                                    <div className="widgetHead float-right">
                                                                                        <a href="#" className="delete-employment" > <i className="fa-solid fa-trash fa-lg float-end text-muted pt-4 mr_10" title="Delete Employment" data-bs-toggle="modal" data-bs-target="#DeleteEmployment" id="deleteEmploymentBtn" data-for={ele.id} onClick={() => {
                                                                                            handleemploymentdelete(ele.id)
                                                                                        }}></i></a>
                                                                                        <a href="#" className="edit-profile"> <i className="fa fa-pencil fa-lg float-end text-muted pt-4" title="Edit" data-bs-toggle="modal" data-bs-target="#EditEmployment" id="editEmploymentBtn" data-for={ele.id} onClick={() => {
                                                                                            setcurEmpId(ele.id)
                                                                                        }}></i></a>
                                                                                    </div>
                                                                                  
                                                                                        <div className="d-flex justify-content-between align-items-start">
                                                                                            <div className="widgetCont col-sm-12 mt-0">
                                                                                                <ul>
                                                                                                        <li> <span className="float-start fw-bold" >{t('companyName')}: </span></li>
                                                                                                        <li className='employment-list-details ml_30 ml-pc-8'>{ele.employmentCompany}</li>
                                                                                                        <li><span className="float-start fw-bold" >{t('designation')}: </span></li>
                                                                                                        <li className='employment-list-details ml_30 ml-pc-8'> {ele.employmentDesignation}</li>
                                                                                                        <li>   <span className="float-start fw-bold"> {t('yearsOfExperience')}: </span></li>
                                                                                                        <li className='employment-list-details ml_30 ml-pc-8'>{ele.employmentYears}</li>
                                                                                                        <li>  <span className="float-start fw-bold" > {t('skillsUsed')}: </span></li>
                                                                                                        <li className='employment-list-details ml_30 ml-pc-8'>{ele.employmentSkills}</li>
                                                                                                        <li><span className="float-start fw-bold"> {t('companyLocation')}: </span></li>
                                                                                                        <li className='employment-list-details ml_30 ml-pc-8'>{cityRef[ele?.employmentCity]}, {stateRef[ele?.employmentState]},{countryRef[ele?.employmentCountry]}</li>
                                                                                                        
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            ))
                                                                        ) : (
                                                                            <p>{t('noRecordsAvailable')}</p>
                                                                        )
                                                                    }
                                                                </div>
                                                                </div>
                                                                <div className="text-center mt_50" id="employmentImg">
                                                                    <img src={employmentImg} className="img-responsive pi-image" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                            <div className="row" id="educationDiv">
                                                <section id="steps-uid-0-p-4" className="mainSection body" role="tabpanel" aria-labelledby="steps-uid-0-h-4" aria-hidden="true" style={{ display: "none" }}>
                                                    <div className="col-md-12 grid-margin stretch-card">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h4 className="card-title">
                                                                    {t('heducation')}<a className="btn-primary add-pi float-right btn-rounded btn-icon" data-bs-toggle="modal" data-bs-target="#AddEducation" id="addeducationBtn">
                                                                        <i className="ti-plus"></i>
                                                                    </a>
                                                                </h4>
                                                                <div className="hide" id="viewEducationDiv">
                                                                    <div className="row">
                                                                        {
                                                                            educationInfo.length != 0 ? (
                                                                                educationInfo.map((ele) => (
                                                                                    <>
                                                                                        <div className="col-md-12 col-lg-6 col-sm-12 custom-width_125">
                                                                                            <div className="cardPad card-body border border-radius mt-0 text-left box-shadow">
                                                                                            <div className='card-header border-0'>
                                                                                            <div className="widgetHead float-right">
                                                                                                        <a href="#" className="delete-education" > <i className="fa-solid fa-trash fa-lg float-end text-muted pt-2" title="Delete Education" data-bs-toggle="modal" data-bs-target="#DeleteEducation" id="deleteEducationBtn" data-for={ele.id} onClick={() => {
                                                                                                            handleeducationdelete(ele.id)
                                                                                                        }}></i></a>
                                                                                                        <a href="#" className="edit-profile customedit "> <i className="fa fa-pencil fa-lg float-end text-muted pt-2 position-absolute right-0" title="Edit Education" data-bs-toggle="modal" data-bs-target="#EditEducation" id="editEducationBtn" data-for={ele.id} onClick={() => {
                                                                                                            setcurEduId(ele.id)
                                                                                                        }}></i></a>

                                                                                                    </div>
                                                                                            </div>

                                                                                                    <div className='education-section'>
                                                                                                        <ul>
                                                                                                            <li><b className="float-start fw-bold">{t('education')}:</b></li>
                                                                                                            <li className='education-list-details ml_30 ml_30 ml-pc-8'> {ele.educationName}</li>
                                                                                                            <li><b className="float-start fw-bold">{t('course')}:</b></li>
                                                                                                            <li className='education-list-details ml_30 ml_30 ml-pc-8'> {ele.educationCourseName}</li>
                                                                                                            <li><b className="float-start fw-bold">{t('university/institute')}:</b></li>
                                                                                                            <li className='education-list-details ml_30 ml_30 ml-pc-8'> {ele.educationInstituteName}</li>
                                                                                                            <li><b className="float-start fw-bold">{t('courseType')}:</b></li>
                                                                                                            <li className='education-list-details ml_30 ml_30 ml-pc-8'>{ele.educationCourseTypeName}</li>
                                                                                                            <li><b className="float-start fw-bold">{t('completedYear')}:</b></li>
                                                                                                            <li className='education-list-details ml_30 ml_30 ml-pc-8'>{ele.educationCompletedYear}</li>
                                                                                                           
                                                                                                        </ul>
                                                                                                    </div>
                                                                                               
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                ))
                                                                            ) : (
                                                                                <p>{t('noRecordsAvailable')}</p>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="text-center mt_50" id="educationImg">
                                                                    <img src={educationImg} className="img-responsive pi-image" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                            <div className="row" id="certificationDiv">
                                                <section id="steps-uid-0-p-5" className="mainSection body" role="tabpanel" aria-labelledby="steps-uid-0-h-5" aria-hidden="true" style={{ display: "none" }}>
                                                    <div className="col-md-12 grid-margin stretch-card">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h4 className="card-title">
                                                                    {t('hcertification')}<a href="#" className="btn-primary add-pi float-right btn-rounded btn-icon" data-bs-toggle="modal" data-bs-target="#AddCertification">
                                                                        <i className="ti-plus"></i>
                                                                    </a>
                                                                </h4>
                                                                <div className="hide" id="viewCertificationDiv">
                                                                    <div className="row">
                                                                        {
                                                                            certificationInfo.length != 0 ? (
                                                                                certificationInfo.map((ele) => (
                                                                                    <>
                                                                                        <div className="col-lg-6 col-md-12 col-sm-12 custom-width-125">
                                                                                            <div className="cardPad card-body border border-radius mt-0 text-left box-shadow">
                                                                                            <div className='card-header border-0'>
                                                                                            <div className="widgetHead float-right">
                                                                                                        <a href="#" className="delete-certificate" > <i className="fa-solid fa-trash fa-lg float-end text-muted pt-2" title="Delete Certificate" data-bs-toggle="modal" data-bs-target="#DeleteCertificate" id="deleteCertificateBtn" data-for={ele.id} onClick={() => {
                                                                                                            handlecertificationdelete(ele.id)
                                                                                                        }}></i></a>
                                                                                                        <a href="#" className="edit-profile"> <i className="fa fa-pencil fa-lg float-end text-muted pt-2" title="Edit Certification" data-bs-toggle="modal" data-bs-target="#EditCertification" id="editCertificateBtn" data-for={ele.id} onClick={() => {
                                                                                                            setcurCertId(ele.id)
                                                                                                        }}></i></a>
                                                                                                    </div>
                                                                                            </div>
                                                                                            <div className='certification-section'>
                                                                                                <ul>
                                                                                                    <li><b className="float-start fw-bold">{t('certificationTitle')}:</b></li>
                                                                                                    <li className='certificate-list-details ml_30 ml-pc-8'> {ele.certificationTitle}</li>
                                                                                                    <li><b className="float-start fw-bold">{t('completiondate')}:</b> </li>
                                                                                                    <li className='certificate-list-details ml_30 ml-pc-8'> {ele.certificationCompletionDate}</li>
                                                                                                    <li ><b className="float-start fw-bold">{t('organization/institute')}:</b></li>
                                                                                                    <li className='certificate-list-details ml_30 ml-pc-8'> {ele.certificationOrganization}</li>
                                                                                                    <li><b className="float-start fw-bold">{t('certification')}:</b></li>
                                                                                                    <li className='certificate-list-details ml_30 ml-pc-8'>  {
                                                                                                                    ele.certificationFiles == "" ?
                                                                                                                        (<label>Not Uploaded</label>) :
                                                                                                                        (<a href={ele.certificationFiles} target="_blank" className="text-link">{ele?.certificationFiles?.split("/")[3]?.split(S3Config.S3ObjectDelimiter)[0]}</a>)
                                                                                                                }</li>
                                                                                                    
                                                                                                </ul>
                                                                                            </div>
                                                                                              
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                ))
                                                                            ) : (
                                                                                <p>{t('noRecordsAvailable')}</p>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="text-center mt_50" id="certificateImg">
                                                                    <img src={certificateImg} className="img-responsive pi-image" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                            <div className="row" id="uploadCVDIV">
                                                <section id="steps-uid-0-p-6" className="mainSection body" role="tabpanel" aria-labelledby="steps-uid-0-h-6" aria-hidden="true" style={{ display: "none" }}>
                                                    <div className="col-md-12 grid-margin stretch-card">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h4 className="card-title">
                                                                    {t('huploadCV')}<a
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#UploadCV"
                                                                        className="btn-primary add-pi float-right btn-rounded btn-icon hide"
                                                                    >
                                                                        <i className="ti-plus"></i>
                                                                    </a>
                                                                </h4>
                                                                <div className="hide" id="showUploadCVInfoDiv">
                                                                    {
                                                                        uploadCVInfo.length != 0 ? (
                                                                            uploadCVInfo.map((ele) => (
                                                                                <>
                                                                                    <div className="cardPad card-body border border-radius box-shadow pt_0 pl_20">
                                                                                        <div className="row">
                                                                                            <a href="#" className="edit-profile text-right">
                                                                                                <i className="fa fa-pencil fa-md text-muted pt-2" title="Edit"
                                                                                                    data-bs-toggle="modal" data-bs-target="#Edit_UploadCV" data-for={ele?.id}
                                                                                                    onClick={() => {
                                                                                                        setcurCVId(ele?.id)
                                                                                                    }}
                                                                                                ></i>
                                                                                            </a>
                                                                                            <div className="text-left mb_10 prefill mr_50"><b className="float-start fw-bold h4">{t('cvTitle')}:</b> {ele?.user_cv_title}</div>
                                                                                            <div className="text-left mb_10 prefill mr_50"><b className="float-start fw-bold h4">{t('uploadaCV')}:</b>  <a href={ele?.cv_attachment ? ele?.cv_attachment : "#"} className="text-link" target={ele?.cv_attachment ? "_blank" : ""}>{ele?.cv_attachment?.split("/")[3]?.split(S3Config.S3ObjectDelimiter)[0]}</a></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            ))
                                                                        ) : (
                                                                            <p>{t('noRecordsAvailable')}</p>
                                                                        )
                                                                    }

                                                                </div>
                                                                <div className="text-center mt_50" id="uploadCVBtn">
                                                                    <button
                                                                        type="file"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#UploadCV"
                                                                        className="tn btn-warning upload-cv-btn btn-icon-text border-0"
                                                                    >
                                                                        <i className="ti-upload btn-icon-prepend"></i>
                                                                        &nbsp;Upload
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </section>
                                            </div>
                                            <div className="row" id="previewDiv">
                                                <section id="steps-uid-0-p-7" className="mainSection body" role="tabpanel" aria-labelledby="steps-uid-0-h-7" aria-hidden="false" style={{ display: "none" }}>
                                                    <div className="col-md-12 grid-margin stretch-card">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h4 className="card-title">
                                                                    {t('hpreview')}
                                                                </h4>
                                                                <div className="card-body p-0 mt-0 text-left">
                                                                    <div className="">
                                                                    <div className="row">
                                                           

                                    <div className="col-md-2 col-lg-2 col-sm-12 custom-width">
                                   <a className="position-relative text-link inline-block">
                                   <h4 className="profile-name pb_10">{t('hpersonalInformation')}</h4>
                                 <img src={previewProfilePicture} alt="CIO Image" className="img-lg mb-2" />
                                    </a>
                                                                            </div>
                                                                            <div className="col-md-12 col-lg-10 col-sm-12">
                                                                            <div className="width_100 mt_50">
                                                                                <div className="row">
                                                                                    <div className="col-12">
<div className='row'>
    <div className='col-md-12 col-lg-7 col-sm-12'>
    <ul className='profile-personal-info'>
    <li className='profile-list-details'><b className="float-start me-3">{t('name')}:</b> </li>
    <li className='profile-list-details ml_30 ml-pc-3'>{personalInfo.firstName} {personalInfo.lastName}</li>
    
    <li className='profile-list-details'><b className="float-start me-3 ">{t('userName')}:</b> </li>
    <li className='profile-list-details ml_30 ml-pc-3'>{personalInfo.userName}@iamcaas.com</li>
    <li className='profile-list-details'><b className="float-start me-3">{t('country')}:</b> </li>
    <li className='profile-list-details ml_30 ml-pc-3'>{$("#country option:selected").text()}</li>
    <li className='profile-list-details'><b className="float-start me-3">{t('city')}:</b> </li>
    <li className='profile-list-details ml_30 ml-pc-3'>{$("#city option:selected").text()}</li>
    <li className='profile-list-details'><b className="float-start me-3">{t('zipCode')}:</b> </li>
    <li className='profile-list-details ml_30 ml-pc-3'>{personalInfo.zipCode ? personalInfo.zipCode : "No data"}</li>
  
   
</ul>
    </div>
    <div className='col-md-12 col-lg-5 col-sm-12'>
    <ul className='profile-personal-info'>
    
    <li className='profile-list-details'><b className="float-start">PO Box:</b></li>
    <li className='profile-list-details ml_30 ml-pc-3'> {personalInfo.poBoxNo ? personalInfo.poBoxNo : "No data"}</li>
    <li className='profile-list-details'><b className="float-start">{t('email')}:</b> </li>
    <li className='profile-list-details ml_30 ml-pc-3'>{personalInfo.email}</li>
    <li className='profile-list-details'><b className="float-start">Phone No:</b> </li>
    <li className='profile-list-details ml_30 ml-pc-3'>{$("#countryPhoneCodeId option:selected").text().slice(0, 4)}{personalInfo.phoneNumber}</li>
    <li className='profile-list-details'><b className="float-start">{t('gender')}:</b> </li>
    <li className='profile-list-details ml_30 ml-pc-3'>
    {$("#gender option:selected").text()} 
    </li>
   <li className='profile-list-details'><b className="float-start">{t('state')}:</b></li>
   <li className='profile-list-details ml_30 ml-pc-3'>
   {$("#state option:selected").text()}
   </li>
</ul>
    </div>
    <div className='row'>
        <div className='col-md-12 col-sm-12'>
<ul className='d-flex w-100'>
<li className='profile-list-details text-nowrap'>
    <b className="float-start">{t('address')} :</b> 
    </li>
    <li className='profile-list-details ml-pc-2'>{personalInfo.address}</li>
</ul>
        </div>
    </div>
</div>



                                                                                       
                                                                                    </div>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="row">
                                                                            <div className="col-md-3 col-sm-12 col-lg-3">
                                                                            <a className="position-relative text-link inline-block">
                                                                                <h4 className="profile-name pb_10">{t('hpersonalInformation')}</h4>
                                                                                <img src={previewProfilePicture} alt="CIO Image" className="img-lg mb-2" />
                                                                            </a>
                                                                            </div>
                                                                            <div className="col">
                                                                                <div className="inline-block width_100 ps-4 mt_50">
                                                                                    <div className="row">
                                                                                        <div className="col-6">
                                                                                            <div className="widgetCont mt-0">
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10 mr_20"><b className="float-start">{t('name')}:</b> {personalInfo.firstName} {personalInfo.lastName}</div>
                                                                                                </div>
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10"><b className="float-start">{t('userName')}:</b> {personalInfo.userName}@iamcaas.com </div>
                                                                                                </div>
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10"><b className="float-start">{t('country')}:</b> {$("#country option:selected").text()}</div>
                                                                                                </div>
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10"><b className="float-start">{t('city')}:</b> {$("#city option:selected").text()} </div>
                                                                                                </div>
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10"><b className="float-start">{t('zipCode')}:</b> {personalInfo.zipCode ? personalInfo.zipCode : "No data"} </div>
                                                                                                </div>
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10"><b className="float-start">PO Box:</b> {personalInfo.poBoxNo ? personalInfo.poBoxNo : "No data"} </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6">
                                                                                            <div className="widgetCont mt-0">
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10 mr_20"><b className="float-start">{t('email')}:</b> {personalInfo.email}</div>
                                                                                                </div>
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10"><b className="float-start">Phone No:</b> {$("#countryPhoneCodeId option:selected").text().slice(0, 4)}{personalInfo.phoneNumber}</div>
                                                                                                </div>
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10"><b className="float-start">{t('gender')}:</b> {$("#gender option:selected").text()}</div>
                                                                                                </div>
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10"><b className="float-start">{t('state')}:</b> {$("#state option:selected").text()}</div>
                                                                                                </div>
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10"><b className="float-start">{t('address')} :</b> {personalInfo.address} </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                        <div className="flex-row">


                                                                        </div>
                                                                        <h4 className="card-title mt_50">
                                                                            {t('hprofile')}
                                                                        </h4>
                                                                    </div>
                                                                    <p className="mb-0 gray-text fs-5 text-color-default">{personalInfo.firstName} {personalInfo.lastName}</p>
                                                                    <p className="mb-0 gray-text text-black fs-6 text-color-default">{profileInfo.profileTitle}</p>
                                                                    <p className="mb-0 gray-text text-black fs-6 text-color-default">{cxoLanguages?.map(x => x.languageName).join(",")}</p>
                                                                    <p className="mb-0 gray-text text-black fs-6 text-color-default">{profileInfo.profileSummary}</p>
                                                                    <h4 className="card-title mt_50">
                                                                        {t('hexperience')}
                                                                    </h4>
                                                                    <div className="row">
                                                                        {
                                                                            experienceInfo.length != 0 ? (
                                                                                experienceInfo.map((ele, index) => (
                                                                                    <>
                                                                                        <div className="col-md-12 col-lg-6 col-sm-12" key={index}>
                                                                                            <div className="cardPad card-body border border-radius mt-0 mb-3 text-left">
                                                                                                <div className='card-header border-0'>
                                                                                                <div className="widgetHead float-right">
                                                                                                        <a href="#" className="delete-experience" > <i className="fa-solid fa-trash fa-lg float-end text-muted pt-2" title="Delete Experience" data-bs-toggle="modal" data-bs-target="#DeleteExperience" id="deleteExperienceBtn" data-for={ele.id} onClick={() => {
                                                                                                            handleexpdelete(ele.id)
                                                                                                        }}></i></a><a href="#" className="edit-profile" > <i className="fa fa-pencil fa-lg float-end text-muted pt-2" title="Edit Experience" data-bs-toggle="modal" data-bs-target="#EditExperience" id="editExperienceBtn" data-for={ele.id} onClick={() => {
                                                                                                            setcurExpId(ele.id)
                                                                                                        }}></i></a></div>
                                                                                                </div>
                                                                                            <div className='experience-section'>
                                                                                                <ul>
                                                                                                    <li><b className="float-start fw-bold">{t('skill')}:</b> </li>
                                                                                                    <li className='expereince-list-details ml_30 ml-pc-8'>{skillRef[ele.experienceSkillId]}</li>
                                                                                                    <li><b className="float-start fw-bold">{t('softwareVersion')}:</b></li>
                                                                                                    <li className='expereince-list-details ml_30 ml-pc-8'>{skillVersionRef[ele.experienceSoftwareVersionId]}</li>
                                                                                                    <li><b className="float-start fw-bold">{t('lastUsed')}:</b></li>
                                                                                                    <li className='expereince-list-details ml_30 ml-pc-8'>{ele.experienceLastUsed}</li>
                                                                                                    <li><b className="float-start fw-bold">{t('experience')}:</b></li>
                                                                                                    <li className='expereince-list-details ml_30 ml-pc-8'>{ele.experienceYears} {t('years')}</li>
                                                                                                    <li><b className="float-start fw-bold">{t('implementationSize')}:</b> </li>
                                                                                                    <li className='expereince-list-details ml_30 ml-pc-8'>{ele.experienceImplementationSize} {t('users')}</li>
                                                                                                    <li><b className="float-start fw-bold">{t('experienceLevel')}:</b> </li>
                                                                                                    <li className='expereince-list-details ml_30 ml-pc-8'>{ele.experienceLevelName}</li>
                                                                                                </ul>
                                                                                            </div>

                                                                                                <div className="d-flex justify-content-between align-items-start">
                                                                                                  
                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                ))
                                                                            ) : (
                                                                                <div className='col-md-12 col-lg-12 col-sm-12'>
                                                                                <div className="cardPad card-body border border-radius box-shadow pb_30"><p>{t('noRecordsAvailable')}</p></div>
                                                                                </div>
                                                                                
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <h4 className="card-title mt_50">
                                                                        {t('hindustry')}
                                                                    </h4>
                                                                    <a href="#" className="edit-profile"> <i className="fa fa-pencil fa-lg float-end text-muted pt-4 pr_30 position-absolute mob-edit-industrypreview-btn" title="Edit" data-bs-toggle="modal" data-bs-target="#Industry"></i></a>
                                                                    <div className="cardPad card-body border border-radius box-shadow pb_30">
                                                                        {/* {
                                                                            industryExperienceInfo.length != 0 ? (
                                                                                industryExperienceInfo.map((ele) => (
                                                                                    <>
                                                                                        <button type="button" className="btn industry-list btn-outline-secondary btn-sm ml_20"> {ele} </button>
                                                                                    </>
                                                                                ))
                                                                            ) : (
                                                                                <p>{t('noRecordsAvailable')}</p>
                                                                            )
                                                                        } */}
                                                                        {
                                                                            industryExperiencedisplay.length != 0 ? (
                                                                                industryExperiencedisplay.map((ele) => (
                                                                                    industrydetails.map((exp) => (
                                                                                        ele == exp.industryId ?
                                                                                            <button type="button" className="btn industry-list btn-outline-secondary btn-sm ml_20 mb-3" value={exp.industryId}> {exp.industryName} </button> :
                                                                                            <></>
                                                                                    ))
                                                                                ))
                                                                            ) : (
                                                                                <>
                                                                                    {/* <a href="#" className="edit-profile"> <i className="fa-solid fa-plus float-end text-muted pt-4 pr_30" title="Edit" data-bs-toggle="modal" data-bs-target="#Industry"></i></a>
                                                                                        <div className="cardPad card-body box-shadow pb_30"> */}
                                                                                    <p>{t('noRecordsAvailable')}</p>
                                                                                    {/* </div> */}
                                                                                </>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <h4 className="card-title mt_50">
                                                                        {t('hemployment')}
                                                                    </h4>
                                                                    <div className="row">
                                                                        {
                                                                            employmentInfo.length != 0 ? (
                                                                                employmentInfo.map((ele) => (
                                                                                    <>

                                                                                    <div className='col-md-12 col-lg-6 col-sm-12'>
                                                                                    <div className="cardPad card-body border border-radius box-shadow">
                                                                                            <div className="widgetHead float-right">
                                                                                                <a href="#" className="delete-employment" > <i className="fa-solid fa-trash fa-lg float-end text-muted pt-4 mr_10" title="Delete Employment" data-bs-toggle="modal" data-bs-target="#DeleteEmployment" id="deleteEmploymentBtn" data-for={ele.id} onClick={() => {
                                                                                                    handleemploymentdelete(ele.id)
                                                                                                }}></i></a>
                                                                                                <a href="#" className="edit-profile"> <i className="fa fa-pencil fa-lg float-end text-muted pt-4" title="Edit" data-bs-toggle="modal" data-bs-target="#EditEmployment" id="editEmploymentBtn" data-for={ele.id} onClick={() => {
                                                                                                    setcurEmpId(ele?.id)
                                                                                                }}></i></a>
                                                                                            </div>
                                                                                            <div className="d-flex justify-content-between align-items-start">
                                                                                                <div className="widgetCont col-sm-12 mt-0">
                                                                                                    <ul>
                                                                                                        <li> <span className="float-start fw-bold" >{t('companyName')}: </span></li>
                                                                                                        <li className='employment-list-details ml_30 ml-pc-8'>{ele?.employmentCompany}</li>
                                                                                                        <li><span className="float-start fw-bold" >{t('designation')}: </span></li>
                                                                                                        <li className='employment-list-details ml_30 ml-pc-8'> {ele?.employmentDesignation}</li>
                                                                                                        <li>   <span className="float-start fw-bold"> {t('yearsOfExperience')}: </span></li>
                                                                                                        <li className='employment-list-details ml_30 ml-pc-8'>{ele?.employmentYears}</li>
                                                                                                        <li>  <span className="float-start fw-bold" > {t('skillsUsed')}: </span></li>
                                                                                                        <li className='employment-list-details ml_30 ml-pc-8'>{ele?.employmentSkills}</li>
                                                                                                        <li><span className="float-start fw-bold"> {t('companyLocation')}: </span></li>
                                                                                                        <li className='employment-list-details ml_30 ml-pc-8'>{cityRef[ele?.employmentCity]}, {stateRef[ele?.employmentState]},{countryRef[ele?.employmentCountry]}</li>
                                                                                                        
                                                                                                    </ul>
                                                                                                  
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                       
                                                                                    </>
                                                                                ))
                                                                            ) : (
                                                                                <div className="cardPad card-body border border-radius box-shadow pb_30"><p>{t('noRecordsAvailable')}</p></div>
                                                                            )
                                                                        }
                                                                    </div>


                                                                    <h4 className="card-title">
                                                                        {t('heducation')}
                                                                    </h4>
                                                                    <div className="row">
                                                                        {
                                                                            educationInfo.length != 0 ? (
                                                                                educationInfo.map((ele) => (
                                                                                    <>
                                                                                        <div className="col-md-12 col-lg-6 col-sm-12">
                                                                                            <div className="cardPad card-body border border-radius mt-0 text-left box-shadow">
                                                                                                <div className='card-header border-0'>
                                                                                                <div className="widgetHead float-right">
                                                                                                        <a href="#" className="delete-education" > <i className="fa-solid fa-trash fa-lg float-end text-muted pt-2" title="Delete Education" data-bs-toggle="modal" data-bs-target="#DeleteEducation" id="deleteEducationBtn" data-for={ele.id} onClick={() => {
                                                                                                            handleeducationdelete(ele.id)
                                                                                                        }}></i></a>
                                                                                                        <a href="#" className="edit-profile customedit"> <i className="fa fa-pencil fa-lg float-end text-muted pt-2" title="Edit Education" data-bs-toggle="modal" data-bs-target="#EditEducation" id="editEducationBtn" data-for={ele.id} onClick={() => {
                                                                                                            setcurEduId(ele.id)
                                                                                                        }}></i></a></div>
                                                                                                </div>
                                                                                                     <div className='preview-education'>
                                                                                                        <ul className=''>
                                                                                                        <li><b className="float-start fw-bold">
                                                                                                                {t('education')}:</b></li>
                                                                                                        <li className='education-list-details ml_30 ml-pc-8'>{ele.educationName}</li>
                                                                                                        <li><b className="float-start fw-bold">{t('course')}:</b></li>
                                                                                                        <li className='education-list-details ml_30 ml-pc-8 '>{ele.educationCourseName}</li>
                                                                                                        <li><b className="float-start fw-bold">{t('university/institute')}:</b> </li>
                                                                                                        <li className='education-list-details ml_30 ml-pc-8'>{ele.educationInstituteName}</li>
                                                                                                        <li><b className="float-start fw-bold">{t('courseType')}:</b></li>
                                                                                                        <li className='education-list-details ml_30 ml-pc-8'>{ele.educationCourseTypeName}</li>
                                                                                                        <li><b className="float-start fw-bold">{t('completedYear')}:</b></li>
                                                                                                        <li className='education-list-details ml_30 ml-pc-8'> {ele.educationCompletedYear}</li>
                                                                                                       
                                                                                                        </ul>
                                                                                                        </div>   

                                                                                                
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                ))
                                                                            ) : (
                                                                                <div className="cardPad card-body border border-radius box-shadow pb_30"><p>{t('noRecordsAvailable')}</p></div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <h4 className="card-title mt_50">
                                                                        {t('hcertification')}
                                                                    </h4>
                                                                    <div className="row">
                                                                        {
                                                                            certificationInfo.length != 0 ? (
                                                                                certificationInfo.map((ele) => (
                                                                                    <>
                                                                                        <div className="col-md-12 col-lg-6 col-sm-12">
                                                                                            <div className="cardPad card-body border border-radius mt-0 text-left box-shadow">
                                                                                                <div className='card-header border-0'>
                                                                                                <div className="widgetHead float-right">
                                                                                                        <a href="#" className="delete-certificate" > <i className="fa-solid fa-trash fa-lg float-end text-muted pt-2" title="Delete Certificate" data-bs-toggle="modal" data-bs-target="#DeleteCertificate" id="deleteCertificateBtn" data-for={ele.id} onClick={() => {
                                                                                                            handlecertificationdelete(ele.id)
                                                                                                        }}></i></a>
                                                                                                        <a href="#" className="edit-profile"> <i className="fa fa-pencil fa-lg float-end text-muted pt-2" title="Edit Certification" data-bs-toggle="modal" data-bs-target="#EditCertification" id="editCertificateBtn" data-for={ele.id} onClick={() => {
                                                                                                            setcurCertId(ele.id)
                                                                                                        }}></i></a></div>
                                                                                                </div>
                                                                                           <div className='certificate-section'>
                                                                                            <ul>
                                                                                                <li><b className="float-start fw-bold">{t('certificationTitle')}:</b></li>
                                                                                                <li className='certificate-list-details ml_30 ml-pc-8'> {ele.certificationTitle}</li>
                                                                                                <li><b className="float-start fw-bold">{t('completiondate')}:</b></li>
                                                                                                <li className='certificate-list-details ml_30 ml-pc-8'> {ele.certificationCompletionDate}</li>
                                                                                                <li><b className="float-start fw-bold">{t('organization/institute')}:</b></li>
                                                                                                <li className='certificate-list-details ml_30 ml-pc-8'> {ele.certificationOrganization}</li>
                                                                                                <li> <b className="float-start fw-bold">{t('certification')}:</b></li>
                                                                                                <li className='certificate-list-details ml_30 ml-pc-8'> {
                                                                                                                    ele.certificationFiles == "" ?
                                                                                                                        (<label>Not Uploaded</label>) :
                                                                                                                        (<a href={ele.certificationFiles} className="text-link" target="_blank">{ele?.certificationFiles?.split("/")[3]?.split(S3Config.S3ObjectDelimiter)[0]}</a>)
                                                                                                                }</li>
														                                                        </ul>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    </>
                                                                                               
                                                                                            
                                                                                   
                                                                                ))
                                                                            ) : (
                                                                                <div className="cardPad card-body border border-radius box-shadow pb_30"><p>{t('noRecordsAvailable')}</p></div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <h4 className="card-title mt_50">
                                                                        {t('hupload')}
                                                                    </h4>
                                                                    {
                                                                        uploadCVInfo.length != 0 ? (
                                                                            uploadCVInfo.map((ele) => (
                                                                                <>
                                                                                    <button type="button" className="btn industry-list btn-outline-secondary btn-sm ml_20 mb-4">
                                                                                    <a href={ele?.cv_attachment ? ele?.cv_attachment : "#"} className="text-link" target={ele?.cv_attachment ? "_blank" : ""}>{ele?.user_cv_title} </a>
                                                                                    </button>
                                                                                </>
                                                                            ))
                                                                        ) : (
                                                                            <div className="cardPad card-body border border-radius box-shadow pb_30"><p>{t('noRecordsAvailable')}</p></div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                            <div className="row" id="acknowledgementDiv">
                                                <section id="steps-uid-0-p-8" className="mainSection body" role="tabpanel" aria-labelledby="steps-uid-0-h-8" aria-hidden="true" style={{ display: "none" }}>
                                                    <div className="col-12 grid-margin stretch-card">
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-md-12 grid-margin stretch-card">
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <h4 className="card-title">
                                                                                {t('hacknowledgement')}
                                                                            </h4>
                                                                            <div className="cardPad card-body box-shadow pb_30">
                                                                                <span className="ps-2 float-end">

                                                                                    <a href="#" className="text-link1 text-danger" title="Clear All" onClick={() => clearSign(0)}>&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-xmark"></i>&nbsp;Clear All</a>
                                                                                </span>
                                                                                <div className="checkbox mt-5">
                                                                                    <label>
                                                                                        <input type="checkbox" id="ndaId" value="1" onChange={handleNDACheck} disabled />
                                                                                        <i className="helper">
                                                                                            <span className="ps-2">
                                                                                                <a href="#" className="text-link1" onClick={() => setCurType(1)} data-bs-target="#NonDiscAgreement" data-bs-toggle="modal">&nbsp;&nbsp;&nbsp;&nbsp;NDA </a>
                                                                                            </span>

                                                                                        </i>
                                                                                    </label>
                                                                                    <span className="ps-2">

                                                                                        <a href="#" className="text-link1 mdi mdi-delete fs-5 text-muted float-end" title='Clear Sign' onClick={() => clearSign(1)}> </a>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="checkbox mt_20">
                                                                                    <label>
                                                                                        <input type="checkbox" id="privacyPolicyId" value="2" onChange={handleNDACheck} disabled />
                                                                                        <i className="helper">
                                                                                            <span className="ps-2">
                                                                                                <a href="#" className="text-link1" data-bs-target="#NDA" onClick={() => setCurType(2)} data-bs-toggle="modal">&nbsp;&nbsp;&nbsp;&nbsp;Privacy Policy </a>
                                                                                            </span>

                                                                                        </i>
                                                                                    </label>
                                                                                    <span className="ps-2">
                                                                                        <a href="#" className="text-link1 mdi mdi-delete fs-5 text-muted float-end" title='Clear Sign' onClick={() => clearSign(2)}></a>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="checkbox mt_20">
                                                                                    <label>
                                                                                        <input type="checkbox" id="cocId" value="3" onChange={handleNDACheck} disabled />
                                                                                        <i className="helper">
                                                                                            <span className="ps-2">
                                                                                                <a href="#" className="text-link1" data-bs-target="#NDA" onClick={() => setCurType(3)} data-bs-toggle="modal">&nbsp;&nbsp;&nbsp;&nbsp;Code of Conduct </a>
                                                                                            </span>

                                                                                        </i>
                                                                                    </label>
                                                                                    <span className="ps-2">
                                                                                        <a href="#" className="text-link1 mdi mdi-delete fs-5 text-muted float-end" title='Clear Sign' onClick={() => clearSign(3)}></a>
                                                                                    </span>
                                                                                </div>
                                                                                {/* <div className="checkbox mt_20">
                                                                                    <label>
                                                                                        <input type="checkbox" id="termsId" onChange={handleTermCheck} disabled/>
                                                                                        <i className="helper">

                                                                                            <span className="ps-2">
                                                                                                <span className="text-link1"> &nbsp;&nbsp;&nbsp;&nbsp;{t('iAgreeToAllTermsAndConditions')} </span>
                                                                                            </span>
                                                                                        </i>
                                                                                    </label>

                                                                                </div> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                            <div className="div-footer mt_200">
                                                <div className="float-right hide" id="navControls">
                                                    <a href="#previous" className="disabled btn btn-warning btn-md previous-btn mr_10" id="previousli" onClick={showPreviousDiv}>{t('previous')}</a>
                                                    <a href="#next" className="btn btn-warning btn-md next-btn mr_10" id="nextBtn" onClick={showNextDiv}>{t('next')}</a>
                                                    <a href="#finish" className="btn btn-warning btn-md next-btn finish-btn" style={{ display: "none" }} id="finishli" onClick={submitcioregistration}  >{t('finish')}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <div className="modal fade show timesheetadd" id="Edittimerow">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title" id="exampleModalLabel">{t('addPrimarySkills')}</h3>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName1">{t('competency')}</label>
                                            <select className="form-control">
                                                <option >SFDC</option>
                                                <option >Oracle Financials</option>
                                                <option >Oracle ERP</option>
                                                <option >SAP</option>
                                                <option >AWS</option>
                                                <option >DevOps</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName1">{t('version')}</label>
                                            <input className="form-control" type="text" placeholder="Enter the version" id="formFile" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName1">{t('industry')} </label>
                                            <select className="form-select" name="" id="">
                                                <option >You can select Multiple values
                                                </option>
                                                <option >Manufacturing
                                                </option>
                                                <option >Banking</option>
                                                <option >Oracle Financials</option>
                                                <option >Oracle ERP</option>
                                                <option >Insurance</option>
                                                <option >Airline</option>
                                                <option >Pharma</option>
                                                <option >Healthcare</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName1">{t('personHours')}</label>
                                            <input className="form-control" type="text" placeholder="Enter the hours" id="formFile" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName1">{t('teamSize')}</label>
                                            <input className="form-control" type="text" placeholder="Enter the Team size" id="formFile" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName1">{t('numberOfYears')}</label>
                                            <input className="form-control" type="text" placeholder="Enter the Number of Years" id="formFile" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName1">{t('numberOfProjects')}</label>
                                            <input className="form-control" type="text" placeholder="Enter the Number of Projects" id="formFile" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName1 ">{t('professionalSummary')}</label>
                                            <textarea className="form-control  min-height-150" placeholder="Professional Summary"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName1">{t('tags')}</label>
                                            <input className="form-control" type="text" placeholder="Tags" id="formFile" />
                                            {/* value="Manufacturing" */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a type="button" className="btn btn-danger btn-sm" data-bs-dismiss="modal">{t('cancel')}</a>
                                <a type="button" className="btn btn-primary btn-sm " data-bs-dismiss="modal">{t('save')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade show timesheetadd" id="Edittimerow1">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">{t('module')}</h3>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName1">{t('module')}</label>
                                            <select className="form-select" name="" id="">
                                                <option >Core</option>
                                                <option >Integration</option>
                                                <option >API</option>
                                                <option >Single sign-on</option>
                                                <option >Data Migration</option>
                                                <option >Implementation</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName1">{t('role')}</label>
                                            <select className="form-select" name="" id="">
                                                <option >Implementation
                                                </option>
                                                <option >Development</option>
                                                <option >Integration</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName1">{t('expertiseLevel')}</label>
                                            <select className="form-select" name="" id="">
                                                <option >Implementor
                                                </option>
                                                <option >Developer</option>
                                                <option >Expert </option>
                                                <option >Entry-level</option>
                                                <option >Exposure</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName1 ">{t('professionalSummary')}</label>
                                            <textarea className="form-control  min-height-150" placeholder="Professional Summary"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a type="button" className="btn btn-danger btn-sm" data-bs-dismiss="modal">{t('cancel')}</a>
                                <a type="button" className="btn btn-primary btn-sm" data-bs-dismiss="modal">{t('save')}</a>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade show" id="EditCompany">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="notify-box">
                                    <h5 className="notification-text"> <img src="../images/okk.jpg" className="img-responsive completeimg" /></h5>
                                    <h5 className="notification-text thnku_txt mb_20">{t('thankYouForRegistering')}</h5>
                                    <h5 className="notification-text">{t('pleaseConfirmYourRegistrationByVerifyingTheEmail')}</h5>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="../CIO/index.html" className="btn btn-danger btn-sm">{t('close')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade show " id="ProfileSummary" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content cardPad card-body border border-radius">
                            <div className="modal-header">
                                <h3 className="modal-title">{t('hprofile')}</h3>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('profileTitle')} <span className="error">*</span></label>
                                        <textarea className="form-control" placeholder={t('profileTitle')} rows="4" id="profileTitle" onChange={handleClick_Profile}></textarea>
                                        <label id="profileTitleerror" className="error hide">Profile Title is required</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('profileSummary')} <span className="error">*</span></label>
                                        <textarea className="form-control" placeholder={t('profileSummary')} rows="4" id="profileSummary" onChange={handleClick_Profile}></textarea>
                                        <label id="profileSummaryerror" className="error hide">Profile Summary is required</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        {/* <label>{t('languages')} <span className="error">*</span></label> */}
                                        <label>Preferred Language <span className="error">*</span></label>

                                        <select className="form-select" id="profileLanguages" onChange={handleClick_Profile}>
                                            <option value="0" key={0}>{t('select')}</option>
                                            {
                                                languagesMeta.length != 0 ? (
                                                    languagesMeta.map((ele, index) => (
                                                        <option key={index} value={ele.languageId}>{ele.languageName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="profilelanguageerror" className="error hide">Profile language not selected</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('uploadaProfilePhoto')} <span className="error">*</span></label>
                                        <input className="form-control" type="file" multiple="" id="profilePicture" onChange={handleClick_Profile} />
                                        <label id="profilephotoerror" className="error hide">Profile photo not uploaded</label>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                                <a href="#" className="btn btn-warning btn-md next-btn" onClick={handleSubmitProfile}>{t('save')}</a>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade show" id="AddExperience" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content cardPad card-body border border-radius">
                            <div className="modal-header">
                                <h3 className="modal-title">{t('experience/skill')}</h3>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <form id="addExperienceForm">
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('skill/softwareName')} <span className="error">*</span></label>
                                            <select className="form-select" id="experienceSkill" onChange={resetversionList}>
                                                <option value="0" key={0}>Select</option>
                                                {
                                                    skilldetailsMeta.length != 0 ? (
                                                        skilldetailsMeta.map((ele, index) => (
                                                            <option key={index} value={ele.skillId}>{ele.skillName}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select>
                                            {/* <input type="text" className="form-control" id="experienceSkill" /> */}
                                            <label id="experienceSkillerror" className="error hide">Skill not selected</label>
                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('softwareVersion')} <span className="error">*</span></label>
                                            <select className="form-select" id="experienceSoftwareVersion" onChange={skillvalidation}>
                                                <option value="0" key={0}>Select</option>
                                                {
                                                    curskillVersionList.length != 0 ? (
                                                        curskillVersionList.map((ele, index) => (
                                                            <option key={index} value={ele.skillVersionId}>{ele.skillVersionName}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select>
                                            {/* <input type="text" className="form-control" id="experienceSoftwareVersion" /> */}
                                            <label id="experienceSoftwareVersionerror" className="error hide">Skill Version not selected</label>
                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('lastUsed')} <span className="error">*</span></label>
                                            <select className="form-select" id="experienceLastUsed" onChange={skillvalidation}>
                                                <option value="0" >{t('select')}</option>
                                                <option >2020</option>
                                                <option >2021</option>
                                                <option >2022</option>
                                            </select>
                                            <label id="experienceLastUsederror" className="error hide">Experience last used not selected</label>
                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('years')} <span className="error">*</span></label>
                                            <select className="form-select" id="experienceYears" onChange={skillvalidation}>
                                                <option value="0">{t('select')}</option>
                                                <option value="1">1 Years</option>
                                                <option value="2">2 Years</option>
                                                <option value="3">3 Years</option>
                                                <option value="4">4 Years</option>
                                                <option value="5">5 Years</option>
                                                <option value="6">6 Above</option>
                                            </select>
                                            <label id="experienceYearserror" className="error hide">Experience in years not selected</label>
                                        </div>
                                        {/* <div className="form-group col-12 col-sm-12">
                                            <label>{t('months')} <span className="error">*</span></label>
                                            <select className="form-select" id="experienceMonths">
                                                <option >{t('select')}</option>
                                                <option value="1">1 Months</option>
                                                <option value="2">2 Months</option>
                                                <option value="3">3 Months</option>
                                                <option value="4">4 Months</option>
                                                <option value="5">5 Months</option>
                                                <option value="6">6 Months</option>
                                                <option value="7">7 Months</option>
                                                <option value="8">8 Months</option>
                                                <option value="9">9 Months</option>
                                                <option value="10">10 Months</option>
                                                <option value="11">11 Months</option>
                                                <option value="12">12 Months</option>
                                            </select>
                                        </div> */}
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('implementationSize')} <span className='imp_size mr_5'>(in numbers)</span><i className="fa fa-info-circle" title="How many end-users have experienced your skill?"></i> </label>
                                            <input type="text" className="form-control" placeholder='How many end-users have experienced your skill?' id="experienceImplementationSize" onChange={skillvalidation} />
                                            {/* <label id="experienceImplementationSizeerror" className="error hide">Implementation Size is Required</label> */}

                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('experienceLevel')} <span className="error">*</span></label>
                                            <select className="form-select" id="experienceLevel" onChange={skillvalidation}>
                                                <option value="0" key={0}>{t('select')}</option>
                                                {
                                                    skillexperiencelevelsMeta.length != 0 ? (
                                                        skillexperiencelevelsMeta.map((ele, index) => (
                                                            <option key={index} value={ele.skillExperienceId}>{ele.skillExperienceLevel}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select>
                                            <label id="experienceLevelerror" className="error hide">Experience level not selected</label>

                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                                <a href="#" className="btn btn-warning btn-md next-btn" onClick={addExperience}>{t('save')}</a>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade show" id="EditExperience" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content cardPad card-body border border-radius">
                            <div className="modal-header">
                                <h3 className="modal-title">{t('experience/skill')}</h3>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <form>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('skill/softwareName')} <span className="error">*</span></label>
                                            <select className="form-select" data-for="experienceSkillId" value={curExp?.experienceSkillId} onChange={editExperience} >
                                                <option value="0" key={0}>Select</option>
                                                {
                                                    skilldetailsMeta.length != 0 ? (
                                                        skilldetailsMeta.map((ele, index) => (
                                                            <option key={index} value={ele.skillId}>{ele.skillName}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select>
                                            {/* <input type="text" className="form-control" data-for="experienceSkill" value={curExp?.experienceSkill} onChange={editExperience} /> */}
                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('softwareVersion')} <span className="error">*</span></label>
                                            <select className="form-select" data-for="experienceSoftwareVersionId" value={curExp?.experienceSoftwareVersionId} onChange={editExperience} >
                                                <option value="0" key={0}>Select</option>
                                                {
                                                    curskillVersionList.length != 0 ? (
                                                        curskillVersionList.map((ele, index) => (
                                                            <option key={index} value={ele.skillVersionId}>{ele.skillVersionName}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select>
                                            {/* <input type="text" className="form-control" data-for="experienceSoftwareVersion" value={curExp?.experienceSoftwareVersion} onChange={editExperience} /> */}
                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('lastUsed')} <span className="error">*</span></label>
                                            <select className="form-select" data-for="experienceLastUsed" value={curExp?.experienceLastUsed} onChange={editExperience}>
                                                <option >{t('select')}</option>
                                                <option >2020</option>
                                                <option >2021</option>
                                                <option >2022</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>Years</label>
                                            <select className="form-select" data-for="experienceYears" value={curExp?.experienceYears} onChange={editExperience}>
                                                <option >{t('select')}</option>
                                                <option value="1">1 Years</option>
                                                <option value="2">2 Years</option>
                                                <option value="3">3 Years</option>
                                                <option value="4">4 Years</option>
                                                <option value="5">5 Years</option>
                                                <option value="6">6 Above</option>
                                            </select>
                                        </div>
                                        {/* <div className="form-group col-12 col-sm-12">
                                            <label>Months</label>
                                            <select className="form-select" data-for="experienceMonths" value={curExp?.experienceMonths} onChange={editExperience}>
                                                <option >{t('select')}</option>
                                                <option value="1">1 Months</option>
                                                <option value="2">2 Months</option>
                                                <option value="3">3 Months</option>
                                                <option value="4">4 Months</option>
                                                <option value="5">5 Months</option>
                                                <option value="6">6 Months</option>
                                                <option value="7">7 Months</option>
                                                <option value="8">8 Months</option>
                                                <option value="9">9 Months</option>
                                                <option value="10">10 Months</option>
                                                <option value="11">11 Months</option>
                                                <option value="12">12 Months</option>
                                            </select>
                                        </div> */}
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('implementationSize')} <span className='imp_size mr_5'>(in numbers)</span><i className="fa fa-info-circle" title="How many users have experienced your skill?"></i>
                                            </label>
                                            <input type="text" className="form-control" placeholder='How many end-users have experienced your skill?' data-for="experienceImplementationSize" value={curExp?.experienceImplementationSize} onChange={editExperience} />
                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('experienceLevel')} <span className="error">*</span></label>
                                            <select className="form-select" data-for="experienceLevel" value={curExp?.experienceLevel} onChange={editExperience}>
                                                {/* <option>Select</option>
                                                <option>Expert</option>
                                                <option>Average</option>
                                                <option>Basic</option> */}
                                                <option value="0" key={0}>{t('select')}</option>
                                                {
                                                    skillexperiencelevelsMeta.length != 0 ? (
                                                        skillexperiencelevelsMeta.map((ele, index) => (
                                                            <option key={index} value={ele.skillExperienceId}>{ele.skillExperienceLevel}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                                <a href="#" className="btn btn-warning btn-md next-btn" data-bs-dismiss="modal">{t('save')}</a>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade show" id="EditEmployment" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content cardPad card-body border border-radius">
                            <div className="modal-header">
                                <h3 className="modal-title">{t('editEmployment')}</h3>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('companyName')} <span className="error">*</span></label>
                                        <input type="text" className="form-control" placeholder={t('companyName')} data-for="employmentCompany" value={curEmp?.employmentCompany} onChange={editEmployment} />
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('country')} <span className="error">*</span></label>
                                        <select className="form-select" data-for="employmentCountry" value={curEmp?.employmentCountry} onChange={editEmployment}>
                                            <option value="0" key={0}>{t('country')}</option>
                                            {
                                                countriesMeta.length != 0 ? (
                                                    countriesMeta.map((ele, index) => (
                                                        <option key={index} value={ele.countryId}>{ele.countryName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="countryerror" className="error hide">Country not selected</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('state')} <span className="error">*</span></label>
                                        <select className="form-select" data-for="employmentState" value={curEmp?.employmentState} onChange={editEmployment}>
                                            <option value="0" key={0}>{t('state')}</option>
                                            {
                                                curStatesMeta.length != 0 ? (
                                                    curStatesMeta.map((ele, index) => (
                                                        <option key={index} value={ele.stateId}>{ele.stateName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="stateerror" className="error hide">State not selected</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('city')} <span className="error">*</span></label>
                                        <select className="form-select" data-for="employmentCity" value={curEmp?.employmentCity} onChange={editEmployment}>
                                            <option value="0" key={0}>{t('city')}</option>
                                            {
                                                curCityList.length != 0 ? (
                                                    curCityList.map((ele, index) => (
                                                        <option key={index} value={ele.cityId}>{ele.cityName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('designation')} <span className="error">*</span></label>
                                        <input type="text" className="form-control" placeholder={t('designation')} data-for="employmentDesignation" value={curEmp?.employmentDesignation} onChange={editEmployment} />
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('yearsOfExperience')} <span className="error">*</span></label>
                                        <input type="text" className="form-control" placeholder={t('yearsOfExperience')} data-for="employmentYears" value={curEmp?.employmentYears} onChange={editEmployment} />

                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('skillsUsed')} <span className="error">*</span></label>
                                        {/* <select className="form-select" data-for="employmentSkills" value={curEmp?.employmentSkills} onChange={editEmployment} >
                                            <option value="0" key={0}>Select</option>
                                            {
                                                skilldetailsMeta.length != 0 ? (
                                                    skilldetailsMeta.map((ele, index) => (
                                                        <option key={index} value={ele.skillId}>{ele.skillName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select> */}


                                        <textarea className="form-control" placeholder={t('skillsUsed')} rows="4" data-for="employmentSkills" value={curEmp?.employmentSkills} onChange={editEmployment}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                                <a href="#" className="btn btn-warning btn-md next-btn" data-bs-dismiss="modal">{t('save')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade show" id="AddEmployment" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content cardPad card-body border border-radius">
                            <div className="modal-header">
                                <h3 className="modal-title">{t('addEmployment')}</h3>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <form id="addEmploymentForm">
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('companyName')} <span className="error">*</span></label>
                                            <input type="text" className="form-control" placeholder={t('companyName')} id="employmentCompany" onChange={employmentValidation} />
                                            <label id="employmentCompanyerror" className="error hide">Company Name is required</label>

                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('country')} <span className="error">*</span></label>
                                            <select className="form-select" id="employmentCountry" data-for="addemploymentCountry" onChange={handleEmploymentLocation}>
                                                <option value="0" key={0}>{t('country')}</option>
                                                {
                                                    countriesMeta.length != 0 ? (
                                                        countriesMeta.map((ele, index) => (
                                                            <option key={index} value={ele.countryId}>{ele.countryName}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select>
                                            <label id="employmentCountryerror" className="error hide">Country not selected</label>
                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('state')} <span className="error">*</span></label>
                                            <select className="form-select" id="employmentState" data-for="addemploymentState" onChange={handleEmploymentLocation}>
                                                <option value="0" key={0}>{t('state')}</option>
                                                {
                                                    curStatesMeta.length != 0 ? (
                                                        curStatesMeta.map((ele, index) => (
                                                            <option key={index} value={ele.stateId}>{ele.stateName}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select>
                                            <label id="employmentStateerror" className="error hide">State not selected</label>
                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('city')} <span className="error">*</span></label>
                                            <select className="form-select" id="employmentCity" data-for="addemploymentCity" onChange={handleEmploymentLocation}>
                                                <option value="0" key={0}>{t('city')}</option>
                                                {
                                                    curempCityList.length != 0 ? (
                                                        curempCityList.map((ele, index) => (
                                                            <option key={index} value={ele.cityId}>{ele.cityName}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select>
                                            <label id="employmentCityerror" className="error hide">City not selected</label>
                                        </div>



                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('designation')} <span className="error">*</span></label>
                                            <input type="text" className="form-control" placeholder={t('designation')} id="employmentDesignation" onChange={employmentValidation} />
                                            <label id="employmentDesignationerror" className="error hide">Designation is Required</label>

                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('yearsOfExperience')} <span className="error">*</span></label>
                                            <input type="text" className="form-control" placeholder={t('yearsOfExperience')} id="employmentYears" onChange={employmentValidation} />
                                            <label id="employmentYearserror" className="error hide">Years of Experience Required</label>
                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('skillsUsed')} <span className="error">*</span></label>
                                            {/* <select className="form-select" id="employmentSkills">
                                                <option value="0" key={0}>Select</option>
                                                {
                                                    skilldetailsMeta.length != 0 ? (
                                                        skilldetailsMeta.map((ele, index) => (
                                                            <option key={index} value={ele.skillId}>{ele.skillName}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select> */}



                                            <textarea className="form-control" placeholder={t('skillsUsed')} rows="4" id="employmentSkills" onChange={employmentValidation}></textarea>
                                            <label id="employmentSkillserror" className="error hide">Skills are Required</label>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                                <a href="#" className="btn btn-warning btn-md next-btn" onClick={addEmployment}>{t('save')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade show" id="EditEducation">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content cardPad card-body border border-radius">
                            <div className="modal-header">
                                <h3 className="modal-title">{t('education')}</h3>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <form id="editEducationForm">
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('education')} <span className="error">*</span></label>
                                            <select className="form-select" id="eduname" data-for="educationId" data-affects-meta="educationsMeta" data-affects="educationName" value={curEdu?.educationId} onChange={editEducation}>
                                                <option value="0" key={0}>{t('select')}</option>
                                                {
                                                    educationsMeta.length != 0 ? (
                                                        educationsMeta.map((ele, index) => (
                                                            <option key={index} value={ele.educationId}>{ele.educationName}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('course')} <span className="error">*</span></label>
                                            <select className="form-select" id="educoursename" data-for="educationCourseId" data-affects-meta="coursesMeta" data-affects="educationCourseName" value={curEdu?.educationCourseId} onChange={editEducation} >
                                                <option value="0" key={0}>{t('select')}</option>
                                                {
                                                    coursesMeta.length != 0 ? (
                                                        coursesMeta.map((ele, index) => (
                                                            <option key={index} value={ele.courseId}>{ele.courseName}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('university/institute')} <span className="error">*</span></label>
                                            <select className="form-select" id="eduInstitutename" data-for="educationInstituteId" data-affects-meta="institutesMeta" data-affects="educationInstituteName" value={curEdu?.educationInstituteId} onChange={editEducation}>
                                                <option value="0" key={0}>{t('select')}</option>
                                                {
                                                    institutesMeta.length != 0 ? (
                                                        institutesMeta.map((ele, index) => (
                                                            <option key={index} value={ele.instituteId}>{ele.instituteName}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('courseType')} <span className="error">*</span></label>
                                            <select className="form-select" id="educoursetypename" data-for="educationCourseTypeId" data-affects-meta="coursetypesMeta" data-affects="educationCourseTypeName" value={curEdu?.educationCourseTypeId} onChange={editEducation}>
                                                <option value="0" key={0}>{t('select')}</option>
                                                {
                                                    coursetypesMeta.length != 0 ? (
                                                        coursetypesMeta.map((ele, index) => (
                                                            <option key={index} value={ele.courseTypeId}>{ele.courseTypeName}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('completedYear')} <span className="error">*</span></label>
                                            {/* <select className="form-select" data-for="educationCompletedYear" data-affects="none" value={curEdu?.educationCompletedYear} onChange={editEducation}>
                                                <option >{t('select')}</option>
                                                <option >
                                                    2016
                                                </option>
                                                <option >
                                                    2017
                                                </option>
                                                <option >
                                                    2018
                                                </option>
                                                <option >
                                                    2019
                                                </option>
                                                <option >
                                                    2020
                                                </option>
                                                <option >
                                                    2021
                                                </option>
                                                <option >
                                                    2022
                                                </option>
                                            </select> */}
                                            {/* <input type="number" className="form-select" data-for="educationCompletedYear" data-affects="none" min="1900" max="2022" maxLength="4" value={curEdu?.educationCompletedYear} onChange={editEducation} /> */}
                                            <input type="number" className="form-select" data-for="educationCompletedYear" data-affects="none" min="1900" max="2022" maxLength="4" id="editCompYear" onChange={editEducation} />
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                                <a href="#" className="btn btn-warning btn-md next-btn" data-bs-dismiss="modal">{t('save')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade show" id="AddEducation" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content cardPad card-body border border-radius">
                            <div className="modal-header">
                                <h3 className="modal-title">{t('education')}</h3>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <form id="addEducationForm">
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('education')} <span className="error">*</span></label>
                                            <select className="form-select" id="educationId" onChange={educationvalid} >
                                                <option value="0" key={0}>{t('select')}</option>
                                                {
                                                    educationsMeta.length != 0 ? (
                                                        educationsMeta.map((ele, index) => (
                                                            <option key={index} value={ele.educationId}>{ele.educationName}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select>
                                            <label id="educationIderror" className="error hide">Education not selected</label>
                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('course')} <span className="error">*</span></label>
                                            <select className="form-select" id="educationCourseId" onChange={educationvalid} >
                                                <option value="0" key={0}>{t('select')}</option>
                                                {
                                                    coursesMeta.length != 0 ? (
                                                        coursesMeta.map((ele, index) => (
                                                            <option key={index} value={ele.courseId}>{ele.courseName}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select>
                                            <label id="educationCourseIderror" className="error hide">Course not selected</label>

                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('university/institute')} <span className="error">*</span></label>
                                            <select className="form-select" id="educationInstituteId" onChange={educationvalid} >
                                                <option value="0" key={0}>{t('select')}</option>
                                                {
                                                    institutesMeta.length != 0 ? (
                                                        institutesMeta.map((ele, index) => (
                                                            <option key={index} value={ele.instituteId}>{ele.instituteName}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select>
                                            <label id="educationInstituteIderror" className="error hide">Institute not selected</label>
                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('courseType')} <span className="error">*</span></label>
                                            <select className="form-select" id="educationCourseTypeId" onChange={educationvalid} >
                                                <option value="0" key={0}>{t('select')}</option>
                                                {
                                                    coursetypesMeta.length != 0 ? (
                                                        coursetypesMeta.map((ele, index) => (
                                                            <option key={index} value={ele.courseTypeId}>{ele.courseTypeName}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select>
                                            <label id="educationCourseTypeIderror" className="error hide">Course Type not selected</label>

                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('completedYear')} <span className="error">*</span></label>
                                            {/* <select className="form-select" id="educationCompletedYear" onChange={educationvalid} >
                                                <option value="0" key={0}>{t('select')}</option>
                                                <option >
                                                    2016
                                                </option>
                                                <option >
                                                    2017
                                                </option>
                                                <option >
                                                    2018
                                                </option>
                                                <option >
                                                    2019
                                                </option>
                                                <option >
                                                    2020
                                                </option>
                                                <option >
                                                    2021
                                                </option>
                                                <option >
                                                    2022
                                                </option>
                                            </select> */}
                                            <input type="number" className="form-select" id="educationCompletedYear" min="1900" max="2022" maxLength="4" onChange={educationvalid} />
                                            <label id="educationCompletedYearerror" className="error hide">Year not provided</label>

                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                                <a href="#" className="btn btn-warning btn-md next-btn" onClick={addEducation}>{t('save')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade show" id="EditCertification" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content cardPad card-body border border-radius">
                            <div className="modal-header">
                                <h3 className="modal-title">{t('certification')}</h3>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('certificationTitle')} <span className="error">*</span></label>
                                        <input type="text" className="form-control" placeholder={t('certificationTitle')} data-for="certificationTitle" value={curCert?.certificationTitle} onChange={EditCertification} />
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('completiondate')} <span className="error">*</span></label>
                                        <input type="date" className="form-control" data-for="certificationCompletionDate" value={curCert?.certificationCompletionDate} onChange={EditCertification} />
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('organization')} <span className="error">*</span></label>
                                        <input type="text" className="form-control" placeholder={t('organization')} data-for="certificationOrganization" value={curCert?.certificationOrganization} onChange={EditCertification} />
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('uploadaCertification')} <span className="error">*</span></label>
                                        <input className="form-control" type="file" multiple="" data-for="certificationFiles" onChange={EditCertification} />
                                        {/* value={curCert?.certificationFiles} */}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                                <a href="#" className="btn btn-warning btn-md next-btn" data-bs-dismiss="modal">{t('save')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade show" id="AddCertification" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content cardPad card-body border border-radius">
                            <div className="modal-header">
                                <h3 className="modal-title">{t('certification')}</h3>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <form id="addCertificationForm">
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('certificationTitle')} <span className="error">*</span></label>
                                            <input type="text" className="form-control" placeholder={t('certificationTitle')} id="certificationTitle" onChange={certificatevalid} />
                                            <label id="certificationTitleerror" className="error hide"> Certification title not provided</label>
                                        </div>

                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('completiondate')} <span className="error">*</span></label>
                                            <input type="date" className="form-control" placeholder={t('completiondate')} id="certificationCompletionDate" onChange={certificatevalid} />
                                            <label id="certificationCompletionDateerror" className="error hide"> Certification completion date not provided</label>

                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('organization')} <span className="error">*</span></label>
                                            <input type="text" className="form-control" placeholder={t('organization')} id="certificationOrganization" onChange={certificatevalid} />
                                            <label id="certificationOrganizationerror" className="error hide">Certification organization not provided</label>

                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('uploadaCertification')}</label>
                                            <input className="form-control" type="file" multiple="" id="certificationFiles" onChange={uploadCertificateFile} />
                                            <label id="certificationFileserror" className="error hide">Certification files not provided</label>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                                <a href="#" className="btn btn-warning btn-md next-btn" onClick={addCertification}>{t('save')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="modal fade show" id="UploadCV">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content cardPad card-body border border-radius">
                            <div className="modal-header">
                                <h3 className="modal-title">{t('uploadaCV')}</h3>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <form id="addCVForm">
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('cvTitle')} <span className="error">*</span></label>
                                            <input type="text" className="form-control" id="user_cv_title" placeholder={t('cvTitle')} />
                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('uploadaCV')} <span className="error">*</span></label>
                                            <input className="form-control" type="file" id="cv_attachment" multiple="" onChange={addCVAttachmentFile} />
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="#" className="btn btn-warning btn-md next-btn" data-bs-dismiss="modal" onClick={addUploadCVInfo}>{t('save')}</a>
                                <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                            </div>
                        </div>
                    </div>
                </div> */}



                <div className="modal fade show" id="UploadCV" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content cardPad card-body border border-radius">
                            <div className="modal-header">
                                <h3 className="modal-title">Upload CV</h3>
                                <button
                                    type="button"
                                    className="close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <form id="addCVForm">
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('cvTitle')} <span className="error">*</span></label>
                                            <input type="text" className="form-control" id="user_cv_title" placeholder={t('cvTitle')} onChange={cvvalid} />
                                            <label id="cvTitleerror" className="error hide">CV Title is Required</label>

                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('uploadaCV')} <span className="error">*</span></label>
                                            <input className="form-control" type="file" id="cv_attachment" multiple="" onChange={addCVAttachmentFile} />
                                            <label id="cvAttachementerror" className="error hide">Attachments is Required </label>

                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                                <a className="btn btn-warning btn-md next-btn" id="uploadCVSave" onClick={addUploadCVInfo}>{t('save')}</a>
                            </div>
                        </div>
                    </div>
                </div>




                <div className="modal fade show" id="Edit_UploadCV" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content cardPad card-body border border-radius">
                            <div className="modal-header">
                                <h3 className="modal-title">{t('uploadaCV')}</h3>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <form id="addCVForm">
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('cvTitle')} <span className="error">*</span></label>
                                            <input type="text" className="form-control" data-for="user_cv_title" value={curCVInfo?.user_cv_title} onChange={editCVInfo} placeholder={t('cvTitle')} />
                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('uploadaCV')} <span className="error">*</span></label>
                                            <input className="form-control" type="file" data-for="cv_attachment" onChange={editCVInfo} multiple="" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                                <a href="#" className="btn btn-warning btn-md next-btn" data-bs-dismiss="modal">{t('save')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade show" id="Industry">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content cardPad card-body border border-radius">
                            <div className="modal-header">
                                <h3 className="modal-title">{t('industry')}</h3>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputName1">{t('industry')} </label>
                                            <select className="form-select" multiple id="experienceIndustry" onChange={addUpdIndustry}>
                                                <option value="0">---Choose---</option>
                                                {
                                                    industrydetails.length != 0 ? (
                                                        industrydetails.map((ele, index) => (
                                                            <option key={index} value={ele.industryId}>{ele.industryName}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                                <a href="#" className="btn btn-warning btn-md next-btn" data-bs-dismiss="modal">{t('save')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade show" id="Terms">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content cardPad card-body border border-radius">
                            <div className="modal-header">
                                <h3 className="modal-title">{t('termsAndConditions')}</h3>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body" id="termsBody">
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                            </div>
                            <div className="modal-footer">
                                <a href="#" className="btn btn-danger btn-sm close" data-bs-dismiss="modal">{t('close')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade show" id="NonDiscAgreement" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">{curType == 1 ? "Non Disclosure" : curType == 2 ? "Privacy Policy" : curType == 3 ? "COC" : ""}
                                    {/* {t('nonDisclosureAgreement')} */}
                                </h3>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body NonDiscAgreementBody" id="NDA_Body_Id">
                                {/* <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> */}

                                {/* <PDFViewer userData={personalInfo} type={curType} sendAckInfo={sendAckInfo} /> */}


                            </div>
                            <div className="pdfViewer mb_10">
                                {/* <a href="#" className="btn btn-danger btn-sm close" data-bs-dismiss="modal">{t('close')}</a> */}
                                <a href="#"

                                    onClick={addNdaAckInfo}
                                    className="float-right btn btn-warning btn-md next-btn hide"
                                    id="nda-sign-btn"
                                >
                                    Sign the agreement
                                </a>
                                <a href="#"
                                    onClick={openSignPad}
                                    //className="float-right btn btn-warning btn-md next-btn"
                                    className= {`float-right btn btn-warning btn-md next-btn ${showNDASignPicker === true ? "d-none" : ""}`}
                                    id="open-nda-sign-btn"
                                >
                                    Open SignPad
                                </a>
                                {showNDASignPicker && <SignPad sendSignImgDataToParent={sendSignImgDataToParent} curAckType={curType} clearAllCount={clearAllCount} clearACkType={clearACkType} />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade show" id="NDA" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">{curType == 1 ? "Non Disclosure" : curType == 2 ? "Privacy Policy" : curType == 3 ? "Code of Conduct" : ""}
                                    {/* {t('nonDisclosureAgreement')} */}
                                </h3>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body ndaBody">
                                {/* <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                        dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                        reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p>
                                <p className="notification-text thnku_txt mb_20">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                                    dolores ipsum veritatis voluptatibus tenetur unde repellendus possimus, debitis sapiente? Eveniet porro vel
                                    reiciendis voluptatibus! Saepe ut delectus labore qui amet.</p> */}

                                <PDFViewer userData={personalInfo} type={curType} sendAckInfo={sendAckInfo} clearAllCount={clearAllCount} clearACkType={clearACkType} />
                            </div>
                            <div className="modal-footer">
                                {/* <a href="#" className="btn btn-danger btn-sm close" data-bs-dismiss="modal">{t('close')}</a> */}
                            </div>
                        </div>
                    </div>
                </div>





                <form ref={emailform} style={{ display: "none" }}>
                    <label>From Name</label>
                    <input type="text" name="from_name" value="WorkConnect" />
                    <label>To Name</label>
                    <input type="text" name="to_name" value={personalInfo?.firstName + " " + personalInfo?.lastName} />
                    <label>Subject</label>
                    <input type="text" name="subject" value="CIO Registration Test" />
                    <label>CIO UserName</label>
                    <input type="text" name="cio_user_name" value={personalInfo?.userName} />
                    <label>Reply To</label>
                    <input type="email" name="reply_to" value={personalInfo?.email} />
                    <label>Message</label>
                    <textarea name="message" value="You have been successfully registered as CIO in WorkConnect !!" />
                </form>
            </div >
        </>
    )
}
export default CXORegistration

