import React from 'react'
import { useEffect, useState } from 'react'
import profilepic from "../../../assets/images/noav.jpg"
import $ from "jquery";
import { Link } from 'react-router-dom';
import axios from 'axios';
import logo1 from "../../../assets/images/group.png";
import { API } from '../../../config/APIConfig';
import { celebrationToast } from '../../../utils/toastUtil';
const GETGROUPSUGGESTIONSLISTAPI = `${API.URL}api/v1/social/group/list/suggestions`;
const PARTICIPATEINGROUPAPI = `${API.URL}api/v1/social/group/participate`;
const AddToFeed = () => {
    const [groupSuggestionsList, setGroupSuggestionsList] = useState([]);
    const curUserId = sessionStorage.getItem("userId");
    const curUserRoleId = sessionStorage.getItem("roleId");
    const curUserEntityId = 3;
    const getGroupList = () => {
        axios.get(`${GETGROUPSUGGESTIONSLISTAPI}?userId=${curUserId}&userEntityId=${curUserEntityId}&userRoleId=${curUserRoleId}`)
            .then(resp => {
                const groupSuggestions = resp.data.response;
                setGroupSuggestionsList([...groupSuggestions])
            }).catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        getGroupList();
    }, [])

    const sendParticipationRequest = (e) => {
        const groupId = e.target.getAttribute("data-group-id");
        const participationObj = {
            "requestedByUserId": curUserId,
            "requestedByUserEntityId": curUserEntityId,
            "requestedByUserRoleId": curUserRoleId,
            "groupId": groupId
        }
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
        }
        axios.post(PARTICIPATEINGROUPAPI, participationObj, {
            headers: headers
        }).then(response => {
            if (response.data.status === 200) {
                $("#subscribeGroupIcon-" + groupId).hide();
                $("#postSubGroupIcon-" + groupId).removeClass("d-none");
                $("#group-" + groupId).addClass("blur");
                celebrationToast(response.data.response);
                setTimeout(() => { getGroupList(); $("#group-" + groupId).removeClass("blur"); }, 1000)
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
    return (
        <div className="border-top-gray">
            <div
                className="addToFeedBody box-home box_shadow_1 icon-box main-company-list px-4 pt-4 pb-3"
            >
                <h3 className="fs-4 fw-bold">
                    Add to feed
                    <Link to="/cxo/cxo/grouplist"><i
                        className="fa-regular fa-square-plus float-end text-black-50" title="Subscribe Groups"
                    ></i></Link>
                </h3>
                <div className="list-company mt-4">
                    {
                        groupSuggestionsList.length != 0 ? (
                            groupSuggestionsList.map((ele, index) => (
                                <div
                                    className="d-flex justify-content-between align-items-center mb-2" key={index} id={`group-${ele?.groupId}`}
                                >
                                    <div className="pe-4">
                                        <img
                                            src={ele?.logo ? ele?.logo : logo1} alt="Group" className="img-fluid img-jobs" />
                                    </div>
                                    <div className="nowarp-text groupname_nowrap">
                                        <span className="fw-bold" title={`${ele.title} ${ele.isPrivate === false ? " - Public" : " - Private"}`}>
                                        <i className={`mdi mdi-lock text-muted ml_10 ${ele.isPrivate === false ? "d-none" : ""}`}></i>
                                            <Link to={`/cxo/cxo/wall/group/${ele?.groupId}`} className="groupname" state={{ groupId: ele?.groupId }}>{ele?.title}</Link>   
                                        </span>
                                        <span className="text-black-50">{ele?.totalParticipants} Members</span>
                                    </div>
                                    <i className="fa-solid fa-plus float-end text-black-50" id={`subscribeGroupIcon-${ele?.groupId}`} data-group-id={ele?.groupId} onClick={sendParticipationRequest} title="Subscribe"></i>
                                    <i className="fa fa-check float-end text-black-50 d-none" id={`postSubGroupIcon-${ele?.groupId}`} title={`${ele.isPrivate === false ? "Subscribed" : "Waiting for Approval"}`}></i>
                                </div>
                            ))
                        ) : null
                    }
                </div>
            </div>
        </div>
    )
}

export default AddToFeed