import React, { useEffect, useState } from 'react';
import Footer from '../../../commons/components/Footer';
import Header from '../../../commons/components/Header';
import EnterpriseSideBar from '../../../commons/components/SideBars/EnterpriseSideBar';
import RFP from './widgets/RFP';
import TotalProjects from './widgets/TotalProjects';
import ProjectsInprogress from './widgets/ProjectsInprogress';
import CompletedProjects from './widgets/CompletedProjects';
import ClosedRFP from './widgets/ClosedRFP';
import ExpiredRFP from './widgets/ExpiredRFP';
import OpenRFP from './widgets/OpenRFP';
import DraftRFP from './widgets/DraftRFP';
import WithdrawnRFP from './widgets/WithdrawnRFP';
import '../../../assets/fontawesome6/css/all.min.css'
import '../../../assets/css/feather.css';
import '../../../assets/css/materialdesignicons.min.css';
import '../../../assets/css/themify-icons.css';
// import '../../../assets/css/typicons.css';
import '../../../assets/css/simple-line-icons.css';
import '../../../assets/css/style.css';
import '../../../assets/css/custom.css';
import { API } from '../../../config/APIConfig';
import axios from 'axios';
// import '../../../assets/js/vendor.bundle.base.js';
// import '../../../assets/js/Chart.min.js';
// import '../../../assets/js/bootstrap-datepicker.min.js';
// import '../../../assets/js/progressbar.min.js';
// import '../../../assets/js/off-canvas.js';
// import '../../../assets/js/hoverable-collapse.js';
// import '../../../assets/js/template.js';
// import '../../../assets/js/settings.js';
// import '../../../assets/js/todolist.js';
// import '../../../assets/js/jquery.cookie.js';
// import '../../../assets/js/dashboard.js';
// import '../../../assets/js/Chart.roundedBarCharts.js';
const DASHBOARDANALYTICSAPI = `${API.URL}api/v1/admin/dashboardcount`;

const EnterpriseDashboard = () => {
    const [dashboardInfo, setDashboardInfo] = useState([])
    const [loading, setLoading] = useState(false);
    const loggedInUserId = sessionStorage.getItem("userId");
    const curuserRoleId = sessionStorage.getItem("roleId");
    const curUserEntityId = sessionStorage.getItem("entityId");
    const getDashboardDetails = () => {
        setLoading(true);
        axios.get(`${DASHBOARDANALYTICSAPI}?userId=${loggedInUserId}&userEntityId=${curUserEntityId}&userRoleId=${curuserRoleId}`)
            .then(resp => {
                setDashboardInfo(resp.data.response)
                setLoading(false);
            }).catch(error => {
                console.log(error);
            })
    }
    useEffect(() => {
        var highlightele = document.getElementById("dashboardSidNav");
        highlightele.classList.add("active");
        getDashboardDetails();
    }, []);
    return (
        <div className="container-scroller">
            <Header entity="Enterprise" />
            <div className="container-fluid page-body-wrapper">
                <EnterpriseSideBar />
                {loading ? (
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                ) : (
                    <div className="main-panel">
                        <div className="content-wrapper bg-light-theme">
                            {
                                dashboardInfo.length != 0 ? (
                                    dashboardInfo.map((elem, index) => (
                                        <div className="row d-none1 mx-auto col-md-6 mx-auto text-center" key={index}>
                                            <RFP count={elem?.totalRFPCreated} />
                                            <OpenRFP count={elem?.totalOpenRFP} />
                                            <DraftRFP count={elem?.totalDraftRFP} />
                                            {/* <ClosedRFP data={elem.totalClosedRFP}/>
                                        <ExpiredRFP data={elem.totalExpiredRFP}/>
                                        <WithdrawnRFP data={elem.totalWithdrawnRFP}/> */}
                                            {/* <TotalProjects />
                            <ProjectsInprogress />
                            <CompletedProjects /> */}
                                        </div>
                                    ))
                                ) : <div className="row d-none1 mx-auto col-md-6 mx-auto text-center">
                                    <RFP />
                                    <OpenRFP />
                                    <DraftRFP />
                                    {/* <ClosedRFP />
                                <ExpiredRFP />
                                <WithdrawnRFP /> */}
                                    {/* <TotalProjects />
                <ProjectsInprogress />
                <CompletedProjects /> */}
                                </div>}
                        </div>
                        <Footer />
                    </div>
                )}
            </div>
        </div>
    )
}

export default EnterpriseDashboard



