import React, { useState, useEffect } from 'react'
import Footer from '../../../commons/components/Footer';
import Header from '../../../commons/components/Header';
import CaaSSideBar from '../../../commons/components/SideBars/CaaSSideBar';
import OpenRFP from './widgets/OpenRFP';
import TotalRFP from './widgets/TotalRFP';
import TotalProjects from './widgets/TotalProjects';
import ProjectsInprogress from './widgets/ProjectsInprogress';
import TotalEnterprises from './widgets/TotalEnterprises';
import TotalCIO from './widgets/TotalCIO';
import TotalPartners from './widgets/TotalPartners';
import CompletedProjects from './widgets/CompletedProjects';
import TotalVendors from './widgets/TotalVendors';
import '../../../assets/fontawesome6/css/all.min.css'
import '../../../assets/css/feather.css';
import '../../../assets/css/materialdesignicons.min.css';
import '../../../assets/css/themify-icons.css';
import '../../../assets/css/simple-line-icons.css';
import '../../../assets/css/style.css';
import '../../../assets/css/custom.css';
import { API } from '../../../config/APIConfig';
import axios from 'axios';
import $ from "jquery";

const DASHBOARDANALYTICSAPI = `${API.URL}api/v1/admin/dashboardcount`;

const GlobalDashboard = () => {
    const [dashboardInfo, setDashboardInfo] = useState([])
    const [loading, setLoading] = useState(false);
    const loggedInUserId = sessionStorage.getItem("userId");
    const curuserRoleId = sessionStorage.getItem("roleId");
    const curUserEntityId = sessionStorage.getItem("entityId");
    const getDashboardDetails = () => {
        setLoading(true);
        axios.get(`${DASHBOARDANALYTICSAPI}?userId=${loggedInUserId}&userEntityId=${curUserEntityId}&userRoleId=${curuserRoleId}`)
            .then(resp => {
                setDashboardInfo(resp.data.response)
                setLoading(false);
            }).catch(error => {
                console.log(error);
            })
    }
    useEffect(() => {
        getDashboardDetails();
        highlightNavMenu();
    }, [])
    const highlightNavMenu = () => {
        $("li.sidebarmenu").removeClass("active");
        $("#caas_dasboard_li").addClass("active");
    }
    return (
        <div className="container-scroller">
            <Header entity="CaaS" />
            <div className="container-fluid page-body-wrapper">
                <CaaSSideBar />
                {loading ? (
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                ) : (
                    <div className="main-panel">
                        <div className="content-wrapper bg-light-theme">
                            {
                                dashboardInfo.length != 0 ? (
                                    dashboardInfo.map((elem, index) => (
                                        <div className="row d-none1" key={index}>
                                            <OpenRFP count={elem?.totalOpenRFP} />
                                            <TotalRFP count={elem?.totalRFPCreated} />
                                            {/* <TotalProjects /> */}
                                            {/* <ProjectsInprogress /> */}
                                            <TotalEnterprises count={elem?.totalenterpriseCreated} />
                                            <TotalCIO count={elem?.totalCIOCreated} />
                                            <TotalVendors count={elem?.totalVendorCreated} />
                                            {/* <TotalPartners/> */}
                                            {/* <CompletedProjects /> */}
                                        </div>

                                    ))
                                ) : <div className="row d-none1">
                                    <OpenRFP />
                                    <TotalRFP />
                                    {/* <TotalProjects /> */}
                                    {/* <ProjectsInprogress /> */}
                                    <TotalEnterprises />
                                    <TotalCIO />
                                    <TotalVendors />
                                    {/* <TotalPartners /> */}
                                    {/* <CompletedProjects /> */}
                                </div>
                            }
                            {/* <div className="row d-none1">
                            <OpenRFP  data={data}/>
                            <TotalRFP data={data}/>
                            <TotalProjects />
                            <ProjectsInprogress />
                            <TotalEnterprises data={data}/>
                            <TotalCIO data={data}/>
                            <TotalPartners data={data}/>
                            <CompletedProjects />
                        </div> */}
                        </div>
                        <Footer />
                    </div>
                )}
            </div>
        </div>
    )
}

export default GlobalDashboard