import { useRef, useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { API } from '../../../../config/APIConfig';
import axios from 'axios';
import CAAS_LOGO from '../../../../assets/images/CAAS_Logo.png';
import logger from '../../../../utils/logger';
import swal from 'sweetalert';
const LOGIN_URL = `${API.URL}api/v1/auth/login`;
const CaasAdminLogin = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [roleList, setRoleList] = useState([]);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const checkLoginValidation = () => {
        var flag = true;
        var obj = {
            "userName": user,
            "password": pwd
        }
        if (!obj.userName) {
            setErrMsg('Missing Username');
            flag = false;
            return flag;
        }
        if (!obj.password) {
            setErrMsg('Missing Password');
            flag = false;
            return flag;
        }
        return flag;
    }
    const handleSubmit = async (e) => {
        const caasAdminRoleId = 1;
        logger.verbose("User Login", "AuthLogin Component", "Auth Login Submit Button", "POST", "User clicked on Submit Button ", "Nodata");
        var flag = checkLoginValidation();
        if (flag) {
            e.preventDefault();
            const userdata = {
                "userName": user,
                "password": pwd,
                "roleId": caasAdminRoleId
            }
            logger.verbose("User Login", "AuthLogin Component", "Auth Login Submit Button", "POST", "After Successfull validation ", JSON.stringify(userdata));
            // try {
            const headers = {
                'Content-Type': 'application/JSON;charset=utf-8',
            }
            axios.post(LOGIN_URL, userdata, {
                headers: headers
            }).then(response => {
                //console.log(response);
                if (response.data.status === 200) {
                    if (response.data.response[0].isAuthenticated === true) {
                        logger.verbose("User Login", "AuthLogin Component", "Auth Login API Check", "POST", "User Authenticated Successfully ", response.data.response.isAuthenticated);
                        sessionStorage.clear();
                        // console.log(response);
                        // console.log(JSON.stringify(response?.data));
                        //console.log(JSON.stringify(response));
                        const accessToken = response?.data?.response[0].accessToken;
                        const roleId = response?.data?.response[0].roleId;
                        const firstName = response?.data?.response[0].firstName;
                        const lastName = response?.data?.response[0].lastName;
                        const userName = response?.data?.response[0].userName;
                        //setAuth({ user, pwd, roleId, accessToken });
                        sessionStorage.setItem("roleId", roleId);
                        sessionStorage.setItem("entityId", response?.data?.response[0].entityId);
                        sessionStorage.setItem("profilePicture", response?.data?.response[0].profilePicture);
                        sessionStorage.setItem("firstname", firstName);
                        sessionStorage.setItem("lastname", lastName);
                        sessionStorage.setItem("userName", userName);
                        sessionStorage.setItem("adminId", response?.data?.response[0].adminId);
                        sessionStorage.setItem("userId", response?.data?.response[0].userId);
                        sessionStorage.setItem("accessToken", accessToken);
                        logger.verbose("User Login", "AuthLogin Component", "Auth Login Submit Button", "POST", "CAAS Admin User LoggedIn Successfully ", JSON.stringify(response.data.response));
                        navigate("/caas/global/dashboard")
                        // navigate("cxo/cxo/dashboard");

                        setUser('');
                        setPwd('');
                    }
                    else {
                        logger.error("User Login", "AuthLogin Component", "Auth Login API user authenticate Failed", "POST", "User Login Failed ", JSON.stringify(response.data.response.isAuthenticated));
                        // setErrMsg('Unauthorized');
                        setErrMsg('');
                        swal("Credentials are incorrect", "Please Try Again", "error");
                    }
                }
            }).catch(err => {
                console.error('There was an error!', err);
                logger.error("User Login", "AuthLogin Component", "Auth Login API respone Failed", "POST", "API Failed", JSON.stringify(err));
                if (!err?.response) {
                    setErrMsg('No Server Response');
                } else if (err.response?.status === 400) {
                    setErrMsg('Missing Username or Password');
                } else if (err.response?.status === 401) {
                    setErrMsg('Unauthorized');
                } else {
                    setErrMsg('Login Failed');
                    swal("Login Failed", "Please Try Again", "error");
                }
                errRef.current.focus();
            });
        }
        else {
            e.preventDefault();
            logger.error("User Login", "AuthLogin Component", "Auth Login Submit Button", "POST", "Validation Failed ", flag);
            console.log("Failed to register");
            //swal("Failed", "Please Try Again", "error");
        }
    }

    const handleBack = () => {
        navigate("/");

    }
    return (

        <div className="container-scroller">
            {/* <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p> */}
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex align-items-center auth px-0">
                    <div className="row w-100 mx-0">
                        <div className="col-lg-4 mx-auto">
                            <div className="auth-form-light text-left py-4 px-3 px-sm-4">
                                <div> <img src={CAAS_LOGO} className="img-responsive caas_logo"></img>
                                </div>
                                <h4 className="text-center pb-1 fw-bold">CAAS Admin Login</h4>
                                <form className="pt-1 font-weight-bold">
                                    <div className="form-group">
                                        <p ref={errRef} className={errMsg ? "bar_login error_login" : "offscreen"} aria-live="assertive">{errMsg ? <i className="ico">&#9747;</i> : ""}{errMsg}</p>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            id="username"
                                            placeholder="Username (Email)"
                                            ref={userRef}
                                            autoComplete="off"
                                            onChange={(e) => setUser(e.target.value)}
                                            value={user}
                                            required
                                        />
                                        {/* <input type="email" className="form-control form-control-sm" id="exampleInputEmail1" placeholder="Username (Email)"/> */}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            className="form-control form-control-sm"
                                            id="password"
                                            placeholder="Password"
                                            onChange={(e) => setPwd(e.target.value)}
                                            value={pwd}
                                            required
                                        />
                                        {/* <input type="password" className="form-control form-control-sm" id="exampleInputPassword1" placeholder="Password"/> */}
                                    </div>
                                    <div className="mt-3">
                                        <button className="btn btn-primary fw-bold btn-sm" onClick={handleSubmit}>Sign In</button>
                                        {/* <a href="index.html" className="btn btn-primary fw-bold btn-sm">Login</a> */}
                                    </div>
                                    <div className="my-2 d-flex justify-content-between align-items-center">
                                        <div className="form-check">
                                            {/* <label className="form-check-label text-muted fw-bold">
                      <input type="checkbox" className="form-check-input"/>
                      Remember me
                    </label> */}
                                            <button className="btn btn-primary fw-bold btn-sm" onClick={handleBack}><i className="mdi mdi-arrow-left" aria-hidden="true"></i> Back to Home</button>
                                        </div>
                                        {/* <a href="../forgotpassword.html" className="auth-link text-black fw-bold">Forgot password?</a> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CaasAdminLogin
