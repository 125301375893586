import { React, useState, useEffect } from 'react'
import { API } from '../../../config/APIConfig';
import axios from 'axios';
import Pagination from './Pagination';
import Header from '../../../commons/components/Header';
import CaaSSideBar from '../../../commons/components/SideBars/CaaSSideBar';
import swal from 'sweetalert';
import $ from "jquery";
import { Link } from 'react-router-dom'

const EnterpriseManagement = () => {
  const [enterpriseList, setEnterpriseList] = useState([]);
  const [curEnterpriseList, setCurEnterpriseList] = useState([]);
  const [resetUserDetails, setResetUserDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const ENTERPRISELISTAPI = `${API.URL}api/v1/enterprise/allenterprises`;
  const UPDATEENTERPRISESTATUSAPI = `${API.URL}api/v1/admin/updateenterprisestatus`;
  const UPDATEENTERPRISEAPPROVALSTATUSAPI = `${API.URL}api/v1/admin/updateenterpriseapproval`;
  const RESETPASSWORDAPI = `${API.URL}api/v1/auth/password/reset`;
  // const ENTERPRISERESETPASSWORDAPI = `${API.URL}api/v1/auth/enterprisepassword/reset`;


  const [currentPage, setCurrentPage] = useState(1);
  // No of Records to be displayed on each page   
  const [recordsPerPage] = useState(10);
  //pagination
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const getEnterpriseList = async () => {
    setLoading(true);
    const response = await fetch(ENTERPRISELISTAPI);
    if (!response.ok) {
      throw new Error('Data coud not be fetched!')
    } else {
            return response.json()      
    }
  }
  const bindEnterprises = () => {
    getEnterpriseList()
      .then((data) => {
        if (data.response) {

          setEnterpriseList(data.response);
          setCurEnterpriseList(data.response)
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e.message)
      });
  }
  useEffect(() => {
    bindEnterprises();
    highlightNavMenu();
  }, []);

  const highlightNavMenu = () => {
    $("li.sidebarmenu").removeClass("active");
    $("#caas_enterprises_li").addClass("active");
  }
  //pagination

  const currentRecords = curEnterpriseList.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(curEnterpriseList.length / recordsPerPage)


  const resetEnterpriseStatus = (enterpriseId, curStatus) => {
    var status = curStatus == 0 ? 1 : 0;
    updateEnterpriseDetails(enterpriseId, status);
  }
  const updateEnterpriseDetails = (enterpriseId, status) => {
    const searchedenterprise = enterpriseList.filter((ele) => ele?.enterpriseId == enterpriseId);
    if (searchedenterprise.length != 1)
      return
    var { enterpriseName, entityId, roleId } = searchedenterprise[0];
    let updobj = { enterpriseId, entityId, roleId, enterpriseName, status }

    axios.put(UPDATEENTERPRISESTATUSAPI, updobj)
      .then(response => {
        if (response.data.status === 200) {
          bindEnterprises();

          swal("Updated Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  const updateEnterpriseApprovalStatus = (enterpriseId, approval) => {

    const searchedenterprise = enterpriseList.filter((ele) => ele?.enterpriseId == enterpriseId);
    if (searchedenterprise.length != 1)
      return
    var { enterpriseName, emailAddress, keyContact, entityId, roleId, keyContactEmail, userName } = searchedenterprise[0];
    var isApproved = approval == 0 ? 1 : 0;
    let updobj = { enterpriseId, entityId, emailAddress, roleId, enterpriseName, keyContact, isApproved, keyContactEmail, userName }
    axios.put(UPDATEENTERPRISEAPPROVALSTATUSAPI, updobj)
      .then(response => {
        if (response.data.status === 200) {
          bindEnterprises();
          swal("Approved Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }


  const searchEnterprise = () => {
    const keyword = document.getElementById("searchenterprise").value;
    const searchedenterprise = enterpriseList.filter((ele) => ele?.enterpriseName.toLowerCase().includes(keyword.toLowerCase() || ele?.userName.toLowerCase().includes(keyword.toLowerCase())));
    setCurrentPage(1);
    setCurEnterpriseList([...searchedenterprise]);
  }
  const bindUserDetailsForReset = (enterpriseId) => {
    document.getElementById("resetpasswordform").reset();
    const searchedenterprise = enterpriseList.filter((ele) => ele?.enterpriseId == enterpriseId);
    if (searchedenterprise.length != 1)
      return
    setResetUserDetails([...searchedenterprise]);
    document.getElementById("resetUserName").value = searchedenterprise[0]?.keyContact;
  }
  const resetUserPassword = () => {
    $("#resetPasswordbtn").addClass("disabled");
    const userName = document.getElementById("resetUserName").value;
    const userNewPassword = document.getElementById("resetEnterprisePassword").value;

    if (userName.length > 0 && userNewPassword.length > 0) {

      const { enterpriseId, emailAddress, userId, entityId, roleId, keyContact, keyContactEmail } = resetUserDetails[0];


      if (userName === keyContact) {
        let updobj = { enterpriseId, emailAddress, userId, entityId, roleId, userName, userNewPassword, keyContactEmail };
        axios.put(RESETPASSWORDAPI, updobj)
          .then(response => {
            if (response.data.status === 200) {
              swal("Password was Reset Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
              $("#ResetPassword .close").trigger('click');
              $("#resetPasswordbtn").removeClass("disabled");
              document.getElementById("resetpasswordform").reset();
              bindEnterprises();
            }
            $("#resetPasswordbtn").removeClass("disabled");
          })
          .catch(error => {
            console.error('There was an error!', error);
            $("#resetPasswordbtn").removeClass("disabled");
          });
      }
    }
    else {
      swal("Please enter the password", "", "error", { closeOnClickOutside: false, closeOnEsc: false });
      $("#resetPasswordbtn").removeClass("disabled");
    }
  }
  const handleKeyPress = (e) => {
    if (e.charCode === 32 && e.key === " ") {
      e.preventDefault();
    }
  }
  return (
    <div className="container-scroller">
      <Header entity="CaaS" />
      <div className="container-fluid page-body-wrapper">
        <CaaSSideBar />
        {loading ? (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        ) : (
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="row">
                <div className="col-12 grid-margin stretch-card">
                  <div className="col-lg-12 d-grid">
                    <div className="grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body pb-0">
                          <h4 className="card-title">Enterprises</h4>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <div className="mt-0 text-left box-shadow cardPad card-body border border-radius">
                          <div className="d-flex justify-content-end">
                            <div className="float-end d-flex align-items-center filter-option mb-0">
                              <input className="form-control border-secondary border-right-0 rounded-0 search-input" type="search"
                                placeholder="Search Enterprise"
                                id="searchenterprise"
                                onChange={searchEnterprise}
                              />
                              <div className="col-auto">
                                <button className="btn btn-outline-secondary border-left-0 rounded-0 rounded-right search-btn" type="button"><i className="fa fa-search"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-sm-12 mt-2">
                              <div className="home-tab">
                                <div className="tab-content1">
                                  <div className="" id="NewCompany" >
                                    <div className="template-demo table-responsive">
                                      <Records currentRecords={currentRecords} resetEnterpriseStatus={resetEnterpriseStatus} updateEnterpriseApprovalStatus={updateEnterpriseApprovalStatus} bindUserDetailsForReset={bindUserDetailsForReset} />
                                      {/* resetEnterpriseStatus={resetEnterpriseStatus} updateEnterpriseApprovalStatus={updateEnterpriseApprovalStatus} */}
                                      <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>)}
      </div>
      <div className="modal fade show" id="ResetPassword" data-bs-keyboard="false" data-bs-backdrop="static">
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content cardPad card-body border border-radius">
            <div className="modal-header">
              <h3 className="modal-title">Reset Password </h3>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form id="resetpasswordform">

                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">User Name </label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control" id="resetUserName" disabled />

                  </div>

                  <label className="col-sm-4 col-form-label mt_10">Password </label>
                  <div className="col-sm-8 mt_10">
                    <input type="text" className="form-control" id="resetEnterprisePassword" name="resetPassword" onKeyPress={handleKeyPress} />

                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <a type="button" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">Cancel</a>
              <a type="button" className="btn btn-warning btn-md next-btn"
                onClick={resetUserPassword} id="resetPasswordbtn"
              >Reset</a>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Records = ({ currentRecords, resetEnterpriseStatus, updateEnterpriseApprovalStatus, bindUserDetailsForReset }) => {

  return (
    <table className="table table-bordered mb-3">
      <thead>
        <tr>

          <th className="text-center">Enterprise Name</th>
          <th className="text-center">Enterprise Location</th>
          <th className="text-center" >Enterprise PM</th>
          <th className="text-center" >Enterprise Email</th>
          <th className="text-center">Enterprise Contact</th>
          <th className="text-center">Status</th>
          <th className="text-center">Approval Status</th>
          <th className="text-center">Reset Password</th>
        </tr>
      </thead>
      <tbody>
        {
          currentRecords.length != 0 ? (
            currentRecords.map((ele, index) => (
              <tr key={ele?.enterpriseId}>

                <td className="text-left-intend"><Link to={`/caas/global/enterprise/view/${ele.enterpriseId}`} >{ele?.enterpriseName}</Link></td>
                <td className='text-left-intend'>{ele?.address}</td>
                <td className='text-left-intend'> {ele?.keyContact}</td>
                <td className='text-left-intend'> {ele?.keyContactEmail}</td>

                <td className='text-left-intend'> {ele.phoneNumber}</td>
                <td className="text-center">
                  <div className="d-flex">
                    <label htmlFor="site_state" className="form-check-label">In Active</label>
                    <div className="form-switch form-check ms-2 margin-0">
                      <input type="checkbox" className="form-check-input" id="site_state" onChange={() => {

                        resetEnterpriseStatus(ele?.enterpriseId, ele?.status)

                      }} checked={ele?.status == 1 ? 'checked' : ''} />
                    </div>
                    <label htmlFor="site_state" className="form-check-label">Active</label>
                  </div>
                </td>
                {/* <td className="text-center">
                                    <div className={ele?.status === 1 ? "badge  badge-success" : "badge badge-warning"}>{ele?.status === 1 ? "Active" : "InActive"}</div>
                                </td> */}
                <td>
                  {ele?.isApproved == 1 ? "Approved" : <div className="form-switch form-check  margin-0 text-center">
                    <input type="checkbox" className="form-check-input text-center" id="site_state" onChange={() => {

                      // resetEnterpriseStatus(ele?.enterpriseId,ele?.enterpriseName,ele?.status)
                      // updateUserApprovalStatus(ele?.userId, ele?.isApproved)
                      updateEnterpriseApprovalStatus(ele?.enterpriseId, ele?.isApproved)


                    }} />
                  </div>}
                </td>
                <td className='text-center'>
                  {ele?.isApproved == 1 ?
                    <a className="btn addindustrybtn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#ResetPassword" title="Reset Password"
                      onClick={() => { bindUserDetailsForReset(ele?.enterpriseId) }}
                    >
                      Reset Password
                    </a>
                    : "NA"}
                </td>
              </tr>
            ))
          ) : (
            <tr>
            </tr>
          )
        }
      </tbody>

    </table>

  )
}
export default EnterpriseManagement