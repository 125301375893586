import ReactQuill, { Quill } from 'react-quill';
import "quill-mention";
import axios from 'axios';
import { API } from '../config/APIConfig';
import "../../src/assets/css/quill-mentions.css"
const Video = Quill.import('formats/video');
const Link = Quill.import('formats/link');
const CAASMEMBERCONNECTIONS = `${API.URL}api/v1/social/caasmember/connections`;
var caasMemberList;
const getCaasMembersDetails = async () => {
    const loggedInUserId = sessionStorage.getItem("userId");
    const curuserRoleId = sessionStorage.getItem("roleId");
    const curUserEntityId = sessionStorage.getItem("entityId");
    return await axios.get(`${CAASMEMBERCONNECTIONS}?userId=${loggedInUserId}&userEntityId=${curUserEntityId}&userRoleId=${curuserRoleId}`)
}
const bindCaasMembers = (result) => {
    var arr = [];
    if (result && result.length > 0) {
        for (let i = 0; i < result.length; i++) {
            var obj = {};
            obj['id'] = result[i].userId + "-" + result[i].entityId + "-" + result[i].roleId;
            obj['value'] = result[i].firstName + " " + result[i].lastName;
            arr.push(obj);
        }
    }
    return arr;
}
export const quillModules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        // ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
        // ["link", "image", "video"],
        ["link"],
        ["clean"],
    ],

    mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@"],
        positioningStrategy: "fixed",
        renderItem: (data) => { return data?.value; },
        renderLoading: () => {
            return "Loading...";
        },
        source: async function (searchTerm, renderList, mentionChar) {
            if (mentionChar === "@") {
                if (!caasMemberList) {
                    var data = await getCaasMembersDetails();
                    var result = await data?.data?.response;
                    caasMemberList = bindCaasMembers(result);
                }
                const caasmembers = caasMemberList;
                if (searchTerm.length === 0) {
                    renderList(caasmembers, searchTerm);
                } else {
                    const filteredmembers = caasmembers.filter(person => person.value.toLowerCase().includes(searchTerm.toLowerCase()));
                    renderList(filteredmembers, searchTerm);
                }
            }
        }
    }
};

