import React, { useEffect, useState } from 'react'

import "../../../assets/fontawesome6/css/all.min.css";
import "../../../assets/css/themify-icons.css"
import "../../../assets/css/style.css"
import "../../../assets/css/select2-bootstrap.min.css"
import "../../../assets/css/select2.min.css"
import "../../../assets/css/custom.css"
import "../../../assets/css/userend.css"
import "../../../assets/css/responsive.css"
import SocialHeader from './SocialHeader';
import SocialFeedNavigations from './SocialFeedNavigations';
import CAASWorkspaceNavigations from './CAASWorkspaceNavigations';
import ProjectAnnouncements from './ProjectAnnouncements';
import CompanyAnnouncements from './CompanyAnnouncements';
import AddToFeed from './AddToFeed';
import Conversations from './Conversations';
import Posts from '../Posts';
import ProfileInfoAnalytics from './ProfileInfoAnalytics';
import profilepic from "../../../assets/images/company1.jpg"
import { API } from '../../../config/APIConfig';
import axios from 'axios';
import Connections from './Connections';
import logger from '../../../utils/logger';
import $ from "jquery";
const GETENTERPEISEAPI = `${API.URL}api/v1/enterprise/list`;



const EnterpriseNetworks = () => {
    const [enterpriseList, setEnterpriseList] = useState([]);
    const getEnterpriseList = () => {
        axios.get(GETENTERPEISEAPI)
            .then(resp => {
                if (resp.data.status === 200) {
                    const enterpriseList = resp.data.response;
                    setEnterpriseList([...enterpriseList]);
                    // logger.verbose("EnterPrise Details", "EnterpriseNetworks Component", "getEnterpriseList", "GET", "Get Enterprise Setails API Response Successfully!", JSON.stringify(response.data.response));
                }
            }).catch(error => {
                console.log(error);
                // logger.error("EnterPrise Details", "EnterpriseNetworks Component", "getEnterpriseList", "GET", "Get Enterprise Setails API Response Failed!", JSON.stringify(error));
            })
    }
    useEffect(() => {
        getEnterpriseList();
        $("html").removeClass();
        $("html").addClass('enterprisenetworkss');
    }, [enterpriseList])
    return (
        <>
            <div className="main-body-section main-body-section-social">
                <SocialHeader />
                <section className="body-section">
                    <div className="body-content container-fluid">
                    <div className="row1 My_enterprisenetwork">
                            <div className="col_res1 align-items-stretch box_1 w_171">
                                <div className="sticky-top">
                                    <SocialFeedNavigations />
                                    <ProfileInfoAnalytics />
                                </div>
                            </div>
                            <div className=" mob-remove-padding box_2 w_461">
                                <div className="box-home box_shadow_1 px-4">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex justify-content-start align-items-center mb-4">
                                            <h3 className="fw-bold fs-3 ">Enterprise Network</h3>
                                        </div>
                                        {/* <div className="float-end"><a className="text-link">Filter</a></div> */}
                                    </div>
                                    {
                                        enterpriseList.length != 0 ? (
                                            enterpriseList.map((ele) => (
                                                <div className="d-flex justify-content-between align-items-center mt-3">
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <img src={ele.enterpriseLogo ? ele.enterpriseLogo : profilepic} alt="CompanyLogo" className="img-fluid img-profile" />
                                                        <div className="ps-3">
                                                            <p className="mb-2 fw-bold">{ele.enterpriseName}</p>
                                                            <p className="mb-2">{ele.natureOfBusiness}</p>
                                                            <p className="mb-2 text-black-50">Key Contact : {ele.keyContact}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : null
                                    }


                                    {/* <div className="text-center pt-4"><a className="text-link ">See more</a></div> */}
                                </div>

                            </div>
                            {/* <Conversations /> */}
                            <Connections />
                            <div className="col_res1 align-items-stretch box_4 w_171">
                                <div className="sticky-top">
                                    <CAASWorkspaceNavigations />
                                    {/* <ProjectAnnouncements /> */}
                                    <CompanyAnnouncements />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default EnterpriseNetworks