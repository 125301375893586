import React, { useEffect, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { useRef } from 'react'
import $ from 'jquery'
import { S3Config } from '../../config/AWSConfig'
import S3FileUpload from '../../utils/S3FileUpload';
import uuidCustom from '../../utils/uuidCustom'
const SignPad = (props) => {

    const sigCanvas = useRef();

    const { sendSignImgDataToParent } = props;
    const { curAckType, clearAllCount, clearACkType } = props;
    const [penColor, setPenColor] = useState("black");
    const [imageURL, setImageURL] = useState(null);
    const colors = ["black", "green", "red"];


    const createSignImg = () => {
        const newCanvas = sigCanvas.current.getTrimmedCanvas();
        if (newCanvas.height > 1 && newCanvas.width > 1) {
            newCanvas.toBlob((blob) => {
                fileUpload(blob).then(
                    (response) => {
                        if (curAckType == 1) {
                            $("#nda-sign-btn").removeClass("hide");
                            $("#open-nda-sign-btn").addClass("hide");
                        }
                        else {
                            $("#sign-btn").removeClass("hide");
                            $("#opensign-btn").addClass("hide");
                        }
                        // console.log(response.s3FileUrl);
                        setImageURL(response.s3FileUrl);
                        sendSignImgDataToParent(response.s3FileUrl);
                    }).catch(err => console.log(err));
            })
        }
        else {
            alert("Please sign");
        }
    }
    const clearSignImg = () => {
        if (curAckType == 1) {
            $("#nda-sign-btn").addClass("hide");
            $("#open-nda-sign-btn").removeClass("hide");
        }
        else {
            $("#sign-btn").addClass("hide");
            $("#opensign-btn").removeClass("hide");
        }
        sigCanvas.current.clear();
        setImageURL("");
    }
    function dataURItoBlob(dataURI) {
        var binary = atob(dataURI.split(',')[1]);
        var array = [];
        for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
    }
    const fileUpload = async (file) => {

        // const fileName = new Date().toISOString() + "|" +  e.target.files[0].name;
        // const fileName = e.target.files[0].name;
        const fileName = uuidCustom()
        const contentType = "image/jpeg";
        const formData = { file, fileName, contentType };
        let result = await S3FileUpload(formData).then(
            (response) => {
                if (response.status === 200) {
                    return { status: 200, s3FileUrl: S3Config.S3ObjectBaseURL + fileName }
                }
            }
        ).catch(err => console.log(err));
        return result;
    }
    useEffect(() => {
        clearSignImg();
    }, [clearAllCount])

    useEffect(() => {
        if (clearACkType == 1)
            clearSignImg();
    }, [clearACkType])
    return (
        <div className='mt_10'>
            <div className="sigPad__penColors">
                <p>Pen Color:</p>
                {colors.map((color) => (
                    <span
                        key={color}
                        style={{
                            backgroundColor: color,
                            border: `${color === penColor ? `2px solid ${color}` : ""}`,
                        }}
                        onClick={() => setPenColor(color)}
                    ></span>
                ))}
            </div>
            <div className="sigPadContainer">
                <SignatureCanvas
                    penColor={penColor}
                    canvasProps={{ className: "sigCanvas" }}
                    ref={sigCanvas}
                />
                <hr />
                <button className="create" onClick={createSignImg}>
                    Add Sign
                </button>
                <br />
                {
                    imageURL && (
                        <>
                            <img src={imageURL} alt="signature" className="signature" />
                        </>
                    )
                }
                {/* <button onClick={() => { sigCanvas.current.clear(); setImageURL(""); }}>Clear</button> */}
                <button onClick={clearSignImg}>Clear</button>
            </div>
        </div>
    )
}

export default SignPad