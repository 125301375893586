import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';
import CAAS_LOGO from "../../assets/images/CAAS_Logo.png"
//For Language Translation
import i18n from '../../libs/i18n';

const AuthHeader = () => {
    //For Language Translation
    const { i18n, t } = useTranslation(["common"]);
    useEffect(() => {
        if (localStorage.getItem("i18nextLng")?.length > 2) {
            i18n.changeLanguage("en");
        }
    }, []);
    const handleLanguageChange = (e) => {
        i18n.changeLanguage(e.target.value);
    };
    //
    return (
        <nav className="navbar default-layout navbar-expand-lg p-0 fixed-top navbar-light boxshadow">
            <div className="container-fluid">
                <Link to="/" className="navbar-brand" href="#">
                    {/* <h1 className="text-dark h3 fw-bold mb-0">Work<span className="TextBlue h3 fw-bold">Connect</span></h1> */}
                    <img src={CAAS_LOGO} className="img-responsive caas_logo"></img>
                </Link>
                <button className="navbar-toggler collapsed hide" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon "></span>
                </button>
                <div className="navbar-collapse collapse" id="navbarCollapse">
                    <ul className="navbar-nav ms-auto MobResView">
                        {/* <li className="nav-item">
                            <select
                                className="nav-link border-0 ml-1 mr-2"
                                value={localStorage.getItem("i18nextLng")}
                                onChange={handleLanguageChange}
                            >
                                <option value="en">English</option>
                                <option value="ar">Arabic</option>
                                <option value="fr">Français</option>
                                <option value="es">Español</option>
                            </select>
                        </li> */}

                        {/* <li className="nav-item">
                            <Link to="/" className="nav-link navlink text-dark">{t("home")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/auth/login" className="nav-link navlink text-dark">{t("login")}</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/register" className="nav-link navlink text-dark" >{t("registerAndJoinUs")}</Link>
                        </li> */}

                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default AuthHeader