import React, { useEffect } from 'react';
import Footer from '../../../commons/components/Footer';
import Header from '../../../commons/components/Header';
import VendorSideBar from '../../../commons/components/SideBars/VendorSideBar';
import $ from "jquery";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { quillModules } from '../../../utils/quillModules';
import { useState } from 'react';
import { API } from '../../../config/APIConfig';
import axios from 'axios';
import S3FileUpload from '../../../utils/S3FileUpload';
import { S3Config } from '../../../config/AWSConfig';
import logger from '../../../utils/logger';
import swal from 'sweetalert';
import VendorPosts from './VendorPosts';
import uuidCustom from '../../../utils/uuidCustom';
const VendorWall = () => {
    const [postContent, setPostContent] = useState("");
    const [postMedia, setPostMedia] = useState([]);

    const curUserFullName = sessionStorage.getItem("userName");
    const curUserId = sessionStorage.getItem("userId");
    const curUserRoleId = sessionStorage.getItem("roleId");
    const curUserEntityId = sessionStorage.getItem("entityId");
    const profilePicture = sessionStorage.getItem("profilePicture");
    const vendorUserWallId = sessionStorage.getItem("vendorUserWallId");
    const CreatePostAPI = `${API.URL}api/v1/social/post/create`;

    useEffect(() => {
        $(".nav-item").removeClass("active");
        $("#vendor_wall").addClass("active");
    }, [])

    const fileUpload = async (e) => {       
        const file = e.target.files[0];
        // const fileName = new Date().toISOString() + "|" +  e.target.files[0].name;
        var fileName = e.target.files[0].name;   
        var fileExtension =fileName.split('.').pop();  
        fileName +=  S3Config.S3ObjectDelimiter + uuidCustom() +"."+ fileExtension;
        const contentType = file.type;
        const formData = { file, fileName, contentType };
        let result = await S3FileUpload(formData).then(
            (response) => {
                if (response.status === 200) {
                    return { status: 200, s3FileUrl: S3Config.S3ObjectBaseURL + fileName, fileName: fileName, contentType: contentType }
                }
            }
        ).catch(err => console.log(err));
        return result;
    }
    const uploadPostMedia = (e) => {
        const [media] = e.target.files;
        let previewMedia = URL.createObjectURL(media);
        fileUpload(e).then(
            (response) => {
                
                let obj = {
                    id: postMedia.length + 1,
                    mediaTitle: response?.fileName,
                    mediaURL: response?.s3FileUrl,
                    mediaType: response?.contentType,
                    previewMedia: previewMedia
                }
                setPostMedia([...postMedia, obj]);
                console.log(postMedia);
            }).catch(err => console.log(err));
    }

    const removeMedia = (e) => {
        let media_id = e.target.getAttribute("data-media-id")
        const filtered_postmedia = postMedia.filter((media) => media.id != media_id)
        const new_postmedia = filtered_postmedia.map((obj, index) => {
            return { ...obj, id: index + 1 };
        });
        setPostMedia(new_postmedia);

    }
    const submitPost = () => {
        // logger.verbose("Create Post", "PersonalWall Component", "SubmitPost Button", "POST", "For Creating Post Submit Button clicked ", "");   
        if ((!postContent || postContent == "<p><br></p>")  && postMedia.length === 0) {
            swal("Post Content is empty", "Please Add Some Content", "error");
            // logger.error("Create Post", "PersonalWall Component", "SubmitPost Button", "POST", "Post Content is empty ", postContent);   
            return
        }
        else {
            const postData = [];
            const postDataObj =
            {
                "socialEntityTypeId": "4",
                "parentSocialEntityTypeId": "1",
                "parentSocialEntityId": vendorUserWallId,
                "postContent": postContent,
                "createdByUserId": curUserId,
                "createdByUserEntityTypeId": curUserEntityId,
                "createdByUserRoleId": curUserRoleId
            }
            postData.push(postDataObj);
            const objdata = {
                "postDetails": postData,
                "mediaDetails": postMedia
            }
            // logger.verbose("Create Post", "PersonalWall Component", "SubmitPost Button", "POST", "Post data Object creation ", JSON.stringify(objdata));
            const headers = {
                'Content-Type': 'application/json;charset=utf-8',
            }
            axios.post(CreatePostAPI, objdata, {
                headers: headers
            }).then(response => {
                if (response.data.status === 200) {
                    logger.verbose("Create Post", "PersonalWall Component", "SubmitPost Button", "POST", "Post Created Successfully!", JSON.stringify(response.data.response));
                    swal('Advertisement Created Successfully!', '', 'success');
                    setPostContent("");
                    setPostMedia([]);
                    $(".ql-editor").html("");
                    window.location.reload(false);
                }
            }).catch(error => {
                logger.error("Create Post", "PersonalWall Component", "SubmitPost Button", "POST", "Post Creation Failed!", JSON.stringify(error));
                console.error('There was an error!', error);
            });
        }
    }
    return (
        <div className="container-scroller">
            <Header entity="Vendor" />
            <div className="container-fluid page-body-wrapper">
                <VendorSideBar />
                <div className="main-panel">
                    <div className="content-wrapper bg-light-theme">
                        <div className="row d-none1 mx-auto col-md-12 mx-auto ">
                            <div className="col-lg-5 col-md-12 mob-remove-padding box_2 w_95">

                                <div className="box-home box_shadow_1 mb_20">
                                    <div className="d-flex justify-content-between align-items-center px-3">
                                        <div>
                                            <a
                                                className="mb-0 text-black-50"
                                                href="#"
                                                data-bs-toggle="modal"
                                                data-bs-target="#CreatePostModal"
                                            >Post your advertisement here...</a
                                            >
                                        </div>

                                        <div>
                                            <a
                                                href="#"
                                                data-bs-toggle="modal"
                                                data-bs-target="#CreatePostModal"
                                                className="text-black-50 me-2"
                                            ><span
                                                className="mdi mdi-file-document-outline text-black-50"
                                            ></span></a>

                                        </div>
                                    </div>
                                </div>

                                <VendorPosts/>
                             
                            </div>
                        </div>
                    </div>

                    <div className="modal fade show" id="CreatePostModal">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="modal-title text-black-50" id="exampleModalLabel">
                                        Create an Advertisement
                                    </h3>
                                    <button
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="d-flex align-items-center">
                                        <img
                                            src={profilePicture}
                                            alt="User Image"
                                            className="img-fluid rounded-circle img-profile me-3"
                                        />
                                        <div>
                                            <p className="fw-bold">{curUserFullName}</p>
                                        </div>
                                    </div>
                                    <div className="cursor mt-3">
                                        <ReactQuill modules={quillModules} theme="snow" onChange={setPostContent} placeholder="what do you want to talk about?" />
                                        <div className="mt_15">
                                            <h3 className="modal-title text-black-50">Media</h3>
                                            <div className="flex-row createPostMediaPreviewDiv">
                                                {postMedia.length != 0 ? (postMedia.map((ele, index) => (
                                                    <>
                                                        <a key={index}>
                                                            {ele?.mediaType.indexOf("image") != -1 ?
                                                                <img src={ele?.previewMedia} alt="Uploaded Media" className="img-lg mb-2" /> :
                                                                <video width="320" height="240" muted controls src={ele?.previewMedia}></video>}
                                                        </a>
                                                        <a className="position-relative text-link inline-block mr_15"
                                                            onClick={removeMedia}



                                                        ><span className="ml-15" data-media-id={ele?.id} aria-hidden="true">&times;</span>
                                                        </a>
                                                    </>

                                                ))) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer text-black-50 justify-content-between">
                                    <div>
                                        <input id="post-image-file-input" className="hiddenInputFile" type="file" accept="image/*" onChange={uploadPostMedia} />
                                        <label className="text-black-50 m-0 me-3 uploadFileIcon" htmlFor="post-image-file-input" >
                                            <i className="fa-regular fa-image"></i>
                                        </label>
                                        <input id="post-video-file-input" className="hiddenInputFile" type="file" accept="video/*" onChange={uploadPostMedia} />
                                        <label className="text-black-50 m-0 me-3 uploadFileIcon" htmlFor="post-video-file-input">
                                            <i className="fa-brands fa-youtube"></i>
                                        </label>
                                    </div>
                                    <div>
                                        <a className="btn btn-primary btn-md float-end"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            id="submitPostBtn" onClick={submitPost}>Post</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>


    )
}

export default VendorWall