import React from 'react'
import { useRef, useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { API } from '../../config/APIConfig';
import axios from 'axios';
import swal from 'sweetalert';
import CAAS_LOGO from "../../assets/images/CAAS_Logo.png";
import logger from '../../utils/logger';
const CHANGEPASSWORDAPIURL = `${API.URL}api/v1/auth/changepassword`;
const ChangePassword = () => {
  const navigate = useNavigate();
  const roleId = sessionStorage.getItem("roleId");
  const userId = sessionStorage.getItem("userId");
  var userEmail = '';
  userEmail = sessionStorage.getItem("userEmail");
  const userRef = useRef();
  const errRef = useRef();

  const [currentpassword, setCurrentPassword] = useState('');
  const [newpassword, setNewPassword] = useState('');
  const [confnewpassword, setConfNewPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {
    setErrMsg('');
  }, [currentpassword, newpassword, confnewpassword])
  if (userEmail == '' || userEmail == null) {
    userEmail = sessionStorage.getItem("userName");
  }
  const checkPasswordValidation = () => {
    var flag = true;
    var obj = {
      "currentPassword": currentpassword,
      "newPassword": newpassword,
      "confirmNewPassword": confnewpassword
    }
    if (!obj.currentPassword) {
      setErrMsg('Missing Current Password');
      flag = false;
    }
    else if (!obj.newPassword) {
      setErrMsg('Missing New Password');
      flag = false;
    }
    else if (!obj.confirmNewPassword) {
      setErrMsg('Missing Confirm New Password');
      flag = false;
    }
    return flag;
  }
  
  const handleSubmit = async (e) => {
    var flag = checkPasswordValidation();
    if (flag) {
      logger.verbose("ChangePassword", "ChangePassword Component", "handleSubmit", "POST", "Changing Password for Caas Member Validation Success ", JSON.stringify(flag));
      e.preventDefault();
      if (newpassword != confnewpassword) {
        setErrMsg('Password Does Not Match');
        return false;
      }
      const changepassworddata = {
        "currentPassword": currentpassword,
        "userNewPassword": newpassword,
        "userId": userId,
        "roleId": roleId
      }
      logger.verbose("ChangePassword", "ChangePassword Component", "Change Password", "POST", "Changing Password for Caas Member ", JSON.stringify(changepassworddata));
      const headers = {
        'Content-Type': 'application/json;charset=utf-8',
      }
      axios.post(CHANGEPASSWORDAPIURL, changepassworddata, {
        headers: headers
      }).then(response => {
        if (response.data.status === 200) {
          let success = parseInt(response?.data?.response?.split("_")[1]);
          logger.verbose("ChangePassword", "ChangePassword Component", "handleSubmit", "POST", "Changing Password for Caas Member API Response success ", JSON.stringify(response?.data?.response));
          if (success)
            swal("Password Changed Successfully!", "Please verify by logging in with new credentials", "success").then(okay => { if (okay) {
              roleId == 3 ? navigate('/auth/enterprisepm/login', { replace: true })
              : roleId == 4 ? navigate('/auth/caasmember/login', { replace: true })
              :
              navigate('/', { replace: true }) } });
          else
            swal("Password Reset Failed", "Try Again", "error");
        }
      }).catch(err => {
        console.error('There was an error!', err);
        logger.error("ChangePassword", "ChangePassword Component", "handleSubmit", "POST", "Changing Password for Caas Member API Response Failed! ", JSON.stringify(err));
        if (!err?.response) {
          setErrMsg('No Server Response');
        } else if (err.response?.status === 400) {
          setErrMsg('Missing Username or Password');
        } else if (err.response?.status === 401) {
          setErrMsg('Unauthorized');
        } else {
          setErrMsg('Login Failed');
        }
        errRef.current.focus();
      });
    }
    else {
      console.log("Failed to register");
      logger.error("ChangePassword", "ChangePassword Component", "handleSubmit", "POST", "Changing Password for Caas Member Validation Failed! ", JSON.stringify(flag));
    }
  }


  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left py-4 px-3 px-sm-4">
                <div className="brand-logo font-weight-bold pb-2">
                  <div> <img src={CAAS_LOGO} className="img-responsive caas_logo"></img>
                  </div>
                </div>
                <h4 className="text-center fw-bold pb-2">Change Password</h4>
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <form className="pt-1">
                  <div className="form-group">
                    <input type="text" className="form-control form-control-sm disabled" id="username" value={userEmail} placeholder="Email Id" />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control form-control-sm"
                      id="currentpassword"
                      placeholder="Current Password"
                      ref={userRef}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      value={currentpassword}
                      required
                    />
                    {/* <input type="password" className="form-control form-control-sm" id="currentpassword" placeholder="Current Password" /> */}
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control form-control-sm"
                      id="newpassword"
                      ref={userRef}
                      placeholder="New Password"
                      onChange={(e) => setNewPassword(e.target.value)}
                      value={newpassword}
                      required
                    />
                    {/* <input type="password" className="form-control form-control-sm" id="newpassword" placeholder="New Password" /> */}
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control form-control-sm"
                      id="confirmpassowrd"
                      ref={userRef}
                      placeholder="Confirm New Password"
                      onChange={(e) => setConfNewPassword(e.target.value)}
                      value={confnewpassword}
                      required
                    />
                    {/* <input type="password" className="form-control form-control-sm" id="confirmpassowrd" placeholder="Confirm New Password" /> */}
                  </div>


                  <div className="my-2 d-flex justify-content-between align-items-center">
                    <div className="">
                      <button className="btn btn-primary btn-sm fw-bold" onClick={handleSubmit}>Save</button>
                    </div>
                    {
                      roleId == 3 ? <Link to="/enterprise/manager/dashboard">
                         <a href="#"  className="btn btn-primary fw-bold btn-sm"><i class="mdi mdi-arrow-left" aria-hidden="true"></i>Back to Dashboard</a>
                      </Link>
                        : roleId == 4 ? <Link to="/cxo/cxo/wall/personal">
                          <a href="#"  className="btn btn-primary fw-bold btn-sm"><i class="mdi mdi-arrow-left" aria-hidden="true"></i> Back to PersonalWall</a>
                        </Link>
                          : <a href="#" className="auth-link text-black fw-bold">Back to Dashboard</a>

                    }

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
}

export default ChangePassword
