import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom'
import { RFPListContext } from './contexts/RFPListContext';
import { useTranslation } from "react-i18next";

const OpenRFPEnterprise = (props) => {  
    const { i18n, t } = useTranslation(["rfp"]);

    const { curRFPList, setCurRFPList } = useContext(RFPListContext);
    const [openRFPList, SetOpenRFPList] = useState([]);
    var enterpriseId = props.enterpriseId;
    useEffect(() => {
        getOpenRFPList();
    }, [curRFPList])
    const getOpenRFPList = () => {
        var data = curRFPList.filter((ele) => ele.enterpriseId == enterpriseId && ele.rfpStatusId == '1' && ele.rfpStatus == 'Open');
        SetOpenRFPList([...data]);
    }
    return (
        <>
            {
                openRFPList.length != 0 ? (
                    openRFPList.map((ele) => (
                        <>
                            <div className="grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="mt-0 text-left box-shadow p-4 cardPad card-body border border-radius">
                                            <div className="d-flex justify-content-between align-items-start RFP_Details">
                                                <div className="list-item-details">
                                                    <div className="d-table">
                                                        <div className="d-table-cell pe-5">{t('title')}</div>
                                                        <div className="d-table-cell text-orange">{ele?.rfpTitle}</div>
                                                    </div>
                                                    <div className="d-table">
                                                        <div className="d-table-cell pe-5">{t('postedDate')}</div>
                                                        <div className="d-table-cell">{ele?.postedDate}</div>
                                                    </div>
                                                    <div className="d-table">
                                                        <div className="d-table-cell pe-5">{t('proposalDueDate')}</div>
                                                        <div className="d-table-cell">{ele?.lastDateForResponse}</div>
                                                    </div>
                                                    <div className="d-table">
                                                        <div className="d-table-cell pe-5">{t('statusRFP')}</div>
                                                        <div className="d-table-cell text-green">
                                                            <Link to={`/enterprise/manager/rfp/view/${ele.rfpId}`} state={{ "passrfpId": `${ele.rfpId}` }} className="edit-profile btn-orange quote-btn">{t(`${ele.rfpStatus}`)}</Link>
                                                            {/* <Link to={`/enterprise/manager/rfp/view/${ele.rfpId}`} className="edit-profile btn-orange quote-btn">{ele.rfpStatus}</Link> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <Link to={`/enterprise/manager/quote/viewReceived/${ele?.rfpId}`} className="edit-profile btn-orange quote-btn">{t('quoteReceived')} - {ele.quotesReceived}</Link>
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))
                ) : null
            }
        </>
    )
}
export default OpenRFPEnterprise