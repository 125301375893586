import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import counterAnimation from '../../../../utils/counterAnimation';
const TotalVendors = (props) => {
    const { i18n, t } = useTranslation(["dashboard"]);
    const { count } = props;
    useEffect(() => {
        let ele = document.getElementById("totalVendorCount");
        counterAnimation(ele, count);
    }, [])
    return (
        <div className="col-sm-6 col-md-4 col-lg-3 grid-margin">
            <div className="card bg-theme d-flex align-items-start border">
                <div className="card-body w-100">
                    <div className="category-icon mb-4">
                        {/* <span className="bg-gray fs-4 fw-bold text-primary">
                            {props.data ? props.data : 0}
                        </span> */}
                        <span className="bg-gray fs-4 fw-bold text-primary num" id="totalVendorCount">
                            0
                        </span>
                    </div>
                    <h6 className="text-gray fs-4 d-flex justify-content-center w-100"><span className="text-gray fs-4">Total Vendors</span></h6>
                    <div className="text-center">
                        <Link to="/caas/global/vendor/manage" className="mt-2 text-gray btn btn-sm btn-primary" href="#" role="button">{t('viewList')}</Link>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default TotalVendors
