import React from 'react'
import Header from '../../../commons/components/Header'
import Footer from '../../../commons/components/Footer'
import CXOSideBar from '../../../commons/components/SideBars/CXOSideBar'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import OpenRFPCXO from './OpenRFPCXO'
import ClosedRFPCXO from './ClosedRFPCXO'
import ExpiredRFPCXO from './ExpiredRFPCXO'
import WithdrawnRFPCXO from './WithdrawnRFPCXO'
import { RFPListCIOContext } from './contexts/RFPListCIOContext'
import { API } from '../../../config/APIConfig';
import axios from 'axios'
import { useTranslation } from "react-i18next";

const PUBLISHEDRFPDRAFTLIST = `${API.URL}api/v1/rfp/publishedrfp/draft/list`; // HAS TO BE CHANGED 

const MyRFPs = () => {
    const { i18n, t } = useTranslation(["rfp"]);
    const [statusFilter, setStatusFilter] = useState("all");
    const [PublishedRFPDraftList, setPublishedDraftRFPList] = useState([]);
    const [filteredRFPlist, setFilteredRFPList] = useState([]);
    //const enterpriseId = 0; //HAS TO BE REMOVED
    const userId = sessionStorage.getItem("userId"); //userId from login context
    const changeStatusFilter = (e) => {
        let value = document.getElementById("rfpStatusFilter").value;
        setStatusFilter(value);
    }
    useEffect(() => {
        getPublishedRFPDraftDetails();
        var highlightele = document.getElementById("MyRFPSidNav");
        highlightele.classList.add("active");
    }, []);
    const getPublishedRFPDraftDetails = () => {
        // logger.verbose("RFP Details", "MyRFPs Component", "getPublishedRFPDraftDetails", "GET", "Get RFP Details API Hit", `enterpriseId=${enterpriseId}&userId=${userId}`);
        axios.get(`${PUBLISHEDRFPDRAFTLIST}?userId=${userId}`).then(response => {
            if (response.data.status === 200) {
                setPublishedDraftRFPList(response.data.response);
                setFilteredRFPList(response.data.response);
                // logger.verbose("RFP Details", "MyRFPs Component", "getPublishedRFPDraftDetails", "GET", "Get RFP Details API Response Successfull", JSON.stringify(response.data.response));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            // logger.error("RFP Details", "MyRFPs Component", "getPublishedRFPDraftDetails", "GET", "Get RFP Details API Response Successfull", JSON.stringify(error));
        });
    }
    useEffect(() => {
        if (statusFilter == "all") {
            setFilteredRFPList(PublishedRFPDraftList);
        } else {
            const rfpfiltered = PublishedRFPDraftList.filter((rfpfilter) => rfpfilter.rfpStatus === statusFilter);
            setFilteredRFPList(rfpfiltered);
        }

    }, [statusFilter])

    return (
        <div className="container-scroller">
            <Header entity="CXO" />
            <div className="container-fluid page-body-wrapper">
                <CXOSideBar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">
                                        {t('myrfps')}
                                        {/* <Link to="/cxo/cxo/dashboard" className="text-blue fs-5 float-sm-right"><span className=" mdi mdi-arrow-left"></span>{t('backToDashboard')}</Link> */}
                                    </h4>
                                    <div className="d-flex mb-0 text-muted">
                                        <span className="mdi mdi-filter"></span>
                                        <a className="filter_by" data-toggle="modal" data-bs-toggle="modal" data-bs-target="#statusfilter">{t('filterByAction')}</a>
                                    </div>
                                </div>
                                {/* <RFPListCIOContext.Provider value={{ RFPList, setRFPList }}> */}
                                <div className="col-md-12 mx-auto">
                                    {
                                        filteredRFPlist.length != 0 ? (
                                            filteredRFPlist.map((ele) => (
                                                <>
                                                    <div className="grid-margin stretch-card">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="mt-0 text-left box-shadow p-4 cardPad card-body border border-radius">
                                                                    <div className="d-flex justify-content-between align-items-start RFP_Details">
                                                                        <div className="list-item-details">
                                                                            <div className="d-table">
                                                                                <div className="d-table-cell pe-5">{t('title')}</div>
                                                                                <div className="d-table-cell text-orange">{ele?.rfpTitle}</div>
                                                                            </div>
                                                                            <div className="d-table">
                                                                                <div className="d-table-cell pe-5">{t('postedDate')}</div>
                                                                                <div className="d-table-cell">{ele?.postedDate}</div>
                                                                            </div>
                                                                            <div className="d-table">
                                                                                <div className="d-table-cell pe-5">{t('proposalDueDate')}</div>
                                                                                <div className="d-table-cell">{ele?.lastDateForResponse}</div>
                                                                            </div>
                                                                            <div className="d-table">
                                                                                <div className="d-table-cell pe-5">{t('statusRFP')}</div>
                                                                                <div className="d-table-cell text-green">
                                                                                    <Link to={`/cxo/cxo/rfp/view/${ele.rfpId}`} className="edit-profile btn-orange quote-btn">{t(`${ele.rfpStatus}`)}</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <Link to={`/cxo/cxo/rfp/quote/${ele.rfpId}`} className="edit-profile btn-orange quote-btn">{t('rfpQuote')}</Link>
                                                                    </div>
                                                                    <div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                        ) : null
                                    }
                                </div>
                                {/* </RFPListCIOContext.Provider> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="statusfilter" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title" id="exampleModalLabel">{t('hfilter')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">{t('filterByStatus')}</label>
                                <div className="col-sm-8">
                                    <select className="form-select" id="rfpStatusFilter">
                                        <option value="all">{t('All')}</option>
                                        <option value="Open">{t('Open')}</option>
                                        <option value="Closed">{t('Closed')}</option>
                                        <option value="Expired">{t('Expired')}</option>
                                        <option value="Withdrawn">{t('Withdrawn')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-warning btn-md next-btn" data-bs-dismiss="modal" onClick={changeStatusFilter}>{t('apply')}</a>
                            <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default MyRFPs