import './App.css';
import { BrowserRouter } from 'react-router-dom';
import ViewBuilder from './ViewBuilder';
import "./libs/i18n.js"
import { Suspense } from 'react';
function App() {
  return (
    <Suspense fallback={null}>
      <div className="App">
        <BrowserRouter>
          <ViewBuilder />
        </BrowserRouter>
      </div>
    </Suspense >
  );
}

export default App;
