import React from 'react'
import profilepic from "../../../assets/images/noav.jpg"
import newprofilepic from "../../../assets/images/company1.jpg"
import projectAnnouncementLogo from "../../../assets/images/projectAnnouncement.jpeg"
import CertificationLogo from "../../../assets/images/certificate.png"
import { Link } from 'react-router-dom'

const CompanyAnnouncements = () => {
    return (
        <div className="box-home box_shadow_1 main-right icon-box mt-4 px-4 announcements-container">
            <div className="d-flex align-items-center justify-content-between">
                <h3 className="fw-bold fs-4 mb-0 f_18">Announcements</h3>
                <div>
                    <i className="fa-solid fa-circle-info" title="You can view major announcements here"></i>
                </div>
            </div>
            <div className='companyannouncements1 mt-4'>
            <div
                className="d-flex justify-content-start align-items-start"
            >
                <img
                    src={newprofilepic}
                    alt="Enterprise Logo"
                    className="img-fluid img-jobs"
                />
                <div className="ps-3">
                    <Link to="/cxo/cxo/enterprisenetwork"><p className="mb-0 fw-bold text-black">New Enterprises</p>  </Link>
                    <p className="mb-0 text-black-50 fs-6">Latest</p>
                </div>
            </div>
            <div className="d-flex justify-content-start align-items-center mt-4 pt-2">
                <img
                    src={CertificationLogo}
                    alt="Project Logo"
                    className="img-fluid img-jobs"
                />
                <div className="ps-3">
                    <p className="mb-0 fw-bold f_14">Certifications In-Demand</p>
                    <p className="mb-0 text-black-50 fs-6">
                    Coming soon...
                    </p>
                </div>
            </div>
            <div
                className="d-flex justify-content-start align-items-center mt-4"
            >
                <img
                    src={projectAnnouncementLogo}
                    alt="Project Logo"
                    className="img-fluid img-jobs"
                />
                <div className="ps-3">

                    <a href="#"><p className="mb-0 fw-bold text-black">MarketPlace</p>  </a>
                    <p className="mb-0 text-black-50 fs-6">Coming soon...</p>
                </div>
            </div>
            </div>
        </div>
    )
}
export default CompanyAnnouncements