import React, { useState, useEffect, useRef } from 'react'
import okImg from "../../../assets/images/okk.jpg"
import Footer from '../../../commons/components/Footer';
import companyRegIcon from "../../../assets/images/company_reg.jpg"
import AuthHeader from '../AuthHeader';
import { useTranslation } from "react-i18next";
import $ from "jquery";
import { API } from '../../../config/APIConfig';
import axios from 'axios';
import swal from 'sweetalert';
import { getCountryStateCityList, getEnterpriseTypeList, getgovissuedUniqueIdentifierDetailsList } from '../../../utils/meta';
import { useLocation, useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import Emailer from '../../../utils/Emailer';
import { S3Config } from '../../../config/AWSConfig';
import S3FileUpload from '../../../utils/S3FileUpload';
import logger from '../../../utils/logger';
import uuidCustom from '../../../utils/uuidCustom';
const EnterpriseRegistrationAPI = `${API.URL}api/v1/enterprise/create`;
const EnterpriseNameCheckAPI = `${API.URL}api/v1/enterprise/validate/enterpriseName`;
const EnterpriseRegistration = () => {
  const emailform = useRef();
  const navigate = useNavigate();
  const [enterpriseInfo, setEnterpriseInfo] = useState({});
  const [statesMeta, setStatesMeta] = useState([]);
  const [citiesMeta, setCitiesMeta] = useState([]);
  const [countriesMeta, setCountriesMeta] = useState([]);
  const [curCityList, setCurCityList] = useState([]);
  const [curStatesMeta, setCurStatesMeta] = useState([]);
  const [enterpriseTypesMeta, setEnterpriseTypesMeta] = useState([]);
  const [enterpriseLogo, setEnterpriseLogo] = useState();
  const [enterpriseExists, setEnterpriseExists] = useState(false);
  const [govissueduniqueIdentifierTypesMeta, setGovIssuedUniqueIdentifierTypesMeta] = useState([]);
  const [countryphonecodeMeta, setCountryPhoneCodeMeta] = useState([]);
  const [curCountryphonecodeMeta, setCurCountryPhoneCodeMeta] = useState([]);
  const { i18n, t } = useTranslation(["registration"]);
  const location = useLocation();
  var type_user = location.state?.userType;
  //added
  // const type_user = useLocation().state.userType
  // console.log(type_user)
  const pathLink = type_user === "caas" ? "/caas/enterprise" : "/auth/enterprisepm/login"
  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value);
  };
  useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18n.changeLanguage("en");
    }
    bindDropDownData();
    bindHandlers();
  }, []);
  const bindDropDownData = () => {
    getCountryStateCityList()
      .then((res) => {
        if (res.response) {
          setCountriesMeta(res.response.countryList);
          setStatesMeta(res.response.stateList);
          setCurStatesMeta(res.response.stateList);
          setCitiesMeta(res.response.cityList);
          setCurCityList(res.response.cityList);
          setCountryPhoneCodeMeta(res.response.phoneCodeList);
          setCurCountryPhoneCodeMeta(res.response.phoneCodeList);
        }
      })
      .catch((e) => {
        console.log(e.message)
      })
    getEnterpriseTypeList()
      .then((data) => {
        if (data.response) {
          setEnterpriseTypesMeta(data.response);
        }
      })
      .catch((e) => {
        console.log(e.message)
      })
    getgovissuedUniqueIdentifierDetailsList()
      .then((data) => {
        if (data.response) {
          setGovIssuedUniqueIdentifierTypesMeta(data.response);
        }
      })
      .catch((e) => {
        console.log(e.message)
      })
  }
  const fileUpload = async (e) => {
    const file = e.target.files[0];
    // const fileName = new Date().toISOString() + "|" +  e.target.files[0].name;
    var fileName = e.target.files[0].name;
    var fileExtension =fileName.split('.').pop();  
    fileName +=  S3Config.S3ObjectDelimiter + uuidCustom() +"."+ fileExtension;
    const contentType = file.type;
    const formData = { file, fileName, contentType };
    let result = await S3FileUpload(formData).then(
      (response) => {
        if (response.status === 200) {
          return { status: 200, s3FileUrl: S3Config.S3ObjectBaseURL + fileName }
        }
      }
    ).catch(err => console.log(err));
    return result;
  }
  function enterpriseValidationCheck(enterprisename) {
    // enterprisename = JSON.stringify(enterprisename).replaceAll('"', '')
    logger.verbose("Enterprise name Validation", "EnterpriseRegistration Component", "enterpriseValidationCheck", "GET", "Enterprise name Validation Check", `enterpriseName=${enterprisename}`);
    axios.get(`${EnterpriseNameCheckAPI}?enterpriseName=${enterprisename}`)
      .then(response => {
        if (response.data === true) {
          $("#enterpriseName").addClass("error-shadow");
          $("#enterprisenameerror").hide()
          $("#existingEnterprise").show();

          setEnterpriseExists(true);

          logger.verbose("Enterprise name Validation", "EnterpriseRegistration Component", "enterpriseValidationCheck", "GET", "Enterprise name Validation Check API True successfull", JSON.stringify(response.data));
          return response.data
        }
        else {
          if ($("#enterpriseName").hasClass("error-shadow"))
            $("#enterpriseName").removeClass("error-shadow");
          $("#existingEnterprise").hide();
          setEnterpriseExists(false)
          return response.data
        }

      })

      .catch(error => {
        console.error('There was an error!', error);
        logger.error("Enterprise name Validation", "EnterpriseRegistration Component", "enterpriseValidationCheck", "GET", "Enterprise name Validation Check API Failed", JSON.stringify(error));
      });

  }
  const handleInput = (e) => {
    var id = e.target.id;
    // if (id === "stateId")
    //   resetCityList(e);
    if (id === "enterpriseName") {
      var enterpriseValue = e.target.value.replace(new RegExp(/[^a-zA-Z0-9_\s]*$/, 'ig'), "");
      e.target.value = enterpriseValue;
      enterpriseValidationCheck(e.target.value);
    }
    if (id === "enterpriseLogo") {
      const [img] = e.target.files;
      setEnterpriseLogo(URL.createObjectURL(img));
      fileUpload(e).then(
        (response) => {
          value = response.s3FileUrl;
          setEnterpriseInfo({ ...enterpriseInfo, [id]: value });
        }).catch(err => console.log(err));
    }
    if (id === "stateId") {
      resetCityList(e);
    }
    if (id === "country") {
      resetStateList(e);     
      resetCountryCodeList(e)
    }
    if (id === "governmentIssuedUniqueIdentifierTypeId") {
      if (e.target.value != 0) {
        $("#governmentIssuedUniqueIdentifierNumber").prop('disabled', false);
        var identifierValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
        e.target.value = identifierValue;
      }
      else {
        $('#governmentIssuedUniqueIdentifierNumber').val('');
        $("#governmentIssuedUniqueIdentifierNumber").prop('disabled', true);
      }
    }
    if (id === "phoneNumber") {
      var phoneNumberValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
      e.target.value = phoneNumberValue;
    }
    if (id === "keyContact") {
      var keyContactValue = e.target.value.replace(new RegExp(/[^a-zA-Z0-9_\s]*$/, 'ig'), "");
      e.target.value = keyContactValue;
    }
    if (id === "zipCode") {
      var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
      e.target.value = newValue;
    }
    // if (id === "ssnNumber") {
    //   var ssnNumberValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
    //   e.target.value = ssnNumberValue;
    // }
    if (id === "natureOfBusiness") {
      var natureOfBusinessValue = e.target.value.replace(new RegExp(/[^a-zA-Z0-9_\s]*$/, 'ig'), "");
      e.target.value = natureOfBusinessValue;
    }

    var value = e.target.value;
    setEnterpriseInfo({ ...enterpriseInfo, [id]: value });
    // console.log(enterpriseInfo);
    checkValidation(e.target.id);
  }

  const checkValidation = (id) => {
    if (id === "" || id === undefined) {
      return allcheckValidation()
    }
    else {
      return singlecheckValidation(id)
    }
  }
  const singlecheckValidation = (id) => {
    var flag = true;
    var single_flaglist = []

    var obj = {
      "enterpriseName": document.getElementById("enterpriseName").value,
      "phoneNumber": document.getElementById("phoneNumber").value,
      "emailAddress": document.getElementById("emailAddress").value,
      "website": document.getElementById("website").value,
      "keyContact": document.getElementById("keyContact").value,
      "keyContactEmail": document.getElementById("keyContactEmail").value,
      "cityId": document.getElementById("cityId").value,
      "stateId": document.getElementById("stateId").value,
      "country": document.getElementById("country").value,
      // "zipCode": document.getElementById("zipCode").value,
      // "ssnNumber": document.getElementById("ssnNumber").value,
      "natureOfBusiness": document.getElementById("natureOfBusiness").value,
      "enterpriseTypeId": document.getElementById("enterpriseTypeId").value,
      "address": document.getElementById("address").value,
      "countryPhoneCodeId": document.getElementById("countryPhoneCodeId").value
    }
    if (id == "enterpriseName") {
      if (!obj.enterpriseName.trim()) {
        $("#existingEnterprise").hide();
        $("#enterprisenameerror").show();
        $("#enterpriseName").addClass("error-shadow")

        flag = false;
        single_flaglist.push(flag);
        return;
      }
      else {
        flag = enterpriseExists
        if (flag === true) {
          $("#enterpriseName").addClass("error-shadow");
          flag = false
          single_flaglist.push(flag);
          return;
        }
        else {
          flag = true;
          single_flaglist.push(flag);
          if ($("#enterpriseName").hasClass('error-shadow'))
            $("#enterpriseName").removeClass('error-shadow');
        }
        $("#enterprisenameerror").hide();
      }
    }
    // if (!obj.website) {
    //   $("#websiteerror").show();
    //   flag = false;
    //   return;


    // }
    // else {
    //   $("#websiteerror").hide();
    //   flag = true;    
    // }
    const websiteregex = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    if (id == "website") {
      if (!obj.website.trim()) {
        $("#websiteerror").show();
        $("#website").addClass("error-shadow");

        flag = false;
        single_flaglist.push(flag);
        return;
      }
      else if (!websiteregex.test(obj.website)) {
        $("#websiteerror").show();
        $("#websiteerror").text("Please enter a valid website");
        $("#website").addClass("error-shadow");

        flag = false;
        single_flaglist.push(flag);
        return;
      }
      else {
        $("#websiteerror").hide();
        if ($("#website").hasClass('error-shadow'))
          $("#website").removeClass('error-shadow');
        flag = true;
        single_flaglist.push(flag);
      }
    }

    const phoneregex = /^\d{12}$/;
    if (id == "phoneNumber") {
      if (!obj.phoneNumber.trim()) {
        $("#phonenumbererror").show();
        $("#phoneNumber").addClass("error-shadow");

        flag = false;
        single_flaglist.push(flag);
        return;
      }
      // else if (!phoneregex.test(obj.phoneNumber)) {
      else if ((obj.phoneNumber.length > 13)) {
        $("#phonenumbererror").show();
        $("#phonenumbererror").text("Please enter the phone number less than 13 digit");
        // $("#phonenumbererror").text("Please enter the 10 digit phone number");
        $("#phoneNumber").addClass("error-shadow");
        flag = false;
        single_flaglist.push(flag);
        return;
      }
      else {
        $("#phonenumbererror").hide();
        if ($("#phoneNumber").hasClass('error-shadow'))
          $("#phoneNumber").removeClass('error-shadow');
        flag = true;
        single_flaglist.push(flag);
      }
    }
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (id == "emailAddress") {
      if (!obj.emailAddress.trim()) {
        $("#emailerror").show();
        $("#emailAddress").addClass("error-shadow");
        flag = false;
        single_flaglist.push(flag);
        return;
      }
      else if (!regex.test(obj.emailAddress)) {
        $("#emailerror").show();
        $("#emailerror").text("Please enter a valid email address");
        $("#emailAddress").addClass("error-shadow");

        flag = false;
        single_flaglist.push(flag);
        return;
      }
      else {
        $("#emailerror").hide();
        if ($("#emailAddress").hasClass('error-shadow'))
          $("#emailAddress").removeClass('error-shadow');
        flag = true;
        single_flaglist.push(flag);
      }
    }
    if (id === "keyContact") {
      if (!obj.keyContact.trim()) {
        $("#keyContacterror").show();
        $("#keyContact").addClass("error-shadow")
        flag = false;
        single_flaglist.push(flag);
      }
      else {
        $("#keyContacterror").hide();
        if ($("#keyContact").hasClass('error-shadow'))
          $("#keyContact").removeClass('error-shadow');
        flag = true;
        single_flaglist.push(flag);
      }
    }
    //added
    if (id === "keyContactEmail") {
      if (!obj.keyContactEmail.trim()) {
        $("#keyContactEmailerror").show();
        flag = false;
        single_flaglist.push(flag);
        $("#keyContactEmail").addClass("error-shadow");
        return;
      }
      else if (!regex.test(obj.keyContactEmail)) {
        $("#keyContactEmailerror").show();
        $("#keyContactEmailerror").text("Please enter a valid email address");
        flag = false;
        single_flaglist.push(flag);
        $("#keyContactEmail").addClass("error-shadow");
        return;
      }
      else {
        $("#keyContactEmailerror").hide();
        if ($("#keyContactEmail").hasClass('error-shadow'))
          $("#keyContactEmail").removeClass('error-shadow');
        flag = true;
        single_flaglist.push(flag);
      }
    }
    if (id == "country") {
      if (obj.country == 0) {
        $("#countryerror").show();
        $("#country").addClass("error-shadow")
        flag = false;
        single_flaglist.push(flag);
        return;
      }
      else {
        $("#countryerror").hide();
        if ($("#country").hasClass('error-shadow'))
          $("#country").removeClass('error-shadow');
        flag = true;
        single_flaglist.push(flag);
      }
    }

    if (id == "stateId") {
      if (obj.stateId == 0) {
        $("#stateerror").show();
        $("#stateId").addClass("error-shadow")
        flag = false;
        single_flaglist.push(flag);
        return;
      }
      else {
        $("#stateerror").hide();
        if ($("#stateId").hasClass('error-shadow'))
          $("#stateId").removeClass('error-shadow');
        flag = true;
        single_flaglist.push(flag);
      }
    }
    if (id == "cityId") {
      if (obj.cityId == 0) {
        $("#cityerror").show();
        $("#cityId").addClass("error-shadow")
        flag = false;
        single_flaglist.push(flag);
        return;
      }
      else {
        $("#cityerror").hide();
        if ($("#cityId").hasClass('error-shadow'))
          $("#cityId").removeClass('error-shadow');
        flag = true;
        single_flaglist.push(flag);
      }
    }
    if (id == "countryPhoneCodeId") {
      if (obj.countryPhoneCodeId == 0) {
        $("#countryPhoneCodeIderror").show();
        $("#countryPhoneCodeId").addClass("error-shadow")
        flag = false;
        single_flaglist.push(flag);
        return;
      }
      else {
        $("#countryPhoneCodeIderror").hide();
        if ($("#countryPhoneCodeId").hasClass('error-shadow'))
          $("#countryPhoneCodeId").removeClass('error-shadow');

        flag = true;
        single_flaglist.push(flag);
      }
    }
    // if (id == "zipCode") {
    //   if (!obj.zipCode.trim()) {
    //     $("#zipcodeerror").show();
    //     $("#zipCode").addClass("error-shadow")
    //     flag = false;
    //     single_flaglist.push(flag);
    //     return;
    //   }
    //   else {
    //     $("#zipcodeerror").hide();
    //     if ($("#zipCode").hasClass('error-shadow'))
    //       $("#zipCode").removeClass('error-shadow');
    //     flag = true;
    //     single_flaglist.push(flag);
    //   }
    // }
    // if (!obj.ssnNumber) {
    //   $("#ssnNoerror").show();
    //   flag = false;
    // }
    // else {
    //   $("#ssnNoerror").hide();
    //   flag = true;
    // }
    if (id == "natureOfBusiness") {
      if (!obj.natureOfBusiness.trim()) {
        $("#natureOfBusinesserror").show();
        $("#natureOfBusiness").addClass("error-shadow")
        flag = false;
        single_flaglist.push(flag);
        return;
      }
      else {
        $("#natureOfBusinesserror").hide();
        if ($("#natureOfBusiness").hasClass('error-shadow'))
          $("#natureOfBusiness").removeClass('error-shadow');
        flag = true;
        single_flaglist.push(flag);
      }
    }
    if (id == "enterpriseTypeId") {
      if (obj.enterpriseTypeId == 0) {
        $("#enterpriseTypeerror").show();
        $("#enterpriseTypeId").addClass("error-shadow")
        flag = false;
        single_flaglist.push(flag);
        return;
      }
      else {
        $("#enterpriseTypeerror").hide();
        if ($("#enterpriseTypeId").hasClass('error-shadow'))
          $("#enterpriseTypeId").removeClass('error-shadow');
        flag = true;
        single_flaglist.push(flag);
      }
    }
    if (id == "address") {
      if (!obj.address.trim()) {
        $("#addresserror").show();
        $("#address").addClass("error-shadow")
        flag = false;
        single_flaglist.push(flag);
        return;
      }
      else {
        flag = true;
        single_flaglist.push(flag);
        if ($("#address").hasClass('error-shadow'))
          $("#address").removeClass('error-shadow');
        $("#addresserror").hide();
      }
    }
    return single_flaglist;
  }

  const allcheckValidation = () => {
    var flag = true;
    var flaglist = []
    var obj = {
      "enterpriseName": document.getElementById("enterpriseName").value,
      "phoneNumber": document.getElementById("phoneNumber").value,
      "emailAddress": document.getElementById("emailAddress").value,
      "website": document.getElementById("website").value,
      "keyContact": document.getElementById("keyContact").value,
      "keyContactEmail": document.getElementById("keyContactEmail").value,
      "cityId": document.getElementById("cityId").value,
      "stateId": document.getElementById("stateId").value,
      "country": document.getElementById("country").value,
      // "zipCode": document.getElementById("zipCode").value,
      // "ssnNumber": document.getElementById("ssnNumber").value,
      "natureOfBusiness": document.getElementById("natureOfBusiness").value,
      "enterpriseTypeId": document.getElementById("enterpriseTypeId").value,
      "address": document.getElementById("address").value,
      "countryPhoneCodeId": document.getElementById("countryPhoneCodeId").value
    }
    if (!obj.enterpriseName.trim()) {
      $("#existingEnterprise").hide();
      $("#enterprisenameerror").show();
      $("#enterpriseName").addClass("error-shadow")

      flag = false;
      flaglist.push(flag);
    }
    else {
      flag = enterpriseExists
      if (flag === true) {
        $("#enterpriseName").addClass("error-shadow");
        flag = false
        flaglist.push(flag);
      }
      else {
        flag = true;
        flaglist.push(flag);
        if ($("#enterpriseName").hasClass('error-shadow'))
          $("#enterpriseName").removeClass('error-shadow');
      }
      $("#enterprisenameerror").hide();
    }
    // if (!obj.website) {
    //   $("#websiteerror").show();
    //   flag = false;
    //   return;


    // }
    // else {
    //   $("#websiteerror").hide();
    //   flag = true;    
    // }
    const websiteregex = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    if (!obj.website.trim()) {
      $("#websiteerror").show();
      $("#website").addClass("error-shadow");
      flag = false;
      flaglist.push(flag);
    }
    else if (!websiteregex.test(obj.website)) {
      $("#websiteerror").show();
      $("#websiteerror").text("Please enter a valid website");
      $("#website").addClass("error-shadow");
      flag = false;
      flaglist.push(flag);
    }
    else {
      $("#websiteerror").hide();
      if ($("#website").hasClass('error-shadow'))
        $("#website").removeClass('error-shadow');
      flag = true;
      flaglist.push(flag);
    }

    const phoneregex = /^\d{12}$/;
    if (!obj.phoneNumber.trim()) {
      $("#phonenumbererror").show();
      $("#phoneNumber").addClass("error-shadow");
      flag = false;
      flaglist.push(flag);
    }
    // else if (!phoneregex.test(obj.phoneNumber)) {
    else if ((obj.phoneNumber.length > 13)) {
      $("#phonenumbererror").show();
      $("#phonenumbererror").text("Please enter the phone number less than 13 digit");
      $("#phoneNumber").addClass("error-shadow");
      flag = false;
      flaglist.push(flag);
    }
    else {
      $("#phonenumbererror").hide();
      if ($("#phoneNumber").hasClass('error-shadow'))
        $("#phoneNumber").removeClass('error-shadow');
      flag = true;
      flaglist.push(flag);
    }
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!obj.emailAddress.trim()) {
      $("#emailerror").show();
      $("#emailAddress").addClass("error-shadow");
      flag = false;
      flaglist.push(flag);
    }
    else if (!regex.test(obj.emailAddress)) {
      $("#emailerror").show();
      $("#emailerror").text("Please enter a valid email address");
      $("#emailAddress").addClass("error-shadow");
      flag = false;
      flaglist.push(flag);
    }
    else {
      $("#emailerror").hide();
      if ($("#emailAddress").hasClass('error-shadow'))
        $("#emailAddress").removeClass('error-shadow');
      flag = true;
      flaglist.push(flag);
    }
    if (!obj.keyContact.trim()) {
      $("#keyContacterror").show();
      $("#keyContact").addClass("error-shadow")
      flag = false;
      flaglist.push(flag);
    }
    else {
      $("#keyContacterror").hide();
      if ($("#keyContact").hasClass('error-shadow'))
        $("#keyContact").removeClass('error-shadow');
      flag = true;
      flaglist.push(flag);
    }
    //added
    if (!obj.keyContactEmail.trim()) {
      $("#keyContactEmailerror").show();
      flag = false;
      $("#keyContactEmail").addClass("error-shadow");
      flaglist.push(flag);
    }
    else if (!regex.test(obj.keyContactEmail)) {
      $("#keyContactEmailerror").show();
      $("#keyContactEmailerror").text("Please enter a valid email address");
      flag = false;
      $("#keyContactEmail").addClass("error-shadow");
      flaglist.push(flag);
    }
    else {
      $("#keyContactEmailerror").hide();
      if ($("#keyContactEmail").hasClass('error-shadow'))
        $("#keyContactEmail").removeClass('error-shadow');
      flag = true;
      flaglist.push(flag);
    }

    if (obj.country == 0) {
      $("#countryerror").show();
      $("#country").addClass("error-shadow")
      flag = false;
      flaglist.push(flag);
     
    }
    else {
      $("#countryerror").hide();
      if ($("#country").hasClass('error-shadow'))
        $("#country").removeClass('error-shadow');
      flag = true;
      flaglist.push(flag);
    }

    if (obj.stateId == 0) {
      $("#stateerror").show();
      $("#stateId").addClass("error-shadow")
      flag = false;
      flaglist.push(flag);
    }
    else {
      $("#stateerror").hide();
      if ($("#stateId").hasClass('error-shadow'))
        $("#stateId").removeClass('error-shadow');
      flag = true;
      flaglist.push(flag);
    }
    if (obj.cityId == 0) {
      $("#cityerror").show();
      $("#cityId").addClass("error-shadow")
      flag = false;
      flaglist.push(flag);
    }
    else {
      $("#cityerror").hide();
      if ($("#cityId").hasClass('error-shadow'))
        $("#cityId").removeClass('error-shadow');
      flag = true;
      flaglist.push(flag);
    }
    // if (!obj.zipCode.trim()) {
    //   $("#zipcodeerror").show();
    //   $("#zipCode").addClass("error-shadow")
    //   flag = false;
    //   flaglist.push(flag);
    // }
    // else {
    //   $("#zipcodeerror").hide();
    //   if ($("#zipCode").hasClass('error-shadow'))
    //     $("#zipCode").removeClass('error-shadow');
    //   flag = true;
    //   flaglist.push(flag);
    // }
    // if (!obj.ssnNumber) {
    //   $("#ssnNoerror").show();
    //   flag = false;
    // }
    // else {
    //   $("#ssnNoerror").hide();
    //   flag = true;
    // }
    if (!obj.natureOfBusiness.trim()) {
      $("#natureOfBusinesserror").show();
      $("#natureOfBusiness").addClass("error-shadow")
      flag = false;
      flaglist.push(flag);
    }
    else {
      $("#natureOfBusinesserror").hide();
      if ($("#natureOfBusiness").hasClass('error-shadow'))
        $("#natureOfBusiness").removeClass('error-shadow');
      flag = true;
      flaglist.push(flag);
    }
    if (obj.enterpriseTypeId == 0) {
      $("#enterpriseTypeerror").show();
      $("#enterpriseTypeId").addClass("error-shadow")
      flag = false;
      flaglist.push(flag);
    }
    else {
      $("#enterpriseTypeerror").hide();
      if ($("#enterpriseTypeId").hasClass('error-shadow'))
        $("#enterpriseTypeId").removeClass('error-shadow');
      flag = true;
      flaglist.push(flag);
    }
    if (!obj.address.trim()) {
      $("#addresserror").show();
      $("#address").addClass("error-shadow")
      flag = false;
      flaglist.push(flag);
    }
    else {
      flag = true;
      flaglist.push(flag);
      if ($("#address").hasClass('error-shadow'))
        $("#address").removeClass('error-shadow');
      $("#addresserror").hide();
    }

    if (obj.countryPhoneCodeId == 0) {
      $("#countryPhoneCodeIderror").show();
      $("#countryPhoneCodeId").addClass("error-shadow")
      flag = false;
      flaglist.push(flag);     
    }
    else {
      $("#countryPhoneCodeIderror").hide();
      if ($("#countryPhoneCodeId").hasClass('error-shadow'))
        $("#countryPhoneCodeId").removeClass('error-shadow');
      flag = true;
      flaglist.push(flag);

    }
    return flaglist;
  }
  const sendMail = () => {
    Emailer(emailform.current);
  }
  const registerEnterprise = () => {
    // console.log(enterpriseInfo);
    $(".enterpriseregbtn").addClass("disabled");
    // var flag=true;
    var flaglist = checkValidation();
    if (flaglist.includes(false))
      var flag = false;
    else
      var flag = true;


    if (flag) {
      enterpriseInfo.zipCode = enterpriseInfo.zipCode ? enterpriseInfo.zipCode : "";
      enterpriseInfo.governmentIssuedUniqueIdentifierNumber = enterpriseInfo.governmentIssuedUniqueIdentifierNumber ? enterpriseInfo.governmentIssuedUniqueIdentifierNumber : "";
      enterpriseInfo.countryPhoneCodeId=enterpriseInfo.countryPhoneCodeId?enterpriseInfo.countryPhoneCodeId:document.getElementById("countryPhoneCodeId").value;
      enterpriseInfo.stateId=enterpriseInfo.stateId?enterpriseInfo.stateId:document.getElementById("stateId").value;
      enterpriseInfo.cityId=enterpriseInfo.cityId?enterpriseInfo.cityId:document.getElementById("cityId").value;
      // enterpriseInfo.ssnNumber = enterpriseInfo.ssnNumber? enterpriseInfo.ssnNumber:"";
      logger.verbose("Enterprise Registration", "EnterpriseRegistration Component", "registerEnterprise", "POST", "Enterprise Registration Validation successfull", JSON.stringify(flag));
      if (Object.keys(enterpriseInfo).length !== 0) {
        logger.verbose("Enterprise Registration", "EnterpriseRegistration Component", "registerEnterprise", "POST", "Enterprise Registration Object created for API", JSON.stringify(enterpriseInfo));
        console.log(JSON.stringify(enterpriseInfo));
        axios.post(EnterpriseRegistrationAPI, enterpriseInfo)
          .then(response => {
            // console.log(response);
            if (response.data.status === 200) {
              // sendMail();
              // $("#EditEnterprise").show();
              swal(t('thankYouForRegistering'), t('pleaseConfirmYourRegistrationByVerifyingTheEmail'), "success", { closeOnClickOutside: false, closeOnEsc: false }
              ).then(okay => {
                if (okay) {
                  navigate(pathLink, { replace: true })
                }
              }
              );
              logger.verbose("Enterprise Registration", "EnterpriseRegistration Component", "registerEnterprise", "POST", "Enterprise Registration API response successfull", JSON.stringify(response.data));
            }
          })
          .catch(error => {
            console.error('There was an error!', error);
            logger.error("Enterprise Registration", "EnterpriseRegistration Component", "registerEnterprise", "POST", "Enterprise Registration API response Failed", JSON.stringify(error));
          });
      }
    }
    else {
      $(".enterpriseregbtn").removeClass("disabled");
      // console.log("Failed to register");
      swal("Please fill the Details", "", "error", { closeOnClickOutside: false, closeOnEsc: false });
      logger.verbose("Enterprise Registration", "EnterpriseRegistration Component", "registerEnterprise", "POST", "Enterprise Registration Validation Failed", JSON.stringify(flag));
    }
  }
  useEffect(() => {
    const stateId = $("#stateId").val();
    var newCityList = citiesMeta.filter((ele) => ele.stateId == stateId);
    setCurCityList([...newCityList]);
  }, [curStatesMeta])
  const resetStateList = (e) => {
    var id = e.target.value;
    var newStateList = statesMeta.filter((ele) => ele.countryId == id);
    setCurStatesMeta([...newStateList]);
  }
  const resetCityList = (e) => {
    var id = e.target.value;
    var newCityList = citiesMeta.filter((ele) => ele.stateId == id);
    setCurCityList([...newCityList]);
  }
  const resetCountryCodeList = (e) => {
    var id = e.target.value;
    if (id == "0") {
      setCurCountryPhoneCodeMeta([...countryphonecodeMeta]);
      return;
    }
    var newCodeList = countryphonecodeMeta.filter((ele) => ele.countryId == id);
    setCurCountryPhoneCodeMeta([...newCodeList]);
    // $("#countryPhoneCodeId").val(newCodeList[0].countryPhoneCodeId);
  }
  useEffect(() => {
    if (curCountryphonecodeMeta.length == 1) {
      $("#countryPhoneCodeId").val(curCountryphonecodeMeta[0]?.countryPhoneCodeId);
      singlecheckValidation("countryPhoneCodeId");

    }
    else {
      $("#countryPhoneCodeId").val(0);
    }

  }, [curCountryphonecodeMeta])
  const bindHandlers = () => {
    $("#showhide_pi").removeClass("hide");
    // $(".addcompany").on("click", function () {
    //   $("#showhide_pi").removeClass("hide");
    //   $("#dispshow").addClass("hide");
    //   $("#addpi_btn").addClass("hide");
    // });
  }
  return (
    <div className="sidebar-icon-only with-welcome-text" data-new-gr-c-s-check-loaded="14.1071.0" data-gr-ext-installed="">
      <div className="container-scroller">
        <AuthHeader />
      </div>
      <div className="container-fluid page-body-wrapper">
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
          <ul className="nav">
            <li className="nav-item title-card" id="RFP_reg">
              <a className="nav-link text-center pl_2 br-btm" href="#">
                <span className="menu-title2 sidebar-title main-title fw-bold">{t('hregistration')}</span>
              </a>
            </li>
            <li className="nav-item active">
              <a className="nav-link" href="#">
                <i className="mdi mdi-view-dashboard menu-icon"></i>
                <span className="menu-title1 sidebar-title">{t('enterpriseRegistration')}</span>
              </a>
            </li>
            {/* <li className="nav-item ">
              <Link to="/cxo/cxo/register" className="nav-link">
                <i className="mdi mdi-account-convert menu-icon"></i>
                <span className="menu-title2 sidebar-title"> Member {t('registration')}</span>
              </Link>
            </li>
            <li className="nav-item ">
              <Link to="/vendor/register" className="nav-link">
                <i className="mdi mdi-account-convert menu-icon"></i>
                <span className="menu-title2 sidebar-title"> Vendor {t('registration')}</span>
              </Link>
            </li> */}
            {/* <li className="nav-item">
              <a className="nav-link" href="#">
                <i className="mdi mdi-account-multiple menu-icon"></i>
                <span className="menu-title3 sidebar-title">{t('partnerRegistration')}</span>
              </a>
            </li> */}
          </ul>
        </nav>
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-12 grid-margin stretch-card">
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <h4 className="card-title">
                            {t('henterpriseRegistration')}<a className="addcompany btn-primary add-pi float-right btn-rounded btn-icon hide" id="addpi_btn">
                              <i className="ti-plus"></i>
                            </a>
                          </h4>
                          <div className="hide" id="showhide_pi">
                            <div className="content clearfix">
                              <section className="body current" id="steps-uid-0-p-0" role="tabpanel" aria-labelledby="steps-uid-0-h-0" aria-hidden="false">
                                <div className="row mb-0">
                                  <div className="form-group col-12 col-sm-6">
                                    <label>{t('enterpriseName')} <span className="error">*</span></label>
                                    <input type="text" className="form-control" placeholder={t('enterpriseName')} id="enterpriseName" onChange={handleInput} />
                                    <label id="enterprisenameerror" className="error hide">Enterprise name is required</label>
                                    <label id="existingEnterprise" className="error hide">Enterprise already exists</label>
                                  </div>
                                  <div className="form-group col-12 col-sm-6">
                                    <label>{t('website')} <span className="error">*</span></label>
                                    <input type="text" className="form-control" placeholder={t('website')} id="website" onChange={handleInput} />
                                    <label id="websiteerror" className="error hide">Website is required</label>
                                  </div>
                                  <div className="form-group col-12 col-sm-6">

                                    <label>{t('uploadEnterpriseLogo')}</label>
                                    <input className="form-control" type="file" id="enterpriseLogo" onChange={handleInput} />

                                  </div>
                                  <div className="form-group col-12 col-sm-6">
                                    <label>{t('phone')} <span className="error">*</span></label>
                                    {/* <input type="number" className="form-control" placeholder={t('phone')} id="phoneNumber" min="0" maxLength="10" onChange={handleInput} /> */}
                                    {/* <input type="text" className="form-control" placeholder={t('phone')} id="phoneNumber" maxLength="10" onChange={handleInput} /> */}
                                    <div className="col-12 col-sm-12 d-flex">
                                      <select className="form-select w-20" id="countryPhoneCodeId" onChange={handleInput}>
                                        <option value="0">Code</option>
                                        {
                                          curCountryphonecodeMeta.length != 0 ? (
                                            curCountryphonecodeMeta.map((ele, index) => (
                                              <option key={index} value={ele.countryPhoneCodeId}>{ele.countryPhoneCode} {ele.countryCode}</option>
                                            ))
                                          ) : null
                                        }
                                      </select>
                                      <input type="text" className="form-control" placeholder={t('mobilePhone')} id="phoneNumber" maxLength="12" onChange={handleInput} />
                                    </div>
                                    <label id="countryPhoneCodeIderror" className="error hide">Phone Code is required</label>&nbsp;
                                    <label id="phonenumbererror" className="error hide">Phone Number is required</label>
                                  </div>
                                  <div className="form-group col-12 col-sm-6">
                                    <label>{t('email')} <span className="error">*</span></label>
                                    <input type="email" className="form-control" placeholder={t('email')} id="emailAddress" onChange={handleInput} />
                                    <label id="emailerror" className="error hide">Email Id is required</label>
                                  </div>
                                  <div className="form-group col-12 col-sm-6">
                                    <label>{t('keyContact')} <span className="error">*</span></label>
                                    <input type="text" className="form-control" placeholder={t('keyContact')} id="keyContact" onChange={handleInput} />
                                    <label id="keyContacterror" className="error hide">Key Contact is required</label>
                                  </div>
                                  <div className="form-group col-12 col-sm-6">
                                    <label>{t('keyContactEmail')} <span className="error">*</span></label>
                                    <input type="text" className="form-control" placeholder={t('keyContactEmail')} id="keyContactEmail" onChange={handleInput} />
                                    <label id="keyContactEmailerror" className="error hide">Key Contact Email is required</label>
                                  </div>
                                  <div className="form-group col-12 col-sm-6">
                                    <label>{t('natureOfBusiness')} <span className="error">*</span></label>
                                    <input type="text" className="form-control" placeholder={t('natureOfBusiness')} id="natureOfBusiness" onChange={handleInput} />
                                    <label id="natureOfBusinesserror" className="error hide">Nature of Business is required</label>
                                  </div>
                                  <div className="form-group col-12 col-sm-6">
                                    <label>{t('enterpriseType')} <span className="error">*</span></label>
                                    <select className="form-select" id="enterpriseTypeId" onChange={handleInput}>
                                      <option value="0"> -- {t('selectEnterpriseType')} -- </option>
                                      {
                                        enterpriseTypesMeta.length != 0 ? (
                                          enterpriseTypesMeta.map((ele, index) => (
                                            <option key={index} value={ele.enterpriseTypeId}>{ele.enterpriseTypeName}</option>
                                          ))
                                        ) : null
                                      }
                                    </select>
                                    <label id="enterpriseTypeerror" className="error hide">Enterprise Type is required</label>
                                  </div>
                                  <div className="form-group col-12 col-sm-6">
                                    <label>Registration Number</label>
                                    <div className="col-12 col-sm-12 d-flex">
                                      <select className="form-select w-20" id="governmentIssuedUniqueIdentifierTypeId" onChange={handleInput}>
                                        <option value="0">Type</option>
                                        {
                                          govissueduniqueIdentifierTypesMeta.length != 0 ? (
                                            govissueduniqueIdentifierTypesMeta.map((ele, index) => (
                                              <option key={index} value={ele.governmentIssuedUniqueIdentifierTypeId}>{ele.governmentIssuedUniqueIdentifierTypeName}</option>
                                            ))
                                          ) : null
                                        }
                                      </select>
                                      <input type="text" className="form-control" placeholder="Registration Number" id="governmentIssuedUniqueIdentifierNumber" maxLength="9" onChange={handleInput} disabled />
                                    </div>

                                    <label id="ssnNoerror" className="error hide">SSN is required</label>
                                  </div>
                                  <div className="form-group col-12 col-sm-6">
                                    <label>{t('country')} <span className="error">*</span></label>
                                    <select className="form-select" id="country" onChange={handleInput}>
                                      <option value="0" key={0}>{t('country')}</option>
                                      {
                                        countriesMeta.length != 0 ? (
                                          countriesMeta.map((ele, index) => (
                                            <option key={index} value={ele.countryId}>{ele.countryName}</option>
                                          ))
                                        ) : null
                                      }
                                    </select>
                                    <label id="countryerror" className="error hide">Country not selected</label>
                                  </div>
                                  <div className="form-group col-12 col-sm-3">
                                    <label>{t('state')} <span className="error">*</span></label>
                                    <select className="form-select" id="stateId" onChange={handleInput}>
                                      <option value="0" key={0} > -- {t('selectState')} -- </option>
                                      {
                                        curStatesMeta.length != 0 ? (
                                          curStatesMeta.map((ele, index) => (
                                            <option key={index} value={ele.stateId}>{ele.stateName}</option>
                                          ))
                                        ) : null
                                      }
                                    </select>
                                    <label id="stateerror" className="error hide">State is required</label>
                                  </div>
                                  <div className="form-group col-12 col-sm-3">
                                    <label>{t('city')} <span className="error">*</span></label>
                                    <select className="form-select" id="cityId" onChange={handleInput}>
                                      <option key={0} value="0" > -- {t('selectCity')} -- </option>
                                      {
                                        curCityList.length != 0 ? (
                                          curCityList.map((ele, index) => (
                                            <option key={index} value={ele.cityId}>{ele.cityName}</option>
                                          ))
                                        ) : null
                                      }
                                    </select>
                                    <label id="cityerror" className="error hide">City is required</label>
                                  </div>



                                  <div className="form-group col-12 col-sm-6">
                                    <label>{t('zipCode')} </label>
                                    <input type="text" className="form-control" placeholder={t('zipCode')} id="zipCode" maxLength="6" onChange={handleInput} />
                                    {/* <label id="zipcodeerror" className="error hide">ZipCode is required</label> */}
                                  </div>
                                  <div className="form-group col-12 col-sm-6">
                                    <label>{t('address')} <span className="error">*</span></label>
                                    <textarea className="form-control" placeholder={t('address')} id="address" onChange={handleInput}></textarea>
                                    <label id="addresserror" className="error hide">Enterprise Address is required</label>
                                  </div>
                                </div>
                              </section>
                              <div className="float-right">
                                <a href="#" data-bs-toggle="modal"
                                  // data-bs-target="#EditEnterprise"
                                  className="btn btn-warning btn-md next-btn enterpriseregbtn" onClick={registerEnterprise}>{t('registerYourEnterprise')}</a>
                              </div>
                            </div>
                          </div>
                          {/* <div className="text-center mt_50 disp_hide" id="dispshow">
                            <img src={companyRegIcon} className="img-responsive pi-image" />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade show" data-backdrop="static" data-keyboard="false" id="EditEnterprise">
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content cardPad card-body border border-radius">
            <div className="modal-header">
              <h3 className="modal-title"></h3>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <img src={okImg} className="img-responsive completeimg" />
                <h3 className="company_reg_confirmation_messg">
                  {t('thankYouForRegistering')}.
                </h3>
                <h3 className="company_reg_confirmation_messg">
                  {t('pleaseConfirmYourRegistrationByVerifyingTheEmail')}.
                </h3>
              </div>
            </div>
            <div className="modal-footer">
              <Link to={pathLink} className="btn btn-danger btn-sm">{t('close')}</Link>
            </div>
          </div>
        </div>
      </div>
      <form ref={emailform} style={{ display: "none" }}>
        <label>From Name</label>
        <input type="text" name="from_name" value="WorkConnect" />
        <label>To Name</label>
        <input type="text" name="to_name" value={enterpriseInfo.keyContact} />
        <label>Subject</label>
        <input type="text" name="subject" value="Enterprise Registration" />
        <label>CAAS Member UserName</label>
        <input type="text" name="cio_user_name" value={enterpriseInfo.keyContact} />
        <label>Reply To</label>
        <input type="email" name="reply_to" value={enterpriseInfo?.emailAddress} />
        <label>Message</label>
        <textarea name="message" value="Enterprise was successfully registered !!" />
      </form>
    </div>
  )
}
export default EnterpriseRegistration;