import axios from "axios";
import { API } from "../config/APIConfig"
const LOGGERAPI = `${API.URL}api/v1/logger/create`;
const getData = async () => {
  const res = await axios.get('https://geolocation-db.com/json/')
  logger.IPv4 = res.data.IPv4;
  return res.data.IPv4;
};
getData();
var logger = {
  application_name: "CAAS WebSite",
  traceObj: "",
  errorObj: "",
  IPv4: "",
  roleId: sessionStorage.getItem("roleId"),
  enterpriseId: sessionStorage.getItem("enterpriseId"),
  loggedInUserId: sessionStorage.getItem("userId"),
  verbose: function (moduleName, origin, userSystemAction, httpMethod, extraInfo, operationData) {
    this.traceObj = logger.logObj(moduleName, origin, userSystemAction, httpMethod, extraInfo, operationData, 'verbose');
    // console.log(this.traceObj);
    handleSubmitlog(this.traceObj)
  },
  error: function (moduleName, origin, userSystemAction, httpMethod, extraInfo, operationData) {
    this.errorObj = logger.logObj(moduleName, origin, userSystemAction, httpMethod, extraInfo, operationData, 'error');
    // console.error(this.errorObj);
    handleSubmitlog(this.errorObj)
  },
  logObj: function (moduleName, origin, userSystemAction, httpMethod, extraInfo, operationData, loggingType) {
    return {
      entityId: 1, roleId: this.roleId, enterpriseId: this.enterpriseId, userId: this.loggedInUserId,
      applicationName: this.application_name, ipAddress: logger.IPv4, browserAgentDetails: navigator.userAgent
      , createdBy: 1,
      moduleName, origin, userSystemAction, httpMethod,
      // correlationId: logger.getCorrelationID(),
      extraInfo, operationData, loggingType
    }
  },
  // getCorrelationID: () => {
  //   return Math.random() * 2; // random number or UUID
  // }
}
export default logger;
export const handleSubmitlog = async (logobj) => {
  const LoggerInfo = {
    "entityId": logobj.entityId == null ? 0 : logobj.entityId,
    "roleId": logobj.roleId == null ? 0 : logobj.roleId,
    "enterpriseId": logobj.enterpriseId == null ? 0 : logobj.enterpriseId,
    "userId": logobj.userId == null ? 0 : logobj.userId,
    "applicationName": logobj.applicationName,
    "moduleName": logobj.moduleName,
    // "correlationId": "",
    "loggingType": logobj.loggingType,
    "origin": logobj.origin,
    "userSystemAction": logobj.userSystemAction,
    "httpMethod": logobj.httpMethod,
    "extraInfo": logobj.extraInfo,
    "operationData": logobj.operationData,
    "ipAddress": logobj.ipAddress,
    "browserAgentDetails": logobj.browserAgentDetails,
    "createdBy": logobj.createdBy
  }
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
  }
  axios.post(LOGGERAPI, LoggerInfo, {
    headers: headers
  }).then(response => {

    if (response.data.status === 200) {
      // console.log(response.data.response);
    }
  }).catch(err => {
    console.error('There was an error!', err);
  });

}
