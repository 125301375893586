import React from 'react'
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { i18n, t } = useTranslation(["common"]);

    return (
        <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">{t('copyrightAllRightsReserved')}</span>
            </div>
        </footer>
    )
}

export default Footer