import React from 'react'
import Header from '../../../commons/components/Header'
import Footer from '../../../commons/components/Footer'
import { Link } from 'react-router-dom'
import CaaSSideBar from '../../../commons/components/SideBars/CaaSSideBar'
import { useParams } from 'react-router-dom'
import RFPDetailsGlobal from './RFPDetailsGlobal'
const PublishedRFPGlobal = (props) => {
    let { rfpId } = useParams();
    //api
    var rfpPublishedDetails = [{
        rfpId: 1, cioCode: "CIO-101",
        userName: "John, Wig",
        userEmail: "John@gmail.com"
    }, {
        rfpId: 2,
        cioCode: "CIO-102",
        userName: "Aravind , Sirivelli",
        userEmail: "aravind.s@swaas.net"
    }, {
        rfpId: 1,
        cioCode: "CIO-103",
        userName: "Roger , Federed",
        userEmail: "roger.federer@gmail.com"
    }]
    var thisRFPPublishedDetails = rfpPublishedDetails.filter((ele) => ele.rfpId == rfpId)
    var CIOCODES = thisRFPPublishedDetails.map(u => u.cioCode).join(', ');
    return (
        <div className="container-scroller">
            <Header entity="CaaS" />
            <div className="container-fluid page-body-wrapper">
                <CaaSSideBar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="col-12">
                                    <div className="card card-rounded card-outline card-primary">
                                        <div className="card-header">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <nav className="navbar navbar-expand bg-white">
                                                        <ul className="navbar-nav">
                                                            <li className="nav-item bold">
                                                                <h3 className="fw-bold">RFP Published</h3>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                                <div className="d-flex align-items-center text-nowrap">
                                                    <Link to="/caas/global/rfp" className="btn btn-primary btn-sm add-client btn-icon-text text-nowrap me-3">Back to RFP</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <RFPDetailsGlobal rfpId={rfpId} CIOCODES={CIOCODES} />
                                            <div className="row">
                                                <div className="col-12 col-sm-12 mt-2">
                                                    <div className="template-demo table-responsive">
                                                        <table className="table table-bordered mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Email</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <>
                                                                    {
                                                                        thisRFPPublishedDetails.length != 0 ? (
                                                                            thisRFPPublishedDetails.map((ele) => (
                                                                                <>
                                                                                    <tr>
                                                                                        <td><a href="#" data-bs-toggle="modal" data-bs-target="#ViewProfile">{ele.userName}</a></td>
                                                                                        <td>{ele.userEmail}</td>
                                                                                    </tr>
                                                                                </>
                                                                            ))
                                                                        ) : (
                                                                            <p>No records available.</p>
                                                                        )
                                                                    }
                                                                </>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}
export default PublishedRFPGlobal