import React, { useState, useEffect } from 'react'
import Header from '../../../commons/components/Header'
import Footer from '../../../commons/components/Footer'
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom'
import CXOSideBar from '../../../commons/components/SideBars/CXOSideBar'
import RFPDetailsGlobal from '../../RFP/caas/RFPDetailsGlobal'
import RFPDetailsCommon from '../../RFP/RFPDetailsCommon'
import $ from "jquery";
import { S3Config } from '../../../config/AWSConfig';
import S3FileUpload from '../../../utils/S3FileUpload';
import { API } from '../../../config/APIConfig';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import logger from '../../../utils/logger'
import uuidCustom from '../../../utils/uuidCustom'
import swal from 'sweetalert'
const QUOTEDETAILSAPI = `${API.URL}api/v1/quote/userwiselist`;
const POSTQUOTEAPI = `${API.URL}api/v1/quote/create`;
const ATTACHMENTTYPELIST = `${API.URL}api/v1/quote/attachmentTypelist`;
const RFPQuote = () => {
    const { i18n, t } = useTranslation(["quote"]);
    // const [rfpquote, setRFPQuote] = useState({});    
    const location = useLocation();
    const navigate = useNavigate();
    //const rfpId = location.pathname.split('/')[5];
    const quotedBy = sessionStorage.getItem("userId");
    const [quoteitems, setQuoteitems] = useState({});
    const [rfpquote, setRFPQuote] = useState({ title: "Designing a reporting portal for digital marketing", requirment: "Lorem, ipsum dolor sit amet consectetur adipisicing Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.", inscope: "Lorem, ipsum dolor sit amet consectetur adipisicing Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.", outscope: "Lorem, ipsum dolor sit amet consectetur adipisicing Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.", startdate: "05-08-2000", quoteduedate: "25-11-2001", minbudget: "123", maxbudget: "234", ciocode: "CIO-01, CIO-02", status: "Open", industryexp: "6", industry: [{ "indid": "1", "indname": "Manufacturing" }, { "indid": "2", "indname": "Banking" }, { "indid": "3", "indname": "Insurance" }], experienceInfo: [{ "experienceId": "1", "experienceSkill": "Oracle finance", "experienceSoftwareVersion": "Latest", "experienceLastUsed": "2 Weeks", "experienceYears": "2 Years", "experienceMonths": "3 Months", "experienceLevel": "Expert", "experienceImplementationSize": "26" }, { "experienceId": "2", "experienceSkill": "Data finance", "experienceSoftwareVersion": "1.0.2", "experienceLastUsed": "6 Weeks", "experienceYears": "2 Years", "experienceMonths": "3 Months", "experienceLevel": "Basic", "experienceImplementationSize": "10" }] });     // const location = useLocation();
    const [attachmentFile, setAttachmentFile] = useState("");
    const [quoteattachments, setQuoteAttachments] = useState([]);
    const [quoteattachmenttype, setQuoteAttachmentsType] = useState([]);
    const [quoteattachmentsget, setQuoteAttachmentsGet] = useState([]);
    const [showcurQuote, setShowCurQuote] = useState({});
    const [curQuote, setCurQuote] = useState([]);
    //const [showcurQuote, setShowCurQuote] = useState([]);
    const [curQuoteId, setcurQuoteId] = useState(0);
    const params = useParams()
    const rfpId = params.rfpId;
    const handlechange = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        if (id === "costEstimate") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
        }
        quoteValidation(id);
        setQuoteitems({ ...quoteitems, [id]: value });
    }
    const editQuote = (e) => {
        // let id = e.target.id;
        let dataId = curQuoteId;
        let dataFor = e.target.getAttribute("data-for");
        let value = e.target.value;
        editquoteValidation(e);
        //const quote_info = [];
        //quote_info.push(quoteitems);
        const newQuote = curQuote.map(obj => {
            if (obj.quoteId == dataId) {
                return { ...obj, [dataFor]: value };
            }
            return obj;
        });
        setCurQuote([...newQuote]);
        setShowCurQuote([...newQuote]);

        // setCurQuote({ ...quoteitems, [dataFor]: value });
    }
    const editquoteValidation = (e) => {
        e.preventDefault();
        let id = e.target.getAttribute("data-for");
        let value = e.target.value;
        if (id === "costEstimate") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
            value = newValue;
        }
        if (id === "quoteRequirement") {
            if (value == "" || value == null || value == undefined) {

                $("#_editquoteRequirementerror").show();
                $("#editquoteRequirement").addClass("error-shadow")

            }
            else {
                $("#_editquoteRequirementerror").hide();
                if ($("#editquoteRequirement").hasClass('error-shadow'))
                    $("#editquoteRequirement").removeClass('error-shadow');
            }
        }
        if (id === "quoteScope") {
            if (value == "" || value == null || value == undefined) {

                $("#_editquoteScoperror").show();
                $("#editquoteScope").addClass("error-shadow")

            }
            else {
                $("#_editquoteScoperror").hide();
                if ($("#editquoteScope").hasClass('error-shadow'))
                    $("#editquoteScope").removeClass('error-shadow');
            }
        }
        if (id === "quoteOutOfScope") {
            if (value == "" || value == null || value == undefined) {

                $("#_editquoteOutOfScopeerror").show();
                $("#editquoteOutOfScope").addClass("error-shadow")

            }
            else {
                $("#_editquoteOutOfScopeerror").hide();
                if ($("#editquoteOutOfScope").hasClass('error-shadow'))
                    $("#editquoteOutOfScope").removeClass('error-shadow');
            }
        }
        if (id === "costEstimate") {
            if (value == "" || value == null || value == undefined) {

                $("#_editcostEstimateerror").show();
                $("#editcostEstimate").addClass("error-shadow")

            }
            else {
                $("#_editcostEstimateerror").hide();
                if ($("#editcostEstimate").hasClass('error-shadow'))
                    $("#editcostEstimate").removeClass('error-shadow');
            }
        }
    }
    useEffect(() => {
        const quote_info = [];
        quote_info.push(quoteitems);
        let editQuoteIndex = quote_info.findIndex((obj => obj.quoteId == curQuoteId));
        if (editQuoteIndex != -1) {
            let editQuoteObj = quote_info[editQuoteIndex];
            setShowCurQuote({ ...editQuoteObj })
            setCurQuote([editQuoteObj])
            setQuoteAttachments(quoteattachmentsget)
        }
        console.log(quoteattachments)
    }, [curQuoteId]);
    useEffect(() => {
        getQuoteDetailsUserwise();
        getAttachmentTypeList();
    }, []);
    const fileUpload = async (e) => {
        const file = e.target.files[0];
        // const fileName = new Date().toISOString() + "|" +  e.target.files[0].name;
        var fileName = e.target.files[0].name;
        var fileExtension = fileName.split('.').pop();
        fileName += S3Config.S3ObjectDelimiter + uuidCustom() + "." + fileExtension;
        const contentType = file.type;
        const formData = { file, fileName, contentType };
        let result = await S3FileUpload(formData).then(
            (response) => {
                if (response.status === 200) {
                    return { status: 200, s3FileUrl: S3Config.S3ObjectBaseURL + fileName }
                }
            }
        ).catch(err => console.log(err));
        return result;
    }
    const uploadAttachmentFile = (e) => {
        let dataFor = e.target.getAttribute("data-for");
        let id = e.target.id;
        // logger.verbose("Quote Attachments", "RFPQuote Component", "uploadAttachmentFile", "POST", "Upload Quote Attachments File", `dataFor=${dataFor}&id=${id}`);
        let attachmentValue = document.getElementById(id).value;
        if (id == 1) {
            if (!attachmentValue) {
                $("#quoteAttachmenterror_1").show();
                return false;
            }
            else {
                $("#quoteAttachmenterror_1").hide();
            }
        }
        else if (id == 2) {
            if (!attachmentValue) {
                $("#quoteAttachmenterror_2").show();
                return false;
            }
            else {
                $("#quoteAttachmenterror_2").hide();
            }
        }
        else if (id == 3) {
            if (!attachmentValue) {
                $("#quoteAttachmenterror_3").show();
                return false;
            }
            else {
                $("#quoteAttachmenterror_3").hide();
            }
        }


        fileUpload(e).then(
            (response) => {
                setAttachmentFile(response.s3FileUrl);
                //console.log(attachmentFile);
                addquoteattachments(response.s3FileUrl, id, dataFor);
            }).catch(err => console.log(err));
    }
    const addquoteattachments = (url, typeId, type) => {
        let obj = {
            quoteAttachmentsId: quoteattachments.length + 1,
            quoteAttachmentsTypeId: typeId,
            attachmentName: type,
            attachmentDetails: url
        }
        // logger.verbose("Quote Attachments", "RFPQuote Component", "addquoteattachments", "GET", "Quote Attachments object created", JSON.stringify(obj));
        // setQuoteAttachments([...quoteattachments, obj]);
        setQuoteAttachments([...quoteattachments.filter((item) => item.quoteAttachmentsTypeId != typeId), obj]);
        // console.log(quoteattachments);
    }
    const uploadEditAttachmentFile = (e) => {
        let dataFor = e.target.getAttribute("data-for");
        let id = e.target.getAttribute("data-attachmentType");;
        fileUpload(e).then(
            (response) => {
                setAttachmentFile(response.s3FileUrl);
                //console.log(attachmentFile);
                addquoteattachments(response.s3FileUrl, id, dataFor);
            }).catch(err => console.log(err));
    }
    const getQuoteDetailsUserwise = () => {
        // logger.verbose("Quote Details Userwise", "RFPQuote Component", "getQuoteDetailsUserwise", "GET", "Get Quote Details Userwise API Hit", `rfpId=${rfpId}&quotedBy=${quotedBy}`);
        axios.get(`${QUOTEDETAILSAPI}?rfpId=${rfpId}&quotedBy=${quotedBy}`).then(response => {
            if (response.data.status === 200 && response.data.response.quotedetailslist != null) {
                setQuoteitems(response.data.response.quotedetailslist);
                setQuoteAttachmentsGet(response.data.response.quoteattachmentslist);
                $("#cxoquotefill").hide();
                $("#viewcxoquote").show();
                logger.verbose("Quote Details Userwise", "RFPQuote Component", "getQuoteDetailsUserwise", "GET", "Get Quote Details Userwise API Response Successfull", JSON.stringify(response.data.response));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            logger.error("Quote Details Userwise", "RFPQuote Component", "getQuoteDetailsUserwise", "GET", "Get Quote Details Userwise API Hit", `rfpId=${rfpId}&quotedBy=${quotedBy}`);
        });
    }
    const getAttachmentTypeList = () => {
        axios.get(`${ATTACHMENTTYPELIST}`).then(response => {
            if (response.data.status === 200) {
                setQuoteAttachmentsType(response.data.response);
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
    // console.log(quoteitems);
    const submitEditQuote = (quoteStatus) => {
        // let quoteobj = curQuote.reduce((obj, item) => {
        //     return {
        //         ...obj,
        //         item,
        //     };
        // }, {});
        // console.log(quoteobj.item);       
        var flaglist = editQuoteValidation();
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;
        if (flag) {
            curQuote[0].quoteStatusId = quoteStatus;
            const quoteobjdata = {
                "quoteDetails": curQuote,
                "quoteAttachments": quoteattachments
            }
            logger.verbose("Submit Quote", "RFPQuote Component", "handlesubmitquote", "POST", "Submit Quote details with status API Object Created", JSON.stringify(quoteobjdata));
            const headers = {
                'Content-Type': 'application/json;charset=utf-8',
            }
            axios.post(POSTQUOTEAPI, quoteobjdata, {
                headers: headers
            }).then(response => {
                //console.log(response);
                if (response.data.status === 200) {
                    $("#EditCXOQuote .close").trigger('click')
                    if (quoteStatus == 1) {
                        swal("Quote submitted successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/cxo/cxo/rfp/manage', { replace: true }) } });

                    }
                    else {
                        swal("Quote submitted successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                        getQuoteDetailsUserwise(rfpId, quotedBy);
                    }

                    logger.verbose("Submit Quote", "RFPQuote Component", "handlesubmitquote", "POST", "Submit Quote details with status API Response Successfull", JSON.stringify(response.data.response));
                }
            }).catch(error => {
                console.error('There was an error!', error);
                logger.error("Submit Quote", "RFPQuote Component", "handlesubmitquote", "POST", "Submit Quote details with status API Response Failed!!", JSON.stringify(error));
            });
            $("#cxoquotefill").hide();
            $("#viewcxoquote").show();
        }
        else {
            swal("Please fill all the details", "ERROR!", "warning", { closeOnClickOutside: false, closeOnEsc: false });
        }
    }
    const editQuoteValidation = () => {
        var flag = true;
        var flaglist = []
        var obj = {
            "quoteRequirement": document.getElementById("editquoteRequirement").value,
            "quoteScope": document.getElementById("editquoteScope").value,
            "quoteOutOfScope": document.getElementById("editquoteOutOfScope").value,
            "costEstimate": document.getElementById("editcostEstimate").value,
            // "attachmentType1": document.getElementById(1).value,
            // "attachmentType2": document.getElementById(2).value,
            // "attachmentType3": document.getElementById(3).value,
        }
        if (!obj.quoteRequirement.trim()) {
            $("#_editquoteRequirementerror").show();
            $("#editquoteRequirement").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_editquoteRequirementerror").hide();
            if ($("#editquoteRequirement").hasClass('error-shadow'))
                $("#editquoteRequirement").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }


        if (!obj.quoteScope.trim()) {
            $("#_editquoteScoperror").show();
            $("#editquoteScope").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_editquoteScoperror").hide();
            if ($("#editquoteScope").hasClass('error-shadow'))
                $("#editquoteScope").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }


        if (!obj.quoteOutOfScope.trim()) {
            $("#_editquoteScoperror").show();
            $("#editquoteOutOfScope").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_editquoteScoperror").hide();
            if ($("#editquoteOutOfScope").hasClass('error-shadow'))
                $("#editquoteOutOfScope").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }


        if (!obj.costEstimate.trim()) {
            $("#_editcostEstimateerror").show();
            $("#editcostEstimate").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_editcostEstimateerror").hide();
            if ($("#editcostEstimate").hasClass('error-shadow'))
                $("#editcostEstimate").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        // if (!obj.attachmentType1) {
        //     $("#quoteAttachmenterror_1").show();
        //     flag = false;
        //     flaglist.push(flag);

        // }
        // else {
        //     $("#quoteAttachmenterror_1").hide();
        //     flag = true;
        //     flaglist.push(flag);
        // }

        // if (!obj.attachmentType2) {
        //     $("#quoteAttachmenterror_2").show();
        //     flag = false;
        //     flaglist.push(flag);

        // }
        // else {
        //     $("#quoteAttachmenterror_2").hide();
        //     flag = true;
        //     flaglist.push(flag);
        // }

        // if (!obj.attachmentType3) {
        //     $("#quoteAttachmenterror_3").show();
        //     flag = false;
        //     flaglist.push(flag);

        // }
        // else {
        //     $("#quoteAttachmenterror_3").hide();
        //     flag = true;
        //     flaglist.push(flag);
        // }
        return flaglist;

    }
    const handlesubmitquote = (quoteStatus) => {
        // logger.verbose("Submit Quote", "RFPQuote Component", "handlesubmitquote", "POST", "Submit Quote details with status API Hit", `quoteStatus=${quoteStatus}`);

        var flaglist = quoteValidation();
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;
        if (flag) {
            //console.log(quoteStatus);
            //console.log(quoteitems);
            //console.log(quoteattachments);
            quoteitems.rfpId = rfpId;
            quoteitems.quotedBy = sessionStorage.getItem("userId");
            quoteitems.quoteStatusId = quoteStatus;
            const quote_info = [];
            quote_info.push(quoteitems);
            const quoteobjdata = {
                "quoteDetails": quote_info,
                "quoteAttachments": quoteattachments
            }
            // console.log(quoteobjdata);
            logger.verbose("Submit Quote", "RFPQuote Component", "handlesubmitquote", "POST", "Submit Quote details with status API Object Created", JSON.stringify(quoteobjdata));
            const headers = {
                'Content-Type': 'application/json;charset=utf-8',
            }
            axios.post(POSTQUOTEAPI, quoteobjdata, {
                headers: headers
            }).then(response => {
                //console.log(response);
                if (response.data.status === 200) {
                    // console.log(response);
                    getQuoteDetailsUserwise(rfpId, quotedBy);
                    logger.verbose("Submit Quote", "RFPQuote Component", "handlesubmitquote", "POST", "Submit Quote details with status API Response Successfull", JSON.stringify(response.data.response));
                }
            }).catch(error => {
                console.error('There was an error!', error);
                logger.error("Submit Quote", "RFPQuote Component", "handlesubmitquote", "POST", "Submit Quote details with status API Response Failed!!", JSON.stringify(error));
            });
            $("#cxoquotefill").hide();
            $("#viewcxoquote").show();
        }
        else {
            swal("Please fill all the details", "ERROR!", "warning", { closeOnClickOutside: false, closeOnEsc: false });
        }
    }
    const quoteValidation = (id) => {
        if (id === "" || id === undefined) {
            return allcheckValidation()
        }
        else {
            return singlecheckValidation(id)
        }
    }
    const singlecheckValidation = (id) => {
        var flag = true;
        var single_flaglist = []
        var obj = {
            "quoteRequirement": document.getElementById("quoteRequirement").value,
            "quoteScope": document.getElementById("quoteScope").value,
            "quoteOutOfScope": document.getElementById("quoteOutOfScope").value,
            "costEstimate": document.getElementById("costEstimate").value
        }
        if (id == "quoteRequirement") {
            if (!obj.quoteRequirement.trim()) {
                $("#quoteRequirementerror").show();
                $("#quoteRequirement").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#quoteRequirementerror").hide();
                if ($("#quoteRequirement").hasClass('error-shadow'))
                    $("#quoteRequirement").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "quoteScope") {
            if (!obj.quoteScope.trim()) {
                $("#quoteScopeerror").show();
                $("#quoteScope").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#quoteScopeerror").hide();
                if ($("#quoteScope").hasClass('error-shadow'))
                    $("#quoteScope").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "quoteOutOfScope") {
            if (!obj.quoteOutOfScope.trim()) {
                $("#quoteOutOfScopeerror").show();
                $("#quoteOutOfScope").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#quoteOutOfScopeerror").hide();
                if ($("#quoteOutOfScope").hasClass('error-shadow'))
                    $("#quoteOutOfScope").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "costEstimate") {
            if (!obj.costEstimate.trim()) {
                $("#costEstimateerror").show();
                $("#costEstimate").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#costEstimateerror").hide();
                if ($("#costEstimate").hasClass('error-shadow'))
                    $("#costEstimate").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        return single_flaglist;
    }
    const allcheckValidation = () => {
        var flag = true;
        var flaglist = []
        var obj = {
            "quoteRequirement": document.getElementById("quoteRequirement").value,
            "quoteScope": document.getElementById("quoteScope").value,
            "quoteOutOfScope": document.getElementById("quoteOutOfScope").value,
            "costEstimate": document.getElementById("costEstimate").value,
            "attachmentType1": document.getElementById(1).value,
            "attachmentType2": document.getElementById(2).value,
            "attachmentType3": document.getElementById(3).value,
        }
        if (!obj.quoteRequirement.trim()) {
            $("#quoteRequirementerror").show();
            $("#quoteRequirement").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#quoteRequirementerror").hide();
            if ($("#quoteRequirement").hasClass('error-shadow'))
                $("#quoteRequirement").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }


        if (!obj.quoteScope.trim()) {
            $("#quoteScopeerror").show();
            $("#quoteScope").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#quoteScopeerror").hide();
            if ($("#quoteScope").hasClass('error-shadow'))
                $("#quoteScope").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }


        if (!obj.quoteOutOfScope.trim()) {
            $("#quoteOutOfScopeerror").show();
            $("#quoteOutOfScope").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#quoteOutOfScopeerror").hide();
            if ($("#quoteOutOfScope").hasClass('error-shadow'))
                $("#quoteOutOfScope").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }


        if (!obj.costEstimate.trim()) {
            $("#costEstimateerror").show();
            $("#costEstimate").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#costEstimateerror").hide();
            if ($("#costEstimate").hasClass('error-shadow'))
                $("#costEstimate").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (!obj.attachmentType1) {
            $("#quoteAttachmenterror_1").show();
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#quoteAttachmenterror_1").hide();
            flag = true;
            flaglist.push(flag);
        }

        if (!obj.attachmentType2) {
            $("#quoteAttachmenterror_2").show();
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#quoteAttachmenterror_2").hide();
            flag = true;
            flaglist.push(flag);
        }

        if (!obj.attachmentType3) {
            $("#quoteAttachmenterror_3").show();
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#quoteAttachmenterror_3").hide();
            flag = true;
            flaglist.push(flag);
        }
        return flaglist;
    }
    return (
        <div className="container-scroller">
            <Header entity="CXO" />
            <div className="container-fluid page-body-wrapper">
                <CXOSideBar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">
                                        {t('hquote')}
                                        <Link to="/cxo/cxo/rfp/manage" className="text-blue fs-5 float-sm-right"><i className="mdi mdi-arrow-left"
                                            aria-hidden="true"></i>{t('backToRFP')}</Link>
                                    </h4>
                                    <div className="mt-0 text-left box-shadow p-4">
                                        <RFPDetailsCommon rfpId={rfpId} />
                                        <div className="quote-wizard" id="cxoquotefill">
                                            <div className="form-wizard">
                                                <form action="" method="post" role="form">
                                                    <fieldset className="wizard-fieldset show">
                                                        <div className="row">
                                                            <div className="col-12 col-sm-12">
                                                                <div className="form-group">
                                                                    <label className="mb-1" htmlFor="exampleInputName1">{t('requirementSpecification')}<span className="error">*</span></label>
                                                                    <textarea className="form-control" placeholder={t('requirementSpecification')} rows="5" id="quoteRequirement" onChange={handlechange}></textarea>
                                                                    <label id="quoteRequirementerror" className="error hide">Quote Requirement is required</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12">
                                                                <div className="form-group">
                                                                    <label className="mb-1" htmlFor="exampleInputName1">{t('scopeConfirmation')}<span className="error">*</span></label>
                                                                    <textarea className="form-control" placeholder={t('scopeConfirmation')} rows="5" id="quoteScope" onChange={handlechange}></textarea>
                                                                    <label id="quoteScopeerror" className="error hide">Quote Scope is required</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12">
                                                                <div className="form-group">
                                                                    <label className="mb-1" htmlFor="exampleInputName1">{t('outOfScopeConfirmationAddition')}<span className="error">*</span></label>
                                                                    <textarea className="form-control" placeholder={t('outOfScopeConfirmationAddition')} rows="5" id="quoteOutOfScope" onChange={handlechange}></textarea>
                                                                    <label id="quoteOutOfScopeerror" className="error hide">Quote Out of Scope is required</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12">
                                                                <div className="form-group">
                                                                    <label className="mb-1" htmlFor="exampleInputName1">{t('cost')} (USD)<span className="error">*</span></label>
                                                                    <input type="text" className="form-control" placeholder={t('cost')} id="costEstimate" onChange={handlechange} />
                                                                    <label id="costEstimateerror" className="error hide">Cost estimate is required</label>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 pb-1">
                                                                <h3 className="text-dark fw-bold h4 border-bottom p-3">{t('attachment')}</h3>
                                                            </div>
                                                            {/* <div className="mt-0 pb-1">
                                                                <label htmlFor="" className="text-gray mb-2 mt-2 pb-3 border-bottom d-block"> <i className="fa-solid fa-file-word fa-lg"></i> - Bill of Material/Hardware/Software Licences <a href="#"><i className="fa fa-close fa-lg text-danger ms-3"></i></a></label>
                                                            </div> */}
                                                            {/* <div className="mt-2 pb-1">
                                                                <label htmlFor="" className="text-gray mb-2 "> <i className="fa-solid fa-file-word fa-lg"></i> - {t('architecture/DesignDocuments')}</label>
                                                                <div className="form-group  border-bottom pb-4">
                                                                    <input className="form-control" type="file" multiple="" id="attachmentFile" onChange={uploadAttachmentFile} />
                                                                </div>
                                                            </div>
                                                            <div className="mt-0 pb-1 ">
                                                                <label htmlFor="" className="text-gray mb-2"> <i className="fa-solid fa-file-word fa-lg"></i> - {t('projectPlan')}</label>
                                                                <div className="form-group border-bottom pb-4">
                                                                    <input className="form-control" type="file" multiple="" id="projectPlanFile" onChange={uploadAttachmentFile} />
                                                                </div>
                                                            </div>
                                                            <div className="mt-0 pb-1   ">
                                                                <label htmlFor="" className="text-gray mb-2 "> <i className="fa-solid fa-file-word fa-lg"></i> - {t('miscellaneousFile')}</label>
                                                                <div className="form-group border-bottom pb-4">
                                                                    <input className="form-control" type="file" multiple="" id="miscellaneousFile" onChange={uploadAttachmentFile} />
                                                                </div>
                                                            </div> */}
                                                            {
                                                                quoteattachmenttype.length != 0 ? (
                                                                    quoteattachmenttype.map((ele, index) => (
                                                                        <div className="mt-2 pb-1">
                                                                            <label htmlFor="" className="text-gray mb-2"><i className="fa-solid fa-file-word fa-lg"></i> <span id="attachmenttypename"> {ele.quoteAttachmentsType}</span> <span className="error">*</span></label>
                                                                            <div className="form-group  border-bottom pb-4"><input className="form-control" type="file" id={ele.quoteAttachmentsTypeId} data-for={ele.quoteAttachmentsType} onChange={uploadAttachmentFile} /></div>
                                                                            <label id={`quoteAttachmenterror_${ele.quoteAttachmentsTypeId}`} className="error hide">Atachment is required</label>
                                                                        </div>
                                                                    ))
                                                                ) : null
                                                            }
                                                            <div className="float-right">
                                                                <a className="btn btn-warning btn-md ml_20 float-right next-btn" data-bs-toggle="modal" data-bs-target="#StatusUpdateSave">{t('submitQuote')}</a>
                                                                {/* <Link to="/cxo/cxo/rfp/manage" className="btn btn-warning btn-md ml_20 float-right next-btn"  data-bs-target="#StatusUpdateSave">{t('submitQuote')}</Link> */}
                                                                <a className="btn btn-warning float-right btn-md previous-btn savepreview" data-bs-toggle="modal" onClick={() => handlesubmitquote(2)}>{t('savePreviewQuote')}</a>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    {/* View CXO Quote    */}
                                    <div className="modal-content noborder" id="viewcxoquote" style={{ display: "none" }}>
                                        <div className="modal-header">
                                            <h4 className="modal-title">{t('caasMemberViewQuote')}</h4>
                                            {
                                                quoteitems.quoteStatusId != 1 ? <i className="fa fa-pencil fa-lg float-end text-muted pt-2" title="Edit" data-bs-toggle="modal" data-bs-target="#EditCXOQuote" onClick={() => {
                                                    setcurQuoteId(quoteitems?.quoteId)
                                                }}></i> : ""
                                            }

                                        </div>
                                        <div className="modal-bodys">
                                            <div className="row">
                                                <div className="col-12 col-sm-12 ">
                                                    <div className="form-group mt_30">
                                                        <label className="mb-1 fw-bold">{t('requirementSpecification')}:</label>
                                                        <span className="ml_20">{quoteitems.quoteRequirement}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="mb-1 fw-bold">{t('scopeConfirmation')}:</label>
                                                        <span className="ml_20">{quoteitems.quoteScope}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 ">
                                                    <div className="form-group">
                                                        <label className="mb-1 fw-bold">{t('outOfScopeConfirmationAddition')}:</label>
                                                        <span className="ml_20">{quoteitems.quoteOutOfScope}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label className="mb-1 fw-bold">{t('cost')}:</label>
                                                        <span className="ml_20">${quoteitems.costEstimate}</span>
                                                    </div>
                                                </div>
                                                <div className="mt-2 pb-1">
                                                    <h3 className="text-dark fw-bold h4 py-3">{t('attachment')}:</h3>
                                                </div>
                                                {/* {
                                                    quoteattachments.length != 0 ? (
                                                        quoteattachments.map((ele, index) => (
                                                            <div className="mt-0 pb-1">
                                                                <label className="text-gray mb-2 mt-2 pb-3 border-bottom d-block" key={index} value={ele.quoteAttachmentsTypeId}> <i className="fa-solid fa-file-word fa-lg"></i> - {ele.attachmentName}</label>
                                                                <a href={ele.attachmentDetails}>{ele.attachmentDetails.split('/')[3].split(S3Config.S3ObjectDelimiter)[0]}</a>
                                                            </div>
                                                        ))
                                                    ) : null
                                                } */}
                                                {
                                                    quoteattachmentsget.length != 0 ? (
                                                        quoteattachmentsget.map((ele, index) => (
                                                            <div className="mt-0 pb-1">
                                                                <label className="text-gray mb-2 mt-2 pb-3 border-bottom d-block" key={index} value={ele.quoteAttachmentsTypeId}> <i className="fa-solid fa-file-word fa-lg"></i> - {ele.quoteAttachmentsType}</label>
                                                                <a href={ele.attachment}>{ele.attachmentTitle.split(S3Config.S3ObjectDelimiter)[0]}</a>
                                                            </div>
                                                        ))
                                                    ) : null
                                                }
                                                {/* <div className="mt-2 pb-1">
                                                    <div className="form-group">
                                                        <label className="text-gray"> <i className="fa-solid fa-file-word fa-lg"></i> - {t('architecture/DesignDocuments')} : </label>
                                                        <span className="ml_20">{quoteattachments[0]?.attachmentDetails.split("/")[3]}</span>
                                                    </div>
                                                </div>
                                                <div className="mt-0 pb-1 ">
                                                    <div className="form-group">
                                                        <label htmlFor="" className="text-gray"> <i className="fa-solid fa-file-word fa-lg"></i> - {t('projectPlan')} : </label>
                                                        <span className="ml_20">{quoteattachments[1]?.attachmentDetails.split("/")[3]}</span>
                                                    </div>
                                                </div>
                                                <div className="mt-0 pb-1 ">
                                                    <div className="form-group">
                                                        <label htmlFor="" className="text-gray"> <i className="fa-solid fa-file-word fa-lg"></i> - {t('miscellaneousFile')}: </label>
                                                        <span className="ml_20">{quoteattachments[2]?.attachmentDetails.split("/")[3]}</span>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* View CXO Quote    */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="StatusUpdateSave" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title" id="exampleModalLabel">{t('confirmation')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <h5>{t('areYouSureYouWantToSubmitTheQuote?')}</h5>

                        </div>
                        <div className="modal-footer">
                            <a className="btn btn-warning btn-md next-btn" data-bs-dismiss="modal" onClick={() => handlesubmitquote(1)}>{t('yes')}</a>
                            <a className="btn btn-warning btn-md previous-btn ml_30" data-bs-dismiss="modal">{t('no')}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="EditCXOQuote" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('editQuote')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="mb-1" htmlFor="exampleInputName1">{t('requirementSpecification')}<span className="error">*</span></label>
                                        <textarea className="form-control" placeholder={t('requirementSpecification')} rows="5" data-for="quoteRequirement" id="editquoteRequirement" value={showcurQuote.quoteRequirement} onChange={editQuote}></textarea>
                                        <label id="_editquoteRequirementerror" className="error hide">Quote Requirement is required</label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="mb-1" htmlFor="exampleInputName1">{t('scopeConfirmation')}<span className="error">*</span></label>
                                        <textarea className="form-control" placeholder={t('scopeConfirmation')} rows="5" data-for="quoteScope" id="editquoteScope" value={showcurQuote.quoteScope} onChange={editQuote}></textarea>
                                        <label id="_editquoteScoperror" className="error hide">Quote Scope is required</label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="mb-1" htmlFor="exampleInputName1">{t('outOfScopeConfirmationAddition')}<span className="error">*</span></label>
                                        <textarea className="form-control" placeholder={t('outOfScopeConfirmationAddition')} rows="5" data-for="quoteOutOfScope" id="editquoteOutOfScope" value={showcurQuote.quoteOutOfScope} onChange={editQuote}></textarea>
                                        <label id="_editquoteOutOfScopeerror" className="error hide">Quote Out of Scope is required</label>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="mb-1" htmlFor="exampleInputName1">{t('cost')}<span className="error">*</span></label>
                                        <input type="text" className="form-control" placeholder={t('cost')} data-for="costEstimate" id="editcostEstimate" value={showcurQuote.costEstimate} onChange={editQuote} />
                                        <label id="_editcostEstimateerror" className="error hide">Cost estimate is required</label>
                                    </div>
                                </div>
                                <div className="mt-2 pb-1">
                                    <h3 className="text-dark fw-bold h4 border-bottom p-3">{t('attachment')}</h3>
                                </div>
                                {/* <div className="mt-0 pb-1">
                                    <label className="text-gray mb-2 mt-2 pb-3 border-bottom d-block"> <i className="fa-solid fa-file-word fa-lg"></i> - Bill of Material/Hardware/Software Licences <a href="#"><i className="fa fa-close fa-lg text-danger ms-3"></i></a></label>
                                </div> */}
                                {/* <div className="mt-2 pb-1">
                                    <label className="text-gray mb-2 "> <i className="fa-solid fa-file-word fa-lg"></i> - {t('architecture/DesignDocuments')}</label>
                                    <div className="form-group  border-bottom pb-4">
                                        <input className="form-control" type="file" multiple="" id="attachmentFile" onChange={uploadAttachmentFile} />
                                    </div>
                                </div>
                                <div className="mt-0 pb-1 ">
                                    <label className="text-gray mb-2"> <i className="fa-solid fa-file-word fa-lg"></i> - {t('projectPlan')}</label>
                                    <div className="form-group border-bottom pb-4">
                                        <input className="form-control" type="file" multiple="" id="projectPlanFile" onChange={uploadAttachmentFile} />
                                    </div>
                                </div>
                                <div className="mt-0 pb-1   ">
                                    <label className="text-gray mb-2 "> <i className="fa-solid fa-file-word fa-lg"></i> - {t('miscellaneousFile')}</label>
                                    <div className="form-group border-bottom pb-4">
                                        <input className="form-control" type="file" multiple="" id="miscellaneousFile" onChange={uploadAttachmentFile} />
                                    </div>
                                </div> */}
                                {
                                    quoteattachments.length != 0 ? (
                                        quoteattachments.sort((a, b) => a.quoteAttachmentsTypeId - b.quoteAttachmentsTypeId).map((ele, index) => (
                                            <div className="mt-2 pb-1" key={index}>
                                                {/* <label htmlFor="" className="text-gray mb-2"><i className="fa-solid fa-file-word fa-lg"></i> <span id="editattachmenttypename"> {ele.quoteAttachmentsType}</span> <a href={ele.attachment} className="text-link country" target="_blank">{ele.attachmentTitle.split(S3Config.S3ObjectDelimiter)[0]}</a></label> */}
                                                <label htmlFor="" className="text-gray mb-2"><i className="fa-solid fa-file-word fa-lg"></i> <span id="editattachmenttypename"> {ele.quoteAttachmentsType ? ele.quoteAttachmentsType : ele.attachmentName}</span> <a href={ele.attachment ? ele.attachment : ele.attachmentDetails} className="text-link country" target="_blank">{ele.attachmentTitle ? ele.attachmentTitle.split(S3Config.S3ObjectDelimiter)[0] : ele.attachmentDetails.split('/')[3]?.split(S3Config.S3ObjectDelimiter)[0]}</a></label>
                                                <div className="form-group  border-bottom pb-4"><input className="form-control" type="file" data-attachmentType={ele.quoteAttachmentsTypeId} id={"attachmentType-" + ele?.quoteAttachmentsTypeId} data-for={ele.quoteAttachmentsType ? ele.quoteAttachmentsType : ele.attachmentName} onChange={uploadEditAttachmentFile} /></div>
                                            </div>
                                        ))
                                    ) : null
                                }
                                <div className="text-center BtnAlign">
                                    {/* <a className="btn btn-warning btn-md next-btn" data-bs-dismiss="modal" onClick={() => handlesubmitquote(2)}>{t('updateQuote')}</a>
                                    <Link to="/cxo/cxo/rfp/manage" className="btn btn-warning btn-md previous-btn ml_30" data-bs-dismiss="modal" onClick={() => handlesubmitquote(1)}>{t('submitQuote')}</Link> */}
                                    <a className="btn btn-warning btn-md next-btn" onClick={() => submitEditQuote(2)}>{t('updateQuote')}</a>
                                    {/* <Link to="/cxo/cxo/rfp/manage" className="btn btn-warning btn-md previous-btn ml_30" onClick={() => submitEditQuote(1)}>{t('submitQuote')}</Link> */}
                                    <a className="btn btn-warning btn-md previous-btn ml_30" onClick={() => submitEditQuote(1)}>{t('submitQuote')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default RFPQuote
