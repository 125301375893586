import React, { useState, useEffect, useRef } from 'react'
import okImg from "../../../assets/images/okk.jpg"
import Footer from '../../../commons/components/Footer';
import vendorRegIcon from "../../../assets/images/company_reg.jpg"
import experienceImg from "../../../assets/images/experience.png"
import AuthHeader from '../AuthHeader';
import { useTranslation } from "react-i18next";
import $ from "jquery";
import { API } from '../../../config/APIConfig';
import axios from 'axios';
import swal from 'sweetalert';
import { getCountryStateCityList, getVendorTypeList, getSkillExperienceLevelList, getSkillDetailslList, getIndustryDetailsList, getgovissuedUniqueIdentifierDetailsList } from '../../../utils/meta';
import { useLocation, useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import { S3Config } from '../../../config/AWSConfig';
import S3FileUpload from '../../../utils/S3FileUpload';
import logger from '../../../utils/logger';
import certificateImg from "../../../assets/images/certificate.png"
import uuidCustom from '../../../utils/uuidCustom';
const VendorRegistrationAPI = `${API.URL}api/v1/vendor/register`;
const VendorNameCheckAPI = `${API.URL}api/v1/vendor/validate/vendorName`;
const VendorRegistration = () => {
    const navigate = useNavigate();

    const [countriesMeta, setCountriesMeta] = useState([]);
    const [statesMeta, setStatesMeta] = useState([]);
    const [curStatesMeta, setCurStatesMeta] = useState([]);
    const [citiesMeta, setCitiesMeta] = useState([]);
    const [curCityList, setCurCityList] = useState([]);
    const [certificationInfo, setCertificationInfo] = useState([]);
    const [experienceInfo, setExperienceInfo] = useState([]);
    const [skillexperiencelevelsMeta, setSkillexperiencelevelsMeta] = useState([]);
    const [skilldetailsMeta, setSkillDetailsMeta] = useState([]);
    const [skillversionMeta, setSkillVersionssMeta] = useState([]);
    const [curskillVersionList, setCurSkillversionList] = useState([]);
    const [skillRef, setSkillRef] = useState({});
    const [skillVersionRef, setSkillVersionRef] = useState({});
    const [industryExperiencedisplay, setIndustryExperienceDisplay] = useState([]);
    const [industryExperienceInfo, setIndustryExperienceInfo] = useState("");
    const [industrydetails, setIndustryDetails] = useState([]);
    const [vendorPrimaryInfo, setVendorPrimaryInfo] = useState({});
    const [vendorLogo, setVendorLogo] = useState();
    const [vendorExists, setVendorExists] = useState(false);
    const [govissueduniqueIdentifierTypesMeta, setGovIssuedUniqueIdentifierTypesMeta] = useState([]);
    const [countryphonecodeMeta, setCountryPhoneCodeMeta] = useState([]);
    const [curCountryphonecodeMeta, setCurCountryPhoneCodeMeta] = useState([]);
    const { i18n, t } = useTranslation(["registration"]);
    const location = useLocation();
    const handleLanguageChange = (e) => {
        i18n.changeLanguage(e.target.value);
    };
    const [curCompId, setcurCompId] = useState(0);
    const [curComp, setCurComp] = useState({});
    const [curCertId, setcurCertId] = useState(0);
    const [curCert, setCurCert] = useState({});
    const [certificateFile, setCertificateFile] = useState("");
    useEffect(() => {
        let editExpIndex = experienceInfo.findIndex((obj => obj.id == curCompId));
        if (editExpIndex != -1) {
            let editExpObj = experienceInfo[editExpIndex];
            setCurComp({ ...editExpObj })
        }
    }, [curCompId]);
   
    useEffect(() => {
        let editCertIndex = certificationInfo.findIndex((obj => obj.id == curCertId));
        if (editCertIndex != -1) {
            let editCertObj = certificationInfo[editCertIndex];
            setCurCert({ ...editCertObj })
        }
    }, [curCertId]);
    const resetskillversion = (id) => {
        // var id = e.target.value;
        var newskillversionList = skillversionMeta.filter((ele) => ele.skillId == id);
        setCurSkillversionList([...newskillversionList]);
    }
    useEffect(() => {
        if (localStorage.getItem("i18nextLng")?.length > 2) {
            i18n.changeLanguage("en");
        }
        bindDropDownData();
        bindHandlers();
    }, []);
    const bindHandlers = () => {
        $("#sideNavTabs > li").on("click", function (e) {
            selectTab(e);
        });
        $(".add_pi").on("click", function () {
            loadPrimaryInforInnerDiv();
        });
        loadPrimaryInforInnerDiv();
    }
    function loadPrimaryInforInnerDiv() {
        $("#primaryInfoInnerDiv").removeClass("hide");
        $("#navControls").removeClass("hide");
        $("#dispshow").addClass("hide");
        $("#addpi_btn").addClass("hide");
    }
    const bindDropDownData = () => {
        getCountryStateCityList()
            .then((res) => {
                if (res.response) {
                    setCountriesMeta(res.response.countryList);
                    setStatesMeta(res.response.stateList);
                    setCurStatesMeta(res.response.stateList);
                    setCitiesMeta(res.response.cityList);
                    setCurCityList(res.response.cityList);
                    setCountryPhoneCodeMeta(res.response.phoneCodeList);
                    setCurCountryPhoneCodeMeta(res.response.phoneCodeList);
                }
            })
            .catch((e) => {
                console.log(e.message)
            })
        getSkillExperienceLevelList()
            .then((res) => {
                if (res.response) {
                    setSkillexperiencelevelsMeta(res.response);
                }
            })
            .catch((e) => {
                //console.log(e.message)
            })
        getSkillDetailslList()
            .then((res) => {
                if (res.response) {
                    var skillDetails = res.response.skillMasterList;
                    var skillVersionDetails = res.response.skillVersionList;
                    setSkillDetailsMeta(skillDetails);
                    setSkillVersionssMeta(skillVersionDetails);
                    var skilldata = skillDetails.reduce((obj, skill) => Object.assign(obj, { [skill.skillId]: skill.skillName }), {});
                    setSkillRef(skilldata);
                    var skillVersiondata = skillVersionDetails.reduce((obj, version) => Object.assign(obj, { [version.skillVersionId]: version.skillVersionName }), {});
                    setSkillVersionRef(skillVersiondata);
                }
            })
            .catch((e) => {
                //console.log(e.message)
            })
        getIndustryDetailsList()
            .then((res) => {
                if (res.response) {
                    setIndustryDetails(res.response);
                }
            })
            .catch((e) => {
                console.error('There was an error!', e);
            })
        getgovissuedUniqueIdentifierDetailsList()
            .then((data) => {
                if (data.response) {
                    setGovIssuedUniqueIdentifierTypesMeta(data.response);
                }
            })
            .catch((e) => {
                console.log(e.message)
            })
    }

    const handleexpdelete = (id) => {
        const deleted = experienceInfo.filter((exp) => exp.id !== id);
        setExperienceInfo(deleted);
    }

    const selectTab = (tab) => {
        let ele = $("#sideNavTabs").find(".current");
        ele.removeClass("current");
        ele.removeClass("active");
        ele.addClass("disabled-wizard");
        highlightSection(tab);
    }
    const highlightSection = (tab) => {
        tab.currentTarget.className = "nav-item active current";
        let curSection = tab.target.getAttribute("aria-controls");
        if (curSection) {
            $("section.mainSection").hide()
            $('#' + curSection).show();
        }
    }
    const showNextDiv = () => {
        let ele = $("#sideNavTabs").find(".current");
        if (ele.index() == 1) {
            var flaglist = allcheckValidation();
            if (flaglist.includes(false))
                var flag = false;
            else
                var flag = true;
            if (flag) {
                showNextSection();
            }

            else {
                swal("Please fill the Vendor Information", "", "error", { closeOnClickOutside: false, closeOnEsc: false });
            }
        }
        if (ele.index() == 2) {
            if (!experienceInfo.length > 0) {
                var flaglist = allskillcheckValidation();
                if (flaglist.includes(false))
                    var flag = false;
                else
                    var flag = true;

                if (!flag) {
                    swal("Please fill the Experience Details", "", "error", { closeOnClickOutside: false, closeOnEsc: false });
                    //   showNextSection(); 
                }
            }
            else if (experienceInfo.length < 2) {
                swal("Please enter 2 skills", "", "error", { closeOnClickOutside: false, closeOnEsc: false });
            }
            else {
                showNextSection();
            }
        }
        if (ele.index() == 3) {
            // if (!certificationInfo.length > 0) {
            //     var flaglist = certificateValidation();
            //     if (flaglist.includes(false))
            //         var flag = false;
            //     else
            //         var flag = true;

            //     if (!flag) {
            //         //   $("#ProfileSummary .close").trigger("click");
            //         swal("Please fill the certification Information", "", "error", { closeOnClickOutside: false, closeOnEsc: false });

            //     }
            // }

            // else {
            showNextSection();

            // }
        }
        if (ele.index() > 7) {
            showNextSection();
        }

    }
    const showNextSection = () => {
        let ele = $("#sideNavTabs").find(".current");
        let parentElement = document.getElementById("sideNavTabs");
        let nextItemId = ele.index() + 1;
        if (nextItemId < 5) {
            $("#previousli").removeClass("disabled");
            let nextItem = parentElement.childNodes.item(nextItemId);
            nextItem.click();
            let curSection = nextItem.childNodes[0].getAttribute("aria-controls");
            if (curSection) {
                $("section.mainSection").hide();
                $('#' + curSection).show();
                $("#nextBtn").show();
                $("#finishli").hide();
            }
            if (nextItemId == 4) {
                $("#nextBtn").hide();
                $("#finishli").show();
            }
            $("#primaryInfoDiv > section").hide();
        }
    }
    const showPreviousDiv = () => {
        let ele = $("#sideNavTabs").find(".current");
        let parentElement = document.getElementById("sideNavTabs");
        let prevItemId = ele.index() - 1;
        if (prevItemId == 0) {
            $("#previousli").addClass("disabled");
        }
        if (prevItemId > 0) {
            let prevItem = parentElement.childNodes.item(prevItemId);
            prevItem.click();

            let curSection = prevItem.childNodes[0].getAttribute("aria-controls");
            if (curSection) {
                $("section.mainSection").hide();
                $('#' + curSection).show();
            }
            $("#nextBtn").show();
            $("#finishli").hide();
        }
    }
    const addUpdIndustry = (e) => {
        let data = $("#experienceIndustry").val();
        data = data.filter((ele) => ele != "0");
        let obj = {
            id: industryExperienceInfo.length + 1,
            industryName: $('#experienceIndustry option:selected').text(),
            industryId: e.target.value,
        }
        setIndustryExperienceInfo(data.toString());
        setIndustryExperienceDisplay(data);
    }
    const addExperience = () => {
        let obj = {
            id: experienceInfo.length + 1,
            experienceSkillId: document.getElementById('experienceSkill').value,
            experienceSoftwareVersionId: document.getElementById('experienceSoftwareVersion').value,
            experienceLastUsed: document.getElementById('experienceLastUsed').value,
            experienceYears: document.getElementById('experienceYears').value,
            // experienceMonths: document.getElementById('experienceMonths').value,
            experienceImplementationSize: document.getElementById('experienceImplementationSize').value,
            experienceLevel: document.getElementById('experienceLevel').value,
            experienceLevelName: $("#experienceLevel option:selected").text(),
            experienceSkillName: $("#experienceSkill option:selected").text(),
            experienceSoftwareVersionName: $("#experienceSoftwareVersion option:selected").text()
        }
        var flaglist = allskillcheckValidation()
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;
        if (flag) {
            setExperienceInfo([...experienceInfo, obj]);
            $("#viewexperienceDiv").removeClass("hide");
            $("#experienceImg").hide();
            document.getElementById("addExperienceForm").reset();
            $("#AddExperience .close").trigger('click')
        }


        // else {
        //     console.log("All the mandatory fields for experience should be filled")
        // }
    }
    const skillvalidation = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        if (id === "experienceImplementationSize") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
        }
        if (id == "experienceSoftwareVersion") {
            if (value == 0) {
                $("#experienceSoftwareVersionerror").show();
                $("#experienceSoftwareVersion").addClass("error-shadow")

            }
            else {
                $("#experienceSoftwareVersionerror").hide();
                if ($("#experienceSoftwareVersion").hasClass('error-shadow'))
                    $("#experienceSoftwareVersion").removeClass('error-shadow');
            }
        }
        if (id == "experienceLastUsed") {
            if (value == 0) {
                $("#experienceLastUsederror").show();
                $("#experienceLastUsed").addClass("error-shadow")

            }
            else {
                $("#experienceLastUsederror").hide();
                if ($("#experienceLastUsed").hasClass('error-shadow'))
                    $("#experienceLastUsed").removeClass('error-shadow');
            }
        }
        if (id == "experienceYears") {
            if (value == 0) {
                $("#experienceYearserror").show();
                $("#experienceYears").addClass("error-shadow")

            }
            else {
                $("#experienceYearserror").hide();
                if ($("#experienceYears").hasClass('error-shadow'))
                    $("#experienceYears").removeClass('error-shadow');
            }
        }
        if (id == "experienceImplementationSize") {
            if (value == 0) {
                $("#experienceImplementationSizeerror").show();
                $("#experienceImplementationSize").addClass("error-shadow")

            }
            else {
                $("#experienceImplementationSizeerror").hide();
                if ($("#experienceImplementationSize").hasClass('error-shadow'))
                    $("#experienceImplementationSize").removeClass('error-shadow');
            }
        }
        if (id == "experienceLevel") {
            if (value == 0) {
                $("#experienceLevelerror").show();
                $("#experienceLevel").addClass("error-shadow")

            }
            else {
                $("#experienceLevelerror").hide();
                if ($("#experienceLevel").hasClass('error-shadow'))
                    $("#experienceLevel").removeClass('error-shadow');
            }
        }
    }
    const allskillcheckValidation = () => {
        var flag = true;
        var flaglist = [];
        let obj = {
            id: experienceInfo.length + 1,
            experienceSkillId: document.getElementById('experienceSkill').value,
            experienceSoftwareVersionId: document.getElementById('experienceSoftwareVersion').value,
            experienceLastUsed: document.getElementById('experienceLastUsed').value,
            experienceYears: document.getElementById('experienceYears').value,
            // experienceMonths: document.getElementById('experienceMonths').value,
            experienceImplementationSize: document.getElementById('experienceImplementationSize').value,
            experienceLevel: document.getElementById('experienceLevel').value,
            experienceLevelName: $("#experienceLevel option:selected").text(),
            experienceSkillName: $("#experienceSkill option:selected").text(),
            experienceSoftwareVersionName: $("#experienceSoftwareVersion option:selected").text()
        }
        if (obj.experienceSkillId == 0) {
            $("#experienceSkillerror").show();
            $("#experienceSkill").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#experienceSkillerror").hide();
            if ($("#experienceSkill").hasClass('error-shadow'))
                $("#experienceSkill").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (obj.experienceSoftwareVersionId == 0) {
            $("#experienceSoftwareVersionerror").show();
            $("#experienceSoftwareVersion").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#experienceSoftwareVersionerror").hide();
            if ($("#experienceSoftwareVersion").hasClass('error-shadow'))
                $("#experienceSoftwareVersion").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (obj.experienceLastUsed == 0) {
            $("#experienceLastUsederror").show();
            $("#experienceLastUsed").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#experienceLastUsederror").hide();
            if ($("#experienceLastUsed").hasClass('error-shadow'))
                $("#experienceLastUsed").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (obj.experienceYears == 0) {
            $("#experienceYearserror").show();
            $("#experienceYears").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#experienceYearserror").hide();
            if ($("#experienceYears").hasClass('error-shadow'))
                $("#experienceYears").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (obj.experienceImplementationSize == 0) {
            $("#experienceImplementationSizeerror").show();
            $("#experienceImplementationSize").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#experienceImplementationSizeerror").hide();
            if ($("#experienceImplementationSize").hasClass('error-shadow'))
                $("#experienceImplementationSize").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (obj.experienceLevel == 0) {
            $("#experienceLevelerror").show();
            $("#experienceLevel").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#experienceLevelerror").hide();
            if ($("#experienceLevel").hasClass('error-shadow'))
                $("#experienceLevel").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        return flaglist;
    }
    // Experience Summary//
    const editExperience = (e) => {
        let dataFor = e.target.getAttribute("data-for");
        let dataId = curCompId;
        let value = e.target.value;
        if (dataFor === "experienceSkillId") {
            resetversionList(e);
            // editExperinceSkillFix(value);
        }
        if (dataFor === "experienceSoftwareVersion") {
            // editExperinceSkillversionFix(value);
        }

        const newExperience = experienceInfo.map(obj => {
            if (obj.id == dataId) {
                return { ...obj, [dataFor]: value };
            }
            return obj;
        });
        setExperienceInfo([...newExperience]);
        setCurComp([...newExperience]);
        if (JSON.stringify(experienceInfo) !== "{}") {
            $("#viewExperienceDiv .row").show();
        }
        if (dataFor === "experienceLevel") {
            editExperienceFix(value);
        }
    }
    const editExperienceFix = (data) => {
        let dataFor = "experienceLevelName";
        let dataId = curCompId;
        let value = skillexperiencelevelsMeta.filter((ele) => ele.skillExperienceId == data)[0].skillExperienceLevel;
        const newExperience = experienceInfo.map(obj => {
            if (obj.id == dataId) {
                return { ...obj, [dataFor]: value };
            }
            return obj;
        });
        setExperienceInfo([...newExperience]);
        setCurComp([...newExperience]);
        if (JSON.stringify(experienceInfo) !== "{}") {
            $("#viewExperienceDiv .row").show();
        }
    }
    const resetversionList = (e) => {
        var id = e.target.value;
        let idName = e.target.getAttribute("data-for");
        var newskillversionList = skillversionMeta.filter((ele) => ele.skillId == id);
        setCurSkillversionList([...newskillversionList]);
        if (idName == "experienceSkillId") {
            if (id == 0) {
                $("#_skillNameerror").show();
                $("#experienceSkill").addClass("error-shadow")

            }
            else {
                $("#_skillNameerror").hide();
                if ($("#experienceSkill").hasClass('error-shadow'))
                    $("#experienceSkill").removeClass('error-shadow');

            }
        }
        else {
            if (id == 0) {
                $("#experienceSkillerror").show();
                $("#experienceSkill").addClass("error-shadow")

            }
            else {
                $("#experienceSkillerror").hide();
                if ($("#experienceSkill").hasClass('error-shadow'))
                    $("#experienceSkill").removeClass('error-shadow');

            }
        }
    }
    const fileUpload = async (e) => {
        const file = e.target.files[0];
        // const fileName = new Date().toISOString() + "|" +  e.target.files[0].name;
        var fileName = e.target.files[0].name;   
        var fileExtension =fileName.split('.').pop();  
        fileName +=  S3Config.S3ObjectDelimiter + uuidCustom() +"."+ fileExtension;
        const contentType = file.type;
        const formData = { file, fileName, contentType };
        let result = await S3FileUpload(formData).then(
            (response) => {
                if (response.status === 200) {
                    return { status: 200, s3FileUrl: S3Config.S3ObjectBaseURL + fileName }
                }
            }
        ).catch(err => console.log(err));
        return result;
    }


    const handleInput = (e) => {
        var id = e.target.id;
        // if (id === "countryId")
        //     resetStateList(e);
        // if (id === "stateId")
        //     resetCityList(e);
        if (id === "vendorName") {
            // var vendorValue = e.target.value.replace(new RegExp(/[^a-zA-Z0-9_\s]*$/, 'ig'), "");
            // e.target.value = vendorValue;
            vendorValidationCheck(e.target.value);
        }
        if (id === "vendorLogo") {
            const [img] = e.target.files;
            setVendorLogo(URL.createObjectURL(img));
            fileUpload(e).then(
                (response) => {
                    value = response.s3FileUrl;
                    setVendorPrimaryInfo({ ...vendorPrimaryInfo, [id]: value });
                }).catch(err => console.log(err));
        }

        if (id === "phoneNumber") {
            var phoneNumberValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = phoneNumberValue;
        }
        if (id === "keyContact") {
            var keyContactValue = e.target.value.replace(new RegExp(/[^a-zA-Z0-9_\s]*$/, 'ig'), "");
            e.target.value = keyContactValue;
        }
        if (id === "zipCode") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
        }
        if (id === "poBoxNo") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
        }
        // if (id === "ssnNumber") {
        //     var ssnNumberValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
        //     e.target.value = ssnNumberValue;
        // }
        if (id === "stateId") {
            resetCityList(e);
          }
          if (id === "countryId") {
            resetStateList(e);            
            resetCountryCodeList(e);
          }
        if (id === "governmentIssuedUniqueIdentifierTypeId") {
            if (e.target.value != 0) {
                $("#governmentIssuedUniqueIdentifierNumber").prop('disabled', false);
                var identifierValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
                e.target.value = identifierValue;
            }
            else {
                $('#governmentIssuedUniqueIdentifierNumber').val('');
                $("#governmentIssuedUniqueIdentifierNumber").prop('disabled', true);
            }
        }
        // if (id === "natureOfBusiness") {
        //     var natureOfBusinessValue = e.target.value.replace(new RegExp(/[^a-zA-Z0-9_\s]*$/, 'ig'), "");
        //     e.target.value = natureOfBusinessValue;
        // }

        var value = e.target.value;
        setVendorPrimaryInfo({ ...vendorPrimaryInfo, [id]: value });
        console.log(vendorPrimaryInfo);
        checkValidation(e.target.id);
    }

    const checkValidation = (id) => {
        if (id === "" || id === undefined) {
            return allcheckValidation()
        }
        else {
            return singlecheckValidation(id)
        }
    }
    const singlecheckValidation = (id) => {
        var flag = true;
        var single_flaglist = []

        var obj = {
            "vendorName": document.getElementById("vendorName").value,
            "phoneNumber": document.getElementById("phoneNumber").value,
            "emailAddress": document.getElementById("emailAddress").value,
            "website": document.getElementById("website").value,
            "keyContact": document.getElementById("keyContact").value,
            "keyContactEmail": document.getElementById("keyContactEmail").value,
            "countryId": document.getElementById("countryId").value,
            "cityId": document.getElementById("cityId").value,
            "stateId": document.getElementById("stateId").value,
            // "zipCode": document.getElementById("zipCode").value,
            // "ssnNumber": document.getElementById("ssnNumber").value, ,
            "address": document.getElementById("address").value,
            "countryPhoneCodeId": document.getElementById("countryPhoneCodeId").value
        }
        if (id == "vendorName") {
            if (!obj.vendorName.trim()) {
                $("#existingVendor").hide();
                $("#vendornameerror").show();
                $("#vendorName").addClass("error-shadow")

                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                flag = vendorExists
                if (flag === true) {
                    $("#vendorName").addClass("error-shadow");
                    flag = false
                    single_flaglist.push(flag);
                    return;
                }
                else {
                    flag = true;
                    single_flaglist.push(flag);
                    if ($("#vendorName").hasClass('error-shadow'))
                        $("#vendorName").removeClass('error-shadow');
                }
                $("#vendornameerror").hide();
            }
        }
        const websiteregex = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
        if (id == "website") {
            if (!obj.website.trim()) {
                $("#websiteerror").show();
                $("#website").addClass("error-shadow");

                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else if (!websiteregex.test(obj.website)) {
                $("#websiteerror").show();
                $("#websiteerror").text("Please enter a valid website");
                $("#website").addClass("error-shadow");

                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#websiteerror").hide();
                if ($("#website").hasClass('error-shadow'))
                    $("#website").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }

        const phoneregex = /^\d{10}$/;
        if (id == "phoneNumber") {
            if (!obj.phoneNumber.trim()) {
                $("#phonenumbererror").show();
                $("#phoneNumber").addClass("error-shadow");

                flag = false;
                single_flaglist.push(flag);
                return;
            }
            // else if (!phoneregex.test(obj.phoneNumber)) {
                else if ((obj.phoneNumber.length > 13)) {
                $("#phonenumbererror").show();
                $("#phonenumbererror").text("Please enter the phone number less than 13 digit");
                // $("#phonenumbererror").text("Please enter the 10 digit phone number");
                $("#phoneNumber").addClass("error-shadow");
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#phonenumbererror").hide();
                if ($("#phoneNumber").hasClass('error-shadow'))
                    $("#phoneNumber").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (id == "emailAddress") {
            if (!obj.emailAddress.trim()) {
                $("#emailerror").show();
                $("#emailAddress").addClass("error-shadow");
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else if (!regex.test(obj.emailAddress)) {
                $("#emailerror").show();
                $("#emailerror").text("Please enter a valid email address");
                $("#emailAddress").addClass("error-shadow");

                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#emailerror").hide();
                if ($("#emailAddress").hasClass('error-shadow'))
                    $("#emailAddress").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id === "keyContact") {
            if (!obj.keyContact.trim()) {
                $("#keyContacterror").show();
                $("#keyContact").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
            }
            else {
                $("#keyContacterror").hide();
                if ($("#keyContact").hasClass('error-shadow'))
                    $("#keyContact").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        //added
        if (id === "keyContactEmail") {
            if (!obj.keyContactEmail.trim()) {
                $("#keyContactEmailerror").show();
                flag = false;
                single_flaglist.push(flag);
                $("#keyContactEmail").addClass("error-shadow");
                return;
            }
            else if (!regex.test(obj.keyContactEmail)) {
                $("#keyContactEmailerror").show();
                $("#keyContactEmailerror").text("Please enter a valid email address");
                flag = false;
                single_flaglist.push(flag);
                $("#keyContactEmail").addClass("error-shadow");
                return;
            }
            else {
                $("#keyContactEmailerror").hide();
                if ($("#keyContactEmail").hasClass('error-shadow'))
                    $("#keyContactEmail").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "countryId") {
            if (obj.countryId == 0) {
                $("#countryerror").show();
                $("#countryId").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#countryerror").hide();
                if ($("#countryId").hasClass('error-shadow'))
                    $("#countryId").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "stateId") {
            if (obj.stateId == 0) {
                $("#stateerror").show();
                $("#stateId").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#stateerror").hide();
                if ($("#stateId").hasClass('error-shadow'))
                    $("#stateId").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "cityId") {
            if (obj.cityId == 0) {
                $("#cityerror").show();
                $("#cityId").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#cityerror").hide();
                if ($("#cityId").hasClass('error-shadow'))
                    $("#cityId").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "countryPhoneCodeId") {
            if (obj.countryPhoneCodeId == 0) {
              $("#countryPhoneCodeIderror").show();
              $("#countryPhoneCodeId").addClass("error-shadow")
              flag = false;
              single_flaglist.push(flag);
              return;
            }
            else {
              $("#countryPhoneCodeIderror").hide();
              if ($("#countryPhoneCodeId").hasClass('error-shadow'))
                $("#countryPhoneCodeId").removeClass('error-shadow');
      
              flag = true;
              single_flaglist.push(flag);
            }
          }

        // if (id == "zipCode") {
        //     if (!obj.zipCode.trim()) {
        //         $("#zipcodeerror").show();
        //         $("#zipCode").addClass("error-shadow")
        //         flag = false;
        //         single_flaglist.push(flag);
        //         return;
        //     }
        //     else {
        //         $("#zipcodeerror").hide();
        //         if ($("#zipCode").hasClass('error-shadow'))
        //             $("#zipCode").removeClass('error-shadow');
        //         flag = true;
        //         single_flaglist.push(flag);
        //     }
        // }
        // if (id == "natureOfBusiness") {
        //     if (!obj.natureOfBusiness.trim()) {
        //         $("#natureOfBusinesserror").show();
        //         $("#natureOfBusiness").addClass("error-shadow")
        //         flag = false;
        //         single_flaglist.push(flag);
        //         return;
        //     }
        //     else {
        //         $("#natureOfBusinesserror").hide();
        //         if ($("#natureOfBusiness").hasClass('error-shadow'))
        //             $("#natureOfBusiness").removeClass('error-shadow');
        //         flag = true;
        //         single_flaglist.push(flag);
        //     }
        // }
        // if (id == "vendorTypeId") {
        //     if (obj.vendorTypeId == 0) {
        //         $("#vendorTypeerror").show();
        //         $("#vendorTypeId").addClass("error-shadow")
        //         flag = false;
        //         single_flaglist.push(flag);
        //         return;
        //     }
        //     else {
        //         $("#vendorTypeerror").hide();
        //         if ($("#vendorTypeId").hasClass('error-shadow'))
        //             $("#vendorTypeId").removeClass('error-shadow');
        //         flag = true;
        //         single_flaglist.push(flag);
        //     }
        // }
        if (id == "address") {
            if (!obj.address.trim()) {
                $("#addresserror").show();
                $("#address").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                flag = true;
                single_flaglist.push(flag);
                if ($("#address").hasClass('error-shadow'))
                    $("#address").removeClass('error-shadow');
                $("#addresserror").hide();
            }
        }
        return single_flaglist;
    }

    const allcheckValidation = () => {
        var flag = true;
        var flaglist = []
        var obj = {
            "vendorName": document.getElementById("vendorName").value,
            "phoneNumber": document.getElementById("phoneNumber").value,
            "emailAddress": document.getElementById("emailAddress").value,
            "website": document.getElementById("website").value,
            "keyContact": document.getElementById("keyContact").value,
            "keyContactEmail": document.getElementById("keyContactEmail").value,
            "countryId": document.getElementById("countryId").value,
            "cityId": document.getElementById("cityId").value,
            "stateId": document.getElementById("stateId").value,
            // "zipCode": document.getElementById("zipCode").value,
            // "ssnNumber": document.getElementById("ssnNumber").value, ,
            "address": document.getElementById("address").value,
            "countryPhoneCodeId": document.getElementById("countryPhoneCodeId").value
        }
        if (!obj.vendorName.trim()) {
            $("#existingVendor").hide();
            $("#vendornameerror").show();
            $("#vendorName").addClass("error-shadow")

            flag = false;
            flaglist.push(flag);
        }
        else {
            flag = vendorExists
            if (flag === true) {
                $("#vendorName").addClass("error-shadow");
                flag = false
                flaglist.push(flag);
            }
            else {
                flag = true;
                flaglist.push(flag);
                if ($("#vendorName").hasClass('error-shadow'))
                    $("#vendorName").removeClass('error-shadow');
            }
            $("#vendornameerror").hide();
        }
        const websiteregex = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
        if (!obj.website.trim()) {
            $("#websiteerror").show();
            $("#website").addClass("error-shadow");
            flag = false;
            flaglist.push(flag);
        }
        else if (!websiteregex.test(obj.website)) {
            $("#websiteerror").show();
            $("#websiteerror").text("Please enter a valid website");
            $("#website").addClass("error-shadow");
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#websiteerror").hide();
            if ($("#website").hasClass('error-shadow'))
                $("#website").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        const phoneregex = /^\d{12}$/;
        if (!obj.phoneNumber.trim()) {
            $("#phonenumbererror").show();
            $("#phoneNumber").addClass("error-shadow");
            flag = false;
            flaglist.push(flag);
        }
        // else if (!phoneregex.test(obj.phoneNumber)) {
            else if ((obj.phoneNumber.length > 13)) {
            $("#phonenumbererror").show();
            // $("#phonenumbererror").text("Please enter the 10 digit phone number");
            $("#phonenumbererror").text("Please enter the phone number less than 13 digit");
            $("#phoneNumber").addClass("error-shadow");
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#phonenumbererror").hide();
            if ($("#phoneNumber").hasClass('error-shadow'))
                $("#phoneNumber").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!obj.emailAddress.trim()) {
            $("#emailerror").show();
            $("#emailAddress").addClass("error-shadow");
            flag = false;
            flaglist.push(flag);
        }
        else if (!regex.test(obj.emailAddress)) {
            $("#emailerror").show();
            $("#emailerror").text("Please enter a valid email address");
            $("#emailAddress").addClass("error-shadow");
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#emailerror").hide();
            if ($("#emailAddress").hasClass('error-shadow'))
                $("#emailAddress").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (!obj.keyContact.trim()) {
            $("#keyContacterror").show();
            $("#keyContact").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#keyContacterror").hide();
            if ($("#keyContact").hasClass('error-shadow'))
                $("#keyContact").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        //added
        if (!obj.keyContactEmail.trim()) {
            $("#keyContactEmailerror").show();
            flag = false;
            $("#keyContactEmail").addClass("error-shadow");
            flaglist.push(flag);
        }
        else if (!regex.test(obj.keyContactEmail)) {
            $("#keyContactEmailerror").show();
            $("#keyContactEmailerror").text("Please enter a valid email address");
            flag = false;
            $("#keyContactEmail").addClass("error-shadow");
            flaglist.push(flag);
        }
        else {
            $("#keyContactEmailerror").hide();
            if ($("#keyContactEmail").hasClass('error-shadow'))
                $("#keyContactEmail").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (obj.countryId == 0) {
            $("#countryerror").show();
            $("#countryId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#countryerror").hide();
            if ($("#countryId").hasClass('error-shadow'))
                $("#countryId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (obj.stateId == 0) {
            $("#stateerror").show();
            $("#stateId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#stateerror").hide();
            if ($("#stateId").hasClass('error-shadow'))
                $("#stateId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (obj.cityId == 0) {
            $("#cityerror").show();
            $("#cityId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#cityerror").hide();
            if ($("#cityId").hasClass('error-shadow'))
                $("#cityId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        // if (!obj.zipCode.trim()) {
        //     $("#zipcodeerror").show();
        //     $("#zipCode").addClass("error-shadow")
        //     flag = false;
        //     flaglist.push(flag);
        // }
        // else {
        //     $("#zipcodeerror").hide();
        //     if ($("#zipCode").hasClass('error-shadow'))
        //         $("#zipCode").removeClass('error-shadow');
        //     flag = true;
        //     flaglist.push(flag);
        // }       
        // if (obj.vendorTypeId == 0) {
        //     $("#vendorTypeerror").show();
        //     $("#vendorTypeId").addClass("error-shadow")
        //     flag = false;
        //     flaglist.push(flag);
        // }
        // else {
        //     $("#vendorTypeerror").hide();
        //     if ($("#vendorTypeId").hasClass('error-shadow'))
        //         $("#vendorTypeId").removeClass('error-shadow');
        //     flag = true;
        //     flaglist.push(flag);
        // }
        if (!obj.address.trim()) {
            $("#addresserror").show();
            $("#address").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            flag = true;
            flaglist.push(flag);
            if ($("#address").hasClass('error-shadow'))
                $("#address").removeClass('error-shadow');
            $("#addresserror").hide();
        }
        if (obj.countryPhoneCodeId == 0) {
            $("#countryPhoneCodeIderror").show();
            $("#countryPhoneCodeId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);     
          }
          else {
            $("#countryPhoneCodeIderror").hide();
            if ($("#countryPhoneCodeId").hasClass('error-shadow'))
              $("#countryPhoneCodeId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
      
          }
        return flaglist;
    }
    const resetStateList = (e) => {
        var id = e.target.value;
        var newStateList = statesMeta.filter((ele) => ele.countryId == id);
        setCurStatesMeta([...newStateList]);
    }
    useEffect(() => {
        const stateId = $("#stateId").val();
        var newCityList = citiesMeta.filter((ele) => ele.stateId == stateId);
        setCurCityList([...newCityList]);
    }, [curStatesMeta])

    const resetCityList = (e) => {
        var id = e.target.value;
        var newCityList = citiesMeta.filter((ele) => ele.stateId == id);
        setCurCityList([...newCityList]);
    }
    const resetCountryCodeList = (e) => {
        var id = e.target.value;
        if (id == "0") {
          setCurCountryPhoneCodeMeta([...countryphonecodeMeta]);
          return;
        }
        var newCodeList = countryphonecodeMeta.filter((ele) => ele.countryId == id);
        setCurCountryPhoneCodeMeta([...newCodeList]);
        // $("#countryPhoneCodeId").val(newCodeList[0].countryPhoneCodeId);
      }
      useEffect(() => {
        if (curCountryphonecodeMeta.length == 1) {
          $("#countryPhoneCodeId").val(curCountryphonecodeMeta[0]?.countryPhoneCodeId);
          singlecheckValidation("countryPhoneCodeId");
    
        }
        else {
          $("#countryPhoneCodeId").val(0);
        }
    
      }, [curCountryphonecodeMeta])
     // Certification Summary//
     const uploadCertificateFile = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        // if (id == "certificationFiles") {
        //     if (value == "" || value == undefined || value == null) {
        //         $("#certificationFileserror").show();
        //         $("#certificationFiles").addClass("error-shadow")
        //     }
        //     else {
        //         $("#certificationFileserror").hide();
        //         if ($("#certificationFiles").hasClass('error-shadow'))
        //             $("#certificationFiles").removeClass('error-shadow');
        //     }
        // }
        fileUpload(e).then(
            (response) => {
                setCertificateFile(response.s3FileUrl);
            }).catch(err => console.log(err));
    }
     const EditCertification = (e) => {
        let dataFor = e.target.getAttribute("data-for");
        let dataId = curCertId;
        let value = "";
        if (dataFor === "certificationFiles") {
            fileUpload(e).then(
                (response) => {
                    var updCert = response.s3FileUrl;
                    setCertificateFile(updCert);
                    const newCert = certificationInfo.map(obj => {
                        if (obj.id == dataId) {
                            return { ...obj, [dataFor]: updCert };
                        }
                        return obj;
                    });
                    setCertificationInfo([...newCert]);
                    setCurCert([...newCert]);
                }).catch(err => console.log(err));
        } else {
            value = e.target.value;
            const newCert = certificationInfo.map(obj => {
                if (obj.id == dataId) {
                    return { ...obj, [dataFor]: value };
                }
                return obj;
            });
            setCertificationInfo([...newCert]);
            setCurCert([...newCert]);
        }
    }
    const addCertification = () => {

        let obj = {
            id: certificationInfo.length + 1,
            certificationTitle: document.getElementById('certificationTitle').value,
            //certificationOrganization: document.getElementById('certificationOrganization').value,
            certificationFiles: certificateFile//document.getElementById('certificationFiles').value,
            //certificationCompletionDate: document.getElementById('certificationCompletionDate').value
        }

        var flaglist = certificateValidation();
        if (flaglist.includes(false)) {
            var flag = false;
        }
        else {
            var flag = true;
        }
        if (flag) {
            setCertificationInfo([...certificationInfo, obj]);
            $("#viewCertificationDiv").removeClass("hide");
            $("#certificateImg").hide();
            document.getElementById("addCertificationForm").reset();
            $("#AddCertification .close").trigger('click');
            $("#vendor_TLC").hide();
        }

    }

    const certificatevalid = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        if (id == "certificationTitle") {
            if (value == "" || value == null || value == undefined) {
                $("#certificationTitleerror").show();
                $("#certificationTitle").addClass("error-shadow")

            }
            else {
                $("#certificationTitleerror").hide();
                if ($("#certificationTitle").hasClass('error-shadow'))
                    $("#certificationTitle").removeClass('error-shadow');
            }
        }
        // if (id == "certificationCompletionDate") {
        //     if (value == "" || value == null || value == undefined) {
        //         $("#certificationCompletionDateerror").show();
        //         $("#certificationCompletionDate").addClass("error-shadow")

        //     }
        //     else {
        //         $("#certificationCompletionDateerror").hide();
        //         if ($("#certificationCompletionDate").hasClass('error-shadow'))
        //             $("#certificationCompletionDate").removeClass('error-shadow');
        //     }
        // }
        // if (id == "certificationOrganization") {
        //     if (value == "" || value == null || value == undefined) {
        //         $("#certificationOrganizationerror").show();
        //         $("#certificationOrganization").addClass("error-shadow")

        //     }
        //     else {
        //         $("#certificationOrganizationerror").hide();
        //         if ($("#certificationOrganization").hasClass('error-shadow'))
        //             $("#certificationOrganization").removeClass('error-shadow');
        //     }
        // }

    }

    const certificateValidation = () => {
        var flag = true;
        var flaglist = []
        var obj = {
            certificationTitle: document.getElementById("certificationTitle").value,
           // certificationCompletionDate: document.getElementById('certificationCompletionDate').value,
           // certificationOrganization: document.getElementById("certificationOrganization").value,
            certificationFiles: document.getElementById("certificationFiles").value
        }

        if (!obj.certificationTitle) {
            $("#certificationTitleerror").show();
            $("#certificationTitle").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#certificationTitleerror").hide();
            if ($("#certificationTitle").hasClass('error-shadow'))
                $("#certificationTitle").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);

        }
        // if (!obj.certificationCompletionDate) {
        //     $("#certificationCompletionDateerror").show();
        //     $("#certificationCompletionDate").addClass("error-shadow")

        //     flag = false;
        //     flaglist.push(flag);

        // }
        // else {
        //     $("#certificationCompletionDateerror").hide();
        //     if ($("#certificationCompletionDate").hasClass('error-shadow'))
        //         $("#certificationCompletionDate").removeClass('error-shadow');

        //     flag = true;
        //     flaglist.push(flag);

        // }
        // if (!obj.certificationOrganization) {
        //     $("#certificationOrganizationerror").show();
        //     $("#certificationOrganization").addClass("error-shadow")
        //     flag = false;
        //     flaglist.push(flag);

        // }
        // else {
        //     $("#certificationOrganizationerror").hide();
        //     if ($("#certificationOrganization").hasClass('error-shadow'))
        //         $("#certificationOrganization").removeClass('error-shadow');

        //     flag = true;
        //     flaglist.push(flag);

        // }
        // if (!obj.certificationFiles) {
        //     $("#certificationFileserror").show();
        //     $("#certificationFiles").addClass("error-shadow")
        //     flag = false;
        //     flaglist.push(flag);

        // }
        // else {
        //     $("#certificationFileserror").hide();
        //     if ($("#certificationFiles").hasClass('error-shadow'))
        //         $("#certificationFiles").removeClass('error-shadow');

        //     flag = true;
        //     flaglist.push(flag);

        // }
        return flaglist;
    }
    const handlecertificationdelete = (id) => {
        const deleted = certificationInfo.filter((exp) => exp.id !== id);
        setCertificationInfo(deleted);
        $("#vendor_TLC").show();
    }
    function vendorValidationCheck(vendorname) {
        // vendorname = JSON.stringify(vendorname).replaceAll('"', '')
        logger.verbose("Vendor name Validation", "VendorRegistration Component", "vendorValidationCheck", "GET", "Vendor name Validation Check", `vendorName=${vendorname}`);
        axios.get(`${VendorNameCheckAPI}?vendorName=${vendorname}`)
            .then(response => {
                if (response.data === true) {
                    $("#vendorName").addClass("error-shadow");
                    $("#vendornameerror").hide()
                    $("#existingVendor").show();

                    setVendorExists(true);

                    logger.verbose("Vendor name Validation", "VendorRegistration Component", "vendorValidationCheck", "GET", "Vendor name Validation Check API True successfull", JSON.stringify(response.data));
                    return response.data
                }
                else {
                    if ($("#vendorName").hasClass("error-shadow"))
                        $("#vendorName").removeClass("error-shadow");
                    $("#existingVendor").hide();
                    setVendorExists(false)
                    return response.data
                }

            })

            .catch(error => {
                console.error('There was an error!', error);
                logger.error("Vendor name Validation", "VendorRegistration Component", "vendorValidationCheck", "GET", "Vendor name Validation Check API Failed", JSON.stringify(error));
            });

    }
    const submitVendorRegistration = () => {
        $(".finish-btn").addClass('disabled');
        vendorPrimaryInfo.countryPhoneCodeId=vendorPrimaryInfo.countryPhoneCodeId?vendorPrimaryInfo.countryPhoneCodeId:document.getElementById("countryPhoneCodeId").value;
        vendorPrimaryInfo.stateId=vendorPrimaryInfo.stateId?vendorPrimaryInfo.stateId:document.getElementById("stateId").value;
        vendorPrimaryInfo.cityId=vendorPrimaryInfo.cityId?vendorPrimaryInfo.cityId:document.getElementById("cityId").value;
        const vendor_Primary_Info = [];
        vendor_Primary_Info.push(vendorPrimaryInfo);
        const objdata = {
            "vendorPrimaryInfo": vendor_Primary_Info,
            "vendorExperienceInfo": experienceInfo,
            "vendorindustryExperienceInfo": industryExperienceInfo,
            "VendorCertificationInfo": certificationInfo
        }
        console.log(objdata);
        console.log(JSON.stringify(objdata));
        // logger.verbose("Vendor Registration", "Vendor Registration Component", "submitVendorRegistration", "POST", "Vendor Member Registration object Created", JSON.stringify(objdata));
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
        }
        axios.post(VendorRegistrationAPI, objdata, {
            headers: headers
        }).then(response => {
            if (response.data.status === 200) {
                // sendMail();                
                swal("Thank you for registering!", "Please confirm your Registration by verifying the email.", "success", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } });
                // logger.verbose("CaasMember Registration", "CaasMember Registration Component", "submitcioregistration", "POST", "Caas Member Registration API response successfull", JSON.stringify(response.data));
            }
            else {
                swal("Vendor Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })
                // logger.error("CaasMember Registration", "CaasMember Registration Component", "submitcioregistration", "POST", "Caas Member Registration API response Failed", JSON.stringify(response.data));
            }
        }).catch(error => {
            // logger.error("CaasMember Registration Failed", "CaasMember Registration Component", "submitcioregistration", "POST", "Caas Member Registration API response Failed", JSON.stringify(error));
            swal("Vendor Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })
        });
    }

    return (
        <div className="sidebar-icon-only with-welcome-text" data-new-gr-c-s-check-loaded="14.1071.0" data-gr-ext-installed="">
            <div className="container-scroller">
                <AuthHeader />
            </div>
            <div className="container-fluid page-body-wrapper">
                <nav className="sidebar sidebar-offcanvas" id="VendorRegistrationsidebar">
                    <ul className="nav" id="sideNavTabs">
                        <li className="nav-item title-card mt_10" id="cio_reg"><a className="nav-link text-center pl_2 br-btm" href="#"><span className="menu-title2 sidebar-title main-title">{t('vendorRegistration')}</span></a></li>
                        <li className="nav-item active current"><a className="nav-link" id="steps-uid-0-t-0" href="#steps-uid-0-h-0" aria-controls="steps-uid-0-p-0"> <i className="mdi mdi-account-check menu-icon"></i><span className="menu-title1 sidebar-title" aria-controls="steps-uid-0-p-0">Primary Information</span> </a> </li>
                        <li className="nav-item disabled-wizard"><a className="nav-link" id="steps-uid-0-t-1" href="#steps-uid-0-h-1" aria-controls="steps-uid-0-p-1"><i className="mdi mdi-account-star menu-icon"></i><span className="menu-title3 sidebar-title" aria-controls="steps-uid-0-p-1">Experience</span></a></li>
                        <li className="nav-item disabled-wizard"><a className="nav-link" id="steps-uid-0-t-3" href="#steps-uid-0-h-3" aria-controls="steps-uid-0-p-3"><i className="mdi mdi-file-certificate menu-icon"></i><span className="menu-title6 sidebar-title" aria-controls="steps-uid-0-p-3">{t('certification')}</span></a></li>
                        <li className="nav-item disabled-wizard"><a className="nav-link" id="steps-uid-0-t-2" href="#steps-uid-0-h-2" aria-controls="steps-uid-0-p-2"><i className="fa fa-eye menu-icon"></i><span className="menu-title8 sidebar-title" aria-controls="steps-uid-0-p-2">{t('preview')}</span></a></li>
                    </ul>
                </nav>

                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="col-12">
                                    <div className="row" id="primaryInfoDiv">
                                        <section className="body current mainSection" id="steps-uid-0-p-0" role="tabpanel" aria-labelledby="steps-uid-0-h-0" aria-hidden="false">
                                            <div className="col-md-12 grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className="card-title">
                                                            Primary Information
                                                            <a className="add_pi btn-primary add-pi float-right btn-rounded btn-icon" id="addpi_btn">
                                                                <i className="ti-plus"></i>
                                                            </a>
                                                        </h4>
                                                        <div className="hide" id="primaryInfoInnerDiv">
                                                            <div className="row mb-0">
                                                                <div className="form-group col-12 col-sm-6">
                                                                    <label>{t('vendorName')} <span className="error">*</span></label>
                                                                    <input type="text" className="form-control" placeholder={t('vendorName')} id="vendorName" onChange={handleInput} />
                                                                    <label id="vendornameerror" className="error hide">Vendor name is required</label>
                                                                    <label id="existingVendor" className="error hide">Vendor already exists</label>
                                                                </div>
                                                                <div className="form-group col-12 col-sm-6">
                                                                    <label>{t('website')} <span className="error">*</span></label>
                                                                    <input type="text" className="form-control" placeholder={t('website')} id="website" onChange={handleInput} />
                                                                    <label id="websiteerror" className="error hide">Website is required</label>
                                                                </div>
                                                                <div className="form-group col-12 col-sm-6">

                                                                    <label>{t('uploadVendorLogo')}</label>
                                                                    <input className="form-control" type="file" id="vendorLogo" onChange={handleInput} />

                                                                </div>
                                                                <div className="form-group col-12 col-sm-6">
                                                                    <label>{t('email')} <span className="error">*</span></label>
                                                                    <input type="email" className="form-control" placeholder={t('email')} id="emailAddress" onChange={handleInput} />
                                                                    <label id="emailerror" className="error hide">Email Id is required</label>
                                                                </div>
                                                                {/* <div className="form-group col-12 col-sm-6">
                                                                    <label>{t('phone')} <span className="error">*</span></label>
                                                                    <input type="text" className="form-control" placeholder={t('phone')} id="phoneNumber" maxLength="10" onChange={handleInput} />
                                                                    <label id="phonenumbererror" className="error hide">Phone Number is required</label>
                                                                </div> */}

                                                                <div className="form-group col-12 col-sm-6">
                                                                    <label>{t('phone')} <span className="error">*</span></label>
                                                                    {/* <input type="number" className="form-control" placeholder={t('phone')} id="phoneNumber" min="0" maxLength="10" onChange={handleInput} /> */}
                                                                    {/* <input type="text" className="form-control" placeholder={t('phone')} id="phoneNumber" maxLength="10" onChange={handleInput} /> */}
                                                                    <div className="col-12 col-sm-12 d-flex">
                                                                        <select className="form-select w-20" id="countryPhoneCodeId" onChange={handleInput}>
                                                                            <option value="0">Code</option>
                                                                            {
                                                                                curCountryphonecodeMeta.length != 0 ? (
                                                                                    curCountryphonecodeMeta.map((ele, index) => (
                                                                                        <option key={index} value={ele.countryPhoneCodeId}>{ele.countryPhoneCode} {ele.countryCode}</option>
                                                                                    ))
                                                                                ) : null
                                                                            }
                                                                        </select>
                                                                        <input type="text" className="form-control" placeholder={t('mobilePhone')} id="phoneNumber" maxLength="12" onChange={handleInput} />
                                                                    </div>
                                                                    <label id="countryPhoneCodeIderror" className="error hide">Phone Code is required</label>&nbsp;
                                                                    <label id="phonenumbererror" className="error hide">Phone Number is required</label>
                                                                </div>






                                                                <div className="form-group col-12 col-sm-6">
                                                                    <label>{t('keyContact')} <span className="error">*</span></label>
                                                                    <input type="text" className="form-control" placeholder={t('keyContact')} id="keyContact" onChange={handleInput} />
                                                                    <label id="keyContacterror" className="error hide">Key Contact is required</label>
                                                                </div>
                                                                <div className="form-group col-12 col-sm-6">
                                                                    <label>{t('keyContactEmail')} <span className="error">*</span></label>
                                                                    <input type="text" className="form-control" placeholder={t('keyContactEmail')} id="keyContactEmail" onChange={handleInput} />
                                                                    <label id="keyContactEmailerror" className="error hide">Key Contact Email is required</label>
                                                                </div>
                                                                <div className="form-group col-12 col-sm-6">
                                                                    {/* <label>{t('ssnNumber')}</label> */}
                                                                    {/* <input type="text" className="form-control" placeholder={t('ssnNumber')} id="ssnNumber" maxLength="9" onChange={handleInput} /> */}
                                                                    <label>Registration Number</label>
                                                                    <div className="col-12 col-sm-12 d-flex">
                                                                        <select className="form-select w-20" id="governmentIssuedUniqueIdentifierTypeId" onChange={handleInput}>
                                                                            <option value="0">Type</option>
                                                                            {
                                                                                govissueduniqueIdentifierTypesMeta.length != 0 ? (
                                                                                    govissueduniqueIdentifierTypesMeta.map((ele, index) => (
                                                                                        <option key={index} value={ele.governmentIssuedUniqueIdentifierTypeId}>{ele.governmentIssuedUniqueIdentifierTypeName}</option>
                                                                                    ))
                                                                                ) : null
                                                                            }
                                                                        </select>
                                                                        <input type="text" className="form-control" placeholder="Registration Number" id="governmentIssuedUniqueIdentifierNumber" maxLength="9" onChange={handleInput} disabled />
                                                                    </div>
                                                                    <label id="ssnNoerror" className="error hide">SSN is required</label>
                                                                </div>
                                                                <div className="form-group col-12 col-sm-6">
                                                                    <label>{t('country')} <span className="error">*</span></label>
                                                                    <select className="form-select" id="countryId" onChange={handleInput}>
                                                                        <option value="0" key={0} > -- {t('selectCountry')} -- </option>
                                                                        {
                                                                            countriesMeta.length != 0 ? (
                                                                                countriesMeta.map((ele, index) => (
                                                                                    <option key={index} value={ele.countryId}>{ele.countryName}</option>
                                                                                ))
                                                                            ) : null
                                                                        }
                                                                    </select>
                                                                    <label id="countryerror" className="error hide">Country is required</label>
                                                                </div>

                                                                <div className="form-group col-12 col-sm-3">
                                                                    <label>{t('state')} <span className="error">*</span></label>
                                                                    <select className="form-select" id="stateId" onChange={handleInput}>
                                                                        <option value="0" key={0} > -- {t('selectState')} -- </option>
                                                                        {
                                                                            curStatesMeta.length != 0 ? (
                                                                                curStatesMeta.map((ele, index) => (
                                                                                    <option key={index} value={ele.stateId}>{ele.stateName}</option>
                                                                                ))
                                                                            ) : null
                                                                        }
                                                                    </select>
                                                                    <label id="stateerror" className="error hide">State is required</label>
                                                                </div>
                                                                <div className="form-group col-12 col-sm-3">
                                                                    <label>{t('city')} <span className="error">*</span></label>
                                                                    <select className="form-select" id="cityId" onChange={handleInput}>
                                                                        <option key={0} value="0" > -- {t('selectCity')} -- </option>
                                                                        {
                                                                            curCityList.length != 0 ? (
                                                                                curCityList.map((ele, index) => (
                                                                                    <option key={index} value={ele.cityId}>{ele.cityName}</option>
                                                                                ))
                                                                            ) : null
                                                                        }
                                                                    </select>
                                                                    <label id="cityerror" className="error hide">City is required</label>
                                                                </div>
                                                                <div className="form-group col-12 col-sm-3">
                                                                    <label>{t('zipCode')} </label>
                                                                    <input type="text" className="form-control" placeholder={t('zipCode')} id="zipCode" maxLength="6" onChange={handleInput} />
                                                                </div>
                                                                <div className="form-group col-12 col-sm-3">
                                                                    <label>PO Box Number</label>
                                                                    <input type="text" className="form-control" placeholder="PO Box Number" id="poBoxNo" maxLength="6" onChange={handleInput} />
                                                                </div>
                                                              
                                                                {/* <div className="form-group col-12 col-sm-6">
                                                                    <label>{t('natureOfBusiness')} <span className="error">*</span></label>
                                                                    <input type="text" className="form-control" placeholder={t('natureOfBusiness')} id="natureOfBusiness" onChange={handleInput} />
                                                                    <label id="natureOfBusinesserror" className="error hide">Nature of Business is required</label>
                                                                </div> */}
                                                                <div className="form-group col-6 col-sm-6">
                                                                    <label>{t('address')} <span className="error">*</span></label>
                                                                    <textarea className="form-control" placeholder={t('address')} id="address" onChange={handleInput}></textarea>
                                                                    <label id="addresserror" className="error hide">Vendor Address is required</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="text-center mt_50 disp_hide" id="dispshow">
                                                            <img src={vendorRegIcon} className="img-responsive pi-image" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>

                                    <div className="row" id="experienceDiv">
                                        <section id="steps-uid-0-p-1" className="mainSection body" role="tabpanel" aria-labelledby="steps-uid-0-h-1" aria-hidden="true" style={{ display: "none" }}>
                                            <div className="col-md-12 grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className="card-title">
                                                            Experience
                                                            <span className="fw-noraml">  ({t('minimum2SkillsRequired')})</span>
                                                            <a href="#" id="addExperienceBtn" data-bs-toggle="modal" data-bs-target="#AddExperience" className="btn-primary add-pi float-right btn-rounded btn-icon">
                                                                <i className="ti-plus"></i>
                                                            </a>
                                                        </h4>
                                                        <div className="hide" id="viewexperienceDiv">
                                                            <div className="row">
                                                                {
                                                                    experienceInfo.length != 0 ? (
                                                                        experienceInfo.map((ele, index) => (
                                                                            <>
                                                                                <div className="col-6" key={index}>
                                                                                    <div className="cardPad card-body border border-radius mt-0 text-left box-shadow">
                                                                                        <div className="d-flex justify-content-between align-items-start">
                                                                                            <div className="widgetCont mt-0">
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10 mr_50"><b className="float-start">{t('skill')}:</b> {skillRef[ele.experienceSkillId]}</div>
                                                                                                </div>
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10"><b className="float-start">{t('softwareVersion')}:</b> {skillVersionRef[ele.experienceSoftwareVersionId]}</div>
                                                                                                </div>
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10"><b className="float-start">{t('lastUsed')}:</b> {ele.experienceLastUsed}</div>
                                                                                                </div>
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10"><b className="float-start">{t('experience')}:</b> {ele.experienceYears} {t('years')}</div>
                                                                                                </div>
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10"><b className="float-start">{t('implementationSize')}:</b> {ele.experienceImplementationSize} {t('users')}</div>
                                                                                                </div>
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10"><b className="float-start">{t('experienceLevel')}:</b> {ele.experienceLevelName}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="widgetHead float-right">
                                                                                                <a href="#" className="delete-experience" > <i className="fa-solid fa-trash fa-lg float-end text-muted pt-2" title="Delete Experience" data-bs-toggle="modal" data-bs-target="#DeleteExperience" id="deleteExperienceBtn" data-for={ele.id} onClick={() => {
                                                                                                    handleexpdelete(ele.id)
                                                                                                }}></i></a>&nbsp;
                                                                                                <a href="#" className="edit-profile" > <i className="fa fa-pencil fa-lg float-end text-muted pt-2" title="Edit Experience" data-bs-toggle="modal" data-bs-target="#EditExperience" id="editExperienceBtn" data-for={ele.id} onClick={() => {
                                                                                                    setcurCompId(ele.id)
                                                                                                }}></i></a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ))
                                                                    ) : (
                                                                        <p>{t('noRecordsAvailable')}</p>
                                                                    )
                                                                }
                                                            </div>
                                                            <h4 className="card-title mt_50">
                                                                Industry
                                                            </h4>
                                                            <a href="#" className="edit-profile"> <i className="fa fa-pencil fa-lg float-end text-muted pt-4 pr_30" title="Edit" data-bs-toggle="modal" data-bs-target="#Industry"></i></a>
                                                            <div className="cardPad card-body box-shadow pb_30">
                                                                {
                                                                    industryExperiencedisplay.length != 0 ? (
                                                                        industryExperiencedisplay.map((ele) => (
                                                                            industrydetails.map((exp) => (
                                                                                ele == exp.industryId ?
                                                                                    <button type="button" className="btn industry-list btn-outline-secondary btn-sm ml_20" value={exp.industryId}> {exp.industryName} </button> :
                                                                                    <></>
                                                                            ))
                                                                        ))
                                                                    ) : (
                                                                        <>
                                                                            <p>{t('noRecordsAvailable')}</p>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="text-center mt_20" id="experienceImg">
                                                            <img src={experienceImg} className="img-responsive pi-image" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>


                                    <div className="row" id="certificationDiv">
                                                <section id="steps-uid-0-p-3" className="mainSection body" role="tabpanel" aria-labelledby="steps-uid-0-h-3" aria-hidden="true" style={{ display: "none" }}>
                                                    <div className="col-md-12 grid-margin stretch-card">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h4 className="card-title">
                                                                    {t('hcertification')}<a href="#" id="vendor_TLC" className="btn-primary add-pi float-right btn-rounded btn-icon" data-bs-toggle="modal" data-bs-target="#AddCertification">
                                                                        <i className="ti-plus"></i>
                                                                    </a>
                                                                </h4>
                                                                <div className="hide" id="viewCertificationDiv">
                                                                    <div className="row">
                                                                        {
                                                                            certificationInfo.length != 0 ? (
                                                                                certificationInfo.map((ele) => (
                                                                                    <>
                                                                                        <div className="col-6">
                                                                                            <div className="cardPad card-body border border-radius mt-0 text-left box-shadow">
                                                                                                <div className="d-flex justify-content-between align-items-start">
                                                                                                    <div className="widgetCont mt-0">
                                                                                                        <div className="prefill">
                                                                                                            <div className="text-left mb_10 mr_50"><b className="float-start">{t('certificationTitle')}:</b> {ele.certificationTitle} </div>
                                                                                                        </div>
{/* 
                                                                                                        <div className="prefill">
                                                                                                            <div className="text-left mb_10"><b className="float-start">{t('completiondate')}:</b> {ele.certificationCompletionDate} </div>
                                                                                                        </div>
                                                                                                        <div className="prefill">
                                                                                                            <div className="text-left mb_10"><b className="float-start">{t('organization/institute')}:</b> {ele.certificationOrganization} </div>
                                                                                                        </div> */}
                                                                                                        <div className="prefill">
                                                                                                            <div className="text-left mb_10"><b className="float-start">{t('certification')}:</b>

                                                                                                                {
                                                                                                                    ele.certificationFiles == "" ?
                                                                                                                        (<label>Not Uploaded</label>) :
                                                                                                                        (<a href={ele.certificationFiles} target="_blank" className="text-link">{ele?.certificationFiles?.split("/")[3]?.split(S3Config.S3ObjectDelimiter)[0]}</a>)
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="widgetHead float-right">
                                                                                                        <a href="#" className="delete-certificate" > <i className="fa-solid fa-trash fa-lg float-end text-muted pt-2" title="Delete Certificate" data-bs-toggle="modal" data-bs-target="#DeleteCertificate" id="deleteCertificateBtn" data-for={ele.id} onClick={() => {
                                                                                                            handlecertificationdelete(ele.id)
                                                                                                        }}></i></a>
                                                                                                        <a href="#" className="edit-profile"> <i className="fa fa-pencil fa-lg float-end text-muted pt-2" title="Edit Certification" data-bs-toggle="modal" data-bs-target="#EditCertification" id="editCertificateBtn" data-for={ele.id} onClick={() => {
                                                                                                            setcurCertId(ele.id)
                                                                                                        }}></i></a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                ))
                                                                            ) : (
                                                                                <p>{t('noRecordsAvailable')}</p>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="text-center mt_50" id="certificateImg">
                                                                    <img src={certificateImg} className="img-responsive pi-image" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>

                                    <div className="row" id="previewDiv">
                                        <section id="steps-uid-0-p-2" className="mainSection body" role="tabpanel" aria-labelledby="steps-uid-0-h-2" aria-hidden="false" style={{ display: "none" }}>
                                            <div className="col-md-12 grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className="card-title">
                                                            {t('hpreview')}
                                                        </h4>
                                                        <div className="card-body  mt-0 text-left">
                                                            <div className="">
                                                                <div className="flex-row">
                                                                    <a className="position-relative text-link inline-block">
                                                                        <h4 className="profile-name pb_10">Primary Information</h4>
                                                                        <img src={vendorPrimaryInfo?.vendorLogo ? vendorPrimaryInfo?.vendorLogo : vendorRegIcon} alt="Vendor Image" className="img-lg mb-2" />
                                                                    </a>
                                                                    <div className="inline-block width_100 ps-4 mt_50">
                                                                        <div className="row">
                                                                            <div className="col-6">
                                                                                <div className="widgetCont mt-0">
                                                                                    <div className="prefill">
                                                                                        <div className="text-left mb_10 mr_20"><b className="float-start">{t('vendorName')}:</b> {vendorPrimaryInfo.vendorName}</div>
                                                                                    </div>
                                                                                    <div className="prefill">
                                                                                        <div className="text-left mb_10"><b className="float-start">{t('website')}:</b> {vendorPrimaryInfo.website}</div>
                                                                                    </div>
                                                                                    <div className="prefill">
                                                                                        <div className="text-left mb_10"><b className="float-start">{t('country')}:</b> {$("#countryId option:selected").text()}</div>
                                                                                    </div>
                                                                                    <div className="prefill">
                                                                                        <div className="text-left mb_10"><b className="float-start">{t('state')}:</b> {$("#stateId option:selected").text()}</div>
                                                                                    </div>
                                                                                    <div className="prefill">
                                                                                        <div className="text-left mb_10"><b className="float-start">{t('city')}:</b> {$("#cityId option:selected").text()} </div>
                                                                                    </div>
                                                                                    <div className="prefill">
                                                                                        <div className="text-left mb_10"><b className="float-start">{t('zipCode')}:</b> {vendorPrimaryInfo.zipCode} </div>
                                                                                    </div>
                                                                                    <div className="prefill">
                                                                                        <div className="text-left mb_10"><b className="float-start">Po Box Number:</b> {vendorPrimaryInfo.poBoxNo} </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div className="widgetCont mt-0">
                                                                                    <div className="prefill">
                                                                                        <div className="text-left mb_10 mr_20"><b className="float-start">{t('email')}:</b> {vendorPrimaryInfo.emailAddress}</div>
                                                                                    </div>
                                                                                    <div className="prefill">
                                                                                                <div className="text-left mb_10"><b className="float-start">Phone No:</b> {$("#countryPhoneCodeId option:selected").text().slice(0, 4)}{vendorPrimaryInfo.phoneNumber}</div>
                                                                                            </div>
                                                                                    <div className="prefill">
                                                                                        <div className="text-left mb_10 mr_20"><b className="float-start">{t('keyContact')}:</b> {vendorPrimaryInfo.keyContact}</div>
                                                                                    </div>
                                                                                    <div className="prefill">
                                                                                        <div className="text-left mb_10 mr_20"><b className="float-start">{t('keyContactEmail')}:</b> {vendorPrimaryInfo.keyContactEmail}</div>
                                                                                    </div>


                                                                                    <div className="prefill">
                                                                                        <div className="text-left mb_10"><b className="float-start">{t('address')} :</b> {vendorPrimaryInfo.address} </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <h4 className="card-title mt_50">
                                                                    Experience
                                                                </h4>
                                                            </div>

                                                            <div className="row">
                                                                {
                                                                    experienceInfo.length != 0 ? (
                                                                        experienceInfo.map((ele, index) => (
                                                                            <>
                                                                                <div className="col-6" key={index}>
                                                                                    <div className="cardPad card-body border border-radius mt-0 text-left box-shadow">
                                                                                        <div className="d-flex justify-content-between align-items-start">
                                                                                            <div className="widgetCont mt-0">
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10 mr_50"><b className="float-start">{t('skill')}:</b> {skillRef[ele.experienceSkillId]}</div>
                                                                                                </div>
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10"><b className="float-start">{t('softwareVersion')}:</b> {skillVersionRef[ele.experienceSoftwareVersionId]} </div>
                                                                                                </div>
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10"><b className="float-start">{t('lastUsed')}:</b> {ele.experienceLastUsed}</div>
                                                                                                </div>
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10"><b className="float-start">{t('experience')}:</b> {ele.experienceYears} {t('years')}</div>
                                                                                                </div>
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10"><b className="float-start">{t('implementationSize')}:</b> {ele.experienceImplementationSize} {t('users')}</div>
                                                                                                </div>
                                                                                                <div className="prefill">
                                                                                                    <div className="text-left mb_10"><b className="float-start">{t('experienceLevel')}:</b> {ele.experienceLevelName} </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="widgetHead float-right">
                                                                                                <a href="#" className="delete-experience" > <i className="fa-solid fa-trash fa-lg float-end text-muted pt-2" title="Delete Experience" data-bs-toggle="modal" data-bs-target="#DeleteExperience" id="deleteExperienceBtn" data-for={ele.id} onClick={() => {
                                                                                                    handleexpdelete(ele.id)
                                                                                                }}></i></a><a href="#" className="edit-profile" > <i className="fa fa-pencil fa-lg float-end text-muted pt-2" title="Edit Experience" data-bs-toggle="modal" data-bs-target="#EditExperience" id="editExperienceBtn" data-for={ele.id} onClick={() => {
                                                                                                    setcurCompId(ele.id)
                                                                                                }}></i></a></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ))
                                                                    ) : (
                                                                        <div className="cardPad card-body box-shadow pb_30"><p>{t('noRecordsAvailable')}</p></div>
                                                                    )
                                                                }
                                                            </div>
                                                            <h4 className="card-title mt_50">
                                                                {t('hindustry')}
                                                            </h4>
                                                            <a href="#" className="edit-profile"> <i className="fa fa-pencil fa-lg float-end text-muted pt-4 pr_30" title="Edit" data-bs-toggle="modal" data-bs-target="#Industry"></i></a>
                                                            <div className="cardPad card-body box-shadow pb_30">

                                                                {
                                                                    industryExperiencedisplay.length != 0 ? (
                                                                        industryExperiencedisplay.map((ele) => (
                                                                            industrydetails.map((exp) => (
                                                                                ele == exp.industryId ?
                                                                                    <button type="button" className="btn industry-list btn-outline-secondary btn-sm ml_20" value={exp.industryId}> {exp.industryName} </button> :
                                                                                    <></>
                                                                            ))
                                                                        ))
                                                                    ) : (
                                                                        <>
                                                                            <p>{t('noRecordsAvailable')}</p>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                            <h4 className="card-title mt_50">
                                                                        {t('hcertification')}
                                                                    </h4>
                                                                    <div className="row">
                                                                        {
                                                                            certificationInfo.length != 0 ? (
                                                                                certificationInfo.map((ele) => (
                                                                                    <>
                                                                                        <div className="col-6">
                                                                                            <div className="cardPad card-body border border-radius mt-0 text-left box-shadow">
                                                                                                <div className="d-flex justify-content-between align-items-start">
                                                                                                    <div className="widgetCont mt-0">
                                                                                                        <div className="prefill">
                                                                                                            <div className="text-left mb_10 mr_50"><b className="float-start">{t('certificationTitle')}:</b> {ele.certificationTitle} </div>
                                                                                                        </div>

                                                                                                        {/* <div className="prefill">
                                                                                                            <div className="text-left mb_10"><b className="float-start">{t('completiondate')}:</b> {ele.certificationCompletionDate} </div>
                                                                                                        </div>
                                                                                                        <div className="prefill">
                                                                                                            <div className="text-left mb_10"><b className="float-start">{t('organization/institute')}:</b> {ele.certificationOrganization} </div>
                                                                                                        </div> */}
                                                                                                        <div className="prefill">
                                                                                                            <div className="text-left mb_10"><b className="float-start">{t('certification')}:</b>
                                                                                                                {/* <a href={ele.certificationFiles} className="text-link">{ele?.certificationFiles?.split("/")[3]}</a> */}
                                                                                                                {
                                                                                                                    ele.certificationFiles == "" ?
                                                                                                                        (<label>Not Uploaded</label>) :
                                                                                                                        (<a href={ele.certificationFiles} className="text-link" target="_blank">{ele?.certificationFiles?.split("/")[3]?.split(S3Config.S3ObjectDelimiter)[0]}</a>)
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="widgetHead float-right">
                                                                                                        <a href="#" className="delete-certificate" > <i className="fa-solid fa-trash fa-lg float-end text-muted pt-2" title="Delete Certificate" data-bs-toggle="modal" data-bs-target="#DeleteCertificate" id="deleteCertificateBtn" data-for={ele.id} onClick={() => {
                                                                                                            handlecertificationdelete(ele.id)
                                                                                                        }}></i></a>
                                                                                                        <a href="#" className="edit-profile"> <i className="fa fa-pencil fa-lg float-end text-muted pt-2" title="Edit Certification" data-bs-toggle="modal" data-bs-target="#EditCertification" id="editCertificateBtn" data-for={ele.id} onClick={() => {
                                                                                                            setcurCertId(ele.id)
                                                                                                        }}></i></a></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                ))
                                                                            ) : (
                                                                                <div className="cardPad card-body box-shadow pb_30"><p>{t('noRecordsAvailable')}</p></div>
                                                                            )
                                                                        }
                                                                    </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>

                                    <div className="div-footer mt_200">
                                        <div className="float-right hide" id="navControls">
                                            <a href="#previous" className="disabled btn btn-warning btn-md previous-btn mr_10" id="previousli" onClick={showPreviousDiv}>{t('previous')}</a>
                                            <a href="#next" className="btn btn-warning btn-md next-btn mr_10" id="nextBtn" onClick={showNextDiv}>{t('next')}</a>
                                            <a href="#finish" className="btn btn-warning btn-md next-btn finish-btn" style={{ display: "none" }} id="finishli" onClick={submitVendorRegistration}  >{t('finish')}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div className="modal fade show" data-backdrop="static" data-keyboard="false" id="EditVendor">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title"></h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <img src={okImg} className="img-responsive completeimg" />
                                <h3 className="vendor_reg_confirmation_messg">
                                    {t('thankYouForRegistering')}.
                                </h3>
                                <h3 className="vendor_reg_confirmation_messg">
                                    {t('pleaseConfirmYourRegistrationByVerifyingTheEmail')}.
                                </h3>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Link to='/' className="btn btn-danger btn-sm">{t('close')}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="AddExperience" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('experience/skill')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <form id="addExperienceForm">
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('skill/softwareName')} <span className="error">*</span></label>
                                        <select className="form-select" id="experienceSkill" onChange={resetversionList}>
                                            <option value="0" key={0}>Select</option>
                                            {
                                                skilldetailsMeta.length != 0 ? (
                                                    skilldetailsMeta.map((ele, index) => (
                                                        <option key={index} value={ele.skillId}>{ele.skillName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="experienceSkillerror" className="error hide">Skill not selected</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('softwareVersion')} <span className="error">*</span></label>
                                        <select className="form-select" id="experienceSoftwareVersion" onChange={skillvalidation}>
                                            <option value="0" key={0}>Select</option>
                                            {
                                                curskillVersionList.length != 0 ? (
                                                    curskillVersionList.map((ele, index) => (
                                                        <option key={index} value={ele.skillVersionId}>{ele.skillVersionName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="experienceSoftwareVersionerror" className="error hide">Skill Version not selected</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('lastUsed')} <span className="error">*</span></label>
                                        <select className="form-select" id="experienceLastUsed" onChange={skillvalidation}>
                                            <option value="0" >{t('select')}</option>
                                            <option >2020</option>
                                            <option >2021</option>
                                            <option >2022</option>
                                        </select>
                                        <label id="experienceLastUsederror" className="error hide">Experience last used not selected</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('years')} <span className="error">*</span></label>
                                        <select className="form-select" id="experienceYears" onChange={skillvalidation}>
                                            <option value="0">{t('select')}</option>
                                            <option value="1">1 Years</option>
                                            <option value="2">2 Years</option>
                                            <option value="3">3 Years</option>
                                            <option value="4">4 Years</option>
                                            <option value="5">5 Years</option>
                                            <option value="6">6 Above</option>
                                        </select>
                                        <label id="experienceYearserror" className="error hide">Experience in years not selected</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('implementationSize')} <i className="fa fa-question-circle" title="How many end-users have experienced your skill?"></i> <span className="error">*</span></label>
                                        <input type="text" className="form-control" id="experienceImplementationSize" onChange={skillvalidation} />
                                        <label id="experienceImplementationSizeerror" className="error hide">Implementation Size is Required</label>

                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('experienceLevel')} <span className="error">*</span></label>
                                        <select className="form-select" id="experienceLevel" onChange={skillvalidation}>
                                            <option value="0" key={0}>{t('select')}</option>
                                            {
                                                skillexperiencelevelsMeta.length != 0 ? (
                                                    skillexperiencelevelsMeta.map((ele, index) => (
                                                        <option key={index} value={ele.skillExperienceId}>{ele.skillExperienceLevel}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="experienceLevelerror" className="error hide">Experience level not selected</label>

                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                            <a href="#" className="btn btn-warning btn-md next-btn" onClick={addExperience}>{t('save')}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="EditExperience" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('experience/skill')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <form>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('skill/softwareName')} <span className="error">*</span></label>
                                        <select className="form-select" data-for="experienceSkillId" value={curComp?.experienceSkillId} onChange={editExperience} >
                                            <option value="0" key={0}>Select</option>
                                            {
                                                skilldetailsMeta.length != 0 ? (
                                                    skilldetailsMeta.map((ele, index) => (
                                                        <option key={index} value={ele.skillId}>{ele.skillName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('softwareVersion')} <span className="error">*</span></label>
                                        <select className="form-select" data-for="experienceSoftwareVersionId" value={curComp?.experienceSoftwareVersionId} onChange={editExperience} >
                                            <option value="0" key={0}>Select</option>
                                            {
                                                curskillVersionList.length != 0 ? (
                                                    curskillVersionList.map((ele, index) => (
                                                        <option key={index} value={ele.skillVersionId}>{ele.skillVersionName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('lastUsed')} <span className="error">*</span></label>
                                        <select className="form-select" data-for="experienceLastUsed" value={curComp?.experienceLastUsed} onChange={editExperience}>
                                            <option >{t('select')}</option>
                                            <option >2020</option>
                                            <option >2021</option>
                                            <option >2022</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>Years</label>
                                        <select className="form-select" data-for="experienceYears" value={curComp?.experienceYears} onChange={editExperience}>
                                            <option >{t('select')}</option>
                                            <option value="1">1 Years</option>
                                            <option value="2">2 Years</option>
                                            <option value="3">3 Years</option>
                                            <option value="4">4 Years</option>
                                            <option value="5">5 Years</option>
                                            <option value="6">6 Above</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('implementationSize')}
                                            <i className="fa fa-question-circle" title="How many users have experienced your skill?"> <span className="error">*</span></i>
                                        </label>
                                        <input type="text" className="form-control" data-for="experienceImplementationSize" value={curComp?.experienceImplementationSize} onChange={editExperience} />
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('experienceLevel')} <span className="error">*</span></label>
                                        <select className="form-select" data-for="experienceLevel" value={curComp?.experienceLevel} onChange={editExperience}>
                                            <option value="0" key={0}>{t('select')}</option>
                                            {
                                                skillexperiencelevelsMeta.length != 0 ? (
                                                    skillexperiencelevelsMeta.map((ele, index) => (
                                                        <option key={index} value={ele.skillExperienceId}>{ele.skillExperienceLevel}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                            <a href="#" className="btn btn-warning btn-md next-btn" data-bs-dismiss="modal">{t('save')}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="Industry">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('industry')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputName1">{t('industry')} </label>
                                        <select className="form-select" multiple id="experienceIndustry" onChange={addUpdIndustry}>
                                            <option value="0">---Choose---</option>
                                            {
                                                industrydetails.length != 0 ? (
                                                    industrydetails.map((ele, index) => (
                                                        <option key={index} value={ele.industryId}>{ele.industryName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                            <a href="#" className="btn btn-warning btn-md next-btn" data-bs-dismiss="modal">{t('save')}</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade show" id="EditCertification" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content cardPad card-body border border-radius">
                            <div className="modal-header">
                                <h3 className="modal-title">{t('certification')}</h3>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('certificationTitle')} <span className="error">*</span></label>
                                        <input type="text" className="form-control" placeholder={t('certificationTitle')} data-for="certificationTitle" value={curCert?.certificationTitle} onChange={EditCertification} />
                                    </div>
                                    {/* <div className="form-group col-12 col-sm-12">
                                        <label>{t('completiondate')} <span className="error">*</span></label>
                                        <input type="date" className="form-control" data-for="certificationCompletionDate" value={curCert?.certificationCompletionDate} onChange={EditCertification} />
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('organization')} <span className="error">*</span></label>
                                        <input type="text" className="form-control" placeholder={t('organization')} data-for="certificationOrganization" value={curCert?.certificationOrganization} onChange={EditCertification} />
                                    </div> */}
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('uploadaCertification')} <span className="error">*</span></label>
                                        <input className="form-control" type="file" multiple="" data-for="certificationFiles" onChange={EditCertification} />
                                        {/* value={curCert?.certificationFiles} */}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                                <a href="#" className="btn btn-warning btn-md next-btn" data-bs-dismiss="modal">{t('save')}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade show" id="AddCertification" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content cardPad card-body border border-radius">
                            <div className="modal-header">
                                <h3 className="modal-title">{t('certification')}</h3>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <form id="addCertificationForm">
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('certificationTitle')} <span className="error">*</span></label>
                                            <input type="text" className="form-control" placeholder={t('certificationTitle')} id="certificationTitle" onChange={certificatevalid} />
                                            <label id="certificationTitleerror" className="error hide"> Certification title not provided</label>
                                        </div>

                                        {/* <div className="form-group col-12 col-sm-12">
                                            <label>{t('completiondate')} <span className="error">*</span></label>
                                            <input type="date" className="form-control" placeholder={t('completiondate')} id="certificationCompletionDate" onChange={certificatevalid} />
                                            <label id="certificationCompletionDateerror" className="error hide"> Certification completion date not provided</label>

                                        </div>
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('organization')} <span className="error">*</span></label>
                                            <input type="text" className="form-control" placeholder={t('organization')} id="certificationOrganization" onChange={certificatevalid} />
                                            <label id="certificationOrganizationerror" className="error hide">Certification organization not provided</label>

                                        </div> */}
                                        <div className="form-group col-12 col-sm-12">
                                            <label>{t('uploadaCertification')}</label>
                                            <input className="form-control" type="file" multiple="" id="certificationFiles" onChange={uploadCertificateFile} />
                                            <label id="certificationFileserror" className="error hide">Certification files not provided</label>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                                <a href="#" className="btn btn-warning btn-md next-btn" onClick={addCertification}>{t('save')}</a>
                            </div>
                        </div>
                    </div>
                </div>

        </div>
    )
}

export default VendorRegistration