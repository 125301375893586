import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import "../../assets/css/acknowledgements/main.css"
import $ from 'jquery'
import SignPad from './SignPad';

const acknowledgement = {
    "nda": "https://mycaas-test-bucket.s3.me-south-1.amazonaws.com/acknowledgements/NDA_General_caas.pdf",
    "coc": "https://mycaas-test-bucket.s3.me-south-1.amazonaws.com/acknowledgements/Code-of-conduct.pdf",
    "privacy": "https://mycaas-test-bucket.s3.me-south-1.amazonaws.com/acknowledgements/Privacy-policy_v2.pdf"
}
// "privacy": "https://mycaas-test-bucket.s3.me-south-1.amazonaws.com/acknowledgements/Privacy-policy.pdf", // old version - v1
export default function PDFViewer(props) {

    const { userData, type, clearAllCount, clearACkType } = props;

    const [mainImageUrl, setMainImageURL] = useState("");
    const { sendAckInfo } = props;
    const sendSignImgDataToParent = (index) => {
        setMainImageURL(index);
    };
    var fileUrl = "";
    if (type == 1) {
        fileUrl = acknowledgement.nda;
    }
    else if (type == 2) {
        fileUrl = acknowledgement.privacy;
    }
    else if (type == 3) {
        fileUrl = acknowledgement.coc;
    }

    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [showSignPicker, setShowSignPicker] = useState(false);
    const [ackInfo, setAckInfo] = useState([]);
    /*To Prevent right click on screen*/
    document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
    });

    /*When document gets loaded successfully*/
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
        setAgreementForUser();
    }
    function setAgreementForUser() {
        debugger
        // console.log(JSON.stringify(userData));
    }
    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    useEffect(() => {
        if (pageNumber > 1) {
            $("#previous-pdf-slide-btn").removeClass("hide");
            $("#next-pdf-slide-btn").removeClass("hide");
            $("#sign-btn").addClass("hide");
            setShowSignPicker(false);
            $("#move-first-pdf-slide-btn").removeClass("hide");
            $("#move-last-pdf-slide-btn").removeClass("hide");
        }
        if (pageNumber == numPages) {
            $("#next-pdf-slide-btn").addClass("hide");
            $("#opensign-btn").removeClass("hide");
            $("#move-last-pdf-slide-btn").addClass("hide");
        }
        if (pageNumber == 1) {
            $("#previous-pdf-slide-btn").addClass("hide");
            $("#next-pdf-slide-btn").removeClass("hide");
            $("#opensign-btn").addClass("hide");
            $("#sign-btn").addClass("hide");
            setShowSignPicker(false);
            $("#move-first-pdf-slide-btn").addClass("hide");
            $("#move-last-pdf-slide-btn").removeClass("hide");
        }
    }, [pageNumber])

    function previousPage() {
        changePage(-1);
    }
    function nextPage() {
        changePage(1);
    }
    function moveToLast() {
        setPageNumber(numPages);

    }
    function moveToFirst() {
        setPageNumber(1);

    }
    const signTheAgreementPage = () => {
        if (type == 1) {
            $("#ndaId").prop("checked", true);
        }
        else if (type == 2) {
            $("#privacyPolicyId").prop("checked", true);
        }
        else if (type == 3) {
            $("#cocId").prop("checked", true);
        }
        const obj = {
            "has_acknowledged": true,
            "acknowledgement_type_id": type,
            "acknowledgementSignatureUrl": mainImageUrl,
            "acknowledgementFileUrl": type == 1 ? fileUrl : type == 2 ? fileUrl : type == 3 ? fileUrl : "",
            "acknowledgementContent": ""
        }
        // console.log(obj);
        const updAckInfo = [...ackInfo, obj];
        setAckInfo([...updAckInfo]);
        // console.log(updAckInfo);
        sendAckInfo([...updAckInfo]);
        $("#sign-btn").addClass("hide");
        $("#NDA .close").trigger('click');

    }
    const openSignPad = () => {
        setShowSignPicker(true);
        $("#opensign-btn").addClass("hide");
    }
    return (
        <div className="pdfViewer">
            <div className="main">
                <div className="pagec">
                    Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                    <a href="#"
                        // type="button"

                        onClick={moveToLast}
                        className="float-right btn btn-warning btn-md next-btn"
                        id="move-last-pdf-slide-btn"
                    >
                        Move to End <i className="mdi mdi-arrow-right"
                            aria-hidden="true"></i>
                    </a>
                    <a href="#"
                        // type="button"

                        onClick={moveToFirst}
                        className="float-right btn btn-warning btn-md previous-btn mr_10"
                        id="move-first-pdf-slide-btn"
                    ><i className="mdi mdi-arrow-left"
                        aria-hidden="true"></i>
                        Move to Start
                    </a>
                </div>
                <Document style={{ alignContent: 'center' }}
                    file={fileUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                // file={{
                //     url: url,
                // }}
                // onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page pageNumber={pageNumber} />
                </Document>
                <div>

                    <div className="buttonc">
                        <a href="#"
                            // type="button"

                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                            className="Pre btn btn-warning btn-md previous-btn hide"
                            id="previous-pdf-slide-btn"
                        >
                            Previous
                        </a>
                        <a href="#"
                            // type="button"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                            className="float-right btn btn-warning btn-md next-btn"
                            id="next-pdf-slide-btn"
                        >
                            Next
                        </a>
                        <a href="#"
                            // type="button"
                            disabled={pageNumber >= numPages}
                            onClick={signTheAgreementPage}
                            className="float-right btn btn-warning btn-md next-btn hide"
                            id="sign-btn"
                        >
                            Sign the agreement
                        </a>
                        <a href="#"
                            // type="button"
                            disabled={pageNumber >= numPages}
                            onClick={openSignPad}
                            className="float-right btn btn-warning btn-md next-btn hide"
                            id="opensign-btn"
                        >
                            Open SignPad
                        </a>
                    </div>
                </div>
            </div>
            {showSignPicker && <SignPad sendSignImgDataToParent={sendSignImgDataToParent} clearAllCount={clearAllCount} clearACkType={clearACkType} />}
        </div>
    );
}





