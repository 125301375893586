import React, { useEffect, useState } from 'react'
import Header from '../../../commons/components/Header'
import Footer from '../../../commons/components/Footer'
import RFPDetailsGlobal from '../../RFP/caas/RFPDetailsGlobal'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import CaaSSideBar from '../../../commons/components/SideBars/CaaSSideBar';
import CaasMemberView from '../../Settings/caas/CaasMemberView';
import CXOProfileView from '../../../commons/components/Profile/CXOProfileView'
import { useParams } from 'react-router-dom';
import profilepic from "../../../assets/images/noav.jpg";
import $ from "jquery";
import { API } from '../../../config/APIConfig';
import swal from 'sweetalert';
import axios from 'axios';
const CXOListAPI = `${API.URL}api/v1/admin/cxo/list`;
const PUBLISHRFPAPI = `${API.URL}api/v1/admin/publishrfp`;
const IndustryListAPI = `${API.URL}api/v1/meta/industrylist`;
const SkillListAPI = `${API.URL}api/v1/meta/skillmasterversionlist`;
const EducationAPI = `${API.URL}api/v1/meta/educationlist`;
const GETRFPDETAILAPI = `${API.URL}api/v1/enterprise/rfp/view`;
const AutomatchAndPublishRFP = () => {
    const { i18n, t } = useTranslation(["quote"]);
    let { rfpId } = useParams();
    const [caasdetails, setCaasDetails] = useState([]);
    const [caasfiltereddetails, setCaasFilteredDetails] = useState([]);
    const [industryList, setIndustryList] = useState([]);
    const [skillMasterList, setSkillMasterList] = useState([]);
    const [educationList, setEducationList] = useState([]);
    const [selectedSkillIds, setSelectedSkillIds] = useState([]);
    const [selectedIndustryIds, setSelectedIndustryIds] = useState([]);
    const [rfpDetails, setrfpDetails] = useState({});
    const [curCXOData, setCurCXOData] = useState([]);
    // const [dataLoaded, setDataLoaded] = useState(false);
    var enterpriseId = 0;
    var userId = 0;
    const handleSkillOnChange = (e) => {
        const { value, checked } = e.target;
        if (checked)
            setSelectedSkillIds([...selectedSkillIds, value]);
        else
            setSelectedSkillIds(selectedSkillIds.filter(val => val != value));
    }
    const handleIndustryOnChange = (e) => {
        const { value, checked } = e.target;
        if (checked)
            setSelectedIndustryIds([...selectedIndustryIds, value]);
        else
            setSelectedIndustryIds(selectedIndustryIds.filter(val => val != value));
    }
    const filterCAASDetails = (skills, industries) => {
        var filteredData = caasdetails;
        if (skills?.length > 0 && filteredData.length > 0) {
            filteredData = filteredData?.filter(el => {
                var skillSet = el?.skillIds?.split(",");
                return skills?.some(id => skillSet?.includes(id.toString()));
            });
        }
        if (industries?.length > 0 && filteredData.length > 0) {
            filteredData = filteredData?.filter(el => {
                var industrySet = el?.industryIds?.split(",");
                return industries?.some(id => industrySet?.includes(id.toString()));
            });
        }
        setCaasFilteredDetails([...filteredData]);
    }
    const applyFiltersByRFPDetails = (data) => {
        if (!$.isEmptyObject(data)) {
            var rfpRequiredSkillIds = data?.rfpExperience?.map(ele => ele?.experienceSkillId);
            var rfpRequiredIndustryIds = data?.rfpIndustryExp?.map(ele => ele?.industryId);
            setSelectedSkillIds([...rfpRequiredSkillIds]);
            setSelectedIndustryIds([...rfpRequiredIndustryIds]);
            checkTheBoxes(rfpRequiredSkillIds, rfpRequiredIndustryIds);
        }
    }
    useEffect(() => {
        bindEventHandlers();
        bindUsers();
        bindIndustries();
        bindSkills();
        // bindEducation();
    }, [])
    useEffect(() => {
        filterCAASDetails(selectedSkillIds, selectedIndustryIds);
    }, [selectedSkillIds, selectedIndustryIds]);
    const checkTheBoxes = (skills, industries) => {
        skills?.forEach(function (id) {
            if ($("#skill_" + id).is(":checked") === false)
                $("#skill_" + id).prop("checked", true);
        });
        industries?.forEach(function (id) {
            if ($("#industry_" + id).is(":checked") === false)
                $("#industry_" + id).prop("checked", true);
        });
    }
    const resetFilter = () => {
        setCaasFilteredDetails([...caasdetails]);
        setSelectedSkillIds([]);
        setSelectedIndustryIds([]);
        $('.caasFilters input:checkbox').prop('checked', false);
    }
    const searchCAASSMembers = (e) => {
        const key = e.target.value;
        if (key === "") {
            filterCAASDetails(selectedSkillIds, selectedIndustryIds);
            return
        }
        const searchedMembers = caasfiltereddetails.filter((ele) => ele.firstName.toLowerCase().includes(key.toLowerCase()) || ele.lastName.toLowerCase().includes(key.toLowerCase()));
        setCaasFilteredDetails([...searchedMembers]);
    }
    const getCXOList = async () => {
        const response = await fetch(`${CXOListAPI}?rfpId=${rfpId}`);
        if (!response.ok) {
            throw new Error('Data coud not be fetched!')
        } else {
            return response.json()
        }
    }
    const bindUsers = () => {
        getCXOList()
            .then(async (data) => {
                if (data.response) {
                    setCaasDetails(data.response);
                    setCaasFilteredDetails(data.response);
                    const response = await axios.get(`${GETRFPDETAILAPI}/?enterpriseId=${enterpriseId}&userId=${userId}&rfpId=${rfpId}`);
                    const rfpdata = response?.data?.response;
                    setrfpDetails(rfpdata);
                    applyFiltersByRFPDetails(rfpdata);
                }
            })
            .catch((e) => {
                console.log(e.message)
            });
    }
    const getIndustryList = async () => {
        const response = await fetch(IndustryListAPI);
        if (!response.ok) {
            throw new Error('Data coud not be fetched!')
        } else {
            return response.json()
        }
    }
    const bindIndustries = () => {
        getIndustryList()
            .then((data) => {
                if (data.response) {
                    setIndustryList(data.response);
                }
            })
            .catch((e) => {
                console.log(e.message)
            });
    }
    const getSkillList = async () => {
        const response = await fetch(SkillListAPI);
        if (!response.ok) {
            throw new Error('Data coud not be fetched!')
        } else {
            return response.json()
        }
    }
    const bindSkills = () => {
        getSkillList()
            .then((data) => {
                if (data.response) {
                    var skillDetails = data.response.skillMasterList;
                    setSkillMasterList(skillDetails);
                }
            })
            .catch((e) => {
                console.log(e.message)
            });
    }
    const getEducationList = async () => {
        const response = await fetch(EducationAPI);
        if (!response.ok) {
            throw new Error('Data coud not be fetched!')
        } else {
            return response.json()
        }
    }
    const bindEducation = () => {
        getEducationList()
            .then((data) => {
                if (data.response) {
                    var educationDetails = data.response.educationList;
                    setEducationList(educationDetails);
                }
            })
            .catch((e) => {
                console.log(e.message)
            });
    }
    const resetCurCXO = (userId, roleId) => {
        let arr = [];
        let obj = { userId, roleId }
        arr.push(obj);
        setCurCXOData([...arr]);
    }
    const publishRFP = () => {
        if ($('#rfp_publish_user :checked').length == 0) {
            swal("Please select at least one CAAS Member", "ERROR!", "warning", { closeOnClickOutside: false, closeOnEsc: false });
            return false;
        }
        var userList = [];
        $('#rfp_publish_user input[type="checkbox"]:checked').map(function (i, e) {
            var usernewObj = {};
            usernewObj.userId = $(this).val();
            var caasDetails = caasfiltereddetails.filter(user => user.userId == $(this).val());
            usernewObj.firstName = caasDetails[0].firstName;
            usernewObj.lastName = caasDetails[0].lastName;
            usernewObj.emailAddress = caasDetails[0].emailAddress;
            userList.push(usernewObj);
        })
        let userobj = {
            rfpId: rfpId,
            userList: userList
        }
        axios.post(PUBLISHRFPAPI, userobj).then(response => {
            if (response.data.status === 200) {
                bindUsers();
                swal("RFP Published Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
            }
        }).catch(error => {
            console.log(error);
        });
    }
    const bindEventHandlers = () => {
        $(".settings-close").on("click", function () {
            $("#right-sidebar,#theme-settings").removeClass("open");
        });
        $("#settings-trigger").on("click", function () {
            $("#theme-settings").toggleClass("open");
        });
    }
    return (
        <div className="container-scroller">
            <Header entity="CaaS" />
            <div className="container-fluid page-body-wrapper">
                <div className="theme-setting-wrapper boxshadow">
                    <div id="theme-settings" className="settings-panel boxshadow rounded">
                        <i className="settings-close ti-close"></i>
                        <p className="settings-heading mt-2 fw-bold fs-5 text-muted" style={{ textAlign: "left" }}>New Search</p>
                        <div className="p-3 SideBarSearchScroll">
                            <div className="sidebar-archive">
                                {/* <div className="section-head">
                                    <button type="submit" className="search-button"><i className="fa fa-search text-white" aria-hidden="true"></i></button>
                                    <div className="search-field-wrap">
                                        <input type="search" placeholder="Search by Name" name="refine_ls" className="text search" spellCheck="false" data-ms-editor="true" />
                                    </div>
                                </div> */}
                                {/* <div className="accordion" id="accordion2" role="tablist">
                                    <div className="card">
                                        <div className="card-header" role="tab" id="heading-4">
                                            <h6 className="mb-0">
                                                <a data-bs-toggle="collapse" href="#collapse-4" aria-expanded="true" aria-controls="collapse-4">
                                                    Industry
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="collapse-4" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                            <div className="card-body1">
                                                <div className="row1">
                                                    <div className="col-md-12">
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Manufacturing
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Banking
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Insurance
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Airline
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Pharma
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Healthcare
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-right">
                                                    <a href="#" className="text-link moreinfo" data-bs-target="#AddIndustry" data-bs-toggle="modal"><i className="fa fa-plus"></i> <span className="px-2">More</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion" id="accordion11" role="tablist">
                                    <div className="card">
                                        <div className="card-header" role="tab" id="heading-13">
                                            <h6 className="mb-0">
                                                <a data-bs-toggle="collapse" href="#collapse-13" aria-expanded="true" aria-controls="collapse-13">
                                                    Designation
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="collapse-13" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                            <div className="card-body1">
                                                <div className="row1">
                                                    <div className="col-md-12">
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                UI Developer
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                UI Front End Developer
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Team Lead
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Tech Lead
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Project Manager
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Project Delivery Head
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Tester
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-right">
                                                    <a href="#" className="text-link moreinfo" data-bs-target="#AddDesignation" data-bs-toggle="modal"><i className="fa fa-plus"></i> <span className="px-2">More</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion" id="accordion6" role="tablist">
                                    <div className="card">
                                        <div className="card-header" role="tab" id="heading-8">
                                            <h6 className="mb-0">
                                                <a data-bs-toggle="collapse" href="#collapse-8" aria-expanded="true" aria-controls="collapse-8">
                                                    Skills:
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="collapse-8" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                            <div className="card-body1">
                                                <div className="row1">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Oracle ERP
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Oracle Finance
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                AWS
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                IOS App
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="text-right">
                                                            <a href="#" className="text-link moreinfo" data-bs-target="#AddSkills" data-bs-toggle="modal"><i className="fa fa-plus"></i> <span className="px-2">More</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion" id="accordion1" role="tablist">
                                    <div className="card">
                                        <div className="card-header" role="tab" id="heading-1">
                                            <h6 className="mb-0">
                                                <a data-bs-toggle="collapse" href="#collapse-1" aria-expanded="true" aria-controls="collapse-1">
                                                    Experience
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="collapse-1" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                            <div className="card-body1">
                                                <div className="row1">
                                                    <div className="card">
                                                        <div className="card-body p-0">
                                                            <div className="template-demo pe-4">
                                                                <div id="skipstep-connect" className="ul-slider slider-primary noUi-target noUi-ltr noUi-horizontal">
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <p className="mt-3">Years: <span id="skip-value-lower-2">2.00</span></p>
                                                                    <p className="mt-3">Years: <span id="skip-value-upper-2">10.00</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion" id="accordion4" role="tablist">
                                    <div className="card">
                                        <div className="card-header" role="tab" id="heading-6">
                                            <h6 className="mb-0">
                                                <a data-bs-toggle="collapse" href="#collapse-6" aria-expanded="true" aria-controls="collapse-6">
                                                    Gender
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="collapse-6" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                            <div className="card-body1">
                                                <div className="row1">
                                                    <div className="form-group">
                                                        <select className="form-select">
                                                            <option>Select</option>
                                                            <option>Male</option>
                                                            <option>Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion" id="accordion5" role="tablist">
                                    <div className="card">
                                        <div className="card-header" role="tab" id="heading-7">
                                            <h6 className="mb-0">
                                                <a data-bs-toggle="collapse" href="#collapse-7" aria-expanded="true" aria-controls="collapse-7">
                                                    Education
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="collapse-7" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                            <div className="card-body1">
                                                <div className="row1">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Any Postgraduate
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Any Graduate
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Others
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion" id="accordion12" role="tablist">
                                    <div className="card">
                                        <div className="card-header" role="tab" id="heading-9">
                                            <h6 className="mb-0">
                                                <a data-bs-toggle="collapse" href="#collapse-9" aria-expanded="true" aria-controls="collapse-9">
                                                    Course
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="collapse-9" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                            <div className="card-body1">
                                                <div className="row1">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                MBA
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Msc
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                MCA
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                BBA
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                B.Sc
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                BMS
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                BA
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="text-right">
                                                            <a href="#" className="text-link moreinfo " data-bs-target="#AddEducation" data-bs-toggle="modal"><i className="fa fa-plus"></i> <span className="px-2">More</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion" id="accordion7" role="tablist">
                                    <div className="card">
                                        <div className="card-header" role="tab" id="heading-9">
                                            <h6 className="mb-0">
                                                <a data-bs-toggle="collapse" href="#collapse-9" aria-expanded="true" aria-controls="collapse-9">
                                                    Implementation size:
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="collapse-9" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                            <div className="card-body1">
                                                <div className="row1">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                5 User
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                10 User
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                15 User
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                20 User
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                More then 25 User
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion" id="accordion12" role="tablist">
                                    <div className="card">
                                        <div className="card-header" role="tab" id="heading-12">
                                            <h6 className="mb-0">
                                                <a data-bs-toggle="collapse" href="#collapse-12" aria-expanded="true" aria-controls="collapse-12">
                                                    Experience Level:
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="collapse-12" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                            <div className="card-body1">
                                                <div className="row1">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Expert
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Good
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Medium
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Basic
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion" id="accordion8" role="tablist">
                                    <div className="card">
                                        <div className="card-header" role="tab" id="heading-10">
                                            <h6 className="mb-0">
                                                <a data-bs-toggle="collapse" href="#collapse-10" aria-expanded="true" aria-controls="collapse-10">
                                                    Language:
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="collapse-10" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                            <div className="card-body1">
                                                <div className="row1">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                English
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Hindi
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Arabic
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Fresch
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="text-right">
                                                            <a href="#" className="text-link moreinfo" data-bs-target="#AddLanguages" data-bs-toggle="modal"><i className="fa fa-plus"></i> <span className="px-2">More</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion" id="accordion9" role="tablist">
                                    <div className="card">
                                        <div className="card-header" role="tab" id="heading-5">
                                            <h6 className="mb-0">
                                                <a data-bs-toggle="collapse" href="#collapse-5" aria-expanded="true" aria-controls="collapse-5">
                                                    Certification:
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="collapse-5" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                            <div className="card-body1">
                                                <div className="row1">
                                                    <div className="form-group">
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Oracle ERP
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Oracle Finance
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Data Analyst
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                UX Design
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Project Management
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Cybersecurity Analyst
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="form-check form-check-primary">
                                                            <label className="form-check-label">
                                                                <input type="checkbox" className="form-check-input" />
                                                                Full Stack Cloud Developer
                                                                <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i></label>
                                                        </div>
                                                        <div className="text-right">
                                                            <a href="#" className="text-link moreinfo" data-bs-target="#AddCertification" data-bs-toggle="modal"><i className="fa fa-plus"></i> <span className="px-2">More</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion" id="accordion9" role="tablist">
                                    <div className="card">
                                        <div className="card-header" role="tab" id="heading-11">
                                            <h6 className="mb-0">
                                                <a data-bs-toggle="collapse" href="#collapse-11" aria-expanded="true" aria-controls="collapse-11">
                                                    Country
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="collapse-11" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                            <div className="card-body1">
                                                <div className="row1">
                                                    <div className="form-group">
                                                        <select className="form-select" name="" id="">
                                                            <option value="-1">Select</option>
                                                            <option value="1">America</option>
                                                            <option value="2">Australia</option>
                                                            <option value="3">Afghanistan</option>
                                                            <option value="4">Argentina</option>
                                                            <option value="5">Brazil</option>
                                                            <option value="6">Cambodia</option>
                                                            <option value="7">United Arab Emirates</option>
                                                            <option value="8">India</option>
                                                            <option value="9">United Kingdom</option>
                                                            <option value="10">United States of America</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion" id="accordion13" role="tablist">
                                    <div className="card">
                                        <div className="card-header" role="tab" id="heading-14">
                                            <h6 className="mb-0">
                                                <a data-bs-toggle="collapse" href="#collapse-14" aria-expanded="true" aria-controls="collapse-14">
                                                    Nationality
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="collapse-14" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                            <div className="card-body1">
                                                <div className="row1">
                                                    <div className="form-group">
                                                        <select className="form-select" name="" id="">
                                                            <option value="-1">Select</option>
                                                            <option value="1">American</option>
                                                            <option value="2">Algerian</option>
                                                            <option value="3">Bahraini</option>
                                                            <option value="4">Cambodian</option>
                                                            <option value="5">Canadian</option>
                                                            <option value="6">Dominican</option>
                                                            <option value="7">Egyptian</option>
                                                            <option value="8">Indian</option>
                                                            <option value="9">Faroese</option>
                                                            <option value="10">Honduran</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <a className="btn btn-primary" href="newsearch.html">Search</a>
                            </div>
                        </div>
                    </div>
                </div>
                <CaaSSideBar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="col-12">
                                    <div className="card card-rounded card-outline card-primary">
                                        <div className="card-header">
                                            <div className="d-lg-flex justify-content-between">
                                                <div>
                                                    <nav className="navbar navbar-expand bg-white">
                                                        <ul className="navbar-nav">
                                                            <li className="nav-item bold">
                                                                <h3 className="fw-bold"> Automatch CAAS Member Profiles and Publish </h3>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                                <div className="d-lg-flex align-items-center text-nowrap">
                                                    {/* <a className="btn btn-info btn-sm add-client btn-icon-text text-nowrap me-3 NewSearch" id="settings-trigger"><i className="mdi mdi-account-search"></i>&nbsp;New Search</a> */}
                                                    <Link to="/caas/global/managerfp" className="btn btn-primary btn-sm add-client btn-icon-text text-nowrap me-3">Back to RFP</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body card-rounded card-primary">
                                            <RFPDetailsGlobal rfpId={rfpId} />
                                            {/* <div className="row mt-1">
                                                <h4 className="card-title" style={{ marginBottom: "-0.5rem" }}><i className="ti-info-alt mr_0"></i> This is an auto match search displayed
                                                    based on the RFP details matching to <span className="text-muted">CIO</span> details</h4>
                                            </div> */}
                                        </div>
                                        <div className="card-body bg-light-blue" id="ScrollGrid">
                                            <div className="row">
                                                <div className="col-12 col-lg-3 bg-white py-2 widthLeft">
                                                    <div className="scrollDiv bg-white">
                                                        {/* <h3 className="h4 RefineSearch pt-3 text-muted mb-3">Reset Filter</h3> */}
                                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                                            <h3 className="fw-bold fs-4 mb-0 text-muted">Filters</h3>
                                                            <h4 className="text-black-50 fs-4 mb-0 fw-bold">
                                                                <button className="btn btn-primary border-radius-20 py-1" onClick={resetFilter}> Reset
                                                                </button>
                                                            </h4>
                                                        </div>
                                                        <div className="sidebar-archive caasFilters">
                                                            {/* <div className="section-head">
                                                                <button type="submit" className="search-button"><i className="fa fa-search text-white" aria-hidden="true"></i></button>
                                                                <div className="search-field-wrap">
                                                                    <input type="search" placeholder="Search by Name" name="refine_ls" className="text search" spellCheck="false" data-ms-editor="true" />
                                                                </div>
                                                            </div> */}
                                                            <div className="accordion" id="accordion2" role="tablist">
                                                                <div className="card">
                                                                    <div className="card-header" role="tab" id="heading-4">
                                                                        <h6 className="mb-0">
                                                                            <a data-bs-toggle="collapse" href="#collapse-4" aria-expanded="true" aria-controls="collapse-4" className="filter_headings">
                                                                                Industry
                                                                            </a>
                                                                        </h6>
                                                                    </div>
                                                                    <div id="collapse-4" className="p-3 collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                                                        <div className="card-body1">
                                                                            <div className="row1">
                                                                                <div className="col-md-12">
                                                                                    {
                                                                                        industryList.length != 0 ? (
                                                                                            industryList.map((ele, index) => (
                                                                                                <div className="form-check form-check-primary " key={index}>
                                                                                                    <label className="form-check-label">
                                                                                                        <input type="checkbox" className="form-check-input" id={"industry_" + ele?.industryId}
                                                                                                            value={ele?.industryId}
                                                                                                            name="industries"
                                                                                                            onChange={handleIndustryOnChange} />
                                                                                                        {ele?.industryName}
                                                                                                        <i className="input-helper"></i>
                                                                                                        <i className="input-helper"></i>
                                                                                                    </label>
                                                                                                </div>))
                                                                                        ) : (
                                                                                            <h4>No Data Available</h4>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="accordion" id="accordion11" role="tablist">
                                                                <div className="card">
                                                                    <div className="card-header" role="tab" id="heading-13">
                                                                        <h6 className="mb-0">
                                                                            <a data-bs-toggle="collapse" href="#collapse-13" aria-expanded="true" aria-controls="collapse-13">
                                                                                Designation
                                                                            </a>
                                                                        </h6>
                                                                    </div>
                                                                    <div id="collapse-13" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                                                        <div className="card-body1">
                                                                            <div className="row1">
                                                                                <div className="col-md-12">
                                                                                    <div className="form-check form-check-primary">
                                                                                        <label className="form-check-label">
                                                                                            <input type="checkbox" className="form-check-input" />
                                                                                            Project Manager
                                                                                            <i className="input-helper"></i><i className="input-helper"></i></label>
                                                                                    </div>
                                                                                    <div className="form-check form-check-primary">
                                                                                        <label className="form-check-label">
                                                                                            <input type="checkbox" className="form-check-input" />
                                                                                            Project Delivery Head
                                                                                            <i className="input-helper"></i><i className="input-helper"></i></label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                            <div className="accordion" id="accordion6" role="tablist">
                                                                <div className="card">
                                                                    <div className="card-header" role="tab" id="heading-8">
                                                                        <h6 className="mb-0">
                                                                            <a data-bs-toggle="collapse" href="#collapse-8" aria-expanded="false" aria-controls="collapse-8" className="filter_headings">
                                                                                Skills:
                                                                            </a>
                                                                        </h6>
                                                                    </div>
                                                                    <div id="collapse-8" className=" p-3 collapse" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                                                        <div className="card-body1">
                                                                            <div className="row1">
                                                                                <div id="skills" className="form-group">
                                                                                    {
                                                                                        skillMasterList.length != 0 ? (
                                                                                            skillMasterList.map((ele, index) => (
                                                                                                <div className="form-check form-check-primary" key={index}>
                                                                                                    <label className="form-check-label">
                                                                                                        <input type="checkbox" className="form-check-input"
                                                                                                            id={"skill_" + ele?.skillId}
                                                                                                            value={ele?.skillId}
                                                                                                            name="skills"
                                                                                                            onChange={handleSkillOnChange}
                                                                                                        />
                                                                                                        {ele?.skillName}
                                                                                                        <i className="input-helper"></i><i className="input-helper"></i></label>
                                                                                                </div>))
                                                                                        ) : (
                                                                                            <h4>No Data Available</h4>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="accordion" id="accordion1" role="tablist">
                                                                <div className="card">
                                                                    <div className="card-header" role="tab" id="heading-1">
                                                                        <h6 className="mb-0">
                                                                            <a data-bs-toggle="collapse" href="#collapse-1" aria-expanded="true" aria-controls="collapse-1">
                                                                                Experience
                                                                            </a>
                                                                        </h6>
                                                                    </div>
                                                                    <div id="collapse-1" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                                                        <div className="card-body1">
                                                                            <div className="row1">
                                                                                <div className="card">
                                                                                    <div className="card-body p-0">
                                                                                        <div className="template-demo pe-4">
                                                                                            <div id="skipstep-connect-3" className="ul-slider slider-primary noUi-target noUi-ltr noUi-horizontal">
                                                                                            </div>
                                                                                            <div className="d-flex justify-content-between">
                                                                                                <p className="mt-3">Years: <span id="skip-value-lower-3"></span></p>
                                                                                                <p className="mt-3">Years: <span id="skip-value-upper-3"></span></p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion" id="accordion4" role="tablist">
                                                                <div className="card">
                                                                    <div className="card-header" role="tab" id="heading-6">
                                                                        <h6 className="mb-0">
                                                                            <a data-bs-toggle="collapse" href="#collapse-6" aria-expanded="true" aria-controls="collapse-6">
                                                                                Gender
                                                                            </a>
                                                                        </h6>
                                                                    </div>
                                                                    <div id="collapse-6" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                                                        <div className="card-body1">
                                                                            <div className="row1">
                                                                                <div className="form-group">
                                                                                    <select className="form-select">
                                                                                        <option>Select</option>
                                                                                        <option>Male</option>
                                                                                        <option>Female</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                            <div className="accordion d-none" id="accordion5" role="tablist">
                                                                <div className="card">
                                                                    <div className="card-header" role="tab" id="heading-7">
                                                                        <h6 className="mb-0">
                                                                            <a data-bs-toggle="collapse" href="#collapse-7" aria-expanded="false" aria-controls="collapse-7" className="filter_headings">
                                                                                Education
                                                                            </a>
                                                                        </h6>
                                                                    </div>
                                                                    <div id="collapse-7" className="p-3 collapse" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                                                        <div className="card-body1">
                                                                            <div className="row1">
                                                                                <div className="form-group">
                                                                                    {
                                                                                        educationList.length != 0 ? (
                                                                                            educationList.map((ele, index) => (
                                                                                                <div className="form-check form-check-primary" key={index}>
                                                                                                    <label className="form-check-label">
                                                                                                        <input type="checkbox" className="form-check-input" id={"education_" + ele.educationId}
                                                                                                        //    onChange={educationSelected}
                                                                                                        />
                                                                                                        {ele.educationName}
                                                                                                        <i className="input-helper"></i><i className="input-helper"></i></label>
                                                                                                </div>))
                                                                                        ) : (
                                                                                            <h4>No Data Available</h4>
                                                                                        )
                                                                                    }
                                                                                    <div className="text-right">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="accordion" id="accordion12" role="tablist">
                                                                <div className="card">
                                                                    <div className="card-header" role="tab" id="heading-9">
                                                                        <h6 className="mb-0">
                                                                            <a data-bs-toggle="collapse" href="#collapse-9" aria-expanded="true" aria-controls="collapse-9">
                                                                                Course
                                                                            </a>
                                                                        </h6>
                                                                    </div>
                                                                    <div id="collapse-9" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                                                        <div className="card-body1">
                                                                            <div className="row1">
                                                                                <div className="form-group">
                                                                                    <div className="form-check form-check-primary">
                                                                                        <label className="form-check-label">
                                                                                            <input type="checkbox" className="form-check-input" />
                                                                                            MBA
                                                                                            <i className="input-helper"></i><i className="input-helper"></i></label>
                                                                                    </div>
                                                                                    <div className="form-check form-check-primary">
                                                                                        <label className="form-check-label">
                                                                                            <input type="checkbox" className="form-check-input" />
                                                                                            BCA
                                                                                            <i className="input-helper"></i><i className="input-helper"></i></label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion" id="accordion7" role="tablist">
                                                                <div className="card">
                                                                    <div className="card-header" role="tab" id="heading-9">
                                                                        <h6 className="mb-0">
                                                                            <a data-bs-toggle="collapse" href="#collapse-9" aria-expanded="true" aria-controls="collapse-9">
                                                                                Implementation size:
                                                                            </a>
                                                                        </h6>
                                                                    </div>
                                                                    <div id="collapse-9" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                                                        <div className="card-body1">
                                                                            <div className="row1">
                                                                                <div className="form-group">
                                                                                    <div className="form-check form-check-primary">
                                                                                        <label className="form-check-label">
                                                                                            <input type="checkbox" className="form-check-input" />
                                                                                            15 User
                                                                                            <i className="input-helper"></i><i className="input-helper"></i></label>
                                                                                    </div>
                                                                                    <div className="form-check form-check-primary">
                                                                                        <label className="form-check-label">
                                                                                            <input type="checkbox" className="form-check-input" />
                                                                                            20 User
                                                                                            <i className="input-helper"></i><i className="input-helper"></i></label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion" id="accordion12" role="tablist">
                                                                <div className="card">
                                                                    <div className="card-header" role="tab" id="heading-12">
                                                                        <h6 className="mb-0">
                                                                            <a data-bs-toggle="collapse" href="#collapse-12" aria-expanded="true" aria-controls="collapse-12">
                                                                                Experience Level:
                                                                            </a>
                                                                        </h6>
                                                                    </div>
                                                                    <div id="collapse-12" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                                                        <div className="card-body1">
                                                                            <div className="row1">
                                                                                <div className="form-group">
                                                                                    <div className="form-check form-check-primary">
                                                                                        <label className="form-check-label">
                                                                                            <input type="checkbox" className="form-check-input" />
                                                                                            Expert
                                                                                            <i className="input-helper"></i><i className="input-helper"></i></label>
                                                                                    </div>
                                                                                    <div className="form-check form-check-primary">
                                                                                        <label className="form-check-label">
                                                                                            <input type="checkbox" className="form-check-input" />
                                                                                            Good
                                                                                            <i className="input-helper"></i><i className="input-helper"></i></label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion" id="accordion8" role="tablist">
                                                                <div className="card">
                                                                    <div className="card-header" role="tab" id="heading-10">
                                                                        <h6 className="mb-0">
                                                                            <a data-bs-toggle="collapse" href="#collapse-10" aria-expanded="true" aria-controls="collapse-10">
                                                                                Language:
                                                                            </a>
                                                                        </h6>
                                                                    </div>
                                                                    <div id="collapse-10" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                                                        <div className="card-body1">
                                                                            <div className="row1">
                                                                                <div className="form-group">
                                                                                    <div className="form-check form-check-primary">
                                                                                        <label className="form-check-label">
                                                                                            <input type="checkbox" className="form-check-input" />
                                                                                            English
                                                                                            <i className="input-helper"></i><i className="input-helper"></i></label>
                                                                                    </div>
                                                                                    <div className="form-check form-check-primary">
                                                                                        <label className="form-check-label">
                                                                                            <input type="checkbox" className="form-check-input" />
                                                                                            Hindi
                                                                                            <i className="input-helper"></i><i className="input-helper"></i></label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion" id="accordion9" role="tablist">
                                                                <div className="card">
                                                                    <div className="card-header" role="tab" id="heading-5">
                                                                        <h6 className="mb-0">
                                                                            <a data-bs-toggle="collapse" href="#collapse-5" aria-expanded="true" aria-controls="collapse-5">
                                                                                Certification:
                                                                            </a>
                                                                        </h6>
                                                                    </div>
                                                                    <div id="collapse-5" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                                                        <div className="card-body1">
                                                                            <div className="row1">
                                                                                <div className="form-group">
                                                                                    <div className="form-check form-check-primary">
                                                                                        <label className="form-check-label">
                                                                                            <input type="checkbox" className="form-check-input" />
                                                                                            Oracle ERP
                                                                                            <i className="input-helper"></i><i className="input-helper"></i></label>
                                                                                    </div>
                                                                                    <div className="form-check form-check-primary">
                                                                                        <label className="form-check-label">
                                                                                            <input type="checkbox" className="form-check-input" />
                                                                                            Oracle Finance
                                                                                            <i className="input-helper"></i><i className="input-helper"></i></label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion" id="accordion9" role="tablist">
                                                                <div className="card">
                                                                    <div className="card-header" role="tab" id="heading-11">
                                                                        <h6 className="mb-0">
                                                                            <a data-bs-toggle="collapse" href="#collapse-11" aria-expanded="true" aria-controls="collapse-11">
                                                                                Country
                                                                            </a>
                                                                        </h6>
                                                                    </div>
                                                                    <div id="collapse-11" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                                                        <div className="card-body1">
                                                                            <div className="row1">
                                                                                <div className="form-group">
                                                                                    <select className="form-select" name="" id="">
                                                                                        <option value="-1">Select</option>
                                                                                        <option value="1">America</option>
                                                                                        <option value="7">United Arab Emirates</option>
                                                                                        <option value="9">United Kingdom</option>
                                                                                        <option value="10">United States of America</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion" id="accordion13" role="tablist">
                                                                <div className="card">
                                                                    <div className="card-header" role="tab" id="heading-14">
                                                                        <h6 className="mb-0">
                                                                            <a data-bs-toggle="collapse" href="#collapse-14" aria-expanded="true" aria-controls="collapse-14">
                                                                                Nationality
                                                                            </a>
                                                                        </h6>
                                                                    </div>
                                                                    <div id="collapse-14" className="collapse show" role="tabpanel" aria-labelledby="heading-1" data-bs-parent="#accordion">
                                                                        <div className="card-body1">
                                                                            <div className="row1">
                                                                                <div className="form-group">
                                                                                    <select className="form-select" name="" id="">
                                                                                        <option value="-1">Select</option>
                                                                                        <option value="1">American</option>
                                                                                        <option value="2">UK</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-9">
                                                    <input className="form-control"
                                                        type="search"
                                                        placeholder="Search Member by Name"
                                                        onKeyUp={searchCAASSMembers} />
                                                    <div className="d-flex justify-content-between align-content-center align-items-center mb-3">
                                                        <p className="text-muted m-0 fw-bold">{caasfiltereddetails?.length > 0 ? caasfiltereddetails?.length + " - Results Found" : "No Results Found"}</p>

                                                        <div className="form-search1">
                                                            <div className="form-check form-check-primary d-inline-block me-2">
                                                                <label className="form-check-label d-inline-block">
                                                                    {/* <input type="checkbox" className="form-check-input float-right" /> 
                                                                    <i className="input-helper"></i>
                                                                    <i className="input-helper"></i>
                                                                    <i className="input-helper"></i>
                                                                    <i className="input-helper"></i>*/}

                                                                </label>
                                                            </div>
                                                            <a href="#" className="btn btn-primary btn-fw text-decoration-none fw-bold d-inline-block" data-bs-toggle="modal" data-bs-target="#PublishRFP">Publish</a>
                                                        </div>
                                                    </div>
                                                    {/* <div className='admin_auto_main_screen' id="rfp_user"> */}
                                                    {
                                                        caasfiltereddetails.length != 0 ? (
                                                            caasfiltereddetails.map((elem, index) => (
                                                                <table className="table table-bordered bg-white colrow mb-4" key={index}>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td rowSpan="3">
                                                                                <img src={elem?.profilePicture ? elem?.profilePicture : profilepic} alt="img" className="img-rounded img-responsive img-autopublish" />
                                                                            </td>
                                                                            <td className="fw-bold"><span>{elem?.firstName} {elem?.lastName} - {elem?.uniqueCode}</span>
                                                                                <div className="form-check form-check-primary d-inline-block m-0 float-end">
                                                                                    {elem.rfpPublishStatus == 0 ? <div id="rfp_publish_user">
                                                                                        {/* <label className="form-check-label d-inline-block">
                                                                                            <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i>
                                                                                            <i className="input-helper"></i><i className="input-helper"></i></label> */}
                                                                                        <input type="checkbox" className="form-check-input float-right" value={elem?.userId} /></div> :
                                                                                        <div id="rfp_alerady_publish_user">
                                                                                            {/* <label className="form-check-label d-inline-block">
                                                                                                 <i className="input-helper"></i><i className="input-helper"></i><i className="input-helper"></i>
                                                                                                 <i className="input-helper"></i><i className="input-helper"></i></label> */}
                                                                                            <input type="checkbox" className="form-check-input float-right" value={elem?.userId} title="Already Published" checked disabled /></div>

                                                                                    }

                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <a href="#"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#ViewProfile"
                                                                                    data-backdrop="static" data-keyboard="false"
                                                                                    onClick={() => resetCurCXO(elem?.userId, elem?.roleId)}
                                                                                >
                                                                                    View Profile</a>
                                                                                {/* <a data-bs-toggle="modal" data-bs-target="#AddEnterprise" href="#">View Profile</a> */}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan="2">{elem?.profileSummary}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan="2" className="card-footer"><span className="text-muted fs-12 mr_20 mb-1">
                                                                                <b>Skills</b> : {elem?.skillNames.length != 0 ? elem?.skillNames?.slice(0, -1) : "No Skills Found"}
                                                                            </span></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                            </tr>
                                                        )
                                                    }
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <div className="modal fade show" id="ViewProfile" aria-modal="true" role="dialog" tabIndex="-1" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('viewProfile')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setCurCXOData([])}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row w-100 mx-0">
                                {curCXOData.length != 0 ? (<CaasMemberView quotedBy={curCXOData[0]?.userId} roleId={curCXOData[0]?.roleId} hideBack={true} />) : <p>{t('noRecordsFound')}</p>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={() => setCurCXOData([])}>{t('close')}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="AddIndustry1">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Add Industry</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="">Industry Name</label>
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-primary" data-bs-dismiss="modal">Add</a>
                            <a type="button" className="btn btn-danger" data-bs-dismiss="modal">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="AddIndustry">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Add Industry</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            CSR & Social Service
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Shipping & Maritime
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Quality Assurance
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Food, Beverage & Hospitality
                                            <i className="input-helper"></i></label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Others
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Security Services
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            CSR & Social Service
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Production, Manufacturing & Engineering
                                            <i className="input-helper"></i></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-primary" data-bs-dismiss="modal">Apply Filiter</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="AddDesignation">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Add Designation</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Application Developer
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Java Developer
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Programmer Analyst
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Software Architect
                                            <i className="input-helper"></i></label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Software Engineer
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Front End Developer
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Senior Web Developer
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            IT Support Manager
                                            <i className="input-helper"></i></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-primary" data-bs-dismiss="modal">Apply Filiter</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="AddEducation">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Add Education</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            M.Tech
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            M.Com
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            MBA
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            MS/MSC
                                            <i className="input-helper"></i></label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Diploma
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            BE
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            ME
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Medical-MS/MD
                                            <i className="input-helper"></i></label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Others
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            BA
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            B.Com
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            LLB
                                            <i className="input-helper"></i></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-primary" data-bs-dismiss="modal">Apply Filiter</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="AddSkills">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Add Skills</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Project management
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Cybersecurity
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Cloud computing
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Data analytics and data science
                                            <i className="input-helper"></i></label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Networking and wireless
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Security Services
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Software development
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            AI and machine learning
                                            <i className="input-helper"></i></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-primary" data-bs-dismiss="modal">Apply Filiter</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="AddLanguages">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Add Languages</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Mandarin
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Spanish
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Bengali
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Russian
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Mandarin Chinese
                                            <i className="input-helper"></i></label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Portuguese
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Indonesian
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Japanese
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            German
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Italian
                                            <i className="input-helper"></i></label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Bengali
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Portuguese
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Yue Chinese
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Marathi
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Korean
                                            <i className="input-helper"></i></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-primary" data-bs-dismiss="modal">Apply Filiter</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="AddCertification">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Add Certification</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            AWS Solution Architect
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            AWS DevOps Engineer
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            AWS SysOps Administrator
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            AWS Developer
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            AWS Cloud Practictioner
                                            <i className="input-helper"></i></label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Adobe Certifications
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Cisco Certified Technicians
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Cisco Certified Architect
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Oracle Cloud Certifications
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Oracle Database Certifications
                                            <i className="input-helper"></i></label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Oracle Java Certifications
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Salesforce System Architect
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Salesforce Application Architect
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Scrum Certification
                                            <i className="input-helper"></i></label>
                                    </div>
                                    <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                            <input type="checkbox" className="form-check-input" />
                                            Red Hat OpenStack Certifications
                                            <i className="input-helper"></i></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-primary" data-bs-dismiss="modal">Apply Filiter</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="PublishRFP" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title" id="exampleModalLabel">Confirmation</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <h5>Are you sure you want to Publish the RFP?</h5>
                        </div>
                        <div className="modal-footer">
                            <a className="btn btn-warning btn-md next-btn" data-bs-dismiss="modal" onClick={() => publishRFP()}>Yes</a>
                            <a className="btn btn-warning btn-md previous-btn ml_30" data-bs-dismiss="modal">No</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AutomatchAndPublishRFP