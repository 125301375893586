import { React, useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom';
import Header from '../../../commons/components/Header';
import CaaSSideBar from '../../../commons/components/SideBars/CaaSSideBar';
import { API } from '../../../config/APIConfig';
import axios from 'axios'
import formatDate from '../../../utils/dateformatter';
import logger from '../../../utils/logger';
import $ from "jquery";
import swal from 'sweetalert';
import Pagination from './Pagination';

const IndustryManagemet = () => {
    const [industryList, setIndustryList] = useState([]);
    const [curIndustryList, setCurIndustryList] = useState([]);
    const [industryExists, setIndustryExists] = useState(false);
    const [curIndustry, setCurIndustry] = useState([]);
    const [loading, setLoading] = useState(false);
    const IndustryListAPI = `${API.URL}api/v1/meta/allindustrylist`;
    const IndustryCreateAPI = `${API.URL}api/v1/meta/industry/create`;
    const IndustryUpdateAPI = `${API.URL}api/v1/meta/industry/update`;
    const IndustryNameValidationAPI = `${API.URL}api/v1/meta/validate/industryname`;

    // User is currently on this page
    const [currentPage, setCurrentPage] = useState(1);
    // No of Records to be displayed on each page   
    const [recordsPerPage] = useState(10);
    //pagination
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;



    const getIndustryList = async () => {
        const response = await fetch(IndustryListAPI);
        if (!response.ok) {
            throw new Error('Data coud not be fetched!')
        } else {
            return response.json()
        }
    }
    const bindIndustries = () => {
        setLoading(true);
        getIndustryList()
            .then((data) => {
                if (data.response) {
                    setIndustryList(data.response);
                    setCurIndustryList(data.response);
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e.message)
            });
    }
    useEffect(() => {
        bindIndustries();
        highlightNavMenu();
    }, []);

    const highlightNavMenu = () => {
        $("li.sidebarmenu").removeClass("active");
        $("#caas_industries_li").addClass("active");
    }
    //pagination
    const currentRecords = curIndustryList.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(curIndustryList.length / recordsPerPage)
    //
    const resetIndustryStatus = (id, name, curStatus) => {
        var status = curStatus == 0 ? 1 : 0;
        updateIndustryDetails(id, name, status);
    }
    const editIndustry = () => {
        const id = curIndustry[0]?.industryId
        const status = curIndustry[0]?.industryStatus;
        const name = document.getElementById("editindustryName").value;
        if (!industryExists)
            updateIndustryDetails(id, name, status);
    }
    const updateIndustryDetails = (id, name, status) => {
        let updobj =
        {
            industryId: id,
            industryName: name,
            industryStatus: status,
        }
        var validation = nameValidation(name)
        if (validation) {
            axios.put(IndustryUpdateAPI, updobj)
                .then(response => {
                    if (response.data.status === 200) {
                        bindIndustries();
                        $("#EditIndustry .close").trigger("click");
                        swal("Updated Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                        document.getElementById("editindustryform").reset();
                    }
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }
    }

    const addIndustry = () => {
        var name = document.getElementById("industryName").value;
        var validation = nameValidation(name);
        if (validation === false)
            return
        if (!industryExists) {
            let obj =
            {
                industryName: name,
            }
            axios.post(IndustryCreateAPI, obj).then(response => {
                if (response.data.status === 200) {
                    bindIndustries();
                    $("#AddIndustry .close").trigger("click");
                    swal("Industry Added Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                    document.getElementById("addIndustryForm").reset();
                    removeErrorMsgs();
                }
            })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }
    }
    const removeErrorMsgs = () => {
        $(".industrynameerror").hide();
        $(".industryexitserror").hide();
        // if ($("#industryName").hasClass("error-shadow")) {
        //     $("#industryName").removeClass("error-shadow");
        // }
    }
    const existsCheck = (e) => {
        var eleId = e.target.id;
        var name = document.getElementById(eleId).value;
        if (name == null || name == undefined)
            return
        if (eleId === "industryName") {
            var validation = nameValidation(name);
        }
        if (eleId === "editindustryName") {
            var validation = nameValidation(name);
            var existingName = curIndustry[0]?.industryName;
            if (existingName.trim() === name.trim()) {
                return true
            }

        }
        if (validation) {
            axios.get(`${IndustryNameValidationAPI}?industryName=${name}`)
                .then(response => {
                    if (response.data === true) {
                        // $("#industryName").addClass("error-shadow");
                        // $("#industrynameerror").hide();
                        // $("#existingIndustry").show();
                        $(".industrynameerror").hide();
                        $(".industryexitserror").show();
                        setIndustryExists(true);
                    }
                    else {
                        // if ($("#industryName").hasClass("error-shadow")) {
                        //     $("#industryName").removeClass("error-shadow");
                        // }
                        // $("#existingIndustry").hide();
                        $(".industryexitserror").hide();
                        setIndustryExists(false)
                    }
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }
    }
    const nameValidation = (name) => {
        if (name) {
            $(".industrynameerror").hide()

            return true;
        }

        else {
            $(".industrynameerror").show()
            $(".industryexitserror").hide();
            return false;
        }
    }

    const bindthisIndustry = (id) => {
        removeErrorMsgs();
        const data = industryList?.filter((ele) => ele.industryId == id);
        setCurIndustry([...data]);
        document.getElementById("editindustryName").value = data[0]?.industryName;
    }
    const searchIndustry = () => {
        const keyword = document.getElementById("searchindustry").value;
        const searchedindustry = industryList.filter((ele) => ele.industryName.toLowerCase().includes(keyword.toLowerCase()));
        setCurrentPage(1);
        setCurIndustryList([...searchedindustry]);
    }


    return (
        <>
            <div className="container-scroller">
                <Header entity="CaaS" />
                <div className="container-fluid page-body-wrapper">
                    <CaaSSideBar />
                    {loading ? (
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    ) : (
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-12 grid-margin stretch-card">
                                        <div className="col-lg-12 d-grid">
                                            <div className="grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body pb-0">
                                                        <h4 className="card-title">Industries</h4>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="mt-0 text-left box-shadow cardPad card-body border border-radius">
                                                        <div className="d-flex justify-content-between">
                                                            <a className="btn addindustrybtn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#AddIndustry" title="Add" onClick={removeErrorMsgs}>
                                                                <i className="mdi mdi-note-plus-outline"></i> Add Industry
                                                            </a>



                                                            {/* <div className="col-6">
                                                    <div  className="input-group">
                                                        <input className="form-control" type="search"
                                                            placeholder="Search Industry"
                                                            id="searchindustry"
                                                            onChange={searchIndustry}
                                                        />
                                                        <div className="input-group-addon input-group-append">
 <i className="ti-search input-group-text"></i>
                                                            
                                                        </div>
                                                    </div>
                                                    </div> */}

                                                            <div className="float-end d-flex align-items-center filter-option mb-0">
                                                                <input className="form-control border-secondary border-right-0 rounded-0 search-input" type="search"
                                                                    placeholder="Search Industry"
                                                                    id="searchindustry"
                                                                    onChange={searchIndustry}
                                                                />
                                                                <div className="col-auto">
                                                                    <button className="btn btn-outline-secondary border-left-0 rounded-0 rounded-right search-btn" type="button"><i className="fa fa-search"></i>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>


                                                        <div className="row">
                                                            <div className="col-12 col-sm-12 mt-2">
                                                                <div className="home-tab">
                                                                    <div className="tab-content1">
                                                                        <div className="" id="NewCompany" >
                                                                            <div className="template-demo table-responsive">
                                                                                <Records curIndustryList={currentRecords} bindthisIndustry={bindthisIndustry} resetIndustryStatus={resetIndustryStatus} />
                                                                                <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="modal fade show" id="EditIndustry" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">Edit Industry</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="editindustryform">
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Industry Name <span className="error">*</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="editindustryName" onChange={existsCheck} />
                                        <label id="editindustrynameerror" className="industrynameerror error hide">Industry name is required</label>
                                        <label id="editexistingIndustry" className="industryexitserror error hide">Industry already exists</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">Cancel</a>
                            <a type="button" className="btn btn-warning btn-md next-btn" onClick={editIndustry}>Update</a>

                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade show" id="AddIndustry" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">Add Industry</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="addIndustryForm">
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Industry Name <span className="error">*</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="industryName" onChange={existsCheck} />
                                        <label id="industrynameerror" className="industrynameerror error hide">Industry name is required</label>
                                        <label id="existingIndustry" className="industryexitserror error hide">Industry already exists</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">Cancel</a>
                            <a type="button" className="btn btn-warning btn-md next-btn" onClick={addIndustry}>Add</a>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const Records = ({ curIndustryList, bindthisIndustry, resetIndustryStatus }) => {

    return (
        <table className="table table-bordered mb-3">
            <thead>
                <tr>

                    <th className="text-left-intend">Industry Name</th>
                    <th className="text-center">Status</th>
                    <th className="text-center" colSpan="2">Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    curIndustryList.length != 0 ? (
                        curIndustryList.map((ele, index) => (
                            <tr key={ele?.industryId}>
                                <td className="text-left-intend">{ele?.industryName}</td>
                                <td className="text-center">
                                    <div className={ele?.industryStatus === 1 ? "badge  badge-success" : "badge badge-warning"}>{ele?.industryStatus === 1 ? "Active" : "InActive"}</div>
                                </td>
                                <td className="text-center">
                                    <a className="pb-3" >
                                        <i className="fa-solid fa-pencil" data-bs-toggle="modal" data-bs-target="#EditIndustry" title="Edit" onClick={() => { bindthisIndustry(ele?.industryId) }}></i>
                                    </a></td>
                                <td className='d-grid'>
                                    <div className="d-flex mx-auto">
                                        <label htmlFor="site_state" className="form-check-label">In Active</label>
                                        <div className="form-switch form-check ms-2 margin-0">
                                            <input type="checkbox" className="form-check-input" id="site_state" onChange={() => {
                                                resetIndustryStatus(ele?.industryId, ele?.industryName, ele?.industryStatus);
                                            }} checked={ele?.industryStatus == 1 ? 'checked' : ''} />
                                        </div>
                                        <label htmlFor="site_state" className="form-check-label">Active</label>
                                    </div>


                                </td>

                            </tr>
                        ))
                    ) : (
                        <tr>
                        </tr>
                    )
                }
            </tbody>

        </table>
    )
}
export default IndustryManagemet