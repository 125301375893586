import React, { useEffect, useState } from 'react'
import { API } from '../../../config/APIConfig';
import axios from 'axios';
import profilepic from "../../../assets/images/noav.jpg"
import dateDiff from '../../../utils/dateDiff';
const GETCOMMENTsAPI = `${API.URL}api/v1/social/comment/list`;
const Comments = (props) => {
    const { parentSocialEntityTypeId, parentSocialEntityId } = props;
    const [commentsData, setCommentsData] = useState([]);
    const getComments = () => {
        axios.get(`${GETCOMMENTsAPI}?parentSocialEntityTypeId=${parentSocialEntityTypeId}&parentSocialEntityId=${parentSocialEntityId}`)
            .then(resp => {
                if (resp.data.status === 200) {
                    const commentslst = resp.data.response;
                    setCommentsData([...commentslst]);
                }
            }).catch(error => {
                console.log(error);
            })
    }
    const repeatBindingComments = setInterval(() => {
        clearInterval(repeatBindingComments);
        getComments();
    }, 10000);
    const collapseId = `collapse-${parentSocialEntityTypeId}-${parentSocialEntityId}`;
    const ariaLabellBy = `heading-${parentSocialEntityTypeId}-${parentSocialEntityId}`;
    return (
        <div id={collapseId} className="collapse" role="tabpanel" aria-labelledby={ariaLabellBy} data-bs-parent="#accordion">
            <div className="card-body">
                <div className="list-wrapper">
                    <ul className="todo-list todo-list-rounded mb_0">
                        {
                            commentsData.length != 0 ? (
                                commentsData.map((ele, index) => (

                                    <li className="d-block" key={index}>
                                        <div className="form-check w-100">
                                            <div className="d-flex align-items-center pb-3 border-bottom">
                                                <img className="img-sm rounded-circle" src={ele?.profilePicture ? ele?.profilePicture : profilepic} alt="profile" />
                                                <div className="ms-3">
                                                    <h6 className="mb-1">{ele?.firstName} {ele?.lastName}<span className="text-black-50 ml-10">{dateDiff(ele?.createdDate)}</span></h6>
                                                    <small className="text-muted mb-0">{ele.content} </small>
                                                </div>

                                            </div>
                                            <a href="#" className="text-black-50 mt_10 mb_0 v-middle ml_20 hide" data-bs-toggle="modal" data-bs-target="#UploadPhoto">
                                                <i className="fa-regular fa-heart v-middle pe-1"></i>
                                                <span>12k Likes</span>
                                            </a>

                                            <a href="#" className="text-black-50 v-middle ml_20 hide" data-bs-toggle="modal" data-bs-target="#UploadPhoto">
                                                <i className="fa-regular fa-heart v-middle pe-1"></i>
                                                <span>Reply</span>
                                            </a>
                                        </div>
                                    </li>

                                ))
                            ) : null
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Comments