import { React, useEffect, useState } from 'react'
import "../../../assets/fontawesome6/css/all.min.css";
import "../../../assets/css/materialdesignicons.min.css"
import "../../../assets/css/themify-icons.css"
import "../../../assets/css/style.css"
import "../../../assets/css/select2-bootstrap.min.css"
import "../../../assets/css/select2.min.css"
import "../../../assets/css/custom.css"
import "../../../assets/css/userend.css"
import "../../../assets/css/responsive.css"
import SocialHeader from './SocialHeader';
import SocialFeedNavigations from './SocialFeedNavigations';
import CAASWorkspaceNavigations from './CAASWorkspaceNavigations';
import ProjectAnnouncements from './ProjectAnnouncements';
import CompanyAnnouncements from './CompanyAnnouncements';
import AddToFeed from './AddToFeed';
import Conversations from './Conversations';
import Posts from '../Posts';
import ProfileInfoAnalytics from './ProfileInfoAnalytics';
import profilepic from "../../../assets/images/noav.jpg"
import { API } from '../../../config/APIConfig';
import axios from 'axios';
import swal from 'sweetalert';
import $ from "jquery";
import Connections from './Connections';
import { Toaster } from 'react-hot-toast';
import { successToast, celebrationToast } from '../../../utils/toastUtil';
import { Link, useLocation } from 'react-router-dom'
import logger from '../../../utils/logger';
import { getSkillDetailslList, getCountryStateCityList, getEducationList, getNationalityList } from '../../../utils/meta';
const CAASMEMBERDETAILS = `${API.URL}api/v1/social/caasmember/network`;
const CAASMEMBERDETAILSBYSKILL = `${API.URL}api/v1/social/caasmember/network/skill`;
const SENDINVITATION = `${API.URL}api/v1/social/connection/request`;
const INVITATIONREQUEST = `${API.URL}api/v1/social/connection/request/accept`;
const CAASMembers = (props) => {
    const [caasdetails, setCaasDetails] = useState([]);
    const [caasfiltereddetails, setCaasFilteredDetails] = useState([]);
    const [curuserid, setcurUserId] = useState(0);
    const [curuser, setCurUser] = useState({});
    const [invitemessage, setInviteMessage] = useState('');
    const loggedInUserId = sessionStorage.getItem("userId");
    const roleId = sessionStorage.getItem("roleId");
    const entityId = sessionStorage.getItem("entityId");
    const [skilldetailsMeta, setSkillDetailsMeta] = useState([]);
    const [statesMeta, setStatesMeta] = useState([]);
    const [citiesMeta, setCitiesMeta] = useState([]);
    const [countriesMeta, setCountriesMeta] = useState([]);
    const [institutesMeta, setInstitutesMeta] = useState([]);
    const [nationalitiesMeta, setNationalitiesMeta] = useState([]);
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [filterData, setfilterData] = useState('');
    const curuserName = sessionStorage.getItem("firstname") + " " + sessionStorage.getItem("lastname");

    const searchfilterName = location?.state?.searchfilterName;
    const searchFilterKey = location?.state?.searchFilterKey;

    const getCaasMembersDetails = () => {
        // logger.verbose("Caas member Network", "CAASMembers Component", "getCaasMembersDetails", "GET", "Get Caas Member Network API Hit", `userId=${loggedInUserId}&userEntityId=${entityId}&userRoleId=${roleId}`);
        axios.get(`${CAASMEMBERDETAILS}?userId=${loggedInUserId}&userEntityId=${entityId}&userRoleId=${roleId}`)
            .then(resp => {
                setCaasDetails(resp.data.response);
                setCaasFilteredDetails(resp.data.response);
                setShow(true);
                // logger.verbose("Caas member Network", "CAASMembers Component", "getCaasMembersDetails", "GET", "Get Caas Member Network API Response successfull", JSON.stringify(resp.data.response));
            }).catch(error => {
                console.log(error);
                logger.error("Caas member Network", "CAASMembers Component", "getCaasMembersDetails", "GET", "Get Caas Member Network API Response Failed!!", JSON.stringify(error));
            })
    }
    useEffect(() => {
        bindFilters();
        getCaasMembersDetails();
        if (location.state?.filterValue) {
            console.log(location.state?.filterValue);
            setfilterData(location.state?.filterValue);
        }
        var highlightele = document.getElementById("caasMemberLink");
        highlightele.classList.remove("text-black-50");
        highlightele.classList.add("text-link");
        $("html").removeClass();
        $("html").addClass('caasmembers1');
        $(".box_3 .connectionss").attr('style', 'height:' + (($(window).height()) - 125 + 'px !important'));
        setTimeout(function(){ var box_1 = $(window).height() - $('.box_1 .sticky-top .box_shadow_1:first-child').height();
        $(".box_1 .sticky-top .box_shadow_1 .list-company").attr('style', 'height:' + (box_1-213+ 'px !important'));
    }, 3000);


    }, [])
useEffect(()=>{
    applyFilter()
},[show])
    const applyFilter = () => {
        if (searchFilterKey != "") {
            if (searchfilterName === "skill") {
                $("#filterSkillVal").val(searchFilterKey);
                getCaasMembersDetailsBySkill();
                return;
            }
            if (searchfilterName === "city") {
                $("#filterCityVal").val(searchFilterKey);
                getCaasMembersDetailsByCity();
                return;
            }
            if (searchfilterName === "country") {
                $("#filterCountryVal").val(searchFilterKey);
                getCaasMembersDetailsByCountry();
                return;
            }
            if (searchfilterName === "state") {
                $("#filterStateVal").val(searchFilterKey);
                getCaasMembersDetailsByState();
                return;
            }
            if (searchfilterName === "institute") {
                $("#filterInstituteVal").val(searchFilterKey);
                getCaasMembersDetailsByInstitute();
                return;
            }

        }
    }

    useEffect(() => {
        if (show) {
            if (filterData) {
                console.log(filterData);
                handlefilterdropdownselect(filterData);
                $('#filterval').val('null');
            }
            setShow(false);
        }
    }, [show]);
    useEffect(() => {
        let selectedUser = caasdetails.findIndex((obj => obj.userId == curuserid));
        if (selectedUser != -1) {
            let currentUserpObj = caasdetails[selectedUser];

            setCurUser({ ...currentUserpObj })
        }
    }, [curuserid]);
    const handleMessageChange = event => {
        setInviteMessage(event.target.value);
    };
    const handleSendInvitation = (e) => {
        // console.log(invitemessage);
        // logger.verbose("Send Invitation", "Caas Members Component", "Send Invitation Request Caas Member", "POST", "Send Invitation request to caas Member ", "");
        const requestObj = {
            "requestedByUserId": loggedInUserId,
            "requestedByUserEntityId": entityId,
            "requestedByUserRoleId": roleId,
            "requestedToUserId": curuserid,
            "requestedToUserEntityId": curuser.entityId,
            "requestedToUserRoleId": curuser.roleId,
            "invitationMessage": invitemessage
        }
        // logger.verbose("Send Invitation", "Caas Members Component", "Send Invitation Request Caas Member", "POST", "Send Invitation request object created", JSON.stringify(requestObj));
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
        }
        axios.post(SENDINVITATION, requestObj, {
            headers: headers
        }).then(response => {
            if (response.data.status === 200) {
                successToast('Invitation Sent Successfully');
                setInviteMessage('');
                getCaasMembersDetails();
                // logger.verbose("Send Invitation", "Caas Members Component", "Send Invitation Request Caas Member", "POST", "Send Invitation request Successfully! API response", JSON.stringify(response.data.response));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            logger.error("Send Invitation", "Caas Members Component", "Send Invitation Request Caas Member", "POST", "Send Invitation request API Failed", JSON.stringify(error));
        });
    }

    const handlefilterdropdownselect = (filtervalue) => {
        // console.log(e.target.value);
        console.log(filtervalue);
        resetFilter();
        // var filtervalue = e.target.value;
        if (filtervalue == "null") {
            document.getElementById("caasnamefilter").textContent = "Suggestions";
            var selectedfilteredUser = caasdetails.filter((ele) => ele.isConnected == null && ele.userId != loggedInUserId);
            setCaasFilteredDetails([...selectedfilteredUser]);
        }
        else if (filtervalue == 1) {
            document.getElementById("caasnamefilter").textContent = "Connections";
            var selectedfilteredUser = caasdetails.filter((ele) => ele.isConnected == filtervalue);
            setCaasFilteredDetails([...selectedfilteredUser]);
        }
        else if (filtervalue == 0) {
            document.getElementById("caasnamefilter").textContent = "Invitations";
            var selectedfilteredUser = caasdetails.filter((ele) => ele.isConnected == filtervalue);
            setCaasFilteredDetails([...selectedfilteredUser]);
        }
        else {
            document.getElementById("caasnamefilter").textContent = "Network";
            setCaasFilteredDetails([...caasdetails]);
        }
    }
    const handleInvitation = (userConnectionRequestId, invitationType, requestedByUserId, requestedToUserEntityId, requestedToUserRoleId, firstName, lastName) => {
        // console.log(userConnectionRequestId, invitationType, requestedByUserId, requestedToUserEntityId, requestedToUserRoleId);
        // logger.verbose("Handle Invitation", "Caas Members Component", "Handle Invitation Request Caas Member", "POST", "Handle Invitation request of caas Member ", "");
        const requestObj = {
            "userConnectionRequestId": userConnectionRequestId,
            "requestedByUserId": requestedByUserId,
            "requestedByUserEntityId": requestedToUserEntityId,
            "requestedByUserRoleId": requestedToUserRoleId,
            "requestedToUserId": loggedInUserId,
            "requestedToUserEntityId": entityId,
            "requestedToUserRoleId": roleId,
            "isAccepted": invitationType
        }
        // logger.verbose("Handle Invitation", "Caas Members Component", "Handle Invitation Request Caas Member", "POST", "Handle Invitation request object created ", JSON.stringify(requestObj));
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
        }
        axios.post(INVITATIONREQUEST, requestObj, {
            headers: headers
        }).then(response => {
            if (response.data.status === 200) {
                if (response.data.response[0].status === true) {
                    // celebrationToast('Invitation Accepted Successfully!')
                    celebrationToast(`${firstName} ${lastName} and ${curuserName} are connected!`)
                    // logger.verbose("Handle Invitation", "Caas Members Component", "Handle Invitation Request Caas Member", "POST", "Invitation Accepted Successfully!", JSON.stringify(response.data.response[0]));
                }
                else {
                    successToast('Invitation Rejected Successfully')
                    // logger.verbose("Handle Invitation", "Caas Members Component", "Handle Invitation Request Caas Member", "POST", "Invitation Rejected Successfully!", JSON.stringify(response.data.response[0]));
                }
                getCaasMembersDetails();
            }
        }).catch(error => {
            logger.error("Handle Invitation", "Caas Members Component", "Handle Invitation Request Caas Member", "POST", "Handle Invitation request API Failed", JSON.stringify(error));
            console.error('There was an error!', error);
        });

    }
    const searchMembers = (e) => {
        const key = e.target.value;
        if (key === "")
            setCaasFilteredDetails([...caasdetails]);
        const searchedGroups = caasdetails.filter((ele) => ele.firstName.toLowerCase().includes(key.toLowerCase()) || ele.lastName.toLowerCase().includes(key.toLowerCase()));
        setCaasFilteredDetails([...searchedGroups]);
    }
    const getCaasMembersDetailsBySkill = () => {
        const skillId = $("#filterSkillVal").val()?$("#filterSkillVal").val():searchFilterKey;
        if (skillId === "0"){
            setCaasFilteredDetails([...caasdetails]);
        }else{
            const searchedGroups = caasdetails.filter((ele) => ele.skillIds.includes(skillId + ","));
            setCaasFilteredDetails([...searchedGroups]);
        }
            
      
    }

    const getCaasMembersDetailsByCountry = () => {
        const countryId = $("#filterCountryVal").val()?$("#filterCountryVal").val():searchFilterKey;
        if (countryId === "0")
            setCaasFilteredDetails([...caasdetails]);
        const searchedGroups = caasdetails.filter((ele) => ele.countryId == countryId);
        setCaasFilteredDetails([...searchedGroups]);
    }

    const getCaasMembersDetailsByState = () => {
        const stateId = $("#filterStateVal").val()?$("#filterStateVal").val():searchFilterKey;
        if (stateId === "0")
            setCaasFilteredDetails([...caasdetails]);
        const searchedGroups = caasdetails.filter((ele) => ele.stateId == stateId);
        setCaasFilteredDetails([...searchedGroups]);
    }

    const getCaasMembersDetailsByCity = () => {
        const cityId = $("#filterCityVal").val()?$("#filterCityVal").val():searchFilterKey;
        if (cityId === "0")
            setCaasFilteredDetails([...caasdetails]);
        const searchedGroups = caasdetails.filter((ele) => ele.cityId == cityId);
        setCaasFilteredDetails([...searchedGroups]);
    }

    const getCaasMembersDetailsByInstitute = () => {
        const instituteId = $("#filterInstituteVal").val()?$("#filterInstituteVal").val():searchFilterKey;
        if (instituteId === "0")
            setCaasFilteredDetails([...caasdetails]);
        const searchedGroups = caasdetails.filter((ele) => ele.instituteIds.includes(instituteId + ","));
        setCaasFilteredDetails([...searchedGroups]);
    }
    const getCaasMembersDetailsByNationality = () => {
        const nationalityId = $("#filterNationalityVal").val()?$("#filterNationalityVal").val():searchFilterKey;
        if (nationalityId === "0")
            setCaasFilteredDetails([...caasdetails]);
        const searchedGroups = caasdetails.filter((ele) => ele.nationalityId == nationalityId);
        setCaasFilteredDetails([...searchedGroups]);
    }
    const resetFilter = () => {
        $("#filterSkillVal").val("0");
        $("#filterInstituteVal").val("0");
        $("#filterCountryVal").val("0");
        $("#filterStateVal").val("0");
        $("#filterCityVal").val("0");
        $("#filterNationalityVal").val("0");
        setCaasFilteredDetails([...caasdetails]);
    }
    const bindFilters = (e) => {
        getSkillDetailslList()
            .then((res) => {
                if (res.response) {
                    var skillDetails = res.response.skillMasterList;
                    setSkillDetailsMeta(skillDetails);
                    //applyFilter();
                }
            })
            .catch((e) => {
                //console.log(e.message)
                // logger.error("Skill Details", "PostRFP Component", "getSkillDetailslList", "GET", "Get Skill Details Reaponse Failed", JSON.stringify(e));
            })
        getCountryStateCityList()
            .then((res) => {
                if (res.response) {
                    setCountriesMeta(res.response.countryList);
                    setStatesMeta(res.response.stateList);
                    setCitiesMeta(res.response.cityList);
                    //applyFilter();
                }
            })
            .catch((e) => {
                console.log(e.message)
            })
        getEducationList()
            .then((res) => {
                if (res.response) {
                    setInstitutesMeta(res.response.instituteList);
                    //applyFilter();
                }
            })
            .catch((e) => {
                //console.log(e.message)
            })
        getNationalityList()
            .then((res) => {
                if (res.response) {
                    setNationalitiesMeta(res.response);
                    //applyFilter();
                }
            })
            .catch((e) => {
                //console.log(e.message)
            })
    }
    return (
        <>
            <div className="main-body-section main-body-section-social">
                <SocialHeader />
                <section className="body-section">
                    <div className="body-content container-fluid">
                        <div className="row1 cassmembers2">
                            <div className="col_res1 align-items-stretch box_1 w_171">
                                <div className="sticky-top">
                                    <SocialFeedNavigations />
                                    <ProfileInfoAnalytics />
                                </div>
                            </div>
                            <div className="mob-remove-padding box_2 w_461">
                                {/* <div className="col-lg-5 col-md-8 mob-remove-padding box_2 w_66"> */}
                                <div className="box-home box_shadow_1 px-4">
                                <div className="d-flex justify-content-between align-items-center mb-4">
                                        <div className="d-flex justify-content-start align-items-center mb-0">
                                            <h3 className="fw-bold fs-4 text-nowrap mb-0">CAAS Member <span className="fw-bold fs-4" id="caasnamefilter">Network</span></h3>
                                        </div>

                                        {/*} <div className="float-end d-flex align-items-center filter-option mb-4">

                                            <i className="fa-solid fa-filter me-2 text-black-50 fa-xs" title="Filter"></i>
                                            <select className="form-select " id="filterval" onClick={(e) => { handlefilterdropdownselect(e.target.value) }}>
                                            
                                                <option value="all">All</option>
                                                <option value="1">Your Connections</option>
                                                <option value="0">Invitations</option>
                                                <option value="null">Suggestions</option>
                                            </select>
                                        </div>
                                         <div className="float-end d-flex align-items-center filter-option mb-4">
                                            <i className="fa-solid fa-filter me-2 text-black-50 fa-xs" title="Filter"></i>
                                            <select className="form-select " id="filterCityVal" onChange={getCaasMembersDetailsByCity}>
                                                <option value="0" key={0}>Select City</option>
                                                {
                                                    citiesMeta.length != 0 ? (
                                                        citiesMeta.map((ele, index) => (
                                                            <option key={index} value={ele?.cityId}>{ele?.cityName}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select>
                                        </div>

                                        <div className="float-end d-flex align-items-center filter-option mb-4">
                                            <i className="fa-solid fa-filter me-2 text-black-50 fa-xs" title="Filter"></i>
                                            <select className="form-select " id="filterSkillVal" onChange={getCaasMembersDetailsBySkill}>
                                                <option value="0" key={0}>Select Skill</option>
                                                {
                                                    skilldetailsMeta.length != 0 ? (
                                                        skilldetailsMeta.map((ele, index) => (
                                                            <option key={index} value={ele.skillId}>{ele.skillName}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select>
                                        </div> */}

                                        <div className="d-flex justify-content-start align-items-center ps-4 mb-0">
                                            <div className="float-end d-flex align-items-center filter-option mb-0">
                                                <input className="form-control border-secondary border-right-0 rounded-0 search-input" type="search"
                                                    placeholder="Search Member by Name"
                                                    id="example-search-input5"
                                                    onChange={searchMembers} />
                                                <div className="col-auto">
                                                    <button className="btn btn-outline-secondary border-left-0 rounded-0 rounded-right search-btn" type="button"><i className="fa fa-search"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {
                                        caasfiltereddetails.length != 0 ?
                                            (caasfiltereddetails.filter(caas => caas.userId == loggedInUserId)?.map((elem, index) => (
                                                <div className="d-flex justify-content-between align-items-center" key={index}>
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <img src={elem.profilePicture} alt="CAAS Member" className="img-fluid img-profile" />
                                                        <div className="ps-3" key={elem.userId}>
                                                            <Link to="/cxo/profile" className="text-black-50 d-block" state={{ quotedBy: elem?.userId, role: elem?.roleId }}>
                                                            <p className="mb-2 fw-bold text-wrap">{elem?.firstName} {elem?.lastName} (You)</p>
                                                            </Link>
                                                            <p className="mb-2">{elem.profileTitle}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))) : null}
                                    {
                                        caasfiltereddetails.length != 0 ?
                                            (caasfiltereddetails.filter(caas => caas.userId != loggedInUserId)?.map((elem, index) => (
                                                <div className="d-flex justify-content-between align-items-center mt-2" key={index}>
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <img src={elem.profilePicture ? elem.profilePicture : profilepic} alt="CAAS Member" className="img-fluid img-profile" />
                                                        <div className="ps-3" key={elem.userId}>
                                                            <Link to="/cxo/profile" className="text-black-50 d-block" state={{ quotedBy: elem?.userId, role: elem?.roleId }}>
                                                            <p className="mb-2 fw-bold text-wrap">{elem.firstName} {elem.lastName}</p>
                                                            </Link>
                                                            <p className="mb-2">{elem.profileTitle}</p>
                                                            {/* <p className="mb-2 text-black-50"><i className="fa-solid fa-link fa-sm pe-1"></i> Aravind Sirivelli, John and 810 others</p> */}
                                                            <p className="mb-2 whitetext"><i className="fa-solid fa-link fa-sm pe-1"></i> Aravind Sirivelli, John and 810 others</p>
                                                        </div>
                                                    </div>
                                                    <div className="float-end d-flex justify-content-end">
                                                        {
                                                            elem.isConnected == null ? <a href="#" className="text-link"
                                                                data-bs-toggle="modal" data-bs-target="#ConnectCaasMember" id="connectcaasMemberbtn" data-for={elem.userId} onClick={() => {
                                                                    setcurUserId(elem.userId); setInviteMessage("");
                                                                }}
                                                            > <i className="fa fa-plug" title="Connect CAAS member" ></i> Connect</a>
                                                                : (elem.isConnected == "1" ? <a href="#" className="text-link" style={{ color: '#008000' }}
                                                                    data-bs-toggle="modal" data-bs-target="#MessageCaasMember" id="messagecaasMemberbtn" data-for={elem.userId}                                                              
                                                                    > <i className="fa-solid fa-link" style={{ color: '#008000' }} title="Message CAAS member" ></i> Connected</a> :
                                                                // > <i className="fa-solid fa-message" style={{ color: '#008000' }} title="Message CAAS member" ></i> Message</a> :
                                                                    (elem.requestedUserId == loggedInUserId ?
                                                                        <>   <span className=""><a className="text-link" value={elem.userConnectionRequestId} onClick={() => {
                                                                            handleInvitation(elem.userConnectionRequestId, true, elem.userId, elem.caasUserEntityId, elem.caasUserRoleId, elem.firstName, elem.lastName)
                                                                        }}><i className="fa-solid fa-check"></i> Accept</a></span>
                                                                            <span className=""><a className="text-muted ms-4 me-0" value={elem.userConnectionRequestId} onClick={() => {
                                                                                handleInvitation(elem.userConnectionRequestId, false, elem.userId, elem.caasUserEntityId, elem.caasUserRoleId, elem.firstName, elem.lastName)
                                                                            }}><i className="fa-solid fa-xmark"></i> Ignore</a></span>
                                                                        </> :

                                                                        <span><i className="fa-solid fa-clock"></i> Invitation Sent</span>))
                                                        }


                                                        {/* <a className="text-link" onClick={()=>handleOpen} value={elem.userId}>Connect</a> */}
                                                    </div>
                                                </div>
                                            ))) : null}







                                    {/* {
                                        caasdetails.length != 0 ? (
                                            caasdetails.map((elem, index) => (  
                                                <div className="d-flex justify-content-between align-items-center" key={index}>
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <img src={elem.profilePicture} alt="CAAS Member" className="img-fluid img-profile" />
                                                    <div className="ps-3" key={elem.userId}>                                                        
                                                        <p className="mb-2 fw-bold">{elem.userName}</p>
                                                        <p className="mb-2">{elem.profileTitle}</p>
                                                        <p className="mb-2 text-black-50"><i className="fa-solid fa-link fa-sm pe-1"></i> Aravind Sirivelli, John and 810 others</p>
                                                    </div>
                                                </div>
                                                <div className="float-end"><a className="text-link"value={elem.userId}>Connect</a></div>
                                            </div>

                                              ))
                                        ) : null
                                    }                                   */}
                                    <div className="text-center pt-4"><a className="text-link ">See more</a></div>
                                </div>

                            </div>
                            {/* <Conversations /> */}
                            {/* <Connections /> */}


                            <div className="col_res1 align-items-stretch mob-remove-pe box_3 w_171">
                                <div className="box-home box_shadow_1 px-4 sticky-top caasmemberfilterDiv1">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h3 className="fw-bold fs-4 mb-0">Filters</h3>
                                        <h4 className="text-black-50 fs-4 mb-0 fw-bold">
                                            <button className="btn btn-primary border-radius-20 py-1" onClick={resetFilter}> Reset
                                            </button>
                                        </h4>
                                        <div>
                                            <i className="fa-solid fa-circle-info" title="Filters for searching CAAS Members"></i>
                                        </div>
                                    </div>
                                    <div className="row no-gutters mt-4">

                                    </div>
                                    <div className="mt-4 connectionss">

                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <label className="label-for-filter filterLabel mt-0 mb_10"> Primary Filter </label>
                                                <div className="d-flex align-items-center filter-option mb-2 border-radius-20">
                                                    <i className="fa-solid fa-filter me-2 text-black-50 fa-xs" title="Filter"></i>
                                                    <select className="form-select filterselect border-radius-20" id="filterval" onClick={(e) => { handlefilterdropdownselect(e.target.value) }}>
                                                        <option value="all">All</option>
                                                        <option value="1">Your Connections</option>
                                                        <option value="0">Invitations</option>
                                                        <option value="null">Suggestions</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <label className="label-for-filter filterLabel mt_10 mb_10"> Skill </label>
                                                <div className="d-flex align-items-center filter-option mb-2 border-radius-20">
                                                    <i className="fa-solid fa-filter me-2 text-black-50 fa-xs" title="Filter"></i>
                                                    <select className="form-select filterselect border-radius-20" id="filterSkillVal" onChange={getCaasMembersDetailsBySkill}>
                                                        <option value="0" key={0}>Select Skill</option>
                                                        {
                                                            skilldetailsMeta.length != 0 ? (
                                                                skilldetailsMeta.map((ele, index) => (
                                                                    <option key={index} value={ele.skillId}>{ele.skillName}</option>
                                                                ))
                                                            ) : null
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <label className="label-for-filter filterLabel mt_10 mb_10"> University </label>
                                                <div className="d-flex align-items-center filter-option mb-2 border-radius-20">
                                                    <i className="fa-solid fa-filter me-2 text-black-50 fa-xs" title="Filter"></i>
                                                    <select className="form-select filterselect border-radius-20" id="filterInstituteVal" onChange={getCaasMembersDetailsByInstitute}>
                                                        <option value="0" key={0}>Select University</option>
                                                        {
                                                            institutesMeta.length != 0 ? (
                                                                institutesMeta.map((ele, index) => (
                                                                    <option key={index} value={ele.instituteId}>{ele.instituteName}</option>
                                                                ))
                                                            ) : null
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>





                                        <div className="mt-4 border_bottom">
                                            <p className="text-black-50 fw-bold"> Location Based Filtering: </p>


                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <label className="label-for-filter filterLabel mt_10 mb_10">  Country: </label>
                                                    <div className="d-flex align-items-center filter-option mb-2 border-radius-20">
                                                        <i className="fa-solid fa-filter me-2 text-black-50 fa-xs" title="Filter"></i>
                                                        <select className="form-select filterselect border-radius-20" id="filterCountryVal" onChange={getCaasMembersDetailsByCountry}>
                                                            <option value="0" key={0}>Select Country</option>
                                                            {
                                                                countriesMeta.length != 0 ? (
                                                                    countriesMeta.map((ele, index) => (
                                                                        <option key={index} value={ele?.countryId}>{ele?.countryName}</option>
                                                                    ))
                                                                ) : null
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <label className="label-for-filter filterLabel mt_10 mb_10">  State: </label>
                                                    <div className="d-flex align-items-center filter-option mb-2 border-radius-20">
                                                        <i className="fa-solid fa-filter me-2 text-black-50 fa-xs" title="Filter"></i>
                                                        <select className="form-select filterselect border-radius-20" id="filterStateVal" onChange={getCaasMembersDetailsByState}>
                                                            <option value="0" key={0}>Select State</option>
                                                            {
                                                                statesMeta.length != 0 ? (
                                                                    statesMeta.map((ele, index) => (
                                                                        <option key={index} value={ele?.stateId}>{ele?.stateName}</option>
                                                                    ))
                                                                ) : null
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <label className="label-for-filter filterLabel mt_10 mb_10">  City: </label>
                                                    <div className="d-flex align-items-center filter-option mb-2 border-radius-20">
                                                        <i className="fa-solid fa-filter me-2 text-black-50 fa-xs" title="Filter"></i>
                                                        <select className="form-select filterselect border-radius-20" id="filterCityVal" onChange={getCaasMembersDetailsByCity}>
                                                            <option value="0" key={0}>Select City</option>
                                                            {
                                                                citiesMeta.length != 0 ? (
                                                                    citiesMeta.map((ele, index) => (
                                                                        <option key={index} value={ele?.cityId}>{ele?.cityName}</option>
                                                                    ))
                                                                ) : null
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <label className="label-for-filter filterLabel mt_10 mb_10">  Nationality: </label>
                                                    <div className="d-flex align-items-center filter-option mb-2 border-radius-20">
                                                        <i className="fa-solid fa-filter me-2 text-black-50 fa-xs" title="Filter"></i>
                                                        <select className="form-select filterselect border-radius-20" id="filterNationalityVal" onChange={getCaasMembersDetailsByNationality}>
                                                            <option value="0" key={0}>Select Nationality</option>
                                                            {
                                                                nationalitiesMeta.length != 0 ? (
                                                                    nationalitiesMeta.map((ele, index) => (
                                                                        <option key={index} value={ele.nationalityId}>{ele.nationalityName}</option>
                                                                    ))
                                                                ) : null
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>





                            <div className="col_res1 align-items-stretch box_4 w_171">
                                <div className="sticky-top">
                                    <CAASWorkspaceNavigations />
                                    {/* <ProjectAnnouncements /> */}
                                    <CompanyAnnouncements />
                                    <Conversations />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="footer-sections"></section>
            </div>
            <div className="modal fade show" id="UploadPhoto">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title text-black-50" id="exampleModalLabel">
                                Create a post
                            </h3>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex align-items-center">
                                <img
                                    src="../images/stevejobs.jpg"
                                    alt="steve"
                                    className="img-fluid rounded-circle img-profile me-3"
                                />
                                <div>
                                    <p className="fw-bold">Steve Jobs</p>
                                    <select className="form-select">
                                        <option>Anyone</option>
                                        <option>Anyone + Twitter</option>
                                        <option>Connections Only</option>
                                    </select>
                                </div>
                            </div>
                            <div className="cursor mt-3">
                                <input
                                    type="text"
                                    className="form-control border-0 ps-0"
                                    placeholder="what do you want to talk about?"
                                />
                                <i></i>
                            </div>
                            <div className="hashtag mt-5 pt-5">
                                <a href="#" className="text-link fw-bold"
                                >Add hashtag</a
                                >
                            </div>
                        </div>
                        <div className="modal-footer text-black-50 justify-content-between">
                            <div>
                                <a href="#" className="text-black-50 m-0 me-3">
                                    <i className="fa-regular fa-image"></i>
                                </a>
                                <a href="#" className="text-black-50 m-0 me-3">
                                    <i className="fa-brands fa-youtube"></i>
                                </a>
                                <a href="#" className="text-black-50 m-0 me-3">
                                    <i className="fa-solid fa-file-lines"></i>
                                </a>
                                <a href="#" className="text-black-50 m-0 me-3">
                                    <i className="fa-solid fa-briefcase"></i>
                                </a>
                                <a href="#" className="text-black-50 m-0 me-3">
                                    <i className="fa-solid fa-sun"></i>
                                </a>
                                <a href="#" className="text-black-50 m-0 me-3">
                                    <span className="mdi mdi-chart-bar"></span>
                                </a>
                                <a href="#" className="text-black-50 m-0 me-4">
                                    <i className="fa-solid fa-ellipsis"></i>
                                </a>
                                <a href="#" className="text-black-50 m-0 me-5 boeder-left ps-4">
                                    <span className="mdi mdi-chat-processing-outline"></span>
                                    Anyone
                                </a>
                            </div>
                            <div>
                                <a className="btn btn-light btn-sm float-end disabled">Post</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show " id="ConnectCaasMember">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">Send Invitation</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="d-flex justify-content-between align-items-center mt-2">
                                    <div className="d-flex justify-content-start align-items-center">
                                        <img src={curuser.profilePicture} alt="CAAS Member" className="img-fluid img-profile" />
                                        <div className="ps-3" key={curuser.userId}>
                                            <p className="mb-2 fw-bold">{curuser.firstName} {curuser.lastName}</p>
                                            <p className="mb-2">{curuser.profileTitle}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-12 col-sm-12 mt-3">
                                    <label className="invitemsg"><b className="bold">Invitation Message</b></label>
                                    <textarea className="form-control" placeholder="Invitation Message" rows="4" id="invitemessage" value={invitemessage}
                                        onChange={handleMessageChange} ></textarea>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-primary btn-md next-btn" data-bs-dismiss="modal" onClick={handleSendInvitation}>Send Request</a>
                            <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster />
        </>
    )
}

export default CAASMembers