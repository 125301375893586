import React from 'react'
import Header from '../../../commons/components/Header'
import Footer from '../../../commons/components/Footer'
import CXOSideBar from '../../../commons/components/SideBars/CXOSideBar'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import OpenRFPCXO from './OpenRFPCXO'
import ClosedRFPCXO from './ClosedRFPCXO'
import ExpiredRFPCXO from './ExpiredRFPCXO'
import WithdrawnRFPCXO from './WithdrawnRFPCXO'
import { RFPListCIOContext } from './contexts/RFPListCIOContext'
import { API } from '../../../config/APIConfig';
import axios from 'axios'
import { useTranslation } from "react-i18next";
import logger from '../../../utils/logger'
const PUBLISHEDRFPLIST = `${API.URL}api/v1/rfp/publishedrfp/list`; // HAS TO BE CHANGED 

const ManageCXORFP = () => {
    const { i18n, t } = useTranslation(["rfp"]);
    const [statusFilter, setStatusFilter] = useState("all");
    const [RFPList, setRFPList] = useState([]);
    const [curRFPList, setCurRFPList] = useState([]);
    // const enterpriseId = 0; //HAS TO BE REMOVED   
    const userId =sessionStorage.getItem('userId');
    const changeStatusFilter = (e) => {
        let value = document.getElementById("rfpStatusFilter").value;
        setStatusFilter(value);
        // logger.verbose("RFP Status Filter", "ManageCXORFP Component", "changeStatusFilter", "GET", "User Clicked RFP Status Filter", `changeStatusFiltervalue: ${value}`);
    }
    useEffect(() => {
        getPublishedRFPDetails();
        var highlightele = document.getElementById("RFPSidNav");
        highlightele.classList.add("active");
    }, []);
    const getPublishedRFPDetails = () => {
        // logger.verbose("RFP Details", "ManageCXORFP Component", "getPublishedRFPDetails", "GET", "Get RFP Details API Hit", `enterpriseId=${enterpriseId}&userId=${userId}`);
        axios.get(`${PUBLISHEDRFPLIST}?userId=${userId}`).then(response => {
            if (response.data.status === 200) {
                setRFPList(response.data.response);
                setCurRFPList(response.data.response);
                // logger.verbose("RFP Details", "ManageCXORFP Component", "getPublishedRFPDetails", "GET", "Get RFP Details API Response Successfull", JSON.stringify(response.data.response));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            // logger.error("RFP Details", "ManageCXORFP Component", "getPublishedRFPDetails", "GET", "Get RFP Details API Response Successfull", JSON.stringify(error));
        });
    }
    const searchRFP = () => {
        const keyword = document.getElementById("searchcxorfp").value;
        const searchedRFP = RFPList.filter((ele) => ele?.rfpTitle?.toLowerCase().includes(keyword.toLowerCase().trim()));
        setCurRFPList([...searchedRFP]);
    }

    return (
        <div className="container-scroller">
            <Header entity="CXO" />
            <div className="container-fluid page-body-wrapper">
                <CXOSideBar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">
                                    {t('rfps')}
                                        {/* <Link to="/cxo/cxo/dashboard" className="text-blue fs-5 float-sm-right"><span className=" mdi mdi-arrow-left"></span>{t('backToDashboard')}</Link> */}
                                    </h4>
                                    <div className="d-flex mb-0 text-muted">
                                        <span className="mdi mdi-filter"></span>
                                        <a className="filter_by" data-toggle="modal" data-bs-toggle="modal" data-bs-target="#statusfilter">{t('filterByAction')}</a>
                                    </div>
                                    <div className="float-end d-flex align-items-center filter-option mb-0">
                                                        <input className="form-control border-secondary border-right-0 rounded-0 search-input" type="search"
                                                            placeholder="Search RFP"
                                                            id="searchcxorfp"
                                                            onChange={searchRFP}
                                                            onPaste={searchRFP}
                                                        />
                                                        <div className="col-auto">
                                                            <button className="btn btn-outline-secondary border-left-0 rounded-0 rounded-right search-btn" type="button"><i className="fa fa-search"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                </div>
                                <RFPListCIOContext.Provider value={{curRFPList, setCurRFPList }}>
                                    <div className="col-md-12 mx-auto">
                                        {statusFilter == "all" || statusFilter == "open" ? <OpenRFPCXO /> : null}
                                        {statusFilter == "all" || statusFilter == "closed" ? <ClosedRFPCXO /> : null}
                                        {statusFilter == "all" || statusFilter == "expired" ? <ExpiredRFPCXO /> : null}
                                        {statusFilter == "all" || statusFilter == "withdrawn" ? <WithdrawnRFPCXO /> : null}
                                    </div>
                                </RFPListCIOContext.Provider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="statusfilter" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title" id="exampleModalLabel">{t('hfilter')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">{t('filterByStatus')}</label>
                                <div className="col-sm-8">
                                    <select className="form-select" id="rfpStatusFilter">
                                    <option value="all">{t('All')}</option>
                                        <option value="open">{t('Open')}</option>
                                        <option value="closed">{t('Closed')}</option>
                                        <option value="expired">{t('Expired')}</option>
                                        <option value="withdrawn">{t('Withdrawn')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-warning btn-md next-btn" data-bs-dismiss="modal" onClick={changeStatusFilter}>{t('apply')}</a>
                            <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ManageCXORFP