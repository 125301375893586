import React from 'react'
import { Link } from 'react-router-dom'
const CAASWorkspaceNavigations = () => {
    const roleId = sessionStorage.getItem("roleId");
    const userId = sessionStorage.getItem("userId");
    return (
        <div className="box-home box_shadow_1 px-4 workspace-container">
            <div
                className="d-flex align-items-center justify-content-between"
            >
                <h3 className="fw-bold fs-4 mb-0">CAAS Workspace</h3>
                <div>
                    <i className="fa-solid fa-circle-info" title="You can access your Workspace Menus here"></i>
                </div>
            </div>
            <div className="main-right icon-box">
                <Link to="/cxo/myprofile" className="text-black-50 d-block mb-4 mt-4 d-flex align-items-center" state={{ quotedBy: userId, role: roleId }}>
                    <i className="fa-regular fa-address-card text-black-50 me-3"
                    ></i>
                    My Profile
                </Link>
                <Link to="/cxo/cxo/rfp/manage" className="text-black-50 d-block mb-4 d-flex align-items-center">
                    <i className="fa-regular fa-rectangle-list me-3"></i>
                    RFPs
                </Link>



                {/* 
                <a href="#" className="text-black-50 d-block mb-4">
                    <i className="fa-regular fa-rectangle-list me-3"></i>
                    RFPs</a
                > */}
     <Link to="/cxo/cxo/rfp/myrfps" className="text-black-50 d-block  d-flex  align-items-center ">
                    <i className="fa-regular fa-file-lines fa-1x me-3 d-flex justify-content-center align-item-center"></i>
                    My RFPs</Link>
                {/* <a href="#" className="text-black-50 d-block">
                    <span className="mdi mdi-database-outline me-3"></span>
                    Projects</a
                > */}
            </div>
        </div>
    )
}

export default CAASWorkspaceNavigations