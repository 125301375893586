import React, { useState, useEffect, useRef } from 'react'
import AuthHeader from '../Auth/AuthHeader';
import { useTranslation } from "react-i18next";
import $ from "jquery";
import { API } from '../../config/APIConfig';
import axios from 'axios';
import swal from 'sweetalert';
import { getCountryStateCityList, getHireCaasIndustryDetailsList, getHireCaasCompanySizeDetailsList } from '../../utils/meta';
import { Link, useNavigate } from 'react-router-dom';
import logger from '../../utils/logger';
const INSERTHIRECAASENQUIRYAPI = `${API.URL}api/v1/hirecaas/insertenquiry`;
const HireCaas = () => {
    const emailform = useRef();
    const navigate = useNavigate();
    const [hirecaasenquiryinfo, setHireCaasEnquiryInfo] = useState({});
    const [countriesMeta, setCountriesMeta] = useState([]);
    const [countryphonecodeMeta, setCountryPhoneCodeMeta] = useState([]);
    const [hirecaasindustry, seHireCaasIndustryeMeta] = useState([]);
    const [hirecaascompanysize, seHireCaasCompanySize] = useState([]);
    const [curCountryphonecodeMeta, setCurCountryPhoneCodeMeta] = useState([]);
    const { i18n, t } = useTranslation(["registration"]);
    const handleLanguageChange = (e) => {
        i18n.changeLanguage(e.target.value);
    };
    useEffect(() => {
        if (localStorage.getItem("i18nextLng")?.length > 2) {
            i18n.changeLanguage("en");
        }
        bindDropDownData();
        bindHandlers();
    }, []);
    const bindDropDownData = () => {
        getCountryStateCityList()
            .then((res) => {
                if (res.response) {
                    setCountriesMeta(res.response.countryList);
                    setCountryPhoneCodeMeta(res.response.phoneCodeList);
                    setCurCountryPhoneCodeMeta(res.response.phoneCodeList);
                }
            })
            .catch((e) => {
                console.log(e.message)
            })
        getHireCaasIndustryDetailsList()
            .then((data) => {
                if (data.response) {
                    seHireCaasIndustryeMeta(data.response);
                }
            })
            .catch((e) => {
                console.log(e.message)
            })
        getHireCaasCompanySizeDetailsList()
            .then((data) => {
                if (data.response) {
                    seHireCaasCompanySize(data.response);
                }
            })
            .catch((e) => {
                console.log(e.message)
            })
    }
    const handleInput = (e) => {
        var id = e.target.id;
        if (id === "firstName") {
            var firstNameValue = e.target.value.replace(/[^a-z ]/gi, "")
            e.target.value = firstNameValue;
        }
        if (id === "lastName") {
            var lastNameValue = e.target.value.replace(/[^a-z ]/gi, "")
            e.target.value = lastNameValue;
        }
        if (id === "country") {
            resetCountryCodeList(e)
        }
        if (id === "phoneNumber") {
            var phoneNumberValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = phoneNumberValue;
        }


        var value = e.target.value;
        setHireCaasEnquiryInfo({ ...hirecaasenquiryinfo, [id]: value });
        // console.log(enterpriseInfo);
        checkValidation(e.target.id);
    }

    const checkValidation = (id) => {
        if (id === "" || id === undefined) {
            return allcheckValidation()
        }
        else {
            return singlecheckValidation(id)
        }
    }
    const singlecheckValidation = (id) => {
        var flag = true;
        var single_flaglist = []

        var obj = {
            "firstName": document.getElementById("firstName").value,
            "lastName": document.getElementById("lastName").value,
            "emailAddress": document.getElementById("emailAddress").value,
            "countryId": document.getElementById("countryId").value,
            "countryPhoneCodeId": document.getElementById("countryPhoneCodeId").value,
            "phoneNumber": document.getElementById("phoneNumber").value,
            "companyName": document.getElementById("companyName").value,
            "designation": document.getElementById("designation").value,
            "additionalInformation": document.getElementById("additionalInformation").value,
            "hireCaasIndustryId": document.getElementById("hireCaasIndustryId").value,
            "hireCaasCompanySizeId": document.getElementById("hireCaasCompanySizeId").value
        }
        if (id === "firstName") {
            if (!obj.firstName.trim()) {
                $("#firstNameerror").show();
                $("#firstName").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
            }
            else {
                $("#firstNameerror").hide();
                if ($("#firstName").hasClass('error-shadow'))
                    $("#firstName").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id === "lastName") {
            if (!obj.lastName.trim()) {
                $("#lastNameeerror").show();
                $("#lastName").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
            }
            else {
                $("#lastNameeerror").hide();
                if ($("#lastName").hasClass('error-shadow'))
                    $("#lastName").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }

        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (id == "emailAddress") {
            if (!obj.emailAddress.trim()) {
                $("#emailerror").show();
                $("#emailAddress").addClass("error-shadow");
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else if (!regex.test(obj.emailAddress)) {
                $("#emailerror").show();
                $("#emailerror").text("Please enter a valid email address");
                $("#emailAddress").addClass("error-shadow");

                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#emailerror").hide();
                if ($("#emailAddress").hasClass('error-shadow'))
                    $("#emailAddress").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "countryId") {
            if (obj.countryId == 0) {
                $("#countryerror").show();
                $("#countryId").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#countryerror").hide();
                if ($("#countryId").hasClass('error-shadow'))
                    $("#countryId").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "countryPhoneCodeId") {
            if (obj.countryPhoneCodeId == 0) {
                $("#countryPhoneCodeIderror").show();
                $("#countryPhoneCodeId").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#countryPhoneCodeIderror").hide();
                if ($("#countryPhoneCodeId").hasClass('error-shadow'))
                    $("#countryPhoneCodeId").removeClass('error-shadow');

                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "phoneNumber") {
            if (!obj.phoneNumber.trim()) {
                $("#phonenumbererror").show();
                $("#phoneNumber").addClass("error-shadow");

                flag = false;
                single_flaglist.push(flag);
                return;
            }
            // else if (!phoneregex.test(obj.phoneNumber)) {
            else if ((obj.phoneNumber.length > 13)) {
                $("#phonenumbererror").show();
                $("#phonenumbererror").text("Please enter the phone number less than 13 digit");
                // $("#phonenumbererror").show();
                // $("#phonenumbererror").text("Please enter the 10 digit phone number");
                $("#phoneNumber").addClass("error-shadow");
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#phonenumbererror").hide();
                if ($("#phoneNumber").hasClass('error-shadow'))
                    $("#phoneNumber").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }

        }
        if (id == "companyName") {
            if (!obj.companyName.trim()) {
                $("#companyNameerror").show();
                $("#companyName").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#companyNameerror").hide();
                if ($("#companyName").hasClass('error-shadow'))
                    $("#companyName").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "designation") {
            if (!obj.designation.trim()) {
                $("#designationerror").show();
                $("#designation").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#designationerror").hide();
                if ($("#designation").hasClass('error-shadow'))
                    $("#designation").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "additionalInformation") {
            if (!obj.additionalInformation.trim()) {
                $("#additionalInformationerror").show();
                $("#additionalInformation").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                flag = true;
                single_flaglist.push(flag);
                if ($("#additionalInformation").hasClass('error-shadow'))
                    $("#additionalInformation").removeClass('error-shadow');
                $("#additionalInformationerror").hide();
            }
        }
        if (id == "hireCaasIndustryId") {
            if (obj.hireCaasIndustryId == 0) {
                $("#hireCaasIndustryIderror").show();
                $("#hireCaasIndustryId").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#hireCaasIndustryIderror").hide();
                if ($("#hireCaasIndustryId").hasClass('error-shadow'))
                    $("#hireCaasIndustryId").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "hireCaasCompanySizeId") {
            if (obj.hireCaasCompanySizeId == 0) {
                $("#hireCaasCompanySizeIderror").show();
                $("#hireCaasCompanySizeId").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#hireCaasCompanySizeIderror").hide();
                if ($("#hireCaasCompanySizeId").hasClass('error-shadow'))
                    $("#hireCaasCompanySizeId").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        return single_flaglist;
    }

    const allcheckValidation = () => {
        var flag = true;
        var flaglist = []
        var obj = {
            "firstName": document.getElementById("firstName").value,
            "lastName": document.getElementById("lastName").value,
            "emailAddress": document.getElementById("emailAddress").value,
            "countryId": document.getElementById("countryId").value,
            "countryPhoneCodeId": document.getElementById("countryPhoneCodeId").value,
            "phoneNumber": document.getElementById("phoneNumber").value,
            "companyName": document.getElementById("companyName").value,
            "designation": document.getElementById("designation").value,
            "additionalInformation": document.getElementById("additionalInformation").value,
            "hireCaasIndustryId": document.getElementById("hireCaasIndustryId").value,
            "hireCaasCompanySizeId": document.getElementById("hireCaasCompanySizeId").value
        }
        if (!obj.firstName.trim()) {
            $("#firstNameerror").show();
            $("#firstName").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#firstNameerror").hide();
            if ($("#firstName").hasClass('error-shadow'))
                $("#firstName").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (!obj.lastName.trim()) {
            $("#lastNameeerror").show();
            $("#lastName").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#lastNameeerror").hide();
            if ($("#lastName").hasClass('error-shadow'))
                $("#lastName").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!obj.emailAddress.trim()) {
            $("#emailerror").show();
            $("#emailAddress").addClass("error-shadow");
            flag = false;
            flaglist.push(flag);
        }
        else if (!regex.test(obj.emailAddress)) {
            $("#emailerror").show();
            $("#emailerror").text("Please enter a valid email address");
            $("#emailAddress").addClass("error-shadow");
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#emailerror").hide();
            if ($("#emailAddress").hasClass('error-shadow'))
                $("#emailAddress").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (obj.countryId == 0) {
            $("#countryerror").show();
            $("#countryId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#countryerror").hide();
            if ($("#countryId").hasClass('error-shadow'))
                $("#countryId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (obj.countryPhoneCodeId == 0) {
            $("#countryPhoneCodeIderror").show();
            $("#countryPhoneCodeId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#countryPhoneCodeIderror").hide();
            if ($("#countryPhoneCodeId").hasClass('error-shadow'))
                $("#countryPhoneCodeId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);

        }
        if (!obj.phoneNumber.trim()) {
            $("#phonenumbererror").show();
            $("#phoneNumber").addClass("error-shadow");
            flag = false;
            flaglist.push(flag);
        }
        else if ((obj.phoneNumber.length > 13)) {
            $("#phonenumbererror").show();
            $("#phonenumbererror").text("Please enter the phone number less than 13 digit");
            $("#phoneNumber").addClass("error-shadow");
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#phonenumbererror").hide();
            if ($("#phoneNumber").hasClass('error-shadow'))
                $("#phoneNumber").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (!obj.companyName.trim()) {
            $("#companyNameerror").show();
            $("#companyName").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#companyNameerror").hide();
            if ($("#companyName").hasClass('error-shadow'))
                $("#companyName").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (!obj.designation.trim()) {
            $("#designationerror").show();
            $("#designation").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#designationerror").hide();
            if ($("#designation").hasClass('error-shadow'))
                $("#designation").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (!obj.additionalInformation.trim()) {
            $("#additionalInformationerror").show();
            $("#additionalInformation").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            if ($("#additionalInformation").hasClass('error-shadow'))
                $("#additionalInformation").removeClass('error-shadow');
            $("#additionalInformationerror").hide();
            flag = true;
            flaglist.push(flag);
        }
        if (obj.hireCaasIndustryId == 0) {
            $("#hireCaasIndustryIderror").show();
            $("#hireCaasIndustryId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#hireCaasIndustryIderror").hide();
            if ($("#hireCaasIndustryId").hasClass('error-shadow'))
                $("#hireCaasIndustryId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (obj.hireCaasCompanySizeId == 0) {
            $("#hireCaasCompanySizeIderror").show();
            $("#hireCaasCompanySizeId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#hireCaasCompanySizeIderror").hide();
            if ($("#hireCaasCompanySizeId").hasClass('error-shadow'))
                $("#hireCaasCompanySizeId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        //added


        return flaglist;
    }

    const sendHireCaasEnquiry = () => {
        // console.log(enterpriseInfo);
        $(".hirecaasbtn").addClass("disabled");
        // var flag=true;
        var flaglist = checkValidation();
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;


        if (flag) {
            hirecaasenquiryinfo.countryPhoneCodeId = hirecaasenquiryinfo.countryPhoneCodeId ? hirecaasenquiryinfo.countryPhoneCodeId : document.getElementById("countryPhoneCodeId").value;
            hirecaasenquiryinfo.countryName = $("#countryId option:selected").text();
            hirecaasenquiryinfo.countryPhoneCodeName = $("#countryPhoneCodeId option:selected").text()?.split(" ")[0];
            hirecaasenquiryinfo.hireCaasIndustry = $("#hireCaasIndustryId option:selected").text();
            hirecaasenquiryinfo.hireCaasCompanySize = $("#hireCaasCompanySizeId option:selected").text();
            //logger.verbose("Enterprise Registration", "EnterpriseRegistration Component", "registerEnterprise", "POST", "Enterprise Registration Validation successfull", JSON.stringify(flag));
            if (Object.keys(hirecaasenquiryinfo).length !== 0) {
                //logger.verbose("Enterprise Registration", "EnterpriseRegistration Component", "registerEnterprise", "POST", "Enterprise Registration Object created for API", JSON.stringify(enterpriseInfo));
                console.log(JSON.stringify(hirecaasenquiryinfo));
                axios.post(INSERTHIRECAASENQUIRYAPI, hirecaasenquiryinfo)
                    .then(response => {
                        // console.log(response);
                        if (response.data.status === 200) {
                            // sendMail();
                            // $("#EditEnterprise").show();
                            swal("Thank you for Inquiring!", "", "success", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } });

                            //logger.verbose("Enterprise Registration", "EnterpriseRegistration Component", "registerEnterprise", "POST", "Enterprise Registration API response successfull", JSON.stringify(response.data));
                        }
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                        //logger.error("Enterprise Registration", "EnterpriseRegistration Component", "registerEnterprise", "POST", "Enterprise Registration API response Failed", JSON.stringify(error));
                    });
            }
        }
        else {
            $(".hirecaasbtn").removeClass("disabled");
            // console.log("Failed to register");
            swal("Please fill the Details", "", "error", { closeOnClickOutside: false, closeOnEsc: false });
            //logger.verbose("Enterprise Registration", "EnterpriseRegistration Component", "registerEnterprise", "POST", "Enterprise Registration Validation Failed", JSON.stringify(flag));
        }
    }
    const resetCountryCodeList = (e) => {
        var id = e.target.value;
        if (id == "0") {
            setCurCountryPhoneCodeMeta([...countryphonecodeMeta]);
            return;
        }
        var newCodeList = countryphonecodeMeta.filter((ele) => ele.countryId == id);
        setCurCountryPhoneCodeMeta([...newCodeList]);
        // $("#countryPhoneCodeId").val(newCodeList[0].countryPhoneCodeId);
    }
    useEffect(() => {
        if (curCountryphonecodeMeta.length == 1) {
            $("#countryPhoneCodeId").val(curCountryphonecodeMeta[0]?.countryPhoneCodeId);
            singlecheckValidation("countryPhoneCodeId");

        }
        else {
            $("#countryPhoneCodeId").val(0);
        }

    }, [curCountryphonecodeMeta])
    const bindHandlers = () => {
        $("#showhide_pi").removeClass("hide");
        // $(".addcompany").on("click", function () {
        //   $("#showhide_pi").removeClass("hide");
        //   $("#dispshow").addClass("hide");
        //   $("#addpi_btn").addClass("hide");
        // });
    }
    return (
        <div className="sidebar-icon-only with-welcome-text" data-new-gr-c-s-check-loaded="14.1071.0" data-gr-ext-installed="">
            <div className="container-scroller">
                <AuthHeader />
            </div>
            <div className="container-fluid page-body-wrapper">
                {/* <nav className="sidebar sidebar-offcanvas" id="sidebar">
                    <ul className="nav">
                        <li className="nav-item title-card" id="RFP_reg">
                            <a className="nav-link text-center pl_2 br-btm" href="#">
                                <span className="menu-title2 sidebar-title main-title fw-bold">{t('hregistration')}</span>
                            </a>
                        </li>
                        <li className="nav-item active">
                            <a className="nav-link" href="#">
                                <i className="mdi mdi-view-dashboard menu-icon"></i>
                                <span className="menu-title1 sidebar-title">{t('enterpriseRegistration')}</span>
                            </a>
                        </li>
                    </ul>
                </nav> */}
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-md-12 grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h4 className="card-title">
                                                        Hire CAAS
                                                        <Link to="/" class="addcompany btn-primary add-pi float-right btn-rounded btn-icon" title="Cancel and Exit"><i class="fa fa-close ico" aria-hidden="true"></i></Link>
                                                    </h4>
                                                    <div className="hide" id="showhide_pi">
                                                        <div className="content clearfix">
                                                            <section className="body current" id="steps-uid-0-p-0" role="tabpanel" aria-labelledby="steps-uid-0-h-0" aria-hidden="false">
                                                                <div className="row mb-0">
                                                                    <div className="form-group col-12 col-sm-6">
                                                                        <label>First Name <span className="error">*</span></label>
                                                                        <input type="text" className="form-control" placeholder="First Name" id="firstName" onChange={handleInput} />
                                                                        <label id="firstNameerror" className="error hide">First name is required</label>
                                                                    </div>
                                                                    <div className="form-group col-12 col-sm-6">
                                                                        <label>Last Name <span className="error">*</span></label>
                                                                        <input type="text" className="form-control" placeholder="Last Name" id="lastName" onChange={handleInput} />
                                                                        <label id="lastNameeerror" className="error hide">Last name is required</label>
                                                                    </div>
                                                                    <div className="form-group col-12 col-sm-6">
                                                                        <label>{t('email')} <span className="error">*</span></label>
                                                                        <input type="email" className="form-control" placeholder={t('email')} id="emailAddress" onChange={handleInput} />
                                                                        <label id="emailerror" className="error hide">Email Id is required</label>
                                                                    </div>
                                                                    <div className="form-group col-12 col-sm-6">
                                                                        <label>{t('phone')} <span className="error">*</span></label>
                                                                        <div className="col-12 col-sm-12 d-flex">
                                                                            <select className="form-select w-20" id="countryPhoneCodeId" onChange={handleInput}>
                                                                                <option value="0">Code</option>
                                                                                {
                                                                                    curCountryphonecodeMeta.length != 0 ? (
                                                                                        curCountryphonecodeMeta.map((ele, index) => (
                                                                                            <option key={index} value={ele.countryPhoneCodeId}>{ele.countryPhoneCode} {ele.countryCode}</option>
                                                                                        ))
                                                                                    ) : null
                                                                                }
                                                                            </select>
                                                                            <input type="text" className="form-control" placeholder={t('mobilePhone')} id="phoneNumber" maxLength="12" onChange={handleInput} />
                                                                        </div>
                                                                        <label id="countryPhoneCodeIderror" className="error hide mr_20">Phone Code is required</label>&nbsp;
                                                                        <label id="phonenumbererror" className="error hide">Phone Number is required</label>
                                                                    </div>
                                                                    <div className="form-group col-12 col-sm-6">
                                                                        <label>{t('country')} <span className="error">*</span></label>
                                                                        <select className="form-select" id="countryId" onChange={handleInput}>
                                                                            <option value="0" key={0}>{t('country')}</option>
                                                                            {
                                                                                countriesMeta.length != 0 ? (
                                                                                    countriesMeta.map((ele, index) => (
                                                                                        <option key={index} value={ele.countryId}>{ele.countryName}</option>
                                                                                    ))
                                                                                ) : null
                                                                            }
                                                                        </select>
                                                                        <label id="countryerror" className="error hide">Country not selected</label>
                                                                    </div>
                                                                    <div className="form-group col-12 col-sm-6">
                                                                        <label>Company Name <span className="error">*</span></label>
                                                                        <input type="text" className="form-control" placeholder="Company Name" id="companyName" onChange={handleInput} />
                                                                        <label id="companyNameerror" className="error hide">Company name is required</label>
                                                                    </div>
                                                                    <div className="form-group col-12 col-sm-6">
                                                                        <label>Designation  <span className="error">*</span></label>
                                                                        <input type="text" className="form-control" placeholder="Designation " id="designation" onChange={handleInput} />
                                                                        <label id="designationerror" className="error hide">Designation is required</label>
                                                                    </div>
                                                                    <div className="form-group col-12 col-sm-6">
                                                                        <label>CAAS Required as <span className="error">*</span></label>
                                                                        <select className="form-select" id="hireCaasIndustryId" onChange={handleInput}>
                                                                            <option value="0">Select</option>
                                                                            {
                                                                                hirecaasindustry.length != 0 ? (
                                                                                    hirecaasindustry.map((ele, index) => (
                                                                                        <option key={index} value={ele.hireCaasindustryId}>{ele.hireCaasindustryName}</option>
                                                                                    ))
                                                                                ) : null
                                                                            }
                                                                        </select>
                                                                        <label id="hireCaasIndustryIderror" className="error hide">CAAS Required as?</label>
                                                                    </div>

                                                                    <div className="form-group col-12 col-sm-6">
                                                                        <label>Company Size <span className="error">*</span></label>
                                                                        <select className="form-select" id="hireCaasCompanySizeId" onChange={handleInput}>
                                                                            <option value="0">Select</option>
                                                                            {
                                                                                hirecaascompanysize.length != 0 ? (
                                                                                    hirecaascompanysize.map((ele, index) => (
                                                                                        <option key={index} value={ele.hireCaasCompanySizeId}>{ele.hireCaasCompanySizeName}</option>
                                                                                    ))
                                                                                ) : null
                                                                            }
                                                                        </select>
                                                                        <label id="hireCaasCompanySizeIderror" className="error hide">Company Size is required</label>
                                                                    </div>
                                                                    <div className="form-group col-12 col-sm-6">
                                                                        <label>Additional Information <span className="error">*</span></label>
                                                                        <textarea className="form-control" placeholder="More Detail" id="additionalInformation" onChange={handleInput}></textarea>
                                                                        <label id="additionalInformationerror" className="error hide">Additional information is required</label>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                            <div className="float-right">
                                                                <a href="#" data-bs-toggle="modal"
                                                                    className="btn btn-warning btn-md next-btn hirecaasbtn" onClick={sendHireCaasEnquiry}>Submit</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HireCaas
