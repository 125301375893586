import toast from "react-hot-toast";
export const celebrationToast = (msg) => {
    toast.success(msg, {
        icon: '👏',
        style: {
            borderRadius: '10px',
            color: "#333",
            background: "#fff",
            marginRight: '400px',
            marginTop: '10px',
        }, position: 'top-right'
    })
}

export const successToast = (msg) => {
    toast.success(msg, {
        style: {
            borderRadius: '10px',
            marginTop: '10px',
            marginRight: '400px',
        },position: 'top-right'
    });
}

export const errorToast = (msg) => {
    toast.error(msg, {
        style: {
            borderRadius: '10px',
            borderColor: 'red',
            color: "#333",
            background: "#fff",
            marginRight: '400px',
            marginTop: '10px',
        }, position: 'top-right'
    })
}