import React, { useEffect, useState } from 'react'
import "../../../assets/fontawesome6/css/all.min.css";
import "../../../assets/css/themify-icons.css"
import "../../../assets/css/style.css"
import "../../../assets/css/select2-bootstrap.min.css"
import "../../../assets/css/select2.min.css"
import "../../../assets/css/custom.css"
import "../../../assets/css/userend.css"
import "../../../assets/css/responsive.css"
import SocialHeader from './SocialHeader';
import SocialFeedNavigations from './SocialFeedNavigations';
import CAASWorkspaceNavigations from './CAASWorkspaceNavigations';
import ProjectAnnouncements from './ProjectAnnouncements';
import CompanyAnnouncements from './CompanyAnnouncements';
import { S3Config } from '../../../config/AWSConfig';
import S3FileUpload from '../../../utils/S3FileUpload';
import AddToFeed from './AddToFeed';
import groupDefaultLogo from "../../../assets/images/group.png";
import { API } from '../../../config/APIConfig';
import axios from 'axios';
import $ from "jquery";
import { Link } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { successToast, celebrationToast, errorToast } from '../../../utils/toastUtil';
import logger from '../../../utils/logger';
import uuidCustom from '../../../utils/uuidCustom';
const GETGROUPLISTAPI = `${API.URL}api/v1/social/group/list`;
const CREATEGROUPAPI = `${API.URL}api/v1/social/group/create`;
const GROUPNAMECHECKAPI = `${API.URL}api/v1/social/group/validate`;
const socialEntityTypeId = 2;
const GroupList = () => {
  const [groupList, setGroupList] = useState([]);
  const [curGroupList, setCurGroupList] = useState([]);
  const [previewLogo, setPreviewLogo] = useState("");
  const curUserId = sessionStorage.getItem("userId");
  const curUserRoleId = sessionStorage.getItem("roleId");
  const curUserEntityId = 3;
  const [groupNameExists, setGroupNameExists] = useState(false);
  const getGroupList = () => {
    // logger.verbose("Get Group List", "GroupList Component", "getGroupList", "GET", "Get Group List API Hit", `userId=${curUserId}&userEntityId=${curUserEntityId}&userRoleId=${curUserRoleId}`);
    axios.get(`${GETGROUPLISTAPI}?userId=${curUserId}&userEntityId=${curUserEntityId}&userRoleId=${curUserRoleId}`)
      .then(resp => {
        const groups = resp.data.response;
        setGroupList([...groups])
        setCurGroupList([...groups])
        // logger.verbose("Get Group List", "GroupList Component", "getGroupList", "GET", "Get Group List API Response Successfull", JSON.stringify(resp.data.response));
      }).catch(error => {
        console.log(error);
        logger.error("Get Group List", "GroupList Component", "getGroupList", "GET", "Get Group List API Response Failed!!!", JSON.stringify(error));
      })
  }
  useEffect(() => {
    getGroupList();
    var highlightele = document.getElementById("groupsLink");
    highlightele.classList.remove("text-black-50");
    highlightele.classList.add("text-link");
    $("html").removeClass();
    $("html").addClass('grouplistt');
    var box_4 = $('.box_4 .sticky-top .box_shadow_1:first-child').height() + $('.header-section').height();
    $(".box_4 .sticky-top .box_shadow_1 .companyannouncements1").attr('style', 'height:' + (($(window).height() - box_4) - 63 + 'px !important'));
    var dd = $('.header-section').height() * 2.13;
    $(".box_1 .sticky-top .box_shadow_1 .list-company").attr('style', 'height:' + ((dd) + 'px !important'));
  }, []);

  const ErrorWhileCreatingGroup = (msg) => {
    errorToast(msg);
    $(".createGroupBtn").attr("disabled", false);
  }

  const createGroup = async () => {
    $(".createGroupBtn").attr("disabled", true);
    const title = $("#groupName").val();
    const description = $("#groupDescription").val();
    const e = document.getElementById("groupLogo");
    const logoAdded = e.files.length == 0 ? false : true;
    if (title == "") {
      ErrorWhileCreatingGroup("Please fill the group title");
      return;
    }
    if (groupNameExists == true) {
      ErrorWhileCreatingGroup('Group Title Already Exists');
      return;
    }
    if (description == "") {
      ErrorWhileCreatingGroup("Please fill the group description");
      return;
    }

    if ($("input[name='groupType']:checked").val() == undefined) {
      ErrorWhileCreatingGroup("Please select a Group Type");
      return;
    }
    let isPrivate = true;
    if ($("input[name='groupType']:checked").val() == '0') {
      isPrivate = false;
    }

    if (logoAdded === false) {
      ErrorWhileCreatingGroup("Please upload a Logo to the Group");
      return;
    }


    const uploadlogo = await fileUpload(e).then(response => response).catch(err => console.log(err));
    const logo = e.files.length == 0 ? groupDefaultLogo : await uploadlogo.s3FileUrl;

    const groupInfo = await createGroupObj(title, description, isPrivate, logo);
    await insertGroup(groupInfo);
  }
  const insertGroup = (groupInfo) => {
    // logger.verbose("Create Group", "GroupList Component", "insertGroup", "POST", "Create Group API Hit", JSON.stringify(groupInfo));
    const headers = {
      'Content-Type': 'application/json;charset=utf-8',
    }
    axios.post(CREATEGROUPAPI, groupInfo, {
      headers: headers
    }).then(response => {
      if (response.data.status === 200) {
        document.getElementById("createGroupForm").reset();
        successToast("Group Created Successfully");
        setPreviewLogo("");
        getGroupList();
        $("#creategroup .close").trigger('click');
        $(".createGroupBtn").attr("disabled", false);
        // logger.verbose("Create Group", "GroupList Component", "insertGroup", "POST", "Create Group API Respone Successfull", JSON.stringify(response.data.response));
      }
    }).catch(error => {
      console.error('There was an error!', error);
      logger.error("Create Group", "GroupList Component", "insertGroup", "POST", "Create Group API Response Failed!!", JSON.stringify(error));
    });
  }
  const createGroupObj = (title, description, isPrivate, logo) => {
    return {
      "socialEntityTypeId": socialEntityTypeId,
      "title": title,
      "description": description,
      "logo": logo,
      "isPrivate": isPrivate,
      "createdByUserId": curUserId,
      "createdByUserEntityTypeId": curUserEntityId,
      "createdByUserRoleId": curUserRoleId
    }
  }
  const previewGroupLogo = (e) => {
    let [logo] = e.target.files;
    logo = URL.createObjectURL(logo);
    setPreviewLogo(logo);
  }
  const fileUpload = async (e) => {
    const file = e.files[0];
    // const fileName = new Date().toISOString() + "|" +  e.target.files[0].name;
    var fileName = e.files[0].name;   
    var fileExtension =fileName.split('.').pop();  
    fileName +=  S3Config.S3ObjectDelimiter + uuidCustom() +"."+ fileExtension;
    const contentType = file.type;
    const formData = { file, fileName, contentType };
    let result = await S3FileUpload(formData).then(
      (response) => {
        if (response.status === 200) {
          return { status: 200, s3FileUrl: S3Config.S3ObjectBaseURL + fileName }
        }
      }
    ).catch(err => console.log(err));
    return result;
  }
  const searchGroups = (e) => {
    const key = e.target.value;
    if (key === "")
      setCurGroupList(groupList);
    const searchedGroups = groupList.filter((ele) => ele.title.toLowerCase().includes(key.toLowerCase()));
    setCurGroupList([...searchedGroups]);
  }
  const GroupNameValidation = (groupTitle) => {
    // logger.verbose("Group Name Validation", "GroupList Component", "GroupNameValidation", "GET", "Group Name Validation API Hit", groupTitle);
    // var groupTitle=e.target.value;

    axios.get(`${GROUPNAMECHECKAPI}?groupTitle=${groupTitle}`)
      .then(response => {
        if (response.data === true) {
          errorToast('Group Name Already Exists');
          // logger.verbose("Group Name Validation", "GroupList Component", "GroupNameValidation", "GET", "Group Name Validation API Respone Successfull", JSON.stringify(response.data));
        }
        setGroupNameExists(response.data);
        return response.data
      })
      .catch(error => {
        console.error('There was an error!', error);
        logger.error("Group Name Validation", "GroupList Component", "GroupNameValidation", "GET", "Group Name Validation API Respone Successfull", JSON.stringify(error));

      });
  }
  return (
    <>
      <div className="main-body-section main-body-section-social">
        <SocialHeader />
        <section className="body-section">
          <div className="body-content container-fluid">
            <div className="row1 My_grouplist">
              <div className="col_res1 align-items-stretch box_1 w_171">
                <div className="sticky-top">
                  <SocialFeedNavigations />
                  <AddToFeed />
                </div>
              </div>
              <div className="mob-remove-padding box_2 w_661">
                <div className="box-home box_shadow_1 no-border-radius">
                  <div className="row">
                    <div className="col-md-9">
                      <div className="form-group">
                        <p className="fw-bold text-muted mt-2 h4">
                          Search By Group Name
                        </p>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"> <i className="mdi mdi-filter"></i></span>
                          </div>
                          <input type="text" className="form-control height-searchbox" placeholder="Search by Group Name" aria-label="Username" onKeyUp={searchGroups} />
                          <div className="input-group-prepend">
                            <button className="input-group-text btn-search-close"> <i className="mdi mdi-account-search"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-3">
                      <div className="form-group">
                        <p className="fw-bold text-muted mt-2 h4">
                          Filter by Group Creation
                        </p>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"> <i className="mdi mdi-filter"></i></span>
                          </div>
                          <select className="form-select filter-btn col-md-2">
                            <option>
                              2022
                            </option>
                            <option>
                              2021
                            </option>
                            <option>
                              2020
                            </option>
                          </select>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-md-3 btn-wrapper float-end mt_30">
                      <a className="btn groupcreate_btn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#creategroup" ><i className="mdi mdi-pencil-box"></i> Create Group</a>
                    </div>
                  </div>
                  <div className="d-sm-flex justify-content-between align-items-start">
                    <p className="text-muted mb-0">  <i className="mdi mdi-bookmark-check"></i> About {curGroupList?.length} results</p>
                  </div>
                  {/* <div className="btn-wrapper float-end">
                    <a className="btn groupcreate_btn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#creategroup" ><i className="mdi mdi-pencil-box"></i> Create Group</a>
                  </div> */}
                  {
                    curGroupList.length != 0 ? (
                      curGroupList.map((ele, index) => (
                        <div className={`wrapper d-flex align-items-center justify-content-between ${index % 2 !== 0 ? "bg-1" : ""} py-2 border-bottom`} key={index}>
                          <div className="d-flex">
                            <img className="img-sm rounded-10" src={ele?.logo ? ele?.logo : groupDefaultLogo} alt="Group" />
                            <div className="wrapper1 ms-3">
                              <p className="ms-1 mb-1 fw-bold"> <Link to={`/cxo/cxo/wall/group/${ele?.groupId}`} className="groupname" state={{ groupId: ele?.groupId }}>{ele?.title}</Link>
                                <i className={`mdi mdi-lock text-muted ml_10 ${ele.isPrivate === false ? "d-none" : ""}`}></i>
                              </p>
                              <small className="text-muted mb-0">{ele?.totalParticipants} Members</small>
                              <label className="form-check-label">
                                {ele?.description}</label>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : <div className={`wrapper d-flex align-items-center justify-content-between py-2`} >
                      <div className="d-flex">
                        <div className="wrapper1 ms-3">
                          <label className="form-check-label">
                            No Records Found</label>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="col_res1 align-items-stretch box_4 w_171">
                <div className="sticky-top">
                  <CAASWorkspaceNavigations />
                  {/* <ProjectAnnouncements /> */}
                  <CompanyAnnouncements />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="modal" id="creategroup">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header pt_0">
              <h3 className="modal-title">Create Group</h3>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="row1">
                <div className="card">
                  <div className="card-body py-3">
                    <form className="forms-sample" id="createGroupForm">
                      <div className="form-group">
                        <label className="fw-bold" htmlFor="exampleInputUsername1">Group Name</label>
                        <input type="text" className="form-control" id="groupName" placeholder="GroupName" onChange={(e) => GroupNameValidation(e.target.value)} />
                      </div>
                      <div className="form-group">
                        <label className="fw-bold" htmlFor="exampleInputEmail1">Description</label>
                        <textarea name="" id="groupDescription" cols="30" rows="3"></textarea>
                      </div>
                      <div className="form-group d-flex flex-row my-4">
                        <label className="fw-bold p-0">Group Type :</label>
                        <div className="col1 ps-4 pt-1">
                          <div className="form-check m-0 ">
                            <label className="form-check-label p-0 lh-1">
                              <input type="radio" className="form-check-input" value="0" name="groupType" />
                              Public
                              <i className="input-helper"></i></label>
                          </div>
                        </div>
                        <div className="col1 ps-4 pt-1">
                          <div className="form-check m-0">
                            <label className="form-check-label p-0 lh-1">
                              <input type="radio" className="form-check-input" value="1" name="groupType" />
                              Private
                              <i className="input-helper"></i></label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="fw-bold">Upload Group's Logo Here </label>
                        <input className="form-control" type="file" id="groupLogo" accept="image/*" onChange={previewGroupLogo} />
                        {previewLogo && <img src={previewLogo} alt="Uploaded Media" className="img-lg mt-5 mb-2" />}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <a href="#" className="btn btn-warning btn-md accept-btn createGroupBtn" onClick={createGroup}>Create Group</a>
              <a href="#" className="btn btn-warning btn-md ignore-btn" data-bs-dismiss="modal">Cancel</a>
            </div>
          </div>
        </div>
      </div >
      <Toaster />
    </>
  )
}
export default GroupList