// import axios from "axios";
import { API } from "../config/APIConfig"
const countryStateCityAPI = `${API.URL}api/v1/meta/countrystatecitylist`;
const enterpriseTypeListAPI = `${API.URL}api/v1/meta/enterprisetypelist`;
const nationalityListAPI = `${API.URL}api/v1/meta/nationalitylist`;
const languageListAPI = `${API.URL}api/v1/meta/languagelist`;
const educationListAPI = `${API.URL}api/v1/meta/educationlist`;
const experiencelevelListAPI = `${API.URL}api/v1/meta/experiencelevellist`;
const areaOfTheProjectAPI = `${API.URL}api/v1/meta/areaofprojectlist`;
const skillMasterVersionListAPI = `${API.URL}api/v1/meta/skillmasterversionlist`;
const genderListAPI = `${API.URL}api/v1/meta/genderlist`;
const roleListAPI=`${API.URL}api/v1/meta/rolelist`;
const industryListAPI=`${API.URL}api/v1/meta/industrylist`;
const govissueduniqueidentifierListAPI=`${API.URL}api/v1/meta/governmentissueduniqueidentifier`;
const hirecaasindustryListAPI=`${API.URL}api/v1/meta/hirecaasindustrylist`;
const hirecaascompanysizeListAPI=`${API.URL}api/v1/meta/hirecaascompanysizelist`;
export const getCountryStateCityList = async () => {
    const response = await fetch(countryStateCityAPI);
    if (!response.ok) {
        throw new Error('Data coud not be fetched!');
    } else {
        return response.json()
    }
}
export const getEnterpriseTypeList = async () => {
    const response = await fetch(enterpriseTypeListAPI);
    if (!response.ok) {
        throw new Error('Data coud not be fetched!')
    } else {
        return response.json()
    }
}
export const getNationalityList = async () => {
    const response = await fetch(nationalityListAPI);
    if (!response.ok) {
        throw new Error('Data coud not be fetched!')
    } else {
        return response.json()
    }
}

export const getLanguageList = async () => {
    const response = await fetch(languageListAPI);
    if (!response.ok) {
        throw new Error('Data coud not be fetched!')
    } else {
        return response.json()
    }
}
export const getEducationList = async () => {
    const response = await fetch(educationListAPI);
    if (!response.ok) {
        throw new Error('Data coud not be fetched!')
    } else {
        return response.json()
    }
}
export const getSkillExperienceLevelList = async () => {
    const response = await fetch(experiencelevelListAPI);
    if (!response.ok) {
        throw new Error('Data coud not be fetched!')
    } else {
        return response.json()
    }
}

export const getAreaOfProjectList = async () => {
    const response = await fetch(areaOfTheProjectAPI);
    if (!response.ok) {
        throw new Error('Data coud not be fetched!')
    } else {
        return response.json()
    }
}

export const getSkillDetailslList = async () => {
    const response = await fetch(skillMasterVersionListAPI);
    if (!response.ok) {
        throw new Error('Data coud not be fetched!')
    } else {
        return response.json()
    }
}

export const getGenderDetailsList = async () => {
    const response = await fetch(genderListAPI);
    if (!response.ok) {
        throw new Error('Data coud not be fetched!')
    } else {
        return response.json()
    }
}
export const getRoleDetailsList = async () => {
    const response = await fetch(roleListAPI);
    if (!response.ok) {
        throw new Error('Data coud not be fetched!')
    } else {
        return response.json()
    }
}

export const getIndustryDetailsList = async () => {
    const response = await fetch(industryListAPI);
    if (!response.ok) {
        throw new Error('Data coud not be fetched!')
    } else {
        return response.json()
    }
}
export const getgovissuedUniqueIdentifierDetailsList = async () => {
    const response = await fetch(govissueduniqueidentifierListAPI);
    if (!response.ok) {
        throw new Error('Data coud not be fetched!')
    } else {
        return response.json()
    }
}
export const getHireCaasIndustryDetailsList = async () => {
    const response = await fetch(hirecaasindustryListAPI);
    if (!response.ok) {
        throw new Error('Data coud not be fetched!')
    } else {
        return response.json()
    }
}
export const getHireCaasCompanySizeDetailsList = async () => {
    const response = await fetch(hirecaascompanysizeListAPI);
    if (!response.ok) {
        throw new Error('Data coud not be fetched!')
    } else {
        return response.json()
    }
}


