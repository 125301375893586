import React from 'react'

export default function Pagination({nPages,currentPage,setCurrentPage}) {
    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
    const nextPage = () => {
        if(currentPage !== nPages) 
            setCurrentPage(currentPage + 1)
    }
    const prevPage = () => {
        if(currentPage !== 1) 
            setCurrentPage(currentPage - 1)
    }
  return (
    <div className="float-end mb-2">
   <nav>
    <ul className='pagination flex-wrap pagination-flat pagination-success'>
        <li className="page-item">
            <a className="page-link"
            onClick={prevPage}
            href="#">
               <i className="ti-angle-left"></i>
            </a>

        </li>
        {pageNumbers.map(pgNumber =>(
            <li key={pgNumber}
            className={`page-item ${currentPage == pgNumber?"active":""}`}>
                <a onClick={()=>setCurrentPage(pgNumber)} className="page-link" href="#">
                    {pgNumber}
                </a>
            </li>
        ))}
        <li className="page-item">
            <a className="page-link"
            onClick={nextPage}
            href="#">
               <i className="ti-angle-right"></i>
            </a>

        </li>
    </ul>
   </nav>
   </div>
  )
}
