import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom'
import { RFPListCIOContext } from './contexts/RFPListCIOContext'
import { useTranslation } from "react-i18next";
const ExpiredRFPCXO = (props) => {
    const { i18n, t } = useTranslation(["rfp"]);
    var enterpriseId = props.enterpriseId; //pass enterpriseId to GET Open RFP (Company) api
    const { curRFPList, setCurRFPList } = useContext(RFPListCIOContext);
    const [expiredRFPList, SetExpiredRFPList] = useState([]);
    var enterpriseId = props.enterpriseId;
    useEffect(() => {
        getExpiredRFPList();
    }, [curRFPList])
    const getExpiredRFPList = () => {
        // var data = RFPList.filter((ele) => ele.enterpriseId == enterpriseId && ele.rfpStatusId == '3' && ele.rfpStatus == 'Expired');
        var data = curRFPList.filter((ele) =>  ele.rfpStatusId == '3' && ele.rfpStatus == 'Expired');
        SetExpiredRFPList([...data]);
    }
    return (
        <>  {
            expiredRFPList.length != 0 ? (
                expiredRFPList.map((ele) => (
                    <>
                        <div className="grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mt-0 text-left box-shadow p-4 cardPad card-body border border-radius">
                                        <div className="d-flex justify-content-between align-items-start RFP_Details">
                                            <div className="list-item-details">
                                                <div className="d-table">
                                                    <div className="d-table-cell pe-5">{t('title')}</div>
                                                    <div className="d-table-cell text-orange">{ele?.rfpTitle}</div>
                                                </div>
                                                <div className="d-table">
                                                    <div className="d-table-cell pe-5">{t('postedDate')}</div>
                                                    <div className="d-table-cell">{ele?.postedDate}</div>
                                                </div>
                                                <div className="d-table">
                                                    <div className="d-table-cell pe-5">{t('proposalDueDate')}</div>
                                                    <div className="d-table-cell">{ele?.lastDateForResponse}</div>
                                                </div>
                                                <div className="d-table">
                                                    <div className="d-table-cell pe-5">{t('statusRFP')}</div>
                                                    <div className="d-table-cell text-green">
                                                        <Link to={`/cxo/cxo/rfp/view/${ele.rfpId}`} className="edit-profile btn-orange quote-btn">{t(`${ele.rfpStatus}`)}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link to={`/cxo/cxo/rfp/quote/${ele.rfpId}`} className="edit-profile btn-orange quote-btn">{t('rfpQuote')}</Link>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ))
            ) : null
        }
        </>
    )
}

export default ExpiredRFPCXO