import React, { useEffect, useState } from 'react'
import profilepic from "../../../assets/images/noav.jpg"
import { API } from '../../../config/APIConfig';
import axios from 'axios';
import Comments from '../commons/Comments';
import HTMLReactParser from 'html-react-parser';
import InfiniteScroll from "react-infinite-scroll-component";
import swal from 'sweetalert';
import dateDiff from '../../../utils/dateDiff';
import $ from "jquery";
import { Toaster } from 'react-hot-toast';
import { successToast, celebrationToast, errorToast } from '../../../utils/toastUtil';
import logger from '../../../utils/logger';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { quillModules } from '../../../utils/quillModules';
import { Link, useNavigate } from 'react-router-dom';
import EmojiPicker, { EmojiStyle, Theme, Categories } from "emoji-picker-react";
// const GETWALLPOSTSAPI = `${API.URL}api/v1/social/wall/post/list`;
const GETWALLPOSTSAPI = `${API.URL}api/v1/social/vendor/wall/post/paginatedlist`;
const SETREACTIONAPI = `${API.URL}api/v1/social/react`;
const ADDCOMMENTAPI = `${API.URL}api/v1/social/comment`;
const DELETEPOSAPI = `${API.URL}api/v1/social/post/delete`;
const GETPOSTSBYIDAPI = `${API.URL}api/v1/social/post/edit`;
const REACTIONTYPE = {
    HEART: 1,
    APPRECIATE: 2,
    SURPRISED: 3
}
const VendorPosts = () => {
    const [postsData, setPostsData] = useState([]);
    const [postAttachments, setPostAttachments] = useState([])
    const [zoomImgInfo, setZoomImgInfo] = useState({ src: "", alt: "" });
    const [postlimit, setPostLimit] = useState(2);
    const [loadedCount, setLoadedCount] = useState(0);
    const curUserFirstName = sessionStorage.getItem("firstname");
    const curUserLastName = sessionStorage.getItem("lastname");
    const curUserFullName = sessionStorage.getItem("userName");// curUserFirstName + " " + curUserLastName;
    const curUserId = sessionStorage.getItem("userId");
    const curUserRoleId = sessionStorage.getItem("roleId");
    const curUserEntityId = 4;
    const curProfilePicture = sessionStorage.getItem("profilePicture")
    const [curPostData, setCurPostData] = useState([]);
    const [curPostId, setCurPostId] = useState(0)
    const [selectedEmoji, setSelectedEmoji] = useState("");
    const [showPicker, setShowPicker] = useState(false);
    const [curFocus, setCurFocus] = useState("");
    const navigate = useNavigate();
    var totalCount = "";
    const displayEmojiPicker = (e) => {
        const focus = e.target.getAttribute("data-ref");
        setShowPicker(val => !val);
        setCurFocus(focus);
    }
    const onEmojiClick = (event, emojiObject) => {
        var value = $("#" + curFocus).val();
        $("#" + curFocus).val(value + event.emoji)
        setShowPicker(false);
    };
    const getWallPostsDetails = () => {
        axios.get(`${GETWALLPOSTSAPI}?userId=${curUserId}&userEntityId=${curUserEntityId}&userRoleId=${curUserRoleId}&postLimit=${postlimit}&loadFrom=${loadedCount}`)
            .then(resp => {
                const postInfo = resp.data.response.postDetails;
                const postMediaInfo = resp.data.response.mediaDetails;
                const mergeData = [...postsData, ...postInfo];
                const mergemediaData = [...postAttachments, ...postMediaInfo];
                totalCount = postInfo[0].postsCount
                setPostsData(mergeData);
                setLoadedCount(loadedCount + postInfo.length)
                // setPostAttachments([...postMediaInfo])
                setPostAttachments(mergemediaData)
                setTimeout(() => {
                    bindMentionClickEventListeners();
                }, 4000)
            }).catch(error => {
                console.log(error);
            })
    }
    useEffect(() => {
        getWallPostsDetails();
        window.addEventListener("scroll", function () {
            setShowPicker(false);
        });
        window.addEventListener("click", function (e) {
            let id = e.target.id;         
            if (!id) {
                var emoji_id = id.substring(0, 5)
                if (emoji_id !== "emoji") {

                    setShowPicker(false);
                }
            }
        });
    }, []);

    const bindMentionClickEventListeners = () => {
        $('.mention').click(function (e) {
            const data = e.currentTarget.getAttribute("data-id");
            if (data.length != 0) {
                const dataArr = data.split("-");
                const userId = dataArr[0];
                const roleId = dataArr[2];
                navigate('/cxo/profile', { state: { quotedBy: userId, role: roleId } });
            }
        });
    }
    const addComment = (e) => {
        logger.verbose("Post Comment", "VendorPosts  Component", "Add Comment Button", "POST", "Add Comment Button Clicked ", "");
        const parentSocialEntityTypeId = e.target.getAttribute("data-parent-entity-type-id");
        const parentSocialEntityId = e.target.getAttribute("data-parent-entity-id");
        const comment = document.getElementById("comment-" + parentSocialEntityTypeId + "-" + parentSocialEntityId).value;
        if (comment.length === 0) {
            errorToast("Please type your Comment");
            return;
        }
        const commentObj = {
            "content": comment,
            "socialEntityTypeId": "5",
            "parentSocialEntityTypeId": parentSocialEntityTypeId,
            "parentSocialEntityId": parentSocialEntityId,
            "userId": curUserId,
            "userEntityId": curUserEntityId,
            "userRoleId": curUserRoleId
        }
        logger.verbose("Post Comment", "VendorPosts  Component", "Add Comment Button", "POST", "Add Comment Object creation ", JSON.stringify(commentObj));
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
        }
        axios.post(ADDCOMMENTAPI, commentObj, {
            headers: headers
        }).then(response => {
            if (response.data.status === 200) {
                document.getElementById("comment-" + parentSocialEntityTypeId + "-" + parentSocialEntityId).value = "";
                let commentsCount = parseInt(document.getElementById("post-comment-" + parentSocialEntityTypeId + "-" + parentSocialEntityId).textContent);
                document.getElementById("post-comment-" + parentSocialEntityTypeId + "-" + parentSocialEntityId).textContent = commentsCount + 1;
                successToast('You have Commented Successfully!')
                logger.verbose("Post Comment", "VendorPosts  Component", "Add Comment Button", "POST", "Commented Created Successfully!", JSON.stringify(response.data.response));

            }
        }).catch(error => {
            logger.error("Post Comment", "VendorPosts  Component", "Add Comment Button", "POST", "Commented Creation API Failed", JSON.stringify(error));
            console.error('There was an error!', error);
        });
    }

    const ReactOnPost = (e) => {
        logger.verbose("Post React", "VendorPosts  Component", "React on Post", "POST", "Reacting on Post Clicked ", "");
        const ele = e.target;
        const parentSocialEntityTypeId = ele.getAttribute("data-parent-entity-type-id");
        const parentSocialEntityId = ele.getAttribute("data-parent-entity-id");
        const reactionTypeId = ele.getAttribute("data-reaction-type-id");
        let reactionStatus = false;
        if (ele.getAttribute("data-reaction-status") === 'true')
            reactionStatus = true;
        var reactionObj = {
            "reactionTypeId": reactionTypeId,
            "parentSocialEntityTypeId": parentSocialEntityTypeId,
            "parentSocialEntityId": parentSocialEntityId,
            "userId": curUserId,
            "userEntityId": curUserEntityId,
            "userRoleId": curUserRoleId,
            "reactionStatus": reactionStatus
        }
        logger.verbose("Post React", "VendorPosts  Component", "React on Post", "POST", "Reacting on Post Object created ", JSON.stringify(reactionObj));
        axios.post(SETREACTIONAPI, reactionObj)
            .then(response => {

                if (response.data.status === 200) {
                    let likesCount = parseInt(document.getElementById("post-like-" + parentSocialEntityTypeId + "-" + parentSocialEntityId + "-" + reactionTypeId).textContent);
                    if (reactionStatus) {
                        ele.classList.add("liked", "fa-solid");
                        ele.classList.remove("fa-regular");
                        document.getElementById("post-like-" + parentSocialEntityTypeId + "-" + parentSocialEntityId + "-" + reactionTypeId).textContent = likesCount + 1;
                        ele.setAttribute("data-reaction-status", "false");
                    }
                    else {
                        ele.classList.remove("liked", "fa-solid");
                        ele.classList.add("fa-regular");
                        if (likesCount >= 0)
                            document.getElementById("post-like-" + parentSocialEntityTypeId + "-" + parentSocialEntityId + "-" + reactionTypeId).textContent = likesCount - 1;
                        ele.setAttribute("data-reaction-status", "true");
                    }
                    logger.verbose("Post React", "VendorPosts  Component", "React on Post", "POST", "React on Post Successfully ", JSON.stringify(response.data.response));
                }
            })
            .catch(error => {
                console.error('There was an error sending reaction!', error);
                logger.error("Post React", "VendorPosts  Component", "React on Post", "POST", "React on Post API Failed ", JSON.stringify(error));
            });
    }
    const fetchMoreData = () => {
        getWallPostsDetails();
    };
    const maximizeImg = (e) => {
        const obj = {
            src: e.target.getAttribute("src"),
            alt: e.target.getAttribute("alt")
        }
        setZoomImgInfo({ ...obj });
    }
    const handlePostEdit = (postId, socialEntityTypeId, parentSocialEntityTypeId) => {
        setCurPostId(postId)
        axios.get(`${GETPOSTSBYIDAPI}/?postId=${postId}&userId=${curUserId}&socialEntityTypeId=${socialEntityTypeId}&parentSocialEntityTypeId=${parentSocialEntityTypeId}&userEntityId=${curUserEntityId}&userRoleId=${curUserRoleId}`)
            .then(response => {
                if (response.data.status === 200) {
                    let currentpostdetails = response.data.response;
                    setCurPostData(currentpostdetails)
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }
    const handlePostDelete = (postId, socialEntityTypeId, parentSocialEntityTypeId) => {
        axios.delete(`${DELETEPOSAPI}/?postId=${postId}&userId=${curUserId}&socialEntityTypeId=${socialEntityTypeId}&parentSocialEntityTypeId=${parentSocialEntityTypeId}`)
            .then(response => {
                if (response.data.status === 200) {
                    successToast('Post Deleted Successfully!')
                    // getWallPostsDetails();
                    window.location.reload(false);
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }
    const resetCarouselRadioChoice = (e) => {
        const name = e.target.name;
        const id = e.target.id;
        $('.' + name).prop('checked', false);
        $('#' + id).prop('checked', true);
    }

    const nextMedia = (postId, index) => {
        const now = `li-${postId}-${index}`;
        $('#' + now).addClass(' d-none');
        $(".carousel-dots label").removeClass("main-carousel-dot");
        const next = `li-${postId}-${index + 1}`;
        const nextdot = `img-dot-${postId}-${index + 1}`;
        if ($("#" + next).length == 0) {
            const pavilion = `li-${postId}-0`;
            const paviliondot = `img-dot-${postId}-0`;
            $("#" + paviliondot).addClass("main-carousel-dot");
            $('#' + pavilion).removeClass('d-none');
        }
        else {
            $("#" + nextdot).addClass("main-carousel-dot");
            $('#' + next).removeClass('d-none');
        }
    }
    const prevMedia = (postId, index) => {
        const now = `li-${postId}-${index}`;
        $('#' + now).addClass('d-none');
        $(".carousel-dots label").removeClass("main-carousel-dot");
        if (index === 0) {
            const findKey = `slidelist-${postId} li`;
            const len = $("#" + findKey).length;
            const last = `li-${postId}-${len - 1}`;
            const lastdot = `img-dot-${postId}-${len - 1}`;
            $("#" + lastdot).addClass("main-carousel-dot");
            $('#' + last).removeClass('d-none');
        }
        else {
            const prev = `li-${postId}-${index - 1}`;
            const prevdot = `img-dot-${postId}-${index - 1}`;
            $("#" + prevdot).addClass("main-carousel-dot");
            $('#' + prev).removeClass('d-none');
        }
    }
    const toggleDropdown = (e) => {
        const postId = e.target.getAttribute("data-target-id");
        if ($("#modifyPostDropdown-" + postId).hasClass("d-none")) {
            $("#modifyPostDropdown-" + postId).removeClass("d-none")
        } else {
            $("#modifyPostDropdown-" + postId).addClass("d-none")
        }
    }
    return (
        <>
            {showPicker && <EmojiPicker
                onEmojiClick={onEmojiClick}
                autoFocusSearch={false}
                lazyLoadEmojis={true}
                theme={Theme.AUTO}
                skinTonesDisabled
                emojiStyle={EmojiStyle.GOOGLE}
                categories={[
                    {
                        name: "Smiles & Emotions",
                        category: Categories.SMILEYS_PEOPLE
                    },
                    {
                        name: "Flags",
                        category: Categories.FLAGS
                    },
                    {
                        name: "Travel Places",
                        category: Categories.TRAVEL_PLACES
                    }, {
                        name: "Fun and Games",
                        category: Categories.ACTIVITIES
                    },
                ]}
            />}

            <div className="innerscroll mb-4">
                <InfiniteScroll
                    dataLength={postsData.length}
                    next={fetchMoreData}
                    // hasMore={postsData.length <= totalCount}
                    hasMore={true}
                    // loader={<h4 className="mt_10 mb_10 text-center">No new posts...</h4>}
                    // loader={<div className="mt_25 mb_10 text-center"><i className="fs_50 fa fa-cog fa-spin" /></div>}
                    useWindow={false}
                    className="ins mt-10"
                >
                    {
                        postsData.length != 0 ? (
                            postsData.map((post, index) => (
                                <div className="box-home box_shadow_1 mt-4" key={index}>
                                    {/*  {
                                        post.createdByUserId == curUserId ?
                                            <>
                                                <div className="dropdown">
                                                    <i className="fa-solid fa-ellipsis text-black-50 float-end fa-md ms-3 dropdown" id="defaultDropdown" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                                    <ul className="dropdown-menu" aria-labelledby="defaultDropdown">

                                                        <li><a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#UpdatePost" onClick={() => { handlePostEdit(post.postId, post.socialEntityTypeId, post.parentSocialEntityTypeId) }}><div>Edit</div></a></li>
                                                        <li><hr className="dropdown-divider"></hr></li>
                                                        <li><a className="dropdown-item" onClick={() => { handlePostDelete(post.postId, post.socialEntityTypeId, post.parentSocialEntityTypeId) }}><div>Delete</div></a></li>

                                                    </ul>
                                                </div> 

                                               
                                            </> : <></>
                                    }*/}

                                    <div className="d-flex px-3 justify-content-start align-items-center">
                                        <div className="d-flex justify-content-start align-items-center">
                                            <img src={post.profilePicture ? post.profilePicture : profilepic} alt="User Picture" className="img-fluid img-jobs" />
                                        </div>
                                        <div>
                                            <p className="ms-3 mb-0">
                                                <span className="fw-bold">{post?.userName}</span>
                                                {post?.parentEntityName ? <>  posted on <Link to={`/cxo/cxo/wall/group/${post?.parentSocialEntityId}`} className="groupname" state={{ groupId: post?.parentSocialEntityId }}>{post?.parentEntityName}</Link></> : ""}
                                                <span className="text-black-50 ps-2"> {dateDiff(post?.createdDate)} </span>
                                            </p>
                                            <p className="ms-3 mb-0">
                                                <span className="text-black-50">{post.profileTitle}</span>
                                            </p>
                                        </div>
                                        {
                                            post.createdByUserId == curUserId ?
                                                <>
                                                    <div className="dropdownPost ml_25 mb_5" title="Delete Post">
                                                        <button onClick={toggleDropdown} data-target-id={post?.postId} className="dropbtnPostModify"></button>
                                                        <div id={`modifyPostDropdown-${post?.postId}`} className="post-dropdown-content d-none">
                                                            {/* <a href="#" data-bs-toggle="modal" data-bs-target="#UpdatePost" onClick={() => { handlePostEdit(post.postId, post.socialEntityTypeId, post.parentSocialEntityTypeId) }} >Edit</a> */}
                                                            <a href="#" onClick={() => { handlePostDelete(post?.postId, post?.socialEntityTypeId, post?.parentSocialEntityTypeId) }} >Delete</a>
                                                        </div>
                                                    </div>
                                                </> : <></>
                                        }
                                    </div>
                                    <div className="px-3 text-black-50 mt-4">
                                        {HTMLReactParser(post.postContent)}
                                        <div className="clearfixs">
                                            {/* <a
                                        href="#"
                                        className="text-link fw-bold float-md-end d-block"
                                    >See more</a
                                    > */}
                                        </div>

                                        {/* <div className="mt_15">
                                            <div className="position-relative text-link inline-block">
                                                {postAttachments.length != 0 ? (postAttachments.filter(media => media.postId == post.postId && media?.mediaType?.indexOf("image") != -1)?.map((ele, index) => (
                                                    <img src={ele?.mediaURL} alt={ele?.mediaTitle} key={index} className="img-IT-rounded img-fluid img-lg mt-4 mr_10" onClick={maximizeImg} data-bs-toggle="modal" data-bs-target="#imgModal" />
                                                ))) : null}
                                                {postAttachments.length != 0 ? (postAttachments.filter(media => media.postId == post.postId && media?.mediaType?.indexOf("video") != -1)?.map((ele, index) => (
                                                    <video key={index} width="320" height="240" muted className="img-IT-rounded img-fluid mt-4 mr_10" controls src={ele?.mediaURL}></video>
                                                ))) : null}
                                            </div>
                                        </div> */}

                                        {postAttachments.length != 0 &&
                                            postAttachments.filter(media => media.postId == post.postId)?.length > 0 ?
                                            (<div className="carousel">
                                                <ul className="slides" id={`slidelist-${post?.postId}`}>
                                                    {
                                                        postAttachments.length != 0 ? (
                                                            postAttachments.filter(media => media.postId == post.postId)?.map((ele, index) => (
                                                                <>
                                                                    <input type="radio" className={`radio-${ele?.postId}`} name={`radio-${ele?.postId}`} id={`media-${ele?.postId}-${index}`} defaultChecked={index === 0 ? true : false} onChange={resetCarouselRadioChoice} />
                                                                    <li className={index === 0 ? "slide-container" : "slide-container d-none"} key={index} id={`li-${ele?.postId}-${index}`}>
                                                                        <div className="slide-image">
                                                                            {
                                                                                ele?.mediaType?.indexOf("image") != -1 ?
                                                                                    <img src={ele?.mediaURL} alt={ele?.mediaTitle} key={index} onClick={maximizeImg} data-bs-toggle="modal" data-bs-target="#imgModal" className="fadeInClass" /> :
                                                                                    <video key={index} className="fadeInClass" muted controls src={ele?.mediaURL} ></video>
                                                                            }
                                                                        </div>
                                                                        <div className="carousel-controls">
                                                                            <label htmlFor={index > 0 ? `media-${ele?.postId}-${index - 1}` : `media-${ele?.postId}-0`} className="prev-slide" onClick={() => { prevMedia(ele?.postId, index); }}>
                                                                                <span className="f-100">&lsaquo;</span>
                                                                            </label>
                                                                            <label htmlFor={`media-${ele?.postId}-${index + 1}`} className="next-slide" onClick={() => { nextMedia(ele?.postId, index); }}>
                                                                                <span className="f-100">&rsaquo;</span>
                                                                            </label>
                                                                        </div>
                                                                    </li>
                                                                </>
                                                            ))
                                                        ) : null
                                                    }
                                                </ul>
                                                <div className="carousel-dots" id={`carousel-dots-${post?.postId}`}>
                                                    {
                                                        postAttachments.length != 0 ? (
                                                            postAttachments.filter(media => media?.postId == post?.postId)?.map((ele, index) => (
                                                                <label htmlFor={`media-${ele?.postId}-${index}`} className={index === 0 ? "carousel-dot main-carousel-dot" : "carousel-dot"} id={`img-dot-${ele?.postId}-${index}`} key={index}></label>
                                                            ))
                                                        ) : null
                                                    }
                                                </div>
                                            </div>) : null
                                        }



                                        <div className="mt-0">
                                            <a href="#" className="text-black-50 v-middle ms-4"
                                            >
                                                <i className={post?.isLiked == false ? "fa-regular fa-heart v-middle pe-1" : "fa-solid liked fa-heart v-middle pe-1"}
                                                    data-parent-entity-type-id={post?.socialEntityTypeId}
                                                    data-parent-entity-id={post?.postId}
                                                    data-reaction-type-id={REACTIONTYPE.HEART}
                                                    data-reaction-status={!post?.isLiked}
                                                    onClick={ReactOnPost}
                                                ></i>
                                                <span id={`post-like-${post?.socialEntityTypeId}-${post?.postId}-${REACTIONTYPE.HEART}`}>{post?.reactionsCount}</span>
                                            </a>

                                            {/* <span id="likebtn"><i className="fa-regular mdi mdi-heart v-middle pe-1" ></i></span>

                                    <span className="text-black-50 v-middle">1952</span> */}

                                            {/* <label
                                        className="text-black-50 v-middle"

                                    >
                                        <i className="fa-regular fa-heart v-middle pe-1"
                                            data-parent-entity-type-id={post?.socialEntityTypeId}
                                            data-parent-entity-id={post?.postId}
                                            data-reaction-type-id={REACTIONTYPE.HEART}
                                            onClick={ReactOnPost}></i>
                                        <span>965</span>
                                    </label> */}
                                            <a
                                                href="#"
                                                className="text-black-50 v-middle ms-4"
                                                data-bs-toggle="modal"
                                                data-bs-target="#UploadPhoto"
                                            >
                                                <i className="fa-regular fa-message v-middle pe-1"></i>
                                                <span id={`post-comment-${post?.socialEntityTypeId}-${post?.postId}`}>{post?.commentsCount}</span>
                                            </a>
                                            <a href="#" className="text-black-50 v-middle ms-4" data-bs-toggle="modal" data-bs-target="#UploadPhoto">
                                                <i className="fa-regular fa-message v-middle pe-1"></i>
                                                <span>
                                                    <a className="collapsed" data-bs-toggle="collapse" href={"#collapse-" + post?.socialEntityTypeId + "-" + post?.postId} aria-expanded="false" aria-controls={"collapse" + "-" + post?.socialEntityTypeId + "-" + post?.postId} >Comment</a>
                                                </span>
                                                <div className="card">
                                                    <div className="card-header" role="tab" id={"heading-" + post?.socialEntityTypeId + "-" + post?.postId}>

                                                    </div>
                                                    {/* <div className="d-flex align-items-end mt-3 pb-3 border-bottom mob_res_grid">

                                                        <img className="img-sm rounded-circle col-1" src={curProfilePicture ? curProfilePicture : profilepic} alt="profile" />
                                                        <div className="ms-3 col-10">
                                                            <h6 className="mb-2">{curUserFullName}</h6>
                                                            <input type="text" className="form-control comment-section" id={"comment-" + post?.socialEntityTypeId + "-" + post?.postId} placeholder="Enter you comment here" />

                                                        </div>
                                                        <div className='emojicommentdiv col-1'>
                                                            <img
                                                                id={"emoji-" + post?.socialEntityTypeId + "-" + post?.postId}
                                                                data-ref={"comment-" + post?.socialEntityTypeId + "-" + post?.postId}
                                                                className="emoji-icon mt_20"
                                                                src="https://mycaas-test-bucket.s3.me-south-1.amazonaws.com/smiley.png"
                                                                onClick={displayEmojiPicker} />

                                                            <button href="#" className="btn-primary mt_15 text-black-50 send-btn commentSubmitBtn"
                                                                data-parent-entity-type-id={post?.socialEntityTypeId}
                                                                data-parent-entity-id={post?.postId}
                                                                onClick={addComment}>
                                                            </button>
                                                        </div>

                                                    </div> */}
                                                    <Comments parentSocialEntityTypeId={post?.socialEntityTypeId} parentSocialEntityId={post?.postId} />
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : null
                    }
                </InfiniteScroll>
                <div className="modal fade show img-modal" id="imgModal">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-header1">
                            <span aria-hidden="true" className="zoomImgclose" data-bs-dismiss="modal" aria-label="Close">&times;</span>
                        </div>
                        <div className="modal-body">
                            <div>
                                <img className="img-modal-content" src={zoomImgInfo?.src} />
                                <div id="caption">{zoomImgInfo?.alt}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <Toaster />
                <div className="modal fade show" id="UpdatePost">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title text-black-50" id="exampleModalLabel1">
                                    Update a post
                                </h3>
                                <button
                                    type="button"
                                    className="close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="d-flex align-items-center">
                                    <img
                                        src={curProfilePicture}
                                        alt="User Image"
                                        className="img-fluid rounded-circle img-profile me-3"
                                    />
                                    <div>
                                        <p className="fw-bold">{curUserFullName}</p>

                                    </div>
                                </div>
                                <div className="cursor mt-3">
                                    <ReactQuill modules={quillModules} theme="snow" value={curPostData.length != 0 ? curPostData.postDetails[0].postContent : null}></ReactQuill>


                                    <div className="mt_15">
                                        <h3 className="modal-title text-black-50">Media</h3>
                                        <div className="flex-row createPostMediaPreviewDiv">

                                            {curPostData.length != 0 ?
                                                curPostData.mediaDetails.length != 0 ? (curPostData.mediaDetails.map((ele, index) => (
                                                    <a className="position-relative text-link inline-block mr_15" key={index}>
                                                        {ele?.mediaType.indexOf("image") != -1 ?
                                                            <img src={ele?.mediaURL} alt="Uploaded Media" className="img-lg mb-2" /> :
                                                            <video width="320" height="240" muted controls autoPlay src={ele?.mediaURL}></video>}
                                                    </a>
                                                ))) : null : null}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer text-black-50 justify-content-between">
                                <div>
                                    <input id="post-image-file-input-update" className="hiddenInputFile" type="file" accept="image/*" />
                                    <label className="text-black-50 m-0 me-3 uploadFileIcon" htmlFor="post-image-file-input-update" >
                                        <i className="fa-regular fa-image"></i>
                                    </label>
                                    <input id="post-video-file-input-update" className="hiddenInputFile" type="file" accept="video/*" />
                                    <label className="text-black-50 m-0 me-3 uploadFileIcon" htmlFor="post-video-file-input-update">
                                        <i className="fa-brands fa-youtube"></i>
                                    </label>

                                </div>
                                <div>
                                    <a className="btn btn-light btn-sm float-end disabled"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        id="updatePostBtn" >Update</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VendorPosts