import React, { useState, useEffect } from 'react'
import $ from "jquery";
import profilepic from "../../../assets/images/noav.jpg"
import { API } from '../../../config/APIConfig';
import axios from 'axios'
import { useTranslation } from "react-i18next";
import { Link, useLocation } from 'react-router-dom'
import Header from '../Header';
import logger from '../../../utils/logger';
import { S3Config } from '../../../config/AWSConfig';
import SocialHeader from '../../../pages/SocialFeeds/commons/SocialHeader';
const CXODETAILSAPI = `${API.URL}api/v1/cxo/details`;
const CXOProfileView = (props) => {
    const { i18n, t } = useTranslation(["registration"]);
    const [personalInfo, setPersonalInfo] = useState([]);
    const [profileInfo, setProfileInfo] = useState([]);
    const [experienceInfo, setExperienceInfo] = useState([]);
    const [employmentInfo, setEmploymentInfo] = useState([]);
    const [educationInfo, setEducationInfo] = useState([]);
    const [certificationInfo, setCertificationInfo] = useState([]);
    const [CVInfo, setCVInfo] = useState([]);
    const [cxoindustryExperienceInfo, setCXOIndustryExperienceInfo] = useState([]);
    const location = useLocation();
    var quotedBy = "";
    var roleId = "";
    const urlheader = location.pathname.split('/')[1].toUpperCase();
    console.log(quotedBy);
    console.log(roleId);
    useEffect(() => {
        window.scrollTo(0, 0);
        if (localStorage.getItem("i18nextLng")?.length > 2) {
            i18n.changeLanguage("en");
        }
        if (props.quotedBy && props.roleId) {
            quotedBy = props.quotedBy;
            roleId = props.roleId;
            $(".headerinfo").hide();
        }
        else {
            quotedBy = location.state?.quotedBy;
            roleId = location.state?.role;
        }
        getCXODetails();
    }, []);
    const getCXODetails = () => {
        // logger.verbose("Caas Member Details", "CXOProfileView Component", "getCXODetails", "GET", "Get Caas Member Details API Hit", `cxoUserId=${quotedBy}&roleId=${roleId}`);
        // axios.get(`${CXODETAILSAPI}?cxoUserId=${props.quotedBy}&roleId=${props.roleId}`).then(response => {
        axios.get(`${CXODETAILSAPI}?cxoUserId=${quotedBy}&roleId=${roleId}`).then(response => {
            //    axios.get(`${CXODETAILSAPI}?cxoUserId=41&roleId=4`).then(response => {
            if (response.data.status === 200) {
                setPersonalInfo(response.data.response.cxoPersonalInfo);
                setProfileInfo(response.data.response.cxoProfileInfo);
                setExperienceInfo(response.data.response.cxoExperienceInfo);
                setEmploymentInfo(response.data.response.cxoEmploymentInfo);
                setEducationInfo(response.data.response.cxoEducationInfo);
                setCertificationInfo(response.data.response.cxoCertificationInfo);
                setCVInfo(response.data.response.cxoUserCVInfo);
                setCXOIndustryExperienceInfo(response.data.response.cxoIndustryExp);
                logger.verbose("Caas Member Details", "CXOProfileView Component", "getCXODetails", "GET", "Get Caas Member Details API Response Successfull", JSON.stringify(response.data.response));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            logger.error("Caas Member Details", "CXOProfileView Component", "getCXODetails", "GET", "Get Caas Member Details API Response Failed", JSON.stringify(error));
        });
    }
    return (
        <>
            <div className="main-body-section">
                <section className="header-section sticky-top1 headerinfo">
                    <SocialHeader />
                </section>
                <section className="body-section previewpagenew">
                    <div className="body-content container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 mob-remove-padding box_2">
                                <div className="box-home p-0 box_shadow_1">
                                    <div className="justify-content-between align-items-center px-3">
                                        <div className="col-md-12 col-lg-12 grid-margin stretch-card">
                                            <div className="card pb-4">
                                                <main className="w-100">
                                                    <div className="elon card">
                                                        <div className="img_profile card-left ">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div id="lightgallery profilegallery" className="row lightGallery">
                                                                        <a href="#" className="image-tile gallery_img">
                                                                            <img src={profileInfo[0]?.profilePicture ? profileInfo[0]?.profilePicture : profilepic} alt="CAAS Member Image" /></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-right pb-0">
                                                            {personalInfo.length != 0 ? (
                                                                personalInfo.map((ele, index) => (
                                                                    <div className="card-title fw-7 fs-2 m-0" key={index}>{ele?.firstName} {ele?.lastName}</div>
                                                                ))
                                                            ) : <div className="card-title fw-7 fs-2 m-0">{t('noUserDataFound')}</div>
                                                            }
                                                            {profileInfo.length != 0 ? (
                                                                profileInfo.map((ele, index) => (
                                                                    <>
                                                                          <div className="card-rb-2">
                                                                                    <div className="card-rb-2-title card-text fw-7">{ele?.profileTitle}
                                                                                    </div>

                                                                                </div>
                                                                                <div className="skill_text text-muted fw-5 m-0">{ele?.profileSummary}</div>

                                                                                <div className="card-rb-2-text card-text fw-7">
                                                                                    <span className="card-text ms-0">
                                                                                        <i className="fa-solid fa-language mt-1 2 text-muted mr_5 fa-xl" title="Languages"></i>
                                                                                        {ele?.profileLanguages}
                                                                                    </span>
                                                                                </div>
                                                                    </>))
                                                            ) : <div className="skill_text text-muted fw-5 m-0">{t('noUserDataFound')}</div>
                                                            }
                                                            <div className="skill card-body ps-0">
                                                                <h4 className="card-title">Skills</h4>
                                                                <div className="template-demo">
                                                                    {experienceInfo.length != 0 ? (
                                                                        experienceInfo.map((ele, index) => (
                                                                            <button type="button" className="btn btn-outline-primary btn-rounded skills-btn btn-fw" key={index}>{ele?.experienceSkillName}</button>
                                                                        ))
                                                                    ) : <>{t('noUserDataFound')}</>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </main>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <nav aria-label="breadcrumb" className="profile-section pl-5 pr-5">
                                                <div className="breadcrumb mb-0 bg-profilesection">
                                                    <ul className=" nav nav-pills nav-pills-success pb-0 mb-0 border-bottom-0" id="pills-tab" role="tablist">
                                                        <li className="nav-item list mb-0">
                                                            <a className="nav-link active " id="pills-experience-tab" data-bs-toggle="pill" href="#pills-experience" role="tab" aria-controls="pills-experience" aria-selected="true"><i className="mdi mdi-account-star menu-icon"></i> {t('experience')} </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="pills-industry-tab" data-bs-toggle="pill" href="#pills-industry" role="tab" aria-controls="pills-industry" aria-selected="false"><i className="mdi mdi-wrap menu-icon"></i>  {t('industry')} </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="pills-employment-tab" data-bs-toggle="pill" href="#pills-employment" role="tab" aria-controls="pills-employment" aria-selected="false"><i className="mdi mdi-comment-account menu-icon"></i>  {t('employment')} </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="pills-employment-tab" data-bs-toggle="pill" href="#pills-education" role="tab" aria-controls="pills-education" aria-selected="false"><i className="mdi mdi mdi-chart-bar menu-icon"></i>  {t('education')} </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="pills-employment-tab" data-bs-toggle="pill" href="#pills-Certification" role="tab" aria-controls="pills-Certification" aria-selected="false"><i className="fa fa-graduation-cap menu-icon"></i>  {t('certification')} </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="pills-employment-tab" data-bs-toggle="pill" href="#pills-CV" role="tab" aria-controls="pills-CV" aria-selected="false"><i className="fa fa-file-text menu-icon"></i>   CV </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="pills-employment-tab" data-bs-toggle="pill" href="#pills-pi" role="tab" aria-controls="pills-pi" aria-selected="false"><i className="mdi mdi-account-check menu-icon"></i> {t('profile')} </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </nav>
                                            <div className="col-md-12 col-lg-12 grid-margin stretch-card card_alignment ">
                                                <div className="card  pb-4">
                                                    <div className="card-body ">
                                                        <div className="border-1">
                                                        </div>
                                                        <div className="tab-content" id="pills-tabContent">
                                                            <div className="tab-pane fade show active" id="pills-experience" role="tabpanel" aria-labelledby="pills-experience-tab">
                                                                <div className="row">
                                                                    <div className="border-top1">
                                                                    </div>
                                                                    {
                                                                        experienceInfo.length != 0 ? (
                                                                            experienceInfo.map((ele, index) => (
                                                                                <div className="col-md-12 col-sm-12 col-lg-4 grid-margin stretch-card ps-sm-0 ps-lg-2 ps-md-3 mb-4 " key={index}>
                                                                                    <div className=" space card card-rounded mr-5">
                                                                                        <div className="card-body">
                                                                                            <ul className="bullet-line-list">
                                                                                                <li>
                                                                                                    <div className="d-flex mt-1">
                                                                                                        <div><span className="text-light-green fw-bold">{t('skill')}&nbsp;:  </span></div>
                                                                                                        <p className="text-light-green text-muted mt-2"> &nbsp;{ele?.experienceSkillName}</p>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="d-flex ">
                                                                                                        <div><span className="text-light-green fw-bold">{t('softwareVersion')} &nbsp;:</span></div>
                                                                                                        <p className="text-light-green text-muted mt-2"> &nbsp;{ele?.experienceSoftwareVersionName}</p>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="d-flex ">
                                                                                                        <div><span className="text-light-green fw-bold">{t('lastUsed')} &nbsp;:</span></div>
                                                                                                        <p className="text-light-green text-muted mt-2"> &nbsp;{ele?.experienceLastUsed}</p>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="d-flex ">
                                                                                                        <div><span className="text-light-green fw-bold">{t('experience')}&nbsp;:</span> </div>
                                                                                                        <p className="text-light-green text-muted mt-2"> &nbsp;{ele?.experienceYears} {t('years')}</p>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="d-flex ">
                                                                                                        <div><span className="text-light-green fw-bold">{t('implementationSize')}&nbsp;:</span> </div>
                                                                                                        <p className="text-light-green text-muted mt-2"> &nbsp;{ele?.experienceImplementationSize}</p>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="d-flex ">
                                                                                                        <div><span className="text-light-green fw-bold">{t('experienceLevel')}&nbsp;:</span> </div>
                                                                                                        <p className="text-light-green text-muted mt-2"> &nbsp;{ele?.experienceLevelName}</p>
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ul>
                                                                                            <div className="list align-items-center float-end pt-3">
                                                                                                <div className="w-100">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        ) : (
                                                                            <div className="cardPad card-body box-shadow pb_30"><p>{t('noRecordsAvailable')}</p></div>
                                                                        )
                                                                    }


                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade" id="pills-industry" role="tabpanel" aria-labelledby="pills-industry-tab">
                                                                <div className="row">
                                                                    <div className="border-top1">
                                                                    </div>
                                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                                        <div className="card card-rounded">
                                                                            <div className="card-body">
                                                                                <div>
                                                                                    <div className="mt-5">
                                                                                        {
                                                                                            cxoindustryExperienceInfo.length != 0 ? (
                                                                                                cxoindustryExperienceInfo.map((ele) => (
                                                                                                    <>
                                                                                                        <button type="button" className="btn industry-list btn-outline-secondary btn-sm ml_20 mb-3" value={ele?.industryId}> {ele?.industryname} </button>
                                                                                                    </>
                                                                                                ))
                                                                                            ) : (
                                                                                                <p>{t('noRecordsAvailable')}</p>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                    <div className="list align-items-center float-end pt-3">
                                                                                        <div className="w-100">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade" id="pills-employment" role="tabpanel" aria-labelledby="pills-employment-tab">
                                                                <div className="row">
                                                                    <div className="border-top1">

                                                                    </div>
                                                                    <div className="col-lg-6 col-sm-12 col-md-12 mb-3">
                                                                        <div className="space card card-rounded">
                                                                            <div className="card-body">
                                                                                {
                                                                                    employmentInfo.length != 0 ? (
                                                                                        employmentInfo.map((ele) => (
                                                                                            <>
                                                                                                <ul className="bullet-line-list">
                                                                                                    <li>
                                                                                                        <div className="d-flex justify-content-between">
                                                                                                            <div><span className="text-light-green fw-bold d-block">{ele?.employmentDesignation}
                                                                                                            </span>
                                                                                                            <span className="fw-bold">{ele?.employmentCompany} </span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                                                                                                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                                                                                                                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                                                                                                        </svg>{ele?.employmentStateName}, {ele?.employmentCountryName}
                                                                                                                <span className="text-light-green text-muted d-block">
                                                                                                                Skills: {ele?.employmentSkills} </span>
                                                                                                                {/* <span className="text-light-green text-muted">
                                                                                                                    {ele?.employmentCityName}, {ele?.employmentStateName}, {ele?.employmentCountryName}
                                                                                                                </span> */}
                                                                                                            </div>
                                                                                                            <p className="fw-bold"> {ele?.employmentYears} {t('years')} </p>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </>
                                                                                        ))
                                                                                    ) : (
                                                                                        <div className="cardPad card-body box-shadow pb_30"><p>{t('noRecordsAvailable')}</p></div>
                                                                                    )
                                                                                }


                                                                                <div className="list align-items-center float-end pt-3">
                                                                                    <div className="w-100">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade" id="pills-education" role="tabpanel" aria-labelledby="pills-employment-tab">
                                                                <div className="row">
                                                                    <div className="border-top1">
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                                                                        <div className="space card card-rounded">
                                                                            <div className="card-body">
                                                                                <ul className="bullet-line-list">

                                                                                    {
                                                                                        educationInfo.length != 0 ? (
                                                                                            educationInfo.map((ele) => (
                                                                                                <>
                                                                                                    <li>
                                                                                                        <div className="d-flex justify-content-between">
                                                                                                            <div><span className="text-light-green">
                                                                                                                {ele?.educationName}  -  {ele?.educationCourseName}  </span>
                                                                                                                <p className="text-muted">{ele?.educationInstituteName}</p>
                                                                                                            </div>
                                                                                                            <p className="fw-bold text-muted">{ele?.educationCompletedYear}</p>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                </>
                                                                                            ))
                                                                                        ) : (
                                                                                            <div className="cardPad card-body box-shadow pb_30"><p>{t('noRecordsAvailable')}</p></div>
                                                                                        )
                                                                                    }

                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade" id="pills-Certification" role="tabpanel" aria-labelledby="pills-employment-tab">
                                                                <div className="row">
                                                                    <div className="border-top1">

                                                                    </div>
                                                                    {
                                                                        certificationInfo.length != 0 ? (
                                                                            certificationInfo.map((ele) => (
                                                                                <>

                                                                                    <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                                                                                        <div className="space card card-rounded">
                                                                                            <div className="card-body">
                                                                                                <ul className="bullet-line-list">
                                                                                                    <li>
                                                                                                        <div className="d-flex justify-content-between">
                                                                                                            <div><span className="text-light-green fw-bold">
                                                                                                                {t('certificationTitle')}: </span>
                                                                                                                <span className="text-light-green text-muted">
                                                                                                                    {ele?.certificationTitle}</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <div className="d-flex justify-content-between">
                                                                                                            <div><span className="text-light-green fw-bold">
                                                                                                                {t('completiondate')} : </span>
                                                                                                                <span className="text-light-green text-muted">
                                                                                                                    {ele?.completionDate}</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <div className="d-flex justify-content-between">
                                                                                                            <div><span className="text-light-green fw-bold">
                                                                                                                {t('organization/institute')}: </span>
                                                                                                                <span className="text-light-green text-muted">
                                                                                                                    {ele?.certificationOrganization} </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <div className="d-flex justify-content-between">
                                                                                                            <div><span className="text-light-green fw-bold">{t('certification')}: </span>
                                                                                                                <span className="text-light-green text-muted">
                                                                                                                    <a href={ele?.certificationFiles} className="text-link country" target="_blank">
                                                                                                                        {ele?.certificationFiles ? ele?.certificationFiles.split('/')[3]?.split(S3Config.S3ObjectDelimiter)[0] : ""}</a>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            ))
                                                                        ) : (
                                                                            <div className="cardPad card-body box-shadow pb_30"><p>{t('noRecordsAvailable')}</p></div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade " id="pills-CV" role="tabpanel" aria-labelledby="pills-employment-tab">
                                                                <div className="border-top1">

                                                                </div>
                                                                <div className="space card card-rounded">
                                                                    <div className="card-body">
                                                                        <div className="text-center" id="uploadCVBtn">

                                                                            {
                                                                                CVInfo.length != 0 ? (
                                                                                    CVInfo.map((ele) => (
                                                                                        <>
                                                                                            {/* <button type="file" data-bs-toggle="modal" data-bs-target="#UploadCV" className="tn btn-warning upload-cv-btn mt-5 btn-icon-text btn-bg-theme"><i className="mdi mdi-file-chart btn-icon-prepend"></i> My-CV.pdf</button>*/}

                                                                                            <a href={ele?.cv_attachment ? ele?.cv_attachment : "#"} target={ele?.cv_attachment ? "_blank" : ""} className="text-link profileupdate_btncv tn btn-warning upload-cv-btn mt-5 btn-icon-text btn-bg-theme"> <i className="mdi mdi-file-chart btn-icon-prepend"></i> {ele?.user_cv_title} </a>
                                                                                        </>
                                                                                    ))
                                                                                ) : (
                                                                                    <div className="cardPad card-body box-shadow pb_30"><p>{t('noRecordsAvailable')}</p></div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade" id="pills-pi" role="tabpanel" aria-labelledby="pills-employment-tab">
                                                                <div className="row">
                                                                    <div className="border-top1">

                                                                    </div>
                                                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                                                        <div className="space card card-rounded">
                                                                            <div className="card-body">
                                                                                {personalInfo.length != 0 ? (
                                                                                    personalInfo.map((ele) => (
                                                                                        <>
                                                                                            <ul className="bullet-line-list">
                                                                                                <li>
                                                                                                    <div className="d-flex justify-content-between">
                                                                                                        <div><span className="text-light-green fw-bold">{t('name')} : </span>
                                                                                                            <span className="text-light-green text-muted">{ele?.firstName} {ele?.lastName}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="d-flex justify-content-between">
                                                                                                        <div><span className="text-light-green fw-bold">{t('userName')} : </span>
                                                                                                            <span className="text-light-green text-muted">{ele?.userName}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="d-flex justify-content-between">
                                                                                                        <div><span className="text-light-green fw-bold">{t('email')} : </span>
                                                                                                            <span className="text-light-green text-muted">
                                                                                                                {ele?.email} </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="d-flex justify-content-between">
                                                                                                        <div><span className="text-light-green fw-bold">{t('gender')} : </span>
                                                                                                            <span className="text-light-green text-muted">
                                                                                                                {ele?.genderName}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="d-flex justify-content-between">
                                                                                                        <div><span className="text-light-green fw-bold">Phone no : </span>
                                                                                                            <span className="text-light-green text-muted">
                                                                                                                {ele?.countryPhoneCode} {ele?.phoneNumber} </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="d-flex justify-content-between">
                                                                                                        <div><span className="text-light-green fw-bold">{t('country')} : </span>
                                                                                                            <span className="text-light-green text-muted">
                                                                                                                <Link to="/cxo/cxo/caasmembers" state={{ searchfilterName: "country", searchFilterKey: ele?.country }}>
                                                                                                                    {ele?.countryName}
                                                                                                                </Link>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="d-flex justify-content-between">
                                                                                                        <div><span className="text-light-green fw-bold">{t('state')} : </span>
                                                                                                            <span className="text-light-green text-muted">
                                                                                                                <Link to="/cxo/cxo/caasmembers" state={{ searchfilterName: "state", searchFilterKey: ele?.state }}>
                                                                                                                    {ele?.stateName}
                                                                                                                </Link></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="d-flex justify-content-between">
                                                                                                        <div><span className="text-light-green fw-bold">{t('city')} : </span>
                                                                                                            <span className="text-light-green text-muted">
                                                                                                                <Link to="/cxo/cxo/caasmembers" state={{ searchfilterName: "city", searchFilterKey: ele?.city }}>
                                                                                                                    {ele?.cityName}
                                                                                                                </Link> </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="d-flex justify-content-between">
                                                                                                        <div><span className="text-light-green fw-bold">{t('zipCode')} : </span>
                                                                                                            <span className="text-light-green text-muted">
                                                                                                                {ele?.zipCode ? ele?.zipCode : "No data"} </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="d-flex justify-content-between">
                                                                                                        <div><span className="text-light-green fw-bold">Po Box : </span>
                                                                                                            <span className="text-light-green text-muted">
                                                                                                                {ele?.poBoxNo ? ele?.poBoxNo : "No data"} </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="d-flex justify-content-between">
                                                                                                        <div><span className="text-light-green fw-bold">{t('address')} : </span>
                                                                                                            <span className="text-light-green text-muted">
                                                                                                                {ele?.address} </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <div className="d-flex justify-content-between">
                                                                                                        <div><span className="text-light-green fw-bold">{t('caasMemberCode')}  : </span>
                                                                                                            <span className="text-light-green text-muted">
                                                                                                                {ele?.cioCode} </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </>
                                                                                    ))
                                                                                ) : <>{t('noUserDataFound')}</>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                <section className="footer-sections"></section>
            </div >
        </>
    )
}
export default CXOProfileView
