import React, { useState, useEffect } from 'react'
import profilepic from "../../../assets/images/noav.jpg"
import { API } from '../../../config/APIConfig';
import axios from 'axios';
import { Link } from 'react-router-dom'
const ProfileInfoAnalytics = () => {
    const [analyticsInfo, setAnalyticsInfo] = useState([])
    const loggedInUserId = sessionStorage.getItem("userId");
    const curuserRoleId = sessionStorage.getItem("roleId");
    const curUserEntityId = sessionStorage.getItem("entityId");
    const CAASMEMBERPROFILEANALYTICSAPI = `${API.URL}api/v1/social/caasmember/profile/analytics`;
    const getCaasMembersDetails = () => {
        axios.get(`${CAASMEMBERPROFILEANALYTICSAPI}?userId=${loggedInUserId}&userEntityId=${curUserEntityId}&userRoleId=${curuserRoleId}`)
            .then(resp => {
                setAnalyticsInfo(resp.data.response)
            }).catch(error => {
                console.log(error);
            })
    }
    useEffect(() => {
        getCaasMembersDetails();
    }, [])

    return (
        <div className="border-top-gray">

            {
                analyticsInfo.length != 0 ? (
                    analyticsInfo.map((elem, index) => (
                        <div className="box-home box_shadow_1 icon-box main-company-list px-4 pt-4 pb-2" key={index}>
                             <Link to="/cxo/myprofile" state={{ quotedBy: loggedInUserId, role: curuserRoleId }}><img src={elem?.profilePicture ? elem?.profilePicture : profilepic} alt="Profile" className="img-fluid user_icon" />  </Link>
                            <div className="list-company mt-2">
                                <div className="text-center">
                                    <h3 className="fw-bold text-center fs-4"></h3>
                                    <Link to="/cxo/myprofile" state={{ quotedBy: loggedInUserId, role: curuserRoleId }}><p className="text-black-50 fw-bold fs-6">{elem?.firstName} {elem?.lastName}</p></Link>
                                </div>
                                <ul className="fs-6 mt-2 List_post">
                                    <li className="fs-6 d-flex justify-content-between">
                                        <span className="fs-6">My Connections</span>
                                        <span className="fs-6 float-end text-link">{elem?.totalConnections}</span>
                                    </li>
                                    <li className="fs-6 d-flex justify-content-between">
                                        <span className="fs-6">Posts Created</span>
                                        <span className="fs-6 float-end text-link">{elem?.totalPostsCreated}</span>
                                    </li>
                                    <li className="fs-6 d-flex justify-content-between">
                                        <span className="fs-6">My Groups</span>
                                        <span className="fs-6 float-end text-link">{elem?.totalGroupsCreated}</span>
                                    </li>
                                    <li className="fs-6 d-flex justify-content-between">
                                        <span className="fs-6">Joined Groups</span>
                                        <span className="fs-6 float-end text-link">{elem?.totalGroupsJoined}</span>
                                    </li>
                                    <li className="fs-6 d-flex justify-content-between">
                                        <span className="fs-6">Quotes Submited</span>
                                        <span className="fs-6 float-end text-link">{elem?.totalQuotesSubmitted}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    ))
                ) : null
            }

        </div>
    )
}

export default ProfileInfoAnalytics
