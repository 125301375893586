import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { API } from '../../config/APIConfig';
import axios from 'axios';
import { useTranslation } from "react-i18next";

const GETRFPDETAILAPI = `${API.URL}api/v1/enterprise/rfp/view`
const RFPDetailsCommon = (props) => {
    //let { rfpId } = useParams();
    const { i18n, t } = useTranslation(["rfp"]);

    let rfpId = props.rfpId;   
    const [rfpDetails, setRfpDetails] = useState([]);
    const [rfpindustryExperienceInfo, setRFPIndustryExperienceInfo] = useState([]);
    const [rfpExperience, setRfpExperience] = useState([{}]);
    var enterpriseId ="";
    var userId = "";
    const roleId = sessionStorage.getItem("roleId");
    if(roleId == "3")
    {
        userId = sessionStorage.getItem("userId");
        enterpriseId = sessionStorage.getItem("enterpriseId");
    }
    else if(roleId == "4")
    {
        userId = 0;
        enterpriseId = 0;
    }

    function getRFPDetails(enterpriseId, userId, rfpId) {
        axios.get(`${GETRFPDETAILAPI}/?enterpriseId=${enterpriseId}&userId=${userId}&rfpId=${rfpId}`)
            .then(resp => {
      
                setRfpDetails(resp.data.response.rfpDetails)      
                setRfpExperience(resp.data.response.rfpExperience)
                setRFPIndustryExperienceInfo(resp.data.response.rfpIndustryExp)
            }).catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        getRFPDetails(enterpriseId, userId, rfpId);

    }, []);

    return (
        <>
            <div className="d-flex justify-content-between align-items-start RFP_Details">
                <div className="list-item-details">
                    <div className="d-table">
                        <div className="d-table-cell pe-5">RFP {t('title')} :</div>
                        <div className="d-table-cell text-orange">{rfpDetails.rfpTitle}</div>
                    </div>
                    <div className="d-table">
                        <div className="d-table-cell pe-5">{t('startDate')}:</div>
                        <div className="d-table-cell">{rfpDetails.rfpStartDate}</div>
                    </div>
                    <div className="d-table">
                        <div className="d-table-cell pe-5">{t('quoteDueDate')}: </div>
                        <div className="d-table-cell">{rfpDetails.rfpQuoteDueDate}</div>
                    </div>
                    <div className="d-table">
                        <div className="d-table-cell pe-5">{t('budget')}: </div>
                        <div className="d-table-cell"><p><b>{t('minimumPrice')}</b>: ${rfpDetails.rfpMinBudget} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <b>{t('maximumPrice')}</b>: ${rfpDetails.rfpMaxBudget}</p></div>
                    </div>
                    <div className="d-table">
                        <div className="d-table-cell pe-5">{t('caasMemberCode')}: </div>
                        <div className="d-table-cell"> {rfpDetails.cxoUser}</div>
                    </div>
                    <div className="d-table">
                        <div className="d-table-cell pe-5">{t('totalExperience')} : </div>
                        <div className="d-table-cell">{rfpDetails.rfpIndustryExperience} {t('years')}</div>
                    </div>
                </div>
            </div>
            <div className="accordion" id="accordion" role="tablist">
                <div className="card">
                    <div className="card-header" role="tab" id="heading-2">
                        <h6 className="mb-0">
                            <a className="collapsed" data-bs-toggle="collapse" href="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                                {t('requirementAndScope')}
                            </a>
                        </h6>
                    </div>
                    <div id="collapse-2" className="collapse" role="tabpanel" aria-labelledby="heading-2" data-bs-parent="#accordion">
                        <div className="card-body">
                            <div className="left-col-deatil col ">
                                <ul className="job-detail-des">
                                    <li><span>{t('requirement')}:</span></li>
                                    <li>
                                        {rfpDetails.rfpRequirement}
                                    </li>
                                    <li>
                                        <span> {t('inscopeItems')}:</span>
                                    </li>
                                    <li>
                                        {rfpDetails.rfpInScope}
                                    </li>
                                    <li>
                                        <span> {t('outscopeItems')}:</span>
                                    </li>
                                    <li>
                                        {rfpDetails.rfpOutScope}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header" role="tab" id="heading-3">
                        <h6 className="mb-0">
                            <a className="collapsed" data-bs-toggle="collapse" href="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                               {t('skillsRequiredForCASSMember')}
                            </a>
                        </h6>
                    </div>
                    <div id="collapse-3" className="collapse" role="tabpanel" aria-labelledby="heading-3" data-bs-parent="#accordion">
                        <div className="card-body">
                            <h4 className="card-title">
                                {t('industry')}
                            </h4>
                            <div className="cardPad card-body box-shadow pb_30">
                                {/* <button type="button" className="btn industry-list btn-outline-secondary btn-sm ">{t('banking')}</button>
                                <button type="button" className="btn industry-list btn-outline-secondary btn-sm ml_20">{t('manufacturing')}</button> */}
                                {/* <button type="button" className="btn industry-list btn-outline-secondary btn-sm ml_20">Insurance</button>
                                <button type="button" className="btn industry-list btn-outline-secondary btn-sm ml_20">Pharma</button> */}
                                {
                                    rfpindustryExperienceInfo.length != 0 ? (
                                        rfpindustryExperienceInfo.map((ele) => (
                                            <button type="button" className="btn industry-list btn-outline-secondary btn-sm ml_20" value={ele.industryId}> {ele.industryname} </button>
                                        ))
                                    ) : (
                                        <p>{t('noRecordsAvailable')}</p>
                                    )
                                }
                            </div>
                            <h4 className="card-title">
                               {t('hskills')}
                            </h4>
                            <div className="row">
                                <>  { rfpExperience != 0 ? (
                                         rfpExperience.map((ele) => (
                                    <div className="col-6">
                                        <div className="cardPad card-body border border-radius mt-0 text-left box-shadow">
                                            <div className="d-flex justify-content-between align-items-start">
                                                <div className="widgetCont mt-0" key={ele.expid}>
                                                    <div className="prefill">
                                                        <div className="text-left mb_10"><b className="float-start">{t('skill')}:</b>{ele?.experienceSkill}</div>
                                                    </div>
                                                    <div className="prefill">
                                                        <div className="text-left mb_10"><b className="float-start">{t('softwareVersion')}:</b> {ele?.experienceSoftwareVersion}</div>
                                                    </div>
                                                    <div className="prefill">
                                                        <div className="text-left mb_10"><b className="float-start">{t('lastUsed')}:</b> {ele?.experienceLastUsed}</div>
                                                    </div>
                                                    <div className="prefill">
                                                        <div className="text-left mb_10"><b className="float-start">{t('experience')}:</b> {ele.experienceYears} {t('years')} , {ele.experienceMonths} {t('months')}</div>
                                                    </div>
                                                    <div className="prefill">
                                                        <div className="text-left mb_10"><b className="float-start">{t('implementationSize')}:</b> {ele?.experienceImplementationSize} {t('users')}</div>
                                                    </div>
                                                    <div className="prefill">
                                                        <div className="text-left mb_10"><b className="float-start">{t('experienceLevel')}:</b> {ele?.experienceLevelName}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))) : (
                                    <p>{t('noRecordsAvailable')}.</p>
                                )}
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RFPDetailsCommon