import React, { useEffect, useState } from 'react'
import { onSnapshot, doc } from "firebase/firestore";
import { db } from '../../../config/firebaseConfig';
import profilepic from "../../../assets/images/noav.jpg";
const ConversationUsers = ({ loggedInChatUserUID, thisUser, setThisChat }) => {
    const [data, setData] = useState("");
    const selectedUserUId = thisUser?.uid;
    useEffect(() => {
        const id = loggedInChatUserUID > selectedUserUId ? `${loggedInChatUserUID + selectedUserUId}` : `${selectedUserUId + loggedInChatUserUID}`;
        let unsub = onSnapshot(doc(db,"tbl_chat_last_messages", id), (doc) => {
            setData(doc.data());
        });
        return () => unsub();
    }, []);
    return (
        <>
        { selectedUserUId && loggedInChatUserUID ? (<div className="mt-4" id={selectedUserUId} key={selectedUserUId} onClick={setThisChat}>
            <div className="d-flex justify-content-start align-items-center">
                <img
                    src={thisUser?.profilePicture ? thisUser?.profilePicture : profilepic}
                    alt={thisUser?.name}
                    className="img-fluid img-jobs"
                />
                <div className="ps-3">
                    <p className="mb-0 fw-bold">{thisUser?.name}</p>
                    <p className="mb-0 text-black-50 fs-6">
                        {data ? data?.text : ""}
                    </p>
                </div>
            </div>
        </div>) : null}
        </>
    )
}

export default ConversationUsers