import { createUserWithEmailAndPassword } from "firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../config/firebaseConfig";
import { collection, query, where, onSnapshot, addDoc, Timestamp, orderBy, setDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { successToast, celebrationToast, errorToast } from '../../utils/toastUtil';
import { data } from "jquery";

export const isRegistered = async (curUserKey) => {
    const userRef = doc(db, "tbl_chat_user_master", curUserKey);
    const docSnap = await getDoc(userRef);
    return docSnap.exists();
}
export const Register = async (name, email, password, userKey, profilePicture) => {
    if (!name || !email || !password || !userKey) {
        errorToast("Registration Failed");
        return
    }
    try {
        const result = await createUserWithEmailAndPassword(
            auth,
            email,
            password
        );
        await setDoc(doc(db, "tbl_chat_user_master", userKey), {
            uid: result.user.uid,
            name,
            email,
            userKey,
            profilePicture,
            createdAt: Timestamp.fromDate(new Date()),
            isOnline: false,
            lastLogInAt: null,
            status: 1
        }).then(resp => {
            celebrationToast(name + " can start chat now");
        })
    } catch (err) {
        console.log("Error Occured" + err);
        // errorToast("Error Occured" + err);
    }
}
export const Login = async (email, password, userKey) => {
    if (!email || !password || !userKey) {
        errorToast("Login Failed");
    }
    try {
        const result = await signInWithEmailAndPassword(auth, email, password);
        await updateDoc(doc(db, "tbl_chat_user_master", userKey), {
            isOnline: true, lastLogInAt: Timestamp.fromDate(new Date())
        });
    } catch (err) {
        console.log("Error Occured" + err);
        errorToast("Error Occured" + err);
    }
}
export const memberList = () => {
    const user = auth.currentUser.uid;
    const usersRef = collection(db, "tbl_chat_user_master");
    const q = query(usersRef, where("uid", "not-in", [user]));
    let users = [];
    const unsub = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
            users.push(doc.data());
        });
        return users;
    });
}
