import React, { useEffect, useContext, useState } from 'react'
import { RFPListContext } from './contexts/RFPListContext';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const ClosedRFPEnterprise = (props) => {
    const { i18n, t } = useTranslation(["rfp"]);

    var enterpriseId = props.enterpriseId; //pass enterpriseId to GET Open RFP (enterprise) api
    const { curRFPList, setCurRFPList } = useContext(RFPListContext);
    const [closedRFPList, SetClosedRFPList] = useState([]);
    var enterpriseId = props.enterpriseId;
    useEffect(() => {
        getClosedRFPList();
    }, [curRFPList])
    const getClosedRFPList = () => {
        var data = curRFPList.filter((ele) => ele.enterpriseId == enterpriseId && ele.rfpStatusId == '2' && ele.rfpStatus == 'Closed');
        SetClosedRFPList([...data]);
    }
    return (
        <>  {
            closedRFPList.length != 0 ? (
                closedRFPList.map((ele) => (
                    <>
                        <div className="grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mt-0 text-left box-shadow p-4 cardPad card-body border border-radius">
                                        <div className="d-flex justify-content-between align-items-start RFP_Details">
                                            <div className="list-item-details">
                                                <div className="d-table">
                                                    <div className="d-table-cell pe-5">{t('title')}</div>
                                                    <div className="d-table-cell text-orange">{ele?.rfpTitle}</div>
                                                </div>
                                                <div className="d-table">
                                                    <div className="d-table-cell pe-5">{t('postedDate')}</div>
                                                    <div className="d-table-cell">{ele?.postedDate}</div>
                                                </div>
                                                <div className="d-table">
                                                    <div className="d-table-cell pe-5">{t('proposalDueDate')}</div>
                                                    <div className="d-table-cell">{ele?.lastDateForResponse}</div>
                                                </div>
                                                <div className="d-table">
                                                    <div className="d-table-cell pe-5">{t('statusRFP')}</div>
                                                    <div className="d-table-cell text-red">
                                                        <Link to={`/enterprise/manager/rfp/view/${ele.rfpId}`} state={{ "passrfpId": `${ele.rfpId}` }} className="edit-profile btn-orange quote-btn">{t(`${ele.rfpStatus}`)}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link to={`/enterprise/manager/quote/viewReceived/${ele?.rfpId}`} className="edit-profile btn-orange quote-btn">{t('quoteReceived')} - {ele.quotesReceived}</Link>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ))
            ) : null
        }
        </>
    )
}

export default ClosedRFPEnterprise