import React, { useState, useEffect } from 'react';
import SocialHeader from './commons/SocialHeader';
import SocialFeedNavigations from './commons/SocialFeedNavigations';
import CAASWorkspaceNavigations from './commons/CAASWorkspaceNavigations';
import CompanyAnnouncements from './commons/CompanyAnnouncements';
import ProfileInfoAnalytics from './commons/ProfileInfoAnalytics';
import { API } from '../../config/APIConfig';
import axios from 'axios';
import dateDiff from '../../utils/dateDiff';
import Connections from './commons/Connections';
import { Toaster } from 'react-hot-toast';
import { successToast, celebrationToast, errorToast } from '../../utils/toastUtil';
import { Link, useNavigate } from 'react-router-dom';
import logger from '../../utils/logger';
import $ from "jquery";
const GROUPPARTICIPATIONREQUESTS = `${API.URL}api/v1/social/group/participate/requests`;
const ManageGROUPPARTICIPATIONREQUEST = `${API.URL}api/v1/social/group/participate/request/manage`;

const GroupNotifications = () => {
    const [groupParticipationRequests, setGroupParticipationRequests] = useState([]);
    const curUserId = sessionStorage.getItem("userId");
    const curUserRoleId = sessionStorage.getItem("roleId");
    const curUserEntityId = 3;

    const getGroupParticipationRequests = () => {
        // logger.verbose("Group Request", "GroupNotifications Component", "getGroupParticipationRequests", "GET", "Get Group Participation Request Count API Hit", `userId=${curUserId}&userEntityId=${curUserEntityId}&userRoleId=${curUserRoleId}`);
        axios.get(`${GROUPPARTICIPATIONREQUESTS}?userId=${curUserId}&userEntityId=${curUserEntityId}&userRoleId=${curUserRoleId}`)
            .then(resp => {
                if (resp.data.status === 200) {
                    setGroupParticipationRequests(resp.data.response);
                    document.getElementById("groupPartReqs").innerText = resp.data.response?.length;
                    // logger.verbose("Group Request", "GroupNotifications Component", "getGroupParticipationRequests", "GET", "Get Group Participation Request Count API Respone Successfull", JSON.stringify(resp.data.response));
                }
            }).catch(error => {
                console.log(error);
                logger.error("Group Request", "GroupNotifications Component", "getGroupParticipationRequests", "GET", "Get Group Participation Request Count API Respone Failed", JSON.stringify(error));
            })
    }
    useEffect(() => {
        getGroupParticipationRequests();
        $("html").removeClass();
        $("html").addClass('groupnotificationss');
        var box_4 = $('.box_4 .sticky-top .box_shadow_1:first-child').height() + $('.header-section').height();
         $(".box_4 .sticky-top .box_shadow_1 .companyannouncements1").attr('style', 'height:' + (($(window).height() - box_4)-63 + 'px !important'));
         setTimeout(function(){ var box_1 = $(window).height() - $('.box_1 .sticky-top .box_shadow_1:first-child').height();
         $(".box_1 .sticky-top .box_shadow_1 .list-company").attr('style', 'height:' + (box_1-213+ 'px !important'));
        }, 3000);
    }, [])
    const handleInvitation = (groupId, participationRequestId, isAccepted, requestedByUserId, requestedByUserEntityId, requestedByUserRoleId, requestedByUserName) => {
        // logger.verbose("Group Request", "GroupNotifications Component", "handleInvitation", "POST", "Handle Group Participation Invitation", `groupId${groupId}/participationRequestId${participationRequestId}/isAccepted${isAccepted}/requestedByUserId${requestedByUserId}/requestedByUserEntityId${requestedByUserEntityId}/requestedByUserRoleId${requestedByUserRoleId}/requestedByUserName${requestedByUserName}`);
        let is_accepted = 0;
        if (isAccepted) is_accepted = 1;
        const requestObj = {
            "groupId": groupId,
            "participationRequestId": participationRequestId,
            "requestedByUserId": requestedByUserId,
            "requestedByUserEntityId": requestedByUserEntityId,
            "requestedByUserRoleId": requestedByUserRoleId,
            "requestedToUserId": curUserId,
            "requestedToUserEntityId": curUserEntityId,
            "requestedToUserRoleId": curUserRoleId,
            "isAccepted": is_accepted
        }
        // logger.verbose("Group Request", "GroupNotifications Component", "handleInvitation", "POST", "Handle Group Participation Invitation object creation", JSON.stringify(requestObj))
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
        }
        axios.post(ManageGROUPPARTICIPATIONREQUEST, requestObj, {
            headers: headers
        }).then(response => {
            if (response?.data?.status === 200) {
                if (response?.data?.response === 1) {
                    celebrationToast(`${requestedByUserName} was added to the group successfully!`)
                }
                else {
                    successToast('Request was rejected Successfully')
                }
                // logger.verbose("Group Request", "GroupNotifications Component", "handleInvitation", "POST", "Handle Group Participation API Response Successfull!!", JSON.stringify(response?.data?.response))
                getGroupParticipationRequests();
            }
        }).catch(error => {
            console.error('There was an error!', error);
            logger.error("Group Request", "GroupNotifications Component", "handleInvitation", "POST", "Handle Group Participation API Response Failed!!", JSON.stringify(error))
        });
    }

    return (
        <>
            <div className="main-body-section main-body-section-social">
                <SocialHeader />
                <section className="body-section">
                    <div className="body-content container-fluid">
                    <div className="row1 My_notifications">
                            <div className="col_res1 align-items-stretch box_1 w_171">
                                <div className="sticky-top">
                                    <SocialFeedNavigations />
                                    <ProfileInfoAnalytics />
                                </div>
                            </div>
                            <div className="mob-remove-padding box_2 w_461">

                                <div className="box-home box_shadow_1">
                                    <h3 className="fw-bold fs-4 mb_20 dis-block d-flex justify-content-between align-items-center px-3">Participation Requests  <a className="float-end hide">Manage</a></h3>
                                    {
                                        groupParticipationRequests.length != 0 ? (
                                            groupParticipationRequests.map((elem, index) => (
                                                <div key={index} className="border-bottom mb_10">
                                                    <div className="d-flex justify-content-between align-items-center px-3" >
                                                        <div>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <img src={elem?.requestedByUserProfilePicture} alt="Requested User" className="img-fluid img-profile" />
                                                                    <div className="ps-3">
                                                                        {/* <p className="mb-2 fw-bold">{elem?.requestedByUserName} <span className="text-black-50 ml-10">{dateDiff(elem?.createdDate)} </span></p>
                                                                        <p className="mb-2">{elem?.requestedByUserProfileTitle}</p>
                                                                        <p className="mb-2 whitetext"><i className="fa-solid fa-link fa-sm pe-1"></i> Aravind Sirivelli, John and 810 others</p> */}

                                                                        <p className="mb-2 fw-bold">{elem?.requestedByUserFirstName} {elem?.requestedByUserLastName}<span className="text-black-50">( {elem?.requestedByUserProfileTitle} ) </span> requested to participate in
                                                                            <Link to={`/cxo/cxo/wall/group/${elem?.groupId}`} className="groupname ml-10" state={{ groupId: elem?.groupId }}>{elem?.groupName}</Link>
                                                                        </p>
                                                                        <p className="mb-2">{dateDiff(elem?.createdDate)}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="float-end"><a className="text-muted ml_100 mr_30" value={elem?.participationRequestId} onClick={() => {
                                                                    handleInvitation(elem?.groupId, elem?.participationRequestId, false, elem?.requestedByUserId, elem?.requestedByUserEntityId, elem?.requestedByUserRoleId, elem?.requestedByUserFirstName +" "+elem?.requestedByUserLastName)
                                                                }}>Ignore</a></div>
                                                                <div className="float-end"><a className="text-link" value={elem?.participationRequestId} onClick={() => {
                                                                    handleInvitation(elem?.groupId, elem?.participationRequestId, true, elem?.requestedByUserId, elem?.requestedByUserEntityId, elem?.requestedByUserRoleId, elem?.requestedByUserFirstName +" "+elem?.requestedByUserLastName)
                                                                }}>Accept</a></div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            ))
                                        ) : <div className="d-flex justify-content-between align-items-center px-3">
                                            <div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <p className="mt_10"> No new Requests </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {groupParticipationRequests.length > 2 ? <div className="text-center mt_30"><div className="clearfix"><a href="#" className="text-link">View All Invitations</a></div></div> : null}
                                </div>
                            </div>
                            <Connections />
                            <div className="col_res1 align-items-stretch box_4 w_171">
                                <div className="sticky-top">
                                    <CAASWorkspaceNavigations />
                                    <CompanyAnnouncements />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="footer-sections"></section>
            </div>

            <Toaster />
        </>
    )
}

export default GroupNotifications