import { React, useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom';
import Header from '../../../commons/components/Header';
import CaaSSideBar from '../../../commons/components/SideBars/CaaSSideBar';
import { API } from '../../../config/APIConfig';
import axios from 'axios'
import formatDate from '../../../utils/dateformatter';
import logger from '../../../utils/logger';
import $ from "jquery";
import swal from 'sweetalert';
import Pagination from './Pagination';

export default function EducationManagement() {
    const [educationList, setEducationList] = useState([]);
    const [curEducationList, setCurEducationList] = useState([]);
    const [educationExists, setEducationExists] = useState(false);
    const [curEducation, setCurEducation] = useState([]);
    const [loading, setLoading] = useState(false);
    const EducationListAPI = `${API.URL}api/v1/meta/education/list/all`;
    const EducationCreateAPI = `${API.URL}api/v1/meta/education/create`;
    const EducationUpdateAPI = `${API.URL}api/v1/meta/education/update`;
    const EducationNameValidationAPI = `${API.URL}api/v1/meta/validate/educationname`;

    // User is currently on this page
    const [currentPage, setCurrentPage] = useState(1);
    // No of Records to be displayed on each page   
    const [recordsPerPage] = useState(10);
    //pagination
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;


    const getEducationList = async () => {
        const response = await fetch(EducationListAPI);
        if (!response.ok) {
            throw new Error('Data coud not be fetched!')
        } else {
            return response.json()
        }
    }
    const bindEducations = () => {
        setLoading(true);
        getEducationList()
            .then((data) => {
                if (data.response) {
                    var educationDetails = data?.response?.educationList;
                    if (educationDetails?.length > 0) {
                        setEducationList([...educationDetails]);
                        setCurEducationList([...educationDetails])
                        setLoading(false);
                    }
                }
            })
            .catch((e) => {
                console.log(e.message)
            });
    }
    useEffect(() => {
        bindEducations();
        highlightNavMenu();
    }, []);
    const highlightNavMenu = () => {
        $("li.sidebarmenu").removeClass("active");
        $("#caas_education_li").addClass("active");
    }

    //pagination
    const currentRecords = curEducationList.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(curEducationList.length / recordsPerPage);
    //
    const resetEducationStatus = (id, name, curStatus) => {
        var status = curStatus == 0 ? 1 : 0;
        updateEducationDetails(id, name, status);
    }
    const editEducation = () => {
        const id = curEducation[0]?.educationId
        const status = curEducation[0]?.status;
        const name = document.getElementById("editeducationName").value;
        if (!educationExists)
            updateEducationDetails(id, name, status);
    }

    const updateEducationDetails = (id, name, status) => {
        let updobj =
        {
            educationId: id,
            educationName: name,
            status: status,
        }
        var validation = nameValidation(name)
        if (validation) {
            axios.put(EducationUpdateAPI, updobj)
                .then(response => {
                    if (response.data.status === 200) {
                        bindEducations();
                        $("#EditEducation .close").trigger("click");
                        swal("Updated Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                        document.getElementById("editeducationform").reset();
                    }
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }
    }

    const addEducation = () => {
        var name = document.getElementById("educationName").value;
        var validation = nameValidation(name);
        if (validation === false)
            return
        if (!educationExists) {
            let obj =
            {
                educationName: name,
            }
            axios.post(EducationCreateAPI, obj).then(response => {
                if (response.data.status === 200) {
                    bindEducations();
                    $("#AddEducation .close").trigger("click");
                    swal("Education Added Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                    document.getElementById("addEducationForm").reset();
                    removeErrorMsgs();
                }
            })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }
    }

    const removeErrorMsgs = () => {
        $(".educationnameerror").hide();
        $(".educationexitserror").hide();
        // if ($("#educationName").hasClass("error-shadow")) {
        //     $("#educationName").removeClass("error-shadow");
        // }
    }


    const existsCheck = (e) => {
        debugger
        var eleId = e.target.id;
        var name = document.getElementById(eleId).value;
        if (name == null || name == undefined)
            return
        if (eleId === "educationName") {
            var validation = nameValidation(name);
        }
        if (eleId === "editeducationName") {
            var validation = nameValidation(name);
            var existingName = curEducation[0]?.educationName;
            if (existingName.trim() === name.trim()) {
                return true
            }

        }
        if (validation) {
            axios.get(`${EducationNameValidationAPI}?educationName=${name}`)
                .then(response => {
                    if (response.data === true) {
                        // $("#educationName").addClass("error-shadow");
                        // $("#educationnameerror").hide();
                        // $("#existingEducation").show();
                        $(".educationnameerror").hide();
                        $(".educationexitserror").show();
                        setEducationExists(true);
                    }
                    else {
                        // if ($("#educationName").hasClass("error-shadow")) {
                        //     $("#educationName").removeClass("error-shadow");
                        // }
                        // $("#existingEducation").hide();
                        $(".educationexitserror").hide();
                        setEducationExists(false)
                    }
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }
    }
    const nameValidation = (name) => {
        if (name) {
            $(".educationnameerror").hide()

            return true;
        }

        else {
            $(".educationnameerror").show()
            $(".educationexitserror").hide();
            return false;
        }
    }

    const bindthisEducation = (id) => {
        removeErrorMsgs();
        const data = educationList?.filter((ele) => ele.educationId == id);
        setCurEducation([...data]);
        document.getElementById("editeducationName").value = data[0]?.educationName;
    }
    const searchEducation = () => {
        const keyword = document.getElementById("searcheducation").value;
        const searchededucation = educationList.filter((ele) => ele.educationName.toLowerCase().includes(keyword.toLowerCase()));
        setCurrentPage(1);
        setCurEducationList([...searchededucation]);
    }

    return (
        <>
            <div className="container-scroller">
                <Header entity="CaaS" />
                <div className="container-fluid page-body-wrapper">
                    <CaaSSideBar />
                    {loading ? (
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    ) : (
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-12 grid-margin stretch-card">
                                        <div className="col-lg-12 d-grid">
                                            <div className="grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body pb-0">
                                                        <h4 className="card-title">Education</h4>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="mt-0 text-left box-shadow cardPad card-body border border-radius">
                                                        <div className="d-flex justify-content-between">
                                                            <a className="btn addindustrybtn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#AddEducation" title="Add" onClick={removeErrorMsgs}>
                                                                <i className="mdi mdi-note-plus-outline"></i> Add Education
                                                            </a>


                                                            <div className="float-end d-flex align-items-center filter-option mb-0">
                                                                <input className="form-control border-secondary border-right-0 rounded-0 search-input" type="search"
                                                                    placeholder="Search Education"
                                                                    id="searcheducation"
                                                                    onChange={searchEducation}
                                                                />
                                                                <div className="col-auto">
                                                                    <button className="btn btn-outline-secondary border-left-0 rounded-0 rounded-right search-btn" type="button"><i className="fa fa-search"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12 col-sm-12 mt-2">
                                                                <div className="home-tab">
                                                                    <div className="tab-content1">
                                                                        <div className="" id="NewCompany" >
                                                                            <div className="template-demo table-responsive">
                                                                                <Records curEducationList={currentRecords} bindthisEducation={bindthisEducation} resetEducationStatus={resetEducationStatus} />
                                                                                <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="modal fade show borderad" id="EditEducation" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">Edit Education</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="editeducationform">
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Education Name <span className="error">*</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="editeducationName" onChange={existsCheck} />
                                        <label id="editeducationnameerror" className="educationnameerror error hide">Education name is required</label>
                                        <label id="editexistingEducation" className="educationexitserror error hide">Education already exists</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">Cancel</a>
                            <a type="button" className="btn btn-warning btn-md next-btn" onClick={editEducation}>Update</a>

                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade show borderad" id="AddEducation" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">Add Education</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="addEducationForm">
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Education Name <span className="error">*</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="educationName" onChange={existsCheck} />
                                        <label id="educationnameerror" className="educationnameerror error hide">Education name is required</label>
                                        <label id="existingEducation" className="educationexitserror error hide">Education already exists</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">Cancel</a>
                            <a type="button" className="btn btn-warning btn-md next-btn" onClick={addEducation}>Add</a>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const Records = ({ curEducationList, bindthisEducation, resetEducationStatus }) => {

    return (
        <table className="table table-bordered mb-3">
            <thead>
                <tr>

                    <th className="text-left-intend">Education Name</th>
                    <th className="text-center">Status</th>
                    <th className="text-center" colSpan="2">Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    curEducationList.length != 0 ? (
                        curEducationList.map((ele, index) => (
                            <tr key={ele?.educationId}>

                                <td className="text-left-intend">{ele?.educationName}</td>
                                <td className="text-center">
                                    <div className={ele?.status === 1 ? "badge  badge-success" : "badge badge-warning"}>{ele?.status === 1 ? "Active" : "InActive"}</div>
                                </td>
                                <td className="text-center">

                                    <a className="pb-3" >
                                        <i className="fa-solid fa-pencil" data-bs-toggle="modal" data-bs-target="#EditEducation" title="Edit" onClick={() => { bindthisEducation(ele?.educationId) }}></i>
                                    </a></td>
                                <td className='d-grid'>
                                    <div className="d-flex mx-auto">
                                        <label htmlFor="site_state" className="form-check-label">In Active</label>
                                        <div className="form-switch form-check ms-2 margin-0">
                                            <input type="checkbox" className="form-check-input" id="site_state" onChange={() => {
                                                resetEducationStatus(ele?.educationId, ele?.educationName, ele?.status);
                                            }} checked={ele?.status == 1 ? 'checked' : ''} />
                                        </div>
                                        <label htmlFor="site_state" className="form-check-label">Active</label>
                                    </div>


                                </td>

                            </tr>
                        ))
                    ) : (
                        <tr>
                        </tr>
                    )
                }
            </tbody>

        </table>
    )
}

