import { S3Config } from "../config/AWSConfig";
import axios from "axios";
const S3FileUpload = async (formData) => {
    const contentType = formData?.type;
    const key = formData?.fileName;
    const presignedAPIUrl = S3Config.S3PresignedURLAPI + "?contentType=" + contentType + "&key=" + key;
    const response = await axios.get(presignedAPIUrl);
    const fileUpload = await fetch(response.data.uploadURL, {
        method: "PUT",
        headers: getS3UploadHeaders(contentType),
        body: formData.file,
        enctype: "multipart/form-data"
    });
    return fileUpload;
}
const getS3UploadHeaders = (contentType) => {
    const allowOrigins = "*";
    const allowMethods = "PUT";
    const allowHeaders = "*";
    return {
        "Access-Control-Allow-Origin": allowOrigins,
        "Access-Control-Allow-Methods": allowMethods,
        "Access-Control-Allow-Headers": allowHeaders,
        "Content-Type": contentType
    }
}

export default S3FileUpload;