import React, { useState, useEffect } from 'react';
import profilepic from "../../../assets/images/noav.jpg";
import $ from "jquery";
import { Link } from 'react-router-dom';
import { isRegistered, Login, Register } from '../../../libs/firebase/firebase';
import { auth, db, storage } from "../../../config/firebaseConfig";
import { collection, query, where, onSnapshot, orderBy, getDoc, doc, updateDoc, setDoc, addDoc, Timestamp } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import axios from 'axios';
import { API } from '../../../config/APIConfig';
const CAASMEMBERCONNECTIONS = `${API.URL}api/v1/social/caasmember/connections`;

const Conversations = () => {
    const curUserFirstName = sessionStorage.getItem("firstname");
    const curUserLastName = sessionStorage.getItem("lastname");
    const curUserEmail = sessionStorage.getItem("userEmail");
    const curUserRoleId = sessionStorage.getItem("roleId");
    const profilePicture = sessionStorage.getItem("profilePicture");
    const curUserEntityId = 3;
    const curUserId = sessionStorage.getItem("userId");
    const curUserKey = `conversation-user-${curUserId}-${curUserEntityId}-${curUserRoleId}`;
    const curUserPassword = `fireChat-Pass-${curUserId}-${curUserEntityId}-${curUserRoleId}`;
    const [chatUsers, setChatUsers] = useState([]);
    const [curChatUser, setCurCharUser] = useState({});
    const [text, setText] = useState("");
    const [img, setImg] = useState("");
    const [msgs, setMsgs] = useState([]);
    const [loggedInChatUserUID, setLoggedInChatUserUID] = useState("");
    useEffect(() => {
        $(".chat_container").slideToggle("fast");
        $(".c_h").on("click", function (e) {
            if ($(".chat_container").is(":visible")) {
                $(".c_h .right_c .mini i").removeClass("fa-minus")
                $(".c_h .right_c .mini i").addClass("fa-plus")
            } else {
                $(".chat_container").hide();
                $(".c_h .right_c .mini i").removeClass("fa-plus")
                $(".c_h .right_c .mini i").addClass("fa-minus")
            }
            $(".chat_container").slideToggle("slow");
            return false
        });
        // $('.fa-minus').on("click", function () {
        //     $(this).closest('.chatbox').toggleClass('chatbox-min');
        // });
        // $('.fa-close').on("click", function () {
        //     $(this).closest('.chatbox').hide();
        // });

        enterChat();
    }, [])

    const enterChat = async () => {
        const userExists = await isRegistered(curUserFirstName, curUserEmail, curUserPassword, curUserKey, profilePicture);
        if (userExists) {
            loginChat();
        } else {
            await Register(curUserFirstName, curUserEmail, curUserPassword, curUserKey, profilePicture).then(() => {
                loginChat();
            }).catch(err => console.log(err));
        }
    }
    const loginChat = async () => {
        await Login(curUserEmail, curUserPassword, curUserKey).then(() => {
            setLoggedInChatUserUID(auth.currentUser.uid)
            // console.log(auth.currentUser.uid);
            console.log("chat user is logged");
            getMemberList(auth.currentUser.uid);
        }).catch(err => console.log(err));
    }

    const getMemberList = (Uid) => {
        const uid = loggedInChatUserUID ? loggedInChatUserUID : Uid;
        const usersRef = collection(db, "tbl_chat_user_master");
        const q = query(usersRef, where("uid", "!=", uid));
        const unsub = onSnapshot(q, (querySnapshot) => {
            let users = [];
            querySnapshot.forEach((doc) => {
                users.push(doc.data());
            });
            // setChatUsers(users);
            bindConnectedhatUsers(users);
        });
    }
    const bindConnectedhatUsers = (users) => {
        axios.get(`${CAASMEMBERCONNECTIONS}?userId=${curUserId}&userEntityId=${curUserEntityId}&userRoleId=${curUserRoleId}`)
            .then(resp => {
                const data = resp.data.response;
                const mapConversationUserNames = data.map(o => ({ ...o, conversation_user_key: `conversation-user-${o.userId}-${o.entityId}-${o.roleId}` }))
                const connectedChatUser = users.filter(el => {
                    return mapConversationUserNames.some(f => {
                        return f.conversation_user_key === el.userKey;
                    });
                });
                setChatUsers([...connectedChatUser]);
            }).catch(error => {
                console.log(error);
            })

    }

    const setThisChat = async (e) => {
        $(".modal-backdrop").hide();
        const selectedUserUId = e.currentTarget.id;
        const newChat = chatUsers?.find((ele) => ele.uid == selectedUserUId);
        setCurCharUser({ ...newChat });
        const id = loggedInChatUserUID > selectedUserUId ? `${loggedInChatUserUID + selectedUserUId}` : `${selectedUserUId + loggedInChatUserUID}`;
        const msgsRef = collection(db, "tbl_chat_messages", id, "chat");
        const q = query(msgsRef, orderBy("createdAt", "asc"));
        onSnapshot(q, (querySnapshot) => {
            let msgs = [];
            querySnapshot.forEach((doc) => {
                msgs.push(doc.data());
            });
            setMsgs(msgs);
            console.log("msgs", msgs)
        });
        const docSnap = await getDoc(doc(db, "tbl_chat_last_messages", id));
        if (docSnap.data() && docSnap.data().from !== loggedInChatUserUID) {
            await updateDoc(doc(db, "tbl_chat_last_messages", id), { unread: false });
        }
    }

    const sendMsg = async (e) => {
        e.preventDefault();
        const selectedUserUId = curChatUser.uid;
        const id = loggedInChatUserUID > selectedUserUId ? `${loggedInChatUserUID + selectedUserUId}` : `${selectedUserUId + loggedInChatUserUID}`;
        let url;
        if (img) {
            const imgRef = ref(
                storage,
                `images/${new Date().getTime()} - ${img.name}`
            );
            const snap = await uploadBytes(imgRef, img);
            const dlUrl = await getDownloadURL(ref(storage, snap.ref.fullPath));
            url = dlUrl;
        }

        await addDoc(collection(db, "tbl_chat_messages", id, "chat"), {
            text,
            from: loggedInChatUserUID,
            to: selectedUserUId,
            createdAt: Timestamp.fromDate(new Date()),
            media: url || "",
        });
        await setDoc(doc(db, "tbl_chat_last_messages", id), {
            text,
            from: loggedInChatUserUID,
            to: selectedUserUId,
            createdAt: Timestamp.fromDate(new Date()),
            media: url || "",
            unread: true,
        });
        setText("");
        setImg("");
    }

    return (
        <>
            <div className="l_c_h">
                <div className="c_h">
                    <div className="left_c">
                        <div className="left right_c left_icons">
                        <a href="#" className="mini"><i className="fa-solid fa-plus mt-1"></i></a>
                        </div>
                        <div className="left center_icons">
                            Conversations
                        </div>
                    </div>
                    <div className="clear"></div>
                </div>
                <div className="chat_container">
                    {/* <p className="no_provider">
                                                Online Members
                                            </p> */}
                    <div className="chat_entry mt-2">
                        <div className="ConversationsBody overflow-y">
                            {/* <div className="row no-gutters mb-3">
                                <div className="col">
                                    <input className="form-control border-secondary border-right-0 rounded-0 search-input" type="search" placeholder="Search Messaging" id="example-search-input5" />
                                </div>
                                <div className="col-auto">
                                    <button className="btn btn-outline-secondary border-left-0 rounded-0 rounded-right search-btn" type="button">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div> */}

                            {chatUsers.length != 0 ? chatUsers.map((ele, index) => (
                                <div className="d-flex justify-content-start align-items-center" key={index} >
                                    <img
                                        src={ele?.profilePicture ? ele?.profilePicture : profilepic}
                                        alt={ele?.name}
                                        className="img-fluid img-jobs"
                                    />
                                    <div className="ps-3">
                                        <p className="mb-0 fw-bold mt-0"> <a className="fw-bold" data-bs-toggle="modal" data-bs-target="#chatModalPopup" id={ele?.uid} onClick={setThisChat}> {ele?.name} <i className="fa-sharp fa-solid fa-circle-dot fa-xs text-green px-2"></i></a></p>
                                        {/* <p className="mb-0 text-black-50 fs-6 mt-0"> {data ? data?.text : ""}</p> */}
                                    </div>
                                </div>
                            )) : null}

                        </div>
                    </div>
                    <p className="footer_c">
                        Powered by <Link to="/">CAAS</Link>
                    </p>
                </div>
            </div>

            <div className="modal fade show" id="chatModalPopup">
                <div className="modal-dialog modal-md chat-popup" role="document">
                    <div className="modal-content">
                        <div className="chatbox-holder">
                            <div className="chatbox">
                                <div className="chatbox-top">
                                    <div className="chatbox-avatar">
                                        <a href="#"><img src={curChatUser?.profilePicture ? curChatUser?.profilePicture : profilepic} /></a>
                                    </div>
                                    <div className="chat-partner-name">
                                        <span className="status online"></span>
                                        <a href="#">{curChatUser?.name}</a>
                                    </div>
                                    <div className="chatbox-icons">
                                        <a href="#" ><i className="fa fa-minus hide"></i></a>
                                        <a href="#" data-bs-dismiss="modal"
                                            aria-label="Close"><i className="fa fa-close"></i></a>
                                    </div>
                                </div>

                                <div className="chat-messages">

                                    {msgs.length
                                        ? msgs.map((msg, i) => (
                                            <><div className="message-box-holder">
                                                {
                                                    msg?.from === loggedInChatUserUID ? (
                                                        <div className="message-box">
                                                            {msg?.text}
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div className="message-sender">
                                                                {curChatUser?.name}
                                                            </div>
                                                            <div className="message-box message-partner">
                                                                {msg?.text}
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                            </>
                                        ))
                                        : null}

                                </div>

                                <div className="chat-input-holder">
                                <textarea className="chat-input1 form-control p-2 border-0 shadow-0" placeholder="write a message..." value={text}
                                        onChange={(e) => setText(e.target.value)} ></textarea>
                                    <input type="submit" value="Send" className="message-send" onClick={sendMsg} />
                                </div>
                                {/* <div className="attachment-panel">
                                    <a href="#" className="fa fa-thumbs-up"></a>
                                    <a href="#" className="fa fa-camera"></a>
                                    <a href="#" className="fa fa-video-camera"></a>
                                    <a href="#" className="fa fa-image"></a>
                                    <a href="#" className="fa fa-paperclip"></a>
                                    <a href="#" className="fa fa-link"></a>
                                    <a href="#" className="fa fa-trash-o"></a>
                                    <a href="#" className="fa fa-search"></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Conversations