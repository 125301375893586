import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';
import userImg from "../../assets/images/person-or-2.png";
import CAAS_LOGO from "../../assets/images/CAAS_Logo.png";
import i18next from '../../libs/i18n';
import { useLocation } from "react-router-dom";

const Header = ({ entity = "CaaS" }) => {
    const { i18n, t } = useTranslation(["common"]);
    const firstname = sessionStorage.getItem("firstname");
    const lastname = sessionStorage.getItem("lastname");
    const userName = sessionStorage.getItem("userName");
    const roleId = sessionStorage.getItem("roleId");
    const userId = sessionStorage.getItem("userId");
    // const [logoNavPath, setLogoNavPath] = useState("");
    const location = useLocation();

    const urlheader = location.pathname.split('/')[1].toUpperCase();

    var logoNavPath = "";
    if (roleId == 1) {
        // logoNavPath= "/caas/global/dashboard");
        logoNavPath = "/caas/global/dashboard";
    }
    if (roleId == 3) {
        logoNavPath= "/enterprise/manager/dashboard";
    }
    if (roleId == 4) {
        logoNavPath= "/cxo/cxo/wall/personal";
    } if (roleId == 5) {
        logoNavPath= "/vendor/admin/dashboard";
    }
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("i18nextLng")?.length > 2) {
            i18next.changeLanguage("en");
        }
        usercheck();
    }, []);
    const handleLanguageChange = (e) => {
        i18n.changeLanguage(e.target.value);
    };

    const usercheck = () => {
        if (entity === "CaaS" && roleId != null) return;
        if (roleId == null) {
            sessionStorage.clear();
            navigate("/")
        }
    }

    const handlelogout = () => {
        sessionStorage.clear();
        navigate("/")
    }
    return (
        <>
            <nav className="navbar default-layout navbar-expand-lg fixed-top navbar-light boxshadow">
                <div className="container-fluid">
                    {/* {urlheader == "CXO" ? <Link to="/cxo/cxo/wall/personal" className="navbar-brand" > <img src={CAAS_LOGO} className="img-responsive caas_logo"></img></Link> : <Link to="" className="navbar-brand" > <img src={CAAS_LOGO} className="img-responsive caas_logo"></img></Link>} */}
                    {/* {roleId == 4 ?
                        <Link to="/cxo/cxo/wall/personal" className="navbar-brand" >
                            <img src={CAAS_LOGO} className="img-responsive caas_logo"></img>
                        </Link>
                        : (roleId == 3 ?
                            (<Link to="/enterprise/manager/dashboard" className="navbar-brand" >
                                <img src={CAAS_LOGO} className="img-responsive caas_logo"></img>
                            </Link>)
                            : (<Link to="/caas/global/dashboard" className="navbar-brand" >
                                <img src={CAAS_LOGO} className="img-responsive caas_logo"></img>
                            </Link>))} */}

                    {logoNavPath != "" ? (<Link to={logoNavPath} className="navbar-brand" >
                        <img src={CAAS_LOGO} className="img-responsive caas_logo"></img>
                    </Link>) : <img src={CAAS_LOGO} className="img-responsive caas_logo"></img>}

                    <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-collapse collapse" id="navbarCollapse">
                        <ul className="navbar-nav ms-auto MobResView">
                            {/* <li className="nav-item mr_20">
                                <select
                                    className="nav-link border-0 col-md-3 select-language"
                                    value={localStorage.getItem("i18nextLng")}
                                    onChange={handleLanguageChange}
                                >
                                    <option value="en">English</option>
                                    <option value="ar">Arabic</option>
                                    <option value="fr">Français</option>
                                    <option value="es">Español</option>
                                </select>
                            </li> */}
                            <li className="nav-item dropdown d-block user-dropdown mr_20 mt_10">
                                <li className="nav-item dropdown d-block user-dropdown" id="UserDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">

                                    {
                                        roleId == 3 || roleId == 5  ? <a className="tenant text-orange"><img src={userImg} alt="person user" />
                                            {userName}</a>
                                            : roleId == 4 || roleId == 1 ? <a className="tenant text-orange"><img src={userImg} alt="person user" />
                                                {firstname} {lastname}</a>
                                                : <a className="tenant text-orange"><img src={userImg} alt="person user" />
                                                    {t('noUser')}</a>
                                    }
                                </li>
                                <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
                                    {
                                        roleId == 4 ? <Link to="/cxo/myprofile" className="dropdown-item" state={{ quotedBy: userId, role: roleId }}>
                                            <i className="dropdown-item-icon mdi mdi-account-outline text-primary me-2"></i> {t('myProfile')}
                                        </Link>
                                            : ""
                                    }
                                    {/* <a className="dropdown-item" href="edituser.html"><i className="dropdown-item-icon mdi mdi-account-outline text-primary me-2"></i> {t("myprofile")} */}
                                    {/* <span className="badge badge-pill badge-danger">1</span>*/}
                                    {/* </a> */}
                                    <Link to="/auth/changepassword" className="dropdown-item"><i className="dropdown-item-icon fa-solid fa-unlock-keyhole text-primary me-2"></i> {t('changePassword')}
                                    </Link>
                                    {/* <a className="dropdown-item" href="../changepassword.html"><i className="dropdown-item-icon fa-solid fa-unlock-keyhole text-primary me-2"></i> Change
                                        Password</a> */}
                                    {/* <Link to="/" className="dropdown-item">
                                        <i className="dropdown-item-icon mdi mdi-power text-primary me-2"></i><a onClick={handlelogout}>Log Out</a>
                                    </Link> */}
                                    <a className="dropdown-item" onClick={handlelogout}><i className="dropdown-item-icon mdi mdi-power text-primary me-2"></i>{t('logOut')}</a>

                                    {/* <i className="dropdown-item-icon mdi mdi-power text-primary me-2"></i><a onClick={handlelogout}>Log Out</a> */}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}
export default Header