import { React, useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom';
import Header from '../../../commons/components/Header';
import CaaSSideBar from '../../../commons/components/SideBars/CaaSSideBar';
import { API } from '../../../config/APIConfig';
import { useTranslation } from "react-i18next";
import axios from 'axios'
import $ from "jquery";
import swal from 'sweetalert';
const skillMasterVersionListAPI = `${API.URL}api/v1/meta/allskillmasterversionlist`;
const SkillUpdateAPI = `${API.URL}api/v1/meta/skill/update`;
const SkillAddAPI = `${API.URL}api/v1/meta/skill/create`;
const SkillDeleteAPI = `${API.URL}api/v1/meta/skill/delete`;
const SkillVersionDeleteAPI = `${API.URL}api/v1/meta/skillversion/delete`;
const SkillVersionUpdateAPI = `${API.URL}api/v1/meta/skillversion/update`;
const SkillVersionAddAPI = `${API.URL}api/v1/meta/skillversion/create`;
const SkillNameValidationAPI = `${API.URL}api/v1/meta/validate/skillname`;
const SkillVersionNameValidationAPI = `${API.URL}api/v1/meta/validate/skillversionname`;



export default function SkillManage() {

    const [skillDetailsList, setSkillDetailslist] = useState([]);
    const [skilldetailsMeta, setSkillDetailsMeta] = useState([]);
    const [curSkillDetailsMeta, setCurSkillDetailsMeta] = useState([]);
    const [skillversionMeta, setSkillVersionssMeta] = useState([]);
    const [curSkill, setCurSkill] = useState({});
    const [curSkillVersion, setCurSkillVersion] = useState({});
    const [addVersionSkillId, setAddVersionSkillId] = useState(0);
    const [curskillVersionList, setCurSkillversionList] = useState([]);
    const [skillRef, setSkillRef] = useState({});
    const [skillVersionRef, setSkillVersionRef] = useState({});
    const [filteredSkillList, setFilteredSkillList] = useState([]);
    const { i18n, t } = useTranslation(["registration"]);
    const [msg, setmsg] = useState("");
    const [skillExists, setSkillExists] = useState(false);
    const [skillVersionExists, setSkillVersionExists] = useState(false);
    const [loading, setLoading] = useState(false);
    const getSkillDetailslList = async () => {
        const response = await fetch(skillMasterVersionListAPI);
        if (!response.ok) {
            throw new Error('Data coud not be fetched!')
        } else {
            return response.json()
        }
    }

    useEffect(() => {
        // $("html").removeClass();
        // $("html").addClass('Skillmaster');
        // setLoading(true);
        // setTimeout(() => {
        //     bindSkillsData();
        //     setLoading(false);
        // }, 2000);
        bindSkillsData();
        highlightNavMenu();
    }, []);

    const highlightNavMenu = () => {
        $("li.sidebarmenu").removeClass("active");
        $("#caas_skills_li").addClass("active");
    }

    const bindSkillsData = () => {
        setLoading(true);
        getSkillDetailslList()
            .then((res) => {
                if (res.response) {
                    var skillDetails = res.response.skillMasterList;
                    var skillVersionDetails = res.response.skillVersionList;
                    setSkillDetailsMeta(skillDetails);
                    setCurSkillDetailsMeta(skillDetails);
                    setSkillVersionssMeta(skillVersionDetails);
                    var skilldata = skillDetails.reduce((obj, skill) => Object.assign(obj, { [skill.skillId]: skill.skillName }), {});
                    setSkillRef(skilldata);
                    var skillVersiondata = skillVersionDetails.reduce((obj, version) => Object.assign(obj, { [version.skillVersionId]: version.skillVersionName }), {});
                    setSkillVersionRef(skillVersiondata);
                    setLoading(false);
                }
            })
            .catch((e) => {
            })
    }

    const setThisSkill = (skillId) => {
        // let skillId = e.target.getAttribute("data-for");
        $(".skillnameerror").hide();
        $(".skillexistserror").hide();
        if (skillId != 0) {
            const skill = skilldetailsMeta.filter((ele) => ele?.skillId == skillId);
            $("#editSkillName").val(skill[0]?.skillName);
            if (skill[0].isApproved == 1) {
                $("#approved").prop("checked", true);
                $("#unapproved").prop("checked", false);
                // $('input:radio[name="approval"]').filter('[value="1"]').attr('checked', true);
                $("#edit-approval_state").prop("checked", true);
            }
            else {
                $("#unapproved").prop("checked", true);
                $("#approved").prop("checked", false);
                //$('input:radio[name="approval"]').filter('[value="0"]').attr('checked', true);
                $("#edit-approval_state").prop("checked", false);
            }
            setCurSkill([...skill]);
        }
    }
    const updateSkill = () => {
        const id = curSkill[0]?.skillId
        //const status = curSkill[0]?.skillId;
        const name = document.getElementById("editSkillName").value;
        // const approval = $('input[name="approval"]:checked').val();
        const approval = document.getElementById("edit-approval_state").checked ? '1' : '0';
        var validation = nameValidation(name);
        if (validation === false) {
            return false;
        }
        if (!skillExists) {
            updateSkillDetails(id, name, approval, 1);
        }
    }
    const updateSkillDetails = (id, name, approval, skillStatus) => {
        // const skillName = $("#editSkillName").val();
        // const approval = $('input[name="approval"]:checked').val();  
        // const skillId = curSkill[0].skillId;
        //var skillStatus = $("#status-active-1").val()?$("#status-active-1").val():$("#status-inactive-1").val();
        let updobj =
        {
            skillId: id,
            skillName: name,
            isApproved: approval,
            skillStatus: skillStatus
        }
        axios.put(SkillUpdateAPI, updobj)
            .then(response => {
                if (response.data.status === 200) {
                    $("#EditSkill .close").trigger("click");
                    // swal("Skill Updated Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });

                    swal("Skill Updated Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false }
                    ).then(okay => {
                        if (okay) {
                            window.location.reload(false);
                        }
                    }
                    );
                    // bindSkillsData();
                    // window.location.reload(false);
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }
    const addSkill = () => {
        const skillName = $("#addSkillName").val();
        // const approval = $('input[name="addapproval"]:checked').val();
        const approval = document.getElementById("add-approval_state").checked ? '1' : '0';
        var validation = nameValidation(skillName);
        if (validation === false) {
            return false;
        }
        if (!skillExists) {
            let addobj =
            {
                skillName: skillName,
                isApproved: approval,
            }
            axios.post(SkillAddAPI, addobj)
                .then(response => {
                    if (response.data.status === 200) {
                        bindSkillsData();
                        if (response.data.response === 1) {
                            setmsg("Skill Exists")
                            $('#skillexists').show();
                        }
                        if (response.data.response === 2) {
                            setmsg("Skill Added")
                            document.getElementById("addSkillForm").reset();
                            $("#AddSkill .close").trigger("click");
                            swal("Skill Added Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });

                        }
                    }
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }
    }
    const deleteSkill = () => {
        const skillId = curSkill[0].skillId;
        //validation
        axios.delete(`${SkillDeleteAPI}/?skillId=${skillId}`)
            .then(response => {
                if (response.data.status === 200) {
                    bindSkillsData();
                    swal("Skill Deleted Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }
    const setdeleteSkill = (e) => {
        const skillId = e.target.getAttribute("data-for");
        const skill = skilldetailsMeta.filter((ele) => ele.skillId == skillId);
        setCurSkill([...skill]);
    }
    const deleteSkillVersion = () => {

        const skillVersionlId = curSkillVersion[0].skillVersionId;
        //validation      
        axios.delete(`${SkillVersionDeleteAPI}/?skillVersionId=${skillVersionlId}`)
            .then(response => {
                if (response.data.status === 200) {
                    bindSkillsData();
                    swal("Version Deleted Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }
    const setdeleteSkillVersion = (e) => {
        const skillVersionId = e.target.getAttribute("data-for");

        const skillVersion = skillversionMeta.filter((ele) => ele.skillVersionId == skillVersionId);
        setCurSkillVersion([...skillVersion]);
    }
    const seteditSkillVersion = (e) => {
        $(".skillversionnameerror").hide();
        $(".skillversionexistserror ").hide();
        const skillVersionId = e.target.getAttribute("data-for");

        if (skillVersionId != 0) {
            const skillVersion = skillversionMeta.filter((ele) => ele?.skillVersionId == skillVersionId);
            setCurSkillVersion([...skillVersion]);
            setAddVersionSkillId(skillVersion[0].skillId)
            $("#editSkillVersionName").val(skillVersion[0]?.skillVersionName);

        }
    }
    const setaddSkillId = (skillId) => {
        // const skillId = e.target.getAttribute("data-for");
        setAddVersionSkillId(skillId);
        skillversionvalidreset();

    }
    const updateSkillVersion = () => {
        const id = curSkillVersion[0]?.skillVersionId
        //const status = curSkill[0]?.skillId;
        const name = document.getElementById("editSkillVersionName").value;
        var validation = versionnameValidation(name);
        if (validation === false) {
            return false;
        }
        if (!skillVersionExists) {

            updateSkillVersionDetails(id, name, 1);
        }
    }


    const updateSkillVersionDetails = (id, name, skillVersionStatus) => {
        // const skillVersionName = $("#editSkillVersionName").val();
        //validation
        // const skillVersionId = curSkillVersion[0].skillVersionId;
        let updobj =
        {
            skillVersionId: id,
            skillVersionName: name,
            skillVersionStatus: skillVersionStatus
        }
        axios.put(SkillVersionUpdateAPI, updobj)
            .then(response => {
                if (response.data.status === 200) {
                    $("#EditVersion .close").trigger("click");
                    //swal("Version Updated Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                    swal("Version Updated Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false }
                    ).then(okay => {
                        if (okay) {
                            window.location.reload(false);
                        }
                    }
                    );
                    //bindSkillsData();
                    //window.location.reload(false);
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }
    const addSkillVersion = () => {
        const skillId = addVersionSkillId
        const skillVersionName = $("#addSkillVersionName").val();

        //validation
        var validation = versionnameValidation(skillVersionName);
        if (validation === false) {
            return false;
        }
        if (!skillVersionExists) {
            let addversionobj =
            {
                skillId: skillId,
                skillVersionName: skillVersionName,

            }
            axios.post(SkillVersionAddAPI, addversionobj)
                .then(response => {
                    if (response.data.status === 200) {

                        bindSkillsData();
                        if (response.data.response === 1) {
                            setmsg("Version Exists")
                            $('#skillexists').show()

                        }
                        if (response.data.response === 2) {

                            //setmsg("Version Added")                            
                            document.getElementById("addSkillVersionForm").reset();
                            $("#AddSKillVersion .close").trigger("click");
                            swal("Version Added Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                        }
                    }
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }
    }

    const searchSkill = () => {
        const keyword = document.getElementById("searchskill").value;
        const searchedSkills = skilldetailsMeta.filter((ele) => ele.skillName.toLowerCase().includes(keyword.toLowerCase()));
        setCurSkillDetailsMeta([...searchedSkills]);
    }
    const skillExistsCheck = (e) => {
        var eleId = e.target.id;
        var name = document.getElementById(eleId).value;
        if (eleId === "editSkillName") {
            var existingName = curSkill[0]?.skillName;
            if (existingName === name) {
                $(".skillexistserror").hide();
                $("#EditSkill .close").trigger("click");
                return true;
            }
        }
        axios.get(`${SkillNameValidationAPI}?skillName=${name}`)
            .then(response => {
                if (response.data === true) {
                    $("#addSkillName").addClass("error-shadow");
                    $(".skillnameerror").hide();
                    $(".skillexistserror").show();
                    setSkillExists(true);
                }
                else {
                    if ($("#addSkillName").hasClass("error-shadow")) {
                        $("#addSkillName").removeClass("error-shadow");
                    }
                    $(".skillexistserror").hide();
                    setSkillExists(false)
                }
            })
            .catch(error => {
                //console.error('There was an error!', error);
            });
    }

    const nameValidation = (name) => {
        if (name) {
            return true;
        }

        else {
            $(".skillnameerror").show();
            $(".skillexistserror").hide();
            return false;
        }

    }
    const skillvalidreset = () => {
        if ($("#addSkillName").hasClass("error-shadow")) {
            $("#addSkillName").removeClass("error-shadow");
        }
        $("#addSkillName").val('')
        $(".skillnameerror").hide();
        $(".skillexistserror").hide();
    }

    const skillVersionExistsCheck = (e) => {
        var eleId = e.target.id;
        var name = document.getElementById(eleId).value;
        if (eleId === "editSkillVersionName") {
            var existingName = curSkillVersion[0]?.skillVersionName;
            if (existingName === name) {
                $(".skillversionexistserror").hide();
                //$("#EditVersion .close").trigger("click");
                return true;
            }
        }
        axios.get(`${SkillVersionNameValidationAPI}?skillId=${addVersionSkillId}&skillVersionName=${name}`)
            .then(response => {
                if (response.data === true) {
                    $("#addSkillVersionName").addClass("error-shadow");
                    $(".skillversionnameerror").hide();
                    $(".skillversionexistserror ").show();
                    setSkillVersionExists(true);
                }
                else {
                    if ($("#addSkillVersionName").hasClass("error-shadow")) {
                        $("#addSkillVersionName").removeClass("error-shadow");
                    }
                    $(".skillversionexistserror ").hide();
                    setSkillVersionExists(false)
                }
            })
            .catch(error => {
                //console.error('There was an error!', error);
            });
    }
    const versionnameValidation = (name) => {
        if (name) {
            return true;
        }

        else {
            $(".skillversionnameerror").show();
            $(".skillversionexistserror").hide();
            return false;
        }

    }
    const skillversionvalidreset = () => {
        if ($("#addSkillVersionName").hasClass("error-shadow")) {
            $("#addSkillVersionName").removeClass("error-shadow");
        }
        $("#addSkillVersionName").val('')
        $(".skillversionnameerror").hide();
        $(".skillversionexistserror").hide();
    }

    return (
        <>
            <div className="container-scroller">
                <Header entity="CaaS" />
                <div className="container-fluid  page-body-wrapper">
                    <CaaSSideBar />
                    {loading ? (
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    ) : (
                        <div className="main-panel Skillmaster">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-12 grid-margin stretch-card">
                                        <div className="col-12">


                                            <div className="grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body pb-0">
                                                        <h4 className="card-title">Skills & Versions</h4>
                                                    </div>
                                                </div>

                                                {/* <a className="btn btn-sm btn-primary btn-rounded mr_5 editpopupclient" data-bs-toggle="modal" data-bs-target="#AddLanguage" title="Add">
                                                        <i className="fa fa-plus"></i>
                                                    </a> */}
                                            </div>
                                            <div className='card-body'>
                                                <div className='mt-0 text-left box-shadow cardPad card-body border border-radius'>
                                                    <div className="carskillVersionExistsCheckd-body pb-0"><a className="btn addindustrybtn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#AddSkill" title="Add Skill">
                                                        <i className="mdi mdi-note-plus-outline"></i> Add Skill</a>
                                                        <div className="float-end d-flex align-items-center filter-option mb-0">
                                                            <input className="form-control border-secondary border-right-0 rounded-0 search-input" type="search"
                                                                placeholder="Search Skill"
                                                                id="searchskill"
                                                                onChange={searchSkill}
                                                            />
                                                            <div className="col-auto"><button className="btn btn-outline-secondary border-left-0 rounded-0 rounded-right search-btn" type="button">
                                                                <i className="fa fa-search"></i></button></div></div></div>

                                                    <div className='row'>

                                                        <div className='col-md-12 col-lg-12 '>
                                                            <div className="card pb-4">
                                                                <div className="card-body">
                                                                    <nav aria-label="breadcrumb" className="profile-section pl-5 pr-5">
                                                                        <div className="breadcrumb mb-0 bg-profilesection">
                                                                            <ul className="nav nav-pills nav-pills-success pb-0 mb-0" id="pills-tab" role="tablist">
                                                                                <li className="nav-item list mb-0">
                                                                                    <a className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true"><i className="mdi mdi-account-star menu-icon"></i>Approved
                                                                                        Skills</a>
                                                                                </li>
                                                                                <li className="nav-item">
                                                                                    <a
                                                                                        className="nav-link nav_hover "
                                                                                        id="pills-profile-tab"
                                                                                        data-bs-toggle="pill"
                                                                                        href="#pills-profile"
                                                                                        role="tab"
                                                                                        aria-controls="pills-profile"
                                                                                        aria-selected="false"
                                                                                    ><i className="mdi mdi-wrap menu-icon"></i> Un Approved Skills</a
                                                                                    >
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </nav>
                                                                    <div className="border-1">

                                                                    </div>
                                                                    <div className="tab-content" id="pills-tabContent">
                                                                        <div
                                                                            className="tab-pane fade show active"
                                                                            id="pills-home"
                                                                            role="tabpanel"
                                                                            aria-labelledby="pills-home-tab"
                                                                        >
                                                                            <div className="row">
                                                                                <div className="col-sm-12 mt-2">
                                                                                    <div className="card-body">
                                                                                        <div className="home-tab1">
                                                                                            <div className="tab-content1">

                                                                                                <div className="accordion accordion-flush" id="accordionExample">

                                                                                                    {
                                                                                                        curSkillDetailsMeta.length != 0 ? (
                                                                                                            curSkillDetailsMeta.filter(skill => skill.isApproved == 1)?.map((ele, index) => (
                                                                                                                <>
                                                                                                                    <div key={index} className="accordion-item">
                                                                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse-" + ele.skillId} aria-expanded="false" data_skill_id={ele.skillId} data_skill_name={ele.skillName}>
                                                                                                                            {ele.skillName}
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                    <VersionAccordion skillId={ele?.skillId} skillversionMeta={skillversionMeta} setThisSkill={setThisSkill} updateSkill={updateSkill} setdeleteSkill={setdeleteSkill}
                                                                                                                        deleteSkill={deleteSkill} setdeleteSkillVersion={setdeleteSkillVersion} deleteSkillVersion={deleteSkillVersion}
                                                                                                                        seteditSkillVersion={seteditSkillVersion} updateSkillVersion={updateSkillVersion} addSkillVersion={addSkillVersion} setaddSkillId={setaddSkillId} skillExistsCheck={skillExistsCheck} skillVersionExistsCheck={skillVersionExistsCheck} skillStatus={ele.skillStatus} skillName={ele.skillName} skillApproval={ele.isApproved} updateSkillDetails={updateSkillDetails} updateSkillVersionDetails={updateSkillVersionDetails} />
                                                                                                                </>
                                                                                                            ))
                                                                                                        ) : null
                                                                                                    }

                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="tab-pane fade"
                                                                            id="pills-profile"
                                                                            role="tabpanel"
                                                                            aria-labelledby="pills-profile-tab"
                                                                        >

                                                                            <div className="card-body">

                                                                                <div className="row">
                                                                                    <div className="col-12 col-sm-12 mt-2">
                                                                                        <div className="home-tab1">
                                                                                            <div className="tab-content1">

                                                                                                <div className="accordion accordion-flush" id="accordionExample">
                                                                                                    {/* Search Bar for Skills */}
                                                                                                    {/* Add Skill  - Skill Name and Approve/Deny */}

                                                                                                    {
                                                                                                        curSkillDetailsMeta.length != 0 ? (
                                                                                                            curSkillDetailsMeta.filter(skill => skill.isApproved == 0)?.map((ele, index) => (
                                                                                                                <>
                                                                                                                    <div key={index} className="accordion-item">
                                                                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse-" + ele.skillId} aria-expanded="false" data_skill_id={ele.skillId} data_skill_name={ele.skillName}>
                                                                                                                            {ele.skillName}
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                    <VersionAccordion skillId={ele?.skillId} skillversionMeta={skillversionMeta} setThisSkill={setThisSkill} updateSkill={updateSkill} setdeleteSkill={setdeleteSkill}
                                                                                                                        deleteSkill={deleteSkill} setdeleteSkillVersion={setdeleteSkillVersion} deleteSkillVersion={deleteSkillVersion}
                                                                                                                        seteditSkillVersion={seteditSkillVersion} updateSkillVersion={updateSkillVersion} addSkillVersion={addSkillVersion} setaddSkillId={setaddSkillId} skillExistsCheck={skillExistsCheck} skillVersionExistsCheck={skillVersionExistsCheck} skillStatus={ele.skillStatus} skillName={ele.skillName} skillApproval={ele.isApproved} updateSkillDetails={updateSkillDetails} updateSkillVersionDetails={updateSkillVersionDetails} />
                                                                                                                </>
                                                                                                            ))
                                                                                                        ) : null
                                                                                                    }

                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)}
                </div>

            </div>
            <div className="modal fade show Skillmaster" id="AddSkill">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Add Skill</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="addSkillForm">
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Skill Name <span className="error">*</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="addSkillName" onChange={skillExistsCheck} />

                                        <label id="skillnameerror" className="skillnameerror error hide">Skill name is required</label>
                                        <label id="skillexists" className="skillexistserror error hide">Skill already exists</label>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Approval Status </label>
                                    <div className="col-sm-8 d-flex">
                                        <div className="d-flex">  <label htmlFor="site_state" className="form-check-label">Off</label>
                                            <div className="form-switch form-check mt-1 ms-1">
                                                <input type="checkbox" className="form-check-input" id="add-approval_state" />
                                            </div>
                                            <label htmlFor="site_state" className="form-check-label">On</label>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>


                        <div className="modal-footer">

                            <a type="button" className="btn btn-warning btn-md ignore-btn" data-bs-dismiss="modal">Cancel</a>
                            <a type="button" className="btn btn-warning btn-md next-btn" onClick={addSkill} >Add</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Edit Skill*/}
            <div className="modal fade show Skillmaster" id="EditSkill">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Edit Skill</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Skill Name <span className="error">*</span></label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" id="editSkillName" onChange={skillExistsCheck} />
                                    <label id="editskillnameerror" className="skillnameerror error hide">Skill name is required</label>
                                    <label id="editskillexists" className="skillexistserror error hide">Skill already exists</label>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Approval Status </label>
                                <div className="col-sm-8 d-flex">
                                    <div className="d-flex">  <label htmlFor="off_site_state" className="form-check-label">Off</label>
                                        <div className="form-switch form-check mt-1 ms-1">
                                            <input type="checkbox" className="form-check-input" id="edit-approval_state" />
                                        </div>
                                        <label htmlFor="on_site_state" className="form-check-label">On</label>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">

                            <a type="button" className="btn btn-warning btn-md ignore-btn" data-bs-dismiss="modal">Cancel</a>
                            <a type="button" className="btn btn-warning btn-md next-btn" onClick={updateSkill} >Update</a>
                        </div>
                    </div>
                </div>
            </div>

            {/* Delete Skill*/}
            <div className="modal fade show Skillmaster" id="deleteskill">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Delete Skill</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <h5>Are you sure want to delete this skill?</h5>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-warning btn-md ignore-btn" data-bs-dismiss="modal">No</a>
                            <a type="button" className="btn btn-warning btn-md next-btn" data-bs-dismiss="modal" onClick={deleteSkill} >Yes</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade show Skillmaster" id="EditVersion">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Edit Version</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">Version  <span className="error">*</span></label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" id="editSkillVersionName" onChange={skillVersionExistsCheck} />
                                    <label id="editskillversionnameerror" className="skillversionnameerror error hide">Skill version name is required</label>
                                    <label id="editskillversionexists" className="skillversionexistserror error hide">Skill version already exists</label>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">

                            <a type="button" className="btn btn-warning btn-md ignore-btn" data-bs-dismiss="modal">Cancel</a>
                            <a type="button" className="btn btn-warning btn-md next-btn" onClick={updateSkillVersion} >Update</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Delete Skill Version*/}
            <div className="modal fade show Skillmaster" id="DeleteVersion">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Delete Version</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <h5>Are you sure want to delete this Version?</h5>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-warning btn-md ignore-btn" data-bs-dismiss="modal">No</a>
                            <a type="button" className="btn btn-warning btn-md next-btn" data-bs-dismiss="modal" onClick={deleteSkillVersion} >Yes</a>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade show Skillmaster" id="AddSKillVersion">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Add Version</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="addSkillVersionForm">
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Version <span className="error">*</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="addSkillVersionName" onChange={skillVersionExistsCheck} />
                                        <label id="skillversionnameerror" className="skillversionnameerror error hide">Skill version name is required</label>
                                        <label id="skillversionexists" className="skillversionexistserror error hide">Skill version already exists</label>
                                    </div>
                                </div>

                            </form>
                        </div>


                        <div className="modal-footer">

                            <a type="button" className="btn btn-warning btn-md ignore-btn" data-bs-dismiss="modal">Cancel</a>
                            <a type="button" className="btn btn-warning btn-md next-btn" onClick={addSkillVersion} >Add</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const VersionAccordion = ({ skillId, skillversionMeta, setThisSkill, updateSkill, setdeleteSkill, deleteSkill, setdeleteSkillVersion, seteditSkillVersion, deleteSkillVersion, updateSkillVersion, addSkillVersion, setaddSkillId, skillExistsCheck, skillVersionExistsCheck, skillStatus, skillName, skillApproval, updateSkillDetails, updateSkillVersionDetails, addSkill }) => {
    var newskillversionList = skillversionMeta.filter((ele) => ele.skillId == skillId);

    return (
        <>
            <div id={"collapse-" + skillId} className="accordion-collapse collapse p-3  mb-2" data-bs-parent="#accordionExample">
                {/* Delete Skill Button */}

                <div className='d-flex'>


                    <a className="btn btn-sm btn-danger btn-rounded skillmetabtn" data-bs-toggle="modal" data-bs-target="#deleteskill" title="Delete Skill" data-for={skillId} onClick={setdeleteSkill}>
                        <i className="fa fa-trash "></i> Delete Skill
                    </a>
                    {/* Update Skill Button -> Skill Name , Approve */}
                    <a className="btn btn-sm btn-primary btn-rounded skillmetabtn mx-5 editskillbtn" data-bs-toggle="modal" data-bs-target="#EditSkill" title="Edit Skill" data-for={skillId} onClick={() => setThisSkill(skillId)}>
                        <i className="fa fa-edit  "></i>  Edit Skill
                    </a>
                    {/* Add Version Button -> Version Name */}
                    <a className="btn btn-sm btn-primary btn-rounded skillmetabtn mx-5 editskillbtn " data-bs-toggle="modal" data-bs-target="#AddSKillVersion" data-for={skillId} onClick={() => setaddSkillId(skillId)} title="Add Version">
                        <i className="fa fa-plus "></i>  Add Version
                    </a>

                    {/* <a className="form-switch form-check ms-2 m-auto" onChange={() => updateSkillDetails(skillId, skillName, skillApproval)}> */}
                    <a className="form-switch form-check ms-2 m-auto mx-5" title="Skill Status">
                        {skillStatus == 0 ? <input type="checkbox" className="form-check-input status-toggle" onChange={() => updateSkillDetails(skillId, skillName, skillApproval, 1)} id={"status-inactive-" + skillId} value="0" /> :
                            <input type="checkbox" className="form-check-input status-toggle active-status" onChange={() => updateSkillDetails(skillId, skillName, skillApproval, 0)} id={"status-active-" + skillId} value="1" checked />}
                        {/* <input type="checkbox" className={skillStatus == 0 ? "form-check-input status-toggle" : "form-check-input status-toggle active-status"} id="status-active_inactive" /> */}
                    </a>
                </div>
                <div className='mt-2'>
                    <table className="table table-bordered skillstablesection ">

                        <thead className='.thead-light'>
                            <tr>
                                <th className="text-center">Version</th>
                                <th className="text-center">Edit</th>
                                <th className="text-center">Delete</th>
                                <th className="text-center">Version Status</th>
                            </tr>
                        </thead>
                        {
                            newskillversionList.length != 0 ? newskillversionList.map((ele, index) => (

                                <tr key={index} className="table-light">

                                    {/* Table with columns - Version, Edit and Delete  */}
                                    <td> {ele.skillVersionName}</td>
                                    <td><a className="btn btn-sm btn-primary btn-rounded" data-bs-toggle="modal" data-bs-target="#EditVersion" title="Edit Version" data-for={ele?.skillVersionId} onClick={seteditSkillVersion}>
                                        <i className="fa fa-edit "></i> Edit Version
                                    </a></td>
                                    <td><a className="btn btn-sm btn-danger btn-rounded" data-bs-toggle="modal" data-bs-target="#DeleteVersion" title="Delete Version" data-for={ele?.skillVersionId} onClick={setdeleteSkillVersion}>
                                        <i className="fa fa-trash "></i>  Delete Version
                                    </a></td>
                                    <td>
                                        <div className="skillversionstatusbgcolor">
                                            {/* <div className="form-switch form-check ms-2"><input type="checkbox" className="form-check-input status-toggle active-status" id="status-active_inactive" /></div> */}
                                            <div className="form-switch form-check ms-2" title="Version Status">
                                                {ele.skillVersionStatus == 0 ? <input type="checkbox" className="form-check-input status-toggle" onChange={() => updateSkillVersionDetails(ele?.skillVersionId, ele?.skillVersionName, 1)} id={"version_status-inactive-" + ele?.skillVersionId} value="0" /> :
                                                    <input type="checkbox" className="form-check-input status-toggle active-status" onChange={() => updateSkillVersionDetails(ele?.skillVersionId, ele?.skillVersionName, 0)} id={"version_status-active-" + ele?.skillVersionId} value="1" checked />}

                                            </div>
                                        </div>
                                    </td>


                                </tr>)) : <p>No data</p>
                        }
                    </table>
                </div>
            </div>

        </>
    )


}

