import { React, useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom';
import Header from '../../../commons/components/Header';
import CaaSSideBar from '../../../commons/components/SideBars/CaaSSideBar';
import { API } from '../../../config/APIConfig';
import axios from 'axios'
import formatDate from '../../../utils/dateformatter';
import logger from '../../../utils/logger';
import $ from "jquery";
import swal from 'sweetalert';
import Pagination from './Pagination';

export default function LanguageManagement() {
    const [curLanguage, setCurLanguage] = useState({});
    const [languageList, setLanguageList] = useState([]);
    const [curLanguageList, setCurLanguageList] = useState([]);
    const LanguageListAPI = `${API.URL}api/v1/meta/alllanguagelist`;
    const LanguageCreateAPI = `${API.URL}api/v1/meta/language/create`
    const LanguageUpdateAPI = `${API.URL}api/v1/meta/language/update`
    const LanguageNameValidationAPI = `${API.URL}api/v1/meta/validate/languagename`
    const [languageExists, setLanguageExists] = useState(false);
    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    // No of Records to be displayed on each page   
    const [recordsPerPage] = useState(10);
    //pagination
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = curLanguageList.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(curLanguageList.length / recordsPerPage);


    const getLanguageList = async () => {
        const response = await fetch(LanguageListAPI);
        if (!response.ok) {
            throw new Error('Data coud not be fetched!')
        } else {
            return response.json()
        }
    }
    useEffect(() => {
        bindLanguages();
        highlightNavMenu();
    }, []);
    const highlightNavMenu = () => {
        $("li.sidebarmenu").removeClass("active");
        $("#caas_languages_li").addClass("active");
    }
    const bindLanguages = () => {
        setLoading(true);
        getLanguageList()
            .then((data) => {
                if (data.response) {
                    setLanguageList(data.response);
                    setCurLanguageList(data.response)
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e.message)
            });
    }

    const resetLanguageStatus = (id, name, curStatus) => {
        var status = curStatus == 0 ? 1 : 0;
        updateLanguageDetails(id, name, status);
    }

    const editLanguage = () => {
        const id = curLanguage[0]?.languageId
        const status = curLanguage[0]?.languageStatus;
        const name = document.getElementById("editLanguageName").value;
        if (!languageExists)
            updateLanguageDetails(id, name, status);
    }

    const updateLanguageDetails = (id, name, status) => {
        let updobj =
        {
            languageId: id,
            languageName: name,
            languageStatus: status,
        }
        var validation = nameValidation(name);
        if (validation) {
            axios.put(LanguageUpdateAPI, updobj)
                .then(response => {
                    if (response.data.status === 200) {
                        bindLanguages();
                        $("#EditLanguage .close").trigger("click");
                        swal("Updated Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                        document.getElementById("editlanguageform").reset();
                    }
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }
    }

    const addLanguage = () => {
        var name = document.getElementById("languageName").value;
        var validation = nameValidation(name);
        if (validation === false)
            return
        if (!languageExists) {
            let obj =
            {
                languageName: name

            }
            axios.post(LanguageCreateAPI, obj).then(response => {
                if (response.data.status === 200) {
                    bindLanguages();
                    $("#AddLanguage .close").trigger("click");
                    swal("Language Added Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                    document.getElementById("addLanguageForm").reset();
                    removeErrorMsgs();

                }
            })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }
    }

    const removeErrorMsgs = () => {
        $(".languagenameerror").hide();
        $(".languageexistserror").hide();
    }
    const existsCheck = (e) => {
        var eleId = e.target.id;
        var name = document.getElementById(eleId).value;
        if (name == null || name == undefined)
            return
        if (eleId === "languageName") {
            var validation = nameValidation(name);
        }

        if (eleId === "editLanguageName") {
            var validation = nameValidation(name);
            var existingName = curLanguage[0]?.languageName;
            if (existingName.trim() === name.trim()) {
                return true
            }
        }
        if (validation) {
            axios.get(`${LanguageNameValidationAPI}?languageName=${name}`)
                .then(response => {
                    if (response.data === true) {
                        $(".languagenameerror").hide();
                        $(".languageexistserror").show();
                        setLanguageExists(true);
                    }
                    else {
                        $(".languageexistserror").hide();
                        setLanguageExists(false)
                    }
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }
    }

    const nameValidation = (name) => {
        if (name) {
            $(".languagenameerror").hide()
            return true;
        }
        else {
            $(".languagenameerror").show()
            $(".languageexistserror").hide();
            return false;
        }
    }


    const bindthisLanguage = (id) => {
        removeErrorMsgs();
        const data = languageList?.filter((ele) => ele.languageId == id);
        setCurLanguage([...data]);
        document.getElementById("editLanguageName").value = data[0]?.languageName;
    }

    const searchLanguage = () => {
        const keyword = document.getElementById("searchlanguage").value;
        const searchedlanguage = languageList.filter((ele) => ele.languageName.toLowerCase().includes(keyword.toLowerCase()));
        setCurrentPage(1);
        setCurLanguageList([...searchedlanguage]);
    }


    return (
        <>
            <div className="container-scroller">
                <Header entity="CaaS" />
                <div className="container-fluid page-body-wrapper">
                    <CaaSSideBar />
                    {loading ? (
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    ) : (
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-12 grid-margin stretch-card">
                                        <div className="col-lg-12 d-grid">
                                            <div className="grid-margin stretch-card">
                                                <div className="card">
                                                    <div className="card-body pb-0">
                                                        <h4 className="card-title">Languages</h4>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="mt-0 text-left box-shadow cardPad card-body border border-radius">
                                                        <div className="d-flex justify-content-between">
                                                            <a className="btn addindustrybtn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#AddLanguage" title="Add" onClick={removeErrorMsgs}>
                                                                <i className="mdi mdi-note-plus-outline"></i> Add Language
                                                            </a>

                                                            <div className="float-end d-flex align-items-center filter-option mb-0">
                                                                <input className="form-control border-secondary border-right-0 rounded-0 search-input" type="search"
                                                                    placeholder="Search Language"
                                                                    id="searchlanguage"
                                                                    onChange={searchLanguage}
                                                                />
                                                                <div className="col-auto">
                                                                    <button className="btn btn-outline-secondary border-left-0 rounded-0 rounded-right search-btn" type="button"><i className="fa fa-search"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="row">
                                                            <div className="col-12 col-sm-12 mt-2">
                                                                <div className="home-tab">
                                                                    <div className="tab-content1">
                                                                        <div className="" id="NewCompany" >
                                                                            <div className="template-demo table-responsive">
                                                                                <Records currentRecords={currentRecords} curLanguageList={curLanguageList} bindthisLanguage={bindthisLanguage} resetLanguageStatus={resetLanguageStatus} />
                                                                                <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="modal fade show borderad" id="EditLanguage" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">Edit Language</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="editlanguageform">
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Language Name <span className="error">*</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="editLanguageName" onChange={existsCheck} />
                                        <label id="editlanguagenameerror" className="languagenameerror error hide">Language name is required</label>
                                        <label id="editExistingLanguage" className="languageexistserror error hide">Language already exists</label>
                                    </div>
                                </div>

                            </form>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">Cancel</a>
                            <a type="button" className="btn btn-warning btn-md next-btn" onClick={editLanguage}>Update</a>

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show borderad" id="AddLanguage" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">Add Language </h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="addLanguageForm">
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Language Name <span className="error">*</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="languageName" onChange={existsCheck} />
                                        <label id="languageerror" className="languagenameerror error hide">Language is required</label>
                                        <label id="existingLanguage" className="languageexistserror error hide">Language already exists</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">Cancel</a>
                            <a type="button" className="btn btn-warning btn-md next-btn" onClick={addLanguage}>Add</a>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

const Records = ({ currentRecords, curLanguageList, bindthisLanguage, resetLanguageStatus }) => {
    return (

        <table className="table table-bordered mb-0">
            <thead>
                <tr>

                    <th className="text-left-intend">Language Name</th>
                    <th className="text-center">Status</th>
                    <th className="text-center" colSpan="2">Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    currentRecords.length != 0 ? (
                        currentRecords.map((ele, index) => (
                            <tr key={ele?.languageId}>
                                <td className="text-left-intend">{ele?.languageName}</td>
                                <td className="text-center">
                                    <div className={ele?.languageStatus === 1 ? "badge badge-success" : "badge  badge-warning"}>{ele?.languageStatus === 1 ? "Active" : "InActive"}</div>
                                </td>
                                <td className="text-center">
                                    <a className="pb-3" >
                                        <i className="fa-solid fa-pencil" data-bs-toggle="modal" data-bs-target="#EditLanguage" title="Edit" onClick={() => { bindthisLanguage(ele?.languageId) }}></i>
                                    </a>
                                </td>
                                <td className='d-grid'>
                                    <div className="d-flex mx-auto">
                                        <label htmlFor="site_state" className="form-check-label">In Active</label>
                                        <div className="form-switch form-check ms-2 margin-0">
                                            <input type="checkbox" className="form-check-input" id="site_state" onChange={() => {
                                                resetLanguageStatus(ele?.languageId, ele?.languageName, ele?.languageStatus);
                                            }} checked={ele?.languageStatus == 1 ? 'checked' : ''} />
                                        </div>
                                        <label htmlFor="site_state" className="form-check-label">Active</label>
                                    </div>
                                </td>



                            </tr>
                        ))
                    ) : (
                        <tr>
                            <h1>No Data</h1>
                        </tr>
                    )
                }
            </tbody>
        </table>)
}