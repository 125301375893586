import React from 'react'
import { Routes, Route } from 'react-router-dom';

import GlobalDashboard from '../pages/Dashboard/caas/GlobalDashboard';
import EnterpriseDashboard from '../pages/Dashboard/enterprise/EnterpriseDashboard';
import CXODashboard from '../pages/Dashboard/cxo/CXODashboard';
import LandingPage from '../pages/Landing/LandingPage';
import LoginHome from '../pages/Auth/Login/LoginHome';
import RegisterHome from '../pages/Auth/Register/RegisterHome';
// import CompanyRegistration from '../pages/Auth/Register/CompanyRegistration';
import EnterpriseRegistration from '../pages/Auth/Register/EnterpriseRegistration';
import CXORegistration from '../pages/Auth/Register/CXORegistration';
import ActiveRFPGlobal from '../pages/RFP/caas/ActiveRFPGlobal';
import PostRFP from '../pages/RFP/enterprise/PostRFP';
import PartnerRegistration from '../pages/Auth/Register/PartnerRegistration';
import PublishedRFPGlobal from '../pages/RFP/caas/PublishedRFPGlobal';
import RecivedQuoteGlobal from '../pages/Quote/caas/RecivedQuoteGlobal';
import ForwardRFP from '../pages/RFP/caas/ForwardRFP';
import AutomatchAndPublishRFP from '../pages/RFP/caas/AutomatchAndPublishRFP';
import RFPList from '../pages/RFP/cxo/RFPList';
import RFPView from '../pages/RFP/cxo/RFPView';
import RFPQuote from '../pages/Quote/cxo/RFPQuote';
import ManageRFP from '../pages/RFP/enterprise/ManageRFP';
import EnterpriseRFPQuote from '../pages/Quote/enterprise/RFPQuote';
import ManageCXORFP from '../pages/RFP/cxo/ManageCXORFP';
import RoleManagement from '../pages/Settings/caas/RoleManagement';
import UserManagement from '../pages/Settings/caas/UserManagement';
import EnterpriseManagement from '../pages/Settings/caas/EnterpriseManagement';
import RFPViewEnterprise from '../pages/RFP/enterprise/RFPViewEnterprise';
import AuthLogin from '../pages/Auth/AuthLogin';
import CXOProfileView from '../commons/components/Profile/CXOProfileView';
import ChangePassword from '../pages/Auth/ChangePassword';
import RFPEdit from '../pages/RFP/enterprise/RFPEdit';
import PersonalWall from '../pages/SocialFeeds/PersonalWall'
import MyConnections from '../pages/SocialFeeds/commons/MyConnections';
import CAASMembers from '../pages/SocialFeeds/commons/CAASMembers';
import MyConversations from '../pages/SocialFeeds/commons/MyConversations';
import MyRFPs from '../pages/RFP/cxo/MyRFPs';
import EnterpriseNetworks from '../pages/SocialFeeds/commons/EnterpriseNetworks';
import GroupList from '../pages/SocialFeeds/commons/GroupList';
import GroupWall from '../pages/SocialFeeds/GroupWall';
import GroupNotifications from '../pages/SocialFeeds/GroupNotifications';
import EncryptionDecryption from '../pages/Auth/EncryptionDecryption';
import LanguageManagement from '../pages/Settings/caas/LanguageManagement';
import CXOMyProfileView from '../commons/components/Profile/CXOMyProfileView';
import VendorRegistration from '../pages/Auth/Register/VendorRegistration';
import VendorDashboard from '../pages/Dashboard/vendor/VendorDashboard';
import VendorWall from '../pages/SocialFeeds/Vendor/VendorWall';
import CaasMemberLogin from '../pages/Auth/Login/caasmember/CaasMemberLogin';
import CaasAdminLogin from '../pages/Auth/Login/caasadmin/CaasAdminLogin';
import EnterprisePMLogin from '../pages/Auth/Login/enterprise/EnterprisePMLogin';
import VendorAdmin from '../pages/Auth/Login/vendor/VendorAdmin';
import HireCaas from '../pages/HireCaas/HireCaas';
import VendorList from '../pages/Settings/caas/VendorList';
import SkillManage from '../pages/Settings/caas/SkillManage';
import IndustryManagement from '../pages/Settings/caas/IndustryManagemet';
import AreaOfProjectManagement from '../pages/Settings/caas/AreaOfProjectManagement';
import CXOUserManagement from '../pages/Settings/caas/CXOUserManagement';
import EducationManagement from '../pages/Settings/caas/EducationManagement';
import RFPManagement from '../pages/Settings/caas/RFPManagement';
import AdminRFPView from '../pages/Settings/caas/AdminRFPView';
import Sitemap from '../seo/Sitemap';
import EnterpriseView from '../pages/Settings/caas/EnterpriseView';
import VendorView from '../pages/Settings/caas/VendorView';
import CaasMemberView from '../pages/Settings/caas/CaasMemberView';
import RFPPublishedUsers from '../pages/Settings/caas/RFPPublishedUsers';
const RouteConfig = () => {
  return (
    <Routes>
      <Route path='/' sitemapIndex='true' changefreq='monthly' priority='1' element={<LandingPage />} />
      <Route path='/auth/changepassword' sitemapIndex='true' changefreq='never' priority='1' element={<ChangePassword />} />

      {/* <Route path='/auth/login' element={<AuthLogin />} /> */}
      {/* <Route path='/partner/register' element={<PartnerRegistration />} /> */}

      <Route path='/auth/caasmember/login' sitemapIndex='true' changefreq='never' priority='1' element={<CaasMemberLogin />} />
      {/* <Route path='/cxo/cxo/dashboard' sitemapIndex='true' changefreq='weekly' priority='1' element={<CXODashboard />} /> */}
      <Route path='/cxo/cxo/register' sitemapIndex='true' changefreq='yearly' priority='1' element={<CXORegistration />} />
      <Route path='/cxo/cxo/wall/personal' sitemapIndex='true' changefreq='hourly' priority='1' element={<PersonalWall />} />
      <Route path='/cxo/profile' sitemapIndex='true' changefreq='weekly' priority='1' element={<CXOProfileView />} />
      <Route path='/cxo/cxo/myconnections' sitemapIndex='true' changefreq='weekly' priority='1' element={<MyConnections />} />
      <Route path='/cxo/cxo/caasmembers' sitemapIndex='true' changefreq='daily' priority='1' element={<CAASMembers />} />
      <Route path='/cxo/cxo/myconversations' sitemapIndex='true' changefreq='daily' priority='1' element={<MyConversations />} />
      <Route path='/cxo/cxo/enterprisenetwork' sitemapIndex='true' changefreq='weekly' priority='1' element={<EnterpriseNetworks />} />
      <Route path='/cxo/cxo/grouplist' sitemapIndex='true' changefreq='weekly' priority='1' element={<GroupList />} />
      <Route path='/cxo/cxo/wall/group/:groupId' sitemapIndex='true' changefreq='hourly' priority='1' element={<GroupWall />} />
      <Route path='/cxo/cxo/notifications' sitemapIndex='true' changefreq='hourly' priority='1' element={<GroupNotifications />} />
      <Route path='/cxo/myprofile' sitemapIndex='true' changefreq='weekly' priority='1' element={<CXOMyProfileView />} />
      {/* <Route path='/cxo/cxo/rfp/manage' sitemapIndex='true' changefreq='weekly' priority='1' element={<RFPList/>}/>  */}
      <Route path='/cxo/cxo/rfp/manage' sitemapIndex='true' changefreq='weekly' priority='1' element={<ManageCXORFP />} />
      <Route path='/cxo/cxo/rfp/myrfps' sitemapIndex='true' changefreq='weekly' priority='1' element={<MyRFPs />} />
      <Route path='/cxo/cxo/rfp/view/:rfpId' sitemapIndex='true' changefreq='weekly' priority='1' element={<RFPView />} />
      <Route path='/cxo/cxo/rfp/quote/:rfpId' sitemapIndex='true' changefreq='weekly' priority='1' element={<RFPQuote />} />
      {/* <Route path='/caas/enterprise' sitemapIndex='true' changefreq='weekly' priority='1' element={<EnterpriseManagement />} /> */}


      <Route path='/enterprise/register' sitemapIndex='true' changefreq='weekly' priority='1' element={<EnterpriseRegistration />} />
      <Route path='/auth/enterprisepm/login' sitemapIndex='true' changefreq='never' priority='1' element={<EnterprisePMLogin />} />
      <Route path='/enterprise/manager/dashboard' sitemapIndex='true' changefreq='weekly' priority='1' element={<EnterpriseDashboard />} />
      <Route path='/enterprise/manager/rfp/create' sitemapIndex='true' changefreq='weekly' priority='1' element={<PostRFP />} />
      <Route path='/enterprise/manager/rfp/edit/:rfpId' sitemapIndex='true' changefreq='weekly' priority='1' element={<RFPEdit />} />
      <Route path='/enterprise/manager/rfp/manage' sitemapIndex='true' changefreq='weekly' priority='1' element={<ManageRFP />} />
      <Route path='/enterprise/manager/rfp/view/:rfpId' sitemapIndex='true' changefreq='weekly' priority='1' element={<RFPViewEnterprise />} />
      <Route path='/enterprise/manager/quote/viewReceived/:rfpId' sitemapIndex='true' changefreq='weekly' priority='1' element={<EnterpriseRFPQuote />} />

      <Route path='/secret/encrdecr' element={<EncryptionDecryption />} />


      <Route path='/vendor/register' sitemapIndex='true' changefreq='weekly' priority='1' element={<VendorRegistration />} />
      <Route path='/auth/vendoradmin/login' sitemapIndex='true' changefreq='weekly' priority='1' element={<VendorAdmin />} />
      <Route path='/vendor/admin/dashboard' sitemapIndex='true' changefreq='weekly' priority='1' element={<VendorDashboard />} />
      <Route path='/vendor/wall' sitemapIndex='true' changefreq='weekly' priority='1' element={<VendorWall />} />


      <Route path='/hire/caas' sitemapIndex='true' changefreq='weekly' priority='1' element={<HireCaas />} />


      {/* Admin Routes */}
      <Route path='/auth/caasadmin/login' element={<CaasAdminLogin />} />
      <Route path='/caas/global/dashboard' element={<GlobalDashboard />} />
      <Route path='/caas/global/rfp' element={<ActiveRFPGlobal />} />




      {/*  */}
      <Route path='/caas/global/rfp/viewPublishedDetails/:rfpId' element={<PublishedRFPGlobal />} />
      <Route path='/caas/global/rfp/forward/:rfpId' element={<ForwardRFP />} />
      <Route path='/caas/global/rfp/automatchAndPublish/:rfpId' element={<AutomatchAndPublishRFP />} />
      <Route path='/caas/global/quote/viewReceived/:rfpId' element={<RecivedQuoteGlobal />} />
      <Route path='/caas/global/rfppublished/list/:rfpId' element={<RFPPublishedUsers />} />
      {/* <Route path='/caas/global/role' element={<RoleManagement />} /> */}
      {/*  */}



      <Route path='/caas/global/languages' element={<LanguageManagement />} />
      <Route path='/caas/global/manageskill' element={<SkillManage />} />
      <Route path='/caas/global/industry' element={<IndustryManagement />} />
      <Route path='/caas/global/areaofproject' element={<AreaOfProjectManagement />} />
      <Route path='/caas/global/education' element={<EducationManagement />} />
      <Route path='/caas/global/managerfp' element={<RFPManagement />} />
      <Route path='/caas/global/rfp/view/:rfpId' element={<AdminRFPView />} />
      <Route path='/caas/global/cxo/manage' element={<CXOUserManagement />} />
      <Route path='/caas/global/enterprise/manage' element={<EnterpriseManagement />} />
      <Route path='/caas/global/vendor/manage' element={<VendorList />} />

      <Route path='/caas/global/enterprise/view/:enterpriseId' element={<EnterpriseView />} />
      <Route path='/caas/global/vendor/view/:vendorId' element={<VendorView />} />
      <Route path='/caas/global/caasmember/view' element={<CaasMemberView />} />

      <Route path='/sitemap' sitemapIndex='true' changefreq='weekly' priority='1' element={<Sitemap />} />
    </Routes>
  )
}

export default RouteConfig
