import React, { useState, useEffect } from 'react'
import Header from '../../../commons/components/Header'
import Footer from '../../../commons/components/Footer'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import EnterpriseSideBar from '../../../commons/components/SideBars/EnterpriseSideBar'
import RFPDetailsCommon from '../../RFP/RFPDetailsCommon'
import CXOProfileView from '../../../commons/components/Profile/CXOProfileView'
import swal from 'sweetalert';
import $ from "jquery";
import { API } from '../../../config/APIConfig';
import axios from 'axios'
import logger from '../../../utils/logger'
import { useTranslation } from "react-i18next";
import { S3Config } from '../../../config/AWSConfig'
const QUOTEDETAILSAPI = `${API.URL}api/v1/quote/list`;


const RFPQuote = () => {
    const { i18n, t } = useTranslation(["quote"]);

    const location = useLocation();
    const rfpId = location.pathname.split('/')[5];
    const [quotedata1, setquoteData1] = useState([{ quoteid: "1", userid: "1", quotereqspec: "Designing a reporting portal for digital marketing", quotescopeconf: "On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment.", quoteoutofscopeconf: "On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment.", quotecost: "45,000", quoteuserfirstname: "John", quoteuserlastname: "Wig", quoteemail: "John@gmail.com" }, { quoteid: "2", userid: "2", quotereqspec: " Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aspernatur consequuntur ratione, aperiam eos adipisci ex et pariatur eum, quaerat tempore excepturi consectetur optio minus similique, commodi quis esse ab", quotescopeconf: "On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment.", quoteoutofscopeconf: "On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment.", quotecost: "15,000", quoteuserfirstname: "John", quoteuserlastname: "Williams", quoteemail: "Williams@gmail.com" }]);    // const { rfpid } = location.state;
    const [quotedata, setQuoteData] = useState([]);
    const [quoteattachments, setQuoteAttachments] = useState([]);
    const [showquotedata, setShowquotedata] = useState('');
    const [showquoteattachments, setShowquoteattachments] = useState([]);
    const [curCXOData, setCurCXOData] = useState([]);
    const navigate = useNavigate();
    const handledeal = (e) => {

        if ($("input[name='radio']:checked").length > 0) {
            $("#DealContract .close").trigger('click')
            swal("Success", "Project is being created and you will be redirecting!", "success" ,{ closeOnClickOutside: false, closeOnEsc: false }).then
                (okay => {
                    if (okay) {
                        navigate('/enterprise/manager/rfp/manage', { replace: true })
                    }
                });
        }
        else {
            swal("Please select a Quote", "", "error" ,{ closeOnClickOutside: false, closeOnEsc: false });
        }



        if ($("input[name='radio']:checked").length > 0) {
            $("#DealContract .close").trigger('click')
            swal("Success", "Project is being created and you will be redirecting!", "success" ,{ closeOnClickOutside: false, closeOnEsc: false }).then
                (okay => {
                    if (okay) {
                        navigate('/enterprise/manager/rfp/manage', { replace: true })
                    }
                });
        }
        else {
            swal("Please select a Quote", "", "error" ,{ closeOnClickOutside: false, closeOnEsc: false });
        }
    }

    useEffect(() => {
        getQuoteDetails();
    }, []);
    const getQuoteDetails = () => {
        // logger.verbose("Quote Details", "RFPQuote Component", "getQuoteDetails", "GET", "Get Quote Details API Hit", `rfpId=${rfpId}`);
        axios.get(`${QUOTEDETAILSAPI}?rfpId=${rfpId}`).then(response => {
            if (response.data.status === 200) {
                setQuoteData(response.data.response.quotedetailslist);
                setQuoteAttachments(response.data.response.quoteattachmentslist);
                // logger.verbose("Quote Details", "RFPQuote Component", "getQuoteDetails", "GET", "Get Quote Details API Response Successfull", JSON.stringify(response.data.response));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            // logger.error("Quote Details", "RFPQuote Component", "getQuoteDetails", "GET", "Get Quote Details API Response Failed!!", JSON.stringify(error));
        });
    }

    const showquote = (id) => {
        let newquote = quotedata.find((elem) => {
            return elem.quoteId === id;
        });
        setShowquotedata(newquote);
        let newquoteattachment = quoteattachments.filter((elem) => {
            return elem.quoteId === id;
        });
        setShowquoteattachments(newquoteattachment);
    }
    const resetCurCXO = (cxoUserId, roleId) => {      
        let obj = {
            userId: cxoUserId, roleId: roleId
        }
        let arr = [];
        arr.push(obj);
        setCurCXOData([...arr]);
    }

    return (
        <div className="container-scroller">
            <Header entity="Enterprise" />
            <div className="container-fluid page-body-wrapper">
            <EnterpriseSideBar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="card card-rounded card-outline card-primary">
                                    <div className="card-header">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <nav className="navbar navbar-expand bg-white">

                                                    <ul className="navbar-nav">
                                                        <li className="nav-item bold">
                                                            <h3 className="fw-bold mb-0 card-title">{t('viewRFPResponse')}
                                                            </h3>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                            <div>
                                                <Link to="/enterprise/manager/rfp/manage" className="btn btn-primary btn-sm add-client btn-icon-text"><i className="fa fa-arrow-left btn-icon-prepend"></i>{t('backToRFP')}</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row px-4">
                                            <RFPDetailsCommon rfpId={rfpId} />
                                            <div className="col-12 col-sm-12 mt-2">
                                                <div className="col-12 col-sm-12 mt-2">
                                                    <div className="template-demo table-responsive">
                                                        <table className="table table-bordered mb-0 DealContractCols">
                                                            <thead>
                                                                <tr>
                                                                    <th>{t('action')}</th>
                                                                    <th className="DealContractCol"><a href="#" className="btn btn-primary btn-sm" data-bs-target="#DealContract" data-bs-toggle="modal">{t('dealAndContract')}</a></th>
                                                                    <th>{t('name')}</th>
                                                                    <th>{t('email')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {quotedata.length != 0 ? (quotedata.map((item, i) => (
                                                                    <tr key={i} value={item.quoteId}>
                                                                        <td>
                                                                            <a className="text-dark text-decoration-underline" id={item.quoteId} data-bs-toggle="modal" data-bs-target="#quotedata" onClick={() => showquote(item.quoteId)}>{t('quoteReceived')}</a>

                                                                        </td>
                                                                        <td className="">

                                                                            <div className="form-check d-flex ms-0">
                                                                                <label className="form-check-label text-gray">
                                                                                    <input type="radio" name="radio" className="form-check-input" value={item.quoteId} />
                                                                                    <i className="input-helper"></i><i className="input-helper"></i></label>

                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <a href="#"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#ViewProfile"
                                                                                data-backdrop="static" data-keyboard="false"
                                                                                onClick={() => resetCurCXO(item.quotedBy, item.roleId)}>
                                                                                {/* {item.quotedByuserName}, {item.quoteuserlastname}</a></td> */}
                                                                                {item.quotedByuserfirstName} {item.quotedByuserlastname}</a></td>
                                                                        <td>{item.quotedByuserEmail}</td>
                                                                    </tr>))) : (
                                                                    <p>{t('noQuoteData')}</p>)}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <Footer />

                </div>




            </div>
            <div className="modal fade show" id="quotedata" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('viewQuote')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">

                                <div className="col-12 col-sm-12 ">

                                    <div className="form-group border-bottom">
                                        <label className="mb-1 fw-bold">{t('requirementSpecification')}</label>
                                        <p>{showquotedata.quoteRequirement}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12">

                                    <div className="form-group  border-bottom">
                                        <label className="mb-1 fw-bold">{t('scopeConfirmation')}</label>
                                        <p>{showquotedata.quoteScope}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 ">

                                    <div className="form-group border-bottom">
                                        <label className="mb-1 fw-bold">{t('outOfScopeConfirmationAddition')}</label>
                                        <p>{showquotedata.quoteOutOfScope}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12">
                                    <div className="form-group border-bottom">
                                        <label className="mb-1 fw-bold">{t('cost')}:</label>
                                        <p>${showquotedata.costEstimate}</p>
                                    </div>
                                </div>


                                <div className="mt-2 pb-1">
                                    <h3 className="text-dark fw-bold h4 border-bottom py-3">{t('attachment')}</h3>
                                </div>

                                {
                                    showquoteattachments.length != 0 ? (
                                        showquoteattachments.map((ele, index) => (
                                            // <option key={index} value={ele.quoteAttachmentsId}>{ele.countryName}</option>
                                            <div className="mt-0 pb-1">
                                                <label className="text-gray mb-2 mt-2 pb-3 border-bottom d-block" key={index} value={ele.quoteAttachmentsId}> <i className="fa-solid fa-file-word fa-lg"></i> - {ele.quoteAttachmentsType}</label>
                                                <a href={ele.attachment}>{ele.attachmentTitle.split(S3Config.S3ObjectDelimiter)[0]}</a>
                                            </div>
                                        ))
                                    ) : null
                                }




                                {/* <label for="" className="text-gray mb-2 mt-2 pb-3 border-bottom d-block"> <i className="fa-solid fa-file-word fa-lg"></i> - Bill of Material/Hardware/Software Licences</label>
                                </div> */}

                                {/* <div className="mt-2 pb-1">
                                    <label for="" className="text-gray mb-2 mt-2 pb-3 border-bottom d-block "> <i className="fa-solid fa-file-word fa-lg"></i> - Architecture/Design Doccuments</label>
                                </div>
                                <div className="mt-0 pb-1 ">
                                    <label for="" className="text-gray mb-2 mt-2 pb-3 border-bottom d-block"> <i className="fa-solid fa-file-word fa-lg"></i> - Project Plan</label>
                                </div>

                                <div className="mt-0 pb-1 ">
                                    <label for="" className="text-gray mb-2 mt-2 pb-3  d-block "> <i className="fa-solid fa-file-word fa-lg"></i> - Miscellaneous File</label>
                                </div>
 */}

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger btn-sm" data-bs-dismiss="modal">{t('close')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="DealContract" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('hdealAndContract')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <h5 className="fw-bold">{t('areYouSureToDealAndContractTheSelectedCAASMember')}</h5>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-primary" id="ShowProjectRedirect" onClick={handledeal}>{t('yes')}</a>
                            <a type="button" className="btn btn-danger" data-bs-dismiss="modal">{t('no')}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="Projectredirecting">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('hdealAndContract')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <h5>{t('projectIsBeingCreatedAndYouWillBeRedirectingSoon')}</h5>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-primary" data-bs-dismiss="modal">{t('yes')}</a>
                            <a type="button" className="btn btn-danger" data-bs-dismiss="modal">{t('no')}</a>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade show" id="ViewProfile" aria-modal="true" role="dialog"tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('viewProfile')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setCurCXOData([])}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row w-100 mx-0">
                                {curCXOData.length != 0 ? (<CXOProfileView quotedBy={curCXOData[0]?.userId} roleId={curCXOData[0]?.roleId} />) : <p>{t('noRecordsFound')}</p>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={() => setCurCXOData([])}>{t('close')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default RFPQuote