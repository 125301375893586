const dateDiff = (value) => {
    if (value === "")
        return value;
    var postedDateUTC = new Date(value);
    postedDateUTC = postedDateUTC.getTime()
    var postedTime = new Date(postedDateUTC);
    postedTime.setHours(postedTime.getHours() + 5);
    postedTime.setMinutes(postedTime.getMinutes() + 30);
    let currentDate = new Date();
    let seconds = (currentDate.getTime() - postedTime.getTime()) / 1000;
    seconds = Math.abs(Math.round(seconds));
    if (seconds < 60)
        return seconds + " Seconds Ago";
    let minutes = Math.abs(Math.round(seconds / 60));
    if (minutes < 60)
        return minutes + " Minutes Ago";
    let hours = Math.abs(Math.round(minutes / 60));
    if (hours < 24)
        return hours + " Hours Ago";
    let days = Math.abs(Math.round(hours / 24));
    if (days < 7)
        return days + " Days Ago";
    let weeks = Math.abs(Math.round(days / 7));
    return weeks + " Weeks Ago";
}

export default dateDiff;