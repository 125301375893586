import React from 'react'
import Footer from '../../../commons/components/Footer'
import Header from '../../../commons/components/Header'
import VendorSideBar from '../../../commons/components/SideBars/VendorSideBar'

const VendorDashboard = () => {
  return (
    <>

      <div className="container-scroller">
        <Header entity="Vendor" />
        <div className="container-fluid page-body-wrapper">
          <VendorSideBar />
          <div className="main-panel">
            <div className="content-wrapper bg-light-theme">
              <div className="row d-none1 mx-auto col-md-6 mx-auto text-center">
                
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default VendorDashboard
