import { React, useEffect, useState } from 'react'
import profilepic from "../../../assets/images/noav.jpg"
import axios from 'axios';
import { API } from '../../../config/APIConfig';
const CAASMEMBERCONNECTIONS = `${API.URL}api/v1/social/caasmember/connections`;
const Connections = () => {
    const [caasdetails, setCaasDetails] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const loggedInUserId = sessionStorage.getItem("userId");
    const curuserRoleId = sessionStorage.getItem("roleId");
    const curUserEntityId = sessionStorage.getItem("entityId");
    const getCaasMembersDetails = () => {
        axios.get(`${CAASMEMBERCONNECTIONS}?userId=${loggedInUserId}&userEntityId=${curUserEntityId}&userRoleId=${curuserRoleId}`)
            .then(resp => {
                setCaasDetails(resp.data.response)
            }).catch(error => {
                console.log(error);
            })
    }
    useEffect(() => {
        getCaasMembersDetails();
    }, [])
    return (
        <div className="col_res1 align-items-stretch mob-remove-pe box_3 w_171">
            <div className="box-home box_shadow_1 px-4 sticky-top">
                <div className="d-flex align-items-center justify-content-between">
                    <h3 className="fw-bold fs-4 mb-0">Connections</h3>
                    {/* <div>
                        <i
                            className="fa-solid fa-ellipsis text-black-50 float-end fa-md ms-3"
                        ></i>
                        <i
                            className="fa-regular fa-square-plus float-end text-black-50"
                        ></i>
                    </div> */}
                    <div>
                        <i className="fa-solid fa-circle-info" title="You can view your Connections here"></i>
                    </div>
                </div>
                <div className="row no-gutters mt-4">
                    <div className="col">
                        <input
                            className="form-control border-secondary border-right-0 rounded-0 search-input"
                            type="search"
                            placeholder="Search Connection"
                            id="example-search-input5"
                            onChange={(event) => {
                                setSearchTerm(event.target.value);
                            }}
                        />
                    </div>
                    <div className="col-auto">
                        <button
                            className="btn btn-outline-secondary border-left-0 rounded-0 rounded-right search-btn"
                            type="button"
                        >
                            <i className="fa fa-search"></i>
                        </button>
                    </div>
                </div>
                <div className="mt-4 connectionss">
                    {
                        caasdetails.length != 0 ? (
                            caasdetails.filter((elem) => {
                                if (searchTerm == "") {
                                    return elem
                                }
                                else if (elem.firstName.toLowerCase().includes(searchTerm.toLowerCase()) || elem.lastName.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return elem
                                }
                            })
                                .map((elem, index) => (
                                    <div
                                        className="d-flex justify-content-start align-items-start mt-3" key={index}
                                    >
                                        <img
                                            src={elem.profilePicture}
                                            alt="CAAS Member"
                                            className="img-fluid img-jobs"
                                        />
                                        <div className="ps-3">
                                            <p className="mb-0 fw-bold word-wrap">{elem.firstName} {elem.lastName} </p>
                                            <p className="mb-0 text-black-50 fs-6">
                                                {elem.profileTitle}
                                            </p>
                                        </div>
                                    </div>

                                ))
                        ) : <div
                            className="d-flex justify-content-start align-items-start mt-3"
                        ><div className="ps-3">
                                <p className="mb-0 fw-bold">No Connections</p> </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Connections