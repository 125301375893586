import React, { useState, useEffect } from 'react'
import $ from "jquery";
import profilepic from "../../../assets/images/noav.jpg"
import { API } from '../../../config/APIConfig';
import axios from 'axios'
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Header from '../Header';
import logger from '../../../utils/logger';
import SocialHeader from '../../../pages/SocialFeeds/commons/SocialHeader';
import { S3Config } from '../../../config/AWSConfig';
import S3FileUpload from '../../../utils/S3FileUpload';
import swal from 'sweetalert';
import uuidCustom from '../../../utils/uuidCustom';
import { getCountryStateCityList, getNationalityList, getLanguageList, getEducationList, getSkillExperienceLevelList, getSkillDetailslList, getGenderDetailsList, getIndustryDetailsList } from '../../../utils/meta';
const CXOOWNDETAILSAPI = `${API.URL}api/v1/cxo/mydetails`;
const CXOUsernameCheckAPI = `${API.URL}api/v1/cxo/validate/username`;
const CXOUpdatePersonalAPI = `${API.URL}api/v1/cxo/update/personalinfo`;
const CXOUpdateProfileAPI = `${API.URL}api/v1/cxo/update/profileinfo`;
const CXOUpdateExperiencelAPI = `${API.URL}api/v1/cxo/update/experienceinfo`;
const CXOUpdateEmploymentAPI = `${API.URL}api/v1/cxo/update/employmentinfo`;
const CXOUpdateEducationAPI = `${API.URL}api/v1/cxo/update/educationinfo`;
const CXOUpdateCertificationAPI = `${API.URL}api/v1/cxo/update/certificationinfo`;
const CXOUpdateCVAPI = `${API.URL}api/v1/cxo/update/cvinfo`;
const CXOUpdateEmploymentSkillAPI = `${API.URL}api/v1/cxo/update/employmentskillinfo`;
const CXOUpdateIndustryAPI = `${API.URL}api/v1/cxo/update/industryinfo`;
const CXOACCOUNTDELETEAPI = `${API.URL}api/v1/cxo/account/disable`;
const CXOPASSWORDCHECKAPI = `${API.URL}api/v1/cxo/password/check`;

const CXOMyProfileView = (props) => {


    const { i18n, t } = useTranslation(["registration"]);

    const [personalInfo, setPersonalInfo] = useState([]);
    const [profileInfo, setProfileInfo] = useState([]);
    const [experienceInfo, setExperienceInfo] = useState([]);
    const [employmentInfo, setEmploymentInfo] = useState([]);
    const [employmentskillInfo, setEmploymentSkillInfo] = useState([]);
    const [educationInfo, setEducationInfo] = useState([]);
    const [certificationInfo, setCertificationInfo] = useState([]);
    const [CVInfo, setCVInfo] = useState([]);
    const [cxoindustryExperienceInfo, setCXOIndustryExperienceInfo] = useState([]);
    const [countriesMeta, setCountriesMeta] = useState([]);
    const [statesMeta, setStatesMeta] = useState([]);
    const [curStatesMeta, setCurStatesMeta] = useState([]);
    const [citiesMeta, setCitiesMeta] = useState([]);
    const [curCityList, setCurCityList] = useState([]);
    const [nationalitiesMeta, setNationalitiesMeta] = useState([]);
    const [languagesMeta, setLanguagesMeta] = useState([]);
    const [educationsMeta, setEducationsMeta] = useState([]);
    const [coursesMeta, setCoursesMeta] = useState([]);
    const [institutesMeta, setInstitutesMeta] = useState([]);
    const [coursetypesMeta, setCoursetypesMeta] = useState([]);
    const [skillexperiencelevelsMeta, setSkillexperiencelevelsMeta] = useState([]);
    const [skilldetailsMeta, setSkillDetailsMeta] = useState([]);
    const [skillversionMeta, setSkillVersionssMeta] = useState([]);
    const [curskillVersionList, setCurSkillversionList] = useState([]);
    const [skillRef, setSkillRef] = useState({});
    const [skillVersionRef, setSkillVersionRef] = useState({});
    const [countryRef, setCountryRef] = useState({});
    const [stateRef, setStateRef] = useState({});
    const [cityRef, setCityRef] = useState({});
    const [genderMeta, setGenderMeta] = useState([]);
    const [industrydetails, setIndustryDetails] = useState([]);
    // Profile Info Start
    const [editprofileInfo, setEditProfileInfo] = useState({});
    const [curProfileId, setcurProfileId] = useState(0);
    const [curProfile, setCurProfile] = useState({});
    const [curProfilePicture, setCurProfilePicture] = useState("");
    // Profile Info End
    // Personal Info Start
    // const [editprersonalInfo, setEditPersonalInfo] = useState({});
    const [editprersonalInfo, setEditPersonalInfo] = useState([]);
    const [curPersonalId, setcurPersonalId] = useState(0);
    const [curPersonal, setCurPersonal] = useState({});
    const [userExists, setUserExists] = useState(false)
    // Personal Info End
    // Experience
    const [curExpId, setcurExpId] = useState(0);
    const [curExp, setCurExp] = useState({});
    const [editskillVersionList, setEditSkillversionList] = useState([]);
    const [editexperienceInfo, setEditExperienceInfo] = useState([]);
    const [updateexperienceInfo, setUpdateExperienceInfo] = useState([]);
    //End
    // Employment
    const [curEmpId, setcurEmpId] = useState(0);
    const [curEmp, setCurEmp] = useState({});
    const [curEmpSkill, setCurEmpSkill] = useState([]);
    const [empskill, setEmpSkill] = useState("");
    const [curcheckId, setcurcheckId] = useState(0);
    const [newempskill, setNewEmpSkill] = useState([]);
    const [curempCityList, setCurEmpCityList] = useState([]);
    //End
    // Education
    const [curEduId, setcurEduId] = useState(0);
    const [curEdu, setCurEdu] = useState({});
    //End
    //Certification
    const [curCertId, setcurCertId] = useState(0);
    const [curCert, setCurCert] = useState({});
    const [certificateFile, setCertificateFile] = useState("");
    //End
    //CV
    const [curCVId, setcurCVId] = useState(0);
    const [curCVInfo, setcurCVInfo] = useState(0);
    const [cvAttachmentFile, setCVAttachmentFile] = useState("");
    // End
    //Industry
    const [industryExperiencedisplay, setIndustryExperienceDisplay] = useState([]);
    const [industryExperienceInfo, setIndustryExperienceInfo] = useState("");
    //End
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const loggedInuserId = sessionStorage.getItem('userId');
    const loggedInroleId = sessionStorage.getItem('roleId');
    const loggedInentityId = sessionStorage.getItem('entityId');
    const loggedUserEmail = sessionStorage.getItem('userEmail');
    const loggedInFirstName = sessionStorage.getItem('firstname');
    const loggedInLastName = sessionStorage.getItem('lastname');
    var userId = "";
    var roleId = "";
    const urlheader = location.pathname.split('/')[1].toUpperCase();
    // console.log(quotedBy);
    // console.log(roleId);
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        if (localStorage.getItem("i18nextLng")?.length > 2) {
            i18n.changeLanguage("en");
        }
        userId = sessionStorage.getItem('userId');
        roleId = sessionStorage.getItem('roleId');
        // if (props.quotedBy && props.roleId) {
        //     quotedBy = props.quotedBy;
        //     roleId = props.roleId;
        //     $(".headerinfo").hide();
        // }
        // else {
        //     quotedBy = location.state?.quotedBy ;
        //     roleId = location.state?.role;
        // }
        setLoading(true);
        setTimeout(() => {
            bindDropDownData();
            setLoading(false);
        }, 2000);
        getCXODetails(userId, roleId);
        // bindDropDownData();	
    }, []);
    const bindDropDownData = () => {
        setLoading(true);
        getCountryStateCityList()
            .then((res) => {
                if (res.response) {
                    var countryDetails = res.response.countryList;
                    var stateDetails = res.response.stateList;
                    var cityDetails = res.response.cityList;
                    setCountriesMeta(countryDetails);
                    setStatesMeta(stateDetails);
                    setCurStatesMeta(stateDetails);
                    setCitiesMeta(cityDetails);
                    setCurCityList(cityDetails);
                    setCurEmpCityList(cityDetails);
                    var countryData = countryDetails.reduce((obj, country) => Object.assign(obj, { [country.countryId]: country.countryName }), {});
                    setCountryRef(countryData);
                    var stateData = stateDetails.reduce((obj, state) => Object.assign(obj, { [state.stateId]: state.stateName }), {});
                    setStateRef(stateData);
                    var cityData = cityDetails.reduce((obj, city) => Object.assign(obj, { [city.cityId]: city.cityName }), {});
                    setCityRef(cityData);
                }
            })
            .catch((e) => {
                //console.log(e.message)
            })
        getNationalityList()
            .then((res) => {
                if (res.response) {
                    setNationalitiesMeta(res.response);
                }
            })
            .catch((e) => {
                //console.log(e.message)
            })
        getLanguageList()
            .then((res) => {
                if (res.response) {
                    setLanguagesMeta(res.response);
                }
            })
            .catch((e) => {
                //console.log(e.message)
            })
        getEducationList()
            .then((res) => {
                if (res.response) {
                    setEducationsMeta(res.response.educationList);
                    setCoursesMeta(res.response.courseList);
                    setInstitutesMeta(res.response.instituteList);
                    setCoursetypesMeta(res.response.coursTypeList);
                }
            })
            .catch((e) => {
                //console.log(e.message)
            })
        getSkillExperienceLevelList()
            .then((res) => {
                if (res.response) {
                    setSkillexperiencelevelsMeta(res.response);
                }
            })
            .catch((e) => {
                //console.log(e.message)
            })
        getSkillDetailslList()
            .then((res) => {
                if (res.response) {
                    var skillDetails = res.response.skillMasterList;
                    var skillVersionDetails = res.response.skillVersionList;
                    setSkillDetailsMeta(skillDetails);
                    setSkillVersionssMeta(skillVersionDetails);
                    var skilldata = skillDetails.reduce((obj, skill) => Object.assign(obj, { [skill.skillId]: skill.skillName }), {});
                    setSkillRef(skilldata);
                    var skillVersiondata = skillVersionDetails.reduce((obj, version) => Object.assign(obj, { [version.skillVersionId]: version.skillVersionName }), {});
                    setSkillVersionRef(skillVersiondata);
                }
            })
            .catch((e) => {
                //console.log(e.message)
            })
        getGenderDetailsList().
            then((res) => {
                if (res.response) {
                    setGenderMeta(res.response)
                }
            })
            .catch((e) => {
                //console.log(e.message)
            })

        getIndustryDetailsList()
            .then((res) => {
                if (res.response) {
                    setIndustryDetails(res.response);
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.error('There was an error!', e);
            })
    }
    const getCXODetails = (userId, roleId) => {
        setLoading(true);
        // logger.verbose("Caas Member Details", "CXOProfileView Component", "getCXODetails", "GET", "Get Caas Member Details API Hit", `cxoUserId=${quotedBy}&roleId=${roleId}`);
        // axios.get(`${CXODETAILSAPI}?cxoUserId=${props.quotedBy}&roleId=${props.roleId}`).then(response => {
        axios.get(`${CXOOWNDETAILSAPI}?cxoUserId=${userId}&roleId=${roleId}`).then(response => {
            //    axios.get(`${CXODETAILSAPI}?cxoUserId=41&roleId=4`).then(response => {
            if (response.data.status === 200) {
                setEmploymentSkillInfo(response.data.response.cxoExperienceSkillInfo);
                setPersonalInfo(response.data.response.cxoPersonalInfo);
                setProfileInfo(response.data.response.cxoProfileInfo);
                setExperienceInfo(response.data.response.cxoExperienceInfo);
                setEmploymentInfo(response.data.response.cxoEmploymentInfo);
                setEducationInfo(response.data.response.cxoEducationInfo);
                setCertificationInfo(response.data.response.cxoCertificationInfo);
                setCVInfo(response.data.response.cxoUserCVInfo);
                setCXOIndustryExperienceInfo(response.data.response.cxoIndustryExp);

                showempSkilledit(response.data.response.cxoExperienceSkillInfo);
                logger.verbose("Caas Member Details", "CXOProfileView Component", "getCXODetails", "GET", "Get Caas Member Details API Response Successfull", JSON.stringify(response.data.response));
                setLoading(false);
            }
        }).catch(error => {
            console.error('There was an error!', error);
            logger.error("Caas Member Details", "CXOProfileView Component", "getCXODetails", "GET", "Get Caas Member Details API Response Failed", JSON.stringify(error));
        });
    }
    // Edit Start
    const addUpdIndustry = (e) => {
        let data = $("#experienceIndustry").val();
        let obj = {
            id: industryExperienceInfo.length + 1,
            industryName: $('#experienceIndustry option:selected').text(),
            industryId: e.target.value,
        }
        setIndustryExperienceInfo(data.toString());
        // setIndustryExperienceInfo([...industryExperienceInfo, obj]);
        setIndustryExperienceDisplay(data);
    }
    const editCXOIndustry = () => {
        if (industryExperiencedisplay.length > 0) {
            var industryData = industryExperiencedisplay.map(({ industryId }) => `${industryId}`).join(',');
            $.each(industryData.split(","), function (i, e) {
                $("#experienceIndustry option[value='" + e + "']").prop("selected", true);
            });
        }
        else {
            var industryData = cxoindustryExperienceInfo.map(({ industryId }) => `${industryId}`).join(',');
            $.each(industryData.split(","), function (i, e) {
                $("#experienceIndustry option[value='" + e + "']").prop("selected", true);
            });
        }
    }
    const resetStateList = (e) => {
        var id = e.target.value;
        var newStateList = statesMeta.filter((ele) => ele.countryId == id);
        setCurStatesMeta([...newStateList]);
    }
    const resetCityList = (e) => {
        var id = e.target.value;
        var newCityList = citiesMeta.filter((ele) => ele.stateId == id);
        setCurCityList([...newCityList]);
        setCurEmpCityList([...newCityList]);
    }
    useEffect(() => {
        const employmentStateId = $("#employmentState").val();
        var newCityList = citiesMeta.filter((ele) => ele.stateId == employmentStateId);
        setCurEmpCityList([...newCityList]);
    }, [curStatesMeta])
    useEffect(() => {
        const stateId = $("._employmentState").val();
        if (stateId != 0) {
            var newCityList = citiesMeta.filter((ele) => ele.stateId == stateId);
            setCurCityList([...newCityList]);
        }

    }, [curStatesMeta])
    function userValidationCheck(username) {
        username = JSON.stringify(username).replaceAll('"', '')
        axios.get(`${CXOUsernameCheckAPI}?username=${username}`)
            .then(response => {
                // console.log(response.data);
                if (response.data === true) {
                    $("#userName").addClass("error-shadow");
                    $('#usernameerror').hide();
                    $("#existingUser").show();
                    setUserExists(true);

                    return response.data

                }
                else {
                    if ($("#userName").hasClass("error-shadow"))
                        $("#userName").removeClass("error-shadow");
                    $("#existingUser").hide();
                    setUserExists(false);
                    return response.data
                }

            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }
    const handleInput = (e) => {
        var id = e.target.id;
        var value = e.target.value;
        if (id === "firstName") {
            var firstNameValue = e.target.value.replace(/[^a-z .]/gi, "")
            e.target.value = firstNameValue;
        }
        if (id === "lastName") {
            var lastNameValue = e.target.value.replace(/[^a-z ]/gi, "")
            e.target.value = lastNameValue;
        }
        if (id === "state") {
            resetCityList(e);
        }
        if (id === "country") {
            resetStateList(e);
        }
        // if (id === "userName") {
        //     var newValue = e.target.value.replace(new RegExp(/ /g), "");
        //     newValue = newValue.replace(new RegExp(/[^a-zA-Z 0-9]+/g), "");
        //     e.target.value = newValue;
        //     userValidationCheck(e.target.value);
        // }
        if (id === "phoneNumber") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
        }
        if (id === "zipCode") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
        }
        if (id === "poBoxNo") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
        }
        let dataId = curPersonalId;

        const newPersonal = personalInfo.map(obj => {
            if (obj.userId == dataId) {
                return { ...obj, [id]: value };
            }
            return obj;
        });
        setCurPersonal([...newPersonal]);
        // setPersonalInfo({ ...personalInfo, [id]: value });
        checkValidation(e.target.id);
    }
    //validation 1 personal info
    const checkValidation = (id) => {
        if (id === "" || id === undefined) {
            return allcheckValidation()
        }
        else {
            return singlecheckValidation(id)
        }
    }
    //personal info single check validation
    const singlecheckValidation = (id) => {
        var flag = true;
        var single_flaglist = []

        var obj = {
            firstName: document.getElementById("firstName").value,
            lastName: document.getElementById("lastName").value,
            email: document.getElementById("email").value,
            // gender: document.getElementById("gender").value,
            // userName: document.getElementById("userName").value,
            country: document.getElementById("country").value,
            state: document.getElementById("state").value,
            city: document.getElementById("city").value,
            // zipCode: document.getElementById("zipCode").value,
            // nationality: document.getElementById("nationality").value,
            phoneNumber: document.getElementById("phoneNumber").value,
            address: document.getElementById("address").value,
            // linkedIn: document.getElementById("linkedIn").value
        }
        if (id == "firstName") {
            if (!obj.firstName.trim()) {
                $("#firstnameerror").show();
                $("#firstName").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#firstnameerror").hide();
                if ($("#firstName").hasClass('error-shadow'))
                    $("#firstName").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "lastName") {
            if (!obj.lastName.trim()) {
                $("#lastnameerror").show();
                $("#lastName").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#lastnameerror").hide();
                if ($("#lastName").hasClass('error-shadow'))
                    $("#lastName").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);

            }
        }

        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (id == "email") {
            if (!obj.email.trim()) {
                $("#emailerror").show();
                $("#email").addClass("error-shadow");
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else if (!regex.test(obj.email)) {
                $("#emailerror").show();
                $("#emailerror").text("Please enter a valid email address");
                $("#email").addClass("error-shadow");
                flag = false;
                single_flaglist.push(flag);
                return;
            } else {
                $("#emailerror").hide();
                if ($("#email").hasClass('error-shadow'))
                    $("#email").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        // if (id == "gender") {

        //     if (obj.gender == 0) {
        //         $("#gendererror").show();
        //         $("#gender").addClass("error-shadow")
        //         flag = false;
        //         single_flaglist.push(flag);
        //         return;
        //     }
        //     else {
        //         $("#gendererror").hide();
        //         if ($("#gender").hasClass('error-shadow'))
        //             $("#gender").removeClass('error-shadow');
        //         flag = true;
        //         single_flaglist.push(flag);
        //     }
        // }
        // if (id == "userName") {


        //     if (!obj.userName.trim()) {
        //         $("#existingUser").hide();
        //         $("#usernameerror").show();
        //         flag = false;
        //         single_flaglist.push(flag);
        //         return;

        //     }
        //     else {
        //         flag = userExists
        //         if (flag === true) {
        //             $("#userName").addClass("error-shadow");
        //             flag = false
        //             single_flaglist.push(flag);
        //             return;
        //         }
        //         else {
        //             flag = true;
        //             single_flaglist.push(flag);
        //             if ($("#userName").hasClass('error-shadow'))
        //                 $("#userName").removeClass('error-shadow');
        //         }
        //         $("#usernameerror").hide();
        //     }
        // }
        if (id == "country") {
            if (obj.country == 0) {
                $("#countryerror").show();
                $("#country").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#countryerror").hide();
                if ($("#country").hasClass('error-shadow'))
                    $("#country").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "state") {
            if (obj.state == 0) {
                $("#stateerror").show();
                $("#state").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#stateerror").hide();
                if ($("#state").hasClass('error-shadow'))
                    $("#state").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "city") {
            if (obj.city == 0) {
                $("#cityerror").show();
                $("#city").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#cityerror").hide();
                if ($("#city").hasClass('error-shadow'))
                    $("#city").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        // if (id == "zipCode") {
        //     if (!obj.zipCode.trim()) {
        //         $("#zipcodeerror").show();
        //         $("#zipCode").addClass("error-shadow")
        //         flag = false;
        //         single_flaglist.push(flag);
        //         return;
        //     }
        //     else {
        //         $("#zipcodeerror").hide();
        //         if ($("#zipCode").hasClass('error-shadow'))
        //             $("#zipCode").removeClass('error-shadow');
        //         flag = true;
        //         single_flaglist.push(flag);
        //     }
        // }
        // if (id == "nationality") {
        //     if (obj.nationality == 0) {
        //         $("#nationalityerror").show();
        //         $("#nationality").addClass("error-shadow")
        //         flag = false;
        //         single_flaglist.push(flag);
        //         return;
        //     }
        //     else {
        //         $("#nationalityerror").hide();
        //         if ($("#nationality").hasClass('error-shadow'))
        //             $("#nationality").removeClass('error-shadow');
        //         flag = true;
        //         single_flaglist.push(flag);
        //     }
        // }
        const phoneregex = /^\d{10}$/;

        if (id == "phoneNumber") {
            if (!obj.phoneNumber.trim()) {
                $("#phonenumbererror").show();
                $("#phoneNumber").addClass("error-shadow");

                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else if (!phoneregex.test(obj.phoneNumber)) {
                $("#phonenumbererror").show();
                $("#phonenumbererror").text("Please enter the 10 digit phone number");
                $("#phoneNumber").addClass("error-shadow");
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#phonenumbererror").hide();
                if ($("#phoneNumber").hasClass('error-shadow'))
                    $("#phoneNumber").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }

        }
        if (id == "address") {
            if (!obj.address.trim()) {
                $("#addresserror").show();
                $("#address").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                flag = true;
                single_flaglist.push(flag);
                if ($("#address").hasClass('error-shadow'))
                    $("#address").removeClass('error-shadow');
                $("#addresserror").hide();
            }
        }
        // if (!obj.linkedIn) {
        //     $("#linkedinerror").show();
        //     flag = false;
        // }
        // else {
        //     flag = true;
        //     $("#linkedinerror").hide();
        // }
        return single_flaglist;
    }

    //personal info all check validation
    const allcheckValidation = () => {

        var flag = true;
        var flaglist = []
        var obj = {
            firstName: document.getElementById("firstName").value,
            lastName: document.getElementById("lastName").value,
            email: document.getElementById("email").value,
            // gender: document.getElementById("gender").value,
            // userName: document.getElementById("userName").value,
            country: document.getElementById("country").value,
            state: document.getElementById("state").value,
            city: document.getElementById("city").value,
            // zipCode: document.getElementById("zipCode").value,
            // nationality: document.getElementById("nationality").value,
            phoneNumber: document.getElementById("phoneNumber").value,
            address: document.getElementById("address").value,
            // linkedIn: document.getElementById("linkedIn").value
        }
        if (!obj.firstName.trim()) {
            $("#firstnameerror").show();
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#firstnameerror").hide();
            flag = true;
            flaglist.push(flag);

        }
        if (!obj.lastName.trim()) {
            $("#lastnameerror").show();
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#lastnameerror").hide();
            flag = true;
            flaglist.push(flag);

        }
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!obj.email.trim()) {
            $("#emailerror").show();
            flag = false;
            flaglist.push(flag);

        }
        else if (!regex.test(obj.email)) {
            $("#emailerror").show();
            $("#emailerror").text("Please enter a valid email address");
            flag = false;
            flaglist.push(flag);

        } else {
            $("#emailerror").hide();
            flag = true;
            flaglist.push(flag);

        }
        // if (obj.gender == 0) {
        //     $("#gendererror").show();
        //     flag = false;
        //     flaglist.push(flag);

        // }
        // else {
        //     $("#gendererror").hide();
        //     flag = true;
        //     flaglist.push(flag);

        // }
        // if (!obj.userName.trim()) {
        //     $("#existingUser").hide();
        //     $("#usernameerror").show();
        //     flag = false;
        //     flaglist.push(flag)
        // }
        // else {
        //     flag = userExists
        //     if (flag === true) {
        //         $("#userName").addClass("error-shadow");
        //         flag = false
        //         flaglist.push(flag);
        //     }
        //     else {
        //         flag = true;
        //         flaglist.push(flag);
        //         if ($("#userName").hasClass('error-shadow'))
        //             $("#userName").removeClass('error-shadow');
        //     }
        //     $("#usernameerror").hide();
        // }
        if (obj.country == 0) {
            $("#countryerror").show();
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#countryerror").hide();
            flag = true;
            flaglist.push(flag);

        }
        if (obj.state == 0) {
            $("#stateerror").show();
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#stateerror").hide();
            flag = true;
            flaglist.push(flag);

        }
        if (obj.city == 0) {
            $("#cityerror").show();
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#cityerror").hide();
            flag = true;
            flaglist.push(flag);

        }
        // if (!obj.zipCode.trim()) {
        //     $("#zipcodeerror").show();
        //     flag = false;
        //     flaglist.push(flag);

        // }
        // else {
        //     $("#zipcodeerror").hide();
        //     flag = true;
        //     flaglist.push(flag);

        // }
        // if (obj.nationality == 0) {
        //     $("#nationalityerror").show();
        //     flag = false;
        //     flaglist.push(flag);

        // }
        // else {
        //     $("#nationalityerror").hide();
        //     flag = true;
        //     flaglist.push(flag);

        // }
        if (!obj.phoneNumber.trim()) {
            $("#phonenumbererror").show();
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#phonenumbererror").hide();
            flag = true;
            flaglist.push(flag);

        }
        if (!obj.address.trim()) {
            $("#addresserror").show();
            flag = false;
            flaglist.push(flag);

        }
        else {
            flag = true;
            $("#addresserror").hide();
            flaglist.push(flag);

        }
        // if (!obj.linkedIn) {
        //     $("#linkedinerror").show();
        //     flag = false;
        // }
        // else {
        //     flag = true;
        //     $("#linkedinerror").hide();
        // }
        return flaglist;
    }
    useEffect(() => {
        let editPersonalIndex = personalInfo.findIndex((obj => obj.userId == curPersonalId));
        if (editPersonalIndex != -1) {
            let editPersonalObj = personalInfo[editPersonalIndex];
            //editPersonalObj.userName = editPersonalObj.userName.split('@')[0];    
            var newStateList = statesMeta.filter((ele) => ele.countryId == editPersonalObj.country);
            setCurStatesMeta([...newStateList]);
            var newCityList = citiesMeta.filter((ele) => ele.stateId == editPersonalObj.state);
            setCurCityList([...newCityList]);
            setCurPersonal({ ...editPersonalObj })
        }
    }, [curPersonalId]);


    const handleSubmitPersonalInfo = (e) => {
        e.preventDefault();
        let obj = {
            firstName: document.getElementById('firstName').value,
            lastName: document.getElementById('lastName').value,
            email: document.getElementById('email').value,
            gender: document.getElementById('gender').value,
            // userName: document.getElementById('userName').value,
            country: document.getElementById('country').value,
            state: document.getElementById('state').value,
            city: document.getElementById('city').value,
            nationality: document.getElementById('nationality').value,
            phoneNumber: document.getElementById('phoneNumber').value,
            address: document.getElementById('address').value,
            zipCode: document.getElementById('zipCode').value,
            userId: curPersonalId,
            entityId: document.getElementById('firstName').value,
            roleId: document.getElementById('firstName').value,
            poBoxNo: document.getElementById('poBoxNo').value,
        }
        var flaglist = allcheckValidation();
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;
        if (flag) {
            // setEditPersonalInfo({obj});
            // const arr=[];
            // arr.push(obj);
            // setEditPersonalInfo([...arr])
            UpdateCXOPersonalInfo(obj);
            $("#Personalprofile .close").trigger("click");
            return flag;
        }
        else {
            return flag;
        }

        // console.log(editprofileInfo);

    }

    const addExperience = () => {
        let obj = {
            // id: editexperienceInfo.length + 1,
            experienceSkillId: document.getElementById('experienceSkill').value,
            experienceSoftwareVersionId: document.getElementById('experienceSoftwareVersion').value,
            // experienceSkill: document.getElementById('experienceSkill').value,
            // experienceSoftwareVersion: document.getElementById('experienceSoftwareVersion').value,
            experienceLastUsed: document.getElementById('experienceLastUsed').value,
            experienceYears: document.getElementById('experienceYears').value,
            // experienceMonths: document.getElementById('experienceMonths').value,
            experienceImplementationSize: document.getElementById('experienceImplementationSize').value,
            experienceLevel: document.getElementById('experienceLevel').value,
            experienceLevelName: $("#experienceLevel option:selected").text(),
            userId: sessionStorage.getItem("userId"),
            cxoexpStatus: 1,
        }
        var flaglist = allskillcheckValidation(obj);
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;
        if (flag) {
            const arr = [];
            arr.push(obj);
            UpdateCXOExperienceInfo(arr);
            setEditExperienceInfo([...editexperienceInfo, obj]);
            document.getElementById("addExperienceForm").reset();
            $("#AddExperience .close").trigger('click')
        }
    }
    const allskillcheckValidation = (obj) => {
        var flag = true;
        var flaglist = []
        if (obj.experienceSkillId == 0) {
            $("#skillNameerror").show();
            $("#experienceSkill").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#skillNameerror").hide();
            if ($("#experienceSkill").hasClass('error-shadow'))
                $("#experienceSkill").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (obj.experienceSoftwareVersionId == 0) {
            $("#softwareVererror").show();
            $("#experienceSoftwareVersion").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#softwareVererror").hide();
            if ($("#experienceSoftwareVersion").hasClass('error-shadow'))
                $("#experienceSoftwareVersion").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (obj.experienceLastUsed == 0) {
            $("#experienceLastUsederror").show();
            $("#experienceLastUsed").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#experienceLastUsederror").hide();
            if ($("#experienceLastUsed").hasClass('error-shadow'))
                $("#experienceLastUsed").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (obj.experienceYears == 0) {
            $("#experienceYearserror").show();
            $("#experienceYears").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#experienceYearserror").hide();
            if ($("#experienceYears").hasClass('error-shadow'))
                $("#experienceYears").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        // if (obj.experienceImplementationSize == 0) {
        //     $("#experienceImplementationSizeerror").show();
        //     $("#experienceImplementationSize").addClass("error-shadow")
        //     flag = false;
        //     flaglist.push(flag);

        // }
        // else {
        //     $("#experienceImplementationSizeerror").hide();
        //     if ($("#experienceImplementationSize").hasClass('error-shadow'))
        //         $("#experienceImplementationSize").removeClass('error-shadow');
        //     flag = true;
        //     flaglist.push(flag);
        // }
        if (obj.experienceLevel == 0) {
            $("#experienceLevelerror").show();
            $("#experienceLevel").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#experienceLevelerror").hide();
            if ($("#experienceLevel").hasClass('error-shadow'))
                $("#experienceLevel").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        return flaglist;
    }

    const handleexpdelete = (id) => {
        // const deleted = experienceInfo.filter((exp) => exp.id !== id);
        const deleted = experienceInfo.filter((exp) => exp.id == id);
        // deleted[0].status = 0;
        const data = experienceInfo.map(obj => {
            if (obj.id == id) {
                obj.cxoexpStatus = "0";
            }
            return obj;
        });
        const delexp = data.filter(obj => obj.id == id)

        console.log(delexp);
        UpdateCXOExperienceInfo(delexp);
    }

    const editExperience = (e) => {
        e.preventDefault();
        let dataFor = e.target.getAttribute("data-for");
        let dataId = curExpId;
        let value = e.target.value;
        if (dataFor === "experienceSkillId") {
            resetversionList(e);
            resetskillversion(value);
        }
        editskillvalidation(e);
        const newExperience = experienceInfo.map(obj => {
            if (obj.id == dataId) {
                obj.cxoexpStatus = 1;
                return { ...obj, [dataFor]: value };
            }
            return obj;
        });
        const updateexp = newExperience.filter(media => media.id == dataId)
        // setUpdateExperienceInfo([...updateexperienceInfo,updateexp]);
        // setUpdateExperienceInfo([...updateexp]);
        // setUpdateExperienceInfo(...updateexperienceInfo,updateexp);
        //setExperienceInfo([...newExperience]);
        setCurExp([...newExperience]);
        if (JSON.stringify(experienceInfo) !== "{}") {
            $("#viewExperienceDiv .row").show();
        }
        if (dataFor === "experienceLevel") {
            editExperinceFix(value);
        }
    }
    const editsaveskills = (e) => {
        e.preventDefault();
        console.log(updateexperienceInfo);
        let obj = {
            id: curExpId,
            experienceSkillId: $("._experienceSkillId").val(),
            experienceSoftwareVersionId: $("._experienceSoftwareVersionId").val(),
            // experienceSkill: document.getElementById('experienceSkill').value,
            // experienceSoftwareVersion: document.getElementById('experienceSoftwareVersion').value,
            experienceLastUsed: $("._experienceLastUsed").val(),
            experienceYears: $("._experienceYears").val(),
            // experienceMonths: document.getElementById('experienceMonths').value,
            experienceImplementationSize: $("._experienceImplementationSize").val(),
            experienceLevel: $("._experienceLevel").val(),
            //experienceLevelName: $(".").val()
            userId: sessionStorage.getItem("userId"),
            cxoexpStatus: 1,

        }
        console.log(obj);
        // var flag =allskillcheckValidation(obj);
        var flag = true;
        var flaglist = []
        if (obj.experienceSkillId == 0) {
            $("#_skillNameerror").show();
            $("#experienceSkill").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_skillNameerror").hide();
            if ($("#experienceSkill").hasClass('error-shadow'))
                $("#experienceSkill").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (obj.experienceSoftwareVersionId == 0) {
            $("#_softwareVererror").show();
            $("#experienceSoftwareVersion").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_softwareVererror").hide();
            if ($("#experienceSoftwareVersion").hasClass('error-shadow'))
                $("#experienceSoftwareVersion").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (obj.experienceLastUsed == 0) {
            $("#_experienceLastUsederror").show();
            $("#experienceLastUsed").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_experienceLastUsederror").hide();
            if ($("#experienceLastUsed").hasClass('error-shadow'))
                $("#experienceLastUsed").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (obj.experienceYears == 0) {
            $("#_experienceYearserror").show();
            $("#experienceYears").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_experienceYearserror").hide();
            if ($("#experienceYears").hasClass('error-shadow'))
                $("#experienceYears").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        // if (obj.experienceImplementationSize == 0) {
        //     $("#_experienceImplementationSizeerror").show();
        //     $("#experienceImplementationSize").addClass("error-shadow")
        //     flag = false;
        //     flaglist.push(flag);

        // }
        // else {
        //     $("#_experienceImplementationSizeerror").hide();
        //     if ($("#experienceImplementationSize").hasClass('error-shadow'))
        //         $("#experienceImplementationSize").removeClass('error-shadow');
        //     flag = true;
        //     flaglist.push(flag);
        // }
        if (obj.experienceLevel == 0) {
            $("#_experienceLevelerror").show();
            $("#experienceLevel").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_experienceLevelerror").hide();
            if ($("#experienceLevel").hasClass('error-shadow'))
                $("#experienceLevel").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        // return flaglist;
        // var flaglist = allskillcheckValidation(obj);
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;
        if (flag) {
            const arr = [];
            arr.push(obj);
            UpdateCXOExperienceInfo(arr);
            $("#EditExperience .close").trigger('click')
        }
    }
    const editExperinceFix = (data) => {
        let dataFor = "experienceLevelName";
        let dataId = curExpId;
        let value = skillexperiencelevelsMeta.filter((ele) => ele.skillExperienceId == data)[0].skillExperienceLevel;
        const newExperience = experienceInfo.map(obj => {
            if (obj.id == dataId) {
                return { ...obj, [dataFor]: value };
            }
            return obj;
        });
        setEditExperienceInfo([...newExperience]);
        setCurExp([...newExperience]);
        if (JSON.stringify(experienceInfo) !== "{}") {
            $("#viewExperienceDiv .row").show();
        }
    }
    const resetversionList = (e) => {
        e.preventDefault();
        var id = e.target.value;
        let idName = e.target.getAttribute("data-for");
        var newskillversionList = skillversionMeta.filter((ele) => ele.skillId == id);
        setCurSkillversionList([...newskillversionList]);
        if (idName == "experienceSkillId") {
            if (id == 0) {
                $("#_skillNameerror").show();
                $("#experienceSkill").addClass("error-shadow")

            }
            else {
                $("#_skillNameerror").hide();
                if ($("#experienceSkill").hasClass('error-shadow'))
                    $("#experienceSkill").removeClass('error-shadow');

            }
        }
        else {
            if (id == 0) {
                $("#skillNameerror").show();
                $("#experienceSkill").addClass("error-shadow")

            }
            else {
                $("#skillNameerror").hide();
                if ($("#experienceSkill").hasClass('error-shadow'))
                    $("#experienceSkill").removeClass('error-shadow');

            }
        }
    }

    const resetskillversion = (id) => {
        // var id = e.target.value;
        var newskillversionList = skillversionMeta.filter((ele) => ele.skillId == id);
        setEditSkillversionList([...newskillversionList]);
    }
    const skillvalidation = (e) => {
        e.preventDefault();
        let id = e.target.id;
        let value = e.target.value;
        if (id === "experienceImplementationSize") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
        }
        if (id == "experienceSoftwareVersion") {
            if (value == 0) {
                $("#softwareVererror").show();
                $("#experienceSoftwareVersion").addClass("error-shadow")

            }
            else {
                $("#softwareVererror").hide();
                if ($("#experienceSoftwareVersion").hasClass('error-shadow'))
                    $("#experienceSoftwareVersion").removeClass('error-shadow');
            }
        }
        if (id == "experienceLastUsed") {
            if (value == 0) {
                $("#experienceLastUsederror").show();
                $("#experienceLastUsed").addClass("error-shadow")

            }
            else {
                $("#experienceLastUsederror").hide();
                if ($("#experienceLastUsed").hasClass('error-shadow'))
                    $("#experienceLastUsed").removeClass('error-shadow');
            }
        }
        if (id == "experienceYears") {
            if (value == 0) {
                $("#experienceYearserror").show();
                $("#experienceYears").addClass("error-shadow")

            }
            else {
                $("#experienceYearserror").hide();
                if ($("#experienceYears").hasClass('error-shadow'))
                    $("#experienceYears").removeClass('error-shadow');
            }
        }
        // if (id == "experienceImplementationSize") {
        //     if (value == 0) {
        //         $("#experienceImplementationSizeerror").show();
        //         $("#experienceImplementationSize").addClass("error-shadow")

        //     }
        //     else {
        //         $("#experienceImplementationSizeerror").hide();
        //         if ($("#experienceImplementationSize").hasClass('error-shadow'))
        //             $("#experienceImplementationSize").removeClass('error-shadow');
        //     }
        // }
        if (id == "experienceLevel") {
            if (value == 0) {
                $("#experienceLevelerror").show();
                $("#experienceLevel").addClass("error-shadow")

            }
            else {
                $("#experienceLevelerror").hide();
                if ($("#experienceLevel").hasClass('error-shadow'))
                    $("#experienceLevel").removeClass('error-shadow');
            }
        }
    }
    const editskillvalidation = (e) => {
        e.preventDefault();
        let id = e.target.getAttribute("data-for");
        let value = e.target.value;
        var flag;
        if (id === "experienceImplementationSize") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
        }
        if (id == "experienceSoftwareVersionId") {
            if (value == 0) {
                $("#_softwareVererror").show();
                $("#experienceSoftwareVersion").addClass("error-shadow")
                flag = false;
                return flag;

            }
            else {
                $("#_softwareVererror").hide();
                if ($("#experienceSoftwareVersion").hasClass('error-shadow'))
                    $("#experienceSoftwareVersion").removeClass('error-shadow');
                flag = true;
                return flag;
            }
        }
        if (id == "experienceLastUsed") {
            if (value == 0) {
                $("#_experienceLastUsederror").show();
                $("#experienceLastUsed").addClass("error-shadow")
                flag = false;
                return flag;
            }
            else {
                $("#_experienceLastUsederror").hide();
                if ($("#experienceLastUsed").hasClass('error-shadow'))
                    $("#experienceLastUsed").removeClass('error-shadow');
                flag = true;
                return flag;
            }
        }
        if (id == "experienceYears") {
            if (value == 0) {
                $("#_experienceYearserror").show();
                $("#experienceYears").addClass("error-shadow")
                flag = false;
                return flag;
            }
            else {
                $("#_experienceYearserror").hide();
                if ($("#experienceYears").hasClass('error-shadow'))
                    $("#experienceYears").removeClass('error-shadow');
                flag = true;
                return flag;
            }
        }
        // if (id == "experienceImplementationSize") {
        //     if (value == 0) {
        //         $("#_experienceImplementationSizeerror").show();
        //         $("#experienceImplementationSize").addClass("error-shadow")
        //         flag = false;
        //         return flag;
        //     }
        //     else {
        //         $("#_experienceImplementationSizeerror").hide();
        //         if ($("#experienceImplementationSize").hasClass('error-shadow'))
        //             $("#experienceImplementationSize").removeClass('error-shadow');
        //         flag = true;
        //         return flag;
        //     }
        // }
        if (id == "experienceLevel") {
            if (value == 0) {
                $("#_experienceLevelerror").show();
                $("#experienceLevel").addClass("error-shadow")
                flag = false;
                return flag;
            }
            else {
                $("#_experienceLevelerror").hide();
                if ($("#experienceLevel").hasClass('error-shadow'))
                    $("#experienceLevel").removeClass('error-shadow');
                flag = true;
                return flag;
            }
        }
    }
    useEffect(() => {
        let editExpIndex = experienceInfo.findIndex((obj => obj.id == curExpId));
        if (editExpIndex != -1) {
            let editExpObj = experienceInfo[editExpIndex];
            resetskillversion(editExpObj.experienceSkillId);
            setCurExp({ ...editExpObj })
        }
    }, [curExpId]);


    const handleemploymentdelete = (id) => {
        // const deleted = employmentInfo.filter((exp) => exp.id !== id);
        const deleted = employmentInfo.filter((exp) => exp.id == id);
        const data = employmentInfo.map(obj => {
            if (obj.id == id) {
                obj.cxoempStatus = "0";
            }
            return obj;
        });
        const delemp = data.filter(obj => obj.id == id)
        // setEmploymentInfo(deleted);
        console.log(delemp);
        UpdateCXOEmploymentInfo(delemp);
    }
    useEffect(() => {
        let editEmpIndex = employmentInfo.findIndex((obj => obj.id == curEmpId));
        if (editEmpIndex != -1) {
            let editEmpObj = employmentInfo[editEmpIndex];
            var newStateList = statesMeta.filter((ele) => ele.countryId == editEmpObj.employmentCountry);
            setCurStatesMeta([...newStateList]);
            var newCityList = citiesMeta.filter((ele) => ele.stateId == editEmpObj.employmentState);
            setCurCityList([...newCityList]);
            setCurEmp({ ...editEmpObj })
        }
        let editEmpSkillIndex = employmentskillInfo.findIndex((obj => obj.userEmploymentId == curEmpId));
        if (editEmpSkillIndex != -1) {
            let editEmpSkillObj = employmentskillInfo.filter((exp) => exp.userEmploymentId == curEmpId);
            setCurEmpSkill(editEmpSkillObj)
        }



    }, [curEmpId]);
    useEffect(() => {
        let editEmpSkillIndex = employmentskillInfo.findIndex((obj => obj.userEmploymentId == curEmpId));
        if (editEmpSkillIndex != -1) {
            let editEmpSkillObj = employmentskillInfo.filter((exp) => exp.userEmploymentId == curEmpId);
            setCurEmpSkill(editEmpSkillObj)
        }
    }, [curcheckId]);

    const showempSkilledit = (empskill) => {
        let editEmpSkillIndex = empskill.findIndex((obj => obj.userEmploymentId == curEmpId));
        if (editEmpSkillIndex != -1) {
            let editEmpSkillObj = empskill.filter((exp) => exp.userEmploymentId == curEmpId);
            setCurEmpSkill(editEmpSkillObj)
        }
        else {
            setCurEmpSkill([]);
        }
    }


    const editEmployment = (e) => {

        let dataFor = e.target.getAttribute("data-for");
        let dataId = curEmpId;
        let value = e.target.value;
        if (dataFor === "employmentState") {
            resetCityList(e);
        }

        if (dataFor === "employmentCountry") {
            resetStateList(e);
        }
        editEmploymentvalidation(e);
        const newEmployment = employmentInfo.map(obj => {
            if (obj.id == dataId) {
                return { ...obj, [dataFor]: value };
            }
            return obj;
        });

        // setEmploymentInfo([...newEmployment]);

        setCurEmp([...newEmployment]);
    }
    const editEmploymentvalidation = (e) => {
        e.preventDefault();
        let id = e.target.getAttribute("data-for");
        let value = e.target.value;
        if (id === "employmentYears") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
        }
        if (id === "employmentCompany") {
            if (value == "" || value == null || value == undefined) {

                $("#_employmentCompanyerror").show();
                $("._employmentCompany").addClass("error-shadow")

            }
            else {
                $("#_employmentCompanyerror").hide();
                if ($("._employmentCompany").hasClass('error-shadow'))
                    $("._employmentCompany").removeClass('error-shadow');
            }
        }
        if (id === "employmentDesignation") {
            if (value == "" || value == null || value == undefined) {

                $("#_employmentDesignationerror").show();
                $("#_employmentDesignation").addClass("error-shadow")

            }
            else {
                $("#_employmentDesignationerror").hide();
                if ($("#_employmentDesignation").hasClass('error-shadow'))
                    $("#_employmentDesignation").removeClass('error-shadow');
            }
        }

        if (id === "employmentYears") {
            if (value == "" || value == null || value == undefined) {
                $("#_employmentYearserror").show();
                $("#employmentYears").addClass("error-shadow")

            }
            else {
                $("#_employmentYearserror").hide();
                if ($("#_employmentYears").hasClass('error-shadow'))
                    $("#_employmentYears").removeClass('error-shadow');
            }
        }

        if (id === "employmentSkills") {
            if (value == "" || value == null || value == undefined) {
                $("#_employmentSkillserror").show();
                $("#_employmentSkills").addClass("error-shadow")

            }
            else {
                $("#_employmentSkillserror").hide();
                if ($("#_employmentSkills").hasClass('error-shadow'))
                    $("#_employmentSkills").removeClass('error-shadow');
            }
        }
    }
    const editsavesemployment = (e) => {
        e.preventDefault();
        let obj = {
            id: curEmpId,
            employmentCompany: $("._employmentCompany").val(),
            employmentCountry: $("._employmentCountry").val(),
            employmentState: $("._employmentState").val(),
            employmentCity: $("._employmentCity").val(),
            employmentDesignation: $("._employmentDesignation").val(),
            employmentYears: $("._employmentYears").val(),
            employmentSkills: $("._employmentSkills").val(),
            userId: sessionStorage.getItem("userId"),
            cxoempStatus: 1,
        }
        var flag = true;
        var flaglist = []
        if (!obj.employmentCompany) {
            $("#_employmentCompanyerror").show();
            $("#_employmentCompany").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#_employmentCompanyerror").hide();
            if ($("#_employmentCompany").hasClass('error-shadow'))
                $("#_employmentCompany").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (obj.employmentCountry == "0") {
            $("#_employmentCountryerror").show();
            $("#_employmentCountry").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#_employmentCountryerror").hide();
            if ($("#_employmentCountry").hasClass('error-shadow'))
                $("#_employmentCountry").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (obj.employmentState == "0") {
            $("#_employmentStateerror").show();
            $("#_employmentState").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#_employmentStateerror").hide();
            if ($("#_employmentState").hasClass('error-shadow'))
                $("#_employmentState").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (obj.employmentCity == "0") {
            $("#_employmentCityerror").show();
            $("#_employmentCity").addClass("error-shadow")

            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#_employmentCityerror").hide();
            if ($("#_employmentCity").hasClass('error-shadow'))
                $("#_employmentCity").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (!obj.employmentDesignation) {
            $("#_employmentDesignationerror").show();
            $("#_employmentDesignation").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#_employmentDesignationerror").hide();
            if ($("#_employmentDesignation").hasClass('error-shadow'))
                $("#_employmentDesignation").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (!obj.employmentYears) {
            $("#_employmentYearserror").show();
            $("#_employmentYears").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#_employmentYearserror").hide();
            if ($("#_employmentYears").hasClass('error-shadow'))
                $("#_employmentYears").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        // if (!obj.employmentSkills) {
        //     $("#_employmentSkillserror").show();
        //     $("#_employmentSkills").addClass("error-shadow")

        //     flag = false;
        //     flaglist.push(flag);
        // }
        // else {
        //     $("#_employmentSkillserror").hide();
        //     if ($("#_employmentSkills").hasClass('error-shadow'))
        //         $("#_employmentSkills").removeClass('error-shadow');
        //     flag = true;
        //     flaglist.push(flag);
        // }
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;

        if (flag) {
            const arremp = [];
            arremp.push(obj);
            UpdateCXOEmploymentInfo(arremp);
            document.getElementById("addEmploymentForm").reset();
            $("#EditEmployment .close").trigger('click')
        }

    }
    const addEmployment = () => {

        let obj = {
            // id: employmentInfo.length + 1,
            employmentCompany: document.getElementById('employmentCompany').value,
            employmentCountry: document.getElementById('employmentCountry').value,
            employmentState: document.getElementById('employmentState').value,
            employmentCity: document.getElementById('employmentCity').value,
            employmentDesignation: document.getElementById('employmentDesignation').value,
            employmentYears: document.getElementById('employmentYears').value,
            // employmentSkillId: document.getElementById('employmentSkills').value,
            employmentSkills: document.getElementById('employmentSkills').value,
            userId: sessionStorage.getItem("userId"),
            cxoempStatus: 1,
        }
        var flaglist = allcheckEmploymentValidation();
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;

        if (flag) {
            const arraddemp = [];
            arraddemp.push(obj);
            UpdateCXOEmploymentInfo(arraddemp);
            // setEmploymentInfo([...employmentInfo, obj]);
            $("#viewEmploymentDiv").removeClass("hide");
            $("#employmentImg").hide();
            document.getElementById("addEmploymentForm").reset();

            $("#AddEmployment .close").trigger('click')
        }
    }

    const allcheckEmploymentValidation = () => {

        var flag = true;
        var flaglist = []
        let obj = {
            id: employmentInfo.length + 1,
            employmentCompany: document.getElementById('employmentCompany').value,
            employmentCountry: document.getElementById('employmentCountry').value,
            employmentState: document.getElementById('employmentState').value,
            employmentCity: document.getElementById('employmentCity').value,
            employmentDesignation: document.getElementById('employmentDesignation').value,
            employmentYears: document.getElementById('employmentYears').value,
            // employmentSkillId: document.getElementById('employmentSkills').value,
            employmentSkills: document.getElementById('employmentSkills').value
        }
        if (!obj.employmentCompany) {
            $("#employmentCompanyerror").show();
            $("#employmentCompany").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#employmentCompanyerror").hide();
            if ($("#employmentCompany").hasClass('error-shadow'))
                $("#employmentCompany").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (obj.employmentCountry == "0") {
            $("#employmentCountryerror").show();
            $("#employmentCountry").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#employmentCountryerror").hide();
            if ($("#employmentCountry").hasClass('error-shadow'))
                $("#employmentCountry").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (obj.employmentState == "0") {
            $("#employmentStateerror").show();
            $("#employmentState").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#employmentStateerror").hide();
            if ($("#employmentState").hasClass('error-shadow'))
                $("#employmentState").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (obj.employmentCity == "0") {
            $("#employmentCityerror").show();
            $("#employmentCity").addClass("error-shadow")

            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#employmentCityerror").hide();
            if ($("#employmentCity").hasClass('error-shadow'))
                $("#employmentCity").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (!obj.employmentDesignation) {
            $("#employmentDesignationerror").show();
            $("#employmentDesignation").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#employmentDesignationerror").hide();
            if ($("#employmentDesignation").hasClass('error-shadow'))
                $("#employmentDesignation").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (!obj.employmentYears) {
            $("#employmentYearserror").show();
            $("#employmentYears").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#employmentYearserror").hide();
            if ($("#employmentYears").hasClass('error-shadow'))
                $("#employmentYears").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (!obj.employmentSkills) {
            $("#employmentSkillserror").show();
            $("#employmentSkills").addClass("error-shadow")

            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#employmentSkillserror").hide();
            if ($("#employmentSkills").hasClass('error-shadow'))
                $("#employmentSkills").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        return flaglist;
    }

    const employmentValidation = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        if (id === "employmentYears") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
        }
        if (id === "employmentCompany") {
            if (value == "" || value == null || value == undefined) {

                $("#employmentCompanyerror").show();
                $("#employmentCompany").addClass("error-shadow")

            }
            else {
                $("#employmentCompanyerror").hide();
                if ($("#employmentCompany").hasClass('error-shadow'))
                    $("#employmentCompany").removeClass('error-shadow');
            }
        }
        if (id === "employmentDesignation") {
            if (value == "" || value == null || value == undefined) {

                $("#employmentDesignationerror").show();
                $("#employmentDesignation").addClass("error-shadow")

            }
            else {
                $("#employmentDesignationerror").hide();
                if ($("#employmentDesignation").hasClass('error-shadow'))
                    $("#employmentDesignation").removeClass('error-shadow');
            }
        }

        if (id === "employmentYears") {
            if (value == "" || value == null || value == undefined) {
                $("#employmentYearserror").show();
                $("#employmentYears").addClass("error-shadow")

            }
            else {
                $("#employmentYearserror").hide();
                if ($("#employmentYears").hasClass('error-shadow'))
                    $("#employmentYears").removeClass('error-shadow');
            }
        }

        if (id === "employmentSkills") {
            if (value == "" || value == null || value == undefined) {
                $("#employmentSkillserror").show();
                $("#employmentSkills").addClass("error-shadow")

            }
            else {
                $("#employmentSkillserror").hide();
                if ($("#employmentSkills").hasClass('error-shadow'))
                    $("#employmentSkills").removeClass('error-shadow');
            }
        }

    }
    const handleEmploymentLocation = (e) => {
        let id = e.target.id;
        let idName = e.target.getAttribute("data-for");
        if (id === "employmentState")
            resetCityList(e);
        if (id === "employmentCountry")
            resetStateList(e);
        if (idName === "addemploymentCountry") {
            if (id == 0) {
                $("#employmentCountryerror").show();
                $("#employmentCountry").addClass("error-shadow")

            }
            else {
                $("#employmentCountryerror").hide();
                if ($("#employmentCountry").hasClass('error-shadow'))
                    $("#employmentCountry").removeClass('error-shadow');

            }
        }
        if (idName === "addemploymentState") {
            if (id == 0) {
                $("#employmentStateerror").show();
                $("#employmentState").addClass("error-shadow")

            }
            else {
                $("#employmentStateerror").hide();
                if ($("#employmentState").hasClass('error-shadow'))
                    $("#employmentState").removeClass('error-shadow');

            }
        }
        if (idName === "addemploymentCity") {
            if (id == 0) {
                $("#employmentCityerror").show();
                $("#employmentCity").addClass("error-shadow")

            }
            else {
                $("#employmentCityerror").hide();
                if ($("#employmentCity").hasClass('error-shadow'))
                    $("#employmentCity").removeClass('error-shadow');

            }
        }

    }




    useEffect(() => {
        let editEduIndex = educationInfo.findIndex((obj => obj.id == curEduId));
        if (editEduIndex != -1) {
            let editEduObj = educationInfo[editEduIndex];
            setCurEdu({ ...editEduObj })
        }
    }, [curEduId]);

    const handleeducationdelete = (id) => {
        // const deleted = educationInfo.filter((exp) => exp.id !== id);
        const deleted = educationInfo.filter((exp) => exp.id == id);
        const data = educationInfo.map(obj => {
            if (obj.id == id) {
                obj.cxoedustatus = "0";
            }
            return obj;
        });
        const deledu = data.filter(obj => obj.id == id)
        // setEmploymentInfo(deleted);
        console.log(deledu);
        UpdateCXOEducationInfo(deledu);
    }
    const editEducation = (e) => {
        let dataFor = e.target.getAttribute("data-for");
        let dataAffects = e.target.getAttribute("data-affects");
        let dataId = curEduId;
        let value = e.target.value;
        const newEducation = educationInfo.map(obj => {
            if (obj.id == dataId) {
                obj.educationName = $("#eduname option:selected").text();
                obj.educationCourseName = $("#educoursename option:selected").text();
                obj.educationInstituteName = $("#eduInstitutename option:selected").text();
                obj.educationCourseTypeName = $("#educoursetypename option:selected").text();
                return { ...obj, [dataFor]: value };
            }
            return obj;
        });
        editEducationvalidation(e);
        // setEducationInfo([...newEducation]);
        setCurEdu([...newEducation]);
    }
    const addEducation = () => {
        let obj = {
            // id: educationInfo.length + 1,
            educationId: document.getElementById('educationId').value,
            educationCourseId: document.getElementById('educationCourseId').value,
            educationInstituteId: document.getElementById('educationInstituteId').value,
            educationCourseTypeId: document.getElementById('educationCourseTypeId').value,
            educationCompletedYear: document.getElementById('educationCompletedYear').value,
            educationName: $("#educationId option:selected").text(),
            educationCourseName: $("#educationCourseId option:selected").text(),
            educationInstituteName: $("#educationInstituteId option:selected").text(),
            educationCourseTypeName: $("#educationCourseTypeId option:selected").text(),
            userId: sessionStorage.getItem("userId"),
            cxoedustatus: 1,
        }
        var flaglist = allcheckEducationvalidation();
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;
        if (flag) {
            const arreduadd = [];
            arreduadd.push(obj);
            UpdateCXOEducationInfo(arreduadd);
            // setEducationInfo([...educationInfo, obj]);
            $("#viewEducationDiv").removeClass("hide");
            $("#educationImg").hide();
            document.getElementById("addEducationForm").reset();
            $("#AddEducation .close").trigger('click');
        }
    }

    const educationvalid = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        if (id == "educationId") {
            if (value == "0") {
                $("#educationIderror").show();
                $("#educationId").addClass("error-shadow")
            }
            else {
                $("#educationIderror").hide();
                if ($("#educationId").hasClass('error-shadow'))
                    $("#educationId").removeClass('error-shadow');

            }
        }
        if (id == "educationCourseId") {
            if (value == "0") {
                $("#educationCourseIderror").show();
                $("#educationCourseId").addClass("error-shadow")
            }
            else {
                $("#educationCourseIderror").hide();
                if ($("#educationCourseId").hasClass('error-shadow'))
                    $("#educationCourseId").removeClass('error-shadow');
            }
        }
        if (id == "educationInstituteId") {
            if (value == "0") {
                $("#educationInstituteIderror").show();
                $("#educationInstituteId").addClass("error-shadow")
            }
            else {
                $("#educationInstituteIderror").hide();
                if ($("#educationInstituteId").hasClass('error-shadow'))
                    $("#educationInstituteId").removeClass('error-shadow');
            }
        }
        if (id == "educationCourseTypeId") {
            if (value == "0") {
                $("#educationCourseTypeIderror").show();
                $("#educationCourseTypeId").addClass("error-shadow")
            }
            else {
                $("#educationCourseTypeIderror").hide();
                if ($("#educationCourseTypeId").hasClass('error-shadow'))
                    $("#educationCourseTypeId").removeClass('error-shadow');
            }
        }
        if (id == "educationCompletedYear") {
            // if (value == "0") {
            //     $("#educationCompletedYearerror").show();
            //     $("#educationCompletedYear").addClass("error-shadow")
            // }
            // else {
            //     $("#educationCompletedYearerror").hide();
            //     if ($("#educationCourseTypeId").hasClass('error-shadow'))
            //         $("#educationCourseTypeId").removeClass('error-shadow');
            // }

            if (value == "") {
                $("#educationCompletedYearerror").show();
                $("#educationCompletedYear").addClass("error-shadow")
            }
            else if (value > 2022) {
                $("#educationCompletedYearerror").show();
                $("#educationCompletedYearerror").text("Year value should be less than 2022");
                $("#educationCompletedYear").addClass("error-shadow")
            }
            else if (value < 1950) {
                $("#educationCompletedYearerror").show();
                $("#educationCompletedYearerror").text("Year value should be greater than 1950");
                $("#educationCompletedYear").addClass("error-shadow")
            }
            else {
                $("#educationCompletedYearerror").hide();

                $("#educationCompletedYear").removeClass('error-shadow');
            }





        }

    }


    const allcheckEducationvalidation = () => {

        var flag = true;
        var flaglist = []
        var obj = {
            educationId: document.getElementById("educationId").value,
            educationCourseId: document.getElementById('educationCourseId').value,
            educationInstituteId: document.getElementById("educationInstituteId").value,
            educationCourseTypeId: document.getElementById("educationCourseTypeId").value,
            educationCompletedYear: document.getElementById('educationCompletedYear').value
        }


        if (obj.educationId == "0") {
            $("#educationIderror").show();
            $("#educationId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#educationIderror").hide();
            if ($("#educationId").hasClass('error-shadow'))
                $("#educationId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);

        }
        if (obj.educationCourseId == "0") {
            $("#educationCourseIderror").show();
            $("#educationCourseId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            if ($("#educationCourseId").hasClass('error-shadow'))
                $("#educationCourseId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);

        }
        if (obj.educationInstituteId == "0") {
            $("#educationInstituteIderror").show();
            $("#educationInstituteId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#educationInstituteIderror").hide();
            if ($("#educationInstituteId").hasClass('error-shadow'))
                $("#educationInstituteId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);

        }
        if (obj.educationCourseTypeId == "0") {
            $("#educationCourseTypeIderror").show();
            $("#educationCourseTypeId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#educationCourseTypeIderror").hide();
            if ($("#educationCourseTypeId").hasClass('error-shadow'))
                $("#educationCourseTypeId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (obj.educationCompletedYear == 0) {
            $("#educationCompletedYearerror").show();
            $("#educationCompletedYear").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else if (obj.educationCompletedYear > 2022) {
            $("#educationCompletedYearerror").show();
            $("#educationCompletedYearerror").text("Year value should be less than 2022");
            $(".educationCompletedYear").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else if (obj.educationCompletedYear < 1950) {
            $("#educationCompletedYearerror").show();
            $("#educationCompletedYearerror").text("Year value should be greater than 1950");
            $(".educationCompletedYear").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#educationCompletedYearerror").hide();
            if ($("#educationCompletedYearerror").hasClass('error-shadow'))
                $("#educationCompletedYear").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        return flaglist;
    }

    const editsavesEducation = (e) => {
        e.preventDefault();
        let obj = {
            id: curEduId,
            educationId: $("._educationId").val(),
            educationCourseId: $("._educationCourseId").val(),
            educationInstituteId: $("._educationInstituteId").val(),
            educationCourseTypeId: $("._educationCourseTypeId").val(),
            educationCompletedYear: $("._educationCompletedYear").val(),
            userId: sessionStorage.getItem("userId"),
            cxoedustatus: 1,
        }
        var flag = true;
        var flaglist = []
        if (obj.educationId == "0") {
            $("#_educationIderror").show();
            $("._educationId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#_educationIderror").hide();
            if ($("._educationId").hasClass('error-shadow'))
                $("._educationId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);

        }
        if (obj.educationCourseId == "0") {
            $("#_educationCourseIderror").show();
            $("._educationCourseId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_educationCourseIderror").hide();
            if ($("._educationCourseId").hasClass('error-shadow'))
                $("._educationCourseId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);

        }
        if (obj.educationInstituteId == "0") {
            $("#_educationInstituteIderror").show();
            $("._educationInstituteId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_educationInstituteIderror").hide();
            if ($("._educationInstituteId").hasClass('error-shadow'))
                $("._educationInstituteId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);

        }
        if (obj.educationCourseTypeId == "0") {
            $("#_educationCourseTypeIderror").show();
            $("._educationCourseTypeId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_educationCourseTypeIderror").hide();
            if ($("._educationCourseTypeId").hasClass('error-shadow'))
                $("._educationCourseTypeId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (obj.educationCompletedYear == 0) {
            $("#_educationCompletedYearerror").show();
            $("._educationCompletedYear").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else if (obj.educationCompletedYear > 2022) {
            $("#_educationCompletedYearerror").show();
            $("#_educationCompletedYearerror").text("Year value should be less than 2022");
            $("._educationCompletedYear").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else if (obj.educationCompletedYear < 1950) {
            $("#_educationCompletedYearerror").show();
            $("#_educationCompletedYearerror").text("Year value should be greater than 1950");
            $("._educationCompletedYear").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);
        }
        else {
            $("#_educationCompletedYearerror").hide();
            if ($("._educationCourseTypeId").hasClass('error-shadow'))
                $("._educationCourseTypeId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;

        if (flag) {
            const arredusave = [];
            arredusave.push(obj);
            UpdateCXOEducationInfo(arredusave);
            //setEducationInfo([...educationInfo, obj]);            
            document.getElementById("addEducationForm").reset();
            $("#EditEducation .close").trigger('click');
        }

    }
    const editEducationvalidation = (e) => {
        e.preventDefault();
        let id = e.target.getAttribute("data-for");
        let value = e.target.value;
        if (id == "educationId") {
            if (value == "0") {
                $("#_educationIderror").show();
                $("._educationId").addClass("error-shadow")
            }
            else {
                $("#_educationIderror").hide();
                if ($("._educationId").hasClass('error-shadow'))
                    $("._educationId").removeClass('error-shadow');
            }
        }
        if (id == "educationCourseId") {
            if (value == "0") {
                $("#_educationCourseIderror").show();
                $("._educationCourseId").addClass("error-shadow")
            }
            else {
                $("#_educationCourseIderror").hide();
                if ($("._educationCourseId").hasClass('error-shadow'))
                    $("._educationCourseId").removeClass('error-shadow');
            }
        }
        if (id == "educationInstituteId") {
            if (value == "0") {
                $("#_educationInstituteIderror").show();
                $("._educationInstituteId").addClass("error-shadow")
            }
            else {
                $("#_educationInstituteIderror").hide();
                if ($("._educationInstituteId").hasClass('error-shadow'))
                    $("._educationInstituteId").removeClass('error-shadow');
            }
        }
        if (id == "educationCourseTypeId") {
            if (value == "0") {
                $("#_educationCourseTypeIderror").show();
                $("._educationCourseTypeId").addClass("error-shadow")
            }
            else {
                $("#_educationCourseTypeIderror").hide();
                if ($("._educationCourseTypeId").hasClass('error-shadow'))
                    $("._educationCourseTypeId").removeClass('error-shadow');
            }
        }
        if (id == "educationCompletedYear") {
            if (value == "0") {
                $("#_educationCompletedYearerror").show();
                $("._educationCompletedYear").addClass("error-shadow")
            }
            else {
                $("#_educationCompletedYearerror").hide();
                if ($("._educationCompletedYear").hasClass('error-shadow'))
                    $("._educationCompletedYear").removeClass('error-shadow');
            }
        }

    }


    useEffect(() => {
        let editCertIndex = certificationInfo.findIndex((obj => obj.id == curCertId));
        if (editCertIndex != -1) {
            let editCertObj = certificationInfo[editCertIndex];
            setCurCert({ ...editCertObj })
        }
    }, [curCertId]);


    // const handlecertificationdelete = (id) => {
    //     const deleted = certificationInfo.filter((exp) => exp.id !== id);
    //     setCertificationInfo(deleted);
    // }
    const handlecertificationdelete = (id) => {
        //const deleted = certificationInfo.filter((exp) => exp.id !== id);
        const data = certificationInfo.map(obj => {
            if (obj.id == id) {
                obj.cxocertificatestatus = "0";
            }
            return obj;
        });
        const delCert = data.filter(obj => obj.id == id)

        //console.log(delCert);
        UpdateCXOCertificationInfo(delCert);
        //setCertificationInfo(deleted);
    }

    // Certification Summary//
    const EditCertification = (e) => {
        let dataFor = e.target.getAttribute("data-for");
        let dataId = curCertId;
        let value = "";
        if (dataFor === "certificationFiles") {
            fileUpload(e).then(
                (response) => {
                    var updCert = response.s3FileUrl;
                    setCertificateFile(updCert);
                    const newCert = certificationInfo.map(obj => {
                        if (obj.id == dataId) {
                            return { ...obj, [dataFor]: updCert };
                        }
                        return obj;
                    });
                    setCertificationInfo([...newCert]);
                    setCurCert([...newCert]);
                }).catch(err => console.log(err));
        } else {
            value = e.target.value;
            const newCert = certificationInfo.map(obj => {
                if (obj.id == dataId) {
                    return { ...obj, [dataFor]: value };
                }
                return obj;
            });
            editCertificationValidation(e);
            // setCertificationInfo([...newCert]);
            setCurCert([...newCert]);
        }
    }
    const addCertification = () => {

        let obj = {
            // id: certificationInfo.length + 1,
            certificationTitle: document.getElementById('certificationTitle').value,
            certificationOrganization: document.getElementById('certificationOrganization').value,
            certificationFiles: certificateFile,//document.getElementById('certificationFiles').value,
            certificationCompletionDate: document.getElementById('certificationCompletionDate').value,
            userId: sessionStorage.getItem("userId"),
            cxocertificatestatus: 1,
        }
        var flaglist = certificateValidation();
        if (flaglist.includes(false)) {
            var flag = false;
        }
        else {
            var flag = true;
        }
        if (flag) {
            // setCertificationInfo([...certificationInfo, obj]);
            const arrcertadd = [];
            arrcertadd.push(obj);
            UpdateCXOCertificationInfo(arrcertadd);
            $("#viewCertificationDiv").removeClass("hide");
            $("#certificateImg").hide();
            document.getElementById("addCertificationForm").reset();
            $("#AddCertification .close").trigger('click');
        }

    }

    const certificatevalid = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        if (id == "certificationTitle") {
            if (value == "" || value == null || value == undefined) {
                $("#certificationTitleerror").show();
                $("#certificationTitle").addClass("error-shadow")

            }
            else {
                $("#certificationTitleerror").hide();
                if ($("#certificationTitle").hasClass('error-shadow'))
                    $("#certificationTitle").removeClass('error-shadow');
            }
        }
        if (id == "certificationCompletionDate") {
            if (value == "" || value == null || value == undefined) {
                $("#certificationCompletionDateerror").show();
                $("#certificationCompletionDate").addClass("error-shadow")

            }
            else {
                $("#certificationCompletionDateerror").hide();
                if ($("#certificationCompletionDate").hasClass('error-shadow'))
                    $("#certificationCompletionDate").removeClass('error-shadow');
            }
        }
        if (id == "certificationOrganization") {
            if (value == "" || value == null || value == undefined) {
                $("#certificationOrganizationerror").show();
                $("#certificationOrganization").addClass("error-shadow")

            }
            else {
                $("#certificationOrganizationerror").hide();
                if ($("#certificationOrganization").hasClass('error-shadow'))
                    $("#certificationOrganization").removeClass('error-shadow');
            }
        }

    }



    const certificateValidation = () => {
        var flag = true;
        var flaglist = []
        var obj = {
            certificationTitle: document.getElementById("certificationTitle").value,
            certificationCompletionDate: document.getElementById('certificationCompletionDate').value,
            certificationOrganization: document.getElementById("certificationOrganization").value,
            certificationFiles: document.getElementById("certificationFiles").value
        }

        if (!obj.certificationTitle) {
            $("#certificationTitleerror").show();
            $("#certificationTitle").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#certificationTitleerror").hide();
            if ($("#certificationTitle").hasClass('error-shadow'))
                $("#certificationTitle").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);

        }
        if (!obj.certificationCompletionDate) {
            $("#certificationCompletionDateerror").show();
            $("#certificationCompletionDate").addClass("error-shadow")

            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#certificationCompletionDateerror").hide();
            if ($("#certificationCompletionDate").hasClass('error-shadow'))
                $("#certificationCompletionDate").removeClass('error-shadow');

            flag = true;
            flaglist.push(flag);

        }
        if (!obj.certificationOrganization) {
            $("#certificationOrganizationerror").show();
            $("#certificationOrganization").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#certificationOrganizationerror").hide();
            if ($("#certificationOrganization").hasClass('error-shadow'))
                $("#certificationOrganization").removeClass('error-shadow');

            flag = true;
            flaglist.push(flag);

        }
        if (!obj.certificationFiles) {
            $("#certificationFileserror").show();
            $("#certificationFiles").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#certificationFileserror").hide();
            if ($("#certificationFiles").hasClass('error-shadow'))
                $("#certificationFiles").removeClass('error-shadow');

            flag = true;
            flaglist.push(flag);

        }
        return flaglist;
    }
    const uploadCertificateFile = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        if (id == "certificationFiles") {
            if (value == "" || value == undefined || value == null) {
                $("#certificationFileserror").show();
                $("#certificationFiles").addClass("error-shadow")
            }
            else {
                $("#certificationFileserror").hide();
                if ($("#certificationFiles").hasClass('error-shadow'))
                    $("#certificationFiles").removeClass('error-shadow');
            }
        }
        fileUpload(e).then(
            (response) => {
                setCertificateFile(response.s3FileUrl);
            }).catch(err => console.log(err));
    }
    const editCertificationValidation = (e) => {
        e.preventDefault();
        let id = e.target.getAttribute("data-for");
        let value = e.target.value;
        if (id == "certificationTitle") {
            if (value == "" || value == null || value == undefined) {
                $("#_certificationTitleerror").show();
                $("._certificationTitle").addClass("error-shadow")

            }
            else {
                $("#_certificationTitleerror").hide();
                if ($("._certificationTitle").hasClass('error-shadow'))
                    $("._certificationTitle").removeClass('error-shadow');
            }
        }
        if (id == "certificationCompletionDate") {
            if (value == "" || value == null || value == undefined) {
                $("#_certificationCompletionDateerror").show();
                $("._certificationCompletionDate").addClass("error-shadow")

            }
            else {
                $("#_certificationCompletionDateerror").hide();
                if ($("._certificationCompletionDate").hasClass('error-shadow'))
                    $("._certificationCompletionDate").removeClass('error-shadow');
            }
        }
        if (id == "certificationOrganization") {
            if (value == "" || value == null || value == undefined) {
                $("#_certificationOrganizationerror").show();
                $("._certificationOrganization").addClass("error-shadow")

            }
            else {
                $("#_certificationOrganizationerror").hide();
                if ($("._certificationOrganization").hasClass('error-shadow'))
                    $("._certificationOrganization").removeClass('error-shadow');
            }
        }
    }
    const updateCertification = (e) => {
        e.preventDefault();
        let obj = {
            id: curCertId,
            certificationTitle: $("._certificationTitle").val(),
            certificationOrganization: $("._certificationOrganization").val(),
            certificationFiles: certificateFile,//document.getElementById('certificationFiles').value,
            certificationCompletionDate: $("._certificationCompletionDate").val(),
            userId: sessionStorage.getItem("userId"),
            cxocertificatestatus: 1,
        }
        var flag = true;
        var flaglist = []
        if (!obj.certificationTitle) {
            $("#_certificationTitleerror").show();
            $("#_certificationTitle").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_certificationTitleerror").hide();
            if ($("#_certificationTitle").hasClass('error-shadow'))
                $("#_certificationTitle").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);

        }
        if (!obj.certificationCompletionDate) {
            $("#_certificationCompletionDateerror").show();
            $("#_certificationCompletionDate").addClass("error-shadow")

            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_certificationCompletionDateerror").hide();
            if ($("#_certificationCompletionDate").hasClass('error-shadow'))
                $("#_certificationCompletionDate").removeClass('error-shadow');

            flag = true;
            flaglist.push(flag);

        }
        if (!obj.certificationOrganization) {
            $("#_certificationOrganizationerror").show();
            $("#_certificationOrganization").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_certificationOrganizationerror").hide();
            if ($("#_certificationOrganization").hasClass('error-shadow'))
                $("#_certificationOrganization").removeClass('error-shadow');

            flag = true;
            flaglist.push(flag);

        }
        if (!obj.certificationFiles) {
            $("#_certificationFileserror").show();
            $("._certificationFiles").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_certificationFileserror").hide();
            if ($("._certificationFiles").hasClass('error-shadow'))
                $("._certificationFiles").removeClass('error-shadow');

            flag = true;
            flaglist.push(flag);

        }
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;

        if (flag) {
            const arrcertupdate = [];
            arrcertupdate.push(obj);
            UpdateCXOCertificationInfo(arrcertupdate);
            $("#viewCertificationDiv").removeClass("hide");
            $("#certificateImg").hide();

            $("#EditCertification .close").trigger('click');
        }
    }

    useEffect(() => {
        let editCVIndex = CVInfo.findIndex((obj => obj.usercvId == curCVId));
        if (editCVIndex != -1) {
            let editCVObj = CVInfo[editCVIndex];
            setcurCVInfo({ ...editCVObj })
        }
    }, [curCVId]);

    // CV Summary
    const editCVInfo = (e) => {
        let dataFor = e.target.getAttribute("data-for");
        let dataId = curCVId;
        let value = "";
        if (dataFor === "cv_attachment") {
            fileUpload(e).then(
                (response) => {
                    var updCVAttachment = response.s3FileUrl;
                    const newCV = CVInfo.map(obj => {
                        if (obj.id == dataId) {
                            return { ...obj, [dataFor]: updCVAttachment };
                        }
                        return obj;
                    });
                    // setUploadCVInfo([...newCV]);
                    setcurCVInfo([...newCV]);
                })
        }
        else {
            value = e.target.value;
            const newCV = CVInfo.map(obj => {
                if (obj.id == dataId) {
                    return { ...obj, [dataFor]: value };
                }
                return obj;
            });
            // setUploadCVInfo([...newCV]);
            setcurCVInfo([...newCV]);
        }
        cvvalid(e)
    }
    const addCVAttachmentFile = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        if (id == "cv_attachment") {
            if (value == "" || value == null || value == undefined) {
                $("#cvAttachementerror").show();
                $("#cv_attachment").addClass("error-shadow")
            }
            else {
                $("#cvAttachementerror").hide();
                if ($("#cv_attachment").hasClass('error-shadow'))
                    $("#cv_attachment").removeClass('error-shadow');
            }
        }

        fileUpload(e).then(
            (response) => {
                setCVAttachmentFile(response.s3FileUrl);
            }).catch(err => console.log(err));
    }
    const UploadCVInfo = () => {

        let obj = {
            usercvId: curCVId,
            user_cv_title: document.getElementById('user_cv_title').value,
            cv_attachment: cvAttachmentFile,
            userId: sessionStorage.getItem("userId"),
        }
        var flaglist = cvValidation();
        if (flaglist.includes(false)) {
            var flag = false;
        }
        else {
            var flag = true;
        }
        if (flag) {
            // setUploadCVInfo([...uploadCVInfo, obj]);
            UpdateCXOCVInfo(obj);
            document.getElementById("addCVForm").reset();
            $("#uploadCVBtn").hide();
            $("#Edit_UploadCV .close").trigger('click');
        }

        // else {
        //     console.log("CV files are not Provided")
        // }
    }
    const cvValidation = () => {
        var flag = true;
        var flaglist = []
        var obj = {
            cvTitle: document.getElementById("user_cv_title").value,
            cvAttachment: document.getElementById("cv_attachment").value,
        }
        if (!obj.cvTitle) {
            $("#cvTitleerror").show();
            $("#user_cv_title").addClass("error-shadow")

            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#cvTitleerror").hide();
            if ($("#user_cv_title").hasClass('error-shadow'))
                $("#user_cv_title").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);

        }
        if (!obj.cvAttachment) {
            $("#cvAttachementerror").show();
            $("#cv_attachment").addClass("error-shadow")

            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#cvAttachementerror").hide();
            if ($("#cv_attachment").hasClass('error-shadow'))
                $("#cv_attachment").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);

        }
        return flaglist;
    }

    const cvvalid = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        if (id == "user_cv_title") {
            if (value == "" || value == null || value == undefined) {
                $("#cvTitleerror").show();
                $("#user_cv_title").addClass("error-shadow");

            }
            else {
                $("#cvTitleerror").hide();
                if ($("#user_cv_title").hasClass('error-shadow'))
                    $("#user_cv_title").removeClass('error-shadow');
            }
        }
    }
    const checkProfilevalidation = (id) => {
        if (id === "" || id === undefined) {
            return allcheckProfilevalidation()
        }
        else {
            return singlecheckProfilevalidation(id)
        }
    }
    //single check profile 
    const singlecheckProfilevalidation = (id) => {
        var flag = true;
        var single_flaglist = []
        var obj = {
            profileTitle: document.getElementById("profileTitle").value,
            profileSummary: document.getElementById("profileSummary").value,
            profileLanguages: document.getElementById("profileLanguages").value,
            profilePhoto: document.getElementById("profilePicture").value
        }
        if (!obj.profileTitle) {
            $("#profileTitleerror").show();
            flag = false;
            single_flaglist.push(flag);
            return;
        }
        else {
            $("#profileTitleerror").hide();
            flag = true;
            single_flaglist.push(flag);

        }
        if (!obj.profileSummary) {
            $("#profileSummaryerror").show();
            flag = false;
            single_flaglist.push(flag);
            return;
        }
        else {
            $("#profileSummaryerror").hide();
            flag = true;
            single_flaglist.push(flag);

        }
        if (obj.profileLanguages == 0) {
            $("#profilelanguageerror").show();
            flag = false;
            single_flaglist.push(flag);
            return;
        }
        else {
            $("#profilelanguageerror").hide();
            flag = true;
            single_flaglist.push(flag);

        }
        // if (!obj.profilePhoto) {
        if (!curProfilePicture && !curProfile.profilePicture) {
            $("#profilephotoerror").show();
            flag = false;
            single_flaglist.push(flag);
            return;
        }
        else {
            $("#profilephotoerror").hide();
            flag = true;
            single_flaglist.push(flag);

        }
        return single_flaglist;
    };

    //all check profile
    const allcheckProfilevalidation = () => {
        var flag = true;
        var flaglist = []
        var obj = {
            profileTitle: document.getElementById("profileTitle").value,
            profileSummary: document.getElementById("profileSummary").value,
            profileLanguages: document.getElementById("profileLanguages").value,
            profilePhoto: document.getElementById("profilePicture").value
        }
        if (!obj.profileTitle) {
            $("#profileTitleerror").show();
            flag = false;
            flaglist.push(flag)
        }
        else {
            $("#profileTitleerror").hide();
            flag = true;
            flaglist.push(flag)

        }
        if (!obj.profileSummary) {
            $("#profileSummaryerror").show();
            flag = false;
            flaglist.push(flag)

        }
        else {
            $("#profileSummaryerror").hide();
            flag = true;
            flaglist.push(flag)

        }
        if (obj.profileLanguages == 0) {
            $("#profilelanguageerror").show();
            flag = false;
            flaglist.push(flag)

        }
        else {
            $("#profilelanguageerror").hide();
            flag = true;
            flaglist.push(flag)

        }
        // if (!obj.profilePhoto) {
        if (!curProfilePicture && !curProfile.profilePicture) {
            $("#profilephotoerror").show();
            flag = false;
            flaglist.push(flag)

        }
        else {
            $("#profilephotoerror").hide();
            flag = true;
            flaglist.push(flag)

        }
        return flaglist;
    };
    const handleSubmitProfile = (e) => {
        e.preventDefault();
        var flaglist = allcheckProfilevalidation();
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;
        if (flag) {
            UpdateCXOProfileInfo();
            $("#ProfileSummary .close").trigger("click");
            return flag;
        }
        else {
            return flag;
        }

    }
    const fileUpload = async (e) => {
        const file = e.target.files[0];
        // const fileName = new Date().toISOString() + "|" +  e.target.files[0].name;     
        var fileName = e.target.files[0].name;
        var fileExtension = fileName.split('.').pop();
        fileName += S3Config.S3ObjectDelimiter + uuidCustom() + "." + fileExtension;
        const contentType = file.type;
        const formData = { file, fileName, contentType };
        let result = await S3FileUpload(formData).then(
            (response) => {
                if (response.status === 200) {
                    return { status: 200, s3FileUrl: S3Config.S3ObjectBaseURL + fileName }
                }
            }
        ).catch(err => console.log(err));
        return result;
    }
    //Profile Section//
    const handleClick_Profile_Common = (id, value) => {
        setEditProfileInfo({ ...editprofileInfo, [id]: value });
        if (JSON.stringify(profileInfo) !== "{}") {
            $("#addProfileBtn").hide();
            $("#viewProfileDiv").show();
        }
        console.log(editprofileInfo);
        checkProfilevalidation(id);
    }
    const handleClick_Profile = (e) => {
        let id = e.target.id; let value = "";
        if (id === "profilePicture") {
            const [img] = e.target.files;
            // setPreviewProfilePicture(URL.createObjectURL(img));
            fileUpload(e).then(
                (response) => {
                    value = response.s3FileUrl;
                    setCurProfilePicture(value);
                    handleClick_Profile_Common(id, value);
                }).catch(err => console.log(err));
        } else {
            value = e.target.value;
            handleClick_Profile_Common(id, value);
        }
    }
    useEffect(() => {
        let editProfileIndex = profileInfo.findIndex((obj => obj.userId == curProfileId));
        if (editProfileIndex != -1) {
            let editProfileObj = profileInfo[editProfileIndex];
            setCurProfilePicture(editProfileObj.profilePicture);
            setCurProfile({ ...editProfileObj })
        }
    }, [curProfileId]);

    const UpdateCXOPersonalInfo = (personalobj) => {
        personalobj.entityId = sessionStorage.getItem('entityId');
        personalobj.roleId = sessionStorage.getItem('roleId')
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
        }
        axios.post(CXOUpdatePersonalAPI, personalobj, {
            headers: headers
        }).then(response => {
            if (response.data.status === 200) {
                sessionStorage.setItem("firstname", personalobj.firstName);
                sessionStorage.setItem("lastname", personalobj.lastName);
                // window.location.reload(false);
                getCXODetails(loggedInuserId, loggedInroleId);
                // sendMail();
                // swal("Thank you for registering!", "Please confirm your Registration by verifying the email.", "success", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/register', { replace: true }) } });
            }
            else {
                // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })

            }
        }).catch(error => {
            // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })
        });

    }
    const UpdateCXOProfileInfo = () => {
        editprofileInfo.profileTitle = editprofileInfo.profileTitle ? editprofileInfo.profileTitle : document.getElementById('profileTitle').value;
        editprofileInfo.profileSummary = editprofileInfo.profileSummary ? editprofileInfo.profileSummary : document.getElementById('profileSummary').value;
        editprofileInfo.profileLanguages = editprofileInfo.profileLanguages ? editprofileInfo.profileLanguages : document.getElementById('profileLanguages').value;
        editprofileInfo.profilePicture = editprofileInfo.profilePicture ? editprofileInfo.profilePicture : curProfile.profilePicture;
        editprofileInfo.userId = loggedInuserId;
        console.log(editprofileInfo);
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
        }
        axios.post(CXOUpdateProfileAPI, editprofileInfo, {
            headers: headers
        }).then(response => {
            if (response.data.status === 200) {
                sessionStorage.setItem("profilePicture", editprofileInfo.profilePicture);
                //window.location.reload(false);
                getCXODetails(loggedInuserId, loggedInroleId);
                // sendMail();
                // swal("Thank you for registering!", "Please confirm your Registration by verifying the email.", "success", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/register', { replace: true }) } });
            }
            else {
                // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })

            }
        }).catch(error => {
            // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })
        });

    }
    const UpdateCXOExperienceInfo = (expobj) => {
        console.log(expobj);
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
        }
        axios.post(CXOUpdateExperiencelAPI, expobj, {
            headers: headers
        }).then(response => {
            if (response.data.status === 200) {
                getCXODetails(loggedInuserId, loggedInroleId);
                // sendMail();
                // swal("Thank you for registering!", "Please confirm your Registration by verifying the email.", "success", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/register', { replace: true }) } });
            }
            else {
                // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })

            }
        }).catch(error => {
            // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })
        });

    }
    const UpdateCXOEmploymentInfo = (empobj) => {
        console.log(empobj);
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
        }
        axios.post(CXOUpdateEmploymentAPI, empobj, {
            headers: headers
        }).then(response => {
            if (response.data.status === 200) {
                getCXODetails(loggedInuserId, loggedInroleId);
                // sendMail();
                // swal("Thank you for registering!", "Please confirm your Registration by verifying the email.", "success", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/register', { replace: true }) } });
            }
            else {
                // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })

            }
        }).catch(error => {
            // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })
        });

    }
    const UpdateCXOEducationInfo = (eduobj) => {
        console.log(eduobj);
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
        }
        axios.post(CXOUpdateEducationAPI, eduobj, {
            headers: headers
        }).then(response => {
            if (response.data.status === 200) {
                getCXODetails(loggedInuserId, loggedInroleId);
                // sendMail();
                // swal("Thank you for registering!", "Please confirm your Registration by verifying the email.", "success", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/register', { replace: true }) } });
            }
            else {
                // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })

            }
        }).catch(error => {
            // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })
        });

    }
    const UpdateCXOCertificationInfo = (certobj) => {
        console.log(certobj);
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
        }
        axios.post(CXOUpdateCertificationAPI, certobj, {
            headers: headers
        }).then(response => {
            if (response.data.status === 200) {
                getCXODetails(loggedInuserId, loggedInroleId);
                // sendMail();
                // swal("Thank you for registering!", "Please confirm your Registration by verifying the email.", "success", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/register', { replace: true }) } });
            }
            else {
                // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })

            }
        }).catch(error => {
            // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })
        });

    }
    const UpdateCXOCVInfo = (cvobj) => {
        console.log(cvobj);
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
        }
        axios.post(CXOUpdateCVAPI, cvobj, {
            headers: headers
        }).then(response => {
            if (response.data.status === 200) {
                getCXODetails(loggedInuserId, loggedInroleId);
                // sendMail();
                // swal("Thank you for registering!", "Please confirm your Registration by verifying the email.", "success", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/register', { replace: true }) } });
            }
            else {
                // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })

            }
        }).catch(error => {
            // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })
        });

    }
    const handleadd = () => {
        var div = $("<div />");
        div.html(GenerateTextbox(""));
        $("#TextBoxContainer").append(div);
        $(".addbox").addClass("hide");
    }
    // const handleaddskill = () => {
    //     var div = $("<div />");
    //     div.html(GenerateaddSkillTextbox(""));
    //     $("#addTextBoxContainer").append(div);
    //     $(".addbox").addClass("hide");
    // }
    // function GenerateaddSkillTextbox(value) {
    //     return '<input name = "CreateTextbox" id="empnewskilladd" type="text" value = "' + value + '" /> ' +
    //         '<i className="fas fa-save addNewempSkills"></i>  <i className="fa-solid fa-trash remove"></i>'
    // }
    // const handleshow = () => {
    // var values = "";
    // $("input[name=CreateTextbox]").each(function () {
    //     values += $(this).val() + "\n";
    // });
    // return values;
    //     var values = "";
    //     $("input[name=CreateTextbox]").each(function () {
    //         values += $(this).val();
    //     });
    //     return values;
    // }
    // useEffect(() => {
    //     $("body").on("click", ".addNewempSkills", function () {
    //         var newval = handleshow();
    //         console.log(newval);
    //         if (newval == '') {
    //             swal("Please fill the Data", "", "error", { closeOnClickOutside: false, closeOnEsc: false });
    //         }
    //         else {
    //             let obj = {
    //                 id: newempskill.length + 1,
    //                 skillName: newval
    //             }
    //             const skill_info = [];
    //             skill_info.push(newval);
    //             console.log(skill_info);
    //             console.log([{ ...newempskill, obj }])
    //             setNewEmpSkill([{ ...newempskill, obj }])
    //             // setNewEmpSkill([...newempskill,obj]);     
    //             //     $(this).closest("div").remove();
    //             //     $(".addbox").removeClass("hide");


    //         }
    //         console.log(newempskill);
    //     });
    // }, [])


    useEffect(() => {
        $("body").on("click", ".addempSkills", function () {
            // var newval = handleshow();
            var newval = document.getElementById("empskilladd").value;
            console.log(newval);
            if (newval == '') {
                swal("Please fill the Data", "", "error", { closeOnClickOutside: false, closeOnEsc: false });
            }
            else {
                //setEmpSkill(newval);
                if (curEmpId != 0) {
                    UpdateEmploymentSkillInfo(0, 1, newval);
                    $(this).closest("div").remove();
                    $(".addbox").removeClass("hide");
                }

            }
        });
        $("body").on("click", ".remove", function () {
            $(this).closest("div").remove();
            //   var  values = $(this).val() + "\n";
            //   alert(values);
            $(".addbox").removeClass("hide");
        });
    }, [curEmpId])
    function GenerateTextbox(value) {
        return '<div class="form-group d-flex"><input name = "CreateTextbox" class="form-control" id="empskilladd" type="text" value = "' + value + '" /> ' +
            '<i class="mdi mdi-playlist-plus text-success addempSkills fs-5 ms-3 mt-2" title="Save"></i>  <i class="fa-solid fa-trash remove fs-6 ms-3 mt-2 text-muted" title="Delete"></i></div>'
    }
    const UpdateEmploymentSkillInfo = (empskilld, status, empskill) => {
        if (curEmpSkill.length < 2 && status == 0) {
            swal("Minimum one skill is needed for an employment", "", "warning", { closeOnClickOutside: false, closeOnEsc: false });
            return false;

        }
        let empskillobj = {
            userEmploymentId: curEmpId,
            userEmploymentSkillId: empskilld,
            skillStatus: status,
            skillName: empskill,
            userId: sessionStorage.getItem('userId')
        }
        console.log(empskillobj);
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
        }
        console.log(JSON.stringify(empskillobj));
        axios.post(CXOUpdateEmploymentSkillAPI, empskillobj, {
            headers: headers
        }).then(response => {
            if (response.data.status === 200) {
                if (status == 0) {
                    swal("Skill Deleted Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                }
                else {
                    swal("Skill Added Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                }
                getCXODetails(loggedInuserId, loggedInroleId)
                $("#EditEmployment .close").trigger("click");
                //setcurcheckId(1);
                // sendMail();
                // swal("Thank you for registering!", "Please confirm your Registration by verifying the email.", "success", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/register', { replace: true }) } });
            }
            else {
                // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })

            }
        }).catch(error => {
            // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })
        });

    }
    const UpdateCXOIndustryInfo = () => {

        $("#Industry .close").trigger("click");
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
        }
        axios.post(`${CXOUpdateIndustryAPI}?cxoindustryExperienceInfo=${industryExperienceInfo}&userId=${loggedInuserId}`).then(response => {
            if (response.data.status === 200) {
                getCXODetails(loggedInuserId, loggedInroleId);
                // sendMail();
                // swal("Thank you for registering!", "Please confirm your Registration by verifying the email.", "success", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/register', { replace: true }) } });
            }
            else {
                // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })

            }
        }).catch(error => {
            // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })
        });
    }
    const deleteAccount = () => {
        const password = document.getElementById('deleteAccountPassword').value;
        if (!password == '') {
            if ($("#deleteAccountAgree").is(':checked')) {
                const userStatus = 0;
                axios.post(`${CXOPASSWORDCHECKAPI}?userId=${loggedInuserId}&entityId=${loggedInentityId}&roleId=${loggedInroleId}&userPassword=${password}`).then(response => {
                    if (response.data.status === 200 && response.data.response === true) {
                        axios.post(`${CXOACCOUNTDELETEAPI}?userId=${loggedInuserId}&entityId=${loggedInentityId}&roleId=${loggedInroleId}&userStatus=${userStatus}`).then(response => {
                            if (response.data.status === 200) {
                                $("#DeleteAccount .close").trigger("click");
                                sessionStorage.clear();
                                swal("Account Deleted successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } });
                            }
                            else {
                                // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })

                            }
                        }).catch(error => {
                            // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })
                        });
                    }
                    else {
                        swal("Please enter the correct password", "Please Try Again", "warning", { closeOnClickOutside: false, closeOnEsc: false });
                    }
                }).catch(error => {
                    // swal("Caas Member Registration Failed", "Please Try Again", "error", { closeOnClickOutside: false, closeOnEsc: false }).then(okay => { if (okay) { navigate('/', { replace: true }) } })
                });

            }
            else {
                swal("You must accept to continue", "Please Try Again", "warning", { closeOnClickOutside: false, closeOnEsc: false });
            }
        }
        else {
            swal("Please enter the password", "Please Try Again", "warning", { closeOnClickOutside: false, closeOnEsc: false });
        }
    }
    const handleKeyPress = (e) => {
        if (e.charCode === 32 && e.key === " ") {
            e.preventDefault();
        }
    }
    return (
        <>
            <div className="sidebar-icon-only with-welcome-text" data-new-gr-c-s-check-loaded="14.1072.0" data-gr-ext-installed="">
                {loading ? (
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                ) : (
                    <div className="main-body-section">
                        <section className="header-section sticky-top1">
                            <SocialHeader />
                        </section>
                        <section className="body-section previewpagenew">
                            <div className="body-content container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 mob-remove-padding box_2">
                                        <div className="box-home p-0 box_shadow_1">
                                            <div className="justify-content-between align-items-center px-3">
                                                <div className="col-md-12 col-lg-12 grid-margin stretch-card">
                                                    <div className="card pb-4">
                                                        <main className="w-100">
                                                            <div className="elon card">
                                                                <div className="img_profile card-left ">
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <div id="lightgallery profilegallery" className="row lightGallery">
                                                                                <a href="#" className="image-tile gallery_img" title="Edit" data-bs-toggle="modal" data-bs-target="#ProfileSummary" onClick={() => {
                                                                                    setcurProfileId(loggedInuserId)
                                                                                }}>
                                                                                    <img src={profileInfo[0]?.profilePicture ? profileInfo[0]?.profilePicture : profilepic} alt="CAAS Member Image" /></a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card-right pb-0">
                                                                    {personalInfo.length != 0 ? (
                                                                        personalInfo.map((ele, index) => (
                                                                            <div className="card-title fw-7 fs-2 m-0" key={index}>{ele?.firstName} {ele?.lastName} <a href="#" class="float right btn-edit" data-bs-toggle="modal" data-bs-target="#ProfileSummary" onClick={() => {
                                                                                setcurProfileId(loggedInuserId)
                                                                            }}> <i class="fa-solid fa-pencil fs-6 mr-3" title="Edit"></i>&nbsp;Edit Profile</a></div>
                                                                        ))
                                                                    ) : <div className="card-title fw-7 fs-2 m-0">{t('noUserDataFound')}</div>
                                                                    }
                                                                    {profileInfo.length != 0 ? (
                                                                        profileInfo.map((ele, index) => (
                                                                            <>
                                                                                <div className="card-rb-2">
                                                                                    <div className="card-rb-2-title card-text fw-7">{ele?.profileTitle}
                                                                                    </div>

                                                                                </div>
                                                                                <div className="skill_text text-muted fw-5 m-0">{ele?.profileSummary}</div>

                                                                                <div className="card-rb-2-text card-text fw-7">
                                                                                    <span className="card-text ms-0">
                                                                                        <i className="fa-solid fa-language mt-1 2 text-muted mr_5 fa-xl" title="Languages"></i>
                                                                                        {ele?.profileLanguages}
                                                                                    </span>
                                                                                </div>
                                                                            </>))
                                                                    ) : <div className="skill_text text-muted fw-5 m-0">{t('noUserDataFound')}</div>
                                                                    }
                                                                    <div className="skill card-body ps-0">
                                                                        <h4 className="card-title">Skills</h4>
                                                                        <div className="template-demo">
                                                                            {experienceInfo.length != 0 ? (
                                                                                experienceInfo.map((ele, index) => (
                                                                                    // <button type="button" className="btn btn-outline-primary btn-rounded skills-btn btn-fw" key={index}>{ele?.experienceSkillName}</button>
                                                                                    <button type="button" className="btn btn-outline-primary btn-rounded skills-btn btn-fw" key={index}>
                                                                                        <Link to="/cxo/cxo/caasmembers" className="d-block" state={{ searchfilterName: "skill", searchFilterKey: ele?.experienceSkillId }}>
                                                                                            {ele?.experienceSkillName}
                                                                                        </Link></button>
                                                                                ))
                                                                            ) : <>{t('noUserDataFound')}</>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </main>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <nav aria-label="breadcrumb" className="profile-section pl-5 pr-5">
                                                        <div className="breadcrumb mb-0 bg-profilesection">
                                                            <ul className=" nav nav-pills nav-pills-success pb-0 mb-0 border-bottom-0" id="pills-tab" role="tablist">
                                                                <li className="nav-item list mb-0">
                                                                    <a className="nav-link active " id="pills-experience-tab" data-bs-toggle="pill" href="#pills-experience" role="tab" aria-controls="pills-experience" aria-selected="true"><i className="mdi mdi-account-star menu-icon"></i> {t('experience')} </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" id="pills-industry-tab" data-bs-toggle="pill" href="#pills-industry" role="tab" aria-controls="pills-industry" aria-selected="false"><i className="mdi mdi-wrap menu-icon"></i>  {t('industry')} </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" id="pills-employment-tab" data-bs-toggle="pill" href="#pills-employment" role="tab" aria-controls="pills-employment" aria-selected="false"><i className="mdi mdi-comment-account menu-icon"></i>  {t('employment')} </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" id="pills-employment-tab" data-bs-toggle="pill" href="#pills-education" role="tab" aria-controls="pills-education" aria-selected="false"><i className="mdi mdi mdi-chart-bar menu-icon"></i>  {t('education')} </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" id="pills-employment-tab" data-bs-toggle="pill" href="#pills-Certification" role="tab" aria-controls="pills-Certification" aria-selected="false"><i className="fa fa-graduation-cap menu-icon"></i>  {t('certification')} </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" id="pills-employment-tab" data-bs-toggle="pill" href="#pills-CV" role="tab" aria-controls="pills-CV" aria-selected="false"><i className="fa fa-file-text menu-icon"></i>   CV </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" id="pills-employment-tab" data-bs-toggle="pill" href="#pills-pi" role="tab" aria-controls="pills-pi" aria-selected="false"><i className="mdi mdi-account-check menu-icon"></i> {t('profile')} </a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" id="pills-employment-tab" data-bs-toggle="pill" href="#pills-accountSettings" role="tab" aria-controls="pills-accountSettings" aria-selected="false"><i className="mdi mdi-account-settings"></i> Account Settings</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </nav>
                                                    <div className="col-md-12 col-lg-12 grid-margin stretch-card card_alignment ">
                                                        <div className="card  pb-4">
                                                            <div className="card-body ">
                                                                <div className="border-1">
                                                                </div>
                                                                <div className="tab-content" id="pills-tabContent">
                                                                    <div className="tab-pane fade show active" id="pills-experience" role="tabpanel" aria-labelledby="pills-experience-tab">
                                                                        <div className="row">
                                                                            <div className="border-top1">
                                                                                <div className="float-end">
                                                                                    <div className="btn-wrapper float-end">
                                                                                        <a id="addExperienceBtn" title="Add Experience" data-bs-toggle="modal" data-bs-target="#AddExperience" className="btn btn-primary btn-md text-white mb-3 mw-auto me-0 border-radius-15">
                                                                                            <i className="fa-solid fa-plus-circle"></i> Add </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                experienceInfo.length != 0 ? (
                                                                                    experienceInfo.map((ele, index) => (
                                                                                        <div className="col-md-12 col-sm-12 col-lg-4 grid-margin stretch-card ps-sm-0 ps-lg-2 ps-md-3 mb-4 " key={index}>
                                                                                            <div className=" space card card-rounded mr-5">
                                                                                                <div className="card-body">
                                                                                                    <div className="">
                                                                                                        <div>
                                                                                                            <h4 className=" card-title-dash pb-5">
                                                                                                                <a href="#" className="delete-employment"> <i className="fa-solid fa-trash float-end text-muted  mr_10 border_radius" title="Delete Experience" data-bs-toggle="modal" data-bs-target="#DeleteExperience" id="deleteExperienceBtn" data-for={ele.id} onClick={() => {
                                                                                                                    handleexpdelete(ele.id)
                                                                                                                }}><span className="f-family"></span></i></a>
                                                                                                                <a href="#" className="edit-profile pb-3"> <i className="fa-solid fa-pencil float right border_radius" data-bs-toggle="modal" data-bs-target="#EditExperience" title="Edit Experience" id="editExperienceBtn" data-for={ele.id} onClick={() => {
                                                                                                                    setcurExpId(ele.id)
                                                                                                                }}><span className="f-family"></span></i></a>
                                                                                                            </h4>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <ul className="bullet-line-list">
                                                                                                        <li>
                                                                                                            <div className="d-flex mt-1">
                                                                                                                <div><span className="text-light-green fw-bold">{t('skill')}&nbsp;:  </span></div>
                                                                                                                <p className="text-light-green text-muted mt-2"> &nbsp;{ele?.experienceSkillName}</p>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <div className="d-flex ">
                                                                                                                <div><span className="text-light-green fw-bold">{t('softwareVersion')} &nbsp;:</span></div>
                                                                                                                <p className="text-light-green text-muted mt-2"> &nbsp;{ele?.experienceSoftwareVersionName}</p>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <div className="d-flex ">
                                                                                                                <div><span className="text-light-green fw-bold">{t('lastUsed')} &nbsp;:</span></div>
                                                                                                                <p className="text-light-green text-muted mt-2"> &nbsp;{ele?.experienceLastUsed}</p>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <div className="d-flex ">
                                                                                                                <div><span className="text-light-green fw-bold">{t('experience')}&nbsp;:</span> </div>
                                                                                                                <p className="text-light-green text-muted mt-2"> &nbsp;{ele?.experienceYears} {t('years')}</p>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <div className="d-flex ">
                                                                                                                <div><span className="text-light-green fw-bold">{t('implementationSize')}&nbsp;:</span> </div>
                                                                                                                <p className="text-light-green text-muted mt-2"> &nbsp;{ele?.experienceImplementationSize}</p>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <div className="d-flex ">
                                                                                                                <div><span className="text-light-green fw-bold">{t('experienceLevel')}&nbsp;:</span> </div>
                                                                                                                <p className="text-light-green text-muted mt-2"> &nbsp;{ele?.experienceLevelName}</p>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                    <div className="list align-items-center float-end pt-3">
                                                                                                        <div className="w-100">
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                ) : (
                                                                                    <div className="cardPad card-body box-shadow pb_30"><p>{t('noRecordsAvailable')}</p></div>
                                                                                )
                                                                            }


                                                                        </div>
                                                                    </div>
                                                                    <div className="tab-pane fade" id="pills-industry" role="tabpanel" aria-labelledby="pills-industry-tab">
                                                                        <div className="row">
                                                                            <div className="border-top1">
                                                                                <div className="float-end">
                                                                                    <div className="btn-wrapper float-end">
                                                                                        <a href="#" className="btn btn-primary btn-md text-white mb-3  mw-auto me-0 border-radius-15" title="Edit Industry" data-bs-toggle="modal" data-bs-target="#Industry" ><i className="fa-solid fa-pencil"></i> Edit </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                                <div className="card card-rounded">
                                                                                    <div className="card-body">
                                                                                        {/* <h4 className=" card-title-dash pb-4">
                                                                                            <a href="#" className="edit-profile pb-3"> <i className="fa-solid fa-pencil float right border_radius" title="Edit" data-bs-toggle="modal" data-bs-target="#Industry" onClick={editCXOIndustry}><span className="f-family"></span></i></a>
                                                                                        </h4> */}
                                                                                        <div>
                                                                                            <div className="mt-5">
                                                                                                {
                                                                                                    cxoindustryExperienceInfo.length != 0 ? (
                                                                                                        cxoindustryExperienceInfo.map((ele) => (
                                                                                                            <>
                                                                                                                <button type="button" className="btn industry-list btn-outline-secondary btn-sm ml_20 mb-3" value={ele?.industryId}> {ele?.industryname} </button>
                                                                                                            </>
                                                                                                        ))
                                                                                                    ) : (
                                                                                                        <p>{t('noRecordsAvailable')}</p>
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                            <div className="list align-items-center float-end pt-3">
                                                                                                <div className="w-100">
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="tab-pane fade" id="pills-employment" role="tabpanel" aria-labelledby="pills-employment-tab">
                                                                        <div className="row">
                                                                            <div className="border-top1">
                                                                                <div className="float-end">
                                                                                    <div className="btn-wrapper float-end">
                                                                                        <a href="#" className="btn btn-primary btn-md text-white mb-3  mw-auto me-0 border-radius-15" data-bs-toggle="modal" data-bs-target="#AddEmployment" title="Add Employment"><i className="fa-solid fa-plus-circle"></i> Add</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-12 col-sm-12 col-md-12 mb-3">
                                                                                <div className="space card card-rounded">
                                                                                    <div className="card-body">
                                                                                        {/* <h4 className=" card-title-dash pb-5">
                                                                                            <a href="#" className="delete-employment"> <i className="fa-solid fa-trash float-end text-muted  mr_10 border_radius" title="Delete Employment" data-bs-toggle="modal" data-bs-target="#DeleteEmployment" id="deleteEmploymentBtn" data-for="366"><span className="f-family"></span></i></a>
                                                                                            <a href="#" className="edit-profile pb-3"> <i className="fa-solid fa-pencil float right border_radius" title="Edit Experience" data-bs-toggle="modal" data-bs-target="#EditExperience"><span className="f-family"></span></i></a>
                                                                                        </h4> */}

                                                                                        {
                                                                                            employmentInfo.length != 0 ? (
                                                                                                employmentInfo.map((ele) => (
                                                                                                    <>
                                                                                                        {/* <ul className="bullet-line-list">
                                                                                                            <li>
                                                                                                                <div className="d-flex justify-content-between">
                                                                                                                    <div><span className="text-light-green fw-bold d-block">{ele?.employmentDesignation} at {ele?.employmentCompany}
                                                                                                                    </span>
                                                                                                                        <span className="text-light-green text-muted d-block">
                                                                                                                            {ele?.employmentSkills} </span>
                                                                                                                        <span className="text-light-green text-muted">
                                                                                                                            {ele?.employmentCityName}, {ele?.employmentStateName}, {ele?.employmentCountryName}
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                    <p className="fw-bold"> {ele?.employmentYears} {t('years')} </p>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                        </ul> */}

                                                                                                        <ul className="bullet-line-list">
                                                                                                            <li>
                                                                                                                <div>
                                                                                                                    <div>

                                                                                                                        <span className="fw-bold">{ele?.employmentDesignation}</span>
                                                                                                                        <h4 className="card-title-dash">
                                                                                                                            <a href="#" className="delete-employment"> <i className="fa-solid fa-trash float-end text-muted  mr_10 border_radius" title="Delete Employment" data-bs-toggle="modal" data-bs-target="#DeleteEmployment" id="deleteEmploymentBtn" data-for={ele.id} onClick={() => {
                                                                                                                                handleemploymentdelete(ele.id)
                                                                                                                            }}><span className="f-family"></span></i></a>
                                                                                                                            <a href="#" className="edit-profile pb-3"> <i className="fa-solid fa-pencil float right float-end border_radius" title="Edit Employment" data-bs-toggle="modal" data-bs-target="#EditEmployment" id="editEmploymentBtn" data-for={ele.id} onClick={() => {
                                                                                                                                setcurEmpId(ele?.id)
                                                                                                                            }}><span className="f-family"></span></i></a>
                                                                                                                        </h4>
                                                                                                                        <span className="fw-bold">{ele?.employmentCompany} </span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                                                                                                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                                                                                                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                                                                                                        </svg>{ele?.employmentStateName}, {ele?.employmentCountryName}
                                                                                                                        <span className="text-light-green fw-bold text-muted d-block">{ele?.employmentYears} {t('years')} </span>
                                                                                                                        <span className="text-light-green text-muted d-block">Skills:  {ele?.employmentSkills}</span>
                                                                                                                        {/* <span className="text-light-green text-muted">{ele?.employmentCityName}, {ele?.employmentStateName}, {ele?.employmentCountryName}</span> */}

                                                                                                                    </div>
                                                                                                                    <p className="fw-bold"></p>
                                                                                                                </div>
                                                                                                            </li>

                                                                                                        </ul>
                                                                                                    </>
                                                                                                ))
                                                                                            ) : (
                                                                                                <div className="cardPad card-body box-shadow pb_30"><p>{t('noRecordsAvailable')}</p></div>
                                                                                            )
                                                                                        }


                                                                                        <div className="list align-items-center float-end pt-3">
                                                                                            <div className="w-100">
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="tab-pane fade" id="pills-education" role="tabpanel" aria-labelledby="pills-education-tab">
                                                                        <div className="row">
                                                                            <div className="border-top1">
                                                                                <div className="float-end">
                                                                                    <div className="btn-wrapper float-end">
                                                                                        <a href="#" className="btn btn-primary btn-md text-white mb-3  mw-auto me-0 border-radius-15" data-bs-toggle="modal" data-bs-target="#AddEducation" id="addeducationBtn" ><i className="fa-solid fa-plus-circle"></i> Add</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                educationInfo.length != 0 ? (
                                                                                    educationInfo.map((ele) => (
                                                                                        <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                                                                                            <div className="space card card-rounded">
                                                                                                <div className="card-body">
                                                                                                    <h4 className=" card-title-dash pb-5">
                                                                                                        <a href="#" className="delete-employment"> <i className="fa-solid fa-trash float-end text-muted  mr_10 border_radius" title="Delete Education" data-bs-toggle="modal" data-bs-target="#DeleteEducation" id="deleteEducationBtn" data-for={ele.id} onClick={() => {
                                                                                                            handleeducationdelete(ele.id)
                                                                                                        }}><span className="f-family"></span></i></a>
                                                                                                        <a href="#" className="edit-profile pb-3"> <i className="fa-solid fa-pencil float right border_radius" title="Edit Education" data-bs-toggle="modal" data-bs-target="#EditEducation" id="editEducationBtn" data-for={ele.id} onClick={() => {
                                                                                                            setcurEduId(ele.id)
                                                                                                        }}><span className="f-family"></span></i></a>
                                                                                                    </h4>
                                                                                                    <ul className="bullet-line-list">
                                                                                                        <li>
                                                                                                            <div className="d-flex justify-content-between">
                                                                                                                <div><span className="text-light-green">
                                                                                                                    {ele?.educationName}  -  {ele?.educationCourseName}  </span>
                                                                                                                    <p className="text-muted">{ele?.educationInstituteName}</p>
                                                                                                                </div>
                                                                                                                <p className="fw-bold text-muted">{ele?.educationCompletedYear}</p>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                ) : (
                                                                                    <div className="cardPad card-body box-shadow pb_30"><p>{t('noRecordsAvailable')}</p></div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="tab-pane fade" id="pills-Certification" role="tabpanel" aria-labelledby="pills-employment-tab">
                                                                        <div className="row">
                                                                            <div className="border-top1">
                                                                                <div className="float-end">
                                                                                    <div className="btn-wrapper float-end">
                                                                                        <a href="#" className="btn btn-primary btn-md text-white mb-3  mw-auto me-0 border-radius-15" title="Add Certification" data-bs-toggle="modal" data-bs-target="#AddCertification"><i className="fa-solid fa-plus-circle"></i> Add</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                certificationInfo.length != 0 ? (
                                                                                    certificationInfo.map((ele) => (
                                                                                        <>

                                                                                            <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                                                                                                <div className="space card card-rounded">
                                                                                                    <div className="card-body">
                                                                                                        <h4 className=" card-title-dash pb-5">
                                                                                                            <a href="#" className="delete-employment"> <i className="fa-solid fa-trash float-end text-muted  mr_10 border_radius" title="Delete Certificate" data-bs-toggle="modal" data-bs-target="#DeleteCertificate" id="deleteCertificateBtn" data-for={ele.id} onClick={() => {
                                                                                                                handlecertificationdelete(ele?.id)
                                                                                                            }}><span className="f-family"></span></i></a>
                                                                                                            <a href="#" className="edit-profile pb-3"> <i className="fa-solid fa-pencil float right border_radius" title="Edit Certification" data-bs-toggle="modal" data-bs-target="#EditCertification" id="editCertificateBtn" data-for={ele.id} onClick={() => {
                                                                                                                setcurCertId(ele?.id)
                                                                                                            }}><span className="f-family"></span></i></a>
                                                                                                        </h4>
                                                                                                        <ul className="bullet-line-list">
                                                                                                            <li>
                                                                                                                <div className="d-flex justify-content-between">
                                                                                                                    <div><span className="text-light-green fw-bold">
                                                                                                                        {t('certificationTitle')}: </span>
                                                                                                                        <span className="text-light-green text-muted">
                                                                                                                            {ele?.certificationTitle}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <div className="d-flex justify-content-between">
                                                                                                                    <div><span className="text-light-green fw-bold">
                                                                                                                        {t('completiondate')} : </span>
                                                                                                                        <span className="text-light-green text-muted">
                                                                                                                            {ele?.completionDate}</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <div className="d-flex justify-content-between">
                                                                                                                    <div><span className="text-light-green fw-bold">
                                                                                                                        {t('organization/institute')}: </span>
                                                                                                                        <span className="text-light-green text-muted">
                                                                                                                            {ele?.certificationOrganization} </span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                            <li>
                                                                                                                <div className="d-flex justify-content-between">
                                                                                                                    <div><span className="text-light-green fw-bold">{t('certification')}: </span>
                                                                                                                        <span className="text-light-green text-muted">
                                                                                                                            <a href={ele?.certificationFiles} className="text-link country" target="_blank">
                                                                                                                                {ele?.certificationFiles ? ele?.certificationFiles.split('/')[3]?.split(S3Config.S3ObjectDelimiter)[0] : ""}</a>
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    ))
                                                                                ) : (
                                                                                    <div className="cardPad card-body box-shadow pb_30"><p>{t('noRecordsAvailable')}</p></div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="tab-pane fade " id="pills-CV" role="tabpanel" aria-labelledby="pills-employment-tab">
                                                                        <div className="border-top1">
                                                                            {/* <div className="float-end">
                                                                            <div className="btn-wrapper float-end">
                                                                                <a href="#" className="btn btn-primary btn-md text-white mb-3  mw-auto me-0 border-radius-15"><i className="fa-solid fa-plus-circle"></i> Add</a>
                                                                            </div>
                                                                        </div> */}
                                                                        </div>
                                                                        <div className="space card card-rounded">
                                                                            <div className="card-body">

                                                                                <div className="text-center" id="uploadCVBtn">

                                                                                    {
                                                                                        CVInfo.length != 0 ? (
                                                                                            CVInfo.map((ele) => (
                                                                                                <>
                                                                                                    <h4 className=" card-title-dash pb-5">
                                                                                                        {/* <a href="#" className="delete-employment"> <i className="fa-solid fa-trash float-end text-muted border_radius mr_10" title="Delete Employment" data-bs-toggle="modal" data-bs-target="#DeleteEmployment" id="deleteEmploymentBtn" data-for="366"><span className="f-family"></span></i></a> */}
                                                                                                        <a href="#" className="edit-profile pb-3" title="Edit"
                                                                                                            data-bs-toggle="modal" data-bs-target="#Edit_UploadCV" data-for={ele?.usercvId}
                                                                                                            onClick={() => {
                                                                                                                setcurCVId(ele?.usercvId)
                                                                                                            }} > <i className="fa-solid fa-pencil float right border_radius"><span className="f-family"></span></i></a>
                                                                                                    </h4>
                                                                                                    {/* <button type="file" data-bs-toggle="modal" data-bs-target="#UploadCV" className="tn btn-warning upload-cv-btn mt-5 btn-icon-text btn-bg-theme"><i className="mdi mdi-file-chart btn-icon-prepend"></i> My-CV.pdf</button>*/}

                                                                                                    <a href={ele?.cv_attachment ? ele?.cv_attachment : "#"} target={ele?.cv_attachment ? "_blank" : ""} className="text-link profileupdate_btncv tn btn-warning upload-cv-btn mt-5 btn-icon-text btn-bg-theme"> <i className="mdi mdi-file-chart btn-icon-prepend"></i> {ele?.user_cv_title} </a>
                                                                                                </>
                                                                                            ))
                                                                                        ) : (
                                                                                            <div className="cardPad card-body box-shadow pb_30"><p>{t('noRecordsAvailable')}</p></div>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="tab-pane fade" id="pills-pi" role="tabpanel" aria-labelledby="pills-employment-tab">
                                                                        <div className="row">
                                                                            <div className="border-top1">
                                                                                {/* <div className="float-end">
                                                                                <div className="btn-wrapper float-end">
                                                                                    <a href="#" className="btn btn-primary btn-md text-white mb-3  mw-auto me-0 border-radius-15"><i className="fa-solid fa-plus-circle"></i> Add</a>
                                                                                </div>
                                                                            </div> */}
                                                                            </div>
                                                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                                                <div className="space card card-rounded">
                                                                                    <div className="card-body">


                                                                                        {personalInfo.length != 0 ? (
                                                                                            personalInfo.map((ele) => (
                                                                                                <>
                                                                                                    <h4 className=" card-title-dash pb-5">
                                                                                                        {/* <a href="#" className="delete-employment"> <i className="fa-solid fa-trash float-end text-muted border_radius mr_10" title="Delete Employment" data-bs-toggle="modal" data-bs-target="#DeleteEmployment" id="deleteEmploymentBtn" data-for="366"><span className="f-family"></span></i></a> */}
                                                                                                        <a href="#" className="edit-profile pb-3"> <i className="fa-solid fa-pencil float right border_radius" title="Edit" data-bs-toggle="modal" data-bs-target="#Personalprofile"
                                                                                                            onClick={() => { setcurPersonalId(ele?.userId) }}><span className="f-family"></span></i></a>
                                                                                                    </h4>
                                                                                                    <ul className="bullet-line-list">
                                                                                                        <li>
                                                                                                            <div className="d-flex justify-content-between">
                                                                                                                <div><span className="text-light-green fw-bold">{t('name')} : </span>
                                                                                                                    <span className="text-light-green text-muted">{ele?.firstName} {ele?.lastName}</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <div className="d-flex justify-content-between">
                                                                                                                <div><span className="text-light-green fw-bold">{t('userName')} : </span>
                                                                                                                    <span className="text-light-green text-muted">{ele?.userName}</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <div className="d-flex justify-content-between">
                                                                                                                <div><span className="text-light-green fw-bold">{t('email')} : </span>
                                                                                                                    <span className="text-light-green text-muted">
                                                                                                                        {ele?.email} </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <div className="d-flex justify-content-between">
                                                                                                                <div><span className="text-light-green fw-bold">{t('gender')} : </span>
                                                                                                                    <span className="text-light-green text-muted">
                                                                                                                        {ele?.genderName}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <div className="d-flex justify-content-between">
                                                                                                                <div><span className="text-light-green fw-bold">Phone no : </span>
                                                                                                                    <span className="text-light-green text-muted">
                                                                                                                        {ele?.countryPhoneCode} {ele?.phoneNumber} </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <div className="d-flex justify-content-between">
                                                                                                                <div><span className="text-light-green fw-bold">{t('country')} : </span>
                                                                                                                    <span className="text-light-green text-muted">
                                                                                                                        <Link to="/cxo/cxo/caasmembers" state={{ searchfilterName: "country", searchFilterKey: ele?.country }}>
                                                                                                                            {ele?.countryName}
                                                                                                                        </Link>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <div className="d-flex justify-content-between">
                                                                                                                <div><span className="text-light-green fw-bold">{t('state')} : </span>
                                                                                                                    <span className="text-light-green text-muted">
                                                                                                                        <Link to="/cxo/cxo/caasmembers" state={{ searchfilterName: "state", searchFilterKey: ele?.state }}>
                                                                                                                            {ele?.stateName}
                                                                                                                        </Link></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <div className="d-flex justify-content-between">
                                                                                                                <div><span className="text-light-green fw-bold">{t('city')} : </span>
                                                                                                                    <span className="text-light-green text-muted">
                                                                                                                        <Link to="/cxo/cxo/caasmembers" state={{ searchfilterName: "city", searchFilterKey: ele?.city }}>
                                                                                                                            {ele?.cityName}
                                                                                                                        </Link> </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <div className="d-flex justify-content-between">
                                                                                                                <div><span className="text-light-green fw-bold">{t('zipCode')} : </span>
                                                                                                                    <span className="text-light-green text-muted">
                                                                                                                        {ele?.zipCode ? ele?.zipCode : "No data"} </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <div className="d-flex justify-content-between">
                                                                                                                <div><span className="text-light-green fw-bold">Po Box : </span>
                                                                                                                    <span className="text-light-green text-muted">
                                                                                                                        {ele?.poBoxNo ? ele?.poBoxNo : "No data"} </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <div className="d-flex justify-content-between">
                                                                                                                <div><span className="text-light-green fw-bold">{t('address')} : </span>
                                                                                                                    <span className="text-light-green text-muted">
                                                                                                                        {ele?.address} </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <div className="d-flex justify-content-between">
                                                                                                                <div><span className="text-light-green fw-bold">{t('caasMemberCode')}  : </span>
                                                                                                                    <span className="text-light-green text-muted">
                                                                                                                        {ele?.cioCode} </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </>
                                                                                            ))
                                                                                        ) : <>{t('noUserDataFound')}</>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="tab-pane fade" id="pills-accountSettings" role="tabpanel" aria-labelledby="pills-employment-tab">
                                                                        <div className="row">
                                                                            <div className="border-top1">
                                                                            </div>
                                                                            <div className="col-lg-3 col-md-12 col-sm-12">
                                                                                <div className="space card card-rounded">
                                                                                    <div className="card-body bg-secondary">

                                                                                       
                                                                                                <div className="d-flex justify-content-between">
                                                                                                    <div className="container d-flex">
                                                                                                        <a href="#" class="text-white mx-auto" title="Delete" data-bs-target="#DeleteAccount" data-bs-toggle="modal"><i class="mdi mdi-delete me-1" aria-hidden="true"></i>Delete My Account</a>
                                                                                                        {/* <a href="#" className="btn btn-danger btn-lg" data-bs-target="#DeleteAccount" data-bs-toggle="modal">Delete My Account</a> */}
                                                                                                    </div>
                                                                                                </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section >
                        <section className="footer-sections"></section>
                    </div >
                )}
            </div >
            <div className="modal fade show" id="DeleteAccount" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">Delete My Account</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-left">
                            {/* <h5 className="fw-bold">Are you sure you want to delete the account?</h5> */}
                            <h1 className="fw-bold fs-5" >{loggedUserEmail}</h1>
                            <p className="notification-text thnku_txt mt_20">Please read this carefully</p>
                            <ul className="list-style-bullet">
<li>You are trying to delete your CAAS Member Account belonging to  {loggedInFirstName} {loggedInLastName}.</li>
<li>You will no longer be able to use your account, your account and data will also be lost and deleted permanently.</li>
<li>All the contents you have posted will also be deleted.</li>
</ul>
                           
                            <div className="form-group text-left mt-4">
                                <label className="fw-bold">Enter your Password</label>
                                <input type="password" className="form-control" id="deleteAccountPassword" placeholder="Password" spellcheck="false" data-ms-editor="true" onKeyPress={handleKeyPress} />
                            </div>
                            <div className="checkbox text-left mt-4">
                                <label>
                                    <input type="checkbox" id="deleteAccountAgree" /><i class="helper"></i> Yes, I agree and I want to permanently delete my CAAS Member Account and all its data.
                                </label>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                            <a href="#" className="btn btn-warning btn-md next-btn" onClick={deleteAccount}>Delete</a>
                            {/* <a type="button" className="btn btn-primary" id="disableAccount" onClick={disableAccount}>Disable</a>
                            <a type="button" className="btn btn-danger" data-bs-dismiss="modal">Cancel</a> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show " id="Personalprofile" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('personalInformation')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div id="personalInfoInnerDiv">
                                    <div className="content clearfix">
                                        <div className="row mb-0">
                                            <div className="form-group col-12 col-sm-6">
                                                <label>{t('firstName')} <span className="error">*</span></label>
                                                <input type="text" className="form-control" data-for="firstName" placeholder={t('firstName')} id="firstName" value={curPersonal?.firstName} onChange={handleInput} />
                                                <label id="firstnameerror" className="error hide">First Name is required</label>
                                            </div>
                                            <div className="form-group col-12 col-sm-6">
                                                <label>{t('lastName')} <span className="error">*</span></label>
                                                <input type="text" className="form-control" placeholder={t('lastName')} id="lastName" value={curPersonal?.lastName} onChange={handleInput} />
                                                <label id="lastnameerror" className="error hide">Last Name is required</label>
                                            </div>
                                            <div className="form-group col-12 col-sm-6">
                                                <label>{t('email')} <span className="error">*</span></label>
                                                <input type="email" className="form-control" placeholder={t('email')} id="email" value={curPersonal?.email} onChange={handleInput} />
                                                <label id="emailerror" className="error hide">Email Address is required</label> </div>
                                            <div className="form-group col-12 col-sm-6">
                                                <label>{t('gender')} </label>
                                                <select className="form-select" id="gender" value={curPersonal?.gender} onChange={handleInput}>
                                                    <option value="0" key={0}>{t('gender')}</option>
                                                    {
                                                        genderMeta.length != 0 ? (
                                                            genderMeta.map((ele, index) => (
                                                                <option key={index} value={ele.genderId}>{ele.genderName}</option>
                                                            ))
                                                        ) : null
                                                    }
                                                </select>
                                                {/* <label id="gendererror" className="error hide">Gender not selected</label> */}
                                            </div>
                                            <div className="form-group col-12 col-sm-6">
                                                <label className="d-block">{t('userName')} <span className="error">*</span></label>
                                                <div><input type="text" className="form-control" placeholder={t('userName')} id="userName" value={curPersonal?.userName} onChange={handleInput} disabled /></div>
                                                <label id="usernameerror" className="error hide">User Name is required</label>
                                                <label id="existingUser" className="error hide">User Name already exists</label>
                                            </div>
                                            <div className="form-group col-12 col-sm-6 mt-4 pt-3">
                                                <div className="text-left"><label className="d-block"><p className="text-danger fw-bold">({t('thisIsYourLoginId')})</p></label></div>
                                            </div>
                                            <div className="form-group col-12 col-sm-6">
                                                <label>{t('country')} <span className="error">*</span></label>
                                                <select className="form-select" id="country" value={curPersonal?.country} onChange={handleInput}>
                                                    <option value="0" key={0}>{t('country')}</option>
                                                    {
                                                        countriesMeta.length != 0 ? (
                                                            countriesMeta.map((ele, index) => (
                                                                <option key={index} value={ele.countryId}>{ele.countryName}</option>
                                                            ))
                                                        ) : null
                                                    }
                                                </select>
                                                <label id="countryerror" className="error hide">Country not selected</label>
                                            </div>
                                            <div className="form-group col-12 col-sm-6">
                                                <label>{t('state')} <span className="error">*</span></label>
                                                <select className="form-select" id="state" onChange={handleInput} value={curPersonal?.state}>
                                                    <option value="0" key={0}>{t('state')}</option>
                                                    {
                                                        curStatesMeta.length != 0 ? (
                                                            curStatesMeta.map((ele, index) => (
                                                                <option key={index} value={ele.stateId}>{ele.stateName}</option>
                                                            ))
                                                        ) : null
                                                    }
                                                </select>
                                                <label id="stateerror" className="error hide">State not selected</label>
                                            </div>
                                            <div className="form-group col-12 col-sm-6">
                                                <label>{t('city')} <span className="error">*</span></label>
                                                <select className="form-select" id="city" value={curPersonal?.city} onChange={handleInput}>
                                                    <option value="0" key={0}>{t('city')}</option>
                                                    {
                                                        curCityList.length != 0 ? (
                                                            curCityList.map((ele, index) => (
                                                                <option key={index} value={ele.cityId}>{ele.cityName}</option>
                                                            ))
                                                        ) : null
                                                    }
                                                </select>
                                                <label id="cityerror" className="error hide">City not selected</label>
                                            </div>
                                            <div className="form-group col-12 col-sm-6">
                                                <label>{t('zipCode')} </label>
                                                <input type="text" className="form-control" placeholder={t('zipCode')} id="zipCode" maxLength="6" value={curPersonal?.zipCode} onChange={handleInput} />
                                                {/* <label id="zipcodeerror" className="error hide">ZipCode is required</label> */}
                                            </div>
                                            <div className="form-group col-12 col-sm-6">
                                                <label>{t('nationality')} </label>
                                                <select className="form-select" id="nationality" value={curPersonal?.nationality} onChange={handleInput}>
                                                    <option value="0" key={0}>{t('select')}</option>
                                                    {
                                                        nationalitiesMeta.length != 0 ? (
                                                            nationalitiesMeta.map((ele, index) => (
                                                                <option key={index} value={ele.nationalityId}>{ele.nationalityName}</option>
                                                            ))
                                                        ) : null
                                                    }
                                                </select>
                                                {/* <label id="nationalityerror" className="error hide">Nationality not selected</label> */}
                                            </div>
                                            <div className="form-group col-12 col-sm-6">
                                                <label>{t('mobilePhone')} <span className="error">*</span></label>
                                                <input type="text" className="form-control" placeholder={t('mobilePhone')} id="phoneNumber" maxLength="10" value={curPersonal?.phoneNumber} onChange={handleInput} />
                                                <label id="phonenumbererror" className="error hide">PhoneNumber is required</label>
                                            </div>
                                            {/* <div className="form-group col-12 col-sm-6">
                                                <label>{t('linkedIn')}</label>
                                                <input type="text" className="form-control" placeholder={t('linkedIn')} id="linkedIn" onChange={handleInput} />
                                                <label id="linkedinerror" className="error hide">LinkedIn Profile is required</label>
                                            </div> */}
                                            <div className="form-group col-12 col-sm-6">
                                                <label>PO Box </label>
                                                <input type="text" className="form-control" placeholder="PO Box" id="poBoxNo" value={curPersonal?.poBoxNo} maxLength="6" onChange={handleInput} />
                                                {/* <label id="poboxeerror" className="error hide">PO Box is required</label> */}
                                            </div>
                                            <div className="form-group col-12 col-sm-6">
                                                <label>{t('address')} <span className="error">*</span></label>
                                                <textarea className="form-control" placeholder={t('address')} id="address" value={curPersonal?.address} onChange={handleInput}></textarea>
                                                <label id="addresserror" className="error hide">Address is required</label>
                                            </div>
                                            {/* {personalInfo.zipCode && <div className="form-group col-12 col-sm-12">
                                                                                        <label>GEO Location</label>
                                                                                        <div className="mapouter img-thumbnail"><div className="gmap_canvas">
                                                                                            <iframe className="gmap_iframe" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={getMap}>
                                                                                            </iframe> 
                                                                                        </div></div>
                                                                                    </div>} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                            <a href="#" className="btn btn-warning btn-md next-btn" onClick={handleSubmitPersonalInfo}>{t('save')}</a>

                        </div>
                    </div>
                </div>
            </div>





            <div className="modal fade show " id="ProfileSummary" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('hprofile')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="form-group col-12 col-sm-12">
                                    <label>{t('profileTitle')} <span className="error">*</span></label>
                                    <textarea className="form-control" placeholder={t('profileTitle')} rows="4" id="profileTitle" defaultValue={curProfile?.profileTitle} onChange={handleClick_Profile}></textarea>
                                    <label id="profileTitleerror" className="error hide">Profile Title is required</label>
                                </div>
                                <div className="form-group col-12 col-sm-12">
                                    <label>{t('profileSummary')} <span className="error">*</span></label>
                                    <textarea className="form-control" placeholder={t('profileSummary')} rows="4" id="profileSummary" defaultValue={curProfile?.profileSummary} onChange={handleClick_Profile}></textarea>
                                    <label id="profileSummaryerror" className="error hide">Profile Summary is required</label>
                                </div>
                                <div className="form-group col-12 col-sm-12">
                                    {/* <label>{t('languages')} <span className="error">*</span></label> */}
                                    <label>Preferred Language <span className="error">*</span></label>

                                    <select className="form-select" id="profileLanguages" value={editprofileInfo.profileLanguages ? editprofileInfo.profileLanguages : curProfile?.languageId} onChange={handleClick_Profile}>
                                        <option value="0" key={0}>{t('select')}</option>
                                        {
                                            languagesMeta.length != 0 ? (
                                                languagesMeta.map((ele, index) => (
                                                    <option key={index} value={ele.languageId}>{ele.languageName}</option>
                                                ))
                                            ) : null
                                        }
                                    </select>
                                    <label id="profilelanguageerror" className="error hide">Profile language not selected</label>
                                </div>
                                <div className="form-group col-12 col-sm-12">
                                    <label>{t('uploadaProfilePhoto')}
                                        {/* <span className="error">*</span> */}
                                    </label>
                                    <span className='ml_10'><a href={curProfilePicture} className="text-link country" target="_blank">
                                        {curProfilePicture ? curProfilePicture.split('/')[3]?.split(S3Config.S3ObjectDelimiter)[0] : ""}</a></span>
                                    <input className="form-control" type="file" multiple="" id="profilePicture" accept="image/png, image/jpeg" onChange={handleClick_Profile} />
                                    <label id="profilephotoerror" className="error hide">Profile photo not uploaded</label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                            <a href="#" className="btn btn-warning btn-md next-btn" onClick={handleSubmitProfile}>{t('save')}</a>

                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade show" id="EditExperience">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('experience/skill')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <form id="addexperienceForm">
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('skill/softwareName')} <span className="error">*</span></label>
                                        <select className="form-select _experienceSkillId" data-for="experienceSkillId" value={curExp?.experienceSkillId} onChange={editExperience} >
                                            <option value="0" key={0}>Select</option>
                                            {
                                                skilldetailsMeta.length != 0 ? (
                                                    skilldetailsMeta.map((ele, index) => (
                                                        <option key={index} value={ele.skillId}>{ele.skillName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="_skillNameerror" className="error hide">Skill is required</label>
                                        {/* <input type="text" className="form-control" data-for="experienceSkill" value={curExp?.experienceSkill} onChange={editExperience} /> */}
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('softwareVersion')} <span className="error">*</span></label>
                                        <select className="form-select _experienceSoftwareVersionId" data-for="experienceSoftwareVersionId" value={curExp?.experienceSoftwareVersionId} onChange={editExperience} >
                                            <option value="0" key={0}>Select</option>
                                            {
                                                editskillVersionList.length != 0 ? (
                                                    editskillVersionList.map((ele, index) => (
                                                        <option key={index} value={ele.skillVersionId}>{ele.skillVersionName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        {/* <input type="text" className="form-control" data-for="experienceSoftwareVersion" value={curExp?.experienceSoftwareVersion} onChange={editExperience} /> */}
                                        <label id="_softwareVererror" className="error hide">Skill Version is required</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('lastUsed')} <span className="error">*</span></label>
                                        <select className="form-select _experienceLastUsed" data-for="experienceLastUsed" value={curExp?.experienceLastUsed} onChange={editExperience}>
                                            <option value="0">Select</option>
                                            <option >2020</option>
                                            <option >2021</option>
                                            <option >2022</option>
                                        </select>
                                        <label id="_experienceLastUsederror" className="error hide">Skill Last used is required</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('years')} <span className="error">*</span></label>
                                        <select className="form-select _experienceYears" data-for="experienceYears" value={curExp?.experienceYears} onChange={editExperience}>
                                            <option value="0">Select</option>
                                            <option value="1">1 Years</option>
                                            <option value="2">2 Years</option>
                                            <option value="3">3 Years</option>
                                            <option value="4">4 Years</option>
                                            <option value="5">5 Years</option>
                                            <option value="6">6 Above</option>
                                        </select>
                                        <label id="_experienceYearserror" className="error hide">Skill Years is required</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('implementationSize')} <span className='imp_size mr_5'>(in numbers)</span><i className="fa fa-info-circle" title="How many end-users have experienced your skill?"></i></label>
                                        <input type="text" className="form-control _experienceImplementationSize" placeholder='How many end-users have experienced your skill?' data-for="experienceImplementationSize" value={curExp?.experienceImplementationSize} onChange={editExperience} />
                                        {/* <label id="_experienceImplementationSizeerror" className="error hide">Implementation Size is required</label> */}
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('experienceLevel')} <span className="error">*</span></label>
                                        <select className="form-select _experienceLevel" data-for="experienceLevel" value={curExp?.experienceLevel} onChange={editExperience}>
                                            <option value="0" key={0}>Select</option>
                                            {
                                                skillexperiencelevelsMeta.length != 0 ? (
                                                    skillexperiencelevelsMeta.map((ele, index) => (
                                                        <option key={index} value={ele.skillExperienceId}>{ele.skillExperienceLevel}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="_experienceLevelerror" className="error hide">Experience Level is required</label>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                            <a className="btn btn-warning btn-md next-btn editSave" onClick={editsaveskills}>{t('save')}</a>

                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade show" id="AddExperience" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('experience/skill')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <form id="addExperienceForm">
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('skill/softwareName')} <span className="error">*</span></label>
                                        <select className="form-select" id="experienceSkill" onChange={resetversionList}>
                                            <option value="0" key={0}>Select</option>
                                            {
                                                skilldetailsMeta.length != 0 ? (
                                                    skilldetailsMeta.map((ele, index) => (
                                                        <option key={index} value={ele.skillId}>{ele.skillName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        {/* <input type="text" className="form-control" id="experienceSkill" /> */}
                                        <label id="skillNameerror" className="error hide">Skill is required</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('softwareVersion')} <span className="error">*</span></label>
                                        <select className="form-select" id="experienceSoftwareVersion" onChange={skillvalidation}>
                                            <option value="0" key={0}>Select</option>
                                            {
                                                curskillVersionList.length != 0 ? (
                                                    curskillVersionList.map((ele, index) => (
                                                        <option key={index} value={ele.skillVersionId}>{ele.skillVersionName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        {/* <input type="text" className="form-control" id="experienceSoftwareVersion" /> */}
                                        <label id="softwareVererror" className="error hide">Skill Version is required</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('lastUsed')} <span className="error">*</span></label>
                                        <select className="form-select" id="experienceLastUsed" onChange={skillvalidation}>
                                            <option value="0">Select</option>
                                            <option >2020</option>
                                            <option >2021</option>
                                            <option >2022</option>
                                        </select>
                                        <label id="experienceLastUsederror" className="error hide">Skill Last used is required</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('years')} <span className="error">*</span></label>
                                        <select className="form-select" id="experienceYears" onChange={skillvalidation}>
                                            <option value="0">Select</option>
                                            <option value="1">1 Years</option>
                                            <option value="2">2 Years</option>
                                            <option value="3">3 Years</option>
                                            <option value="4">4 Years</option>
                                            <option value="5">5 Years</option>
                                            <option value="6">6 Above</option>
                                        </select>
                                        <label id="experienceYearserror" className="error hide">Skill Years is required</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('implementationSize')} <span className='imp_size mr_5'>(in numbers)</span><i className="fa fa-info-circle" title="How many end-users have experienced your skill?"></i></label>
                                        <input type="text" className="form-control" placeholder='How many end-users have experienced your skill?' id="experienceImplementationSize" onChange={skillvalidation} />
                                        {/* <label id="experienceImplementationSizeerror" className="error hide">Implementation Size is required</label> */}
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('experienceLevel')} <span className="error">*</span></label>
                                        <select className="form-select" id="experienceLevel" onChange={skillvalidation}>
                                            <option value="0" key={0}>Select</option>
                                            {
                                                skillexperiencelevelsMeta.length != 0 ? (
                                                    skillexperiencelevelsMeta.map((ele, index) => (
                                                        <option key={index} value={ele.skillExperienceId}>{ele.skillExperienceLevel}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="experienceLevelerror" className="error hide">Experience Level is required</label>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                            <a className="btn btn-warning btn-md next-btn" id="myModalsave" onClick={addExperience}>{t('save')}</a>

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="EditEmployment" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('editEmployment')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="form-group col-12 col-sm-12">
                                    <label>{t('companyName')} <span className="error">*</span></label>
                                    <input type="text" className="form-control _employmentCompany" placeholder={t('companyName')} data-for="employmentCompany" value={curEmp?.employmentCompany} onChange={editEmployment} />
                                    <label id="_employmentCompanyerror" className="error hide">Company Name is required</label>
                                </div>
                                <div className="form-group col-12 col-sm-12">
                                    <label>{t('country')} <span className="error">*</span></label>
                                    <select className="form-select _employmentCountry" data-for="employmentCountry" value={curEmp?.employmentCountry} onChange={editEmployment}>
                                        <option value="0" key={0}>{t('country')}</option>
                                        {
                                            countriesMeta.length != 0 ? (
                                                countriesMeta.map((ele, index) => (
                                                    <option key={index} value={ele.countryId}>{ele.countryName}</option>
                                                ))
                                            ) : null
                                        }
                                    </select>
                                    <label id="_countryerror" className="error hide">Country not selected</label>
                                </div>
                                <div className="form-group col-12 col-sm-12">
                                    <label>{t('state')} <span className="error">*</span></label>
                                    <select className="form-select _employmentState" data-for="employmentState" value={curEmp?.employmentState} onChange={editEmployment}>
                                        <option value="0" key={0}>{t('state')}</option>
                                        {
                                            curStatesMeta.length != 0 ? (
                                                curStatesMeta.map((ele, index) => (
                                                    <option key={index} value={ele.stateId}>{ele.stateName}</option>
                                                ))
                                            ) : null
                                        }
                                    </select>
                                    <label id="stateerror" className="error hide">State not selected</label>
                                </div>
                                <div className="form-group col-12 col-sm-12">
                                    <label>{t('city')} <span className="error">*</span></label>
                                    <select className="form-select _employmentCity" data-for="employmentCity" value={curEmp?.employmentCity} onChange={editEmployment}>
                                        <option value="0" key={0}>{t('city')}</option>
                                        {
                                            curCityList.length != 0 ? (
                                                curCityList.map((ele, index) => (
                                                    <option key={index} value={ele.cityId}>{ele.cityName}</option>
                                                ))
                                            ) : null
                                        }
                                    </select>
                                    <label id="_employmentCityerror" className="error hide">City not selected</label>
                                </div>
                                <div className="form-group col-12 col-sm-12">
                                    <label>{t('designation')} <span className="error">*</span></label>
                                    <input type="text" className="form-control _employmentDesignation" placeholder={t('designation')} data-for="employmentDesignation" value={curEmp?.employmentDesignation} onChange={editEmployment} />
                                    <label id="_employmentDesignationerror" className="error hide">Designation is Required</label>
                                </div>
                                <div className="form-group col-12 col-sm-12">
                                    <label>{t('yearsOfExperience')} <span className="error">*</span></label>
                                    <input type="text" className="form-control _employmentYears" placeholder={t('yearsOfExperience')} data-for="employmentYears" value={curEmp?.employmentYears} onChange={editEmployment} />
                                    <label id="_employmentYearserror" className="error hide">Years of Experience Required</label>
                                </div>
                                <div className="form-group col-12 col-sm-12">
                                    <label>{t('skillsUsed')} <span className="error">*</span></label>
                                    {/* <select className="form-select" data-for="employmentSkills" value={curEmp?.employmentSkills} onChange={editEmployment} >
                                            <option value="0" key={0}>Select</option>
                                            {
                                                skilldetailsMeta.length != 0 ? (
                                                    skilldetailsMeta.map((ele, index) => (
                                                        <option key={index} value={ele.skillId}>{ele.skillName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select> */}


                                    {/* <textarea className="form-control _employmentSkills" placeholder={t('skillsUsed')} rows="4" data-for="employmentSkills" value={curEmp?.employmentSkills} onChange={editEmployment}></textarea> */}
                                    <label id="_employmentSkillserror" className="error hide">Skills are Required</label>
                                    {/* <input id="buttonAdd" type="button" value="Click here to Add Textbox" onClick={handleadd} /> */}
                                    <i className="fa-solid fa-plus w-10 btn-primary add-pi float-right btn-rounded btn-icon addbox" onClick={handleadd}></i>
                                    <div id="TextBoxContainer">
                                    </div>
                                    {/* <input id="buttonGet" type="button" value="Show Values" onClick={handleshow} /> */}
                                    {
                                        curEmpSkill.length != 0 ? (
                                            curEmpSkill.map((empskill) => (
                                                <button className="mb-0 w-150 me-4 mb-4 border-primary">
                                                    <a className="d-blocktext-black-50 text-dark fs-5 fw-bold">{empskill?.skillName}</a>
                                                    <i className="fa-solid fa-trash mt-1 ms-2 fs-6 text-muted" title="Delete" onClick={() => { UpdateEmploymentSkillInfo(empskill?.userEmploymentSkillId, 0, '') }}></i></button>

                                            ))
                                        ) : <>{t('noUserDataFound')}</>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                            <a href="#" className="btn btn-warning btn-md next-btn" onClick={editsavesemployment}>{t('save')}</a>

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="AddEmployment" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('addEmployment')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <form id="addEmploymentForm">
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('companyName')} <span className="error">*</span></label>
                                        <input type="text" className="form-control" placeholder={t('companyName')} id="employmentCompany" onChange={employmentValidation} />
                                        <label id="employmentCompanyerror" className="error hide">Company Name is required</label>

                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('country')} <span className="error">*</span></label>
                                        <select className="form-select" id="employmentCountry" data-for="addemploymentCountry" onChange={handleEmploymentLocation}>
                                            <option value="0" key={0}>{t('country')}</option>
                                            {
                                                countriesMeta.length != 0 ? (
                                                    countriesMeta.map((ele, index) => (
                                                        <option key={index} value={ele.countryId}>{ele.countryName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="employmentCountryerror" className="error hide">Country not selected</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('state')} <span className="error">*</span></label>
                                        <select className="form-select" id="employmentState" data-for="addemploymentState" onChange={handleEmploymentLocation}>
                                            <option value="0" key={0}>{t('state')}</option>
                                            {
                                                curStatesMeta.length != 0 ? (
                                                    curStatesMeta.map((ele, index) => (
                                                        <option key={index} value={ele.stateId}>{ele.stateName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="employmentStateerror" className="error hide">State not selected</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('city')} <span className="error">*</span></label>
                                        <select className="form-select" id="employmentCity" data-for="addemploymentCity" onChange={handleEmploymentLocation}>
                                            <option value="0" key={0}>{t('city')}</option>
                                            {
                                                curempCityList.length != 0 ? (
                                                    curempCityList.map((ele, index) => (
                                                        <option key={index} value={ele.cityId}>{ele.cityName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="employmentCityerror" className="error hide">City not selected</label>
                                    </div>



                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('designation')} <span className="error">*</span></label>
                                        <input type="text" className="form-control" placeholder={t('designation')} id="employmentDesignation" onChange={employmentValidation} />
                                        <label id="employmentDesignationerror" className="error hide">Designation is Required</label>

                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('yearsOfExperience')} <span className="error">*</span></label>
                                        <input type="text" className="form-control" placeholder={t('yearsOfExperience')} id="employmentYears" onChange={employmentValidation} />
                                        <label id="employmentYearserror" className="error hide">Years of Experience Required</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('skillsUsed')} <span className="error">*</span></label>
                                        {/* <select className="form-select" id="employmentSkills">
                                                <option value="0" key={0}>Select</option>
                                                {
                                                    skilldetailsMeta.length != 0 ? (
                                                        skilldetailsMeta.map((ele, index) => (
                                                            <option key={index} value={ele.skillId}>{ele.skillName}</option>
                                                        ))
                                                    ) : null
                                                }
                                            </select> */}



                                        <textarea className="form-control" placeholder={t('skillsUsed')} rows="4" id="employmentSkills" onChange={employmentValidation}></textarea>
                                        <label id="employmentSkillserror" className="error hide">Skills are Required</label>
                                        {/* <i className="fa-solid fa-plus addbox" onClick={handleaddskill}></i>
                                    <div id="addTextBoxContainer">
                                    </div> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                            <a href="#" className="btn btn-warning btn-md next-btn" onClick={addEmployment}>{t('save')}</a>

                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade show" id="EditEducation" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('education')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <form id="editEducationForm">
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('education')} <span className="error">*</span></label>
                                        <select className="form-select _educationId" id="eduname" data-for="educationId" data-affects-meta="educationsMeta" data-affects="educationName" value={curEdu?.educationId} onChange={editEducation}>
                                            <option value="0" key={0}>{t('select')}</option>
                                            {
                                                educationsMeta.length != 0 ? (
                                                    educationsMeta.map((ele, index) => (
                                                        <option key={index} value={ele.educationId}>{ele.educationName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="_educationIderror" className="error hide">Education not selected</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('course')} <span className="error">*</span></label>
                                        <select className="form-select _educationCourseId" id="educoursename" data-for="educationCourseId" data-affects-meta="coursesMeta" data-affects="educationCourseName" value={curEdu?.educationCourseId} onChange={editEducation} >
                                            <option value="0" key={0}>{t('select')}</option>
                                            {
                                                coursesMeta.length != 0 ? (
                                                    coursesMeta.map((ele, index) => (
                                                        <option key={index} value={ele.courseId}>{ele.courseName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="_educationCourseIderror" className="error hide">Course not selected</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('university/institute')} <span className="error">*</span></label>
                                        <select className="form-select _educationInstituteId" id="eduInstitutename" data-for="educationInstituteId" data-affects-meta="institutesMeta" data-affects="educationInstituteName" value={curEdu?.educationInstituteId} onChange={editEducation}>
                                            <option value="0" key={0}>{t('select')}</option>
                                            {
                                                institutesMeta.length != 0 ? (
                                                    institutesMeta.map((ele, index) => (
                                                        <option key={index} value={ele.instituteId}>{ele.instituteName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="_educationInstituteIderror" className="error hide">Institute not selected</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('courseType')} <span className="error">*</span></label>
                                        <select className="form-select _educationCourseTypeId" id="educoursetypename" data-for="educationCourseTypeId" data-affects-meta="coursetypesMeta" data-affects="educationCourseTypeName" value={curEdu?.educationCourseTypeId} onChange={editEducation}>
                                            <option value="0" key={0}>{t('select')}</option>
                                            {
                                                coursetypesMeta.length != 0 ? (
                                                    coursetypesMeta.map((ele, index) => (
                                                        <option key={index} value={ele.courseTypeId}>{ele.courseTypeName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="_educationCourseTypeIderror" className="error hide">Course Type not selected</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('completedYear')} <span className="error">*</span></label>
                                        {/* <select className="form-select _educationCompletedYear" data-for="educationCompletedYear" data-affects="none" value={curEdu?.educationCompletedYear} onChange={editEducation}>
                                            <option value="0">{t('select')}</option>
                                            <option >
                                                2016
                                            </option>
                                            <option >
                                                2017
                                            </option>
                                            <option >
                                                2018
                                            </option>
                                            <option >
                                                2019
                                            </option>
                                            <option >
                                                2020
                                            </option>
                                            <option >
                                                2021
                                            </option>
                                            <option >
                                                2022
                                            </option>
                                        </select> */}
                                        <input type="number" className="form-select _educationCompletedYear" data-for="educationCompletedYear" data-affects="none" min="1900" max="2022" maxLength="4" value={curEdu?.educationCompletedYear} onChange={editEducation} />
                                        <label id="_educationCompletedYearerror" className="error hide">Year not provided</label>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                            <a href="#" className="btn btn-warning btn-md next-btn" onClick={editsavesEducation}>{t('save')}</a>

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="AddEducation" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('education')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <form id="addEducationForm">
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('education')} <span className="error">*</span></label>
                                        <select className="form-select" id="educationId" onChange={educationvalid} >
                                            <option value="0" key={0}>{t('select')}</option>
                                            {
                                                educationsMeta.length != 0 ? (
                                                    educationsMeta.map((ele, index) => (
                                                        <option key={index} value={ele.educationId}>{ele.educationName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="educationIderror" className="error hide">Education not selected</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('course')} <span className="error">*</span></label>
                                        <select className="form-select" id="educationCourseId" onChange={educationvalid} >
                                            <option value="0" key={0}>{t('select')}</option>
                                            {
                                                coursesMeta.length != 0 ? (
                                                    coursesMeta.map((ele, index) => (
                                                        <option key={index} value={ele.courseId}>{ele.courseName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="educationCourseIderror" className="error hide">Course not selected</label>

                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('university/institute')} <span className="error">*</span></label>
                                        <select className="form-select" id="educationInstituteId" onChange={educationvalid} >
                                            <option value="0" key={0}>{t('select')}</option>
                                            {
                                                institutesMeta.length != 0 ? (
                                                    institutesMeta.map((ele, index) => (
                                                        <option key={index} value={ele.instituteId}>{ele.instituteName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="educationInstituteIderror" className="error hide">Institute not selected</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('courseType')} <span className="error">*</span></label>
                                        <select className="form-select" id="educationCourseTypeId" onChange={educationvalid} >
                                            <option value="0" key={0}>{t('select')}</option>
                                            {
                                                coursetypesMeta.length != 0 ? (
                                                    coursetypesMeta.map((ele, index) => (
                                                        <option key={index} value={ele.courseTypeId}>{ele.courseTypeName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="educationCourseTypeIderror" className="error hide">Course Type not selected</label>

                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('completedYear')} <span className="error">*</span></label>
                                        {/* <select className="form-select" id="educationCompletedYear" onChange={educationvalid} >
                                            <option value="0" key={0}>{t('select')}</option>
                                            <option >
                                                2016
                                            </option>
                                            <option >
                                                2017
                                            </option>
                                            <option >
                                                2018
                                            </option>
                                            <option >
                                                2019
                                            </option>
                                            <option >
                                                2020
                                            </option>
                                            <option >
                                                2021
                                            </option>
                                            <option >
                                                2022
                                            </option>
                                        </select> */}
                                        <input type="number" className="form-select" id="educationCompletedYear" min="1900" max="2022" maxLength="4" onChange={educationvalid} />
                                        <label id="educationCompletedYearerror" className="error hide">Year not provided</label>
                                        {/* <label id="educationCompletedYearerror" className="error hide">Year not selected</label> */}

                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                            <a href="#" className="btn btn-warning btn-md next-btn" onClick={addEducation}>{t('save')}</a>

                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade show" id="EditCertification" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('certification')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="form-group col-12 col-sm-12">
                                    <label>{t('certificationTitle')} <span className="error">*</span></label>
                                    <input type="text" className="form-control _certificationTitle" placeholder={t('certificationTitle')} data-for="certificationTitle" value={curCert?.certificationTitle} onChange={EditCertification} />
                                    <label id="_certificationTitleerror" className="error hide"> Certification title not provided</label>
                                </div>
                                <div className="form-group col-12 col-sm-12">
                                    <label>{t('completiondate')} <span className="error">*</span></label>
                                    <input type="date" className="form-control _certificationCompletionDate" data-for="certificationCompletionDate" value={curCert?.completionDate} onChange={EditCertification} />
                                    <label id="_certificationCompletionDateerror" className="error hide"> Certification completion date not provided</label>
                                </div>
                                <div className="form-group col-12 col-sm-12">
                                    <label>{t('organization')} <span className="error">*</span></label>
                                    <input type="text" className="form-control _certificationOrganization" placeholder={t('organization')} data-for="certificationOrganization" value={curCert?.certificationOrganization} onChange={EditCertification} />
                                    <label id="_certificationOrganizationerror" className="error hide">Certification organization not provided</label>
                                </div>
                                <div className="form-group col-12 col-sm-12">
                                    <label>{t('uploadaCertification')} <span className="error">*</span></label>
                                    <input className="form-control _certificationFiles" type="file" multiple="" data-for="certificationFiles" onChange={EditCertification} />
                                    <label id="_certificationFileserror" className="error hide">Certification files not provided</label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                            <a href="#" className="btn btn-warning btn-md next-btn" onClick={updateCertification}>{t('save')}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="AddCertification" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('certification')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <form id="addCertificationForm">
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('certificationTitle')} <span className="error">*</span></label>
                                        <input type="text" className="form-control" placeholder={t('certificationTitle')} id="certificationTitle" onChange={certificatevalid} />
                                        <label id="certificationTitleerror" className="error hide"> Certification title not provided</label>
                                    </div>

                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('completiondate')} <span className="error">*</span></label>
                                        <input type="date" className="form-control" placeholder={t('completiondate')} id="certificationCompletionDate" onChange={certificatevalid} />
                                        <label id="certificationCompletionDateerror" className="error hide"> Certification completion date not provided</label>

                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('organization')} <span className="error">*</span></label>
                                        <input type="text" className="form-control" placeholder={t('organization')} id="certificationOrganization" onChange={certificatevalid} />
                                        <label id="certificationOrganizationerror" className="error hide">Certification organization not provided</label>

                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('uploadaCertification')} <span className="error">*</span></label>
                                        <input className="form-control" type="file" multiple="" id="certificationFiles" onChange={uploadCertificateFile} />
                                        <label id="certificationFileserror" className="error hide">Certification files not provided</label>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                            <a href="#" className="btn btn-warning btn-md next-btn" onClick={addCertification}>{t('save')}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="Edit_UploadCV" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('uploadaCV')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <form id="addCVForm">
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('cvTitle')} <span className="error">*</span></label>
                                        <input type="text" className="form-control" id="user_cv_title" value={curCVInfo?.user_cv_title} onChange={editCVInfo} placeholder={t('cvTitle')} />
                                        <label id="cvTitleerror" className="error hide">CV Title is Required</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('uploadaCV')} <span className="error">*</span></label>
                                        <input className="form-control" type="file" id="cv_attachment" onChange={addCVAttachmentFile} multiple="" />
                                        <label id="cvAttachementerror" className="error hide">Attachments is Required </label>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                            <a href="#" className="btn btn-warning btn-md next-btn" onClick={UploadCVInfo}>{t('save')}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="Industry" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('industry')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputName1">{t('industry')} </label>
                                        <select className="form-select" multiple id="experienceIndustry" onChange={addUpdIndustry}>
                                            <option value="0">---Choose---</option>

                                            {
                                                industrydetails.length != 0 ? (
                                                    industrydetails.map((ele, index) => (
                                                        <option key={index} value={ele.industryId}>{ele.industryName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                            <a className="btn btn-warning btn-md next-btn" onClick={UpdateCXOIndustryInfo}>{t('save')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CXOMyProfileView
