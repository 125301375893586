import React, { useEffect } from 'react'
import "../../assets/fontawesome6/css/all.min.css";
import "../../assets/css/materialdesignicons.min.css"
import "../../assets/css/themify-icons.css"
import "../../assets/css/style.css"
import "../../assets/css/select2-bootstrap.min.css"
import "../../assets/css/select2.min.css"
import "../../assets/css/custom.css"
import "../../assets/css/userend.css"
import "../../assets/css/responsive.css"
import SocialHeader from './commons/SocialHeader';
import SocialFeedNavigations from './commons/SocialFeedNavigations';
import CAASWorkspaceNavigations from './commons/CAASWorkspaceNavigations';
import ProjectAnnouncements from './commons/ProjectAnnouncements';
import CompanyAnnouncements from './commons/CompanyAnnouncements';
import AddToFeed from './commons/AddToFeed';
import Conversations from './commons/Conversations';
import Posts from './Posts';
import $ from "jquery";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { quillModules } from '../../utils/quillModules';
import { useState } from 'react';
import { API } from '../../config/APIConfig';
import axios from 'axios';
import S3FileUpload from '../../utils/S3FileUpload';
import { S3Config } from '../../config/AWSConfig';
import logger from '../../utils/logger';
import swal from 'sweetalert';
import profilepic from "../../assets/images/noav.jpg"
// import toast, { Toaster } from "react-hot-toast";
import { Toaster } from 'react-hot-toast';
import { successToast, celebrationToast, errorToast } from '../../utils/toastUtil';
import Connections from './commons/Connections';
import resize from '../../utils/resize';
// import resetHeights from '../../utils/resize';
import SearchedPosts from './SearchedPosts';
import uuidCustom from '../../utils/uuidCustom';
const PersonalWall = () => {
    const [postContent, setPostContent] = useState("");
    const [postMedia, setPostMedia] = useState([]);
    const [searchMode, setSearchMode] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState(false);
    const [subscibedGroups, setSubscribedGroups] = useState([]);
    const curUserFirstName = sessionStorage.getItem("firstname");
    const curUserLastName = sessionStorage.getItem("lastname");
    const curUserFullName = curUserFirstName + " " + curUserLastName;
    const curUserRoleId = sessionStorage.getItem("roleId");
    const profilePicture = sessionStorage.getItem("profilePicture");
    const curUserEntityId = sessionStorage.getItem("entityId");
    const userWallId = sessionStorage.getItem("userWallId");
    const curUserId = sessionStorage.getItem("userId");
    const CreatePostAPI = `${API.URL}api/v1/social/post/create`;
    const SubscribedGroupListAPI = `${API.URL}api/v1/social/group/participation/list`;


    useEffect(() => {
        if (postContent)
            $("#submitPostBtn").removeClass("disabled");
        else
            $("#submitPostBtn").addClass("disabled");
    }, [postContent])
    useEffect(() => {
        var highlightele = document.getElementById("homeLink");
        highlightele.classList.remove("text-black-50");
        highlightele.classList.add("text-link");
        $("body").css("height", '');
        $("html").removeClass();
        $("html").addClass('personalwall');
        bindEventHandlers();
        getSubscribedGroups();
    }, []);
    const bindEventHandlers = () => {
        $('#globalSearchInput').removeClass("d-none");
        $('#globalSearchBtn').removeClass("d-none");
        $("#globalSearchInput").keyup(function () {
            const value = $("#globalSearchInput").val();
            setSearchKeyword(value);
            if (value === "")
                setSearchMode(false);
            else
                setSearchMode(true);
        });
        $('#globalSearchInput').keypress(function (e) {
            setSearchMode(false);
            var key = e.which;
            if (key == 13)  // the enter key code
            {
                if (searchKeyword === "")
                    setSearchMode(false);
                else
                    setSearchMode(true);
            }
        });
    }

    const fileUpload = async (e) => {       
        const file = e.target.files[0];
        // const fileName = new Date().toISOString() + "|" +  e.target.files[0].name;
        var fileName = e.target.files[0].name;   
        var fileExtension =fileName.split('.').pop();  
        fileName +=  S3Config.S3ObjectDelimiter + uuidCustom() +"."+ fileExtension;
        const contentType = file.type;
        const formData = { file, fileName, contentType };
        let result = await S3FileUpload(formData).then(
            (response) => {
                if (response.status === 200) {
                    return { status: 200, s3FileUrl: S3Config.S3ObjectBaseURL + fileName, fileName: fileName, contentType: contentType }
                }
            }
        ).catch(err => console.log(err));
        return result;
    }
    const uploadPostMedia = (e) => {
        const [media] = e.target.files;
        let previewMedia = URL.createObjectURL(media);
        fileUpload(e).then(
            (response) => {              
                let obj = {
                    id: postMedia.length + 1,
                    mediaTitle: response?.fileName,
                    mediaURL: response?.s3FileUrl,
                    mediaType: response?.contentType,
                    previewMedia: previewMedia
                }
                setPostMedia([...postMedia, obj]);
                console.log(postMedia);
            }).catch(err => console.log(err));
    }

    const removeMedia = (e) => {
        let media_id = e.target.getAttribute("data-media-id")
        const filtered_postmedia = postMedia.filter((media) => media.id != media_id)
        const new_postmedia = filtered_postmedia.map((obj, index) => {
            return { ...obj, id: index + 1 };
        });
        setPostMedia(new_postmedia);

    }
    const submitPost = () => {
        const groupId = $("#groupSelectDropdown").val();
        // logger.verbose("Create Post", "PersonalWall Component", "SubmitPost Button", "POST", "For Creating Post Submit Button clicked ", "");   
        console.log(postContent)
        if ((!postContent || postContent == "<p><br></p>") && postMedia.length === 0) {
            // swal("Post Content is empty", "Please Add Some Content", "error");
            errorToast("Post Content is empty")
            // logger.error("Create Post", "PersonalWall Component", "SubmitPost Button", "POST", "Post Content is empty ", postContent);   
            return
        }
        else {
            const postData = [];
            const postDataObj =
            {
                "socialEntityTypeId": "4",
                "parentSocialEntityTypeId": "1",
                "parentSocialEntityId": userWallId,
                "postContent": postContent,
                "createdByUserId": curUserId,
                "createdByUserEntityTypeId": curUserEntityId,
                "createdByUserRoleId": curUserRoleId
            }
            postData.push(postDataObj);
            const objdata = {
                "postDetails": postData,
                "mediaDetails": postMedia
            }
            // logger.verbose("Create Post", "PersonalWall Component", "SubmitPost Button", "POST", "Post data Object creation ", JSON.stringify(objdata));
            const headers = {
                'Content-Type': 'application/json;charset=utf-8',
            }
            axios.post(CreatePostAPI, objdata, {
                headers: headers
            }).then(response => {
                if (response.data.status === 200) {
                    logger.verbose("Create Post", "PersonalWall Component", "SubmitPost Button", "POST", "Post Created Successfully!", JSON.stringify(response.data.response));
                    if (groupId != '0') {
                        submitGroupPost(groupId);
                    } else {
                        successToast('Post Created Successfully!')
                        setPostContent("");
                        setPostMedia([]);
                        $(".ql-editor").html("");
                        window.location.reload(false);
                    }
                }
            }).catch(error => {
                logger.error("Create Post", "PersonalWall Component", "SubmitPost Button", "POST", "Post Creation Failed!", JSON.stringify(error));
                console.error('There was an error!', error);
            });
        }
    }

    const getSubscribedGroups = () => {
        axios.get(`${SubscribedGroupListAPI}?userId=${curUserId}&userEntityId=${curUserEntityId}&userRoleId=${curUserRoleId}`)
            .then(resp => {
                setSubscribedGroups(resp.data.response);
            }).catch(error => {
                console.log(error);
            })
    }

    const submitGroupPost = (groupId) => {
        // logger.verbose("Group Post Create", "PersonalWall Component", "submitGroupPost", "POST", "Submit Group Post", "");
        if (!postContent && postMedia.length === 0) {
            errorToast("Post Content is empty")
            return
        }
        else {
            const postData = [];
            const postDataObj =
            {
                "socialEntityTypeId": "4",
                "parentSocialEntityTypeId": "2",
                "parentSocialEntityId": groupId,
                "postContent": postContent,
                "createdByUserId": curUserId,
                "createdByUserEntityTypeId": curUserEntityId,
                "createdByUserRoleId": curUserRoleId
            }
            postData.push(postDataObj);
            const objdata = {
                "postDetails": postData,
                "mediaDetails": postMedia
            }
            // logger.verbose("Group Post Create", "PersonalWall Component", "submitGroupPost", "POST", "Submit Group Post Object API", JSON.stringify(objdata));
            const headers = {
                'Content-Type': 'application/json;charset=utf-8',
            }
            axios.post(CreatePostAPI, objdata, {
                headers: headers
            }).then(response => {
                if (response.data.status === 200) {
                    successToast('Post Created Successfully!')
                    setPostContent("");
                    setPostMedia([]);
                    $(".ql-editor").html("");
                    // logger.verbose("Group Post Create", "PersonalWall Component", "submitGroupPost", "POST", "Group Post Created from Personal wall API Respone Successfull", JSON.stringify(response.data));
                    window.location.reload(false);

                }
            }).catch(error => {
                console.error('There was an error!', error);
                logger.error("Group Post Create", "GroupWall Component", "getGroupDetails", "GET", "Post Created in group wall API Respone Failed", JSON.stringify(error));
            });
        }
    }
    return (
        <>
            <div className="main-body-section main-body-section-social">
                <SocialHeader />
                <section className="body-section">
                    <div className="body-content container-fluid">
                    <div className="row1 My_home">
                            <div className="col_res1 align-items-stretch box_1 w_171">
                                <div className="sticky-top stickyTop">
                                    <SocialFeedNavigations />
                                    <AddToFeed />
                                </div>
                            </div>
                            <div className="mob-remove-padding box_2 w_661">
                                {
                                    searchMode === false ?
                                        <>
                                            <div className="box-home box_shadow_1 mb_20">
                                                <div className="d-flex justify-content-between align-items-center px-3">
                                                    <div>
                                                        <a
                                                            className="mb-0 text-black-50"
                                                            href="#"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#CreatePostModal"
                                                        >Post your thoughts here...</a
                                                        >
                                                    </div>

                                                    <div>
                                                        <a
                                                            href="#"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#CreatePostModal"
                                                            className="text-black-50 me-2"
                                                        ><span
                                                        className="mdi mdi-file-document-outline text-black-50 fa-xl"
                                                        ></span></a>
                                                        {/* <a
                                                href="#"
                                                data-bs-toggle="modal"
                                                data-bs-target="#CreatePostModal"
                                                className="text-black-50"
                                        ><span
                                                className="mdi mdi-camera-enhance-outline text-black-50"
                                            ></span></a> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <Posts />
                                        </> : <>
                                            <div className="box-home box_shadow_1 mb_20">
                                                <div className="d-flex justify-content-between align-items-center px-3">
                                                    <div>
                                                        Search Results for "{searchKeyword}"
                                                    </div>
                                                    <div>
                                                        <a
                                                            href="#"
                                                            className="text-black-50 me-2"
                                                            onClick={() => { setSearchKeyword(""); setSearchMode(false); $("#globalSearchInput").val(''); }}
                                                        ><span
                                                            className="mdi mdi-close-circle-outline text-black-50" title="Undo Search"
                                                        ></span></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <SearchedPosts key={searchKeyword} searchType="1" searchFor={searchKeyword} />
                                        </>
                                }
                            </div>
                            <div className="col_res1 align-items-stretch box_4 w_1717">
                                <div className="sticky-top">
                                    <CAASWorkspaceNavigations />
                                    <CompanyAnnouncements />
                                    <Conversations />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="modal fade show" id="CreatePostModal">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title text-black-50" id="exampleModalLabel">
                                Create a post
                            </h3>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex align-items-center">
                                <img
                                    src={profilePicture}
                                    alt="User Image"
                                    className="img-fluid rounded-circle img-profile me-3"
                                />
                                <div>
                                    <p className="fw-bold">{curUserFullName}</p>
                                    <select className="form-select" id="groupSelectDropdown">
                                        <option value="0" key={0}>Select Group</option>
                                        {
                                            subscibedGroups.length != 0 ? (
                                                subscibedGroups.map((ele, index) => (
                                                    <option key={index} value={ele?.groupId}>{ele?.title}</option>
                                                ))
                                            ) : null
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="cursor mt-3">
                                <ReactQuill modules={quillModules} theme="snow" onChange={setPostContent} placeholder="what do you want to talk about?" />
                                <div className="mt_15">
                                <h4 className="text-black-50 fw-bold fs-5">Media</h4>
                                    <div className="flex-row createPostMediaPreviewDiv">
                                        {postMedia.length != 0 ? (postMedia.map((ele, index) => (
                                            <>
                                                <a key={index}>
                                                    {ele?.mediaType.indexOf("image") != -1 ?
                                                        <img src={ele?.previewMedia} alt="Uploaded Media" className="img-lg mb-2" /> :
                                                        <video width="320" height="240" muted controls src={ele?.previewMedia}></video>}
                                                </a>
                                                <a className="position-relative text-link inline-block mr_15"
                                                    onClick={removeMedia}



                                                ><span className="ml-15" data-media-id={ele?.id} aria-hidden="true">&times;</span>
                                                </a>
                                            </>

                                        ))) : null}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="hashtag mt-5 pt-5">
                                <a href="#" className="text-link fw-bold"
                                >Add hashtag</a
                                >
                            </div> */}
                        </div>
                        <div className="modal-footer text-black-50 justify-content-between">
                            <div>
                                <input id="post-image-file-input" className="hiddenInputFile" type="file" accept="image/*" onChange={uploadPostMedia} />
                                <label className="text-black-50 m-0 me-3 uploadFileIcon" htmlFor="post-image-file-input" >
                                <i className="fa-regular fa-image fa-xl"></i>
                                </label>
                                <input id="post-video-file-input" className="hiddenInputFile" type="file" accept="video/*" onChange={uploadPostMedia} />
                                <label className="text-black-50 m-0 me-3 uploadFileIcon" htmlFor="post-video-file-input">
                                <i className="fa-brands fa-youtube fa-xl"></i>
                                </label>
                                {/* <a href="#" className="text-black-50 m-0 me-5 boeder-left ps-4">
                                    <span className="mdi mdi-chat-processing-outline"></span>
                                    Anyone
                                </a> */}
                            </div>
                            <div>
                                <a className="btn btn-primary btn-md float-end"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    id="submitPostBtn" onClick={submitPost}>Post</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster />
        </>
    )
}

export default PersonalWall