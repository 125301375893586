import React, { useContext, useState, useEffect } from 'react'
import Header from '../../../commons/components/Header';
import $ from "jquery";
import EnterpriseSideBar from '../../../commons/components/SideBars/EnterpriseSideBar';
import '../../../assets/fontawesome6/css/all.min.css'
import '../../../assets/css/feather.css';
import '../../../assets/css/materialdesignicons.min.css';
import '../../../assets/css/themify-icons.css';
import '../../../assets/css/simple-line-icons.css';
import '../../../assets/css/style.css';
import '../../../assets/css/custom.css';
import swal from 'sweetalert';
import { useTranslation } from "react-i18next";
import { S3Config } from '../../../config/AWSConfig';
import S3FileUpload from '../../../utils/S3FileUpload';
import { getSkillExperienceLevelList, getAreaOfProjectList, getSkillDetailslList, getIndustryDetailsList } from '../../../utils/meta';
import { API } from '../../../config/APIConfig';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import uuidCustom from '../../../utils/uuidCustom';
const POSTRFPAPI = `${API.URL}api/v1/rfp/create`;
const CXOUSERLIST = `${API.URL}api/v1/cxo/list`;
const GETRFPDETAILAPI = `${API.URL}api/v1/enterprise/rfp/view`;
const RFPEdit = () => {
    const { i18n, t } = useTranslation(["rfp"]);
    const [rfpInfo, setRfpInfo] = useState({});
    const [experienceInfo, setExperienceInfo] = useState([]);
    // const [industryExperienceInfo, setIndustryExperienceInfo] = useState([]);
    const [industryExperienceInfo, setIndustryExperienceInfo] = useState("");
    const [curExpId, setcurExpId] = useState(0);
    const [curExp, setCurExp] = useState({});
    const [rfpattachments, setRfpAttachments] = useState([]);
    const [certificateFile, setCertificateFile] = useState("");
    const [skillexperiencelevelsMeta, setSkillexperiencelevelsMeta] = useState([]);
    const [skilldetailsMeta, setSkillDetailsMeta] = useState([]);
    const [skillversionMeta, setSkillVersionssMeta] = useState([]);
    const [curskillVersionList, setCurSkillversionList] = useState([]);
    const [editskillVersionList, setEditSkillversionList] = useState([]);
    const [cxouserdetails, setCXOUserDetails] = useState([]);
    const [areaOfProjectdetails, setAreaOfProjectDetails] = useState([]);
    const [industrydetails, setIndustryDetails] = useState([]);
    const [skillRef, setSkillRef] = useState({});
    const [skillVersionRef, setSkillVersionRef] = useState({});
    const [rfpdetails, setRfpDetails] = useState({});
    const [industryExperiencedisplay, setIndustryExperienceDisplay] = useState([]);
    const [rfpindustryExperience, setRFPIndustryExperience] = useState([]);
    const handlechange = (e) => {
        let id = e.target.id;
        let value = e.target.value;       
        if (id === "rfpexpe") {
            value = value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = value;
        }
        else if (id === "rfpMinBudget") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
            // if (parseInt(value) >= parseInt(rfpInfo.rfpMaxBudget ? rfpInfo.rfpMaxBudget : rfpdetails.rfpMaxBudget)) {
            // if (!(rfpInfo.rfpMaxBudget == undefined)) {
            if ((rfpInfo.rfpMaxBudget != undefined || rfpdetails.rfpMaxBudget > 0)) {
                if (!(parseInt(value) <= parseInt(rfpInfo.rfpMaxBudget ? rfpInfo.rfpMaxBudget : rfpdetails.rfpMaxBudget))) {
                    // $('#rfpMinBudget').val(rfpdetails.rfpMinBudget);
                    // swal("Min budget value should be less than Max Budget", "Please Try Again", "warning", { closeOnClickOutside: false, closeOnEsc: false });
                    // return false;
                    $("#rfpMinBudgetvalueerror").show();
                }
                else {
                    $("#rfpMinBudgetvalueerror").hide();
                    $("#rfpMaxBudgetvalueerror").hide();
                }

            }
            else {
                $("#rfpMinBudgetvalueerror").hide();
                $("#rfpMaxBudgetvalueerror").hide();
            }

        }
        else if (id === "rfpMaxBudget") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
            // if (parseInt(value) <= parseInt(rfpInfo.rfpMinBudget ? rfpInfo.rfpMinBudget : rfpdetails.rfpMinBudget)) {
            if ((rfpInfo.rfpMinBudget != undefined || rfpdetails.rfpMinBudget > 0)) {
                if (!(parseInt(value) >= parseInt(rfpInfo.rfpMinBudget ? rfpInfo.rfpMinBudget : rfpdetails.rfpMinBudget))) {
                    // $('#rfpMaxBudget').val(rfpdetails.rfpMaxBudget);
                    // swal("Max budget value should be greater than Min Budget", "Please Try Again", "warning", { closeOnClickOutside: false, closeOnEsc: false });
                    // return false;
                    $("#rfpMaxBudgetvalueerror").show();
                }
                else {
                    $("#rfpMaxBudgetvalueerror").hide();
                    $("#rfpMinBudgetvalueerror").hide();
                }

            }
            else {
                $("#rfpMaxBudgetvalueerror").hide();
                $("#rfpMinBudgetvalueerror").hide();
            }

        }

        rfpValidation(id);
        setRfpInfo({ ...rfpInfo, [id]: value });
    }
    const location = useLocation();
    const enterpriseId = sessionStorage.getItem("enterpriseId");
    const userId = sessionStorage.getItem("userId");
    const rfpId = location.pathname.split('/')[5];
    useEffect(() => {
        bindDropDownData();
        getcxouserlist();
        bindHandlers();
        getRFPDetails(enterpriseId, userId, rfpId);
        var highlightele = document.getElementById("PostRFPSidNav");
        highlightele.classList.add("active");
    }, []);



    function getRFPDetails(enterpriseId, userId, rfpId) {
        // logger.verbose("RFP Details", "RFPEdit Component", "getRFPDetails", "GET", "Get RFP Details", `enterpriseId=${enterpriseId}&userId=${userId}`);
        axios.get(`${GETRFPDETAILAPI}/?enterpriseId=${enterpriseId}&userId=${userId}&rfpId=${rfpId}`)
            .then(resp => {
                //console.log(resp.data.response);
                // $('#rfpTitle').val(resp.data.response.rfpDetails[0].rfpTitle)
                // setRFPViewData(resp.data.response);
                setRfpDetails(resp.data.response.rfpDetails)
                setRfpAttachments(resp.data.response.rfpAttachments)
                // console.log(resp.data.response.rfpAttachments);
                setExperienceInfo(resp.data.response.rfpExperience)
                setRFPIndustryExperience(resp.data.response.rfpIndustryExp)
                // logger.verbose("RFP Details", "RFPEdit Component", "getRFPDetails", "GET", "Get RFP Details API Response Successfull", JSON.stringify(response.data.response));
            }).catch(error => {
                console.log(error);
                // logger.error("RFP Details", "RFPEdit Component", "getRFPDetails", "GET", "Get RFP Details API Response Failed!", JSON.stringify(error));
            })
    }
    const bindDropDownData = () => {
        getSkillExperienceLevelList()
            .then((res) => {
                if (res.response) {
                    setSkillexperiencelevelsMeta(res.response);
                }
            })
            .catch((e) => {
                console.error('There was an error!', e);
            })
        getAreaOfProjectList()
            .then((res) => {
                if (res.response) {
                    setAreaOfProjectDetails(res.response);
                }
            })
            .catch((e) => {
                console.error('There was an error!', e);
            })
        getSkillDetailslList()
            .then((res) => {
                if (res.response) {
                    var skillDetails = res.response.skillMasterList;
                    var skillVersionDetails = res.response.skillVersionList;
                    setSkillDetailsMeta(skillDetails);
                    setSkillVersionssMeta(skillVersionDetails);
                    var skilldata = skillDetails.reduce((obj, skill) => Object.assign(obj, { [skill.skillId]: skill.skillName }), {});
                    setSkillRef(skilldata);
                    var skillVersiondata = skillVersionDetails.reduce((obj, version) => Object.assign(obj, { [version.skillVersionId]: version.skillVersionName }), {});
                    setSkillVersionRef(skillVersiondata);
                }
            })
            .catch((e) => {
                //console.log(e.message)
            })
        getIndustryDetailsList()
            .then((res) => {
                if (res.response) {
                    setIndustryDetails(res.response);
                }
            })
            .catch((e) => {
                console.error('There was an error!', e);
            })
    }
    const editRFPIndustry = () => {
        if (industryExperiencedisplay.length > 0) {
            var industryData = industryExperiencedisplay.map(({ industryId }) => `${industryId}`).join(',');
            $.each(industryData.split(","), function (i, e) {
                $("#experienceIndustry option[value='" + e + "']").prop("selected", true);
            });
        }
        else {
            var industryData = rfpindustryExperience.map(({ industryId }) => `${industryId}`).join(',');
            $.each(industryData.split(","), function (i, e) {
                $("#experienceIndustry option[value='" + e + "']").prop("selected", true);
            });
        }
    }
    const resetversionList = (e) => {
        var id = e.target.value;
        let idName = e.target.getAttribute("data-for");
        var newskillversionList = skillversionMeta.filter((ele) => ele.skillId == id);
        setCurSkillversionList([...newskillversionList]);
        if (idName == "experienceSkillId") {
            if (id == 0) {
                $("#_skillNameerror").show();
                $("#experienceSkill").addClass("error-shadow")

            }
            else {
                $("#_skillNameerror").hide();
                if ($("#experienceSkill").hasClass('error-shadow'))
                    $("#experienceSkill").removeClass('error-shadow');

            }
        }
        else {
            if (id == 0) {
                $("#skillNameerror").show();
                $("#experienceSkill").addClass("error-shadow")

            }
            else {
                $("#skillNameerror").hide();
                if ($("#experienceSkill").hasClass('error-shadow'))
                    $("#experienceSkill").removeClass('error-shadow');

            }
        }
    }
    const getcxouserlist = () => {
        axios.get(CXOUSERLIST).then(response => {
            if (response.data.status === 200) {
                setCXOUserDetails(response.data.response);
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
    const fileUpload = async (e) => {
        const file = e.target.files[0];
        // const fileName = new Date().toISOString() + "|" +  e.target.files[0].name;
        var fileName = e.target.files[0].name;   
        var fileExtension =fileName.split('.').pop();  
        fileName +=  S3Config.S3ObjectDelimiter + uuidCustom() +"."+ fileExtension;
        const contentType = file.type;
        const formData = { file, fileName, contentType };
        let result = await S3FileUpload(formData).then(
            (response) => {
                if (response.status === 200) {
                    return { status: 200, s3FileUrl: S3Config.S3ObjectBaseURL + fileName }
                }
            }
        ).catch(err => console.log(err));
        return result;
    }
    const uploadCertificateFile = (e) => {
        fileUpload(e).then(
            (response) => {
                setCertificateFile(response.s3FileUrl);
                console.log(certificateFile);
                addrfpattachments(response.s3FileUrl);
                uploadOnChangePreview(e);
            }).catch(err => console.log(err));
    }
    const addrfpattachments = (url) => {
        let obj = {
            id: rfpattachments.length + 1,
            attachmentFile: url,//document.getElementById('certificationFiles').value,
            createdBy: sessionStorage.getItem("userId"),
        }
        setRfpAttachments([...rfpattachments, obj]);
        console.log(rfpattachments);
    }
    useEffect(() => {
        let editExpIndex = experienceInfo.findIndex((obj => obj.id == curExpId));
        if (editExpIndex != -1) {
            let editExpObj = experienceInfo[editExpIndex];
            resetskillversion(editExpObj.experienceSkillId);
            setCurExp({ ...editExpObj })
        }
    }, [curExpId]);
    const resetskillversion = (id) => {
        // var id = e.target.value;
        var newskillversionList = skillversionMeta.filter((ele) => ele.skillId == id);
        setEditSkillversionList([...newskillversionList]);
    }
    const addExperience = () => {
        let obj = {
            id: experienceInfo.length + 1,
            experienceSkillId: document.getElementById('experienceSkill').value,
            experienceSoftwareVersionId: document.getElementById('experienceSoftwareVersion').value,
            // experienceSkill: document.getElementById('experienceSkill').value,
            // experienceSoftwareVersion: document.getElementById('experienceSoftwareVersion').value,
            experienceLastUsed: document.getElementById('experienceLastUsed').value,
            experienceYears: document.getElementById('experienceYears').value,
            // experienceMonths: document.getElementById('experienceMonths').value,
            experienceImplementationSize: document.getElementById('experienceImplementationSize').value,
            experienceLevel: document.getElementById('experienceLevel').value,
            experienceLevelName: $("#experienceLevel option:selected").text(),
            createdBy: sessionStorage.getItem("userId"),
            status: "1",
        }
        var flaglist = allskillcheckValidation(obj);
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;
        if (flag) {
            setExperienceInfo([...experienceInfo, obj]);
            document.getElementById("addExperienceForm").reset();
            $("#AddExperience .close").trigger('click')
        }
    }
    const skillvalidation = (e) => {
        let id = e.target.id;
        let value = e.target.value;
        if (id === "experienceImplementationSize") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
        }
        if (id == "experienceSoftwareVersion") {
            if (value == 0) {
                $("#softwareVererror").show();
                $("#experienceSoftwareVersion").addClass("error-shadow")

            }
            else {
                $("#softwareVererror").hide();
                if ($("#experienceSoftwareVersion").hasClass('error-shadow'))
                    $("#experienceSoftwareVersion").removeClass('error-shadow');
            }
        }
        if (id == "experienceLastUsed") {
            if (value == 0) {
                $("#experienceLastUsederror").show();
                $("#experienceLastUsed").addClass("error-shadow")

            }
            else {
                $("#experienceLastUsederror").hide();
                if ($("#experienceLastUsed").hasClass('error-shadow'))
                    $("#experienceLastUsed").removeClass('error-shadow');
            }
        }
        if (id == "experienceYears") {
            if (value == 0) {
                $("#experienceYearserror").show();
                $("#experienceYears").addClass("error-shadow")

            }
            else {
                $("#experienceYearserror").hide();
                if ($("#experienceYears").hasClass('error-shadow'))
                    $("#experienceYears").removeClass('error-shadow');
            }
        }
        // if (id == "experienceImplementationSize") {
        //     if (value == 0) {
        //         $("#experienceImplementationSizeerror").show();
        //         $("#experienceImplementationSize").addClass("error-shadow")

        //     }
        //     else {
        //         $("#experienceImplementationSizeerror").hide();
        //         if ($("#experienceImplementationSize").hasClass('error-shadow'))
        //             $("#experienceImplementationSize").removeClass('error-shadow');
        //     }
        // }
        if (id == "experienceLevel") {
            if (value == 0) {
                $("#experienceLevelerror").show();
                $("#experienceLevel").addClass("error-shadow")

            }
            else {
                $("#experienceLevelerror").hide();
                if ($("#experienceLevel").hasClass('error-shadow'))
                    $("#experienceLevel").removeClass('error-shadow');
            }
        }
    }


    const editskillvalidation = (e) => {
        let id = e.target.getAttribute("data-for");
        let value = e.target.value;
        var flag;
        if (id === "experienceImplementationSize") {
            var newValue = e.target.value.replace(new RegExp(/[^\d]/, 'ig'), "");
            e.target.value = newValue;
        }
        if (id == "experienceSoftwareVersionId") {
            if (value == 0) {
                $("#_softwareVererror").show();
                $("#experienceSoftwareVersion").addClass("error-shadow")
                flag = false;
                return flag;

            }
            else {
                $("#_softwareVererror").hide();
                if ($("#experienceSoftwareVersion").hasClass('error-shadow'))
                    $("#experienceSoftwareVersion").removeClass('error-shadow');
                flag = true;
                return flag;
            }
        }
        if (id == "experienceLastUsed") {
            if (value == 0) {
                $("#_experienceLastUsederror").show();
                $("#experienceLastUsed").addClass("error-shadow")
                flag = false;
                return flag;
            }
            else {
                $("#_experienceLastUsederror").hide();
                if ($("#experienceLastUsed").hasClass('error-shadow'))
                    $("#experienceLastUsed").removeClass('error-shadow');
                flag = true;
                return flag;
            }
        }
        if (id == "experienceYears") {
            if (value == 0) {
                $("#_experienceYearserror").show();
                $("#experienceYears").addClass("error-shadow")
                flag = false;
                return flag;
            }
            else {
                $("#_experienceYearserror").hide();
                if ($("#experienceYears").hasClass('error-shadow'))
                    $("#experienceYears").removeClass('error-shadow');
                flag = true;
                return flag;
            }
        }
        // if (id == "experienceImplementationSize") {
        //     if (value == 0) {
        //         $("#_experienceImplementationSizeerror").show();
        //         $("#experienceImplementationSize").addClass("error-shadow")
        //         flag = false;
        //         return flag;
        //     }
        //     else {
        //         $("#_experienceImplementationSizeerror").hide();
        //         if ($("#experienceImplementationSize").hasClass('error-shadow'))
        //             $("#experienceImplementationSize").removeClass('error-shadow');
        //         flag = true;
        //         return flag;
        //     }
        // }
        if (id == "experienceLevel") {
            if (value == 0) {
                $("#_experienceLevelerror").show();
                $("#experienceLevel").addClass("error-shadow")
                flag = false;
                return flag;
            }
            else {
                $("#_experienceLevelerror").hide();
                if ($("#experienceLevel").hasClass('error-shadow'))
                    $("#experienceLevel").removeClass('error-shadow');
                flag = true;
                return flag;
            }
        }
    }


    const allskillcheckValidation = (obj) => {
        var flag = true;
        var flaglist = []
        if (obj.experienceSkillId == 0) {
            $("#skillNameerror").show();
            $("#experienceSkill").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#skillNameerror").hide();
            if ($("#experienceSkill").hasClass('error-shadow'))
                $("#experienceSkill").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (obj.experienceSoftwareVersionId == 0) {
            $("#softwareVererror").show();
            $("#experienceSoftwareVersion").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#softwareVererror").hide();
            if ($("#experienceSoftwareVersion").hasClass('error-shadow'))
                $("#experienceSoftwareVersion").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (obj.experienceLastUsed == 0) {
            $("#experienceLastUsederror").show();
            $("#experienceLastUsed").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#experienceLastUsederror").hide();
            if ($("#experienceLastUsed").hasClass('error-shadow'))
                $("#experienceLastUsed").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (obj.experienceYears == 0) {
            $("#experienceYearserror").show();
            $("#experienceYears").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#experienceYearserror").hide();
            if ($("#experienceYears").hasClass('error-shadow'))
                $("#experienceYears").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        // if (obj.experienceImplementationSize == 0) {
        //     $("#experienceImplementationSizeerror").show();
        //     $("#experienceImplementationSize").addClass("error-shadow")
        //     flag = false;
        //     flaglist.push(flag);

        // }
        // else {
        //     $("#experienceImplementationSizeerror").hide();
        //     if ($("#experienceImplementationSize").hasClass('error-shadow'))
        //         $("#experienceImplementationSize").removeClass('error-shadow');
        //     flag = true;
        //     flaglist.push(flag);
        // }
        if (obj.experienceLevel == 0) {
            $("#experienceLevelerror").show();
            $("#experienceLevel").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#experienceLevelerror").hide();
            if ($("#experienceLevel").hasClass('error-shadow'))
                $("#experienceLevel").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        return flaglist;
    }

    const handleexpdelete = (id) => {
        // const deleted = experienceInfo.filter((exp) => exp.id !== id);
        const deleted = experienceInfo.filter((exp) => exp.id == id);
        deleted[0].status = 0;
        const data = experienceInfo.map(obj => {
            if (obj.id == id) {
                obj.status = "0";
            }
            return obj;
        });


        console.log(data);
        setExperienceInfo(data);
    }
    const addUpdIndustry = (e) => {
        let data = $("#experienceIndustry").val();
        let obj = {
            id: industryExperienceInfo.length + 1,
            industryName: $('#experienceIndustry option:selected').text(),
            industryId: e.target.value,
        }
        setIndustryExperienceInfo(data.toString());
        // setIndustryExperienceInfo([...industryExperienceInfo, obj]);
        setIndustryExperienceDisplay(data);
    }
    const editExperience = (e) => {
        let dataFor = e.target.getAttribute("data-for");
        let dataId = curExpId;
        let value = e.target.value;
        if (dataFor === "experienceSkillId") {
            resetversionList(e);
            resetskillversion(value);
        }
        editskillvalidation(e);
        const newExperience = experienceInfo.map(obj => {
            if (obj.id == dataId) {
                return { ...obj, [dataFor]: value };
            }
            return obj;
        });
        setExperienceInfo([...newExperience]);
        setCurExp([...newExperience]);
        if (JSON.stringify(experienceInfo) !== "{}") {
            $("#viewExperienceDiv .row").show();
        }
        if (dataFor === "experienceLevel") {
            editExperinceFix(value);
        }
    }
    const editsaveskills = () => {
        let obj = {
            id: experienceInfo.length + 1,
            experienceSkillId: $("._experienceSkillId").val(),
            experienceSoftwareVersionId: $("._experienceSoftwareVersionId").val(),
            // experienceSkill: document.getElementById('experienceSkill').value,
            // experienceSoftwareVersion: document.getElementById('experienceSoftwareVersion').value,
            experienceLastUsed: $("._experienceLastUsed").val(),
            experienceYears: $("._experienceYears").val(),
            // experienceMonths: document.getElementById('experienceMonths').value,
            experienceImplementationSize: $("._experienceImplementationSize").val(),
            experienceLevel: $("._experienceLevel").val(),
            //experienceLevelName: $(".").val()

        }
        // var flag =allskillcheckValidation(obj);
        var flag = true;
        var flaglist = []
        if (obj.experienceSkillId == 0) {
            $("#_skillNameerror").show();
            $("#experienceSkill").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_skillNameerror").hide();
            if ($("#experienceSkill").hasClass('error-shadow'))
                $("#experienceSkill").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (obj.experienceSoftwareVersionId == 0) {
            $("#_softwareVererror").show();
            $("#experienceSoftwareVersion").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_softwareVererror").hide();
            if ($("#experienceSoftwareVersion").hasClass('error-shadow'))
                $("#experienceSoftwareVersion").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (obj.experienceLastUsed == 0) {
            $("#_experienceLastUsederror").show();
            $("#experienceLastUsed").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_experienceLastUsederror").hide();
            if ($("#experienceLastUsed").hasClass('error-shadow'))
                $("#experienceLastUsed").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        if (obj.experienceYears == 0) {
            $("#_experienceYearserror").show();
            $("#experienceYears").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_experienceYearserror").hide();
            if ($("#experienceYears").hasClass('error-shadow'))
                $("#experienceYears").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        // if (obj.experienceImplementationSize == 0) {
        //     $("#_experienceImplementationSizeerror").show();
        //     $("#experienceImplementationSize").addClass("error-shadow")
        //     flag = false;
        //     flaglist.push(flag);

        // }
        // else {
        //     $("#_experienceImplementationSizeerror").hide();
        //     if ($("#experienceImplementationSize").hasClass('error-shadow'))
        //         $("#experienceImplementationSize").removeClass('error-shadow');
        //     flag = true;
        //     flaglist.push(flag);
        // }
        if (obj.experienceLevel == 0) {
            $("#_experienceLevelerror").show();
            $("#experienceLevel").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#_experienceLevelerror").hide();
            if ($("#experienceLevel").hasClass('error-shadow'))
                $("#experienceLevel").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }

        // return flaglist;
        // var flaglist = allskillcheckValidation(obj);
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;
        if (flag) {
            $("#EditExperience .close").trigger('click')
        }
    }
    const editExperinceFix = (data) => {
        let dataFor = "experienceLevelName";
        let dataId = curExpId;
        let value = skillexperiencelevelsMeta.filter((ele) => ele.skillExperienceId == data)[0].skillExperienceLevel;
        const newExperience = experienceInfo.map(obj => {
            if (obj.id == dataId) {
                return { ...obj, [dataFor]: value };
            }
            return obj;
        });
        setExperienceInfo([...newExperience]);
        setCurExp([...newExperience]);
        if (JSON.stringify(experienceInfo) !== "{}") {
            $("#viewExperienceDiv .row").show();
        }
    }
    const postenterpriserfp = (e) => {
        e.preventDefault();
        $("#step-1").hide();
        $("#step-2").show();
    }
    function PreviewRFP() {
        var flaglist = rfpValidation();
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;
        if (flag) {
            if (!experienceInfo.filter((exp) => exp.status == 1).length > 0) {
                swal("Please Add Skills", "Please Try Again", "error");
                return false;
            }
            if (experienceInfo.filter((exp) => exp.status == 1).length < 2) {
                swal("Please Add one more skill", "Please Try Again", "warning");
                return false;
            }
            if (!(parseInt(rfpInfo.rfpMinBudget ? rfpInfo.rfpMinBudget : rfpdetails.rfpMinBudget) <= parseInt(rfpInfo.rfpMaxBudget ? rfpInfo.rfpMaxBudget : rfpdetails.rfpMaxBudget))) {
                swal("Min budget value should be less than Max Budget", "Please Try Again", "warning", { closeOnClickOutside: false, closeOnEsc: false });
                return false;
            }
            if (!(parseInt(rfpInfo.rfpMaxBudget ? rfpInfo.rfpMaxBudget : rfpdetails.rfpMaxBudget) >= parseInt(rfpInfo.rfpMinBudget ? rfpInfo.rfpMinBudget : rfpdetails.rfpMinBudget))) {
                swal("Max budget value should be greater than Min Budget", "Please Try Again", "warning", { closeOnClickOutside: false, closeOnEsc: false });
                return false;
            }

            $("#Step_1").addClass("d-none");
            $("#Step_3").addClass("d-none");
            $("#Step_2").removeClass("d-none");
            $(".steps ul li:nth-child(2)").addClass("active");
            $(".steps ul li:nth-child(3)").removeClass("active");
        }
    }
    function EditRFP() {
        $("#Step_2").addClass("d-none");
        $("#Step_3").addClass("d-none");
        $("#Step_1").removeClass("d-none");
        $(".steps ul li:nth-child(2)").removeClass("active");
        $(".steps ul li:nth-child(3)").removeClass("active");
    }
    function SubmitRFP() {
        $("#Step_2").addClass("d-none");
        $("#Step_1").addClass("d-none");
        $("#Step_3").removeClass("d-none");
        $(".steps ul li:nth-child(1)").addClass("active");
        $(".steps ul li:nth-child(2)").addClass("active");
        $(".steps ul li:nth-child(3)").addClass("active");
    }
    function uploadOnChangePreview(e) {
        document.getElementById("uploadFile").value = e.target.value;
        var filename = e.target.value;
        var lastIndex = filename.lastIndexOf("\\");
        if (lastIndex >= 0) {
            filename = filename.substring(lastIndex + 1);
        }
        var files = $("#uploadBtn")[0].files;
        for (var i = 0; i < files.length; i++) {
            $("#upload_prev").append(
                "<span>" +
                '<a className="filenameupload" href=>' +
                files[i].name +
                "</a>" +
                '<p className="close" >&times;</p></span>'
            );
        }
        document.getElementById("filename").value = filename;
    }
    const bindHandlers = () => {
        $("#upload_link").on("click", function (e) {
            e.preventDefault();
            $("#uploadBtn:hidden").trigger("click");
        });
    }
    const rfpValidation = (id) => {
        if (id === "" || id === undefined) {
            return allcheckValidation()
        }
        else {
            return singlecheckValidation(id)
        }
    }
    const singlecheckValidation = (id) => {
        var flag = true;
        var single_flaglist = []
        var obj = {
            "rfpTitle": document.getElementById("rfpTitle").value,
            "rfpRequirement": document.getElementById("rfpRequirement").value,
            "rfpInScope": document.getElementById("rfpInScope").value,
            "rfpOutScope": document.getElementById("rfpOutScope").value,
            "rfpMinBudget": document.getElementById("rfpMinBudget").value,
            "rfpMaxBudget": document.getElementById("rfpMaxBudget").value,
            "rfpStartDate": document.getElementById("rfpStartDate").value,
            "rfpQuoteDueDate": document.getElementById("rfpQuoteDueDate").value,
            "rfpAreaOfProjectId": document.getElementById("rfpAreaOfProjectId").value,
            "cxoUserId": document.getElementById("cxoUserId").value,
            "rfpIndustryExperience": document.getElementById("rfpexpe").value
        }
        if (id == "rfpTitle") {
            if (!obj.rfpTitle.trim()) {
                $("#rfpTitleerror").show();
                $("#rfpTitle").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#rfpTitleerror").hide();
                if ($("#rfpTitle").hasClass('error-shadow'))
                    $("#rfpTitle").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "rfpRequirement") {
            if (!obj.rfpRequirement.trim()) {
                $("#rfpRequirementerror").show();
                $("#rfpRequirement").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#rfpRequirementerror").hide();
                if ($("#rfpRequirement").hasClass('error-shadow'))
                    $("#rfpRequirement").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "rfpInScope") {
            if (!obj.rfpInScope.trim()) {
                $("#rfpInScopeerror").show();
                $("#rfpInScope").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#rfpInScopeerror").hide();
                if ($("#rfpInScope").hasClass('error-shadow'))
                    $("#rfpInScope").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "rfpOutScope") {
            if (!obj.rfpOutScope.trim()) {
                $("#rfpOutScopeerror").show();
                $("#rfpOutScope").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#rfpOutScopeerror").hide();
                if ($("#rfpOutScope").hasClass('error-shadow'))
                    $("#rfpOutScope").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "rfpMinBudget") {
            if (!obj.rfpMinBudget.trim()) {
                $("#rfpMinBudgeterror").show();
                $("#rfpMinBudgetvalueerror").hide();
                $("#rfpMinBudget").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#rfpMinBudgeterror").hide();
                if ($("#rfpMinBudget").hasClass('error-shadow'))
                    $("#rfpMinBudget").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "rfpMaxBudget") {
            if (!obj.rfpMaxBudget.trim()) {
                $("#rfpMaxBudgeterror").show();
                $("#rfpMaxBudgetvalueerror").hide();
                $("#rfpMaxBudget").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#rfpMaxBudgeterror").hide();
                if ($("#rfpMaxBudget").hasClass('error-shadow'))
                    $("#rfpMaxBudget").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "rfpStartDate") {
            if (!obj.rfpStartDate.trim()) {
                $("#rfpStartDateerror").show();
                $("#rfpStartDate").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#rfpStartDateerror").hide();
                if ($("#rfpStartDate").hasClass('error-shadow'))
                    $("#rfpStartDate").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "rfpQuoteDueDate") {
            if (!obj.rfpQuoteDueDate.trim()) {
                $("#rfpQuoteDueDateerror").show();
                $("#rfpQuoteDueDate").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#rfpQuoteDueDateerror").hide();
                if ($("#rfpQuoteDueDate").hasClass('error-shadow'))
                    $("#rfpQuoteDueDate").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "rfpAreaOfProjectId") {
            if (obj.rfpAreaOfProjectId == 0) {
                $("#rfpAreaOfProjectIderror").show();
                $("#rfpAreaOfProjectId").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#rfpAreaOfProjectIderror").hide();
                if ($("#rfpAreaOfProjectId").hasClass('error-shadow'))
                    $("#rfpAreaOfProjectId").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }
        if (id == "rfpexpe") {
            if (obj.rfpIndustryExperience == 0) {
                $("#rfpexpeerror").show();
                $("#rfpexpe").addClass("error-shadow")
                flag = false;
                single_flaglist.push(flag);
                return;
            }
            else {
                $("#rfpexpeerror").hide();
                if ($("#rfpexpe").hasClass('error-shadow'))
                    $("#rfpexpe").removeClass('error-shadow');
                flag = true;
                single_flaglist.push(flag);
            }
        }

        return single_flaglist;
    }
    const allcheckValidation = () => {
        var flag = true;
        var flaglist = []
        var obj = {
            "rfpTitle": document.getElementById("rfpTitle").value,
            "rfpRequirement": document.getElementById("rfpRequirement").value,
            "rfpInScope": document.getElementById("rfpInScope").value,
            "rfpOutScope": document.getElementById("rfpOutScope").value,
            "rfpMinBudget": document.getElementById("rfpMinBudget").value,
            "rfpMaxBudget": document.getElementById("rfpMaxBudget").value,
            "rfpStartDate": document.getElementById("rfpStartDate").value,
            "rfpQuoteDueDate": document.getElementById("rfpQuoteDueDate").value,
            "rfpAreaOfProjectId": document.getElementById("rfpAreaOfProjectId").value,
            "cxoUserId": document.getElementById("cxoUserId").value,
            "rfpIndustryExperience": document.getElementById("rfpexpe").value
        }
        if (!obj.rfpTitle.trim()) {
            $("#rfpTitleerror").show();
            $("#rfpTitle").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#rfpTitleerror").hide();
            if ($("#rfpTitle").hasClass('error-shadow'))
                $("#rfpTitle").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }


        if (!obj.rfpRequirement.trim()) {
            $("#rfpRequirementerror").show();
            $("#rfpRequirement").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#rfpRequirementerror").hide();
            if ($("#rfpRequirement").hasClass('error-shadow'))
                $("#rfpRequirement").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }


        if (!obj.rfpInScope.trim()) {
            $("#rfpInScopeerror").show();
            $("#rfpInScope").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#rfpInScopeerror").hide();
            if ($("#rfpInScope").hasClass('error-shadow'))
                $("#rfpInScope").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }


        if (!obj.rfpOutScope.trim()) {
            $("#rfpOutScopeerror").show();
            $("#rfpOutScope").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#rfpOutScopeerror").hide();
            if ($("#rfpOutScope").hasClass('error-shadow'))
                $("#rfpOutScope").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }


        if (!obj.rfpMinBudget.trim()) {
            $("#rfpMinBudgeterror").show();
            $("#rfpMinBudget").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#rfpMinBudgeterror").hide();
            if ($("#rfpMinBudget").hasClass('error-shadow'))
                $("#rfpMinBudget").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }


        if (!obj.rfpMaxBudget.trim()) {
            $("#rfpMaxBudgeterror").show();
            $("#rfpMaxBudget").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#rfpMaxBudgeterror").hide();
            if ($("#rfpMaxBudget").hasClass('error-shadow'))
                $("#rfpMaxBudget").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }


        if (!obj.rfpStartDate.trim()) {
            $("#rfpStartDateerror").show();
            $("#rfpStartDate").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#rfpStartDateerror").hide();
            if ($("#rfpStartDate").hasClass('error-shadow'))
                $("#rfpStartDate").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }


        if (!obj.rfpQuoteDueDate.trim()) {
            $("#rfpQuoteDueDateerror").show();
            $("#rfpQuoteDueDate").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#rfpQuoteDueDateerror").hide();
            if ($("#rfpQuoteDueDate").hasClass('error-shadow'))
                $("#rfpQuoteDueDate").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }


        if (obj.rfpAreaOfProjectId == 0) {
            $("#rfpAreaOfProjectIderror").show();
            $("#rfpAreaOfProjectId").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#rfpAreaOfProjectIderror").hide();
            if ($("#rfpAreaOfProjectId").hasClass('error-shadow'))
                $("#rfpAreaOfProjectId").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }



        if (obj.rfpIndustryExperience == 0) {
            $("#rfpexpeerror").show();
            $("#rfpexpe").addClass("error-shadow")
            flag = false;
            flaglist.push(flag);

        }
        else {
            $("#rfpexpeerror").hide();
            if ($("#rfpexpe").hasClass('error-shadow'))
                $("#rfpexpe").removeClass('error-shadow');
            flag = true;
            flaglist.push(flag);
        }
        return flaglist;
    }
    const handleEdit = () => {
        $("#step-1").show();
        $("#step-2").hide();
    }
    const handleSubmit = (rfpStatus) => {
        var flaglist = rfpValidation();
        if (flaglist.includes(false))
            var flag = false;
        else
            var flag = true;
        if (flag) {
            if (!experienceInfo.filter((exp) => exp.status == 1).length > 0) {
                swal("Please Add Skills", "Please Try Again", "error");
                return false;
            }
            if (experienceInfo.filter((exp) => exp.status == 1).length < 2) {
                swal("Please Add one more skill", "Please Try Again", "warning");
                return false;
            }
            if (!(parseInt(rfpInfo.rfpMinBudget ? rfpInfo.rfpMinBudget : rfpdetails.rfpMinBudget) <= parseInt(rfpInfo.rfpMaxBudget ? rfpInfo.rfpMaxBudget : rfpdetails.rfpMaxBudget))) {
                swal("Min budget value should be less than Max Budget", "Please Try Again", "warning", { closeOnClickOutside: false, closeOnEsc: false });
                return false;
            }
            if (!(parseInt(rfpInfo.rfpMaxBudget ? rfpInfo.rfpMaxBudget : rfpdetails.rfpMaxBudget) >= parseInt(rfpInfo.rfpMinBudget ? rfpInfo.rfpMinBudget : rfpdetails.rfpMinBudget))) {
                swal("Max budget value should be greater than Min Budget", "Please Try Again", "warning", { closeOnClickOutside: false, closeOnEsc: false });
                return false;
            }
            SubmitRFP();
            rfpInfo.enterpriseId = sessionStorage.getItem("enterpriseId");
            rfpInfo.createdBy = sessionStorage.getItem("userId");
            rfpInfo.rfpStatus = rfpStatus;
            rfpInfo.rfpId = rfpId;
            rfpInfo.rfpTitle = rfpInfo.rfpTitle ? rfpInfo.rfpTitle : rfpdetails.rfpTitle;
            rfpInfo.rfpRequirement = rfpInfo.rfpRequirement ? rfpInfo.rfpRequirement : rfpdetails.rfpRequirement;
            rfpInfo.rfpInScope = rfpInfo.rfpInScope ? rfpInfo.rfpInScope : rfpdetails.rfpInScope;
            rfpInfo.rfpOutScope = rfpInfo.rfpOutScope ? rfpInfo.rfpOutScope : rfpdetails.rfpOutScope;
            rfpInfo.rfpMinBudget = rfpInfo.rfpMinBudget ? rfpInfo.rfpMinBudget : rfpdetails.rfpMinBudget;
            rfpInfo.rfpMaxBudget = rfpInfo.rfpMaxBudget ? rfpInfo.rfpMaxBudget : rfpdetails.rfpMaxBudget;
            rfpInfo.rfpStartDate = rfpInfo.rfpStartDate ? rfpInfo.rfpStartDate : rfpdetails.rfpStartDate;
            rfpInfo.rfpQuoteDueDate = rfpInfo.rfpQuoteDueDate ? rfpInfo.rfpQuoteDueDate : rfpdetails.rfpQuoteDueDate;
            rfpInfo.rfpAreaOfProjectId = rfpInfo.rfpAreaOfProjectId ? rfpInfo.rfpAreaOfProjectId : rfpdetails.rfpAreaOfProjectId;
            rfpInfo.cxoUserId = rfpInfo.cxoUserId ? rfpInfo.cxoUserId : rfpdetails.cxoUserId;
            rfpInfo.rfpIndustryExperience = rfpInfo.rfpexpe ? rfpInfo.rfpexpe : rfpdetails.rfpIndustryExperience;
            const rfp_info = [];
            rfp_info.push(rfpInfo);
            console.log(rfp_info);
            console.log(experienceInfo);
            console.log(rfpattachments);
            var industryData_old = rfpindustryExperience.map(({ industryId }) => `${industryId}`).join(',');
            const rfpobjdata = {
                "rfpDetails": rfp_info,
                "rfpExperience": experienceInfo,
                "rfpAttachments": rfpattachments,
                "rfpindustryExperienceInfo": industryExperienceInfo ? industryExperienceInfo : industryData_old
            }
            console.log(rfpobjdata);
            const headers = {
                'Content-Type': 'application/json;charset=utf-8',
            }

            axios.post(POSTRFPAPI, rfpobjdata, {
                headers: headers
            }).then(response => {
                if (response.data.status === 200) {
                    console.log(response);
                }
            }).catch(error => {
                swal("RFP Updation Failed", "Try Again", "error");
                console.error('There was an error!', error);
            });
            $("#step-1").hide();
            $("#step-2").hide();
            $("#step-3").show();
        } else {
            return flag;
        }
    }
    return (
        <div className="container-scroller Post_RFP">
            <Header entity="CXO" />
            <div className="container-fluid page-body-wrapper">
                <EnterpriseSideBar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">UPDATE A RFP</h4>
                                    <div className="mt-0 text-left">
                                        <div className="text-center">
                                            <div id="wizard" className="wizard clearfix">
                                                <div className="steps clearfix">
                                                    <ul role="tablist">
                                                        <li role="tab" className="first active" aria-disabled="false" aria-selected="true">
                                                            <a
                                                                id="wizard-t-0"
                                                                href="#wizard-h-0"
                                                                aria-controls="wizard-p-0"
                                                            ><span className="current-info audible"
                                                            >current step: </span
                                                                ><span className="number">1</span></a
                                                            >
                                                        </li>
                                                        <li
                                                            role="tab"
                                                            className="disabled1 active1"
                                                            aria-disabled="true"
                                                        >
                                                            <a
                                                                id="wizard-t-1"
                                                                href="#wizard-h-1"
                                                                aria-controls="wizard-p-1"
                                                            ><span className="number">2</span></a
                                                            >
                                                        </li>
                                                        <li
                                                            role="tab"
                                                            className="disabled1 last active1"
                                                            aria-disabled="true"
                                                        >
                                                            <a
                                                                id="wizard-t-2"
                                                                href="#wizard-h-2"
                                                                aria-controls="wizard-p-2"
                                                            ><span className="number">3</span></a
                                                            >
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="Step_1">
                                            <fieldset className="wizard-fieldset show border-0">
                                                {/* {
                                                    rfpdetails.length != 0 ? (
                                                        rfpdetails.map((ele, index) => (<> */}
                                                <div className="row">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputName1" className="text-dark">{t('title')} <span className="error">*</span></label>
                                                        <input type="text" className="form-control" placeholder={t('title')} defaultValue={rfpdetails.rfpTitle} id="rfpTitle" onChange={handlechange} />
                                                        <label id="rfpTitleerror" className="error hide">Title is required</label>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputName1"
                                                            className="text-dark">
                                                            {t('describeYourRequirement')} <span className="error">*</span>
                                                        </label>
                                                        <textarea className="form-control project-desc1" rows="7"
                                                            placeholder={t('describeYourRequirement')} defaultValue={rfpdetails.rfpRequirement} id="rfpRequirement" onChange={handlechange} />
                                                        <label id="rfpRequirementerror" className="error hide">Requirement is required</label>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputName1"
                                                            className="text-dark">{t('inscopeItems')} <span className="error">*</span></label>
                                                        <textarea className="form-control project-desc1" rows="5"
                                                            placeholder={t('inscopeItems')} defaultValue={rfpdetails.rfpInScope} id="rfpInScope" onChange={handlechange} />
                                                        <label id="rfpInScopeerror" className="error hide">InScope is required</label>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputName1"
                                                            className="text-dark">{t('outscopeItems')} <span className="error">*</span></label>
                                                        <textarea className="form-control project-desc1" rows="5"
                                                            placeholder={t('outscopeItems')} defaultValue={rfpdetails.rfpOutScope} id="rfpOutScope" onChange={handlechange} />
                                                        <label id="rfpOutScopeerror" className="error hide">OutScope is required</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-12 col-md-6">
                                                        <label htmlFor="exampleInputName1"
                                                            className="text-dark">{t('budget')} (USD) <span className="error">*</span></label>
                                                        <input type="text" className="form-control" placeholder={t('minimumPrice')} defaultValue={rfpdetails.rfpMinBudget} id="rfpMinBudget" onChange={handlechange} />
                                                        <label id="rfpMinBudgeterror" className="error hide">Min Budget is required</label>
                                                        <label id="rfpMinBudgetvalueerror" className="error hide">Min budget value should be less than Max Budget</label>
                                                    </div>
                                                    <div className="form-group col-12 col-md-6 mt-4 pt-2">
                                                        <input type="text" className="form-control" placeholder={t('maximumPrice')} defaultValue={rfpdetails.rfpMaxBudget} id="rfpMaxBudget" onChange={handlechange} />
                                                        <label id="rfpMaxBudgeterror" className="error hide">Max Budget is required</label>
                                                        <label id="rfpMaxBudgetvalueerror" className="error hide">Max budget value should be greater than Min Budget</label>
                                                    </div>
                                                    <div className="form-group col-12 col-md-6">
                                                        <label htmlFor="exampleInputName1"
                                                            className="text-dark">
                                                            {t('startDate')} <span className="error">*</span>
                                                        </label>
                                                        <div id="datepicker-default" className="input-group date datepicker">
                                                            <input type="date" className="form-control" defaultValue={rfpdetails.rfpStartDate}
                                                                id="rfpStartDate" onChange={handlechange} />
                                                            {/* <span className="input-group-addon input-group-append border-left">
                                                                <span className="ti-calendar input-group-text"></span>
                                                            </span> */}
                                                        </div>
                                                        <label id="rfpStartDateerror" className="error hide">Start Date is required</label>
                                                    </div>
                                                    <div className="form-group col-12 col-md-6">
                                                        <label htmlFor="exampleInputName1" className="text-dark">{t('quoteDueDate')} <span className="error">*</span></label>
                                                        <div id="datepicker-default1" className="input-group date datepicker">
                                                            <input type="date" className="form-control" defaultValue={rfpdetails.rfpQuoteDueDate}
                                                                id="rfpQuoteDueDate" onChange={handlechange} />
                                                            {/* <span className="input-group-addon input-group-append border-left">
                                                                <span className="ti-calendar input-group-text" ></span>
                                                            </span> */}
                                                        </div>
                                                        <label id="rfpQuoteDueDateerror" className="error hide">Due Date is required</label>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label>{t('areaOfTheProject')} <span className="error">*</span></label>
                                                            <select className="form-select" id="rfpAreaOfProjectId" value={rfpInfo.rfpAreaOfProjectId ? rfpInfo.rfpAreaOfProjectId : rfpdetails.rfpAreaOfProjectId} onChange={handlechange}>
                                                                <option value="0" key={0}>Select</option>
                                                                {
                                                                    areaOfProjectdetails.length != 0 ? (
                                                                        areaOfProjectdetails.map((ele, index) => (
                                                                            <option key={index} value={ele.areaOfProjectId}>{ele.areaOfProject}</option>
                                                                        ))
                                                                    ) : null
                                                                }
                                                            </select>
                                                        </div>
                                                        <label id="rfpAreaOfProjectIderror" className="error hide">Area of Project is required</label>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="">  {t('caasMemberCode')} </label>
                                                            <select className="form-select" id="cxoUserId" value={rfpInfo.cxoUserId ? rfpInfo.cxoUserId : rfpdetails.cxoUserId} onChange={handlechange}>
                                                                <option value="0" key={0}>Select</option>
                                                                {
                                                                    cxouserdetails.length != 0 ? (
                                                                        cxouserdetails.map((ele, index) => (
                                                                            <option key={index} value={ele.userId}>{ele.firstName} {ele.lastName} : {ele.uniqueCode}</option>
                                                                        ))
                                                                    ) : null
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="d-sm-flex d-block justify-content-between">
                                                            <label htmlFor="">{t('attachments')} </label>
                                                            <input
                                                                id="uploadFile"
                                                                type="hidden"
                                                                placeholder="Add files from My Computer" />
                                                            <a href="#" id="upload_link"><i className="fa-solid fa-paperclip pe-2"></i>{t('clickToAddAttachments')}</a
                                                            >
                                                            <input
                                                                id="uploadBtn"
                                                                type="file"
                                                                className="upload"
                                                                multiple="multiple"
                                                                name="browsefile"
                                                                onChange={uploadCertificateFile}
                                                            />
                                                        </div>
                                                        <div className="file-upload" id="upload_prev"></div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="">Uploaded Attachments</label>
                                                        <div className="file-upload" id="upload_prev_edit">
                                                            {
                                                                rfpattachments.length != 0 ? (
                                                                    rfpattachments.map((ele) => (
                                                                        <>
                                                                            <a className="text-blue border_right" href={ele.attachmentFile} target="_blank">{ele.attachmentFile.split("/")[3]?.split(S3Config.S3ObjectDelimiter)[0]}</a>

                                                                        </>
                                                                    ))
                                                                ) : <span className="text-blue">{t('noRecordsAvailable')}</span>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* </>))
                                                    ) : null
                                                } */}
                                                <div className="row1">
                                                    <span className="">
                                                        <div className="d-flex justify-content-between align-items-center mt-4">
                                                            <h3 className="m-0 fw-bold fs-5 fst-italic text-blue border_green">
                                                                {t('chooseTheIndustryAndTheExperienceRequiredForCAASMember')}
                                                            </h3>
                                                        </div>
                                                        <div
                                                            data-plugin="lazyload"
                                                            className="lazyHead pt-4 mt-2"
                                                            id="lazyKeySkills"
                                                            data-loaded="true"
                                                        >
                                                            <div className="keySkills">
                                                                <div className="col-12 col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="">
                                                                            {t('enterTheTotalYearsOfIndustryExperience')} <span className="error">*</span>
                                                                        </label>
                                                                        <input className="form-control" placeholder={t('years')} defaultValue={rfpdetails.rfpIndustryExperience} id="rfpexpe" onChange={handlechange} />
                                                                        <label id="rfpexpeerror" className="error hide">Total Experience is required</label>
                                                                    </div>
                                                                </div>
                                                                <div className="card">
                                                                    <div className="cardPad">
                                                                        <div className="widgetHead">
                                                                            <span className="widgetTitle"> {t('industry')} </span>
                                                                            {/* <a
                                                                            href="#"
                                                                            className="text-link"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#Experience"
                                                                        ><i
                                                                            className="mdi mdi-pencil-box-outline font-weight-semibold"></i>
                                                                        </a>
                                                                    </div>
                                                                    <input
                                                                        name="tags"
                                                                        id="tags"
                                                                        value="Manufacturing,Banking,Healthcare,Airline, Pharma, Insurance"
                                                                    /> */}
                                                                            <a href="#" className="edit-profile"> <i className="fa fa-pencil fa-lg float-end text-muted pt-4 pr_30" title="Edit" data-bs-toggle="modal" data-bs-target="#Industry" onClick={editRFPIndustry}></i></a>
                                                                            <div className="cardPad card-body box-shadow pb_30">
                                                                                {
                                                                                    industryExperiencedisplay.length != 0 ? (
                                                                                        industryExperiencedisplay.map((ele) => (
                                                                                            industrydetails.map((exp) => (
                                                                                                ele == exp.industryId ?
                                                                                                    <button type="button" className="btn industry-list btn-outline-secondary btn-sm ml_20" value={exp.industryId}> {exp.industryName} </button> :
                                                                                                    <></>
                                                                                            ))
                                                                                        ))
                                                                                    ) : (
                                                                                        <>
                                                                                            {/* <a href="#" className="edit-profile"> <i className="fa-solid fa-plus float-end text-muted pt-4 pr_30" title="Edit" data-bs-toggle="modal" data-bs-target="#Industry"></i></a>
                                                                                        <div className="cardPad card-body box-shadow pb_30"> */}
                                                                                            {/* <p>{t('noRecordsAvailable')}</p> */}
                                                                                            {/* </div> */}
                                                                                            {
                                                                                                rfpindustryExperience.length != 0 ? (
                                                                                                    rfpindustryExperience.map((ele) => (
                                                                                                        <button type="button" className="btn industry-list btn-outline-secondary btn-sm ml_20" value={ele.industryId}> {ele.industryname} </button>
                                                                                                    ))
                                                                                                ) : (
                                                                                                    <p>{t('noRecordsAvailable')}</p>
                                                                                                )
                                                                                            }


                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="d-flex justify-content-between align-items-center mt-5"
                                                        >
                                                            <h3
                                                                className="m-0 fw-bold fs-5 fst-italic text-blue border_green"
                                                            >
                                                                {t('chooseTheSkillsRequiredForCAASMember')}
                                                            </h3>
                                                            <a id="addExperienceBtn" data-bs-toggle="modal" data-bs-target="#AddExperience" className="btn-primary add-pi float-right btn-rounded btn-icon">
                                                                <i className="fa-solid fa-plus"></i>
                                                            </a>
                                                        </div>
                                                    </span>
                                                    <div className="skills_panel mt-3 pt-4">
                                                        <div className="row">
                                                            {
                                                                experienceInfo.length != 0 ? (
                                                                    experienceInfo.map((ele) => (
                                                                        <>
                                                                            {
                                                                                ele.status !== "0" ? <div className="col-md-6">
                                                                                    <div className="box-skill">
                                                                                        <div className="widgetHead float-right mt_25 mr_10">
                                                                                            <a href="#" className="delete-experience" > <i className="fa-solid fa-trash fa-lg float-end text-muted pt-2" title="Delete Experience" data-bs-toggle="modal" data-bs-target="#DeleteExperience" id="deleteExperienceBtn" data-for={ele.id} onClick={() => {
                                                                                                handleexpdelete(ele.id)
                                                                                            }}></i></a>
                                                                                            <a
                                                                                                href="#"
                                                                                                className="edit-profile"
                                                                                            ><i className="fa fa-pencil fa-lg float-end text-muted pt-2" data-bs-toggle="modal" data-bs-target="#EditExperience" title="Edit Experience" id="editExperienceBtn" data-for={ele.id} onClick={() => {
                                                                                                setcurExpId(ele.id)
                                                                                            }}></i></a>
                                                                                        </div>
                                                                                        <div className="main_skill">
                                                                                            <div className="d-table">
                                                                                                <div className="d-table-cell">{t('skill')}:</div>
                                                                                                <div className="d-table-cell">{skillRef[ele.experienceSkillId]}</div>
                                                                                            </div>
                                                                                            <div className="d-table">
                                                                                                <div className="d-table-cell">
                                                                                                    {t('softwareVersion')}:
                                                                                                </div>
                                                                                                <div className="d-table-cell">{skillVersionRef[ele.experienceSoftwareVersionId]}</div>
                                                                                            </div>
                                                                                            <div className="d-table">
                                                                                                <div className="d-table-cell">{t('lastUsed')}:</div>
                                                                                                <div className="d-table-cell">{ele?.experienceLastUsed}</div>
                                                                                            </div>
                                                                                            <div className="d-table">
                                                                                                <div className="d-table-cell">
                                                                                                    {t('experience')}:
                                                                                                </div>
                                                                                                <div className="d-table-cell">
                                                                                                    {ele?.experienceYears} Years
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="d-table">
                                                                                                <div className="d-table-cell">
                                                                                                    {t('implementationSize')}:
                                                                                                </div>
                                                                                                <div className="d-table-cell">{ele?.experienceImplementationSize} {t('users')}</div>
                                                                                            </div>
                                                                                            <div className="d-table">
                                                                                                <div className="d-table-cell">
                                                                                                    {t('experienceLevel')}:
                                                                                                </div>
                                                                                                <div className="d-table-cell">{ele.experienceLevelName}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div> : ""
                                                                            }
                                                                        </>
                                                                    ))
                                                                ) : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group clearfix text-right mt-5 pt-5">
                                                    <a
                                                        href="javascript:;"
                                                        className="form-wizard-next-btn btn btn-gray btn-secondary btn-sm me-2"
                                                        onClick={PreviewRFP}
                                                    //onClick={postenterpriserfp}
                                                    >
                                                        {t('preview')}
                                                    </a>
                                                    <a
                                                        href="#"
                                                        className="form-wizard-next-btn btn btn-orange btn-warning btn-sm"
                                                        onClick={() => handleSubmit(5)}
                                                    >
                                                        {t('save')}
                                                    </a>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div className="step_2 mt-5 d-none" id="Step_2">
                                            <table className="table table-borderless first-tab">
                                                <tr>
                                                    <td className="text-orange">{t('title')}</td>
                                                    <td>
                                                        {rfpInfo.rfpTitle ? rfpInfo.rfpTitle : rfpdetails.rfpTitle}
                                                        {/* {rfpInfo.rfpTitle} */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-orange">{t('requirement')}</td>
                                                    <td>
                                                        {rfpInfo.rfpRequirement ? rfpInfo.rfpRequirement : rfpdetails.rfpRequirement}
                                                        {/* {rfpInfo.rfpRequirement} */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-orange">{t('inscopeItems')}</td>
                                                    <td>
                                                        {rfpInfo.rfpInScope ? rfpInfo.rfpInScope : rfpdetails.rfpInScope}
                                                        {/* {rfpInfo.rfpInScope} */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-orange">{t('outscopeItems')}</td>
                                                    <td>
                                                        {rfpInfo.rfpOutScope ? rfpInfo.rfpOutScope : rfpdetails.rfpOutScope}
                                                        {/* {rfpInfo.rfpOutScope} */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-orange">{t('budget')} (USD)</td>
                                                    <td className="v-middle">
                                                        <span className="border_right"
                                                        >{t('minimumPrice')} -  {rfpInfo.rfpMinBudget ? rfpInfo.rfpMinBudget : rfpdetails.rfpMinBudget} USD</span
                                                        ><span>{t('maximumPrice')} -  {rfpInfo.rfpMaxBudget ? rfpInfo.rfpMaxBudget : rfpdetails.rfpMaxBudget} USD</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-orange"> {t('startDate')}</td>
                                                    <td>
                                                        {rfpInfo.rfpStartDate ? rfpInfo.rfpStartDate : rfpdetails.rfpStartDate}
                                                        {/* {rfpInfo.rfpStartDate} */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-orange">{t('quoteDueDate')}</td>
                                                    <td> {rfpInfo.rfpQuoteDueDate ? rfpInfo.rfpQuoteDueDate : rfpdetails.rfpQuoteDueDate}
                                                        {/* {rfpInfo.rfpQuoteDueDate} */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-orange"> Area of the Project</td>
                                                    <td className="v-middle">
                                                        <span className="">{$("#rfpAreaOfProjectId option:selected").text()}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-orange"> {t('caasMemberCode')}</td>
                                                    <td className="v-middle">
                                                        <span className="">{$("#cxoUserId option:selected").text()}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-orange">{t('attachments')}</td>
                                                    <td className="v-middle">
                                                        {
                                                            rfpattachments.length != 0 ? (
                                                                rfpattachments.map((ele) => (
                                                                    <>
                                                                        <span className="text-blue border_right">{ele.attachmentFile.split("/")[3]?.split(S3Config.S3ObjectDelimiter)[0]}</span>

                                                                    </>
                                                                ))
                                                            ) : <span className="text-blue">{t('noRecordsAvailable')}</span>
                                                        }
                                                        {/* <span className="text-blue border_right">File1.txt</span>
                                                        <span className="text-blue">File2022.txt</span> */}
                                                    </td>
                                                </tr>
                                            </table>
                                            <hr className="mx-20" />
                                            <div
                                                className="d-flex justify-content-between align-items-center mt-4 pt-2 mx-20"
                                            >
                                                <h3 className="m-0 fw-bold fs-5 fst-italic text-blue">
                                                    {t('industryAndExperience')}
                                                </h3>
                                            </div>
                                            <table className="table table-borderless second-tab mt-4">
                                                <tr>
                                                    <td className="text-orange">{t('industryExperience')}</td>
                                                    <td>
                                                        {rfpInfo.rfpexpe ? rfpInfo.rfpexpe : rfpdetails.rfpIndustryExperience} {t('years')}
                                                        {/* {rfpInfo.rfpexpe} {t('years')} */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-orange"> {t('industry')}</td>
                                                    <td>
                                                        {/* {
                                                            industryExperiencedisplay.length != 0 ? (
                                                                industryExperiencedisplay.map((ele) => (
                                                                    <>
                                                                        <span className="bg-gray p-2 me-2" value={ele}> {ele}</span>
                                                                    </>
                                                                ))
                                                            ) : (
                                                                <p>{t('noRecordsAvailable')}.</p>
                                                            )
                                                        } */}
                                                        {/* {
                                                            
                                                            industryExperiencedisplay.length != 0 ? (
                                                                industryExperiencedisplay.map((ele) => (
                                                                    industrydetails.map((exp) => (
                                                                        ele == exp.industryId ?
                                                                            <span className="bg-gray p-2 me-2" value={exp.industryId}> {exp.industryName}</span>
                                                                            // <button type="button" className="btn industry-list btn-outline-secondary btn-sm ml_20" value={exp.industryId}> {exp.industryName} </button>
                                                                            :
                                                                            <></>
                                                                    ))
                                                                ))
                                                            ) : (
                                                                <p>{t('noRecordsAvailable')}</p>
                                                            )
                                                        } */}
                                                        {
                                                            industryExperiencedisplay.length != 0 ? (
                                                                industryExperiencedisplay.map((ele) => (
                                                                    industrydetails.map((exp) => (
                                                                        ele == exp.industryId ?
                                                                            <span className="bg-gray p-2 me-2" value={exp.industryId}> {exp.industryName} </span> :
                                                                            <></>
                                                                    ))
                                                                ))
                                                            ) : (
                                                                <>
                                                                    {
                                                                        rfpindustryExperience.length != 0 ? (
                                                                            rfpindustryExperience.map((ele) => (
                                                                                <span className="bg-gray p-2 me-2" value={ele.industryId}> {ele.industryname} </span>
                                                                            ))
                                                                        ) : (
                                                                            <p>{t('noRecordsAvailable')}</p>
                                                                        )
                                                                    }


                                                                </>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-orange">{t('skills')}</td>
                                                    <td>
                                                        {
                                                            experienceInfo.length != 0 ? (
                                                                experienceInfo.map((ele) => (
                                                                    <>   {
                                                                        ele.status !== "0" ?
                                                                            <div className="Skill_section mb-4">
                                                                                <p className="fw-bold">{skillRef[ele.experienceSkillId]}</p>
                                                                                <div className="List_Skills">
                                                                                    <span className="border_right"
                                                                                    >{t('softwareVersion')} : {skillVersionRef[ele.experienceSoftwareVersionId]}</span>
                                                                                    <span className="border_right">
                                                                                        {t('lastUsed')} :{ele.experienceLastUsed}
                                                                                    </span>
                                                                                    <span className="border_right">
                                                                                        {t('experience')} : {ele.experienceYears} Years
                                                                                    </span>
                                                                                    <span className="border_right mr_10"> {t('implementationSize')} : {ele.experienceImplementationSize} {t('users')} </span>
                                                                                    <span className="border_right last">{t('experienceLevel')} : {ele.experienceLevelName}</span>
                                                                                </div>
                                                                            </div> : ""
                                                                    }
                                                                    </>
                                                                ))
                                                            ) : null
                                                        }
                                                    </td>
                                                </tr>
                                            </table>
                                            <div className="form-group clearfix text-right mt-5 pt-5">
                                                <a
                                                    href="javascript:;"
                                                    className="form-wizard-next-btn btn btn-gray btn-secondary btn-sm me-2"
                                                    onClick={EditRFP}
                                                // onClick={handleEdit}
                                                >
                                                    {t('edit')}
                                                </a>
                                                <a
                                                    href="#"
                                                    className="form-wizard-next-btn btn btn-orange btn-warning btn-sm"
                                                    // onClick="SubmitRFP()"
                                                    onClick={() => handleSubmit(1)}
                                                >
                                                    {t('submit')}
                                                </a>
                                            </div>
                                        </div>
                                        <div
                                            className="d-flex justify-content-center align-items-center mh-500 d-none ThankyouRFP"
                                            id="Step_3"
                                        >
                                            <div className="text-center">
                                                <p className="fs-24">Thank you for Updating the RFP.</p>
                                                <p className="fs-24">You will be notified soon.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div className="modal fade show" id="Industry" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('industry')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputName1">{t('industry')} </label>
                                        <select className="form-select" multiple id="experienceIndustry" onChange={addUpdIndustry}>
                                            <option value="0">---Choose---</option>

                                            {
                                                industrydetails.length != 0 ? (
                                                    industrydetails.map((ele, index) => (
                                                        <option key={index} value={ele.industryId}>{ele.industryName}</option>
                                                    ))
                                                ) : null
                                            }
                                            {/* <option value="1">Manufacturing</option>
                                            <option value="2">Banking</option>
                                            <option value="3">Oracle Financials</option>
                                            <option >Oracle ERP</option>
                                            <option >Insurance</option>
                                            <option >Airline</option>
                                            <option >Pharma</option>
                                            <option >Healthcare</option> */}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                            <a className="btn btn-warning btn-md next-btn" data-bs-dismiss="modal">{t('save')}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="AddExperience" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('experience/skill')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <form id="addExperienceForm">
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('skill/softwareName')} <span className="error">*</span></label>
                                        <select className="form-select" id="experienceSkill" onChange={resetversionList}>
                                            <option value="0" key={0}>Select</option>
                                            {
                                                skilldetailsMeta.length != 0 ? (
                                                    skilldetailsMeta.map((ele, index) => (
                                                        <option key={index} value={ele.skillId}>{ele.skillName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        {/* <input type="text" className="form-control" id="experienceSkill" /> */}
                                        <label id="skillNameerror" className="error hide">Skill is required</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('softwareVersion')} <span className="error">*</span></label>
                                        <select className="form-select" id="experienceSoftwareVersion" onChange={skillvalidation}>
                                            <option value="0" key={0}>Select</option>
                                            {
                                                curskillVersionList.length != 0 ? (
                                                    curskillVersionList.map((ele, index) => (
                                                        <option key={index} value={ele.skillVersionId}>{ele.skillVersionName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        {/* <input type="text" className="form-control" id="experienceSoftwareVersion" /> */}
                                        <label id="softwareVererror" className="error hide">Skill Version is required</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('lastUsed')} <span className="error">*</span></label>
                                        <select className="form-select" id="experienceLastUsed" onChange={skillvalidation}>
                                            <option value="0">Select</option>
                                            <option >2020</option>
                                            <option >2021</option>
                                            <option >2022</option>
                                        </select>
                                        <label id="experienceLastUsederror" className="error hide">Skill Last used is required</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('years')} <span className="error">*</span></label>
                                        <select className="form-select" id="experienceYears" onChange={skillvalidation}>
                                            <option value="0">Select</option>
                                            <option value="1">1 Years</option>
                                            <option value="2">2 Years</option>
                                            <option value="3">3 Years</option>
                                            <option value="4">4 Years</option>
                                            <option value="5">5 Years</option>
                                            <option value="6">6 Above</option>
                                        </select>
                                        <label id="experienceYearserror" className="error hide">Skill Years is required</label>
                                    </div>
                                    {/* <div className="form-group col-12 col-sm-12">
                                        <label>{t('months')}</label>
                                        <select className="form-select" id="experienceMonths">
                                            <option >Select</option>
                                            <option value="1">1 Months</option>
                                            <option value="2">2 Months</option>
                                            <option value="3">3 Months</option>
                                            <option value="4">4 Months</option>
                                            <option value="5">5 Months</option>
                                            <option value="6">6 Months</option>
                                            <option value="7">7 Months</option>
                                            <option value="8">8 Months</option>
                                            <option value="9">9 Months</option>
                                            <option value="10">10 Months</option>
                                            <option value="11">11 Months</option>
                                            <option value="12">12 Months</option>
                                        </select>
                                    </div> */}
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('implementationSize')} <span className='imp_size mr_5'>(in numbers)</span><i className="fa fa-info-circle" title="How many end-users have experienced your skill?"></i></label>
                                        <input type="text" className="form-control" placeholder='How many end-users have experienced your skill?' id="experienceImplementationSize" onChange={skillvalidation} />
                                        {/* <label id="experienceImplementationSizeerror" className="error hide">Implementation Size is required</label> */}
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('experienceLevel')} <span className="error">*</span></label>
                                        <select className="form-select" id="experienceLevel" onChange={skillvalidation}>
                                            <option value="0" key={0}>Select</option>
                                            {
                                                skillexperiencelevelsMeta.length != 0 ? (
                                                    skillexperiencelevelsMeta.map((ele, index) => (
                                                        <option key={index} value={ele.skillExperienceId}>{ele.skillExperienceLevel}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="experienceLevelerror" className="error hide">Experience Level is required</label>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                            <a className="btn btn-warning btn-md next-btn" id="myModalsave" onClick={addExperience}>{t('save')}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade show" id="EditExperience">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('experience/skill')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <form id="addEmploymentForm">
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('skill/softwareName')} <span className="error">*</span></label>
                                        <select className="form-select _experienceSkillId" data-for="experienceSkillId" value={curExp?.experienceSkillId} onChange={editExperience} >
                                            <option value="0" key={0}>Select</option>
                                            {
                                                skilldetailsMeta.length != 0 ? (
                                                    skilldetailsMeta.map((ele, index) => (
                                                        <option key={index} value={ele.skillId}>{ele.skillName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="_skillNameerror" className="error hide">Skill is required</label>
                                        {/* <input type="text" className="form-control" data-for="experienceSkill" value={curExp?.experienceSkill} onChange={editExperience} /> */}
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('softwareVersion')} <span className="error">*</span></label>
                                        <select className="form-select _experienceSoftwareVersionId" data-for="experienceSoftwareVersionId" value={curExp?.experienceSoftwareVersionId} onChange={editExperience} >
                                            <option value="0" key={0}>Select</option>
                                            {
                                                editskillVersionList.length != 0 ? (
                                                    editskillVersionList.map((ele, index) => (
                                                        <option key={index} value={ele.skillVersionId}>{ele.skillVersionName}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        {/* <input type="text" className="form-control" data-for="experienceSoftwareVersion" value={curExp?.experienceSoftwareVersion} onChange={editExperience} /> */}
                                        <label id="_softwareVererror" className="error hide">Skill Version is required</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('lastUsed')} <span className="error">*</span></label>
                                        <select className="form-select _experienceLastUsed" data-for="experienceLastUsed" value={curExp?.experienceLastUsed} onChange={editExperience}>
                                            <option value="0">Select</option>
                                            <option >2020</option>
                                            <option >2021</option>
                                            <option >2022</option>
                                        </select>
                                        <label id="_experienceLastUsederror" className="error hide">Skill Last used is required</label>
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('years')} <span className="error">*</span></label>
                                        <select className="form-select _experienceYears" data-for="experienceYears" value={curExp?.experienceYears} onChange={editExperience}>
                                            <option value="0">Select</option>
                                            <option value="1">1 Years</option>
                                            <option value="2">2 Years</option>
                                            <option value="3">3 Years</option>
                                            <option value="4">4 Years</option>
                                            <option value="5">5 Years</option>
                                            <option value="6">6 Above</option>
                                        </select>
                                        <label id="_experienceYearserror" className="error hide">Skill Years is required</label>
                                    </div>
                                    {/* <div className="form-group col-12 col-sm-12">
                                        <label>{t('months')}</label>
                                        <select className="form-select" data-for="experienceMonths" value={curExp?.experienceMonths} onChange={editExperience}>
                                            <option >Select</option>
                                            <option value="1">1 Months</option>
                                            <option value="2">2 Months</option>
                                            <option value="3">3 Months</option>
                                            <option value="4">4 Months</option>
                                            <option value="5">5 Months</option>
                                            <option value="6">6 Months</option>
                                            <option value="7">7 Months</option>
                                            <option value="8">8 Months</option>
                                            <option value="9">9 Months</option>
                                            <option value="10">10 Months</option>
                                            <option value="11">11 Months</option>
                                            <option value="12">12 Months</option>
                                        </select>
                                    </div> */}
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('implementationSize')} <span className='imp_size mr_5'>(in numbers)</span><i className="fa fa-info-circle" title="How many end-users have experienced your skill?"></i></label>
                                        <input type="text" className="form-control _experienceImplementationSize" placeholder='How many end-users have experienced your skill?' data-for="experienceImplementationSize" value={curExp?.experienceImplementationSize} onChange={editExperience} />
                                        {/* <label id="_experienceImplementationSizeerror" className="error hide">Implementation Size is required</label> */}
                                    </div>
                                    <div className="form-group col-12 col-sm-12">
                                        <label>{t('experienceLevel')} <span className="error">*</span></label>
                                        <select className="form-select _experienceLevel" data-for="experienceLevel" value={curExp?.experienceLevel} onChange={editExperience}>
                                            <option value="0" key={0}>Select</option>
                                            {
                                                skillexperiencelevelsMeta.length != 0 ? (
                                                    skillexperiencelevelsMeta.map((ele, index) => (
                                                        <option key={index} value={ele.skillExperienceId}>{ele.skillExperienceLevel}</option>
                                                    ))
                                                ) : null
                                            }
                                        </select>
                                        <label id="_experienceLevelerror" className="error hide">Experience Level is required</label>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                            <a className="btn btn-warning btn-md next-btn editSave" onClick={editsaveskills}>{t('save')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default RFPEdit