import { React, useEffect, useState } from 'react'
import "../../../assets/fontawesome6/css/all.min.css";
import "../../../assets/css/materialdesignicons.min.css"
import "../../../assets/css/themify-icons.css"
import "../../../assets/css/style.css"
import "../../../assets/css/select2-bootstrap.min.css"
import "../../../assets/css/select2.min.css"
import "../../../assets/css/custom.css"
import "../../../assets/css/userend.css"
import "../../../assets/css/responsive.css"
import SocialHeader from './SocialHeader';
import SocialFeedNavigations from './SocialFeedNavigations';
import CAASWorkspaceNavigations from './CAASWorkspaceNavigations';
import ProjectAnnouncements from './ProjectAnnouncements';
import CompanyAnnouncements from './CompanyAnnouncements';
import AddToFeed from './AddToFeed';
import Conversations from './Conversations';
import Posts from '../Posts';
import ProfileInfoAnalytics from './ProfileInfoAnalytics';
import profilepic from "../../../assets/images/noav.jpg"
import { API } from '../../../config/APIConfig';
import axios from 'axios';
import swal from 'sweetalert';
import dateDiff from '../../../utils/dateDiff';
import Connections from './Connections';
import { Toaster } from 'react-hot-toast';
import { successToast, celebrationToast, errorToast } from '../../../utils/toastUtil';
import { Link, useNavigate } from 'react-router-dom';
import logger from '../../../utils/logger';
import $ from "jquery";
const CAASMEMBERSUGGESTIONS = `${API.URL}api/v1/social/caasmember/suggestions`;
const CONNECTIONREQUEST = `${API.URL}api/v1/social/connection/request/list`;
const INVITATIONREQUEST = `${API.URL}api/v1/social/connection/request/accept`;
const SENDINVITATION = `${API.URL}api/v1/social/connection/request`;
const MyConnections = () => {
    const [caasdetails, setCaasDetails] = useState([]);
    const [connectiondetails, setConnectionDetails] = useState([]);
    const [curuserid, setcurUserId] = useState(0);
    const [curuser, setCurUser] = useState({});
    const [invitemessage, setInviteMessage] = useState('');
    const [curInvite, setCurInvite] = useState({});
    const loggedInUserId = sessionStorage.getItem("userId");
    const curuserRoleId = sessionStorage.getItem("roleId");
    const curUserEntityId = sessionStorage.getItem("entityId");
    const curuserName = sessionStorage.getItem("firstname") + " " + sessionStorage.getItem("lastname");
    const [viewInvitations, setViewInvitations] = useState(false);
    const navigate = useNavigate();
    const repeatBindingInvite = setInterval(() => {
        clearInterval(repeatBindingInvite);
        getConnectionRequest();
    }, 1000);
    const bindThisInvite = (ele) => {
        setCurInvite({ ...ele });
    }
    const getCaasMembersDetails = () => {
        // logger.verbose("Caas member Suggestions", "MyConnections Component", "getCaasMembersDetails", "GET", "Get Caas Suggested Member API Hit", `userId=${loggedInUserId}&userEntityId=${curUserEntityId}&userRoleId=${curuserRoleId}`);
        axios.get(`${CAASMEMBERSUGGESTIONS}?userId=${loggedInUserId}&userEntityId=${curUserEntityId}&userRoleId=${curuserRoleId}`)
            .then(resp => {
                setCaasDetails(resp.data.response)
                // logger.verbose("Caas member Suggestions", "MyConnections Component", "getCaasMembersDetails", "GET", "Get Caas Suggested Member API Hit", JSON.stringify(resp.data.response));
            }).catch(error => {
                console.log(error);
                logger.error("Caas member Suggestions", "MyConnections Component", "getCaasMembersDetails", "GET", "Get Caas Suggested Member API Response Failed", JSON.stringify(error));
            })
    }
    const getConnectionRequest = () => {
        // logger.verbose("Caas member Connection Request", "MyConnections Component", "getConnectionRequest", "GET", "Get Caas Member Request API Hit", `userId=${loggedInUserId}&userEntityId=${curUserEntityId}&userRoleId=${curuserRoleId}`);
        axios.get(`${CONNECTIONREQUEST}?userId=${loggedInUserId}&userEntityId=${curUserEntityId}&userRoleId=${curuserRoleId}`)
            .then(resp => {
                if (resp.data.status === 200) {
                    setConnectionDetails(resp.data.response);
                    document.getElementById("connectionCount").innerText = resp.data.response?.length;
                    // logger.verbose("Caas member Connection Request", "MyConnections Component", "getConnectionRequest", "GET", "Get Caas Member Request API Response Successfull", JSON.stringify(resp.data.response));
                }
            }).catch(error => {
                console.log(error);
                logger.error("Caas member Connection Request", "MyConnections Component", "getConnectionRequest", "GET", "Get Caas Member Request API Response Failed!!", JSON.stringify(error));
            })
    }
    useEffect(() => {
        getCaasMembersDetails();
        getConnectionRequest();
        var highlightele = document.getElementById("myConnectionsLink");
        highlightele.classList.remove("text-black-50");
        highlightele.classList.add("text-link");
        $("html").removeClass();
        $("html").addClass('myconnections');
        setTimeout(function(){ var box_1 = $(window).height() - $('.box_1 .sticky-top .box_shadow_1:first-child').height();
        $(".box_1 .sticky-top .box_shadow_1 .list-company").attr('style', 'height:' + (box_1-213+ 'px !important')); }, 3000);
        
    }, [])
    const handleInvitation = (userConnectionRequestId, invitationType, requestedByUserId, requestedToUserEntityId, requestedToUserRoleId, firstName, lastName) => {
        // console.log(userConnectionRequestId, invitationType, requestedByUserId, requestedToUserEntityId, requestedToUserRoleId);
        // logger.verbose("Handle Invitation", "MyConnections Component", "Handle Invitation Request Caas Member", "POST", "Handle Invitation request of caas Member ", "");
        const requestObj = {
            "userConnectionRequestId": userConnectionRequestId,
            "requestedByUserId": requestedByUserId,
            "requestedByUserEntityId": requestedToUserEntityId,
            "requestedByUserRoleId": requestedToUserRoleId,
            "requestedToUserId": loggedInUserId,
            "requestedToUserEntityId": curUserEntityId,
            "requestedToUserRoleId": curuserRoleId,
            "isAccepted": invitationType
        }
        // logger.verbose("Handle Invitation", "MyConnections Component", "Handle Invitation Request Caas Member", "POST", "Handle Invitation request object created ", JSON.stringify(requestObj));
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
        }
        axios.post(INVITATIONREQUEST, requestObj, {
            headers: headers
        }).then(response => {
            if (response.data.status === 200) {
                if (response.data.response[0].status === true) {
                    celebrationToast(`${firstName} ${lastName} and ${curuserName} are connected!`)
                    // celebrationToast('Invitation Accepted Successfully!')
                    // logger.verbose("Handle Invitation", "MyConnections Component", "Handle Invitation Request Caas Member", "POST", "Invitation Accepted Successfully!", JSON.stringify(response.data.response[0]));
                }
                else {
                    successToast('Invitation Rejected Successfully')
                    // logger.verbose("Handle Invitation", "MyConnections Component", "Handle Invitation Request Caas Member", "POST", "Invitation Rejected Successfully!", JSON.stringify(response.data.response[0]));
                }
                getConnectionRequest();

            }
        }).catch(error => {
            console.error('There was an error!', error);
            logger.error("Handle Invitation", "MyConnections Component", "Handle Invitation Request Caas Member", "POST", "Handle Invitation request API Failed", JSON.stringify(error));
        });

    }
    useEffect(() => {
        let selectedUser = caasdetails.findIndex((obj => obj.userId == curuserid));
        if (selectedUser != -1) {
            let currentUserpObj = caasdetails[selectedUser];

            setCurUser({ ...currentUserpObj })
        }
    }, [curuserid]);
    const handleMessageChange = event => {
        setInviteMessage(event.target.value);
    };
    const handleSendInvitation = (e) => {
        // console.log(invitemessage);
        // logger.verbose("Send Invitation", "MyConnections Component", "Send Invitation Request Caas Member", "POST", "Send Invitation request to caas Member ", "");
        const requestObj = {
            "requestedByUserId": loggedInUserId,
            "requestedByUserEntityId": curUserEntityId,
            "requestedByUserRoleId": curuserRoleId,
            "requestedToUserId": curuserid,
            "requestedToUserEntityId": curuser.entityId,
            "requestedToUserRoleId": curuser.roleId,
            "invitationMessage": invitemessage
        }
        // logger.verbose("Send Invitation", "MyConnections Component", "Send Invitation Request Caas Member", "POST", "Send Invitation request object created", JSON.stringify(requestObj));
        const headers = {
            'Content-Type': 'application/json;charset=utf-8',
        }
        axios.post(SENDINVITATION, requestObj, {
            headers: headers
        }).then(response => {
            if (response.data.status === 200) {
                successToast('Invitation Sent Successfully');
                setInviteMessage('');
                getCaasMembersDetails();
                // logger.verbose("Send Invitation", "MyConnections Component", "Send Invitation Request Caas Member", "POST", "Send Invitation request Successfully! API response", JSON.stringify(response.data.response));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            logger.error("Send Invitation", "MyConnections Component", "Send Invitation Request Caas Member", "POST", "Send Invitation request API Failed", JSON.stringify(error));
        });
    }
    const handledropdownselect = () => {
        navigate('/cxo/cxo/caasmembers', { state: { filterValue: "null" } });
    }
    const toggleinvitations=()=>{
        setViewInvitations(!viewInvitations);
    }
    return (
        <>
            <div className="main-body-section main-body-section-social">
                <SocialHeader />
                <section className="body-section">
                    <div className="body-content container-fluid">
                    <div className="row1 col_myconnection">
                            <div className="col_res1 align-items-stretch box_1 w_171z">
                                <div className="sticky-top">
                                    <SocialFeedNavigations />
                                    <ProfileInfoAnalytics />
                                </div>
                            </div>
                            <div className="mob-remove-padding box_2">

                                <div className="box-home box_shadow_1">
                                    <h3 className="fw-bold fs-4 mb_10 dis-block d-flex justify-content-between align-items-center px-3">Invitations  <a className="float-end d-none">Manage</a></h3>
                                    {
                                        connectiondetails.length != 0 ? (
                                            connectiondetails.map((elem, index) => (
                                                <>
                                                {(index >2)?<div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
  data-bs-parent="#accordionExample">
  <div className="accordion-body px-0">
    <div key={index} className="border-bottom mb_10">
      <div className="d-flex1 justify-content-between align-items-center px-3" >
          <div>
              <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex justify-content-start align-items-center">
                      <img src={elem?.requestedByUserProfilePicture} alt="Requested User" className="img-fluid img-profile" />
                      <div className="ps-3">
                          <p className="mb-2 fw-bold text-wrap">{elem?.requestedByUserFirstName} {elem?.requestedByUserLastName}<span className="text-black-50 ml-10">{dateDiff(elem?.createdDate)} </span></p>
                          <p className="mb-2">{elem?.requestedByUserProfileTitle}</p>
                          {/* <p className="mb-2 text-black-50"><i className="fa-solid fa-link fa-sm pe-1"></i> Aravind Sirivelli, John and 810 others</p> */}
                          <p className="mb-2 whitetext"><i className="fa-solid fa-link fa-sm pe-1"></i> Aravind Sirivelli, John and 810 others</p>
                          <p className="mb-2 text-black-50 breakContent">{elem?.invitationMessage?.substring(0, 140)}
          {elem?.invitationMessage.length > 140 ? <label title={elem?.invitationMessage}>...</label> : null}
          <a data-bs-toggle="modal" data-bs-target="#ProfileSummary" className={`${elem?.invitationMessage.length <= 140 ? "hide" : ""} text-link ml_20`} onClick={() => {
              bindThisInvite(elem);
          }}>See more</a></p>
                      </div>
                  </div>
                  <div className="float-end"></div>
                  <div className="float-end"><a className="text-muted ml_1001 mr_30" value={elem?.userConnectionRequestId} onClick={() => {
                      handleInvitation(elem?.userConnectionRequestId, false, elem?.requestedByUserId, elem.requestedToUserEntityId, elem?.requestedToUserRoleId, elem?.requestedByUserFirstName, elem?.requestedByUserLastName)
                  }}>Ignore</a> 
                  <a className="text-link" value={elem.userConnectionRequestId} onClick={() => {
                      handleInvitation(elem?.userConnectionRequestId, true, elem?.requestedByUserId, elem?.requestedToUserEntityId, elem?.requestedToUserRoleId, elem?.requestedByUserFirstName, elem?.requestedByUserLastName)
                  }}>Accept</a></div>
              </div>
          </div>

      </div>
      
  </div>
    
  </div>
</div>:<div key={index} className="border-bottom mb_10">
                                                    <div className="d-flex1 justify-content-between align-items-center px-3" >
                                                        <div>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <img src={elem?.requestedByUserProfilePicture} alt="Requested User" className="img-fluid img-profile" />
                                                                    <div className="ps-3">
                                                                        <p className="mb-2 fw-bold text-wrap">{elem?.requestedByUserFirstName} {elem?.requestedByUserLastName}<span className="text-black-50 ml-10">{dateDiff(elem?.createdDate)} </span></p>
                                                                        <p className="mb-2">{elem?.requestedByUserProfileTitle}</p>
                                                                        {/* <p className="mb-2 text-black-50"><i className="fa-solid fa-link fa-sm pe-1"></i> Aravind Sirivelli, John and 810 others</p> */}
                                                                        <p className="mb-2 whitetext"><i className="fa-solid fa-link fa-sm pe-1"></i> Aravind Sirivelli, John and 810 others</p>
                                                                        <p className="mb-2 text-black-50 breakContent">{elem?.invitationMessage?.substring(0, 140)}
                                                        {elem?.invitationMessage.length > 140 ? <label title={elem?.invitationMessage}>...</label> : null}
                                                        <a data-bs-toggle="modal" data-bs-target="#ProfileSummary" className={`${elem?.invitationMessage.length <= 140 ? "hide" : ""} text-link ml_20`} onClick={() => {
                                                            bindThisInvite(elem);
                                                        }}>See more</a></p>
                                                                    </div>
                                                                </div>
                                                                <div className="float-end"></div>
                                                                <div className="float-end"> 
                                                                <a className="text-muted ml_1001 mr_30" value={elem?.userConnectionRequestId} onClick={() => {
                                                                    handleInvitation(elem?.userConnectionRequestId, false, elem?.requestedByUserId, elem.requestedToUserEntityId, elem?.requestedToUserRoleId, elem?.requestedByUserFirstName, elem?.requestedByUserLastName)
                                                                }}>Ignore</a>
                                                                <a className="text-link" value={elem.userConnectionRequestId} onClick={() => {
                                                                    handleInvitation(elem?.userConnectionRequestId, true, elem?.requestedByUserId, elem?.requestedToUserEntityId, elem?.requestedToUserRoleId, elem?.requestedByUserFirstName, elem?.requestedByUserLastName)
                                                                }}>Accept</a></div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    
                                                </div>}
                                                
                                                </>
                                            ))
                                        ) : <div className="d-flex justify-content-between align-items-center px-3">
                                            <div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <p className="mt_10"> No new Invitations </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {connectiondetails.length > 3 ? <div className="text-center mt_30"><div className="clearfix"><div className="accordion" id="accordionExample">
  <a className="accordion-button collapsed invitation_style" type="button" data-bs-toggle="collapse" onClick ={toggleinvitations} data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">{viewInvitations?"View Recent Invitations":"View all Invitations"}</a>
  </div></div></div> : null}
                                </div>

                                {/* <div className="box-home box_shadow_1 mt-4 pr_25 d-none">
                                    <h3 className="fw-bold fs-4 mb_10 dis-block pl_20">CAAS Members you may know
                                        <a href="#" className="text-link float-md-end" onClick={handledropdownselect}>See all</a></h3>
                                    <div className="row mt_10">
                                        {
                                            caasdetails.length != 0 ? (
                                                caasdetails.map((elem, index) => (
                                                    <div className="col-md-4" key={index}>
                                                        <div className="d-flex  justify-content-start align-items-center">
                                                            <div className="box-shadow p-2 border-round w-100 custom_width">
                                                                <div className="fit-div">
                                                                    <img src={elem.profilePicture} className="fit-img" />
                                                                </div>
                                                                <p className="mb-0 fw-bold mt_10 text-center">{elem.firstName} {elem.lastName}</p>
                                                                <p className="mb-0 text-black-50  text-center font-small">{elem.profileTitle}</p>
                                                                <p className="mb-0 text-black-50  text-center font-small hide">Henry David and 317 and others</p>
                                                                <div className="text-center mt_20 fw-bold">
                                                                    <a href="#" className="text-link"
                                                                        data-bs-toggle="modal" data-bs-target="#ConnectCaasMember" id="connectcaasMemberbtn" data-for={elem.userId} onClick={() => {
                                                                            setcurUserId(elem.userId)
                                                                        }}
                                                                    > <i className="fa fa-plug" title="Connect CAAS member" ></i> Connect</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                ))
                                            ) : null
                                        }
                                    </div>
                                </div> */}

                                <div className="box-home box_shadow_1 mt-4 ">
                                    <h3 className="fw-bold fs-4 mb_10 dis-block pl_20">CAAS Members you may know
                                        <a href="#" className="text-link float-md-end" onClick={handledropdownselect}>See all</a></h3>
                                    <div className="card-body">
                                        <div id="lightgallery" className="row lightGallery">
                                            {
                                                caasdetails.length != 0 ? (
                                                    caasdetails.map((elem, index) => (
                                                        <div className="image-tile p-2 border-radius-20" key={index}>
                                                            <div>
                                                                <img src={elem?.profilePicture ? elem?.profilePicture : profilepic} alt="image small" className="img-fluid img-center border-radius-20" />
                                                            </div>
                                                            <p className="mb-0 fw-bold mt_10 text-center caasMemberYouMayKnow word-wrap-ellipss">{elem?.firstName} {elem?.lastName}</p>
                                                            <p className="mb-0 text-black-50  text-center font-small caasMemberYouMayKnow word-wrap-ellipss">{elem?.profileTitle}</p>
                                                            <a href="#" className="text-center text-link mt_20 mb_10"
                                                                data-bs-toggle="modal" data-bs-target="#ConnectCaasMember" id="connectcaasMemberbtn" data-for={elem?.userId} onClick={() => {
                                                                    setcurUserId(elem?.userId)
                                                                }}
                                                            > <i className="fa fa-plug" title="Connect CAAS member" ></i> Connect</a>
                                                        </div>
                                                    ))
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* <Conversations /> */}
                            <Connections />
                            <div className="col_res1 align-items-stretch box_4 w_171">
                                <div className="sticky-top">
                                    <CAASWorkspaceNavigations />
                                    {/* <ProjectAnnouncements /> */}
                                    <CompanyAnnouncements />
                                    <Conversations />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="footer-sections"></section>
            </div >
            <div className="modal fade show " id="ProfileSummary">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius profile-card">
                        <div className="modal-header pt_0">
                            <h3 className="modal-title">Invitation</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="profile-content">
                                    <div className="d-flex justify-content-between align-items-center px-3">
                                        <div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <img src={curInvite?.requestedByUserProfilePicture ? curInvite?.requestedByUserProfilePicture : profilepic} alt="Requested User" className="img-fluid img-profile" />
                                                    <div className="ps-3">
                                                        <p className="mb-2 fw-bold">{curInvite.requestedByUserFirstName} {curInvite.requestedByUserLastName}<span className="text-black-50 ml-10">{dateDiff(curInvite?.createdDate)} </span></p>
                                                        <p className="mb-2">{curInvite.requestedByUserProfileTitle}</p>
                                                        <p className="mb-2 text-black-50"><i className="fa-solid fa-link fa-sm pe-1"></i> Aravind Sirivelli, John and 810 others</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-2  mt-3 text-black-50"> {curInvite.invitationMessage} </p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-warning btn-md accept-btn" data-bs-dismiss="modal" value={curInvite.userConnectionRequestId} onClick={() => {
                                handleInvitation(curInvite.userConnectionRequestId, true, curInvite.requestedByUserId, curInvite.requestedToUserEntityId, curInvite.requestedToUserRoleId)
                            }}>Accept</a>
                            <a href="#" className="btn btn-warning btn-md ignore-btn" data-bs-dismiss="modal" value={curInvite.userConnectionRequestId} onClick={() => {
                                handleInvitation(curInvite.userConnectionRequestId, false, curInvite.requestedByUserId, curInvite.requestedToUserEntityId, curInvite.requestedToUserRoleId)
                            }}>Ignore</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="modal fade show" id="UploadPhoto">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title text-black-50" id="exampleModalLabel">
                                Create a post
                            </h3>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex align-items-center">
                                <img
                                    src={profilepic}
                                    alt="CAAS Member"
                                    className="img-fluid rounded-circle img-profile me-3"
                                />
                                <div>
                                    <p className="fw-bold">Steve Jobs</p>
                                    <select className="form-select">
                                        <option>Anyone</option>
                                        <option>Anyone + Twitter</option>
                                        <option>Connections Only</option>
                                    </select>
                                </div>
                            </div>
                            <div className="cursor mt-3">
                                <input
                                    type="text"
                                    className="form-control border-0 ps-0"
                                    placeholder="what do you want to talk about?"
                                />
                                <i></i>
                            </div>
                            <div className="hashtag mt-5 pt-5">
                                <a href="#" className="text-link fw-bold"
                                >Add hashtag</a
                                >
                            </div>
                        </div>
                        <div className="modal-footer text-black-50 justify-content-between">
                            <div>
                                <a href="#" className="text-black-50 m-0 me-3">
                                    <i className="fa-regular fa-image"></i>
                                </a>
                                <a href="#" className="text-black-50 m-0 me-3">
                                    <i className="fa-brands fa-youtube"></i>
                                </a>
                                <a href="#" className="text-black-50 m-0 me-3">
                                    <i className="fa-solid fa-file-lines"></i>
                                </a>
                                <a href="#" className="text-black-50 m-0 me-3">
                                    <i className="fa-solid fa-briefcase"></i>
                                </a>
                                <a href="#" className="text-black-50 m-0 me-3">
                                    <i className="fa-solid fa-sun"></i>
                                </a>
                                <a href="#" className="text-black-50 m-0 me-3">
                                    <span className="mdi mdi-chart-bar"></span>
                                </a>
                                <a href="#" className="text-black-50 m-0 me-4">
                                    <i className="fa-solid fa-ellipsis"></i>
                                </a>
                                <a href="#" className="text-black-50 m-0 me-5 boeder-left ps-4">
                                    <span className="mdi mdi-chat-processing-outline"></span>
                                    Anyone
                                </a>
                            </div>
                            <div>
                                <a className="btn btn-light btn-sm float-end disabled">Post</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="modal fade show " id="ConnectCaasMember">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">Send Invitation</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="d-flex justify-content-between align-items-center mt-2">
                                    <div className="d-flex justify-content-start align-items-center">
                                        <img src={curuser.profilePicture} alt="CAAS Member" className="img-fluid img-profile" />
                                        <div className="ps-3" key={curuser.userId}>
                                            <p className="mb-2 fw-bold">{curuser.userName}</p>
                                            <p className="mb-2">{curuser.profileTitle}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-12 col-sm-12 mt-3">
                                    <label className="invitemsg"><b className="bold">Invitation Message</b></label>
                                    <textarea className="form-control" placeholder="Invitation Message" rows="4" id="invitemessage" value={invitemessage}
                                        onChange={handleMessageChange} ></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-primary btn-md next-btn" data-bs-dismiss="modal" onClick={handleSendInvitation}>Send Request</a>
                            <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster />
        </>
    )
}

export default MyConnections