import React, { useEffect, useState } from 'react'
import Header from '../../../commons/components/Header'
import Footer from '../../../commons/components/Footer'
import RFPDetailsGlobal from '../../RFP/caas/RFPDetailsGlobal'
import { Link } from 'react-router-dom'
import CaaSSideBar from '../../../commons/components/SideBars/CaaSSideBar'
import { useParams, useNavigate } from 'react-router-dom'
import $ from "jquery";
import swal from 'sweetalert';
const ForwardRFP = () => {
    const [forwardAll, setForwardAll] = useState(false);
    const navigate = useNavigate();
    let { rfpId } = useParams();
    var rfpPublishedDetails = [
        {
            rfpId: 1,
            quoteId: 1,
            cioCode: "CIO-101",
            userName: "John, Wig",
            userEmail: "John@gmail.com"
        },
        {
            rfpId: 2,
            quoteId: 2,
            cioCode: "CIO-102",
            userName: "Aravind , Sirivelli",
            userEmail: "aravind.s@swaas.net"
        },
        ,
        {
            rfpId: 1,
            quoteId: 3,
            cioCode: "CIO-103",
            userName: "Roger , Federed",
            userEmail: "roger.federer@gmail.com"
        }
    ]
    useEffect(() => {
        $(".forwardCheckBox").prop('checked', forwardAll);
    }, [forwardAll])
    function forwardQuote() {
        var forwardList = $('input[name=forwardCheckBox]:checked');
        if (forwardList.length != 0) {
            swal("Forwarded RFP Responses", "", "success").then(navigate('/caas/global/rfp', { replace: true }));
        }
        else
            swal("Failed to Forward RFP Response(s)", "Please choose atleast one Response", "error");
    }
    var thisRFPPublishedDetails = rfpPublishedDetails.filter((ele) => ele.rfpId == rfpId)
    var CIOCODES = thisRFPPublishedDetails.map(u => u.cioCode).join(', ');
    return (
        <div className="container-scroller">
            <Header entity="CaaS" />
            <div className="container-fluid page-body-wrapper">
                <CaaSSideBar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="col-12">
                                    <div className="card card-rounded card-outline card-primary">
                                        <div className="card-header">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <nav className="navbar navbar-expand bg-white">
                                                        <ul className="navbar-nav">
                                                            <li className="nav-item bold">
                                                                <h3 className="fw-bold">Forward to Enterprise</h3>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                                <div className="d-flex align-items-center text-nowrap">
                                                    <Link to="/caas/global/rfp" className="btn btn-primary btn-sm add-client btn-icon-text text-nowrap me-3">Back to RFP</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <RFPDetailsGlobal rfpId={rfpId} CIOCODES={CIOCODES} />
                                            <div className="row">
                                                <div className="col-12 col-sm-12 mt-2">
                                                    <div className="template-demo table-responsive">
                                                        <table className="table table-bordered mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <div className="form-check form-check-primary d-flex">
                                                                        <label className="form-check-label">
                                                                            <input type="checkbox" className="form-check-input" id="selectAllQuotes" onClick={() => setForwardAll(!forwardAll)} />
                                                                            <i className="input-helper"></i>
                                                                            <i className="input-helper"></i>
                                                                        </label>
                                                                        <a className="btn btn-lg btn-primary frwrd-btn " href="#" id="forwardBtn" onClick={forwardQuote}>Forward</a>
                                                                    </div>
                                                                    <th>Name</th>
                                                                    <th>Email</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <>{thisRFPPublishedDetails.length != 0 ? (thisRFPPublishedDetails.map((ele) => (<>
                                                                    <tr>
                                                                        <td><div className="form-check form-check-primary d-flex">
                                                                            <input type="checkbox" className="form-check-input forwardCheckBox" name="forwardCheckBox" data-quote-id={ele.quoteId} data-rfp-id={rfpId} /> &nbsp;
                                                                            <a href="#" data-bs-toggle="modal" data-bs-target="#ReceivedQuoteModal">Quote Recived</a>
                                                                        </div></td>
                                                                        <td><a href="#" data-bs-toggle="modal" data-bs-target="#ViewProfile">{ele.userName}</a></td>
                                                                        <td>{ele.userEmail}</td>
                                                                    </tr>
                                                                </>))
                                                                ) : (
                                                                    <p>No records available.</p>
                                                                )
                                                                }
                                                                </>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                <div className="modal fade show" id="ReceivedQuoteModal" tabndex="-1" aria-labelledby="ReceivedQuoteModal" style={{ display: "none", paddingLeft: "0px" }} aria-modal="true" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">View Quote</h3>
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12 col-sm-12 ">
                                        <div className="form-group border-bottom">
                                            <label className="mb-1 fw-bold">Requirement Specification</label>
                                            <p>
                                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aspernatur consequuntur ratione, aperiam eos adipisci ex et pariatur eum, quaerat tempore excepturi consectetur optio minus similique, commodi quis esse ab. Cum.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group  border-bottom">
                                            <label className="mb-1 fw-bold">Scope Confirmation</label>
                                            <p>
                                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aspernatur consequuntur ratione, aperiam eos adipisci ex et pariatur eum, quaerat tempore excepturi consectetur optio minus similique, commodi quis esse ab. Cum.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 ">
                                        <div className="form-group border-bottom">
                                            <label className="mb-1 fw-bold">Out-of-scope Confirmation Addition</label>
                                            <p>
                                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aspernatur consequuntur ratione, aperiam eos adipisci ex et pariatur eum, quaerat tempore excepturi consectetur optio minus similique, commodi quis esse ab. Cum.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12">
                                        <div className="form-group border-bottom">
                                            <label className="mb-1 fw-bold">Cost:</label>
                                            <p>$45,000</p>
                                        </div>
                                    </div>
                                    <div className="mt-2 pb-1">
                                        <h3 className="text-dark fw-bold h4 border-bottom py-3">Attachment</h3>
                                    </div>
                                    <div className="mt-0 pb-1">
                                        <label for="" className="text-gray mb-2 mt-2 pb-3 border-bottom d-block"> <i className="fa-solid fa-file-word fa-lg"></i> - Bill of Material/Hardware/Software Licences</label>
                                    </div>
                                    <div className="mt-2 pb-1">
                                        <label for="" className="text-gray mb-2 mt-2 pb-3 border-bottom d-block "> <i className="fa-solid fa-file-word fa-lg"></i> - Architecture/Design Doccuments</label>
                                    </div>
                                    <div className="mt-0 pb-1 ">
                                        <label for="" className="text-gray mb-2 mt-2 pb-3 border-bottom d-block"> <i className="fa-solid fa-file-word fa-lg"></i> - Project Plan</label>
                                    </div>
                                    <div className="mt-0 pb-1 ">
                                        <label for="" className="text-gray mb-2 mt-2 pb-3  d-block "> <i className="fa-solid fa-file-word fa-lg"></i> - Miscellaneous File</label>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger btn-sm" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ForwardRFP