import React from 'react'
import Header from '../../../commons/components/Header'
import Footer from '../../../commons/components/Footer'
import EnterpriseSideBar from '../../../commons/components/SideBars/EnterpriseSideBar'
import { Link, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import OpenRFPEnterprise from './OpenRFPEnterprise'
import ClosedRFPEnterprise from './ClosedRFPEnterprise'
import ExpiredRFPEnterprise from './ExpiredRFPEnterprise'
import WithdrawnRFPEnterprise from './WithdrawnRFPEnterprise'
import { RFPListContext } from './contexts/RFPListContext'
import { API } from '../../../config/APIConfig';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import DraftRFPEnterprise from './DraftRFPEnterprise'
import logger from '../../../utils/logger'

const RFPLIST = `${API.URL}api/v1/rfp/list`;
const ManageRFP = () => {
    const { i18n, t } = useTranslation(["rfp"]);

    const [statusFilter, setStatusFilter] = useState("all");
    const [RFPList, setRFPList] = useState([]);
    const [curRFPList, setCurRFPList] = useState([]);
    const enterpriseId = sessionStorage.getItem("enterpriseId");
    const userId = sessionStorage.getItem("userId");
    const location = useLocation();
    const data = location.state?.type; 

    const changeStatusFilter = (e) => {
        let value = document.getElementById("rfpStatusFilter").value;
        setStatusFilter(value);
    }
    useEffect(() => {
        getRFPDetails();
        var highlightele = document.getElementById("RFPSidNav");
        highlightele.classList.add("active");
        if (data) {
            setStatusFilter(data);
        }
    }, []);
    const getRFPDetails = () => {
        // logger.verbose("RFP Details", "ManageRFP Component", "getRFPDetails", "GET", "Get RFP Details", `enterpriseId=${enterpriseId}&userId=${userId}`);
        axios.get(`${RFPLIST}?enterpriseId=${enterpriseId}&userId=${userId}`).then(response => {
            if (response.data.status === 200) {
                setRFPList(response.data.response);
                setCurRFPList(response.data.response);
                // logger.verbose("RFP Details", "ManageRFP Component", "getRFPDetails", "GET", "Get RFP Details API Response Successfull", JSON.stringify(response.data.response));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            // logger.error("RFP Details", "ManageRFP Component", "getRFPDetails", "GET", "Get RFP Details API Response Failed!", JSON.stringify(error));
        });
    }
    const searchRFP = () => {
        const keyword = document.getElementById("searchenterrfp").value;
        const searchedRFP = RFPList.filter((ele) => ele?.rfpTitle?.toLowerCase().includes(keyword.toLowerCase().trim()));
        setCurRFPList([...searchedRFP]);
    }
    return (
        <div className="container-scroller">
            <Header entity="Enterprise" />
            <div className="container-fluid page-body-wrapper">
                <EnterpriseSideBar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                
                                    <h4 className="card-title">
                                    {t('manageRFP')}
                                        <Link to="/enterprise/manager/dashboard" className="text-blue fs-5 float-sm-right"><span className=" mdi mdi-arrow-left"></span>{t('backToDashboard')}</Link>
                                    </h4>
                                    <div className="d-flex justify-content-between mb-0 text-muted">
                                        <div>
                                        <span className="mdi mdi-filter"></span>
                                        <a className="filter_by" data-toggle="modal" data-bs-toggle="modal" data-bs-target="#statusfilter">{t('filterByAction')}</a>
                                        </div>
                                        <div className="float-end d-flex align-items-center filter-option mb-0">
                                                        <input className="form-control border-secondary border-right-0 rounded-0 search-input" type="search"
                                                            placeholder="Search RFP"
                                                            id="searchenterrfp"
                                                            onChange={searchRFP}
                                                            onPaste={searchRFP}
                                                        />
                                                        <div className="col-auto">
                                                            <button className="btn btn-outline-secondary border-left-0 rounded-0 rounded-right search-btn" type="button"><i className="fa fa-search"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                    </div>
                                   
                                </div>
                                <RFPListContext.Provider value={{ curRFPList, setCurRFPList }}>
                                    <div className="col-md-12 mx-auto">
                                        {statusFilter == "all" || statusFilter == "open" ? <OpenRFPEnterprise enterpriseId={enterpriseId} /> : null}
                                        {statusFilter == "all" || statusFilter == "draft" ? <DraftRFPEnterprise enterpriseId={enterpriseId} /> : null}
                                        {statusFilter == "all" || statusFilter == "closed" ? <ClosedRFPEnterprise enterpriseId={enterpriseId} /> : null}
                                        {statusFilter == "all" || statusFilter == "expired" ? <ExpiredRFPEnterprise enterpriseId={enterpriseId} /> : null}
                                        {statusFilter == "all" || statusFilter == "withdrawn" ? <WithdrawnRFPEnterprise enterpriseId={enterpriseId} /> : null}
                                    </div>
                                </RFPListContext.Provider>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="modal fade show" id="statusfilter" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title" id="exampleModalLabel">{t('hfilter')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label">{t('filterByStatus')}</label>
                                <div className="col-sm-8">
                                    <select className="form-select" id="rfpStatusFilter">
                                        <option value="all">{t('All')}</option>
                                        <option value="open">{t('Open')}</option>
                                        <option value="draft">{t('draft')}</option>
                                        <option value="closed">{t('Closed')}</option>
                                        <option value="expired">{t('Expired')}</option>
                                        <option value="withdrawn">{t('Withdrawn')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-warning btn-md next-btn" data-bs-dismiss="modal" onClick={changeStatusFilter}>{t('apply')}</a>
                            <a href="#" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">{t('cancel')}</a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}
export default ManageRFP