import React from 'react'
import Header from '../../../commons/components/Header';
import CaaSSideBar from '../../../commons/components/SideBars/CaaSSideBar';
import { API } from '../../../config/APIConfig';
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import swal from 'sweetalert';
import $ from "jquery";
const VENDORDETAILSAPI = `${API.URL}api/v1/vendor/view/details`;
const UPDATEVENDORSTATUSAPI = `${API.URL}api/v1/admin/updatevendorstatus`;
const UPDATEVENDORAPPROVALSTATUSAPI = `${API.URL}api/v1/admin/updatevendorapprovalstatus`;
const RESETPASSWORDAPI = `${API.URL}api/v1/auth/password/reset`;
const VendorView = () => {
    const [vendordetails, SetVendorDetails] = useState([]);
    const [resetUserDetails, setResetUserDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const params = useParams()
    const vendorId = params.vendorId;
    useEffect(() => {
        getVendorDetails(vendorId);
    }, []);
    const getVendorDetails = (vendorId) => {
        setLoading(true);
        axios.get(`${VENDORDETAILSAPI}?vendorId=${vendorId}`).then(response => {
            if (response.data.status === 200) {
                SetVendorDetails(response.data.response);
                setLoading(false);
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
    // const Approved = (e) => {
    //     console.log("In approved")
    //     let id = e.currentTarget.getAttribute("data-id")
    //     const thisVendor = vendordetails.filter((ele) => ele.vendorId == id);
    //     var obj = {
    //         "vendorId": id,
    //         "isApproved": 1,
    //         "keyContact": thisVendor[0]?.keyContact,
    //         "keyContactEmail": thisVendor[0].keyContactEmail,
    //         "vendorName": thisVendor[0].vendorName
    //     }
    //     console.log(obj);
    //     axios.put(UPDATEAPPROVALAPI, obj)
    //         .then(response => {
    //             console.log(response);
    //             console.log(response.data.status);
    //             if (response.data.status === 200) {
    //                 getVendorDetails();
    //             }
    //         })
    //         .catch(error => {
    //             console.error('There was an error!', error);
    //         });
    // }
    // const Rejected = (e) => {
    //     let id = e.target.getAttribute("data-for")
    //     var obj = {
    //         "vendorId": id,
    //         "isApproved": 0
    //     }
    //     axios.put(UPDATEAPPROVALAPI, obj)
    //         .then(response => {
    //             console.log(response);
    //             console.log(response.data.status);
    //             if (response.data.status === 200) {
    //                 getVendorDetails();
    //             }
    //         })
    //         .catch(error => {
    //             console.error('There was an error!', error);
    //         });
    // }
    const resetVendorStatus = (vendorId, curStatus) => {
        var status = curStatus == 0 ? 1 : 0;
        updateVendorDetails(vendorId, status);
    }
    const bindUserDetailsForReset = (vendorId) => {
        document.getElementById("resetpasswordform").reset();
        const searchvendor = vendordetails.filter((ele) => ele?.vendorId == vendorId);
        if (searchvendor.length != 1)
            return
        setResetUserDetails([...searchvendor]);
        document.getElementById("resetUserName").value = searchvendor[0]?.keyContact;
    }
    const handleKeyPress = (e) => {
        if (e.charCode === 32 && e.key === " ") {
          e.preventDefault();
        }
      }
    const updateVendorDetails = (vendorId, status) => {
        const searchvendor = vendordetails.filter((ele) => ele?.vendorId == vendorId);
        if (searchvendor.length != 1)
            return
        var { keyContact, entityId, roleId } = searchvendor[0];
        let updobj = { vendorId, entityId, roleId, keyContact, status }
        axios.put(UPDATEVENDORSTATUSAPI, updobj)
            .then(response => {
                if (response.data.status === 200) {

                    swal("Updated Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                    getVendorDetails(vendorId);
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    const resetVendorPassword = () => {
        $("#resetPasswordbtn").addClass("disabled");
        const userName = document.getElementById("resetUserName").value;
        const userNewPassword = document.getElementById("resetVendorPassword").value;
        if (userName.length > 0 && userNewPassword.length > 0) {

            const { vendorId, emailAddress, userId, entityId, roleId, keyContact, keyContactEmail } = resetUserDetails[0];
            if (userName === keyContact) {
                let updobj = { vendorId, emailAddress, userId, entityId, roleId, userName, userNewPassword, keyContactEmail };
                axios.put(RESETPASSWORDAPI, updobj)
                    .then(response => {
                        if (response.data.status === 200) {
                            swal("Password was Reset Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                            $("#resetPasswordbtn").removeClass("disabled");
                            $("#ResetPassword .close").trigger('click');
                            document.getElementById("resetpasswordform").reset();
                            getVendorDetails(vendorId);
                        }
                        $("#resetPasswordbtn").removeClass("disabled");
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                        $("#resetPasswordbtn").removeClass("disabled");
                    });
            }
        }
        else {
            swal("Please enter the password", "", "error", { closeOnClickOutside: false, closeOnEsc: false });
            $("#resetPasswordbtn").removeClass("disabled");
        }
    }
    const updateVendorApprovalStatus = (vendorId, approval) => {

        const searchedvendor = vendordetails.filter((ele) => ele?.vendorId == vendorId);
        if (searchedvendor.length != 1)
            return
        var { vendorName, emailAddress, keyContact, entityId, roleId, keyContactEmail } = searchedvendor[0];
        var isApproved = approval == 0 ? 1 : 0;
        let updobj = { vendorId, entityId, emailAddress, roleId, vendorName, keyContact, isApproved, keyContactEmail }
        axios.put(UPDATEVENDORAPPROVALSTATUSAPI, updobj)
            .then(response => {
                if (response.data.status === 200) {
                    getVendorDetails(vendorId);
                    swal("Approved Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }
    return (
        <div className="container-scroller">
            <Header entity="CaaS" />
            <div className="container-fluid page-body-wrapper">
                <CaaSSideBar />
                {loading ? (
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                ) : (
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-12 grid-margin stretch-card">
                                    <div className="col-12">
                                        <div className="grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body pb-0">
                                                    <h4 className="card-title">
                                                        Vendor Details
                                                    </h4>
                                                    <Link className="text-blue fs-5 float-sm-right" to="/caas/global/vendor/manage"><span className=" mdi mdi-arrow-left"></span>Back to Vendors</Link>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="mt-0 text-left box-shadow p-4 cardPad card-body border border-radius">
                                                    <div class="d-flex justify-content-between align-items-start RFP_Details">
                                                        {vendordetails.length != 0 ? (
                                                            vendordetails.map((ele, index) => (
                                                                < div class="list-item-details" key={index}>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5 text-dark">Vendor Name</div>
                                                                        <div class="d-table-cell">{ele.vendorName}</div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">Vendor Website</div>
                                                                        <div class="d-table-cell">{ele.website}</div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">Key Contact</div>
                                                                        <div class="d-table-cell">{ele.keyContact}</div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">Key Contact Email</div>
                                                                        <div class="d-table-cell">{ele.keyContactEmail}</div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">Phone Number</div>
                                                                        <div class="d-table-cell">{ele.phoneNumber}</div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">City</div>
                                                                        <div class="d-table-cell">{ele.cityName}</div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">State</div>
                                                                        <div class="d-table-cell">{ele.stateName}</div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">Country</div>
                                                                        <div class="d-table-cell">{ele.countryName}</div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">Zip Code</div>
                                                                        <div class="d-table-cell">{ele.zipCode}</div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">Status</div>
                                                                        {/* <div class="d-table-cell">
                                                                        <div className={ele?.isApproved == 1 ? "badge badge-pill badge-success" : "badge badge-pill badge-danger"}>{ele?.isApproved == 1 ? "Approved" : "Rejected"}</div>
                                                                    </div> */}
                                                                        <div className="d-flex">
                                                                            <label htmlFor="site_state" className="form-check-label">In Active</label>
                                                                            <div className="form-switch form-check ms-2 margin-0">
                                                                                <input type="checkbox" className="form-check-input" id="site_state" onChange={() => {

                                                                                    resetVendorStatus(ele?.vendorId, ele?.status)

                                                                                }} checked={ele?.status == 1 ? 'checked' : ''} />
                                                                            </div>
                                                                            <label htmlFor="site_state" className="form-check-label">Active</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">Approval Status</div>
                                                                        <div class="d-table-cell">
                                                                            {ele?.isApproved == 1 ? "Approved" : <div className="form-switch form-check  margin-0 text-center">
                                                                                <input type="checkbox" className="form-check-input text-center" id="site_state" onChange={() => {

                                                                                    // resetUserStatus(ele?.userId,ele?.userName,ele?.userStatus)
                                                                                    updateVendorApprovalStatus(ele?.vendorId, ele?.isApproved)

                                                                                }} />
                                                                            </div>}
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-table">
                                                                        <div class="d-table-cell pe-5">Reset Password</div>
                                                                        <div class="d-table-cell">{ele?.isApproved == 1 ?
                                                                            <a className="btn addindustrybtn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#ResetPassword" title="Reset Password"
                                                                                onClick={() => { bindUserDetailsForReset(ele?.vendorId) }}
                                                                            >
                                                                                Reset Password
                                                                            </a>
                                                                            : "NA"}</div>
                                                                    </div>
                                                                </div>))
                                                        ) : <span>No Vendor Data Available</span>
                                                        }
                                                    </div>
                                                    <div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="modal fade show" id="ResetPassword" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">Reset Password </h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="resetpasswordform">

                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">User Name </label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="resetUserName" disabled />

                                    </div>

                                    <label className="col-sm-4 col-form-label mt_10">Password </label>
                                    <div className="col-sm-8 mt_10">
                                        <input type="text" className="form-control" id="resetVendorPassword" onKeyPress={handleKeyPress} />

                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">Cancel</a>
                            <a type="button" className="btn btn-warning btn-md next-btn" id="resetPasswordbtn"
                                onClick={resetVendorPassword}
                            >Reset</a>

                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default VendorView
