import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../commons/components/Header';
import Footer from '../../../commons/components/Footer';
import { API } from '../../../config/APIConfig';
import axios from 'axios';
import CaaSSideBar from '../../../commons/components/SideBars/CaaSSideBar';
import { useTranslation } from "react-i18next";
import CXOProfileView from '../../../commons/components/Profile/CXOProfileView';
import $ from "jquery"
import Pagination from './Pagination';
import RFPDetailsGlobal from '../../RFP/caas/RFPDetailsGlobal'
import CaasMemberView from './CaasMemberView';

export default function RFPPublishedUsers() {
    const { i18n, t } = useTranslation(["rfp"]);
    const RFPUBLISHEDUSERSAPI = `${API.URL}api/v1/admin/rfp/cxo/list`;
    const location = useLocation();
    const rfpId = location.pathname.split('/')[5];
    // const rfpStatus = location.state.status;
    // const title = location.state.title;
    const [rfpPublishedUsers, setRFPPublishedUsers] = useState([]);
    const [curRFPPublishedUsers, setCurRFPPublishedUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [curCXOData, setCurCXOData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    // No of Records to be displayed on each page   
    const [recordsPerPage] = useState(10);
    //pagination
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = curRFPPublishedUsers.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(curRFPPublishedUsers.length / recordsPerPage);
    useEffect(() => {
        getRFPPublishedUsersDetails();
        // highlightNavMenu();
    }, []);
    const getRFPPublishedUsersDetails = () => {
        setLoading(true);
        // logger.verbose("Quote Details", "RFPQuote Component", "getQuoteDetails", "GET", "Get Quote Details API Hit", `rfpId=${rfpId}`);
        axios.get(`${RFPUBLISHEDUSERSAPI}?rfpId=${rfpId}`).then(response => {

            if (response.data.status === 200) {

                setRFPPublishedUsers(response.data.response);
                setCurRFPPublishedUsers(response.data.response);
                setLoading(false);

                // logger.verbose("Quote Details", "RFPQuote Component", "getQuoteDetails", "GET", "Get Quote Details API Response Successfull", JSON.stringify(response.data.response));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            // logger.error("Quote Details", "RFPQuote Component", "getQuoteDetails", "GET", "Get Quote Details API Response Failed!!", JSON.stringify(error));
        });
    }
    // const highlightNavMenu = () => {
    //     $("li.sidebarmenu").removeClass("active");
    //     $("#caas_dasboard_li").addClass("active");
    // }
    const resetCurCXO = (userId, roleId) => {
        let arr = [];
        let obj = { userId, roleId }
        arr.push(obj);
        setCurCXOData([...arr]);
    }
    const searchPublishedUser = () => {
        const keyword = document.getElementById("searchuser").value;
        const searcheduser = rfpPublishedUsers.filter((ele) => ele.firstName.toLowerCase().includes(keyword.toLowerCase()) || ele.lastName.toLowerCase().includes(keyword.toLowerCase()));
        setCurrentPage(1);
        setCurRFPPublishedUsers([...searcheduser]);
    }
    return (
        <>
            <div className="container-scroller">
                <Header entity="CaaS" />
                <div className="container-fluid page-body-wrapper">
                    <CaaSSideBar />
                    {loading ? (
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    ) : (
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <div className="card card-rounded card-outline card-primary">
                                            <div className="card-header">
                                                <h1 className='fw-bold'>RFP Details</h1>
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        {/* <nav className="navbar navbar-expand bg-white">

                                                            <ul className="navbar-nav">
                                                                <li className="nav-item bold">


                                                                    <h3 className='fw-bold'></h3>
                                                                </li>
                                                            </ul>
                                                        </nav> */}
                                                    </div>
                                                    <div>
                                                        {/* <Link to="/caas/global/managerfp" state={{ status: rfpStatus }} className="btn btn-primary btn-sm add-client btn-icon-text"><i className="fa fa-arrow-left btn-icon-prepend"></i>{t('backToRFP')}</Link> */}
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="card-body card-rounded card-primary">

                                                <RFPDetailsGlobal rfpId={rfpId} />
                                            </div>
                                            <div className="card-body">
                                                <div className="float-end d-flex align-items-center filter-option mb-0 mt-2 ">
                                                    <input className="form-control border-secondary border-right-0 rounded-0 search-input" type="search"
                                                        placeholder="Search User"
                                                        id="searchuser"
                                                        onChange={searchPublishedUser}
                                                    />
                                                    <div className="col-auto">
                                                        <button className="btn btn-outline-secondary border-left-0 rounded-0 rounded-right search-btn" type="button"><i className="fa fa-search"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row px-4">

                                                    <div className="col-12 col-sm-12 mt-2">
                                                        <div className="col-12 col-sm-12 mt-2">
                                                            <h1 className='fw-bold'>RFP Recipient</h1>
                                                            <div className="template-demo table-responsive">
                                                                <Records currentRecords={currentRecords} curRFPPublishedUsers={curRFPPublishedUsers} resetCurCXO={resetCurCXO} />
                                                                <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />

                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <Footer />

                        </div>

                    )}


                </div>
            </div>
            <div className="modal fade show" id="ViewProfile" aria-modal="true" role="dialog" tabIndex="-1" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('viewProfile')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setCurCXOData([])}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row w-100 mx-0">

                                {curCXOData.length != 0 ? (<CaasMemberView quotedBy={curCXOData[0]?.userId} roleId={curCXOData[0]?.roleId} hideBack={true} />) : <p>{t('noRecordsFound')}</p>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={() => setCurCXOData([])}>{t('close')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </>


    )
}
const Records = ({ currentRecords, resetCurCXO }) => {
    return (
        <>

            <table className="table table-bordered mb-2">

                <thead>
                    <tr>
                        <th>CAAS Members</th>
                        <th>Status</th>
                        <th>Profile Details</th>

                    </tr>
                </thead>
                <tbody>
                    {
                        currentRecords.length != 0 ? (currentRecords.map((item, i) => (
                            item.userValidity == 1 ?
                                <tr key={i}>
                                    <td className="text-left-intend">
                                        {item?.firstName}{item?.lastName}
                                    </td>
                                    <td>Active</td>
                                    <td>
                                        <a href="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#ViewProfile"
                                            data-backdrop="static" data-keyboard="false"
                                            onClick={() => resetCurCXO(item?.userId, item?.roleId)}
                                        >
                                            View Profile</a>
                                    </td>

                                </tr> : <tr key={i} className="table-light">
                                    <td className="text-left-intend">
                                        {item?.firstName}{item?.lastName}
                                    </td>
                                    <td>Inactive</td>
                                    <td>NA</td>

                                </tr>))) : <tr> <td>No Data Found</td><td>No Data Found</td></tr>}

                </tbody>
            </table>
        </>)
}

