
const counterAnimation = (ele, count) => {
    if (ele) {
        let interval = 1000;
        let startValue = 0;
        let endValue = parseInt(count);
        if (endValue > 0) {
            let duration = Math.floor(interval / endValue);
            let counter = setInterval(function () {
                startValue += 1;
                ele.textContent = startValue;
                if (startValue >= endValue) {
                    clearInterval(counter);
                }
            }, duration);
        }
    }
}

export default counterAnimation