import { React, useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom';
import Header from '../../../commons/components/Header';
import CaaSSideBar from '../../../commons/components/SideBars/CaaSSideBar';
import { API } from '../../../config/APIConfig';
import axios from 'axios'
import formatDate from '../../../utils/dateformatter';
import logger from '../../../utils/logger';
import $ from "jquery";
import swal from 'sweetalert';
import Pagination from './Pagination';

export default function AreaOfProjectManagement() {

    const [curAreaOfProject, setCurAreaOfProject] = useState({});
    const [areaOfProjectList, setAreaOfProjectList] = useState([]);
    const [curAreaOfProjectList, setCurAreaOfProjectList] = useState([]);
    const AreaOfProjectAPI = `${API.URL}api/v1/meta/allareaofprojectlist`;
    const AreaOfProjectCreateAPI = `${API.URL}api/v1/meta/areaofproject/create`
    const AreaOfProjectUpdateAPI = `${API.URL}api/v1/meta/areaofproject/update`
    const AreaOfProjectDeleteAPI = `${API.URL}api/v1/meta/areaofproject/delete`
    const ProjectNameValidationAPI = `${API.URL}api/v1/meta/validate/areaofproject`
    const [projectExists, setProjectExists] = useState(false);
    const [loading, setLoading] = useState(false);

    // User is currently on this page
    const [currentPage, setCurrentPage] = useState(1);
    // No of Records to be displayed on each page   
    const [recordsPerPage] = useState(10);
    //pagination
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

    const getareaOfProjectList = async () => {
        const response = await fetch(AreaOfProjectAPI);
        if (!response.ok) {
            throw new Error('Data coud not be fetched!')
        } else {
            return response.json()
        }
    }
    useEffect(() => {
        bindAreaOfProject();
        highlightNavMenu();
    }, []);
    const highlightNavMenu = () => {
        $("li.sidebarmenu").removeClass("active");
        $("#caas_projectareas_li").addClass("active");
    }

    //pagination
    const currentRecords = curAreaOfProjectList.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(curAreaOfProjectList.length / recordsPerPage)

    //
    const bindAreaOfProject = () => {
        setLoading(true);
        getareaOfProjectList()
            .then((data) => {
                if (data.response) {
                    setAreaOfProjectList(data.response);
                    setCurAreaOfProjectList(data.response);
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e.message)
            });
    }

    const resetProjectStatus = (id, name, curStatus) => {
        var status = curStatus == 0 ? 1 : 0;
        updateProjectDetails(id, name, status);
    }

    const editAreaOfProject = () => {
        const id = curAreaOfProject[0]?.areaOfProjectId
        const status = curAreaOfProject[0]?.areaOfProjectStatus;
        const name = document.getElementById("editAreaOfProject").value;
        if (!projectExists)
            updateProjectDetails(id, name, status);
    }
    const updateProjectDetails = (id, name, status) => {
        let updobj =
        {
            areaOfProjectId: id,
            areaOfProject: name,
            areaOfProjectStatus: status,
        }
        var validation = nameValidation(name)
        if (validation) {
            axios.put(AreaOfProjectUpdateAPI, updobj)
                .then(response => {
                    if (response.data.status === 200) {
                        bindAreaOfProject();
                        $("#EditAreaOfProject .close").trigger("click");
                        swal("Updated Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                        document.getElementById("areaofprojectform").reset();
                    }
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }
    }


    const addAreaOfProject = () => {
        var name = document.getElementById("areaOfProject").value;
        var validation = nameValidation(name);
        if (validation === false)
            return

        if (!projectExists) {
            let obj =
            {
                areaOfProject: name,
            }
            axios.post(AreaOfProjectCreateAPI, obj).then(response => {
                if (response.data.status === 200) {
                    bindAreaOfProject();
                    $("#AddAreaOfProject .close").trigger("click");
                    swal("Area Of Project Added Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                    document.getElementById("addProjectForm").reset();
                    removeErrorMsgs();
                }
            })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }
    }
    const removeErrorMsgs = () => {
        $(".projectnameerror").hide();
        $(".projectexistserror").hide();
        // if ($("#areaOfProject").hasClass("error-shadow")) {
        //     $("#areaOfProject").removeClass("error-shadow");
        // }
    }
    const existsCheck = (e) => {
        debugger
        var eleId = e.target.id;
        var name = document.getElementById(eleId).value;
        if (name == null || name == undefined)
            return
        if (eleId === "areaOfProject") {
            var validation = nameValidation(name);
            // if (validation === false)
            //     return
        }

        if (eleId === "editAreaOfProject") {
            var validation = nameValidation(name);
            var existingName = curAreaOfProject[0]?.areaOfProject;
            if (existingName.trim() === name.trim()) {
                return true
            }
        }
        if (validation) {
            axios.get(`${ProjectNameValidationAPI}?areaOfProjectName=${name}`)
                .then(response => {
                    if (response.data === true) {
                        // $("#editAreaOfProject").addClass("error-shadow");
                        // $("#industrynameerror").hide();
                        // $("#existingIndustry").show();
                        $(".projectnameerror").hide();
                        $(".projectexistserror").show();
                        setProjectExists(true);
                    }
                    else {
                        // if ($("#editAreaOfProject").hasClass("error-shadow")) {
                        //     $("#editAreaOfProject").removeClass("error-shadow");
                        // }
                        // $("#existingIndustry").hide();
                        $(".projectexistserror").hide();
                        setProjectExists(false)
                    }
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }
    }


    const nameValidation = (name) => {
        if (name) {
            $(".projectnameerror").hide()
            return true;
        }

        else {
            $(".projectnameerror").show()
            $(".projectexistserror").hide();
            return false;
        }


    }

    const bindThisProject = (id) => {
        removeErrorMsgs();
        const data = areaOfProjectList?.filter((ele) => ele.areaOfProjectId == id);
        setCurAreaOfProject([...data]);
        document.getElementById("editAreaOfProject").value = data[0]?.areaOfProject;
    }
    const searchProject = () => {
        const keyword = document.getElementById("searchproject").value;
        const searchedProjects = areaOfProjectList.filter((ele) => ele.areaOfProject.toLowerCase().includes(keyword.toLowerCase()));
        setCurrentPage(1);
        setCurAreaOfProjectList([...searchedProjects]);
    }

    return (
        <>
            <div className="container-scroller">
                <Header entity="CaaS" />
                <div className="container-fluid page-body-wrapper">
                    <CaaSSideBar />
                    {loading ? (
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    ) : (
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-12 grid-margin stretch-card">
                                        <div className="col-12">
                                            <div class="grid-margin stretch-card">
                                                <div class="card">
                                                    <div class="card-body pb-0">
                                                        <h4 class="card-title">Project Areas</h4>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div class="mt-0 text-left box-shadow cardPad card-body border border-radius">
                                                        <div className="d-flex justify-content-between">
                                                            <a className="btn addindustrybtn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#AddAreaOfProject" title="Add" onClick={removeErrorMsgs}>
                                                                <i class="mdi mdi-note-plus-outline"></i> Add Area of project
                                                            </a>



                                                            <div className="float-end d-flex align-items-center filter-option mb-0">
                                                                <input className="form-control border-secondary border-right-0 rounded-0 search-input" type="search"
                                                                    placeholder="Search Project"
                                                                    id="searchproject"
                                                                    onChange={searchProject}
                                                                />
                                                                <div className="col-auto">
                                                                    <button className="btn btn-outline-secondary border-left-0 rounded-0 rounded-right search-btn" type="button"><i className="fa fa-search"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="row">
                                                            <div className="col-12 col-sm-12 mt-2">
                                                                <div className="home-tab">
                                                                    <div className="tab-content1">
                                                                        <div className="" id="NewCompany" >
                                                                            <div className="template-demo table-responsive">
                                                                                <Records curAreaOfProjectList={currentRecords} bindThisProject={bindThisProject} resetProjectStatus={resetProjectStatus} />
                                                                                <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="modal fade show borderad" id="EditAreaOfProject" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">Edit Area Of Project</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="areaofprojectform">
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Area Of Project Name <span className='error'>*</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="editAreaOfProject" onChange={existsCheck} />
                                        <label id="editprojectnameerror" className="projectnameerror error hide">Project name is required</label>
                                        <label id="editexistingProject" className="projectexistserror error hide">Project already exists</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">Cancel</a>
                            <a type="button" className="btn btn-warning btn-md next-btn" onClick={editAreaOfProject}>Update</a>

                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade show borderad" id="AddAreaOfProject" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">Add Area Of Project</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="addProjectForm">
                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">Area Of Project Name <span className='error'>*</span></label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="areaOfProject" onChange={existsCheck} />
                                        <label id="areaofprojecterror" className="projectnameerror error hide">Area of project  is required</label>
                                        <label id="existingProject" className="projectexistserror error hide">Area of project exists</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">Cancel</a>
                            <a type="button" className="btn btn-warning btn-md next-btn" onClick={addAreaOfProject}>Add</a>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )



}
const Records = ({ curAreaOfProjectList, bindThisProject, resetProjectStatus }) => {

    return (<table className="table table-bordered mb-0">
        <thead>
            <tr>

                <th className="text-left-intend">Area Of Project Name</th>
                <th className="text-center">Status</th>
                <th className="text-center" colspan="2">Action</th>
            </tr>
        </thead>
        <tbody>
            {
                curAreaOfProjectList.length != 0 ? (
                    curAreaOfProjectList.map((ele, index) => (
                        <tr key={ele?.areaOfProjectId} >
                            <td className="text-left-intend">{ele?.areaOfProject}</td>
                            <td className="text-center">
                                <div className={ele?.areaOfProjectStatus === 1 ? "badge  badge-success" : "badge badge-warning"}>{ele?.areaOfProjectStatus === 1 ? "Active" : "InActive"}</div>
                            </td>
                            <td className="text-center">

                                <a className="pb-3"  >
                                    <i className="fa-solid fa-pencil" data-bs-toggle="modal" data-bs-target="#EditAreaOfProject" title="Edit" data-for={ele?.areaOfProjectId} onClick={() => { bindThisProject(ele?.areaOfProjectId) }}></i>
                                </a>


                            </td>
                            <td className='d-grid'>
                                <div className="d-flex mx-auto">
                                    <label htmlFor="site_state" class="form-check-label">In Active</label>
                                    <div class="form-switch form-check ms-2 margin-0">
                                        <input type="checkbox" class="form-check-input" id="site_state" onChange={() => {
                                            resetProjectStatus(ele?.areaOfProjectId, ele?.areaOfProject, ele?.areaOfProjectStatus);
                                        }} checked={ele?.areaOfProjectStatus == 1 ? 'checked' : ''} />
                                    </div>
                                    <label htmlFor="site_state" class="form-check-label">Active</label>
                                </div>
                            </td>

                        </tr>
                    ))
                ) : (
                    <tr>
                    </tr>
                )
            }
        </tbody>
    </table>
    )
}