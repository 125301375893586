import { React, useEffect } from 'react'
import profilepic from "../../../assets/images/noav.jpg"
import CAAS_logo from "../../../assets/images/CAAS_Logo.png"
import { Link, useNavigate } from 'react-router-dom'
import $ from "jquery";
import logger from '../../../utils/logger';
import scrollTop from '../../../utils/scrolltop';

const SocialHeader = () => {
    const navigate = useNavigate();
    const firstname = sessionStorage.getItem("firstname");
    const lastname = sessionStorage.getItem("lastname");
    const profilePicture = sessionStorage.getItem("profilePicture");
    const roleId = sessionStorage.getItem("roleId");
    const userId = sessionStorage.getItem("userId");
    useEffect(() => {
        const bindEventHandlers = () => {
            $("body").css("height","100%");
            // setTimeout(scrollTop, 5000);
            scrollTop();
            $(window).on("resize", function () {
                scrollTop();
            });
        }

        bindEventHandlers();
        usercheck();
    }, [])

    const handlelogout = () => {
        sessionStorage.clear();
        logger.verbose("User Logout", "SocialHeader Component", "Logout Button", "POST", `${firstname} ${lastname} Clicked Logout Button!`, `${firstname}/${lastname}/UserId:${userId}/roleId:${roleId}`);
        navigate("/")
    }
    const usercheck = () => {
        if (roleId == null) {
            navigate("/")
        }
    }

    const handledropdownselect = () => {
        let selectvalue = document.getElementById('dropdownval').value;
        if (selectvalue == 1) {
            logger.verbose("User Profile", "SocialHeader Component", "User Profile Button", "GET", `${firstname} ${lastname} Clicked my Profile Option and redirect to profile View Page!`, `${firstname}/${lastname}/UserId:${userId}/roleId:${roleId}`);
            navigate('/cxo/myprofile', { state: { quotedBy: userId, role: roleId } });
        }
        else if (selectvalue == 0) {
            handlelogout();
        }
    }
    return (
        <section className="header-section sticky-top1">
            <div className="container-fluid pb-4 pt-3">
                <div
                    className="header d-flex justify-content-between align-items-center box_shadow_1 ml_20 mr_20 px-4 py-3"
                >
                    <div className="d-flex align-items-center">
                        <img
                            src={profilePicture}
                            alt="profile"
                            className="img-fluid img-profile-head"
                        />
                        <select className="form-select border-0 outline-hide" id="dropdownval" onClick={handledropdownselect}>
                            <option className="hide" value="-1">{firstname} {lastname}</option>
                            <option value="1">My Profile</option>
                            <option value="0">Logout</option>
                        </select>

                    </div>
                    <div className="d-flex align-items-center">
                        <Link to="/cxo/cxo/wall/personal" className="Home-Brand" href="#">
                            <img
                                src={CAAS_logo}
                                className="img-responsive caas_logo"
                            />
                        </Link>
                    </div>
                    <div className="row no-gutters col-md-3" id="globalSearchDiv">
                        <div className="col">
                            <input
                                className="form-control border-secondary border-right-0 rounded-0 search-input d-none"
                                type="search"
                                placeholder="Global Search"
                                id="globalSearchInput"
                            />
                        </div>
                        <div className="col-auto">
                            <button
                                className="btn btn-outline-secondary border-left-0 rounded-0 rounded-right search-btn d-none" 
                                id="globalSearchBtn"
                                type="button"
                            >
                                <i className="fa fa-search"></i>
                            </button>
                        </div> 
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SocialHeader