import React, { useState,useEffect } from 'react'
import { Link } from "react-router-dom";
import CAAS from "../../assets/images/website/CAAS.png";
import menu from "../../assets/images/website/menu.png";
import leftarrow from "../../assets/images/website/left-arrow.png";
import team from "../../assets/images/website/team.png";
import shopify from "../../assets/images/website/shopify.png";
import team2 from "../../assets/images/website/team2.png";
import google from "../../assets/images/website/google.png";
import team3 from "../../assets/images/website/Team3.png";
import apple from "../../assets/images/website/apple.png";
import team4 from "../../assets/images/website/team4.png";
import team5 from "../../assets/images/website/team5.png";
import amazon from "../../assets/images/website/amazon.png";
import goldmansachs from "../../assets/images/website/goldman-sachs.png";
import rightarrow from "../../assets/images/website/right-arrow.png";
import service1 from "../../assets/images/website/service1.png";
import service2 from "../../assets/images/website/service2.png";
import service3 from "../../assets/images/website/service3.png";
import service4 from "../../assets/images/website/service4.png";
import service5 from "../../assets/images/website/service5.png";
import service6 from "../../assets/images/website/service6.png";
import service7 from "../../assets/images/website/service7.png";
import service8 from "../../assets/images/website/service8.png";
import service9 from "../../assets/images/website/service9.png";
import service10 from "../../assets/images/website/service10.png";
import service11 from "../../assets/images/website/service11.png";
import service12 from "../../assets/images/website/service12.png";
import service13 from "../../assets/images/website/service13.png";
import service14 from "../../assets/images/website/service14.png";
import service15 from "../../assets/images/website/service15.png";
import service16 from "../../assets/images/website/service16.png";
import one from "../../assets/images/website/one.png";
import two from "../../assets/images/website/two.png";
import three from "../../assets/images/website/three.png";
import four from "../../assets/images/website/four.png";
import testileft from "../../assets/images/website/testi-left.png";
import testi from "../../assets/images/website/testi.png";
import testigirl from "../../assets/images/website/testi-girl.png";
import testright from "../../assets/images/website/test-right.png";
import testilogo from "../../assets/images/website/testi-logo.png";
import facebook from "../../assets/images/website/facebook.jpg";
import youtube from "../../assets/images/website/youtube.jpg";
import instagram from "../../assets/images/website/instagram.jpg";
import twitter from "../../assets/images/website/twitter.jpg";
import linkedin from "../../assets/images/website/linkedin.png";
import "../../assets/css/website-custom.css";
import $ from "jquery";



const LandingPage = () => {
    const firstname = sessionStorage.getItem("firstname");
    const lastname = sessionStorage.getItem("lastname");
    const userName = sessionStorage.getItem("userName");
    const roleId = sessionStorage.getItem("roleId");
    const [Heightt, setHeight] = useState('0px')
    // var menuList = document.getElementById("menuList");
    // console.log(menuList);
    // menuList.style.maxHeight = "0px"
    const togglemenu = () => {
        ((Heightt == '0px') ? setHeight('500px') : setHeight('0px'));

    }


  
    useEffect(() => {
        $("html").removeClass();
        $("html").addClass('landingpage');
        
          $("#join_link").on("click",function(){
            $("#joinloginview").show();
          });
    }, []);

    return (
    <>
            
                <div className='border-bottom sticky-top bg-white'>
<div className="container websitecontainer1 website">
            <nav class="navbar navbar-expand-xl bg-white px-0 ">
  <div class="container-fluid px-0">
    <a class="navbar-brand py-0" href="#"><img src={CAAS} className="websitelogo" /></a>
    <button class="navbar-toggler p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
    <img src={menu} className="websitemenu-icon1 toggle-icon1" height="30" />
    </button>
    {/* <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button> */}
    <div class="collapse navbar-collapse" id="navbarCollapse">
        
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      <li> <a href="#about">About Us </a></li>
                                <li> <a href="#whyjoin">Why Join </a></li>
                                <li> <a href="#caasstory">The CaaS Story</a></li>
                                <li> <a href="#contact">Contact us </a></li>
                                </ul>
                                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">    
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Join
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item m-0 p-0  w-100" href="/cxo/cxo/register">
                                            <div class="d-flex justify-content-between mt-1 pt-2 ps-3 pe-3 pb-1  w-100">
                                                <div class="bg-user"><i class="fa-regular fa-circle-user fa-2x text-white"></i></div>
                                                <div class="px-2 "><h4 class="head-popup ff-default ">CAAS Member</h4></div>
                                                <div><i class="fa-solid fa-play fa-lg text-orange"></i></div></div></a></li>
            <li><a class="dropdown-item m-0 p-0 w-100" href="/enterprise/register">
                                                    <div class="d-flex justify-content-between pt-2 ps-3 pe-3 pb-2 border-top w-100">
                                                        <div class="bg-user"><i class="fa-solid fa-building text-white"></i></div>
                                                        <div class="px-0 position-relative right-13"><h4 class="head-popup ff-default">Enterprise</h4></div><div><i class="fa-solid fa-play fa-lg text-orange"></i></div></div></a></li>
          
          </ul>
        </li>
        <li className="hirecaas"> 
        <Link to="/hire/caas"className="active">Hire CAAS</Link>
        
        {/* <a href="#" class="active">Hire CAAS  </a> */}
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Login
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item m-0 p-0 w-100" href="/auth/caasmember/login">
                                            <div class="d-flex justify-content-between align-items-center mt-1 pt-2 ps-3 pe-3 pb-1 w-100">
                                                <div class="bg-user"><i class="fa-regular fa-circle-user fa-2x text-white"></i></div>
                                                <div class="px-2 "><h4 class="head-popup ff-default ">CAAS Member Login</h4></div>
                                                <div><i class="fa-solid fa-play fa-lg text-orange"></i></div></div></a></li>
            <li><a class="dropdown-item m-0 p-0  w-100" href="/auth/enterprisepm/login">
                                                    <div class="d-flex justify-content-between align-items-center  pt-2 ps-3 pe-3 pb-2 border-top  w-100">
                                                        <div class="bg-user"><i class="fa-solid fa-building text-white"></i></div>
                                                        <div class="px-2"><h4 class="head-popup ff-default">Enterprise Login</h4></div><div><i class="fa-solid fa-play fa-lg text-orange"></i></div></div></a></li>
          
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
              
               </div>
                 </div>
                <div className="container">
                <div className="websiterow-slider">
                    <div className="slide-text">

                        <h2 className="websitehead-yellow"> I am CaaS... </h2>
                        <h3 className="websitehead-white"> I am here to help...  </h3>

                    </div>

                </div>
            </div>

            <div className=" websitecontainer-middle">

                
                <div className="row">
                    <div className="websitewhoarewe">

                        <h3> WHO are We? </h3>
                        <h2> CaaS is all about People and the Community! </h2>

                        <div className="websiteinterim"> Interim CIO </div>
                        <div className="websiteproject"> Project CIO </div>
                        <div className="websiteconsultant"> Consultant CIO </div>
                        <div className="websitementor"> Mentor CIO </div>

                    </div>
                </div>





                <div className="row2 container">

                    <div className="team">
                        </div>
                        <div id="about" className="about pt-0">

<p className='text-left'> We are CIO as a Service, your ‘One Stop Shop’ for all  your technology and information needs. Whether  you want to implement turnkey projects, you need  expertise in specific areas or you are looking for  consultation for your assignments, CaaS is your  solution!

        We partner with you for your strategic long term  needs or for a specific short term requirement.  We will work with you to offer our deep expertise  and leverage our decades of experience in similar  environments to ultimately bring the best option to  your enterprise.  </p>

        <h2 className='text-left fw-bold'> How do we operate?</h2>

        <p className='text-left'> "We operate as a revolutionary ecosystem platform, which is driven by a group of independent experts with diverse industry experience.  We are constantly growing our community of experts who will be the catalyst behind the ongoing transformation.  This platform will emerge as one of the most revolutionary skills’ marketplace of the decade." </p>

        <h3 className='text-left'> What is our aim? </h3>

        <p className='text-left'> To be the customer’s Go-To-Platform for any and every ICT need! </p>

        <h3 className='text-left'> How do we do it? </h3>

        <p className='text-left'> At CaaS, we aim to offer our clients the most appropriate technology, by the best minds, at the  most competitive price, in the fastest possible time</p>

</div> 

                </div>

                <div className="container">


<div className="cio-customer mob-dis-block">

<div className="forcio mob-width-100 pe-4">


<h3> WHAT IS IN IT FOR CIOs </h3>

<ul className='col-xs-12'> 

<li> Earn money in your free time, monetize your experience </li>
<li> Break out of the rat race or retire early</li>
<li>Test your marketability - kickstart your entrepreneurial journey in a low risk  environment</li>
<li>Build your personal brand, work across  borders</li>
<li> Set your own hours, be your own boss</li>
<li> Get support by our back-office team</li>
</ul>


</div> 


<div className="forcustomer">


<h3> WHAT IS IN IT FOR CUSTOMERS? </h3>

<ul className='col-xs-12'> 

<li> Access vetted expertise to grow revenue, reduce cost, increase efficiencies and lower risk </li>
<li> Hire experts on demand with no risk and at a fraction of the cost </li>
<li> You don’t have to do it all yourself - complement your team’s strength </li>
<li> No regrets - Read reviews and check ratings before making your final selection </li>
</ul>


</div> 




</div>



</div>




                <div className="row" id="whyjoin">
                    <div className="websiteservices">

                        <h3> when, where & how </h3>
                        <h2 className='fw-bold'> Customer’s platform for any technology Need! </h2>
                        <p> At CaaS, we aim to offer our clients the most appropriate technology, by the best minds, at the most competitive price, in the fastest possible time! </p>

                    </div>


                    <div className="websiteservice-block-1">

                        <div className="websiteservices1"> <img src={service1} /> <br /> Business strategy  </div>
                        <div className="websiteservices2"> <img src={service2} /> <br /> Business process <br />re-engineering  </div>
                        <div className="websiteservices3"> <img src={service3} /> <br /> Design Thinking  </div>
                        <div className="websiteservices4"> <img src={service4} /> <br /> Supply Chain  </div>

                    </div>

                    <div className="websiteservice-block-2">

                        <div className="websiteservices5"> <img src={service5} /> <br /> Operations </div>
                        <div className="websiteservices6"> <img src={service6} /> <br /> Banking-retail,<br /> wholesale, SME  </div>
                        <div className="websiteservices7"> <img src={service7} /> <br /> Treasury/Global markets  </div>
                        <div className="websiteservices8"> <img src={service8} /> <br /> Trade/Core finance  </div>

                    </div>


                    <div className="websiteservice-block-3">

                        <div className="websiteservices9"> <img src={service9} /> <br /> Risk  </div>
                        <div className="websiteservices10"> <img src={service10} /> <br /> Technology  </div>
                        <div className="websiteservices11"> <img src={service11} /> <br /> Compliance </div>
                        <div className="websiteservices12"> <img src={service12} /> <br /> IT/ITES  </div>

                    </div>



                    <div className="websiteservice-block-4">

                        <div className="websiteservices13"> <img src={service13} /> <br /> BFSI  </div>
                        <div className="websiteservices14"> <img src={service14} /> <br /> Insurance  </div>
                        <div className="websiteservices15"> <img src={service15} /> <br /> Corporate &  Investment services </div>
                        <div className="websiteservices16"> <img src={service16} /> <br /> HR & HCM  </div>

                    </div>



                </div>








                <div className="container" id="caasstory">
                    <div className="websitewhygocaas">
                        <h2 className='fw-bold'>The CaaS Story </h2>
                        <p> In 2021, two technology evangelists met at an airport lounge and got talking, since their flight was delayed by a few hours. After a long conversation, CaaS  was born! The aim was to build a community of tech professionals who trust each other and offer solutions to clients by leveraging the power of collaboration, competency, and trust! </p>

									<p>After working on this concept for close to a year, CaaS was born as the platform as it stands today! We want to change the world by helping clients leverage the best and most appropriate technology solutions. We want to build stronger relationships between our champions, our clients, the product vendors, system integrators, and finally, the people and the community </p>

                    </div>


                    <div className="websiteProcess">

                        <div className="websiteone"> <img src={one} /> <br /> <h3 className='fw-bold mt-3'>Go Worry-Free </h3> <br /> Relief from admin issues for a pain-free engagement experience  </div>
                        <div className="websitetwo"> <img src={two} /> <br /> <h3 className='fw-bold mt-3'>Speed to Capacity</h3> <br /> Confidence to undertake and complete projects on schedule without carrying spare capacity.  </div>
                        <div className="websitethree"> 
                        <img src={three} />
                         <br /> 
                         <h3 className='fw-bold mt-3'>Experts on Demand </h3> <br /> 
                         <p>Comfort with the ability to find appropriate expert management talent quickly on demand. Sureness of platform capability to create expert led ninja teams speedily</p> </div>
                        <div className="websitefour"> <img src={four} /> <br /> <h3 className='fw-bold mt-3'>Break the Boundaries</h3> <br />Freedom to hire the best without concern for physical presence or employment restrictions. Assurance about the capability of the experts on the platform Curated </div>

                    </div>



                    <div className="websitebusiness-block-1">

                        <div className="websitebusinessone"> 
                        <div>
                        <h2 className='fw-bold mt-3'>How does CaaS improve business?</h2>
                         <p> We Believe Clients are the Reason we are here! </p> 
                        </div>
                         
                        </div>
                        <div className="websitebusinesstwo">  
                        <div>
                        <h3>WORKFORCE <br /> PRODUCTIVITY</h3>
                         <p> Find the experts when needed, utilize as needed & build a business based on a culture of performance </p>
                        </div>
                        
                          </div>
                        <div className="websitebusinessthree">
                        <div>
                              <h3>POWER OF <br />AGILITY </h3> <p> Generating high calibre management capability with variable cost structure. </p>
                              </div>
                               </div>

                    </div>


                    <div className="websitebusiness-block-2">


                        <div className="websitebusinessfour">  <div><h3>REDUCE RISK,<br /> INCREASE ASSURANCE</h3> <p> Reduces  risk characteristic of permanent workforce. Provides assurance of the capability needed to cross the finish line </p></div> </div>
                        <div className="websitebusinessfive">  <div><h3>SECURE <br />EXPERTISE </h3> <p> Reduces likelihood of persistent unfilled positions, and increases likelihood of securing expertise and skills needed to execute strategy </p> </div></div>
                        <div className="websitebusinesssix">  <div><h3>COST <br /> EFFICIENCY</h3> <p> Allows for cost optimization of OpEx on people costs by balancing between fixed and variable costs. </p> </div></div>

                    </div>





                





                </div>

                <div className="websitetrust-block">

<div className="websitetrustone"> <div><h2 className='fw-bold mt-3'>CaaS is built on Trust?</h2> <p> CaaS – CIO As a Service is your one-stop Shop for all your Technology Needs! </p></div>  </div>
<div className="websitetrusttwo"> <div> <h3>Finding high-level expertise and consultation for projects</h3>  </div> </div>
<div className="websitetrustthree"> <div> <h3>Support diverse workforce solutions </h3>  </div></div>
<div className="websitetrustfour"><div > <h3>Implement turnkey projects and assignments with expert touch </h3></div>   </div>

</div>



                <div className="row">


                    <div className="websitetestimonials">

                        <div className="websitetestimonials-inner">


                            {/* <div className="websiteleft-arrow">    </div> */}
                            <div className="websitetesti-text mb-4">   <img src={testi} />
                                <h3> Client’s experience </h3>
                                <p> We aim to offer our clients the most appropriate technology, by the best minds, at the most competitive price, in the fastest possible time! CaaS is your one-stop Shop for all your Technology Needs! <br /><br /> </p>
                                <img src={testilogo} />
                            </div>

                            <div className="websiteclient-image"> <img src={testigirl} /> </div>
                            {/* <div className="websiteleft-arrow"> </div> */}



                        </div>


                    </div>
                </div>







            </div>

            <div className="container websitecontainer" id="contact">
                <div className="websitefooter ">
                    <div className="websitefooter-inner align-center justify-content-between">

                        <div className="websitefooter-section1">

                            <img src={CAAS} className="websitefooter-logo" />
                          
                        </div>
                            <div className="websitefooter-section2 ">

                            <div className="casssupport">
                            <h3 className='fs-22'>UAE </h3>
                         
                            </div>

                        </div>

                        <div className="websitefooter-section3">

                            <div className="casssupport">
                                <h3 className='fs-22'>USA</h3>
						
                            </div>

                        </div>

                        <div className="websitefooter-section4">

                            <div className="casssupport">
                                <h3 className='fs-22'>Singapore</h3>
						
                            </div>
                        </div>

                        <div className="websitefooter-section5">

                            <div className="casssupport">
                                <h3 className='fs-22'>India</h3>
                        </div>
                        </div>
                        
                    </div>
                    

                </div>

                <div className="websitecopyright">
                    <div className="websitecopyright-inner">


                        <div className="social">

                            {/* <a href="#" target="_blank"> <img src={facebook}/></a> */}
                            <a href="https://twitter.com/iam_caas" target="_blank" className='ms-1 me-2'> <img src={twitter} /></a>
                            <a href="https://www.instagram.com/iam_caas/" target="_blank" className='me-2'> <img src={instagram} /></a>
                            <a href="https://www.linkedin.com/company/iamcaas/" target="_blank" > <img src={linkedin} className="linked-inimg mx-2"/></a>
                            {/* <a href="https://www.linkedin.com/company/iamcaas/" target="_blank"> <i className="mdi mdi-linkedin" /></a> */}

                            {/* <a href="#"> <img src={youtube} /></a> */}
                            
                        </div>

                        <div className="websitecopyright-text"> <p className='emailcontact-text mb-0'> Contact Email - hello@iamcaas.com</p>  <p> &copy; 2012-2022, CAAS FZ LLC. All rights reserved. </p>
                        </div>
                    </div>
                </div>
                </div>
</>
                )


}

export default LandingPage


