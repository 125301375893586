import { React, useState } from 'react'
import { API } from '../../config/APIConfig';
import axios from 'axios';
const ENCRDECRAPI = `${API.URL}api/v1/auth/encrdecr`;
const EncryptionDecryption = () => {
    const [inputtext, SetInputText] = useState("");
    const [responsetext, SetResponseText] = useState("");
    const [responseTypetext, SetResponseTypeText] = useState("Your Response");
    const EncryptionDecryption = (inputtext, methodtype) => {
        axios.get(`${ENCRDECRAPI}?encrdecrtext=${inputtext}&type=${methodtype}`).then(response => {
            if (response.data.status === 200) {
                SetResponseText(response.data.response);
            }

        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
    const handleEncryptSubmit = (event) => {
        event.preventDefault();
        SetResponseTypeText("Your Encrypted Cipher Text Response")
        const type = "Encrypt"
        EncryptionDecryption(inputtext, type);
    }
    const handleDecryptSubmit = (event) => {
        event.preventDefault();
        SetResponseTypeText("Your Decrypted Plain Text Response")
        const type = "Decrypt"
        EncryptionDecryption(inputtext, type);
    }
    const handlePasswordEncryptSubmit = (event) => {
        event.preventDefault();
        SetResponseTypeText("Your Encrypted Password Response")
        const type = "PasswordEncrypt"
        EncryptionDecryption(inputtext, type);
    }
    return (

        <div>

            <div className="form-group" style={{ marginRight: "45em", marginTop: "40px", marginLeft: "50px" }}>
                <label htmlFor="exampleInputEmail1">Enter the Text</label>
                <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="Enter text" rows="3" onChange={(e) => SetInputText(e.target.value)} ></textarea>
            </div>
            <button type="submit" className="btn btn-primary" style={{ marginRight: "53.5em", marginTop: "10px", marginLeft: "50px" }} onClick={handleEncryptSubmit}>Encrypt Plain Text</button>
            <button type="button" className="btn btn-success" style={{ marginTop: "10px", marginLeft: "-50.5em" }} onClick={handleDecryptSubmit}>Decrypt Cipher Text</button>
            <button type="button" className="btn btn-warning" style={{ marginTop: "10px", marginLeft: "3.5em" }} onClick={handlePasswordEncryptSubmit}>Encrypt Password</button>
            <label htmlFor="exampleInputEmail1" style={{ marginRight: "53.5em", marginTop: "40px", marginLeft: "50px" }}>
                <p className="text-success">{responseTypetext}:</p>
                <p className="font-weight-bold" style={{ "font-weight": "bold" }} >{responsetext}</p>
            </label>
        </div>
    )
}

export default EncryptionDecryption
