import { React, useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom';
import Header from '../../../commons/components/Header';
import VendorSideBar from '../../../commons/components/SideBars/VendorSideBar';
import { API } from '../../../config/APIConfig';
import axios from 'axios'
import formatDate from '../../../utils/dateformatter';
import logger from '../../../utils/logger';
import $ from "jquery";
import CaaSSideBar from '../../../commons/components/SideBars/CaaSSideBar';
import Pagination from './Pagination';
import swal from 'sweetalert';
export default function VendorList() {
    const [vendorList, setVendorList] = useState([]);
    const [curVendorList, setCurVendorList] = useState([]);
    const [resetUserDetails, setResetUserDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const GETVENDORLISTAPI = `${API.URL}api/v1/vendor/alllist`;
    const UPDATEVENDORSTATUSAPI = `${API.URL}api/v1/admin/updatevendorstatus`;
    const UPDATEVENDORAPPROVALSTATUSAPI = `${API.URL}api/v1/admin/updatevendorapprovalstatus`;
    const RESETPASSWORDAPI = `${API.URL}api/v1/auth/password/reset`;
    // No of Records to be displayed on each page   
    const [recordsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    //pagination
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const getVendorList = async () => {
        setLoading(true);
        const response = await fetch(GETVENDORLISTAPI);
        if (!response.ok) {
            throw new Error('Data coud not be fetched!')
        } else {
            return response.json()
        }
    }
    useEffect(() => {
        bindVendors();
        highlightNavMenu();
    }, []);

    const highlightNavMenu = () => {
        $("li.sidebarmenu").removeClass("active");
        $("#caas_vendors_li").addClass("active");
    }
    const bindVendors = () => {
        getVendorList()
            .then((data) => {
                if (data.response) {
                    setVendorList(data.response);
                    setCurVendorList(data.response);
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e.message)
            });
    }
    const currentRecords = curVendorList.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(curVendorList.length / recordsPerPage)
    const resetVendorStatus = (vendorId, curStatus) => {
        var status = curStatus == 0 ? 1 : 0;
        updateVendorDetails(vendorId, status);
    }
    const searchVendor = () => {
        const keyword = document.getElementById("searchvendor").value;
        const searchedvendor = vendorList.filter((ele) => ele?.vendorName.toLowerCase().includes(keyword.toLowerCase() || ele?.userName.toLowerCase().includes(keyword.toLowerCase())));
        setCurrentPage(1);
        setCurVendorList([...searchedvendor]);
    }
    const bindUserDetailsForReset = (vendorId) => {
        document.getElementById("resetpasswordform").reset();
        const searchvendor = vendorList.filter((ele) => ele?.vendorId == vendorId);
        if (searchvendor.length != 1)
            return
        setResetUserDetails([...searchvendor]);
        document.getElementById("resetUserName").value = searchvendor[0]?.keyContact;
    }
    const handleKeyPress = (e) => {
        if (e.charCode === 32 && e.key === " ") {
          e.preventDefault();
        }
      }
    const updateVendorDetails = (vendorId, status) => {
        const searchvendor = vendorList.filter((ele) => ele?.vendorId == vendorId);
        if (searchvendor.length != 1)
            return
        var { keyContact, entityId, roleId } = searchvendor[0];
        let updobj = { vendorId, entityId, roleId, keyContact, status }
        axios.put(UPDATEVENDORSTATUSAPI, updobj)
            .then(response => {
                if (response.data.status === 200) {

                    swal("Updated Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                    bindVendors();
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    const resetVendorPassword = () => {
        $("#resetPasswordbtn").addClass("disabled");
        const userName = document.getElementById("resetUserName").value;
        const userNewPassword = document.getElementById("resetVendorPassword").value;
        if (userName.length > 0 && userNewPassword.length > 0) {

            const { vendorId, emailAddress, userId, entityId, roleId, keyContact, keyContactEmail } = resetUserDetails[0];
            if (userName === keyContact) {
                let updobj = { vendorId, emailAddress, userId, entityId, roleId, userName, userNewPassword, keyContactEmail };
                axios.put(RESETPASSWORDAPI, updobj)
                    .then(response => {
                        if (response.data.status === 200) {
                            swal("Password was Reset Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                            $("#ResetPassword .close").trigger('click');
                            $("#resetPasswordbtn").removeClass("disabled");
                            document.getElementById("resetpasswordform").reset();
                            bindVendors();
                        }
                        $("#resetPasswordbtn").removeClass("disabled");
                    })
                    .catch(error => {
                        console.error('There was an error!', error);
                        $("#resetPasswordbtn").removeClass("disabled");
                    });
            }
        }
        else {
            swal("Please enter the password", "", "error", { closeOnClickOutside: false, closeOnEsc: false });
            $("#resetPasswordbtn").removeClass("disabled");
        }
    }
    const updateVendorApprovalStatus = (vendorId, approval) => {

        const searchedvendor = vendorList.filter((ele) => ele?.vendorId == vendorId);
        if (searchedvendor.length != 1)
            return
        var { vendorName, emailAddress, keyContact, entityId, roleId, keyContactEmail } = searchedvendor[0];
        var isApproved = approval == 0 ? 1 : 0;
        let updobj = { vendorId, entityId, emailAddress, roleId, vendorName, keyContact, isApproved, keyContactEmail }
        axios.put(UPDATEVENDORAPPROVALSTATUSAPI, updobj)
            .then(response => {
                if (response.data.status === 200) {
                    bindVendors();
                    swal("Approved Successfully", "", "success", { closeOnClickOutside: false, closeOnEsc: false });
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }


    return (
        <>
            <div className="container-scroller">
                <Header />
                <div className="container-fluid page-body-wrapper">
                    < CaaSSideBar />
                    {loading ? (
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    ) : (
                        <div className="main-panel">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-12 grid-margin stretch-card">
                                        <div className="col-lg-12 d-grid">
                                            <div className="card">
                                                <div className="card-body pb-0">
                                                    <h4 className="card-title">Vendors</h4>
                                                </div>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="mt-0 text-left box-shadow cardPad card-body border border-radius">

                                                            <div className="d-flex justify-content-end">
                                                                {/* <a className="btn addindustrybtn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#AddIndustry" title="Add" >
                                                        <i className="mdi mdi-note-plus-outline"></i> Add CXO
                                                    </a> */}
                                                                <div className="float-end d-flex align-items-center filter-option mb-0">
                                                                    <input className="form-control border-secondary border-right-0 rounded-0 search-input" type="search"
                                                                        placeholder="Search Vendor"
                                                                        id="searchvendor"
                                                                        onChange={searchVendor}
                                                                    />
                                                                    <div className="col-auto">
                                                                        <button className="btn btn-outline-secondary border-left-0 rounded-0 rounded-right search-btn" type="button"><i className="fa fa-search"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12 mt-2">
                                                                    <div className="home-tab">
                                                                        <div className="tab-content1">
                                                                            <div className="" id="NewCompany" >
                                                                                <div className="template-demo table-responsive">
                                                                                    <Records currentRecords={currentRecords} resetVendorStatus={resetVendorStatus} bindUserDetailsForReset={bindUserDetailsForReset} updateVendorApprovalStatus={updateVendorApprovalStatus} />
                                                                                    {/* resetUserStatus={resetUserStatus} updateVendorApprovalStatus={updateVendorApprovalStatus} bindUserDetailsForReset={bindUserDetailsForReset}  */}
                                                                                    <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}
                </div>
            </div>
            <div className="modal fade show" id="ResetPassword" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content cardPad card-body border border-radius">
                        <div className="modal-header">
                            <h3 className="modal-title">Reset Password </h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="resetpasswordform">

                                <div className="form-group row">
                                    <label className="col-sm-4 col-form-label">User Name </label>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="resetUserName" disabled />

                                    </div>

                                    <label className="col-sm-4 col-form-label mt_10">Password </label>
                                    <div className="col-sm-8 mt_10">
                                        <input type="text" className="form-control" id="resetVendorPassword" name="resetPassword" onKeyPress={handleKeyPress} />

                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-warning btn-md previous-btn" data-bs-dismiss="modal">Cancel</a>
                            <a type="button" className="btn btn-warning btn-md next-btn" id="resetPasswordbtn"
                                onClick={resetVendorPassword}
                            >Reset</a>

                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
// , resetUserStatus, updateVendorApprovalStatus, bindUserDetailsForReset
const Records = ({ currentRecords, resetVendorStatus, bindUserDetailsForReset, updateVendorApprovalStatus }) => {
    return (
        <table className="table table-bordered mb-0">
            <thead>
                <tr>

                    <th>Vendor Name</th>
                    <th>Vendor Website</th>
                    <th>Key Contact</th>
                    <th>Key Contact Email</th>
                    <th>Status</th>
                    <th>Approval Status</th>
                    <th className="text-center">Reset Password</th>
                </tr>
            </thead>
            <tbody>
                {
                    currentRecords.length != 0 ? (
                        currentRecords.map((ele, index) => (

                            <tr key={ele?.vendorId}>
                                {/* <td className="text-center">
    
                                                                                                    <a className="btn btn-sm btn-primary btn-rounded mr_5 editpopupclient" data-bs-toggle="modal" data-bs-target="#EditLanguage" title="Edit" data-for={ele?.languageId}>
                                                                                                        <i className="fa fa-edit"></i>
                                                                                                    </a>
                                                                                                    <a className={ele?.languageStatus==1?"btn btn-sm btn-danger btn-rounded deletepopup":""}
                                                                                                        data-bs-toggle="modal" data-bs-target="#deleterecord" title="Delete" data-for={ele?.vendorId} >
                                                                                                        <i className={ele?.languageStatus==0?"":"fa fa-trash"}></i>
                                                                                                    </a>
                                                                                                </td> */}
                                <td><Link to={`/caas/global/vendor/view/${ele.vendorId}`} >{ele?.vendorName}</Link></td>
                                <td>{ele?.website}</td>
                                <td>{ele?.keyContact}</td>
                                <td>{ele?.keyContactEmail}</td>
                                <td>
                                    {/* <div className={ele?.vendorStatus == 1 ? "badge badge-pill badge-success" : "badge badge-pill badge-danger"}>{ele?.isApproved == 1 ? "Approved" : "Rejected"}</div> */}

                                    <div className="d-flex">
                                        <label htmlFor="site_state" className="form-check-label">In Active</label>
                                        <div className="form-switch form-check ms-2 margin-0">
                                            <input type="checkbox" className="form-check-input" id="site_state" onChange={() => {

                                                resetVendorStatus(ele?.vendorId, ele?.status)

                                            }} checked={ele?.status == 1 ? 'checked' : ''} />
                                        </div>
                                        <label htmlFor="site_state" className="form-check-label">Active</label>
                                    </div>
                                </td>
                                <td>
                                    {/* {ele?.isApproved == 1 ? <><button className='btn btn-disabled unsetCase' disabled>Approve</button> <button className='btn btn-danger unsetCase' data-for={ele?.vendorId} onClick={Rejected}>Reject</button></> : <>
                                                                                                        <button className='btn btn-success unsetCase' onClick={Approved} data-id={ele?.vendorId}>Approve</button>
                                                                                                        <button className='btn btn-disable unsetCase' disabled>Reject</button></>} */}
                                    {ele?.isApproved == 1 ? "Approved" : <div className="form-switch form-check  margin-0 text-center">
                                        <input type="checkbox" className="form-check-input text-center" id="site_state" onChange={() => {

                                            // resetUserStatus(ele?.userId,ele?.userName,ele?.userStatus)
                                            updateVendorApprovalStatus(ele?.vendorId, ele?.isApproved)

                                        }} />
                                    </div>}
                                </td>
                                <td className="text-center">
                                    {ele?.isApproved == 1 ?
                                        <a className="btn addindustrybtn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#ResetPassword" title="Reset Password"
                                            onClick={() => { bindUserDetailsForReset(ele?.vendorId) }}
                                        >
                                            Reset Password
                                        </a>
                                        : "NA"}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                        </tr>
                    )
                }
            </tbody>
        </table>
    )
}