import $ from "jquery"
export default function scrollTop() {
    if($(window).width()>1024){
      
        // var dd =$('.header-section').height()*3;
        // $(".box_1 .sticky-top .box_shadow_1 .list-company").attr('style', 'height:' + ((dd) + 'px !important'));
        // var box_1 = $('.box_1 .sticky-top .box_shadow_1:first-child').height() + $('.header-section').height();
        // var screenHeight = $(window).height();

        // var box_4 = $('.box_4 .sticky-top .box_shadow_1:first-child').height() + $('.header-section').height();
        // $(".box_4 .sticky-top .box_shadow_1 .companyannouncements1").attr('style', 'height:' + ((screenHeight - box_4) - 215 + 'px !important'));
        // var box_3 = $(window).height() - $('.header-section').height();
        // $(".box_3 .connectionss").attr('style', 'height:' + ((box_3) - 160 + 'px !important'));
        // var box_4 = $(window).height() - $('.header-section').height();
        //$(".box_3 .sticky-top .box_shadow_1").attr('style', 'height:' + (box_3 - 5 + 'px !important'));
        //var box_5 = $(window).height() - $('.header-section').height();
        var box_1 = $(window).height() - $('.box_1 .sticky-top .box_shadow_1:first-child').height();
        var box_4 = $(window).height() - $('.box_4 .sticky-top .box_shadow_1:first-child').height();
        var box_3 = $(window).height();
        
 
        $(".box_1 .sticky-top .box_shadow_1 .list-company").attr('style', 'height:' + (box_1-157+ 'px !important'));
        //$(".box_3 .box_shadow_1 .sticky-top").attr('style', 'height:' + ($(window).height() + 'px !important'));
        //$(".box_1, .box_4").addClass('TopFixed');

        $(".box_4 .sticky-top .box_shadow_1 .companyannouncements1").attr('style', 'height:' + (box_4 - 215 + 'px !important'));

        $(".box_3 .connectionss").attr('style', 'height:' + (($(window).height()) - 150 + 'px !important'));

    //     if($(window).width()>1024){
    //     var x = $('.box_1').offset();
    //     window.addEventListener("scroll",function () {
    // console.log("inside function")
    //         if ($(window).scrollTop() >= x.top) {
    //             console.log("addtofeed")

    //             var box_1 = $(window).height() - $('.box_1 .sticky-top .box_shadow_1:first-child').height();
    //             var box_4 = $(window).height() - $('.box_4 .sticky-top .box_shadow_1:first-child').height();
    //             var box_3 = $(window).height();
    //             $(".box_1 .sticky-top .box_shadow_1 .list-company").attr('style', 'height:' + (box_1 - 195 + 'px !important'));
    //             //$(".box_3 .box_shadow_1 .sticky-top").attr('style', 'height:' + ($(window).height() + 'px !important'));
    //             //$(".box_1, .box_4").addClass('TopFixed');

    //             $(".box_4 .sticky-top .box_shadow_1 .companyannouncements1").attr('style', 'height:' + (box_4 - 215 + 'px !important'));

    //             $(".box_3 .connectionss").attr('style', 'height:' + (($(window).height()) - 150 + 'px !important'));
    //         }
    //         else {
    //     //         var box_1 = $('.box_1 .sticky-top .box_shadow_1:first-child').height() + $('.header-section').height();
    //     //         var screenHeight = $(window).height();
    //     //         $(".box_1 .sticky-top .box_shadow_1 .list-company").attr('style', 'height:' + ((dd) + 'px !important'));
    //     //         //var box_3 = $(window).height() - $('.header-section').height();
    //     //         //$(".box_3 .box_shadow_1 .sticky-top").attr('style', 'height:' + (box_3 - 5 + 'px !important'));
    //     //         $(".box_1, .box_4").removeClass('TopFixed');
    //     //         var box_4 = $('.box_4 .sticky-top .box_shadow_1:first-child').height() + $('.header-section').height();
    //     //         $(".box_4 .sticky-top .box_shadow_1 .companyannouncements1").attr('style', 'height:' + ((screenHeight - box_4) - 215 + 'px !important'));
    //     //         var box_3 = $(window).height() - $('.header-section').height();
    //     // $(".box_3 .connectionss").attr('style', 'height:' + ((box_3) - 160 + 'px !important'));
    //     var box_1 = $(window).height() - $('.box_1 .sticky-top .box_shadow_1:first-child').height();
    //     var box_4 = $(window).height() - $('.box_4 .sticky-top .box_shadow_1:first-child').height();
    //     var box_3 = $(window).height();
    //     $(".box_1 .sticky-top .box_shadow_1 .list-company").attr('style', 'height:' + (box_1 - 195 + 'px !important'));
    //     //$(".box_3 .box_shadow_1 .sticky-top").attr('style', 'height:' + ($(window).height() + 'px !important'));
    //     //$(".box_1, .box_4").addClass('TopFixed');

    //     $(".box_4 .sticky-top .box_shadow_1 .companyannouncements1").attr('style', 'height:' + (box_4 - 215 + 'px !important'));

    //     $(".box_3 .connectionss").attr('style', 'height:' + (($(window).height()) - 150 + 'px !important'));
    //         }
    //     });
    // }
    // else{
    //     $(".box_1 .sticky-top .box_shadow_1 .list-company").attr('style', '');
    //     $(".box_4 .sticky-top .box_shadow_1 .companyannouncements1").attr('style', '');
    //     $(".box_3 .connectionss").attr('style','');
    // }
}
else{
    $(".box_1 .sticky-top .box_shadow_1 .list-company").attr('style', '');
    $(".box_4 .sticky-top .box_shadow_1 .companyannouncements1").attr('style', '');
    $(".box_3 .connectionss").attr('style','');
}
}