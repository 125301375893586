import React from 'react'
import Header from '../../../commons/components/Header';
import CaaSSideBar from '../../../commons/components/SideBars/CaaSSideBar';
import { API } from '../../../config/APIConfig';
import axios from 'axios'
import { Link, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
const RFPLISTAPI = `${API.URL}api/v1/rfp/admin/list`;
const RFPManagement = (props) => {
    const { i18n, t } = useTranslation(["rfp"]);
    const [RFPList, setRFPList] = useState([]);
    const [curRFPList, setCurRFPList] = useState([]);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const data = location.state?.status;
    useEffect(() => {
        getRFPDetails();
    }, []);
    const getRFPDetails = () => {
        setLoading(true);
        axios.get(RFPLISTAPI).then(response => {
            if (response.data.status === 200) {
                if (data) {
                    var RFPListDetails = response.data.response.filter(status => status.rfpStatusId == data)
                    setRFPList(RFPListDetails);
                    setCurRFPList(RFPListDetails);
                }
                else {
                    setRFPList(response.data.response);
                    setCurRFPList(response.data.response);
                }
                setLoading(false);
            }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    }
    const searchRFP = () => {
        const keyword = document.getElementById("searchrfp").value;
        // console.log(keyword)
        // console.log(RFPList)
        // const a= RFPList.filter((ele) => ele?.rfpTitle.includes(keyword));
        // console.log("Titles",a)
        const searchedRFP = RFPList.filter((ele) => ele?.rfpTitle?.toLowerCase().includes(keyword.toLowerCase().trim()));
        setCurRFPList([...searchedRFP]);

    }


    return (
        <div className="container-scroller">
            <Header entity="CaaS" />
            <div className="container-fluid page-body-wrapper">
                <CaaSSideBar /> {loading ? (
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                ) : (
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-12 grid-margin stretch-card">
                                    <div className="col-12">
                                        <div className="grid-margin stretch-card">
                                            <div className="card">

                                                <div className="card-body pb-0">
                                                    <h4 className="card-title">
                                                        {
                                                            data ? "Open RFP" : "Total RFP"
                                                        }
                                                        <Link className="text-blue fs-5 float-sm-right" to="/caas/global/dashboard"><span className=" mdi mdi-arrow-left"></span>Back to Dashboard</Link>


                                                    </h4>
                                                    <div className="float-end d-flex align-items-center filter-option mb-0 searchmanagerfp">
                                                        <input className="form-control border-secondary border-right-0 rounded-0 search-input" type="search"
                                                            placeholder="Search RFP"
                                                            id="searchrfp"
                                                            onChange={searchRFP}
                                                            onPaste={searchRFP}
                                                        />
                                                        <div className="col-auto">
                                                            <button className="btn btn-outline-secondary border-left-0 rounded-0 rounded-right search-btn" type="button"><i className="fa fa-search"></i>
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        <div className="card">

                                            <div className="card-body">
                                                {
                                                    // data ?
                                                    curRFPList.length != 0 ? (
                                                        curRFPList.map((ele, index) => (
                                                            <div className="mt-0 text-left box-shadow p-4 cardPad card-body border border-radius" key={index}>
                                                                <div className="d-flex justify-content-between align-items-start RFP_Details">
                                                                    <div className="list-item-details">
                                                                        <div className="d-table">
                                                                            <div className="d-table-cell pe-5">{t('title')}</div>
                                                                            <div className="d-table-cell text-orange">
                                                                                <Link to={`/caas/global/rfp/automatchAndPublish/${ele.rfpId}`}>
                                                                                    {ele?.rfpTitle}
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-table">
                                                                            <div className="d-table-cell pe-5">{t('postedDate')}</div>
                                                                            <div className="d-table-cell">{ele?.postedDate}</div>
                                                                        </div>
                                                                        <div className="d-table">
                                                                            <div className="d-table-cell pe-5">{t('proposalDueDate')}</div>
                                                                            <div className="d-table-cell">{ele?.lastDateForResponse}</div>
                                                                        </div>
                                                                        <div className="d-table">
                                                                            <div className="d-table-cell pe-5">{t('statusRFP')}</div>
                                                                            <div className="d-table-cell text-green">
                                                                                <Link to={`/caas/global/rfp/view/${ele.rfpId}`} state={{ "passrfpId": `${ele.rfpId}`, status: data }} className="edit-profile btn-orange quote-btn">{t(`${ele.rfpStatus}`)}</Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='d-block'>
                                                                        <Link to={`/caas/global/quote/viewReceived/${ele?.rfpId}`} state={{ status: data }} className="edit-profile float-end btn-orange quote-btn right-0 admin-manage-rfpbtn ">{t('quoteReceived')} - {ele.quotesReceived}</Link>
                                                                        <Link to={`/caas/global/rfppublished/list/${ele?.rfpId}`} state={{ status: data,title:`${ele?.rfpTitle}` }} className="edit-profile btn-orange quote-btn position-relative float-end my-4 admin-manage-rfpbtn">RFP Published - {ele.rfpPublished}</Link>
                                                                    </div>




                                                                </div>
                                                                <div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : null
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default RFPManagement
