import react from 'react'
import RouteConfig from './routes/RouteConfig'
import $ from "jquery";
import swal from 'sweetalert';
import "./assets/css/style.css";
import "./assets/css/custom.css";
import "./assets/css/responsive.css"
const ViewBuilder = () => {
    return (
        <RouteConfig />
    )
}



export default ViewBuilder