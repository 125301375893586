import React, { useState, useEffect } from 'react'
import Header from '../../../commons/components/Header'
import Footer from '../../../commons/components/Footer'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import CaaSSideBar from '../../../commons/components/SideBars/CaaSSideBar'
import RFPDetailsGlobal from '../../RFP/caas/RFPDetailsGlobal'
import CXOProfileView from '../../../commons/components/Profile/CXOProfileView'
import swal from 'sweetalert';
import $ from "jquery";
import { API } from '../../../config/APIConfig';
import axios from 'axios'
import logger from '../../../utils/logger'
import { useTranslation } from "react-i18next";
import { S3Config } from '../../../config/AWSConfig'
import CaasMemberView from '../../Settings/caas/CaasMemberView'
const QUOTEDETAILSAPI = `${API.URL}api/v1/quote/list/all`;
const FORWARDQUOTEAPI = `${API.URL}api/v1/admin/forwardquote`;

const RFPQuote = () => {
    const { i18n, t } = useTranslation(["quote"]);

    const location = useLocation();
    const rfpId = location.pathname.split('/')[5];
    const rfpStatus = location.state.status;
    const [quotedata, setQuoteData] = useState([]);
    const [quoteattachments, setQuoteAttachments] = useState([]);
    const [showquotedata, setShowquotedata] = useState('');
    const [showquoteattachments, setShowquoteattachments] = useState([]);
    const [curCXOData, setCurCXOData] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        getQuoteDetails();
    }, []);
    const getQuoteDetails = () => {
        setLoading(true);
        // logger.verbose("Quote Details", "RFPQuote Component", "getQuoteDetails", "GET", "Get Quote Details API Hit", `rfpId=${rfpId}`);
        axios.get(`${QUOTEDETAILSAPI}?rfpId=${rfpId}`).then(response => {
            if (response.data.status === 200) {
                setQuoteData(response.data.response.quotedetailslist);
                setQuoteAttachments(response.data.response.quoteattachmentslist);
                setLoading(false);
                // logger.verbose("Quote Details", "RFPQuote Component", "getQuoteDetails", "GET", "Get Quote Details API Response Successfull", JSON.stringify(response.data.response));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            // logger.error("Quote Details", "RFPQuote Component", "getQuoteDetails", "GET", "Get Quote Details API Response Failed!!", JSON.stringify(error));
        });
    }

    const showquote = (id) => {
        let newquote = quotedata.find((elem) => {
            return elem.quoteId === id;
        });
        setShowquotedata(newquote);
        let newquoteattachment = quoteattachments.filter((elem) => {
            return elem.quoteId === id;
        });
        setShowquoteattachments(newquoteattachment);
    }
    const resetCurCXO = (cxoUserId, roleId) => {
        let obj = {
            userId: cxoUserId, roleId: roleId
        }
        let arr = [];
        arr.push(obj);
        setCurCXOData([...arr]);
    }
    const handleForwardQuote = (quoteForwardStatus, quoteId, rfpId, quotedBy) => {
        axios.post(`${FORWARDQUOTEAPI}?quoteForwardStatus=${quoteForwardStatus}&quoteId=${quoteId}&rfpId=${rfpId}&quotedBy=${quotedBy}`).then(response => {
            if (response.data.status === 200) {
                getQuoteDetails();
                swal("Success", "Quote forwarded successfully", "success", { closeOnClickOutside: false, closeOnEsc: false })
                // logger.verbose("Quote Details", "RFPQuote Component", "getQuoteDetails", "GET", "Get Quote Details API Response Successfull", JSON.stringify(response.data.response));
            }
        }).catch(error => {
            console.error('There was an error!', error);
            // logger.error("Quote Details", "RFPQuote Component", "getQuoteDetails", "GET", "Get Quote Details API Response Failed!!", JSON.stringify(error));
        });

    }
    return (
        <div className="container-scroller">
            <Header entity="CaaS" />
            <div className="container-fluid page-body-wrapper">
                <CaaSSideBar />
                {loading ? (
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                ) : (
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="card card-rounded card-outline card-primary">
                                        <div className="card-header">
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <nav className="navbar navbar-expand bg-white">

                                                        <ul className="navbar-nav">
                                                            <li className="nav-item bold">
                                                                <h3 className="fw-bold mb-0 card-title">{t('viewRFPResponse')}
                                                                </h3>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                                <div>
                                                    <Link to="/caas/global/managerfp" state={{ status: rfpStatus }} className="btn btn-primary btn-sm add-client btn-icon-text"><i className="fa fa-arrow-left btn-icon-prepend"></i>{t('backToRFP')}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row px-4">
                                                <RFPDetailsGlobal rfpId={rfpId} />
                                                <div className="col-12 col-sm-12 mt-2">
                                                    <div className="col-12 col-sm-12 mt-2">
                                                        <div className="template-demo table-responsive">
                                                            <table className="table table-bordered mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Details</th>
                                                                        <th>Name</th>
                                                                        <th>Email</th>
                                                                        <th>Forward Quotes</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {quotedata.length != 0 ? (quotedata.map((item, i) => (
                                                                        item.userStatus == 0 ? <tr key={i} value={item.quoteId} className="table-light">
                                                                            <td className="text-left-intend">
                                                                                Inactive User
                                                                            </td>

                                                                            <td className="text-left-intend">



                                                                                {/* {item.quotedByuserName}, {item.quoteuserlastname}</a></td> */}
                                                                                {item.quotedByuserfirstName} {item.quotedByuserlastname}
                                                                            </td>
                                                                            <td className="text-left-intend">{item.quotedByuserEmail}</td>
                                                                            <td className="text-left-intend"><a className='edit-profile btn-orange quote-btn disabled'>No Quote</a></td>

                                                                        </tr> :
                                                                            <tr key={i} value={item.quoteId} >
                                                                                <td className="text-left-intend">
                                                                                    <a className="text-dark text-decoration-underline" id={item.quoteId} data-bs-toggle="modal" data-bs-target="#quotedata" onClick={() => showquote(item.quoteId)}>{t('quoteReceived')}</a>

                                                                                </td>

                                                                                <td className="text-left-intend">
                                                                                    <a href="#"
                                                                                        data-bs-toggle="modal"
                                                                                        data-bs-target="#ViewProfile"
                                                                                        data-backdrop="static" data-keyboard="false"
                                                                                        onClick={() => resetCurCXO(item.quotedBy, item.roleId)}
                                                                                    >
                                                                                        {/* {item.quotedByuserName}, {item.quoteuserlastname}</a></td> */}
                                                                                        {item.quotedByuserfirstName} {item.quotedByuserlastname}</a></td>
                                                                                <td className="text-left-intend">{item.quotedByuserEmail}</td>
                                                                                <td className="text-left-intend">
                                                                                    {item.isForwarded == 0 ? <a className='edit-profile btn-orange quote-btn' title='Forward Quote' onClick={() => handleForwardQuote(1, item.quoteId, item.rfpId, item.quotedBy)}>Forward Quote</a> : <a className='edit-profile btn-orange quote-btn disabled'>Forward Quote</a>}

                                                                                </td>
                                                                            </tr>))) : (
                                                                        <p>{t('noQuoteData')}</p>)}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <Footer />

                    </div>

                )}


            </div>
            <div className="modal fade show" id="quotedata" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('viewQuote')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">

                                <div className="col-12 col-sm-12 ">

                                    <div className="form-group border-bottom">
                                        <label className="mb-1 fw-bold">{t('requirementSpecification')}</label>
                                        <p>{showquotedata.quoteRequirement}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12">

                                    <div className="form-group  border-bottom">
                                        <label className="mb-1 fw-bold">{t('scopeConfirmation')}</label>
                                        <p>{showquotedata.quoteScope}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 ">

                                    <div className="form-group border-bottom">
                                        <label className="mb-1 fw-bold">{t('outOfScopeConfirmationAddition')}</label>
                                        <p>{showquotedata.quoteOutOfScope}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12">
                                    <div className="form-group border-bottom">
                                        <label className="mb-1 fw-bold">{t('cost')}:</label>
                                        <p>${showquotedata.costEstimate}</p>
                                    </div>
                                </div>


                                <div className="mt-2 pb-1">
                                    <h3 className="text-dark fw-bold h4 border-bottom py-3">{t('attachment')}</h3>
                                </div>

                                {
                                    showquoteattachments.length != 0 ? (
                                        showquoteattachments.map((ele, index) => (
                                            // <option key={index} value={ele.quoteAttachmentsId}>{ele.countryName}</option>
                                            <div className="mt-0 pb-1">
                                                <label className="text-gray mb-2 mt-2 pb-3 border-bottom d-block" key={index} value={ele.quoteAttachmentsId}> <i className="fa-solid fa-file-word fa-lg"></i> - {ele.quoteAttachmentsType}</label>
                                                <a href={ele?.attachment} target="_blank">{ele.attachmentTitle.split(S3Config.S3ObjectDelimiter)[0]}</a>
                                            </div>
                                        ))
                                    ) : null
                                }




                                {/* <label for="" className="text-gray mb-2 mt-2 pb-3 border-bottom d-block"> <i className="fa-solid fa-file-word fa-lg"></i> - Bill of Material/Hardware/Software Licences</label>
                                </div> */}

                                {/* <div className="mt-2 pb-1">
                                    <label for="" className="text-gray mb-2 mt-2 pb-3 border-bottom d-block "> <i className="fa-solid fa-file-word fa-lg"></i> - Architecture/Design Doccuments</label>
                                </div>
                                <div className="mt-0 pb-1 ">
                                    <label for="" className="text-gray mb-2 mt-2 pb-3 border-bottom d-block"> <i className="fa-solid fa-file-word fa-lg"></i> - Project Plan</label>
                                </div>

                                <div className="mt-0 pb-1 ">
                                    <label for="" className="text-gray mb-2 mt-2 pb-3  d-block "> <i className="fa-solid fa-file-word fa-lg"></i> - Miscellaneous File</label>
                                </div>
 */}

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger btn-sm" data-bs-dismiss="modal">{t('close')}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade show" id="ViewProfile" aria-modal="true" role="dialog" tabIndex="-1" aria-labelledby="myModalLabel" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">{t('viewProfile')}</h3>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setCurCXOData([])}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row w-100 mx-0">
                                {curCXOData.length != 0 ? (<CaasMemberView quotedBy={curCXOData[0]?.userId} roleId={curCXOData[0]?.roleId} hideBack={true} />) : <p>{t('noRecordsFound')}</p>}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a type="button" className="btn btn-danger btn-sm" data-bs-dismiss="modal" onClick={() => setCurCXOData([])}>{t('close')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default RFPQuote
